import React from 'react'
import { Link } from 'react-router-dom'


const Manage_AudioCategories = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className=" pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between mb-4 align-items-baseline">
                      <div className="iq-header-title">
                        <h4 className="card-title">Audio Category</h4>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link
                          to="/cpp/add-category"
                          className="btn btn-primary"
                        >
                          Add Audio category
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <div className="table-view p-0">
                        <table
                          className="table-bordered iq-card"
                          id="categorytbl"
                        >
                          <thead>
                            <tr className="text-center r1">
                              <th>
                                <label>S.No</label>
                              </th>
                              <th>
                                <label>Image</label>
                              </th>
                              <th>
                                <label>Name</label>
                              </th>
                              <th>
                                <label>Action</label>
                              </th>
                            </tr>
                          </thead>
                          {/* {managecategories.map((item, key) => (
                            <tbody>
                              <tr className="text-center">
                                <td key={key}> {key + 1} </td>
                                <td>
                                  <img src={item.image_url} width="100px" />
                                </td>
                                <td>{item.name}</td>
                                <td>
                                  <Link
                                    to={
                                      "/admin/edit-audio-categories/" + item.id
                                    }
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))} */}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                            //   onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">
                                {/* {page} */}
                                </p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                            //   onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Manage_AudioCategories