import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const MyChannelSettings = () => {
  const [order, setOrder] = useState({});
  const [editUser, setEditUser] = useState({
    id: "",
    channel_name: "",
    channel_slug: "",
    email: "",
    password: "",
    ccode: "",
    mobile_number: "",
    channel_image: "",
    channel_banner: "",
    channel_about: "",
    channel_logo: "",
    intro_video: "",
    status: "",
    unhased_password: "",
    activation_code: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    IFSC_Code: "",
    cancelled_cheque: "",
    upi_id: "",
    unhased_password: "",
    upi_mobile_number: "",
    cancelled_cheque_url: "",
  });
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Bank-setting`,
        { Channel_Partner_id: Channel_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log("api checks", response.data.comment_section);
        var result = response?.data?.Channel;
        setOrder(result);
        setEditUser(result);
        // console.log("response", response);
        console.log("result", result);
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
     };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: Channel_Partner_id,
      channel_name: editUser.channel_name,
      channel_slug: editUser.channel_slug,
      email: editUser.email,
      password: editUser.password,
      ccode: editUser.ccode,
      mobile_number: editUser.mobile_number,
      channel_image: editUser.channel_image,
      channel_banner: editUser.channel_banner,
      channel_about: editUser.channel_about,
      channel_logo: editUser.channel_logo,
      status: editUser.status,
      unhased_password: editUser.unhased_password,
      activation_code: editUser.activation_code,
      bank_name: editUser.bank_name,
      branch_name: editUser.branch_name,
      account_number: editUser.account_number,
      IFSC_Code: editUser.IFSC_Code,
      cancelled_cheque: editUser.cancelled_cheque,
      upi_id: editUser.upi_id,
      upi_mobile_number: editUser.upi_mobile_number,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/Bank-setting-update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {

      const formDatas = new FormData();

      formDatas.append("Channel_Partner_id", Channel_Partner_id);
      formDatas.append("image", image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Cancelled-Cheque-Image`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });

    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="moderator-container">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> My Profile
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />
                    <form
                      method="POST"
                      action=""
                      accept-charset="UTF-8"
                      file="1"
                      enctype="multipart/form-data"
                      id="Moderator_form"
                    >
                      <div className="row justify-content-between p-3">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              User Name:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="channel_name"
                              value={editUser?.channel_name}
                              onChange={handleInput}
                              autocomplete="channel_name"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                              Email:
                            </label>
                            <input
                              id="email"
                              type="email"
                              className="form-control "
                              name="email"
                              value={editUser?.email}
                              onChange={handleInput}
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              Phone Number:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control"
                              name="mobile_number"
                              value={editUser?.mobile_number}
                              onChange={handleInput}
                              autocomplete="mobile_number"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="image"
                              className=" col-form-label text-md-right"
                            >
                              Cancelled Cheque:
                            </label>
                            <div>
                              <img
                                src={editUser?.cancelled_cheque_url}
                                alt="cheque"
                                width={200}
                              />
                            </div>
                            <input
                              id="image"
                              type="file"
                              className="form-control"
                              onChange={(e) => setImage(e.target.files[0])}
                              name="image"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="upi_id"
                              upi_id=" col-form-label text-md-right"
                            >
                              UPI ID:
                            </label>
                            <input
                              id="upi_id"
                              type="number"
                              className="form-control "
                              name="upi_id"
                              value={editUser?.upi_id}
                              onChange={handleInput}
                              autocomplete="upi_id"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="upi_mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              UPI Phone Number:
                            </label>
                            <input
                              id="upi_mobile_number"
                              type="number"
                              className="form-control "
                              name="upi_mobile_number"
                              value={editUser?.upi_mobile_number}
                              onChange={handleInput}
                              autocomplete="upi_mobile_number"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="bank_name"
                              className=" col-form-label text-md-right"
                            >
                              Bank Name:
                            </label>
                            <input
                              id="bank_name"
                              type="text"
                              className="form-control"
                              name="bank_name"
                              value={editUser?.bank_name}
                              onChange={handleInput}
                              autocomplete="bank_name"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="branch_name"
                              className=" col-form-label text-md-right"
                            >
                              Branch Name:
                            </label>
                            <input
                              id="branch_name"
                              type="text"
                              className="form-control"
                              name="branch_name"
                              value={editUser?.branch_name}
                              onChange={handleInput}
                              autocomplete="branch_name"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="account_number"
                              className=" col-form-label text-md-right"
                            >
                              Account No:
                            </label>
                            <input
                              id="account_number"
                              type="number"
                              className="form-control "
                              name="account_number"
                              value={editUser?.account_number}
                              onChange={handleInput}
                              autocomplete="account_number"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="IFSC_Code"
                              className=" col-form-label text-md-right"
                            >
                              IFSC Code:
                            </label>
                            <input
                              id="IFSC_Code"
                              type="number"
                              className="form-control "
                              name="IFSC_Code"
                              value={editUser?.IFSC_Code}
                              onChange={handleInput}
                              autocomplete="IFSC_Code"
                            />
                          </div>
                        </div>

                        <div className="form-group row mb-0  text-end">
                          <div className="col-md-12 ">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleUpdate}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MyChannelSettings;
