import React from "react";
import { Link } from "react-router-dom";

function AdsPlan() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Ads Plans List</h4>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="btn btn-primary create_category"
                        >
                          Create Ads Plan
                        </Link>
                      </div>
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div>
                                <label>Plan Name</label>

                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Ads Plan Name"
                                  className="form-control rtmp_urls"
                                  //   onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                              <div>
                                <label>Plan Amount</label>

                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Ads Plan Amount"
                                  className="form-control rtmp_urls"
                                  //   onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                              <div>
                                <label>No of Ads view count </label>

                                <input
                                  type="text"
                                  name="name"
                                  placeholder="No of Ads view count"
                                  className="form-control rtmp_urls"
                                  //   onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="submit"
                                className="btn btn-primary ml-3 "
                                // onClick={handleSubmit}
                              >
                                Add Plan
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body table-responsive mt-3">
                      <div className="table-view">
                        <table className="table table-striped table-bordered table movie_table ">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>Plan Name</th>
                              <th>Plan Amount</th>
                              <th>No of Ads View Count</th>
                              <th>Created at</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="r1">
                              <td>1</td>
                              <td>plan_name </td>
                              <td>plan_amount </td>
                              <td>no_of_ads</td>
                              <td>created_at </td>
                              <td>
                                {/* <Link className="iq-bg-success editcategory" data-toggle="tooltip" data-placement="top" title=""
                                    data-original-title="Edit" d data-target='#edit_modal' data-id="{{ $ads_plan->id }}"></Link>
                                    <Link  onclick="return confirm('Are you sure?')" to="{{ URL::to('/admin/ads_plan_delete').'/'. $ads_plan->id }}" className="iq-bg-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete">
                                  </Link> */}

                                <td className="table-content">
                                  <Link to={"/admin/edit_ads_list"}>
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link>
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </td>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsPlan;
