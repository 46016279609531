
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { BiMenu } from 'react-icons/bi';
import "./TotalRevenue.css"

function TotalRevenue() {

  const series = [70]
  const options = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        }
      },
    },
    labels: ['PPV Payment'],
  }



  return (

    <>
      <div className=' m-0 p-0'>
        <div className=''>

          <section className="section container-fluid pt-3">

            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">Revenue System</h4>

                  </div>
                </div>
                <hr />
                <div className="row p-3">
                  <div className="col-md-12">
                    <div className="row p-3">
                        <div className='col-md-6'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div>
                            <label>PPV Payment</label>
                            </div>
                            
                          </div>
                          {/* <div className='text-end menu-icon'>
                            <BiMenu />
                            </div> */}
                      <div id="chart">
                        <ReactApexChart options={options} series={series} type="radialBar" height={350} />
                      </div>
                      </div>
                      <div className='col-md-6'>
                      <div className='d-flex align-items-center justify-content-center'>
                            <div>
                            <label>Subscription Payment</label>
                            </div>
                            
                          </div>
                          {/* <div className='text-end menu-icon'>
                            <BiMenu />
                            </div> */}
                      <div id="chart">
                        <ReactApexChart options={options} series={series} type="radialBar" height={350} />
                      </div>
                      </div>
                    </div>
                    
                  </div>
                </div>

              </div>
              <div>

              </div>

            </div>

          </section>
        </div></div>
    </>
  );
}

export default TotalRevenue;

