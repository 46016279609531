import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";

const EditLandingPages = (initialState) => {
  const editor = useRef(null);
  const [contents, setContents] = useState("");
  // console.log(contents, "con");
  const { id } = useParams();
  const navigate = useNavigate();
  const [editLanding, setEditLanding] = useState({
    landing_page_id: "",
    title: "",
    slug: "",
    custom_css: "",
    script_content: "",
    bootstrap_link: "",
    content: "",
    header: false,
    footer: false,
  });
  console.log(editLanding, "hhh");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const handleChangeSecond = (e) => {

  //   setContent({ ...content, [e.target.name]: e.target.value });

  // };

  console.log(editLanding, "editLanding");
  useEffect(() => {
    const getlanding = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.section_1[0];
      var results = resData?.section_1;
      setEditLanding(res);
      setIsChecked(res?.header);
      setIsChecked1(res?.footer);
      // console.log(res, "kkk");
    };
    getlanding();
  }, [id]);

  const handleInput = (e) => {
    setEditLanding({ ...editLanding, [e.target.name]: e.target.value });
  };

  const handleChecked = (event) => {
    setEditLanding({
      ...editLanding,
      header: event.value,
    });
    setIsChecked(!isChecked);
  };
  // console.log(isChecked, "!checking.....");

  const handleChecked1 = (event) => {
    setEditLanding({
      ...editLanding,
      footer: event.value,
    });
    setIsChecked1(!isChecked1);
  };
  // console.log(isChecked1, "!footer");

  const handleChangeFirst = (e) => {
    setEditLanding({ ...editLanding, content: e });
    // console.log(e, "onchnageddddd");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      landing_page_id: editLanding.landing_page_id,
      title: editLanding.title,
      slug: editLanding.slug,
      custom_css: editLanding.custom_css,
      script_content: editLanding.script_content,
      bootstrap_link: editLanding.bootstrap_link,
      header: isChecked === true ? 1 : 0,
      footer: isChecked1 === true ? 1 : 0,
      section_1: editLanding.content,
    };
    console.log(editInputvalue, "rrr");
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/landing-page/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();
    console.log(resjson, "tt");
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const config = {
    placeholder: "Start typings...",
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i> Landing Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">
                      <div className="col-md-5 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                              value={editLanding?.landing_page_id}
                              hidden
                            />
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                              value={editLanding?.title}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Slug</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                              value={editLanding?.slug}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    <div class="mb-3">
                      <label>Custom CSS :</label>
                      <textarea
                        class="form-control"
                        name="custom_css"
                        id="custom_css"
                        rows="3"
                        value={editLanding?.custom_css}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label>Bootstrap & Script Link :</label>
                      <textarea
                        class="form-control"
                        name="script_content"
                        id="script_content"
                        rows="3"
                        value={editLanding?.bootstrap_link}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label>Custom Script Content :</label>
                      <textarea
                        class="form-control"
                        name="bootstrap_link"
                        id="bootstrap_link"
                        rows="3"
                        value={editLanding?.script_content}
                        onChange={handleInput}
                      ></textarea>
                    </div>

                    <div class="mb-3 mt-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Header & Footer :
                      </label>
                      <div>
                        <label> Header</label>
                        <div className="mt-3">
                          <label className="text-danger">Disable </label>
                          <label className="switch">
                            <input
                              name="header"
                              type="checkbox"
                              value={editLanding?.header}
                              checked={isChecked}
                              onChange={handleChecked}
                            />

                            <span className="slider round" name="active"></span>
                          </label>
                          <label className="text-success">Enable</label>
                        </div>

                        <label> Footer</label>
                        <div className="mt-3">
                          <label className="text-danger">Disable</label>
                          <label className="switch">
                            <input
                              name="footer"
                              value={editLanding?.footer}
                              type="checkbox"
                              checked={isChecked1}
                              onChange={handleChecked1}
                            />
                            <span
                              className="slider round"
                              name="active"
                              // value={item.id}
                            ></span>
                          </label>
                          <label className="text-success">Enable</label>
                        </div>
                      </div>
                    </div>

                    <div>
                      {/* <div className="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Section 1
                            <TbPlus />
                          </label>
                        </div>
                      </div> */}

                      <div className="row d-flex align-items-center my-3">
                        <label>Section</label>
                        <div
                          className="panel panel-primary col-md-10"
                          data-collapsed="0"
                        >
                          <div className="panel-title mt-3">
                            <label>Page Content</label>
                          </div>
                          <div className="panel-body mt-3">
                            <JoditEditor
                              // ref={editor}
                              value={editLanding?.content}
                              //   onChange={(newContent) => setContent(newContent)}
                              // onChange={handleInput}

                              onChange={(e) => {
                                handleChangeFirst(e);
                              }}
                            />
                          </div>
                          {/* <div className="panel-body mt-3">
                        <textarea
                          className="form-control"
                          name="body"
                          id="summary-ckeditor"
                          onChange={handleInput}
                          value={editLanding.section_1}
                        ></textarea>

                        <JoditEditor value={editLanding.section_1} 
                        />
                      </div> */}
                        </div>
                        <div className="col-md-2">
                          <RiDeleteBin6Line />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary pull-right"
                          onClick={handleUpdate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditLandingPages;
