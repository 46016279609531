import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./AllLiveStream.css";
import Select from "react-select";

function AllLiveStream() {
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);
  const navigate = useNavigate();

  const [editUser, setEditUser] = useState({ status: "", livestream_id: "" });
  const [message, setMessage] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/live-stream/list`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.LiveStream?.data;
        var results = response?.data?.LiveStream?.data[0]?.id;
        setData(result);
        // console.log(results)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (id, e) => {
    e.preventDefault();
    console.log(id);
    alert(id);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    const editInputvalue = {
      status: editUser.status,
      livestream_id: editUser.livestream_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/live-stream/livestream-slider/` +
        data.id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/destroy/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log("next", res?.data?.LiveStream);
        setNext(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-live-stream container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card text-center">
                <div className="card-body">
                  <Table>
                    <thead>
                      <tr className="title">
                        {/* <th>Select All</th> */}
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>User Name</th>
                        <th>Video Type</th>
                        <th>Video Access</th>
                        <th>Status</th>
                        <th>Slider</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {data?.map((item, key) => (
                      <thead key={item?.id}>
                        <tr className="text-center">
                          {/* <td><input type="checkbox" id="" name="" value="" /></td> */}
                          <td key={key}> {key + 1} </td>
                          <td className="">
                            <img
                              src={item?.Video_thumbnail}
                              className="img-fluid img-thumbnail img-manage"
                              alt="image"
                            />
                          </td>
                          <td>{item?.title}</td>
                          <td>{item?.id}</td>
                          <td>{item?.publish_type}</td>
                          <td>{item?.access}</td>
                          <td>{item?.active}</td>
                          {/*
                          <td>
                            <label className="switch">
                            <input type="text" value={setLivestream_id ? 1 : ''} onChange={e => setLivestream_id(e.target.value)} />
                          </label> 
                            <input
                              type="checkbox"
                              className="form-group" value={item.id}
                              onChange={e => setLivestream_id(e.target.value)}
                              placeholder="banner"
                            />
                            <input
                              type="checkbox" value={item.banner}
                              className="form-group"
                              onChange={e => setBanner_status(e.target.value)}
                              placeholder="banner"
                            />
                            <button onClick={stream}></button>
                            </td>
                          */}

                          <td>
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleUpdate}
                                value={
                                  editUser?.status === 1 ||
                                  editUser?.status === null
                                    ? "0"
                                    : editUser?.status === 1
                                    ? "0"
                                    : "1"
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleUpdate}
                                onClick={() => handleUpdate(item?.id)}
                                value={
                                  editUser?.status === 1 ||
                                  editUser?.status === null
                                    ? "0"
                                    : editUser?.status === 1
                                    ? "0"
                                    : "1"
                                }
                              ></span>
                            </label>
                            {/* <label className="switch">
                              <input name="enable_restream" className={ isOff ? item.status : null } onClick={() => setIsOff(!isOff)} value={ isOff } type="checkbox" />
                              <span className="slider round"></span>
                            </label> */}
                          </td>
                          <td>
                            <Link to="" className="view">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                            <Link
                              to={"/admin/edit/add-livestream/" + item?.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item?.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </Table>

                  <div className="row d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{page}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AllLiveStream;
