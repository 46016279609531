import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./EditNewArtist.css";

function EditNewArtist() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [artisttype, setArtisttype] = useState([]);
  const [editUser, setEditUser] = useState({
    artist_name: "",
    artist_slug: "",
    description: "",
    artist_type: "",
    image: "",
    Thumbnail: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");

  // console.log(editUser)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/artist/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.artist;
      setEditUser(res);
      // console.log(resData)
      console.log(res);
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/artist/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.artist_type;
        setArtisttype(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      artist_name: editUser.artist_name,
      artist_slug: editUser.artist_slug,
      description: editUser.description,
      artist_type: editUser.artist_type,
      Thumbnail: editUser.Thumbnail,
    };
    console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/artist/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson.arti)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");

      var result = resjson.artist_id;
      
      const formDatas = new FormData();

      formDatas.append("image", image);
      formDatas.append("artist_id", result);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-artist`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });

      setTimeout(() => {
        //   navigate('/admin/livestream');
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="admin-section-title">
                  <h4 className="fs-title">Create Artist</h4>
                </div>
                <hr />
                <div className="f">
                  <div className="row mt-3 p-0">
                    <div className="col-sm-6 mt-3" data-collapsed="0">
                      <label className="m-0"> Artist </label>
                      <div className="panel-body">
                        <input
                          type="text"
                          placeholder="Artist Name"
                          className="form-control"
                          name="artist_name"
                          id="artist_name"
                          value={editUser?.artist_name}
                          onChange={handleInput}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 mt-3" data-collapsed="0">
                      <label className="m-0"> Artist Slug </label>
                      <div className="panel-body">
                        <input
                          type="text"
                          placeholder="Artist Slug"
                          className="form-control"
                          name="artist_slug"
                          id="artist_slug"
                          value={editUser?.artist_slug}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 p-0 align-items-center">
                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Artist Description</label>
                    <div className="panel-body">
                      <textarea
                        className="form-control"
                        placeholder="Artist Description"
                        name="description"
                        id="description"
                        value={editUser?.description}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Artist Type</label>
                    <div className="panel-body">
                      <select
                        value={editUser?.artist_type}
                        className="form-control"
                        name="artist_type"
                        id="artist_type"
                        onChange={handleInput}
                      >
                        {/* <option value={editUser.artist_type} > {editUser.artist_type} </option> */}
                        {artisttype?.map((item) => (
                          <option key={item?.id} value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Picture</label>
                    <p className="p1">
                      Select the artist image (300x300 px or 2:2 ratio):
                    </p>
                    <div className="panel-body">
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="image"
                        id="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <div className="panel-body">
                      {/* <img src={data.Thumbnail} className="movie-img" width="200" /> */}
                    </div>
                  </div>
                </div>

                <div className=" p-0 mt-4 d-flex justify-content-end">
                  <input
                    type="submit"
                    defaultValue="Update"
                    onClick={handleUpdate}
                    className="btn btn-primary mr-2"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default EditNewArtist;
