import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function StorageSettings() {
  // const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    site_storage: "",
    aws_storage: "",
    aws_access_key: "",
    aws_secret_key: "",
    aws_region: "",
    aws_bucket: "",
    aws_storage_path: "",
    aws_video_trailer_path: "",
    aws_season_trailer_path: "",
    aws_episode_path: "",
    aws_live_path: "",
    aws_audio_path: "",
    aws_transcode_path: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/storage_index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Storage_setting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      // setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      // setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      site_storage: editUser.site_storage,
      aws_storage: editUser.aws_storage,
      aws_access_key: editUser.aws_access_key,
      aws_secret_key: editUser.aws_secret_key,
      aws_region: editUser.aws_region,
      aws_bucket: editUser.aws_bucket,
      aws_storage_path: editUser.aws_storage_path,
      aws_video_trailer_path: editUser.aws_video_trailer_path,
      aws_season_trailer_path: editUser.aws_season_trailer_path,
      aws_episode_path: editUser.aws_episode_path,
      aws_live_path: editUser.aws_live_path,
      aws_audio_path: editUser.aws_audio_path,
      aws_transcode_path: editUser.aws_transcode_path,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/storage_update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-0">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Storage Settings
                    </h4>{" "}
                    <hr />
                  </div>
                  <div className="clear"></div>
                  <form
                    method="POST"
                    action=""
                    accept-charset="UTF-8"
                    file="1"
                    enctype="multipart/form-data"
                    id="storage_settings"
                  >
                    <div className="col-sm-12">
                      <div className="row mt-3 mb-3">
                        <div className="col-sm-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              {" "}
                              <div className="panel-title">
                                <label>Site Storage</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body row mt-3">
                              <div className="form-group col-md-4">
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  {/* <label className="switch">
                                                                        <input name="site_storage" onChange={handleInput} value={(editUser.site_storage === 1 || editUser.site_storage === null) ? '0' : (editUser.site_storage === 1) ? '0' : '1'} type="checkbox" />
                                                                        <span className="slider round" name="site_storage" onChange={handleInput} value={(editUser.site_storage === 1 || editUser.site_storage === null) ? '0' : (editUser.site_storage === 1) ? '0' : '1'} ></span>
                                                                    </label> */}
                                  <label className="switch">
                                    <input
                                      name="site_storage"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.site_storage === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.site_storage === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="site_storage"
                                      onChange={handleInput}
                                      value={
                                        editUser?.site_storage === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              {" "}
                              <div className="panel-title">
                                <label>AWS Storage</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body row mt-3">
                              <div className="form-group col-md-4">
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  {/* <label className="switch">
                                                                        <input name="aws_storage" onChange={handleInput} value={(editUser.aws_storage === 1 || editUser.aws_storage === null) ? '0' : (editUser.aws_storage === 1) ? '0' : '1'} type="checkbox" />
                                                                        <span className="slider round" name="aws_storage" onChange={handleInput} value={(editUser.aws_storage === 1 || editUser.aws_storage === null) ? '0' : (editUser.aws_storage === 1) ? '0' : '1'} ></span>
                                                                    </label> */}
                                  <label className="switch">
                                    <input
                                      name="aws_storage"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.aws_storage === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.aws_storage === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="aws_storage"
                                      onChange={handleInput}
                                      value={
                                        editUser?.aws_storage === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3" id="aws_details">
                        <div className="col-sm-6 mt-3">
                          <label className="">AWS ACCESS KEY </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_access_key"
                              id="aws_access_key"
                              onChange={handleInput}
                              value={editUser?.aws_access_key}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">AWS SCRETE KEY </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_secret_key"
                              id="aws_secret_key"
                              onChange={handleInput}
                              value={editUser?.aws_secret_key}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">AWS REGION </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_region"
                              id="aws_region"
                              onChange={handleInput}
                              value={editUser?.aws_region}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">AWS BUCKET </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_bucket"
                              id="aws_bucket"
                              onChange={handleInput}
                              value={editUser?.aws_bucket}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Video Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_storage_path"
                              id="aws_storage_path"
                              onChange={handleInput}
                              value={editUser?.aws_storage_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Video Trailer Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_video_trailer_path"
                              id="aws_video_trailer_path"
                              onChange={handleInput}
                              value={editUser?.aws_video_trailer_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Season Trailer Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_season_trailer_path"
                              id="aws_season_trailer_path"
                              onChange={handleInput}
                              value={editUser?.aws_season_trailer_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Season Episode Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_episode_path"
                              id="aws_episode_path"
                              onChange={handleInput}
                              value={editUser?.aws_episode_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Live Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_live_path"
                              id="aws_live_path"
                              onChange={handleInput}
                              value={editUser?.aws_live_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Storage Audios Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_audio_path"
                              id="aws_audio_path"
                              onChange={handleInput}
                              value={editUser?.aws_audio_path}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 mt-3">
                          <label className="">
                            AWS Transcode Path (Ex: /path/to ){" "}
                          </label>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="aws_transcode_path"
                              id="aws_transcode_path"
                              onChange={handleInput}
                              value={editUser?.aws_transcode_path}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="_token" value=" " />

                    <div className="col-md-12 form-group" align="right">
                      <input
                        type="submit"
                        value="Update Settings"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default StorageSettings;
