import React from "react";
import "../Dashboard/Dashboard.css";
import { Link } from "react-router-dom";
import { AiOutlineCalendar } from "react-icons/ai";

function Dashboard() {
  return (
    <section>
      <div className=" m-0 p-0">
        <div className="">
          <div className="section dashboard p-2">
            <div className="row container-fluid">
              <div className="col-lg-4">
                <div className="row">
                  <div className="card info-card user-webnexs">
                    <div className="card-body">
                      <h5 className="card-title fw-bold text-center">
                        User's Of Webnexs
                      </h5>
                      <div className="d-flex align-items-center p-3">
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span className="small pt-1 fs-6 fw-bold">375</span>
                        </div>
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span> | </span>{" "}
                        </div>
                        <div className="col-lg-8">
                          {" "}
                          <span className="text-muted small fs-6 pt-2 ps-1">
                            Total Subscriber's
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-3">
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span className="small pt-1 fs-6 fw-bold">5</span>
                        </div>
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span> | </span>{" "}
                        </div>
                        <div className="col-lg-8">
                          {" "}
                          <span className="text-muted small fs-6 pt-2 ps-1">
                            New Subscriber's
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-3">
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span className="small pt-1 fs-6 fw-bold">75</span>
                        </div>
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span> | </span>{" "}
                        </div>
                        <div className="col-lg-8">
                          {" "}
                          <span className="text-muted small fs-6 pt-2 ps-1">
                            Total Video's
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-3">
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span className="small pt-1 fs-6 fw-bold">37</span>
                        </div>
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span> | </span>{" "}
                        </div>
                        <div className="col-lg-8">
                          {" "}
                          <span className="text-muted small fs-6 pt-2 ps-1">
                            Total Audio's
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-3">
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span className="small pt-1 fs-6 fw-bold">75</span>
                        </div>
                        <div className="col-lg-2 text-center">
                          {" "}
                          <span> | </span>{" "}
                        </div>
                        <div className="col-lg-8">
                          {" "}
                          <span className="text-muted small fs-6 pt-2 ps-1">
                            Total Visitor's
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="card p-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="card-title">Get Started</h5>
                        <p className="">You Are 3 Steps Away From Completion</p>
                      </div>
                      <div className="col-lg-6">
                        <p>Subscribed To Business</p>
                        <p>Package Ends On 2023-Apr-21</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex align-items-start">
                          <div
                            className="nav flex-column nav-pills me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              className="nav-link active"
                              id="v-pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-home"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              Upload your content
                            </button>
                            <button
                              className="nav-link"
                              id="v-pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected="false"
                            >
                              Setup Website Logo, Name, Social Links, Payment
                              Types, Etc
                            </button>
                            <button
                              className="nav-link"
                              id="v-pills-messages-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-messages"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-messages"
                              aria-selected="false"
                            >
                              Payment Plans
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active upload-video mt-4"
                            id="v-pills-home"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                          >
                            <h5>First things first, you need Video.</h5>
                            <div>Upload A Video To Get Started.</div>
                            <div className="mt-3">
                              <Link to="/admin/addnewvideo">
                                Upload Videos Now
                              </Link>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade upload-video mt-5"
                            id="v-pills-profile"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                          >
                            <div>
                              Setup Website Logo, Name, Social Links, Payment
                              Types, Etc.
                            </div>
                            <div className="mt-3">
                              <Link to="">Go to Storefront Settings</Link>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade upload-video mt-5"
                            id="v-pills-messages"
                            role="tabpanel"
                            aria-labelledby="v-pills-messages-tab"
                          >
                            <div>Setup Subscription Plans</div>
                            <div className="mt-3">
                              <Link to="">Add Subscription Plans</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-0 m-0">
              <div className="col-lg-3">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="iq-card-body1 p-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="iq-cart-text text-center text-capitalize">
                        <img className="ply" src="" />
                        <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                        <p className="mb-0 mt-3 fw-bold">view</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <h4 className=" mb-0">3.724k</h4>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        35%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="iq-card-body1 p-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="iq-cart-text text-center text-capitalize">
                        <img className="ply" src="" />
                        <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                        <p className="mb-0 mt-3 fw-bold">Visitors</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <h4 className=" mb-0">10</h4>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-up mr-2"></i>
                        </span>
                        100%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="iq-card-body1 p-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="iq-cart-text text-center text-capitalize">
                        <img className="ply" src="" />
                        <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                        <p className="mb-0 mt-3 fw-bold">view</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <h4 className=" mb-0">3.724k</h4>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        45%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="iq-card-body1 p-3">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="iq-cart-text text-center text-capitalize">
                        <img className="ply" src="" />
                        <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                        <p className="mb-0 mt-3 fw-bold">view</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <h4 className=" mb-0">3.724k</h4>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-up mr-2"></i>
                        </span>
                        95%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-3 m-0">
              <div className="col-md-12">
                <div>
                  <h3>Good Afternoon, Techweb!</h3>
                  <p>Welcome to Muvi One</p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h3>Overview</h3>
                  <div className="days-cards ms-2">
                    <p className="mb-0">
                      <AiOutlineCalendar /> Last 7 Days
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-0 m-0">
              <div className="col-lg-7">
                <div className="iq-card-body1">
                  <div className="row p-0 m-0">
                    <div className="col-md-6 ">
                      <div className="p-3 iq-cards">
                        <p>Revenue</p>
                        <h3>USD 0</h3>
                        <div className="progress-bar-vertical">
                          <div className="progress-now-vertical"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  ">
                      <div className="p-3 iq-cards">
                        <p>ARPU</p>
                        <h2>USD 0</h2>
                        <div className="progress-bar-vertical">
                          <div className="progress-now-vertical"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col-md-6  ">
                      <div className="p-3 iq-cards">
                        <p>Views</p>
                        <h3>0</h3>
                        <div className="progress-bar-vertical">
                          <div className="progress-now-vertical"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="p-3 iq-cards">
                        <p>Watch Duration</p>
                        <h2>00h 00m 00s</h2>
                        <div className="progress-bar-vertical">
                          <div className="progress-now-vertical"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className="section-con">
                    <div className="iq-card-body1 p-3">
                      <div className="row">
                        <div className="col-md-7">
                          <div>
                            <h5>Trending Content</h5>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="d-flex align-items-center justifiy-content-center">
                            <h5>By</h5>
                            <div>
                              <div className="dropdown">
                                <Link
                                  className="btn btn-outline-secondary dropdown-toggle"
                                  to="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Views
                                </Link>

                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
