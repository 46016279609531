import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

function LifeTimeSubcription() {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    status: "",
    name: "",
    price: "",
    devices: "",
    id: "",
  });
  const [devices, setDevices] = useState([]);
  const [devices2, setDevices2] = useState([]);
  const [data1, setData1] = useState("");
  const [deviceIds, setDeviceIds] = useState("");
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Life-time-subscription`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.AdminLifeTimeSubscription;
      const result = resData?.devices;
      setEditUser(res);
      setDevices(result);
      const deviceIds = res?.devices?.split(",");
      setDeviceIds(deviceIds);

      //

      // if (res ==== devices) {
      //   const data = result.map((item) => item.user_id);
      //   setDevices2(data);
      // } else {
      //   console.log("Error");
      // }
    };

    getUser();
  }, []);

  const handleInputs = (id) => {
    const deviceIds = editUser?.devices?.split(",");
    const index = deviceIds?.findIndex((val) => val.includes(id));
    if (index !== -1) {
      deviceIds?.splice(index, 1);
    } else {
      deviceIds?.push(id);
    }
    const devices = deviceIds?.length > 0 ? deviceIds?.join(",") : "";
    setEditUser({ ...editUser, devices });
  };

  // const handleInput = (e) => {
  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });

  //   if (e.target.checked === true) {
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: e.target.value,
  //       [e.target.name]: 1,
  //     });
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: e.target.value,
  //       [e.target.name]: true,
  //     });
  //   } else {
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: 0,
  //       [e.target.name]: e.target.value,
  //     });
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: false,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      device_id: editUser.id,
      name: editUser.name,
      user_id: editUser.user_id,
      price: editUser.price,
      status: editUser.status,
      devices: editUser.devices,
    };

    console.log(editInputvalue, "editInputvalue");
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Life-time-subscription/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        // navigate("/admin/age/index");
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };
  const myArray = [];
  const arrayvalue = myArray.filter((item) => item !== null).length === 0;
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i>Subscription Plan Update
              </h4>
              <hr />
              <div className="p-3">
                <div className="row">
                  <div className="col-md-6">
                    <form>
                      <div className="form-group ">
                        <label>Life Time Subscription Name</label>
                        <input
                          type="text"
                          name="name"
                          value={editUser?.name}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Life Time Subscription"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6 ">
                    <form>
                      <div className="form-group ">
                        <label>Life Time Subscription Price ($)</label>
                        <input
                          type="text"
                          name="price"
                          value={editUser?.price}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Life Time Subscription Price"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <div className="mt-2 d-flex align-items-center">
                      <label>Status</label>

                      <label className="switch">
                        <input
                          name="status"
                          onChange={handleInput}
                          defaultChecked={editUser?.status === 1 ? true : false}
                          checked={editUser?.status === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="status"
                          onChange={handleInput}
                          value={editUser?.status === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 ">
                    <div className="mt-3 row">
                      <label>Devices :</label>

                      {devices?.map((item, index) => {
                        const value = item?.devices_name;
                        const deviceIds = editUser?.devices?.split(",");
                        const devices = deviceIds?.filter((val) =>
                          val.includes(item?.id)
                        );
                        const array =
                          myArray.filter((item) => item !== null).length === 0;

                        return (
                          <div key={item?.id}>
                            <label>{value} :</label>
                            {/* <label>
                              {devices.length > 0 ? "Selected" : "Not selected"}{" "}
                              :
                            </label> */}
                            <label className="switch">
                              <input
                                name="devices"
                                onChange={() => handleInputs(item.id)}
                                checked={devices?.length > 0}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="devices"
                                onChange={() => handleInputs(item.id)}
                                value={devices?.length > 0}
                              ></span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary "
                      onClick={handleUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LifeTimeSubcription;
