import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import JoditEditor from "jodit-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Button from "@mui/material/Button";
import Select from "react-select";

export default function Manage_Episode() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();
  const { seriesid } = useParams();
  const [file, setFile] = useState("");
  const [episode_id, setEpisode_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [episode_description, setEpisode_description] = useState();
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [disk, setDisk] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [active, setActive] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [duration, setDuration] = useState("");
  const [age_restrict, setAge_restrict] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");
  const [free_content_duration, setFree_content_duration] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [episode_ads, setEpisode_ads] = useState("");
  const [ads_position, setAds_position] = useState("");
  const [video_duration, setVideo_duration] = useState("");
  const [editUser, setEditUser] = useState({ video_title: "", duration: "" });
  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
 const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [message, setMessage] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  const contentFieldChanageddescript = (data) => {
    setEpisode_description(data);
  };

  const handleInput = (e) => {
    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setFooter({ ...footer, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
    }
  };


  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
        { headers: headers }
      );
      setBlock_Country(response.data.Block_Country);
      setAvailable_country(response.data.Available_country);
      setRelated_videos(response.data.Related_videos);
      setLanguage(response.data.video_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response.data.video_artist);
      setCategory(response.data.video_category);
      setInputValueAPI(response.data.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      // setAlbum(response?.data?.audio_albums);

      var age_restrictdata = response.data.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      //  console.log(response.data);
      console.log(response.data.video_artist);
      console.log(response);
      // console.log(response.data.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/upload`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("Channel_Partner_id", Channel_Partner_id);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);

      responses.forEach((response, index) => {
        setEditUser(response.data.Episode_id);
        setEpisode_id(response.data.Episode.title);
        setVideo_duration(response.data.Episode.duration);
      });
      alert("All videos uploaded successfully!");

      const uploadedDetails = files.map((file, index) => ({
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
      }));

      setUploadedFiles(uploadedDetails);
      setUploadCompleted(true);
      setUploadProgress(0);
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function Manageepisode() {
    // console.log('formData', formData)

    const editorepisode_description = episode_description;
    var editorepisode_descriptiondata = JSON.stringify(
      editorepisode_description
    );
    var editorepisode_descriptionsubmit = editorepisode_descriptiondata;

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvalueppv_status = ppv_status.ppv_status;
    var datappv_status = JSON.stringify(editInputvalueppv_status);
    var ppv_statusdata = datappv_status;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputviews = views.views;
    var dataviews = JSON.stringify(editInputviews);
    var viewsdata = dataviews;

    const editInputfooter = footer.footer;
    var datafooter = JSON.stringify(editInputfooter);
    var footerdata = datafooter;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputstatus = status.status;
    var datastatus = JSON.stringify(editInputstatus);
    var statusdata = datastatus;

    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    formData.append("episode_id", episode_id);
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("episode_description", editorepisode_descriptionsubmit);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("ppv_status", ppv_statusdata);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("mp4_url", mp4_url);
    formData.append("url", url);
    formData.append("path", path);
    formData.append("disk", disk);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    formData.append("active", aactivedata);
    formData.append("skip_recap", skip_recap);
    formData.append("skip_intro", skip_intro);
    formData.append("recap_start_time", recap_start_time);
    formData.append("recap_end_time", recap_end_time);
    formData.append("intro_start_time", intro_start_time);
    formData.append("intro_end_time", intro_end_time);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("footer", footerdata);
    formData.append("duration", duration);
    
    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "available_country_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    formData.append("age_restrict", age_restrict);
    formData.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option.value)
      )
    );

    formData.append("views", viewsdata);
    formData.append("rating", rating);
    formData.append("status", statusdata);
    formData.append("free_content_duration", free_content_duration);
    formData.append("search_tags", search_tags);
    formData.append("uploaded_by", uploaded_by);
    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);
    formData.append("episode_ads", episode_ads);
    formData.append("Channel_Partner_id", Channel_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log("result1", response);
          // console.log("result2", result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");

          var episode_id = response.data.Episode_id;
          var resssss = response.data;
          // console.log("resssss", resssss);
          // console.log("episode_id", episode_id);

          const formDatas = new FormData();

          formDatas.append("episode_id", episode_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/list`,
        { Channel_Partner_id: Channel_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.Episodes?.data;
        setData(result);
        // console.log('result', result)
        // console.log("orders", result);
        // console.log("orders", result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/delete/` +
          id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          
          <div>
            <Dropzone
              accept="video/*"
              onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="draganddrop">
                    Drag and drop video files here, or click to select files
                  </p>
                </div>
              )}
            </Dropzone>
            <div className="text-center">
              {uploadProgress > 0 && (
                <div>
                  <p>Uploading... {uploadProgress}%</p>
                  <progress value={uploadProgress} max="100" />
                </div>
              )}

              {uploadCompleted && (
                <div>
                  {uploadedFiles.length > 0 && (
                    <div className="text-center">
                      <p>Uploaded Files:</p>
                      <ul>
                        {uploadedFiles.map((file, index) => (
                          <li key={index}>
                            {file.name} - {file.size} MB
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn btn-primary"
                  >
                    Proceed to Next
                  </Button>
                </div>
              )}
              
            </div>
            
          </div>

          <div onClick={() => {
                      handleNext();
                    }}>
            Proceed to Next
          </div>

          <div>
            <div className="row d-flex ">
              <div className="col-md-10 offset-md-2 mt-2"> </div>{" "}
            </div>

            <div className="panel-body mt-2">
              <div id="nestable" className="nested-list dd with-margins">
                <table
                  className="table table-bordered iq-card text-center"
                  id="categorytbl"
                >
                  <tr className="table-header r1">
                    <th>
                      <label>Episode</label>
                    </th>
                    <th>
                      <label>Episode Name</label>
                    </th>
                    <th>
                      <label>Slider</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th>
                      <label>Action</label>
                    </th>
                  </tr>

                  {data?.map((item) => (
                    <tr>
                      <td className="">
                        <img src={item?.Thumbnail} width="200" alt="" />
                      </td>
                      <td className="edit_genre">
                        <p>{item?.title}</p>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            name="ppv_status"
                            value={
                              item?.ppv_status === 1 ||
                              item?.ppv_status === null
                                ? "0"
                                : item?.status === 1
                                ? "0"
                                : "1"
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="ppv_status"
                            value={
                              item?.ppv_status === 1 ||
                              item?.ppv_status === null
                                ? "0"
                                : item.status === 1
                                ? "0"
                                : "1"
                            }
                          ></span>
                        </label>
                      </td>
                      <td className="text-center">
                        {item?.draft === 0 || item?.draft === null ? (
                          <td className="bg-warning video_active">Draft</td>
                        ) : item?.draft === 1 &&
                          item?.status === 1 &&
                          item?.active === 1 ? (
                          <td className="bg-success video_active">Approved</td>
                        ) : (
                          <td className="bg-warning video_active">Draft</td>
                        )}
                      </td>
                      <td className="edit_genre">
                        <Link
                          to={"/admin/edit-episode/video-manage/" + item?.id}
                          className="edit ms-1"
                        >
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                          Edit Video
                        </Link>
                        <Link
                          to={"/channel/edit-episode/manage/" + item?.id}
                          className="edit ms-1"
                        >
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                          Edit
                        </Link>
                        <Link to="" className="delete ms-1">
                          <span onClick={() => deleteOperation(item?.id)}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <div id="episode_video_data" className="episode_video_data">
            <div>
              <div className="row mt-4">
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Title</label>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes title in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Title"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Slug</label>
                      </div>
                      <div className="panel-options">
                        <Link href="#" data-rel="collapse">
                          <i className="entypo-down-open"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes slug in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Slug"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-3">
                                <div className="panel panel-primary" data-collapsed="0">
                                    <div className="panel-heading">
                                        <div className="panel-title">Created Date</div>
                                        <div className="panel-options">
                                            <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link>
                                        </div>
                                    </div>
                                    <div className="panel-body" >
                                        <p>Select Date/Time Below</p>
                                        <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="@if(!empty($episodes->created_at)){{ $episodes->created_at }}@endif" />
                                    </div>
                                </div>
                            </div> */}
              </div>

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Episode Image Cover</label>
                      </div>
                    </div>
                    <div className="panel-body">
                      <p className="p1">
                        Select the episodes image (1080 X 1920px or 9:16 ratio):
                      </p>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="image"
                        id="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <label className="m-0">Episode Player Image</label>
                  <p className="p1">
                    Select the player image ( 1280 X 720px or 16:9 Ratio )
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>

                {/* <div className="col-sm-6 mt-3">
                  <label className="m-0">Episode TV Image</label>
                  <p className="p1">
                    Select the player image ( 16:9 Ratio or 1920 X 1080 px)
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="tv_image"
                      id="tv_image"
                      onChange={(e) => setTv_image(e.target.files[0])}
                    />
                  </div>
                </div> */}

                <div className="row mt-4 mb-3">
                  <div className="col-sm-12">
                    <label className="m-0"> Episode Description </label>
                    <p className="p1">
                      {" "}
                      Add a description of the Episode below:{" "}
                    </p>
                    <div className="panel-body">
                      <textarea
                        className="form-control description_editor"
                        name="episode_description"
                        onChange={(e) => setEpisode_description(e.target.value)}
                        id="description_editor"
                      >
                        {" "}
                      </textarea>

                      <JoditEditor
                        // ref={editor}
                        value={episode_description}
                        onChange={(newContent) =>
                          contentFieldChanageddescript(newContent)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Episode Ratings</label>
                        </div>
                      </div>
                      <div className="panel-body p-0">
                        <p className="p1">IMDb Ratings 10 out of 10</p>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <img alt="dfghj" />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Age Restrict :</h6>
                    </label>
                    <select
                      className="form-control"
                      id="age_restrict"
                      name="age_restrict"
                      onChange={(e) => setAge_restrict(e.target.value)}
                    >
                      <option selected disabled="">
                        Choose Age
                      </option>
                      {age_restrictdata.map((item) => (
                    <option selected value={item.id}>
                      {item.slug}
                    </option>
                  ))}
                    </select>
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Search Tags :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="tag-input1"
                        name="searchtags"
                        onChange={(e) => setSearch_tags(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Ads Position:</h6>
                    </label>
                    <input
                      name="ads_position"
                      className="form-control"
                      onChange={(e) => setAds_position(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Episode Ads :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="episode_ads"
                        name="episode_ads"
                        onChange={(e) => setEpisode_ads(e.target.value)}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Intro Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_intro"
                          id="skip_intro"
                          onChange={(e) => setSkip_intro(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_start_time"
                          id="intro_start_time"
                          onChange={(e) => setIntro_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_end_time"
                          id="intro_end_time"
                          onChange={(e) => setIntro_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Recap Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_recap"
                          id="skip_recap"
                          onChange={(e) => setSkip_recap(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_start_time"
                          id="recap_start_time"
                          onChange={(e) => setRecap_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_end_time"
                          id="recap_end_time"
                          onChange={(e) => setRecap_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-start mt-4">
                  <div className="col-sm-6">
                    <label className="m-0">Choose Ads Position</label>

                    <div className="">
                      {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                      <Select
                        options={adsPositions}
                        onChange={(e) => handleAdsPositionChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label className="m-0">Choose Advertisement</label>

                    <div className="">
                      <Select
                        options={secondSelectOptions}
                        onChange={(e) => setSecondSelectOptions(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Duration</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          Enter the episode duration in the following format
                          (Hours : Minutes : Seconds)
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          name="duration"
                          id="duration"
                          onChange={(e) => setDuration(e.target.value)}
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 ">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>User Access</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          Who is allowed to view this episode?
                        </p>

                        <Select
                          options={userAccesses}
                          id="access"
                          name="access"
                          onChange={(e) => handleAccessChange(e)}
                          className="abcd form-control"
                        />
                        {/* <select
                          id="access"
                          name="access"
                          className="form-control"
                          onChange={(e) => setAccess(e.target.value)}
                        >
                          <option value="guest">Guest (everyone)</option>
                          <option value="registered"></option>
                          <option value="subscriber"></option>
                          <option value="ppv">PPV Users (Pay per movie)</option>
                          <option value="ppv">PPV Users (Pay per movie)</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                </div>

                {userAccessesValue?.value === "ppv" ? (
                  <>
                    <div class="row mt-3 text-start ">
                      <div class="col-sm-4">
                        <label class="m-0">PPV Price</label>
                        <p class="p1">
                          Apply PPV Price from Global Settings?
                          <input
                            type="checkbox"
                            name="ppv_gobal_price"
                            id="ppv_gobal_price"
                            checked={checkboxChecked}
                            onChange={handleCheckboxChange}
                          />
                        </p>

                        <div class="panel-body">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="PPV Price"
                            name="ppv_price"
                            id="price"
                            value={inputValue}
                            onChange={handleInputChange}
                          />
                          <div class="clear"></div>
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <label class="m-0"> IOS PPV Price</label>
                        <p class="p1">
                          Apply IOS PPV Price from Global Settings?
                        </p>
                        <div class="panel-body form-control">
                          <Select
                            options={ios_ppv_price}
                            onChange={(e) => setIos_ppv_price(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="fs-5">Status Settings</label>
                        </div>
                      </div>

                      <div className="panel-body">
                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Featured:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="featured"
                                onChange={handleInput}
                                defaultChecked={
                                  featured?.featured === 1 ? true : false
                                }
                                checked={
                                  featured?.featured === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="featured"
                                onChange={handleInput}
                                value={featured?.featured === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Status:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  status?.status === 1 ? true : false
                                }
                                checked={status?.status === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={status?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">Is this episode Views:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="views"
                                onChange={handleInput}
                                defaultChecked={
                                  views?.views === 1 ? true : false
                                }
                                checked={views?.views === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="views"
                                onChange={handleInput}
                                value={views?.views === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="active">Is this episode Active:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="active"
                                onChange={handleInput}
                                defaultChecked={
                                  active?.active === 1 ? true : false
                                }
                                checked={active?.active === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="active"
                                onChange={handleInput}
                                value={active?.active === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="banner">
                              Is this episode display in Banner:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="banner"
                                onChange={handleInput}
                                defaultChecked={
                                  banner?.banner === 1 ? true : false
                                }
                                checked={banner?.banner === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="banner"
                                onChange={handleInput}
                                value={banner?.banner === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="footer">
                              Is this episode display in Footer:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="footer"
                                onChange={handleInput}
                                defaultChecked={
                                  footer?.footer === 1 ? true : false
                                }
                                checked={footer?.footer === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="footer"
                                onChange={handleInput}
                                value={footer?.footer === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <input
                    type="submit"
                    id="submit"
                    value="Create Episode"
                    className="btn btn-primary pull-right"
                    onClick={Manageepisode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add New Episode</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepContent>
                          <Typography>{step?.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index ==== 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index ==== 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
