import React from "react";
import { Link } from "react-router-dom";

function SeeLogActivity() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="iq-card">
              <div className="iq-card-header  justify-content-between">
                <div className="iq-header-title p-0">
                  <h4>Log Activity Lists</h4>
                </div>
              </div>

              <hr />
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table" id="logtable">
                        <thead>
                          <tr className="r1">
                            <th>No</th>
                            <th>Subject</th>

                            <th>User Agent</th>

                            <th>User Name</th>
                            <th>Video Title</th>
                            <th>Video Category</th>
                            <th>Video Language</th>
                            <th>Video Cast Crew</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>

                            <td className="text-danger"></td>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SeeLogActivity;
