import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function SeriesChannelPartner() {
  const [series, setSeries] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios

      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/series-pending`,
        {
          headers: headers,
        }
      )

      .then((getData) => {
        setSeries(getData?.data?.pending_Series?.data);
      });
  };

  const rejected = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/reject-series/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approved = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/approve-series/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/series-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_Series?.data);
        setSeries(res?.data?.pending_Series?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/series-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_Series?.data);
        setSeries(res?.data?.pending_Series?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/series-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.pending_Series?.data);
        setSeries(res?.data?.pending_Series?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <div className="iq-header-title">
                      <h4 className="card-title p-3">
                        Channel Series Video Lists
                      </h4>
                    </div>
                  </div>{" "}
                  <hr />
                  <div className="iq-card-body table-responsive p-0">
                    <div className="table-view p-2">
                      <table className="table table-striped table-bordered table movie_table iq-card text-center">
                        <thead>
                          <tr className="r1">
                            <th>Title</th>
                            <th>User Name</th>
                            <th>Year</th>
                            <th>Video Access</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {series?.map((item) => (
                            <tr>
                              <td key={item?.id} className="table-content">
                                <p>{item?.title}</p>
                              </td>
                              <td className="table-content">
                                <p>{item?.username}</p>
                              </td>
                              <td className="table-content">
                                <p>{item?.year}</p>
                              </td>
                              <td className="table-content">
                                <p>{item?.access}</p>
                              </td>
                              <td className="text-center">
                                {item?.ppv_status === 0 ||
                                item?.ppv_status === null ? (
                                  <td className="bg-warning video_active">
                                    Pending Approved Rejected
                                  </td>
                                ) : item?.ppv_status === 1 &&
                                  item?.ppv_status === 1 &&
                                  item?.ppv_status === 1 ? (
                                  <td className="bg-success video_active">
                                    Approved
                                  </td>
                                ) : (
                                  <td className="bg-warning video_active">
                                    Draft
                                  </td>
                                )}
                              </td>
                              <td colSpan="2">
                                <div className="flex align-items-center list-user-action fs-5">
                                  <Link
                                    className="iq-bg-warning"
                                    to=""
                                    onClick={() => approved(item?.id)}
                                  >
                                    <i className="fa fa-check-circle"></i>
                                  </Link>
                                  <Link
                                    className="iq-bg-success ms-2"
                                    to=""
                                    onClick={() => rejected(item?.id)}
                                  >
                                    <i className="fa fa-close"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="row text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClick}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{page}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClick}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SeriesChannelPartner;
