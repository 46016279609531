import { Link, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Creatable from "react-select/creatable";

function EditVideotag() {
  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-3 mb-5 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control"
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                      name="addvideo"
                      onClick={(event) => {
                        setEnable_restreamaddvideo(event.target.name);
                      }}
                    />
                  </p>
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>

              {/* <input className="form-check-input ms-5" type="radio" name="addvideo" id="addvideo"
                  value='1' onClick={(event) => { setEnable_restreamaddvideo(event.target.name); }} />
                <label className="form-check-label ms-1" for="flexRadioDefault1">
                  Video Upload
                </label> */}

              {/* <div className='mb-5'>
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
  
              </div> */}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    placeholder="m3u8 Trailer"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn btn-primary">Proceed to Update </button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <button className="btn btn-primary">Proceed to Update </button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn btn-primary">Proceed to Update </button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-3 mb-5">
              <button className="btn btn-primary">Proceed to Update </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card addvideo">
                    <div className="iq-card-header">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Tag Video</h4>
                        <hr />
                        <div>
                          <div
                            onClick={(event) => {
                              setEnablestreamvisible(event.target.value);
                            }}
                          >
                            {proceednext()}
                          </div>
                          <div>
                            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
                    <input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
                    <input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

                            <div className="row d-flex">
                              <div className="col-md-12 offset-md-2 mt-3">
                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="1"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault1"
                                >
                                  Video Upload
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  value="m3u8_url"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault2"
                                >
                                  m3u8 Url
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="mp4_url"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault3"
                                >
                                  Video mp4
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="embed"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault3"
                                >
                                  Embed Code
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditVideotag;
