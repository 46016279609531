import React from "react";
import { Link } from "react-router-dom";

function Player() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div className="">
            <div className="iq-card">
              <div id="admin-container">
                <div className="admin-section-title">
                  <h4 className=" card-title pt-3">
                    <i className="entypo-globe"></i> Player Settings
                  </h4>
                </div>
                <hr />
                <form
                  method="POST"
                  action=""
                  accept-charset="UTF-8"
                  file="1"
                  enctype="multipart/form-data"
                >
                  <input type="hidden" name="_token" defaultValue="" />
                  <section className="">
                    <h2></h2>
                    <div className="row align-items-center" id="pda">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label> Show logos on player</label>
                          </div>
                          <div>
                            <label className="switch">
                              <input type="hidden" defaultValue="0" name="show_logo" />
                              <input type="checkbox" name="show_logo" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label className="mb-0">
                              Allow Embed player on specfic domians
                            </label>
                            <p className="p1 ">
                              (Note: Saving without a domain name will restrict
                              your embed player for all the domains.)
                            </p>
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="hidden"
                                defaultValue="0"
                                name="embed_player"
                              />

                              <input type="checkbox" name="embed_player" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>Add Watermark on the player</label>
                          </div>
                          <div>
                            <label className="switch">
                              <input type="hidden" defaultValue="0" name="watermark" />

                              <input type="checkbox" name="watermark" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>Thumbnails on seekbar</label>
                          </div>
                          <div>
                            <label className="switch">
                              <input type="hidden" defaultValue="0" name="thumbnail" />

                              <input type="checkbox" name="thumbnail" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 Section2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label className="mb-0">Skip intro</label>
                            <p className="p1 col-md-8 p-0">
                              (Allows end users to skip some portion of thr
                              video opening credits)
                            </p>
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="hidden"
                                defaultValue="0"
                                name="skip_intro"
                              />

                              <input type="checkbox" name="skip_intro" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>Playback speed control</label>
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="hidden"
                                defaultValue="0"
                                name="speed_control"
                              />

                              <input type="checkbox" name="speed_control" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <label>
                              Advanced player{" "}
                              <span className="span1">
                                (Allows you to change add html code)
                              </span>
                            </label>
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="hidden"
                                defaultValue="0"
                                name="advance_player"
                              />

                              <input type="checkbox" name="advance_player" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div>
                            <label>Enable video cards</label>
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="hidden"
                                defaultValue="0"
                                name="video_card"
                              />

                              <input type="checkbox" name="video_card" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-3">
                      <h4 className="card-title">Subtitle option</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label>Subtitle on as Default</label>
                        </div>
                        <div>
                          <label className="switch">
                            <input type="hidden" defaultValue="0" name="subtitle" />

                            <input type="checkbox" name="subtitle" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label>Remeber subtitle preferences</label>
                        </div>
                        <div>
                          <label className="switch">
                            <input
                              type="hidden"
                              defaultValue="0"
                              name="subtitle_preference"
                            />

                            <input type="checkbox" name="subtitle_preference" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="card-title p-3">
                        Video Player Watermark Settings
                      </h4>
                    </div>
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div>
                          <p> Right:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermark_right"
                              id="watermark_right"
                              defaultValue=""
                            />
                          </div>
                        </div>
                        <div>
                          <p> Top:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermark_top"
                              id="watermark_top"
                              defaultValue=""
                            />
                          </div>
                        </div>
                        <div>
                          <p> Bottom:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermark_bottom"
                              id="watermark_bottom"
                              defaultValue=""
                            />
                          </div>
                        </div>
                        <div>
                          <p> Left:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermark_left"
                              id="watermark_left"
                              defaultValue=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <p> Opacity:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermark_opacity"
                              id="watermark_opacity"
                              defaultValue=""
                            />
                          </div>
                        </div>

                        <div>
                          <p> Link:</p>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="watermar_link"
                              id="watermar_link"
                              defaultValue=""
                            />
                          </div>
                        </div>

                        <div>
                          <div>
                            <p> Width:</p>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="watermar_width"
                                id="watermar_width"
                                defaultValue=""
                              />
                            </div>
                          </div>

                          <div>
                            <p>Upload Watermark:</p>
                            <input
                              type="file"
                              multiple="true"
                              className="form-control"
                              name="watermark_logo"
                              id="watermark_logo"
                            />
                          </div>
                          <div className="panel-heading col-md-10">
                            {" "}
                            <div className="panel-title"></div>
                            <div className="panel-options">
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bt p-3">
                        <button
                          name="save"
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                        <button name="" className="btn btn-primary">
                          Reset Default
                        </button>
                      </div>
                    </div>
                  </section>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Player;
