import React from "react";
import Header from "../../layout/Header/Header";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Dashboard from "../../Pages/Dashboard/Dashboard";

function MainPage() {
  return (
    <>
      <div className="row m-0 p-0">
        <div className="col-lg-3 p-0"> </div>
        <div className="col-lg-12 p-0">
          <div>
            <Dashboard />
          </div>
        </div>
      </div>
    </>
  );
}

export default MainPage;
