import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const EditManageLiveCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editCategory, setEditCategory] = useState({
    name: "",
    slug: "",
    in_menu: false,
    parent_id: "",
    image: "",
    image_url: "",
  });
  // console.log(editCategory, "ccc");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState([])
  const [isChecked, setIsChecked] = useState(false);

  console.log(isChecked, "option");

  const [message, setMessage] = useState("");

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getlanding = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.LiveCategory[0];
      var result = resData?.LiveCategory;
      setEditCategory(res);
      setIsChecked(res?.in_menu === "0" ? false : true);

      // console.log(res, "kkk");
    };
    getlanding();

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/index`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.LiveCategory;
        setCategory(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditCategory({ ...editCategory, [e.target.name]: e.target.value });
  };


  const handleChecked = (event) => {
    setEditCategory({
      ...editCategory,
      in_menu: event.value,
    });
    setIsChecked(!isChecked);
    console.log(isChecked, "!checking.....");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      name: editCategory.name,
      slug: editCategory.slug,
      parent_id: editCategory.parent_id,
      image: editCategory.image,
      in_menu: isChecked === true ? 1 : 0,
    };
    // console.log(editInputvalue, "rrr");
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/update/` +
        id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();
    console.log(resjson, "tt");
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");

      var asdf = resjson.LiveCategory.id;

      // console.log(id, "klk")
      console.log(asdf, "mmmm");

      const formDat = new FormData();

      formDat.append("id", asdf);
      formDat.append("image", image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/image`,
          formDat,
          {
            headers: headers,
          }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <div className="p-3">
                <div className="col-md-12 mb-3">
                  <label className="m-0"> Name:</label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Enter Name"
                    value={editCategory?.name}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label className="m-0"> Slug:</label>

                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    className="form-control"
                    placeholder="Enter Slug"
                    value={editCategory?.slug}
                    onChange={handleInput}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label>Display In Home page:</label>

                  {/* <div class="form-check  mt-2 ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="in_menu"
                      id="in_menu"
                      value={editCategory.in_menu}
                      value="yes"
                      checked={selectedOption ==== "yes"}

                    onChange={handleOptionChange}

                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Yes
                    </label>
                  </div>
                  <div class="form-check  mt-2 ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="in_menu"
                      id="in_menu"
                      value={editCategory.in_menu}
                      value="no"

                      checked={selectedOption ==== "no"}

                    onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      No
                    </label>
                  </div> */}

                  <div className="ms-3">
                    <label className="text-danger">No </label>
                    <label className="switch">
                      <input
                        name="in_menu"
                        // id="in_menu"
                        type="checkbox"
                        value={editCategory?.in_menu}
                        checked={isChecked}
                        onChange={handleChecked}
                      />

                      <span className="slider round" name="active"></span>
                    </label>
                    <label className="text-success">Yes</label>
                  </div>
                </div>
                <div className=" mt-3 form-group">
                  <div>
                    <label>Image:</label>
                  </div>
                  <img src={editCategory?.image_url} width={150} height={150} />

                  <input
                    type="file"
                    multiple="true"
                    className="form-control"
                    name="image"
                    id="image"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>

                <div className="form-group ">
                  <label>Category:</label>
                  <select
                    className="form-control"
                    onChange={handleInput}
                    name="parent_id"
                    id="parent_id"
                  >
                    <option value={0}>Select</option>
                    {category?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                {/* <div className="col-md-12 mb-3">
                  <label className="m-0">Category:</label>
                  <select id="in_name" name="in_name" className="form-control">
                    <option value="0">Select</option>
                    <option value="1">webnexs</option>
                    <option value="0">test</option>
                  </select>
                </div> */}
                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Upload"
                      className="btn btn-primary "
                      onClick={handleUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditManageLiveCategories;
