import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./SubscriptionPlan.css";

function SubscriptionPlan() {
  const [data, setData] = useState([]);
  const [subscriptionplan, setSubscriptionplan] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [plan_price, setPlan_price] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(purchase.plan_price)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/subscription/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.plans?.data;
        setData(result);
        setSubscriptionplan(result);
        // console.log(result)
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.plans?.data);
        setSubscriptionplan(res?.data?.plans?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.plans?.data);
        setSubscriptionplan(res?.data?.plans?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res.data.plans);
        setNext(res?.data?.plans?.data);
        setSubscriptionplan(res?.data?.plans?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <h4>
                            <i className="entypo-archive"></i> Subscription
                            Plans
                          </h4>
                        </div>
                        <div className="col-md-9" align="right">
                          <Link to="/admin/add-subscription-plans">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <table
                        className="table table-bordered iq-card text-center mt-5"
                        id="categorytbl"
                      >
                        <tr className="table-header r1">
                          <th>
                            <label>S.No</label>
                          </th>

                          <th>
                            <label>Plan Name</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>

                        {subscriptionplan?.map((item, key) => (
                          <tr>
                            <td className="table-content" key={item?.id}>
                              {key + 1}
                            </td>

                            <td className="table-content">
                              <p>{item?.plans_name}</p>
                            </td>
                            <td className="table-content">
                              <Link
                                to={"/admin/edit-subscription-plans/" + item?.id}
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link onClick={() => deleteOperation(item?.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                  <div className="row d-flex text-center align-items-center">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{page}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlan;
