import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PlayerUserAnalytics() {
  const datas = [
    {
      uname: "Admin",
      vname: "How to Train Your Dragon",
      count: "5",
      watch: "50.880812204999",
      seek: "127.640091",
      time: "No Buffer",
    },
    {
      uname: "Admin",
      vname: "Frozen",
      count: "5",
      watch: "66.381068605083",
      seek: "127.640091",
      time: "No Buffer",
    },
  ];

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = [
    "June",
    "June",
    "June",
    "June",
    "August",
    "August",
    "August",
    "August",
    "August",
    "September",
    "September",
    "September",
    "October",
    "November",
    "November",
    "January",
    "February",
    "April",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Player Videos",

        data: [
          5, 0, 40, 0, 10, 20, 0, 30, 0, 80, 0, 60, 0, 10, 0, 10, 0, 0, -20,
          100,
        ],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">Player User Analytics :</h4>
                  </div>
                </div>

                <div className="row p-3">
                  <hr />
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <label for="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_time"
                          name="start_time"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="start_time"> End Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_time"
                          name="end_time"
                        />
                      </div>

                      <div className="col-md-3 d-flex align-items-end">
                        <div class="dropdown">
                          <button
                            class="btn btn-outline-dark filter-btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Filter
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Day
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Week
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Month{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-3 d-flex align-items-end">
                        <span
                          id="export"
                          className="btn btn-primary download-btn btn-sm"
                        >
                          Download CSV
                        </span>
                      </div>
                    </div>
                    <div className="clear"></div>
                    <div className="mt-3">
                      <h5>Concurrent Viewers : 442</h5>
                    </div>
                    <div className="mt-5">
                      <h4>Player Video Graph :</h4>
                    </div>
                    <div>
                      <div className="row mt-5">
                        <div className="col-md-2"></div>
                        <div className="col-md-7">
                          <Line options={options} data={data} />
                          <p className="text-center">Player Videos</p>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex">
                        <p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className="d-flex">
                        <p>Search:</p>
                        <input
                          type="search"
                          id="gsearch"
                          name="gsearch"
                          className="border-0 search-rev"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>User Name</th>
                              <th>Video Name</th>
                              <th>Viewed Count</th>
                              <th>Watch Hours (H:M:S)</th>
                              <th>Seek Time (Seconds)</th>
                              <th>Buffer Time (Seconds)</th>
                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr  key={item?.id} className="r1">
                                <td key={key}> {key + 1} </td>
                                <td>{item.uname}</td>
                                <td>{item.vname}</td>
                                <td>{item.count}</td>
                                <td>{item.watch}</td>
                                <td>{item.seek} </td>
                                <td>{item.time}</td>
                              </tr>
                            </thead>
                          ))}
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PlayerUserAnalytics;
