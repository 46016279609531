import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AddMobileMenu.css";

function AddMobileMenu() {
  const [user_id, setUser_id] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [short_note, setShort_note] = useState("");
  const [order, setOrder] = useState("");
  const [image, setImage] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function Addmobilemenudata() {
    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id", id);
    formData.append("name", name);
    formData.append("short_note", short_note);
    formData.append("order", order);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
          var MobileSideMenu_id = response.data.MobileSideMenu_id;
          var resssss = response.data;

          // console.log('resssss', Menu_id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          formDatas.append("mobile_side_menu_id", MobileSideMenu_id);
          formDatas.append("image", image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className="mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div>
                    <div className="">
                      <h4 className="modal-title">New Mobile Side Menu</h4>
                    </div>
                    <div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label>Upload Icon Image:</label> <br />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            id="image"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </div>

                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter the Name"
                          />
                        </div>

                        <div className="form-group">
                          <label>Short Note:</label>
                          <input
                            type="text"
                            id="short_note"
                            name="short_note"
                            className="form-control"
                            onChange={(e) => setShort_note(e.target.value)}
                            placeholder="Enter the Short Note"
                          />
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-mobilemenu"
                          onClick={Addmobilemenudata}
                        >
                          Save Mobile Menu
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddMobileMenu;
