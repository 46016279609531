import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import './AudioList.css'

const AudioLists = () => {
  const [audio, setAudio] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/List`,
        { Channel_Partner_id: Channel_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Audios?.data;
        setAudio(result);
        // console.log(result);
        // console.log(response);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.Audios?.data);
        setAudio(res?.data?.Audios?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data);
        setPrevious(res?.data?.Audios?.data);
        setAudio(res?.data?.Audios?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/List?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.audios);
        setNext(res?.data?.Audios?.data);
        setAudio(res?.data?.Audios?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section ">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <i className="entypo-archive"></i> Audio Lists
                        </h4>
                      </div>
                      <div className="col-md-6" align="right">
                        <Link to="/channel/add-new-audio">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="fa fa-plus-circle"></i> Add New
                          </button>
                        </Link>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="border-0 search-rev"
                          />
                        </div>
                      </div> */}
                    <div className=" p-0 mt-4">
                      <div className="table-view">
                        <table className="data-tables table audio_table iq-card text-center p-0 w-100">
                          <thead>
                            <tr className="r1">
                              {/* <th>Select All <input type="checkbox" id="select_all" /></th> */}
                              {/* <th>S.No</th> */}
                              <th>Image</th>
                              <th>Title</th>
                              <th>Rating</th>
                              <th>Views</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {audio?.map((item, key) => (
                            <tbody>
                              <tr>
                                <td>
                                  <img src={item.image_url} width={100} />
                                </td>
                                <td>{item.title}</td>
                                <td>{item.rating}</td>
                                <td>{item.views}</td>
                                <td>{item.status}</td>

                                <td>
                                  <Link
                                    to={"/channel/edit-audio/" + item?.id}
                                    className="edit ms-1"
                                  >
                                     <span class="hovertext" data-hover="Edit Meta">
                                     <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>{" "}
                                   </span>
                                    
                                  </Link>

                                  <Link
                                    to={"/channel/audio/edit/" + item?.id}
                                    className="edit ms-1"
                                    data-original-title="Edit Video"
                                  >
                                    <span class="hovertextedit" data-hover="Edit Audio">
                                     <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>{" "}
                                   </span>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>

                                {/* <td>
                                  <Link
                                    to={"/channel/edit-audio/" + item.id}
                                    className="edit ms-1" 
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>Edit Meta
                                  </Link>
                                  <Link
                                    to={"/channel/audio/edit/" + item.id}
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>Edit Audio
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td> */}
                              </tr>
                            </tbody>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AudioLists;
