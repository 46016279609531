import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./ContentCommission.css";
import { Link, useNavigate } from "react-router-dom";

function ContentCommission() {
  const [menuItems, setMenuItems] = useState([]);
  console.log(menuItems);

  // console.log(menuItems);
  const [data, setData] = useState([]);

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  // useEffect(async (id) => {
  //   await axios
  //     .get(`${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/index`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       // console.log('api checks', response)
  //       var result = response?.data?.Web_HomePage_Order;
  //       setMenuItems(result);
  //       // console.log(result);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  // useEffect(() => {
  //   fetchMenuItems();
  // }, []);

  // const fetchMenuItems = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/index`,
  //       {
  //         headers: headers,
  //       }
  //     );
  //     setMenuItems(response?.data?.Web_HomePage_Order);
  //   } catch (error) {
  //     console.error("Error fetching menu items:", error);
  //   }
  // };

  // const shuffleItems = () => {
  //   const shuffledItems = [...menuItems];
  //   for (let i = shuffledItems?.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [shuffledItems[i], shuffledItems[j]] = [
  //       shuffledItems[j],
  //       shuffledItems[i],
  //     ];
  //   }
  //   setMenuItems(shuffledItems);
  // };

  // const handleDragEnd = (result) => {
  //   if (!result?.destination) return; // Item dropped outside the list

  //   const items = [...menuItems];
  //   const [reorderedItem] = items?.splice(result?.source?.index, 1);
  //   items?.splice(result?.destination?.index, 0, reorderedItem);

  //   setMenuItems(items);
  // };

  // const updateMenuOrder = async () => {
  //   try {
  //     const updatedItems = menuItems.map((menuItem, index) => ({
  //       id: menuItem.id,
  //       position: index + 1, // Adjust the position as needed
  //     }));

  //     await axios.post(`${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`, updatedItems,  {
  //       headers: headers,
  //     });
  //     console.log('Menu order updated successfully');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const updateMenuOrder = async () => {
  //   try {
  //     if (!menuItems || menuItems.length ==== 0) {
  //       console.error('Menu items are empty');
  //       return;
  //     }

  //     const updatedItems = menuItems.map((menuItem, index) => ({
  //       id: menuItem.id,
  //       position: index + 1, // Adjust the position as needed
  //     }));

  //     const requestData = {
  //       items: updatedItems.reduce((accumulator, item) => {
  //         accumulator[item.id] = item.position;
  //         return accumulator;
  //       }, {}),
  //     };

  //     await axios.post(`${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`, updatedItems,  {
  //       headers: headers,
  //     });
  //     console.log('Menu order updated successfully');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleMenuChange = () => {
  //   updateMenuOrder();
  // };

  // const updateMenuOrder = async () => {
  //   try {
  //     if (!menuItems || menuItems.length ==== 0) {
  //       console.error('Menu items are empty');
  //       return;
  //     }

  //     const ids = menuItems.map((menuItem) => menuItem.id);
  //     const positions = menuItems.map((_, index) => index + 1);

  //     const requestData = {
  //       id: ids,
  //       position: positions,
  //     };

  //     await axios.post(`${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`, requestData, {headers: headers});
  //     console.log('Menu order updated successfully');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleMenuChange = () => {
  //   updateMenuOrder();
  // };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/index`,
        { headers: headers }
      );
      setMenuItems(response.data.Web_HomePage_Order);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(menuItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setMenuItems(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    try {
      if (!updatedItems || updatedItems.length === 0) {
        console.error("Menu items are empty");
        return;
      }

      // const updatedItems = menuItems.map((menuItem, index) => ({
      //   id: menuItem.id,
      //   position: index + 1, // Adjust the position as needed
      // }));
      const ids = updatedItems.map((menuItem) => menuItem.id);
      const positions = updatedItems.map((_, index) => index + 1);

      const requestData = {
        id: ids,
        position: positions,
      };

      await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`,
        requestData,
        { headers: headers }
      );
      console.log("Shuffle order saved successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Add Commission</h4>
                        </div>
                      </div>
                      <hr />

                      {/* <div className="order_home text-center">
                        <div className="row">
                          <div className="col-lg-4 col-4 data">#</div>
                          <div className="col-lg-4 col-4 data">Name</div>
                          <div className="col-lg-4 col-4 data">Action</div>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="menu-items">
                            {(provided) => (
                              <ol
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {menuItems?.map((item, index) => (
                                  <Draggable
                                    key={item?.id.toString()}
                                    draggableId={item?.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <li
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <div className="text-center" >
                                          <div className="row" onChange={updateMenuOrder}>
                                            <div className="col-lg-4 col-4 data_th" onChange={handleMenuChange}>
                                              {item?.id}
                                            </div>
                                            <div className="col-lg-4 col-4 data_th" onChange={updateMenuOrder}>
                                              {item?.header_name}
                                            </div>
                                            <div className="col-lg-4 col-4 data_th" onChange={updateMenuOrder}>
                                              <Link
                                                to={
                                                  "/admin/edit-order-home/" +
                                                  item?.id
                                                }
                                                className="edit ms-1"
                                              >
                                                <i
                                                  className="fa fa-pencil-square"
                                                  aria-hidden="true"
                                                ></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </ol>
                            )}
                          </Droppable>
                          <button onClick={updateMenuOrder}>Save Order</button>
                        </DragDropContext>
                      </div> */}

                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="menu-items">
                          {(provided) => (
                            <ul
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {menuItems.map((menuItem, index) => (
                                <Draggable
                                  key={menuItem?.id.toString()}
                                  draggableId={menuItem?.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {/* <input
                      type="text"
                      value={menuItem.id}
                      readOnly
                    /> */}
                                      <div className="text-center">
                                        <div className="row">
                                          <div className="col-lg-4 col-4 data_th">
                                            {menuItem.id}
                                          </div>
                                          <div className="col-lg-4 col-4 data_th">
                                            {menuItem.header_name}
                                          </div>
                                          <div className="col-lg-4 col-4 data_th">
                                            <Link
                                              to={
                                                "/admin/edit-orderhome/" +
                                                menuItem?.id
                                              }
                                              className="edit ms-1"
                                            >
                                              <i
                                                className="fa fa-pencil-square"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>

                      {/* <div>
      <h2>Menu</h2>
      <button onClick={shuffleItems}>Shuffle</button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="menu-items">
          {(provided) => (
            <ol {...provided.droppableProps} ref={provided.innerRef}>
              {menuItems.map((item, index) => (
                <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {item.name}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
    </div> */}

                      <div className="iq-card-body container-fluid">
                        <h5></h5>
                        <form
                          method="POST"
                          action=""
                          accept-charset="UTF-8"
                          file="1"
                          enctype="multipart/form-data"
                        >
                          <div className="row mt-12 align-items-center">
                            <div className="col-md-6 p-0">
                              <div
                                className="panel panel-primary "
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label>Percentage</label>
                                  </div>{" "}
                                  <div className="panel-options">
                                    {" "}
                                    <Link to="#" data-rel="collapse">
                                      <i className="entypo-down-open"></i>
                                    </Link>{" "}
                                  </div>
                                </div>
                                <div className="panel-body">
                                  <p className="p1">
                                    Add the Commissiom Account For Videos:
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="percentage"
                                    id="percentage"
                                    placeholder="Add Commission"
                                    value=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 mt-3">
                              <input
                                type="submit"
                                value="Update Percentage"
                                className="btn btn-primary pull-right"
                              />
                            </div>
                          </div>
                        </form>

                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentCommission;
