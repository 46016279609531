import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import './Editseason.css'

function Editseason() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    trailer: "",
    image: "",
    access: "",
    ppv_price: "",
    ppv_interval: "",
    trailer_type: "",
    ios_product_id: "",
    uploaded_by: "",
  });

  const [trailer, setTrailer] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailerimage, setTrailerImage] = useState("");

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [language, setLanguage] = useState([]);

  const [category, setCategory] = useState([]);
  const [useraccess, setUseraccess] = useState([]);

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesSeason[0];
      var result = resData?.user_access;
      setEditUser(res);
      setUseraccess(result);
      // console.log(resData)
      // console.log(result)
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      series_id: editUser.series_id,
      trailer: editUser.trailer,
      image: editUser.image,
      access: editUser.access,
      ppv_price: editUser.ppv_price,
      ppv_interval: editUser.ppv_interval,
      trailer_type: editUser.trailer_type,
      ios_product_id: editUser.ios_product_id,
      uploaded_by: editUser.uploaded_by,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/series_season/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      const formDa = new FormData();

      formDa.append("season_id", id);
      formDa.append("trailer", trailer);
      formDa.append("trailer_type", trailer_type);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Trailer-series_season`,
          formDa,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            console.log("result", result);
            alert("Added successfully  !");
          }
        });

      const formDat = new FormData();

      formDat.append("season_id", id);
      formDat.append("image", trailerimage);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series_season`,
          formDat,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            console.log("result", result);
            alert("Added successfully  !");
          }
        });

      
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-2">
              <div className="iq-card">
                <div className="modal-body">
                  <form
                    id="update-cat-form"
                    accept-charset="UTF-8"
                    action=""
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <div className="form-group ">
                      <label className="m-0 ">Season Thumbnail </label>
                      <p className="p1">(16:9 Ratio or 1080 X 1920px)</p>

                      <img
                        src={editUser?.image}
                        className="movie-img"
                        width="200"
                      />

                      <input
                        type="file"
                        multiple="true"
                        className="form-control "
                        name="trailerimage"
                        id="trailerimage"
                        onChange={(e) => setTrailerImage(e.target.files[0])}
                      />
                    </div>
                    <div className="form-group">
                      <label className="m-0">Season Trailer :</label>
                      <p className="p1">Drop and drag the video file</p>
                      <div className="form_video-upload">
                        <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="trailer"
                          id="trailer"
                          onChange={(e) => setTrailer(e.target.files[0])}
                        />
                        <p className="p1">Drop and drag the video file</p>
                      </div>

                      <media-player width="200px"
                        title={editUser?.access}
                        src={editUser?.trailer}
                        poster=""
                        controls
                      >
                        <media-outlet width="200px"></media-outlet>
                      </media-player>
                    </div>

                    <div className="form-group ">
                      <label className="m-0"> Choose User Access:</label>
                      <select
                        value={editUser?.access}
                        onChange={handleInput}
                        className="form-control"
                        id="ppv_access"
                        name="ppv_access"
                      >
                        {/* <option value={editUser.access} >{editUser.access}</option> */}
                        {useraccess?.map((item) => (
                          <option value={item?.name}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group " id="ppv_price">
                      <label className="m-0">PPV Price:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="PPV Price"
                        name="ppv_price"
                        id="price"
                        onChange={handleInput}
                        value={editUser?.ppv_price}
                      />
                    </div>
                    <div className="form-group">
                      <label className="m-0">IOS PPV Price:</label>
                      <select
                        value={editUser?.ios_product_id}
                        name="ios_ppv_price"
                        className="form-control"
                        id="ios_ppv_price"
                        onChange={handleInput}
                      >
                        {/* <option value={editUser.ios_product_id} >{editUser.ios_product_id}</option> */}
                        <option value="">Select IOS PPV Price: </option>
                        <option value=""> </option>
                      </select>
                    </div>
                    <div className="form-group ">
                      <label className="m-0">PPV Interval:</label>
                      <p className="p1">
                        Please Mention How Many Episodes are Free:
                      </p>
                      <input
                        type="text"
                        id="ppv_interval"
                        name="ppv_interval"
                        value={editUser?.ppv_interval}
                        onChange={handleInput}
                        className="form-control"
                      />
                    </div>

                    <div className="modal-footer form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="submit-update-cat"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Editseason;
