import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ContentPartnerApproval() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Moderators Users Lists</h4>
                        </div>

                        <div className="iq-card-header-toolbar d-flex align-items-baseline">
                          <div className="form-group mr-2"></div>
                        </div>
                      </div>
                      <div className="iq-card-body table-responsive p-0">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table iq-card text-center">
                            <thead>
                              <tr className="r1">
                                <th>Id</th>
                                <th>Profile</th>
                                <th>Moderator Name</th>
                                <th>Email ID</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td>
                                  <div className="media align-items-center">
                                    <div className="iq-movie">
                                      <Link to="">
                                        <img
                                          src=""
                                          className="img-border-radius avatar-40 img-fluid"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td className="bg-warning"> </td>

                                <td colspan="2">
                                  <div className=" align-items-center list-user-action">
                                    <Link
                                      className="iq-bg-warning"
                                      onclick="return confirm('Do you want to approve this Moderator ?')"
                                      to=""
                                    >
                                      {" "}
                                      <i className="fa fa-check-circle"></i>
                                    </Link>
                                    <Link
                                      className="iq-bg-success"
                                      onclick="return confirm('Do you want to reject this Moderator  ?')"
                                      to=""
                                    >
                                      {" "}
                                      <i className="fa fa-close"></i>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="clear"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentPartnerApproval;
