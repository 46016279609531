import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Video.css";

function Allvideo() {
  const [allvideos, setAllvideos] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  // const headers = {
  //   'Content-Type': 'application/json',
  //   Authorization: 'Bearer ' + access_token,
  //   "Accept": 'application/json',
  //   'Content-Type': 'application/json',
  //   'Access-Control-Allow-Headers':
  //     'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
  //   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  //   'Access-Control-Allow-Credentials': true,
  //   'Access-Control-Allow-Origin': '*',
  //   'X-Requested-With': '*',
  // };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/video-list`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.videos?.data;
        setAllvideos(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete(`${process.env.REACT_APP_Baseurl}/admin/Block-countries/delete/` + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/video-list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.videos?.data);
        setAllvideos(res?.data?.videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/video-list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.videos?.data);
        setAllvideos(res?.data?.videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/video-list?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.videos?.data);
        setAllvideos(res?.data?.videos?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-video-stream ">
            <div className="col-lg-12 container-fluid mt-3">
              <div className="card ">
                <h3 className="p-3">All Videos</h3>
                <div className="p-2 text-center iq-card-body table-responsive">
                  <Table>
                    <thead>
                      <tr className="title">
                        {/* <th>SELECT ALL</th> */}
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Rating</th>
                        <th>Video Uploaded By</th>
                        <th>Video Type</th>
                        <th>Video Access</th>
                        <th>Status</th>

                        <th>Slider</th>
                        <th>Action</th>
                        <th> </th>
                      </tr>
                    </thead>
                    {allvideos?.map((item, key) => (
                      <thead>
                        <tr>
                          <td key={key}> {key + 1} </td>
                          <td className="">
                            <img
                              src={item?.Thumbnail}
                              className="img-fluid img-thumbnail img-manage"
                              alt="image"
                            />
                          </td>
                          <td className="title">{item?.title}</td>
                          <td>{item?.rating}</td>
                          <td className="">
                            {item?.uploaded_by === "admin"
                              ? "Uploaded by Admin"
                              : item.uploaded_by === "CPP"
                              ? "Uploaded by Content Partner"
                              : "Uploaded by Channel Partner "}
                          </td>
                          <td>{item?.type}</td>
                          <td>{item?.access}</td>

                          <td className="text-center">
                            {item?.active == 0 || item?.active == null ? (
                              <td className="bg-warning video_active">
                                Inactive
                              </td>
                            ) : item?.active == 1 &&
                              item?.active == 1 &&
                              item?.active == 1 ? (
                              <td className="bg-success video_active">
                                Active
                              </td>
                            ) : (
                              <td className="bg-warning video_active">Draft</td>
                            )}
                          </td>

                          <td>
                            <label className="switch">
                              {/* <button onClick={() => setIsOff(!isOff)}>{ isOff ? item.status : null }</button> */}
                              <input
                                className={isOff ? item?.status : null}
                                onClick={() => setIsOff(!isOff)}
                                // value={isOff ? item?.status : null}
                                type="checkbox"
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                            <Link
                              to={"/admin/editvideo/" + item?.id}
                              className="edit ms-2"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-2">
                              <span onClick={() => deleteOperation(item?.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </Table>

                  <div className="row text-center d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{page}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Allvideo;
