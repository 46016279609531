import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditMenu.css";

function EditMenu() {
  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [categories, setCategories] = useState([]);
  console.log(categories);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    parent_id: "",
    order: "",
    in_menu: "",
    name: "",
    url: "",
    type: "",
    select_url: "",
    custom_url: "",
    image: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Menu[0];
      var result = resData?.categories;
      // console.log(res);
      setEditUser(res);
      setCategories(result);
      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      parent_id: editUser.parent_id,
      order: editUser.order,
      in_menu: editUser.in_menu,
      name: editUser.name,
      url: editUser.url,
      type: editUser.type,
      select_url: editUser.select_url,
      custom_url: editUser.custom_url,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/menu/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')

      var Menu_id = resjson.Menu_id;

      console.log(Menu_id);

      // console.log('slider_id', slider_id)
      const formDatas = new FormData();
      formDatas.append("menu_id", Menu_id);
      formDatas.append("image", image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-menus`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/sliders')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  function enablestreams() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "site_url":
        return (
          <div className="mt-3">
            <div id="div_Site">
              <label for="url">Menu Item URL (ex. /site/url)</label>
              <input
                name="url"
                id="url"
                placeholder="URL"
                className="form-control"
                value={editUser?.url}
                onChange={handleInput}
              />
            </div>
          </div>
        );
      case "custom_url":
        return (
          <div className="mt-3">
            <div id="div_Custom">
              <label for="url">Custom URL (ex. full url)</label>
              <input
                name="custom_url"
                id="custom_url"
                placeholder="Custom URL"
                className="form-control"
                value={editUser?.custom_url}
                onChange={handleInput}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className="mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row justify-content-start">
                    <div className="col-md-12 d-flex justify-content-between">
                      <h4>
                        <i className="entypo-list"></i> Edit Menu Items
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="">
                    <div className="modal-body">
                      <div>
                        <label for="name">
                          Enter the new menu item name below
                        </label>
                        <input
                          name="name"
                          id="name"
                          placeholder="Menu Item Name"
                          className="form-control"
                          value={editUser?.name}
                          onChange={handleInput}
                        />
                        <input
                          name="name"
                          id="name"
                          placeholder="Menu Item Name"
                          className="form-control"
                          hidden
                        />
                        <br />
                        <div id="image">
                          <label for="name">Menu Item Image</label>
                          <br />
                          <img src={editUser?.image} width="150" height="150" />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            id="image"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </div>

                        <br />
                        <label for="name">Show In-Home</label>
                        <div className="mt-2 d-flex align-items-center">
                          <div className="mr-2">OFF</div>
                          <label className="switch">
                            <input
                              name="in_menu"
                              id="in_menu"
                              onChange={handleInput}
                              defaultChecked={
                                editUser?.in_menu === 1 ? true : false
                              }
                              checked={editUser?.in_menu === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              id="in_menu"
                              name="in_menu"
                              onChange={handleInput}
                              value={editUser?.in_menu === 1 ? "1" : "0"}
                            ></span>
                          </label>
                          <div className="ml-2">ON</div>
                        </div>
                        <br />
                        <label for="name">Menu Item URL</label>
                        <div>
                          <select
                            className="form-control url_type mt-3"
                            onClick={(event) => {
                              setEnable_restream(event.target.value);
                            }}
                          >
                            <option defaultValue="none">Select URL Type</option>
                            <option value="site_url">Site URL</option>
                            <option value="custom_url">Custom URL</option>
                          </select>
                        </div>
                        <div
                          onClick={(event) => {
                            setEnablestreamvisible(event.target.value);
                          }}
                        >
                          {enablestreams()}
                        </div>
                        <br />
                        <div>
                          <label for="dropdown">or Dropdown for:</label>
                          <select
                            value={editUser?.type}
                            name="select_url"
                            id="select_url"
                            className="form-control"
                            onChange={handleInput}
                          >
                            {/* <option className="menu-dropdown-radio" value={editUser.type} >{editUser.type}</option> */}
                            {categories?.map((item, key) => (
                              <option key={item?.id}
                                className="menu-dropdown-radio"
                                value={item?.name}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <br />
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        id="submit-new-menu"
                        onClick={handleUpdate}
                      >
                        Save Menus
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditMenu;
