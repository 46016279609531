import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Editorderhome() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    header_name: "",
    video_name: "",
    order_id: "",
    url: "",
    user_id: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Web_HomePage_Order;
      setEditUser(res);
      console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      order_id: editUser.order_id,
      url: editUser.url,
      video_name: editUser.video_name,
      header_name: editUser.header_name,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="user_roles-container">
                    <form className="form-group">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label
                            for="name"
                            className=" col-form-label text-md-right"
                          >
                            Home Page Header
                          </label>

                          <input
                            id="header_name"
                            type="text"
                            value={editUser?.header_name}
                            onChange={handleInput}
                            placeholder="Header Name"
                            className="form-control"
                            name="header_name"
                            autocomplete="header_name"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group row">
                          <label
                            for="name"
                            className=" col-form-label text-md-right"
                          >
                            URL (ex. /site/url)
                          </label>

                          <input
                            id="url"
                            type="text"
                            placeholder="URL"
                            value={editUser?.url}
                            onChange={handleInput}
                            className="form-control"
                            name="url"
                            autocomplete="url"
                            autofocus
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-0">
                        <div className="col-md-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleUpdate}
                          >
                            Update Order
                            
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Editorderhome;
