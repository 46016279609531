import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./AddNewLiveStream.css";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import JoditEditor from "jodit-react";

const MultiSelectDropdown = ({ options, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    const selectedValues = selected?.map((option) => option.value);
    onChange(selectedValues);
  };

  return (
    <Select
      options={options}
      isMulti
      defaultValue={selectedOptions}
      onChange={handleChange}
    />
  );
};

const AddNewLiveStream = () => {
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [url_type, setUrl_type] = useState("");
  const [image, setImage] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_url, setEmbed_url] = useState("");
  const [m3u_url, setM3u_url] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");
  const [details, setDetails] = useState("");
  const [rating, setRating] = useState("");
  const [year, setYear] = useState("");
  const [duration, setDuration] = useState("");
  const [access, setAccess] = useState("");
  const [publish_type, setPublish_type] = useState("");
  // console.log(publish_type, "pp");
  const [publish_time, setPublish_time] = useState("");
  // console.log(publish_time, "time");

  const [enable_restream, setEnable_restream] = useState("");
  // console.log(enable_restream, "ee");
  const [showModal, setShowModal] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [featured, setFeatured] = useState(0);
  // console.log(featured, "featured");

  const [banner, setBanner] = useState(0);
  // console.log(banner, "banner");
  const [active, setActive] = useState(0);
  const [player_image, setPlayer_image] = useState("");
  const [Tv_image, setTv_Image] = useState("");
  const [livestream_id, setLivestream_id] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  // console.log(search_tags, "ser");
  const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  // console.log(ios_ppv_price, "ios_ppv_price");
  const [fb_restream_url, setFb_restream_url] = useState("");
  const [youtube_restream_url, setYoutube_restream_url] = useState("");
  const [twitter_restream_url, setTwitter_restream_url] = useState("");
  const [fb_streamkey, setFb_streamkey] = useState("");
  const [youtube_streamkey, setYoutube_streamkey] = useState("");
  const [twitter_streamkey, setTwitter_streamkey] = useState("");
  const [description, setDescription] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [order3, setOrder3] = useState({});
  const [order4, setOrder4] = useState({});

  const [artist, setArtist] = useState([]);
  const [art, setArt] = useState([]);
  // console.log(art, "art");
  const [category, setCategory] = useState([]);
  const [cate, setCate] = useState([]);
  // console.log(cate, "cate");

  const [language, setLanguage] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [age_res, setAge_res] = useState([]);
  // console.log(age_res, "age_res");
  const [blockCountries, setBlockCountries] = useState([]);
  const [selectedBlockCountries, setSelectedBlockCountries] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  // const [secondOptions, setSecondOptions] = useState([]);
  // console.log(secondOptions, "live_ads");
  const [live_ads, setLive_ads] = useState("");
  // console.log(live_ads, "live_ads");

  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");
  // const [selectedUserAccess, setSelectedUserAccess] = useState(null);
  // console.log(selectedUserAccess, "ll");

  // console.log(language, "language");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  console.log(inputValue, "checkboxChecked");

  const handleCheckbox = () => {
    setEnable_restream(!enable_restream);
    if (!enable_restream) {
      setShowModal(true);
    } else {
      setShowInputs(false);
      setSelectedButtons([]);
    }
  };

  const handleButtonClick = (button) => {
    setSelectedButtons((prevSelectedButtons) => [
      ...prevSelectedButtons,
      button,
    ]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    setShowModal(false);
    setShowInputs(true);
  };

  const handleCategory = (selectedValues1) => {
    setCate(selectedValues1);

    // console.log(selectedValues1, "selectedValues1"); // Array of selected values
  };

  const handleArtistChange = (selectedValues2) => {
    setArt(selectedValues2);
    // console.log(selectedValues2); // Array of selected values
  };

  const handleLanguageChange = (selectedValues3) => {
    setSelectedLanguages(selectedValues3);
    // console.log(selectedValues3); // Array of selected values
  };

  const handleBlockCountriesChange = (selectedValues4) => {
    setSelectedBlockCountries(selectedValues4);
    // console.log(selectedValues4); // Array of selected values
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  // const handleUserAccessChange = (selectedValue) => {
  //   setSelectedUserAccess(selectedValue);
  //   console.log(selectedValue);
  // };

  const handlePublishType = (value) => {
    setPublish_type(value);
  };

  const handlePublishTime = (value) => {
    setPublish_time(value);
  };

  const handleFeaturedChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setFeatured(value);
  };

  const handleActiveChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setActive(value);
  };

  const handleBannerChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setBanner(value);
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  async function stream() {
    console.log(
      "append data",
      title,
      image,
      url_type,
      player_image,
      fb_streamkey,
      twitter_streamkey,
      youtube_streamkey,
      fb_restream_url,
      twitter_restream_url,
      youtube_restream_url,
      ppv_price,
      // Tv_live_image,
      search_tags,
      embed_url,
      m3u_url,
      details,
      year,
      banner,
      active,
      publish_type,
      featured,
      enable_restream,
      duration,
      access,
      live_stream_video,
      mp4_url,
      rating,
      age_res,
      mp4_url,
      description,
      category,
      artist,
      language,
      adsPositions
    );

    const formData = new FormData();

    formData.append("title", title);
    // formData.append("image", image);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", slug);
    formData.append("url_type", url_type);
    formData.append("embed_url", embed_url);
    formData.append("m3u_url", m3u_url);
    formData.append("live_stream_video", live_stream_video);
    formData.append("details", details);
    formData.append("rating", rating);
    formData.append("age_restrict", age_res?.value);
    formData.append("block_country", selectedBlockCountries);
    formData.append("year", year);
    formData.append("duration", duration);
    formData.append("access", userAccesses?.value);
    formData.append("publish_type", publish_type);
    formData.append("publish_time", publish_time);
    formData.append("enable_restream", enable_restream === true ? 1 : 0);
    formData.append("featured", featured);
    formData.append("banner", banner);
    formData.append("active", active);
    // formData.append("player_image", player_image);
    // formData.append("Tv_live_image", Tv_live_image);
    // formData.append("search_tags", JSON.stringify(search_tags));
    formData.append("search_tags", search_tags);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);

    formData.append("fb_restream_url", fb_restream_url);
    formData.append("youtube_restream_url", youtube_restream_url);
    formData.append("twitter_restream_url", twitter_restream_url);
    formData.append("fb_streamkey", fb_streamkey);
    formData.append("youtube_streamkey", youtube_streamkey);
    formData.append("twitter_streamkey", twitter_streamkey);
    formData.append("description", description);
    formData.append("language", selectedLanguages);
    formData.append("artist", art);
    formData.append("category", cate);
    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          navigate("/admin/livestream");
          alert("Added successfully  !");

          var livestream_id = response.data.LiveStream.id;

          // setLivestream_id(livestream_id);
          console.log("livestream_id", livestream_id);

          const formDatas = new FormData();

          formDatas.append("livestream_id", livestream_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("TV_image", Tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/live-stream/Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    // getCategorys();
    // getArtists();
    // fetchLanguages();
    // fetchBlockCountries();
    // fetchAdsPositions();
    // fetchUserAccesses();
    globel();
    fetchAgerestrict();
    publishType();
  }, []);

  const getCategorys = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      // console.log(response, "response"); // Log the response object
      const data = await response?.json();
      // console.log(data); // Log the data received
      const category = data?.livestream_category?.map((category) => ({
        value: category?.name,
        label: category?.name,
      }));
      setCategory(category);
    } catch (error) {
      console.log("Error fetching category:", error);
    }
  };

  // const getArtists = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
  //       {
  //         headers: headers,
  //       }
  //     );

  //     console.log(response, "response"); // Log the response object
  //     const data = await response.json();
  //     console.log(data); // Log the data received
  //     const artist = data.livestream_artist.map((artist) => ({
  //       value: artist.artist_name,
  //       label: artist.artist_name,
  //     }));
  //     setArtist(artist);
  //   } catch (error) {
  //     console.log("Error fetching artist:", error);
  //   }
  // };

  const getArtists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const artistOptions = data?.livestream_artist?.map((artist) => ({
          value: artist?.artist_name,
          label: artist?.artist_name,
        }));
        setArtist(artistOptions);
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.log("Error fetching artists:", error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response?.ok) {
        const data = await response?.json();
        const languageOptions = data?.livestream_language?.map((language) => ({
          value: language?.name,
          label: language?.name,
        }));
        setLanguage(languageOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching languages:", error);
    }
  };

  const fetchBlockCountries = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response?.ok) {
        const data = await response?.json();
        const blockCountryOptions = data?.Block_Country?.map((country) => ({
          value: country?.country_name,
          label: country?.country_name,
        }));
        setBlockCountries(blockCountryOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching block countries:", error);
    }
  };

  const fetchAdsPositions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const adsPositionOptions = data?.ads_position?.map((position) => ({
          value: position?.position,
          label: position?.name,
        }));
        setAdsPositions(adsPositionOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching ads positions:", error);
    }
  };

  const globel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const globelset = data?.ppv_gobal_price;
        setInputValueAPI(globelset);

        console.log(data?.user_access, "user");
        const userAcces = data?.user_access?.map((userAcc) => ({
          value: userAcc?.value,
          label: userAcc?.name,
        }));
        setUserAccesses(userAcces);

        const iosprice = data?.InappPurchase?.map((ios) => ({
          value: ios?.id,
          label: ios?.plan_price,
        }));
        setIos_ppv_price(iosprice);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching user accesses:", error);
    }
  };

  const fetchAgerestrict = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response?.ok) {
        const data = await response?.json();
        const agerestricts = data.Age_Restrict?.map((ageres) => ({
          value: ageres?.age,
          label: ageres?.age,
        }));
        setAge_res(agerestricts);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching user accesses:", error);
    }
  };

  const publishType = async () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/live-stream/create`, {
        headers: headers,
      })
      .then((response) => {
        const result = response?.data?.Publish_type;
        setPublish(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div>
            <input
              type="text"
              className="form-control mt-4"
              onChange={(e) => setMp4_url(e.target.value)}
              placeholder="setMp4_url"
            />
          </div>
        );
      case "Embed_url":
        return (
          <div>
            <input
              type="text"
              className="form-control mt-4"
              onChange={(e) => setEmbed_url(e.target.value)}
              placeholder="setEmbed_url"
            />
          </div>
        );
      case "Mp3":
        return (
          <div>
            <input
              type="text"
              className="form-control mt-4"
              onChange={(e) => setM3u_url(e.target.value)}
              placeholder="setM3u_url"
            />
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card text-center ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Add New LiveStream</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p className="">
                              Add the Live stream title in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="slug"
                                name="slug"
                                className="form-control"
                                onChange={(e) => setSlug(e.target.value)}
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">
                              Live Stream Image Cover
                            </label>
                            <p className="p1">
                              Select the Live stream image ( 9:16 Ratio or
                              1080X1920px ):
                            </p>
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                name="image"
                                id="image"
                                onChange={(e) => setImage(e.target.files[0])}
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="">
                                Select the Live stream image( 16:9 Ratio or
                                1280X720px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Source</label>
                            <p className="p1">
                              Select the Live Stream Source :
                            </p>
                            <div className="">
                              <div>
                                <select
                                  className="form-select url_type"
                                  onClick={(event) => {
                                    setLivesource(event.target.value);
                                  }}
                                >
                                  <option defaultValue="0" selected>
                                    Choose URL Format
                                  </option>
                                  <option defaultValue="mp4" selected>
                                    mp4
                                  </option>
                                  <option defaultValue="Embed_url">Embed_url</option>
                                  <option defaultValue="Mp3">Mp3</option>
                                </select>
                              </div>
                              {live_stream_source()}

                              {/*  <select className="form-select url_type" aria-label="Default select example" name="url_type" onChange={e => setUrl_type(e.target.value)}>
                                <option selected>Choose URL Format</option>
                                <option value="mp4">mp4</option>
                              </select>
                            </div>

                            <div>
                              <input
                                type="text"
                                className="form-control mt-2"
                                onChange={e => setMp4_url(e.target.value)}
                                placeholder="setMp4_url"
                              />
                            </div>

                            <div>
                              <input
                                type="text"
                                className="form-control mt-2"
                                onChange={e => setEmbed_url(e.target.value)}
                                placeholder="setEmbed_url"
                              />
                            </div>
                            <div>
                              <input
                                type="text"
                                className="form-control mt-2"
                                onChange={e => setM3u_url(e.target.value)}
                                placeholder="setM3u_url"
                              />
                            */}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">TV Image Cover</label>
                            <div className="new-video-upload" id="">
                              <p className="p1">
                                Select The Live Stream TV Image (1920 X 1080 Or
                                16:9 Ratio) :
                              </p>
                              <input
                                type="file"
                                className="form-control"
                                name="Tv_image"
                                id="Tv_image"
                                onChange={(e) => setTv_Image(e.target.files[0])}
                                placeholder="Tv_live_image"
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Enable ReStream</label>

                            <Form.Check
                              type="switch"
                              checked={enable_restream}
                              onChange={handleCheckbox}
                            />

                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Choose A Platfrom</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div class="col-md-4 youtube_col">
                                    <a class="youtube_btn btn  btn-primary active">
                                      <img
                                        class="w-50"
                                        src="https://dev-flick.webnexs.org/assets/img/you.png"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("YouTube")
                                        }
                                      >
                                        YouTube
                                      </p>
                                    </a>
                                  </div>
                                  <div class="col-md-4 facebook_col">
                                    <a
                                      class="facebook_btn btn btn-outline-primary"
                                      defaultValue="ss"
                                    >
                                      <img
                                        class="w-100"
                                        src="https://dev-flick.webnexs.org/assets/img/face.jpg"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("Facebook")
                                        }
                                      >
                                        Facebook
                                      </p>
                                    </a>
                                  </div>

                                  <div class="col-md-4 twitter_col">
                                    <a class="twitter_btn btn btn-outline-primary">
                                      <img
                                        class="w-50"
                                        src="https://dev-flick.webnexs.org/assets/img/twitter.png"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("Twitter")
                                        }
                                      >
                                        Twitter
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseModal}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleSave}>
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                        {showInputs && (
                          <div>
                            {selectedButtons?.includes("YouTube") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6 ">
                                  <div className="">
                                    <label className="mb-1">
                                      {" "}
                                      YouTube Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setYoutube_restream_url(e.target.value)
                                      }
                                      placeholder="youtube_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="" id="">
                                    <label className="mb-1">
                                      {" "}
                                      YouTube Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setYoutube_streamkey(e.target.value)
                                      }
                                      placeholder="youtube_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedButtons?.includes("Facebook") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      FaceBook Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFb_restream_url(e.target.value)
                                      }
                                      placeholder="fb_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="mt-2" id="">
                                    <label className="mb-1">
                                      {" "}
                                      FaceBook Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFb_streamkey(e.target.value)
                                      }
                                      placeholder="fb_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedButtons?.includes("Twitter") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      Twitter Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setTwitter_restream_url(e.target.value)
                                      }
                                      placeholder="twitter_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      Twitter Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setTwitter_streamkey(e.target.value)
                                      }
                                      placeholder="twitter_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Short Description</label>
                            <p className="">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Search Tags</label>
                            {/* <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="search_tags"
                                aria-label="Search"
                                name="search_tags"
                                id="search_tag"
                                onChange={(e) => setSearch_tags(e.target.value)}
                              />
                            </div> */}
                            <TagsInput
                              value={search_tags}
                              onChange={setSearch_tags}
                              name="search_tags"
                            />
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Artists</label>
                            <div className=" form-control">
                              <div className="text-dark">
                                <MultiSelectDropdown
                                  options={artist}
                                  onChange={handleArtistChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-12">
                            <label className="m-0">
                              Live Stream Details, Links, and Info
                            </label>
                            <div className="">
                              {/* <textarea
                                className="form-control"
                                id="details"
                                name="details"
                                onChange={(e) => setDetails(e.target.value)}
                                placeholder="setDetails"
                              /> */}

                              <JoditEditor
                                // ref={editor}
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>

                            <div className=" form-control">
                              <div className="text-dark">
                                <MultiSelectDropdown
                                  options={category}
                                  onChange={handleCategory}
                                  // className= 'form-control'
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="form-control">
                              <div className="text-dark">
                                <MultiSelectDropdown
                                  options={language}
                                  onChange={handleLanguageChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Age Restriction</label>

                            <div className="form-control">
                              <div className="text-dark">
                                {/* <Multiselect
                                  isObject={false}
                                  options={age_res}
                                  onChange={(e) => setAge_res(e.target.value)}
                                  showCheckbox
                                /> */}

                                <Select
                                  options={age_res}
                                  onChange={(e) => setAge_res(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Block Country</label>

                            <div className="form-control">
                              <div className="text-dark">
                                <MultiSelectDropdown
                                  options={blockCountries}
                                  onChange={handleBlockCountriesChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Ratings</label>
                            <p className="p1">
                              Live Stream Ratings 10 Out Of 10{" "}
                            </p>
                            <div className="form-control">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setRating(e.target.value)}
                              >
                                <option defaultValue="0">0</option>
                                <option defaultValue="1">1</option>
                                <option defaultValue="2">2</option>
                                <option defaultValue="3">3</option>
                                <option defaultValue="4">4</option>
                                <option defaultValue="5">5</option>
                                <option defaultValue="6">6</option>
                                <option defaultValue="7">7</option>
                                <option defaultValue="8">8</option>
                                <option defaultValue="9">9</option>
                                <option defaultValue="10">10</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Year</label>
                            <p className="p1">Live Stream Released Year</p>
                            <div className="">
                              <input
                                type="text"
                                name="year"
                                className="form-control"
                                onChange={(e) => setYear(e.target.value)}
                                placeholder="Set Year"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Choose Ads Position</label>

                            <div className="form-control">
                              {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                              <Select
                                options={adsPositions}
                                onChange={(e) => handleAdsPositionChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Choose Advertisement</label>

                            <div className="form-control">
                              <Select
                                options={secondSelectOptions}
                                onChange={(e) => setSecondSelectOptions(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Live Stream Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setDuration(e.target.value)}
                                placeholder="duration"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Live Stream ?
                            </p>
                            <div className="form-control">
                              <Select
                                options={userAccesses}
                                onChange={(e) => handleAccessChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        {userAccessesValue?.value === "ppv" ? (
                          <>
                            <div class="row mt-3 text-start ">
                              <div class="col-sm-4">
                                <label class="m-0">PPV Price</label>
                                <p class="p1 d-flex align-items-center">
                                  Apply PPV Price from Global Settings?
                                  {/* <input
                                    type="checkbox"
                                    name="ppv_gobal_price"
                                    id="ppv_gobal_price"
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                  /> */}
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      checked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                      className="featured form-group"
                                      id="featured"
                                      type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>

                                <div class="panel-body">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="PPV Price"
                                    name="ppv_price"
                                    id="price"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                  <div class="clear"></div>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <label class="m-0"> IOS PPV Price</label>
                                <p class="p1">
                                  Apply IOS PPV Price from Global Settings?
                                </p>
                                <div class="panel-body form-control">
                                  <Select
                                    options={ios_ppv_price}
                                    onChange={(e) => setIos_ppv_price(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>

                            <div className="p2">
                              <div>
                                <input
                                  // className="active"
                                  type="radio"
                                  id="publish_now"
                                  name="publish_type"
                                  defaultValue="1"
                                  checked={publish_type === "1"}
                                  onChange={() => handlePublishType("1")}
                                />
                                <label> Publish Now</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="publish_later"
                                  name="publish_type"
                                  defaultValue="0"
                                  checked={publish_type === "0"}
                                  onChange={() => handlePublishType("0")}
                                />
                                <label> Publish Later </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            {publish_type === "0" && (
                              <div>
                                <label>Publish Time:</label>
                                <input
                                  type="datetime-local"
                                  id="publish_time"
                                  name="publish_time"
                                  defaultValue={publish_time}
                                  onChange={(e) =>
                                    handlePublishTime(e.target.value)
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4" id="publishlater">
                            <label className="m-0">Status Settings</label>
                            <div>
                              <p className="p2">
                                Is this Live stream Featured:
                                <label className="switch">
                                  <input
                                    name="featured"
                                    onChange={handleFeaturedChange}
                                    className="featured form-group"
                                    id="featured"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                            <div>
                              <p className="p2">
                                Is this Live stream Active:
                                <label className="switch">
                                  <input
                                    name="active"
                                    onChange={handleActiveChange}
                                    className="active form-group"
                                    id="active"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>

                            <div>
                              <p className="p2">
                                Is this Live stream display in Banner:
                                <label className="switch">
                                  <input
                                    name="banner"
                                    onChange={handleBannerChange}
                                    className="banner form-group"
                                    id="banner"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button onClick={stream} className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddNewLiveStream;

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import './AddNewLiveStream.css'
// import Multiselect from 'multiselect-react-dropdown';
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';

// function AddNewLiveStream() {

//   const [title, setTitle] = useState('');
//   const [livestreamsource, setLivestreamsource] = useState('');
//   const [language, setLanguage] = useState('');
//   const [livestreamcover, setLivestreamcover] = useState('');
//   const [playerimagecover, setPlayerimagecover] = useState('');
//   const [tvimagecover, setTvimagecover] = useState('');
//   var url = 'https://api.flicknexs.com/admin/live-stream/store';
//   const access_token = localStorage.getItem('access_token');

//   async function addProduct() {
//     console.warn(title, livestreamsource, language, livestreamcover, playerimagecover, tvimagecover);
//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('livestreamsource', livestreamsource);
//     formData.append('livestreamcover', livestreamcover);
//     formData.append('playerimagecover', playerimagecover);
//     formData.append('tvimagecover', tvimagecover);

//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + access_token,
//     };

//     await axios.post('https://api.flicknexs.com/admin/live-stream/store', formData, { headers: headers }).then(response => {
//       var result = response.data;
//       console.warn('result', result);
//     });
//     alert('Added Successfully');
//   }

//   const [inputField, setInputField] = useState({
//     title: '',
//     livestreamsource: '',
//     language: '',
//     livestreamcover: '',
//     playerimagecover: '',
//     tvimagecover: '',
//   });

//   const [errField, setErrField] = useState({
//     titleerr: '',
//     livestreamsourceerr: '',
//     languageerr: '',
//     livestreamcovererr: '',
//     playerimagecovererr: '',
//     tvimagecovererr: '',
//   });
//   function clicked(e) {
//     e.preventDefault();
//   }
//   function handleChange(e) {
//     setInputField({ ...inputField, [e.target.name]: e.target.value });
//   }

//   useEffect(() => {
//     if (localStorage.getItem('access_token') !=== null) {
//     }
//   }, []);

//   const validForm = (e) => {
//     e.preventDefault();

//     let formIsValid = true;
//     setErrField({
//       titleerr: '',
//       livestreamsourceerr: '',
//       languageerr: '',
//       livestreamcovererr: '',
//       playerimagecovererr: '',
//       tvimagecovererr: '',
//     });
//     if (inputField.title === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         titleerr: 'Please Enter Title*',
//       }));
//     }

//     if (inputField.livestreamcover === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         livestreamcovererr: 'Please Enter Live Stream Cover*',
//       }));
//     }
//     if (inputField.livestreamsource === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         livestreamsourceerr: 'Please Enter Live Stream Source*',
//       }));
//     }
//     if (inputField.language === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         languageerr: 'Please Enter Language*',
//       }));
//     }
//     if (inputField.playerimagecover === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         playerimagecovererr: 'Please Enter Palyer Image*',
//       }));
//     }
//     if (inputField.tvimagecover === '') {
//       formIsValid = false;
//       setErrField(prevState => ({
//         ...prevState,
//         tvimagecovererr: 'Please Enter Tv Image*',
//       }));
//     }

//     if (inputField.title !=== '' && inputField.language !=== '' && inputField.tvimagecover !=== '' && inputField.livestreamcover !=== '' && inputField.livestreamsource !=== '' && inputField.playerimagecover !=== '') {
//       addProduct()
//     }
//     return formIsValid;
//   };

//   const [category, setLategory] = useState(["Drama", "News", "Kids", "Entertaiment", "Music"]);
//   const [languages, setLanguages] = useState(["Tamil", "English", "Hindi", "Telugu", "Kannada"]);

//   return (

//     <div>
//       <div className='row m-0 p-0'>
//         <div className='col-lg-3'>  </div>
//         <div className='col-lg-9 p-0'>
//
//           <section className="section dashboard p-3">
//             <div className="col-lg-12">
//               <div className="card text-center">
//                 <div className="card-body">
//                   <div className="container-fluid p-0">
//                     <div className="p-2">
//                       <div id="admin-container" >
//                         <div className="admin-section-title">
//                           <div className="d-flex justify-content-between">
//                           </div>
//                           <h4><i className="entypo-plus"></i> Add New LiveStream</h4>
//                           <hr />
//                         </div>

//                         <form >
//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Title</label>
//                               <p className="p1">Add the Live stream title in the textbox below:</p>
//                               <div className="panel-body">
//                                 <input type="text" className="form-control"
//                                   onChange={e => setTitle(e.target.value)} value={inputField.title} placeholder="Live stream Title" />
//                               </div>
//                               {errField.titleerr !=== '' && (
//                                 <span className="livestreamsourceerror">{errField.titleerr}</span>
//                               )}
//                             </div>

//                             <div className="col-sm-6">
//                               <label className="m-0">Slug</label>
//                               <p className="p1">Add the Live stream  slug in the textbox below:</p>
//                               <div className="panel-body">
//                                 <input type="text" className="form-control" name="slug" id="slug" placeholder="Live stream Slug" />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="row mt-2 text-start align-items-center">
//                             <div className="col-md-6">
//                               <div className="">
//                                 <label className="m-0">Live Stream Image Cover</label>
//                                 <p className="p1">Select the Live stream  image ( 9:16 Ratio or 1080X1920px ):</p>
//                                 <div className="panel-body">
//                                   <input type="file" multiple="true" className="form-group" name="image" id="image" value={inputField.livestreamcover}
//                                     onChange={handleChange} />
//                                 </div>
//                                 {errField.livestreamcovererr !=== '' && (
//                                   <span className="livestreamsourceerror">{errField.livestreamcovererr}</span>
//                                 )}
//                               </div>

//                               <div className="mt-2 ">
//                                 <div className="panel-body">
//                                   <img src="" className="video-imgimg" width="200" />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <div className="row mt-3">
//                                 <div className="">
//                                   <label className="m-0">Player Image Cover</label>
//                                   <p className="p1">Select the Live stream  image( 16:9 Ratio or 1280X720px ):</p>

//                                   <div className="panel-body">
//                                     <input type="file" multiple="true" value={inputField.playerimagecover}
//                                       onChange={handleChange} className="form-group" name="player_image" id="player_image" />
//                                   </div>
//                                   {errField.playerimagecovererr !=== '' && (
//                                     <span className="livestreamsourceerror">{errField.playerimagecovererr}</span>
//                                   )}
//                                 </div>

//                                 <div className="mt-2 text-center">
//                                   <div className="panel-body">
//                                     <img src="" className="video-imgimg" width="200" />
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Live Stream Source</label>
//                               <p className="p1">Select the Live Stream Source :</p>
//                               <div className="panel-body">
//                                 <select className="form-control url_type" id="url_type" name="url_type" value={inputField.livestreamcover}
//                                   onChange={handleChange}>
//                                   <option value="" >Choose URL Format</option>
//                                   <option value="mp4"> MP4/M3U8 URL </option>
//                                   <option value="embed"> Embed URL</option>
//                                   <option value="live_stream_video"> Live Stream Video</option>
//                                   <option value="m3u_url"> M3U URL </option>
//                                   <option className="Encode_stream_video" > </option>
//                                 </select>
//                               </div>
//                               {errField.livestreamsourceerr !=== '' && (
//                                 <span className="livestreamsourceerror">{errField.livestreamsourceerr}</span>
//                               )}
//                             </div>

//                             <div className="col-sm-6">
//                               <label className="m-0">TV Image Cover</label>
//                               <div className="panel-body">
//                                 <div className="new-video-upload mt-2" id="">
//                                   <p className="p1">Select The Live Stream TV Image  (1920 X 1080  Or 16:9 Ratio) :</p>
//                                   <input type="file" multiple="true" value={inputField.tvimagecover}
//                                     onChange={handleChange} className="form-group" name="live_stream_tv_image" id="" />
//                                 </div>
//                               </div>
//                               {errField.tvimagecovererr !=== '' && (
//                                 <span className="livestreamsourceerror">{errField.tvimagecovererr}</span>
//                               )}
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Enable ReStream</label>
//                               <div className="panel-body">
//                                 <div className="mt-1">
//                                   <label className="switch">
//                                     <input name="enable_restream" className="enable_restream" id="enable_restream" type="checkbox" />
//                                     <span className="slider round"></span>
//                                   </label>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start" id="youtube_restream_url">
//                             <div className="col-sm-6 ">
//                               <div className="panel-body">
//                                 <div className="mt-2" >
//                                   <label className="mb-1"> YouTube Stream (RTMP URL) </label>
//                                   <input type="text" name="youtube_restream_url" className="form-control" id="youtube_restream_url" placeholder="YouTube Stream Url" />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-sm-6">
//                               <div className="panel-body">
//                                 <div className="mt-2" id="">
//                                   <label className="mb-1"> YouTube Stream Key </label>
//                                   <input type="text" name="youtube_streamkey" className="form-control" id="youtube_streamkey" placeholder="YouTube Stream Key" />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start" id="fb_restream_url">
//                             <div className="col-sm-6">
//                               <div className="panel-body">
//                                 <div className="mt-2" id="fb_restream_url">
//                                   <label className="mb-1"> FaceBook Stream (RTMP URL) </label>
//                                   <input type="text" name="fb_restream_url" className="form-control" id="" placeholder="Facebook Stream" />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-sm-6" >
//                               <div className="panel-body">
//                                 <div className="mt-2" id="">
//                                   <label className="mb-1"> FaceBook Stream Key </label>
//                                   <input type="text" name="fb_streamkey" className="form-control" id="" placeholder="Facebook Stream Key" />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start" id="twitter_restream_url">
//                             <div className="col-sm-6">
//                               <div className="panel-body">
//                                 <div className="mt-2" >
//                                   <label className="mb-1"> Twitter Stream (RTMP URL) </label>
//                                   <input type="text" name="twitter_restream_url" className="form-control" id="" placeholder="Twitter Stream" />
//                                 </div>
//                               </div>
//                             </div>

//                             <div className="col-sm-6">
//                               <div className="panel-body">
//                                 <div className="mt-2" >
//                                   <label className="mb-1"> Twitter Stream Key </label>
//                                   <input type="text" name="twitter_streamkey" className="form-control" id="twitter_streamkey" placeholder="Twitter Stream" />
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-12">
//                               <label className="m-0">Short Description</label>
//                               <p className="p1">Add a short description of the Livestream below:</p>
//                               <div className="panel-body">
//                                 <textarea className="form-control" name="description" id="description"></textarea>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Search Tags</label>
//                               <div className="panel-body">
//                                 <input className="form-control me-2 w-75" type="search" placeholder="" aria-label="Search" />
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-12">
//                               <label className="m-0">Live Stream Details, Links, and Info</label>

//                               <div className="panel-body">
//                                 <textarea className="form-control" name="details" id="details"></textarea>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Category</label>
//                               <p className="p1">Select A Live Stream Category Below:</p>
//                               <div className="panel-body">
//                                 <Multiselect isObject={false} options={category} showCheckbox>

//                                 </Multiselect>
//                               </div>
//                             </div>
//                             <div className="col-sm-6">
//                               <label className="m-0">Language</label>
//                               <p className="p1">Select A Live Stream Language Below:</p>
//                               <div className="panel-body">
//                                 <Multiselect isObject={false} options={languages} showCheckbox >

//                                 </Multiselect>
//                               </div>
//                               {errField.languageerr !=== '' && (
//                                 <span className="livestreamsourceerror">{errField.languageerr}</span>
//                               )}
//                             </div>
//                           </div>

//                           <div className="row text-start mt-4" id="ppv_price">
//                             <div className="col-sm-6">
//                               <label className="m-0">Live Stream Ratings</label>
//                               <p className="p1">Live Stream Ratings 10 Out Of 10 </p>
//                               <div className="panel-body">
//                                 <input type="text" className="form-control" placeholder="" name="ppv_price" id="price" />
//                                 <div className="clear"></div>
//                               </div>
//                             </div>

//                             <div className="col-sm-6">
//                               <label className="m-0">Live Stream Year</label>
//                               <p className="p1">Live Stream Released Year</p>
//                               <div className="panel-body">
//                                 <select name="ios_ppv_price" className="form-control" id="ios_ppv_price">
//                                   <option value="" >Select IOS PPV Price: </option>
//                                   <option value=""> </option>
//                                 </select>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Duration</label>
//                               <p className="p1">Enter The Live Stream Duration In (HH : MM : SS)</p>
//                               <div className="panel-body">
//                                 <input type="text" className="form-control" placeholder="" id="price" />
//                               </div>
//                             </div>
//                             <div className="col-sm-6">
//                               <label className="m-0">User Access</label>
//                               <p className="p1">Who Is Allowed To View This Live Stream ?</p>
//                               <div className="panel-body">
//                                 <select className="form-control" >
//                                   <option value="English">English</option>
//                                   <option value="Hindi" >Hindi</option>
//                                   <option value="Tamil" >Tamil</option>
//                                 </select>
//                                 <div className="clear"></div>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Publish Type</label>
//                               <div className="panel-body p2" >
//                                 <input type="radio" id="publish_now" name="publish_type" value="publish_now" checked /> Publish Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
//                                 <input type="radio" id="publish_later" name="publish_type" value="publish_later" /> Publish Later
//                               </div>
//                             </div>
//                             <div className="col-sm-6 text-start" >
//                               <label className="m-0">Status Settings</label>
//                               <div className="panel-body">
//                                 <div>
//                                   <p className="p2" for="featured">Is this Live stream Featured:
//                                     <input type="checkbox" name="featured" value="1" id="featured" /></p>
//                                 </div>
//                                 <div className="clear"></div>
//                                 <div>
//                                   <p className="p2" for="active">Is this Live stream Active:
//                                     <input type="checkbox" name="active" value="1" id="active" /></p>
//                                 </div>
//                                 <div className="clear"></div>
//                                 <div>
//                                   <p className="p2" for="banner">Is this Live stream display in Banner:
//                                     <input type="checkbox" name="banner" value="1" id="banner" /></p>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>

//                           <input type="hidden" className="btn btn-primary" />
//                           <div className="d-flex justify-content-end">
//                             <input type="submit" className="btn btn-primary" onClick={addProduct} /></div>
//                         </form>

//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div></div>
//     </div>
//   );
// }

// export default AddNewLiveStream;

// <div>
//       <div className="addproduct">
//         <div className="sub-addproduct">
//           <div>
//             <div className="imgs">
//               <div className="container-image">
//               </div>
//             </div>{' '}
//             <br />
//             <div>
//               <h1 className='text-3xl '></h1><br />

//               <div className="prod ">
//                 <div className="row justify-content-around height-300px ">
//                   <div>
//                     <input
//                       type="text"
//                       className=""
//                       onChange={e => setTitle(e.target.value)}
//                       placeholder="Title"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className=""
//                       onChange={e => setSlug(e.target.value)}
//                       placeholder="slug"
//                     />
//                   </div>
//                   <div>
//                   <h3>Url_type</h3>
//                   <input
//                   type="text"
//                   className="form-group"
//                   onChange={e => setUrl_type(e.target.value)}
//                   placeholder="Url_type"
//                 />
//                   {/*  <select className="form-select" aria-label="Default select example" onChange={e => setUrl_type(e.target.value)}>
//                       <option selected>Open this select menu</option>
//                       <option value="1">mp4</option>
//                       <option value="2">Two</option>
//                       <option value="3">Three</option>
//                     </select>*/}
//                   </div>

//                   <div>
//                   <h3>Rating</h3>
//                   <input
//                   type="text"
//                   className="form-group"
//                   onChange={e => setRating(e.target.value)}
//                   placeholder="Rating"
//                 />
//                   {/*  <select className="form-select" aria-label="Default select example" onChange={e => setRating(e.target.value)}>
//                       <option value="1">0</option>
//                       <option value="2">1</option>
//                       <option value="3">3</option>
//   </select> */}
//                   </div>

//                   <div>
//                     <input
//                       type="text"
//                       className=""
//                       onChange={e => setDescription(e.target.value)}
//                       placeholder="Description"
//                     />
//                   </div>

//                   <div>
//                     <input
//                       type="text"
//                       className=""
//                       onChange={e => setMp4_url(e.target.value)}
//                       placeholder="setMp4_url"
//                     />
//                   </div>

//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setEmbed_url(e.target.value)}
//                       placeholder="setEmbed_url"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setDetails(e.target.value)}
//                       placeholder="setDetails"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setM3u_url(e.target.value)}
//                       placeholder="setM3u_url"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setYear(e.target.value)}
//                       placeholder="setYear"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setDuration(e.target.value)}
//                       placeholder="duration"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setAccess(e.target.value)}
//                       placeholder="access"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setPublish_type(e.target.value)}
//                       placeholder="publish_type"
//                     />
//                   </div>
//                   <div>
//                   <h3>Enable_restream</h3>
//                   <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setEnable_restream(e.target.value)}
//                       placeholder="Enable_restream"
//                     />
//                   {/*<select className="form-select" aria-label="Default select example" onChange={e => setEnable_restream(e.target.value)}>
//                   <option value="1">0</option>
//                   <option value="2">1</option>
//   </select>*/}
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setFeatured(e.target.value)}
//                       placeholder="featured"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setActive(e.target.value)}
//                       placeholder="active"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setPpv_price(e.target.value)}
//                       placeholder="ppv_price"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setFb_restream_url(e.target.value)}
//                       placeholder="fb_restream_url"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setYoutube_restream_url(e.target.value)}
//                       placeholder="youtube_restream_url"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setTwitter_restream_url(e.target.value)}
//                       placeholder="twitter_restream_url"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setFb_streamkey(e.target.value)}
//                       placeholder="fb_streamkey"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setYoutube_streamkey(e.target.value)}
//                       placeholder="youtube_streamkey"
//                     />
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setTwitter_streamkey(e.target.value)}
//                       placeholder="twitter_streamkey"
//                     />
//                   </div>
//                   <div>
//                     <input className="form-control me-2" type="search" placeholder="search_tags" aria-label="Search"
//                       onChange={e => setSearch_tags(e.target.value)} />
//                   </div>
//                   <div>
//                   <h3>image</h3>
//                     <input
//                       type="file"
//                       className="form-group"
//                       onChange={e => setImage(e.target.files[0])}
//                       placeholder="image"
//                     />
//                   </div>
//                   <div>
//                   <h3>Live_stream_video</h3>
//                     <input
//                       type="file"
//                       className="form-group"
//                       onChange={e => setLive_stream_video(e.target.files[0])}
//                       placeholder="Live_stream_video"
//                     />
//                   </div>
//                   <div>
//                   <h3>banner</h3>
//                     <input
//                       type="text"
//                       className="form-group"
//                       onChange={e => setBanner(e.target.value)}
//                       placeholder="banner"
//                     />
//                   </div>
//                   <div>
//                   <h3>player_image</h3>
//                     <input
//                       type="file"
//                       className="form-group"
//                       onChange={e => setPlayer_image(e.target.files[0])}
//                       placeholder="player_image"
//                     />
//                   </div>
//                   <div>
//                   <h3>Tv_live_image</h3>
//                     <input
//                       type="file"
//                       className="form-group"
//                       onChange={e => setTv_live_image(e.target.files[0])}
//                       placeholder="Tv_live_image"
//                     />
//                   </div>
//                   <div>
//                     <button onClick={stream} className="addproduct-button bg-cyan-500 shadow-lg shadow-cyan-500/50">
//                       Submit
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
