import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditSubscriptionPlan = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    placeholder: "Start typings...",
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [plan_iddata, setPlans_iddata] = useState([]);
  const [paymentSetting, setPaymentSetting] = useState([]);
  const [subscriptionplanname, setSubscriptionplanname] = useState([]);
  const [paymentsetting, setPaymentsetting] = useState([]);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    plans_name: "",
    devices: "",
    plan_id: "",
    billing_interval: "",
    billing_type: "",
    payment_type: "",
    type: "",
    days: "",
    price: "",
    andriod_paystack_url: "",
    video_quality: "",
    plan_content: "",
    resolution: "",
    subscription_plan_name: "",
    ios_plan_price: "",
    ios_product_id: "",
    ads_status: "",
  });
  const [devices, setDevices] = useState([]);
  const [devices2, setDevices2] = useState([]);
  const [data1, setData1] = useState("");
  const [deviceIds, setDeviceIds] = useState("");
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, content: e });
    console.log(e, "onchnageddddd");
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.plans;
      const result = resData?.Devices;
      const plans_id = resData?.plans_id;
      const PaymentSetting = resData?.PaymentSetting;
      const subscriptionplanname = resData?.SubscriptionPlan_Name;
      setEditUser(res);
      setDevices(result);
      setPlans_iddata(plans_id);
      setPaymentSetting(PaymentSetting);
      setSubscriptionplanname(subscriptionplanname);
      console.log(subscriptionplanname);

      const deviceIds = res?.devices?.split(",");
      setDeviceIds(deviceIds);

      //

      // if (res ==== devices) {
      //   const data = result.map((item) => item.user_id);
      //   setDevices2(data);
      // } else {
      //   console.log("Error");
      // }
    };

    getUser();
  }, []);

  const handleInputs = (id) => {
    const deviceIds = editUser?.devices?.split(",");
    const index = deviceIds?.findIndex((val) => val.includes(id));
    if (index !== -1) {
      deviceIds?.splice(index, 1);
    } else {
      deviceIds?.push(id);
    }
    const devices = deviceIds?.length > 0 ? deviceIds?.join(",") : "";
    setEditUser({ ...editUser, devices });
  };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.name]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.name]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    //     subscriptionplanname.forEach((item, index) => {
    //       if (typeof item?.type ==== "string") {
    //         var data = item?.type;
    //     const editInputvalue = {
    //       id: editUser.id,
    //       plans_name: editUser.plans_name,
    //       user_id: editUser.user_id,
    //       "plan_id[Stripe]": editUser.plan_id,
    //       price: editUser.price,
    //       billing_interval: editUser.billing_interval,
    //       billing_type: editUser.billing_type,
    //       payment_type: editUser.payment_type,
    //       type: editUser.type,
    //       days: editUser.days,
    //       andriod_paystack_url: editUser.andriod_paystack_url,
    //       video_quality: editUser.video_quality,
    //       plan_content: editUser.plan_content,
    //       resolution: editUser.resolution,
    //       devices: editUser.devices,
    //       [`subscription_plan_name[${data}]`]: editUser.subscription_plan_name,
    //       ios_plan_price: editUser.ios_plan_price,
    //       ios_product_id: editUser.ios_product_id,
    //       ads_status: editUser.ads_status,
    //     };
    //   }
    // });

    // const updatedPlans = subscriptionplanname.map((item, index) => {
    //   if (typeof item?.type ==== "string") {
    //     var data = item?.type;
    //     var datas = item;
    //     console.log(data, "data")
    //     console.log(datas, "data")
    //     return {
    //       [`subscription_plan_name[${data}]`]: editUser.subscription_plan_name,
    //     };
    //   }
    // });

    // const updatedPlansObj = Object.assign([...updatedPlans] );

    // console.log(updatedPlansObj , "updatedPlansObj")

    // const updatedPlansdata = paymentSetting.map((item, index) => {
    //   if (typeof item?.type ==== "string") {
    //     var data = item?.type;
    //     var datas = item;
    //     console.log(data, "data")
    //     return {
    //       [`plain_id[${data}]`]: editUser.plan_id,
    //     };
    //   }
    // });

    // const updatedPlansObjdata = Object.assign({}, ...updatedPlansdata);

    // console.log(updatedPlansObjdata , "updatedPlansObjdata")

    const formData = new FormData();

    formData.append("id", editUser.id);
    formData.append("plans_name", editUser.plans_name);
    paymentSetting?.forEach((item, index) => {
      if (typeof item?.payment_type === "string") {
        var data = item?.payment_type;
        formData.append(`plan_id[${data}]`, editUser?.plan_id);
      }
    });
    subscriptionplanname.forEach((item, index) => {
      if (typeof item?.type === "string") {
        var data = item?.type;
        formData.append(
          `subscription_plan_name[${data}]`,
          editUser.subscription_plan_name
        );
      }
    });
    formData.append("billing_interval", editUser.billing_interval);
    formData.append("billing_type", editUser.billing_type);
    formData.append("payment_type", editUser.payment_type);
    formData.append("type", editUser.type);
    formData.append("days", editUser.days);
    formData.append("price", editUser.price);
    formData.append("andriod_paystack_url", editUser.andriod_paystack_url);
    formData.append("video_quality", editUser.video_quality);
    formData.append("plan_content", editUser.plan_content);
    formData.append("resolution", editUser.resolution);
    formData.append("devices", editUser.devices);
    // formData.append("subscription_plan_name", editUser.subscription_plan_name);
    formData.append("ios_plan_price", editUser.ios_plan_price);
    formData.append("ios_product_id", editUser.ios_product_id);
    formData.append("ads_status", editUser.ads_status);

    // const editInputvalue = {
    //   id: editUser.id,
    //   plans_name: editUser.plans_name,
    //   user_id: editUser.user_id,
    //   price: editUser.price,
    //   billing_interval: editUser.billing_interval,
    //   billing_type: editUser.billing_type,
    //   payment_type: editUser.payment_type,
    //   type: editUser.type,
    //   days: editUser.days,
    //   andriod_paystack_url: editUser.andriod_paystack_url,
    //   video_quality: editUser.video_quality,
    //   plan_content: editUser.plan_content,
    //   resolution: editUser.resolution,
    //   devices: editUser.devices,
    //   ads_status: editUser.ads_status,
    //   ...updatedPlansObj,
    //   ...updatedPlansObjdata,
    // };

    // console.log(formData, "editInputvalue");
    //   let res = await fetch(
    //     `${process.env.REACT_APP_Baseurl}/admin/subscription/update`,
    //     {
    //       method: "POST",
    //       headers: headers,
    //       body: JSON.stringify(formData),
    //     }
    //   );

    //   let resjson = await res.json();
    //   console.log(resjson);
    //   if (res.status ==== 200) {
    //     setMessage(resjson.success);
    //     alert("Success");
    //     setTimeout(() => {
    //       // navigate("/admin/age/index");
    //     }, 2000);
    //   } else {
    //     setMessage("Some error Occured");
    //     console.log("Error");
    //     alert("Enter Correct Details");
    //   }
    // };
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/update`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          alert("Added successfully  !");
          setInterval(10000);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const myArray = [];
  const arrayvalue = myArray.filter((item) => item !== null).length === 0;

  return (
    <div>
      <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="section container-fluid pt-3">
              <div className="iq-card p-2 mt-3">
                <h4 className="p-2">
                  <i className="entypo-plus"></i>Subscription Plan Update
                </h4>
                <hr />
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Plan Name:</label>
                        <input
                          type="text"
                          id="plans_name"
                          name="plans_name"
                          onChange={handleInput}
                          value={editUser?.plans_name}
                          className="form-control"
                          placeholder="Plan Name"
                        />
                      </div>

                      <div className="form-group">
                        {paymentSetting?.map((item, index) => {
                          const value = item?.payment_type;
                          const value2 = item?.payment_type;

                          if (value && value2) {
                            return (
                              <div className="">
                                <label key={item?.id}>{value} Plan ID:</label>
                                <input
                                  type="text"
                                  id="plan_id"
                                  name={value}
                                  onChange={handleInput}
                                  className="form-control"
                                  placeholder={value + " " + "Plan Id"}
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}

                        {/* {paymentSetting.map((item, index) => {
                          const value = item?.Stripe;
                          const value2 = item?.payment_type;

                          return (
                            <div className="">
                              <label>{value} :</label>
                              <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            value={value2}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                            </div>
                          );
                        })} */}
                      </div>

                      <div className="form-group">
                        {subscriptionplanname?.map((item, index) => {
                          const value = item?.type;
                          const value2 = item?.subscription_plan_name;

                          if (value && value2) {
                            return (
                              <div className="" key={item?.id}>
                                <input
                                  type="text"
                                  id="plan_id"
                                  name={value}
                                  value={value}
                                  hidden
                                  onChange={handleInput}
                                  className="form-control"
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}

                        {/* {paymentSetting.map((item, index) => {
                          const value = item?.Stripe;
                          const value2 = item?.payment_type;

                          return (
                            <div className="">
                              <label>{value} :</label>
                              <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            value={value2}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                            </div>
                          );
                        })} */}
                      </div>

                      {/* <div className="form-group ">
                          <label>Stripe Plan ID:</label>
                          <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      
                      <form>
                        <div className="form-group ">
                          <label>PayPal Plan ID:</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>Paystack Plan ID:</label>
                          <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            onChange={handleInput}
                            value={editUser.plan_id}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      </form> */}
                      <form>
                        <div className="form-group ">
                          <label>Video Quality:</label>
                          <input
                            type="text"
                            id="video_quality"
                            name="video_quality"
                            onChange={handleInput}
                            value={editUser?.video_quality}
                            className="form-control"
                            placeholder="Quality"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>Price ( $ ):</label>
                          <input
                            type="text"
                            id="price"
                            value={editUser?.price}
                            onChange={handleInput}
                            name="price"
                            className="form-control"
                            placeholder="Price"
                          />
                        </div>
                      </form>
                    </div>

                    <div className="col-md-6 ">
                      <div className="row mt-2">
                        <label>Payment Type:</label>

                        <div class="form-check col-md-6 mt-2 ps-5">
                          <input
                            class="form-check-input"
                            type="radio"
                            value="one_time"
                            name="payment_type"
                            id="payment_type1"
                            onChange={handleInput}
                            checked
                          />
                          <label class="form-check-label" for="payment_type1">
                            One Time Payment
                          </label>
                        </div>
                        <div class="form-check col-md-6 mt-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            id="payment_type2"
                            onChange={handleInput}
                            value="recurring"
                          />
                          <label class="form-check-label" for="payment_type2">
                            Recurring
                          </label>
                        </div>
                      </div>
                      <form>
                        <div className="form-group ">
                          <label>Resolution :</label>
                          <input
                            type="text"
                            id="resolution"
                            name="resolution"
                            value={editUser?.resolution}
                            onChange={handleInput}
                            className="form-control"
                            placeholder="Resolution"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>IOS Product ID :</label>
                          <input
                            type="text"
                            id="ios_product_id"
                            value={editUser?.ios_product_id}
                            onChange={handleInput}
                            name="ios_product_id"
                            className="form-control"
                            placeholder="IOS Product ID"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>IOS Plan Price ( $ ):</label>
                          <input
                            type="text"
                            id="ios_plan_price"
                            value={editUser?.ios_plan_price}
                            onChange={handleInput}
                            name="ios_plan_price"
                            className="form-control"
                            placeholder="IOS Plan Price"
                          />
                        </div>
                      </form>

                      <div className="mt-3 row">
                        <label>Devices :</label>

                        {devices?.map((item, index) => {
                          const value = item?.devices_name;
                          const deviceIds = editUser?.devices?.split(",");
                          const devices = deviceIds?.filter((val) =>
                            val.includes(item?.id)
                          );
                          const array =
                            myArray?.filter((item) => item !== null).length ===
                            0;

                          return (
                            <div key={item?.id}>
                              <label>{value} :</label>
                              {/* <label>
                              {devices.length > 0 ? "Selected" : "Not selected"}{" "}
                              :
                            </label> */}
                              <label className="switch">
                                <input
                                  name="devices"
                                  onChange={() => handleInputs(item.id)}
                                  checked={devices?.length > 0}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="devices"
                                  onChange={() => handleInputs(item.id)}
                                  value={devices?.length > 0}
                                ></span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="colmd-12">
                    <div className="panel panel-primary" data-collapsed="0">
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Page Content</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body mt-3">
                        <JoditEditor
                          // ref={editor}
                          value={editUser?.plan_content}
                          //   onChange={(newContent) => setContent(newContent)}
                          // onChange={handleInput}

                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>Enable Ads</label>
                        <div>
                          <label className="switch">
                            <input
                              name="ads_status"
                              defaultChecked={
                                editUser?.ads_status === 1 ? true : false
                              }
                              onChange={handleInput}
                              checked={editUser?.ads_status === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ads_status"
                              onChange={handleInput}
                              value={editUser?.ads_status === 1 ? "1" : "0"}
                            ></span>
                          </label>

                          {/* <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <form>
                        <div className="form-group ">
                          <label>Andriod Paystack Url</label>
                          <input
                            type="text"
                            id="andriod_paystack_url"
                            value={editUser?.andriod_paystack_url}
                            onChange={handleInput}
                            name="andriod_paystack_url"
                            className="form-control"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <hr />

                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Update"
                        onClick={handleUpdate}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionPlan;
