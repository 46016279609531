import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AddFooterMenu.css";

function AddFooterMenu() {
  const [user_id, setUser_id] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [order, setOrder] = useState("");
  const [column_position, setColumn_position] = useState("");
  const [column, setColumn] = useState([]);
  const [parent_id, setParent_id] = useState("0");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/footer_menu/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.columns;
        setColumn(result);
        // console.log(result)
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  async function Addfootermenudata() {
    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id", id);
    formData.append("name", name);
    formData.append("link", link);
    formData.append("column_position", column_position);
    formData.append("order", order);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          alert("Added Successfully");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className="mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="modal-header">
                    <h4 className="modal-title">New Footer Link</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                  </div>
                  <div>
                    <div className="modal-body">
                      <input
                        type="hidden"
                        name="_token"
                        value=""
                      />

                      <div className="form-group">
                        <label>Name:</label>
                        <input
                          type="text"
                          id="footer_name"
                          name="footer_name"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Enter the Name"
                        />
                        <input
                          type="text"
                          id="parent_id"
                          name="parent_id"
                          onChange={(e) => setParent_id(e.target.value)}
                          className="form-control"
                          placeholder="Enter the parent_id"
                          hidden
                        />
                      </div>

                      <div className="form-group">
                        <label>Link:</label>
                        <input
                          type="text"
                          id="footer_link"
                          name="footer_link"
                          onChange={(e) => setLink(e.target.value)}
                          className="form-control"
                          placeholder="Enter the Link"
                        />
                      </div>

                      <div className="form-group">
                        <label>Column position :</label>

                        <select
                          name="column_position"
                          id="column_position"
                          className="form-control"
                          onChange={(e) => setColumn_position(e.target.value)}
                        >
                          {/* <option value=""  > Select the Position</option> */}
                          {column?.map((item, key) => (
                            <option key={item?.id} value={item?.name}> {item?.Column}</option>
                          ))}
                          {/* <option value="2" > Column 2</option>
																<option value="3" > Column 3</option>
																<option value="4" > Column 4</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        id="submit-new-footer"
                        onClick={Addfootermenudata}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddFooterMenu;
