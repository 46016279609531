import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

function Edit_new_audio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
  });

  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [Block_Country, setBlock_Country] = useState([]);
  const [Available_country, setAvailable_country] = useState([]);
  const [user_access, setUser_access] = useState([]);
  const [trailer, setTrailer] = useState("");
  const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  const [data, setData] = useState([]);
  const [order, setOrder] = useState({});
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [order3, setOrder3] = useState({});

  const [category, setCategory] = useState([]);
  const [cate, setCate] = useState([]);
  const [artist, setArtists] = useState([]);
  const [language, setLanguage] = useState([]);

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    console.log(selectedValue5); // Selected value
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const [options, setOptions] = useState([""]);
  const [publishdata, setPublish_timedata] = useState([]);
  const [ThumbnailTitle, setThumbnailTitle] = useState([]);

  const [optionscate, setOptionscate] = useState([""]);
  const [publishdatacate, setPublish_timedatacate] = useState([]);
  const [ThumbnailTitlecate, setThumbnailTitlecate] = useState([]);

  const [optionsartist_name, setOptionsartist_name] = useState([""]);
  const [publishdataartist_name, setPublish_timedataartist_name] = useState([]);
  const [ThumbnailTitleartist_name, setThumbnailTitleartist_name] = useState(
    []
  );

  const [optionsrelated_videos, setOptionsrelated_videos] = useState([""]);
  const [publishdatarelated_videos, setPublish_timedatarelated_videos] =
    useState([]);
  const [ThumbnailTitlerelated_videos, setThumbnailTitlerelated_videos] =
    useState([]);

  const [optionsblock_country, setOptionsblock_country] = useState([""]);
  const [publishdatablock_country, setPublish_timedatablock_country] = useState(
    []
  );
  const [ThumbnailTitleblock_country, setThumbnailTitleblock_country] =
    useState([]);
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audios[0];
      setEditUser(res);
      // console.log(resData)
      console.log(res);
      console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      slug: editUser.slug,
      ppv_status: editUser.ppv_status,
      ppv_price: editUser.ppv_price,
      type: editUser.type,
      access: editUser.access,
      album_id: editUser.album_id,
      artists: editUser.artists,
      rating: editUser.rating,
      details: editUser.details,
      description: editUser.description,
      active: editUser.active,
      status: editUser.status,
      draft: editUser.draft,
      featured: editUser.featured,
      banner: editUser.banner,
      duration: editUser.duration,
      views: editUser.views,
      year: editUser.year,
      ads_position: editUser.ads_position,
      age_restrict: editUser.age_restrict,
      mp3_url: editUser.mp3_url,
      image: editUser.image,
      player_image: editUser.player_image,
      tv_image: editUser.tv_image,
      search_tags: editUser.search_tags,
      ios_ppv_price: editUser.ios_ppv_price,
      uploaded_by: editUser.uploaded_by,
      image_url: editUser.image_url,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
      Channel_Partner_id: editUser.Channel_Partner_id,
      pre_ads_category: editUser.pre_ads_category,
      mid_ads_category: editUser.mid_ads_category,
      post_ads_category: editUser.post_ads_category,
      pre_ads: editUser.pre_ads,
      mid_ads: editUser.mid_ads,
      post_ads: editUser.post_ads,
      ads_tag_url_id: editUser.ads_tag_url_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);

    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')

      var audio_id = resjson.audio_id;
      var resssss = resjson;
      console.log("resssss", resssss);
      console.log("audio_id", audio_id);

      const formDatas = new FormData();

      formDatas.append("audio_id", audio_id);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("tv_image", tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          // const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data.ads_position.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const getData = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_language;
          // console.log(result)
          result.map((user) => {
            return arr.push({ value: user.id, label: user.name });
          });
          setOptions(arr);
        });
    };
    getData();

    const getDatacate = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_category;
          // console.log(result)
          // console.log(result)
          // console.log(result)
          result.map((user) => {
            return arr.push({ value: user.id, label: user.name });
          });
          setOptionscate(arr);
        });
    };
    getDatacate();

    const getDatartist_name = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_artist;
          console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.artist_name });
          });
          setOptionsartist_name(arr);
        });
    };

    getDatartist_name();

    const getDatarelated_videos = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.Related_videos;
          // console.log(result);
          // console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.title });
          });
          setOptionsrelated_videos(arr);
          console.log(arr);
        });
    };
    getDatarelated_videos();

    const getDatablock_country = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.Block_Country;
          console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.country_name });
          });
          setOptionsblock_country(arr);
        });
    };
    getDatablock_country();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var age_restrictdata = response.data.Age_Restrict;
        var ads_category = response.data.ads_category;
        var Block_Country = response.data.Block_Country;
        var Available_country = response.data.Available_country;
        var user_access = response.data.user_access;
        setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(ads_category)
        setUser_access(user_access);
        setBlock_Country(Block_Country);
        setAvailable_country(Available_country);
      })
      .catch((error) => console.log(error));

    //  axios.get('http://api.flicknexs.com/admin/videos-create', { headers: headers }).then(response => {
    //   var ads_category = response.data.ads_category
    //   // setAllvideos(result)
    //   console.log(ads_category)
    //   console.log(ads_category)
    // }).catch(error =>
    //   console.log(error));

    // const getlanguagedata = async () => {

    //   const getlanguagename = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_language
    //   // console.log(data);
    //   // console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getlanguagename.push(data[i].name);
    //   }
    //   setLanguage(getlanguagename);
    // }
    // getlanguagedata();

    // const getartistdata = async () => {

    //   const getartistname = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_artist
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getartistname.push(data[i].artist_name);
    //   }
    //   setArtist(getartistname);
    //   // console.log(getartistname);
    // }
    // getartistdata();

    // const getvideo_categorydata = async () => {

    //   const getvideo_categoryname = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_category
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getvideo_categoryname.push(data[i].artist_name);
    //   }
    //   setArtist(getvideo_categoryname);
    //   // console.log(getvideo_categoryname);
    // }
    // getvideo_categorydata();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          console.log(data.user_access, "user");
          const userAcces = data?.user_access.map((userAcc) => ({
            value: userAcc.role,
            label: userAcc.name,
          }));

          const ppv_price = data?.InappPurchase.map((userAcc) => ({
            value: userAcc.id,
            label: userAcc.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Audio</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body">
                      <form>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary "
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Title</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Add the audio title in the textbox below:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  placeholder="Audio Title"
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Slug</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Add the Audio slug:</p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="slug"
                                  id="slug"
                                  placeholder=""
                                  onChange={handleInput}
                                  value={editUser?.slug}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-6">
														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
															<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
															<div className="panel-body" >
																<p className="p1">Select Date/Time Below</p>
																<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
															</div>
														</div>
													</div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.image_url}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Player Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <img
                                  src={editUser?.Player_thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio TV Imgae Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <img src="" className="audio-img" width="200" />

                                <p className="p1">
                                  Select the audio TV Image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.TV_Thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />
                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="tv_thumbnail"
                                  id="tv_thumbnail"
                                  onChange={(e) =>
                                    setTv_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="panel panel-primary col-sm-6  mt-3"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Country</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <p className="p1">
                                Block the Audio for Selected Country:
                              </p>
                              {/* <select name="country[]" className="js-example-basic-multiple" multiple="multiple">
																<option value="" ></option>
															</select> */}
                            </div>
                          </div>

                          <div className="col-sm-6 mt-3">
                            <div className="">
                              <label className="m-0">Search Tags</label>

                              <div className="panel-body mt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tag-input1"
                                  onChange={handleInput}
                                  value={editUser?.search_tags}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Audio Details, Links, and Info</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <textarea
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.details}
                              name="details"
                              id="details"
                            ></textarea>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Short Description</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add a short description of the audio below:
                            </p>
                            <textarea
                              className="form-control"
                              name="description"
                              onChange={handleInput}
                              value={editUser?.description}
                              id="description"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Cast and Crew</label>{" "}
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p>Add artists for the audio below:</p>
                                {/* <select name="artists[]" className="js-example-basic-multiple" multiple="multiple">
																	<option value="" selected="true"></option>
																	<option value=""></option>
																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Album</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Album Below:
                                </p>
                                <select
                                  id="album_id"
                                  name="album_id"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.album_id}>
                                    {editUser?.album_id}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                <label className="p2" for="global_ppv">
                                  Age Restrict:
                                </label>
                              </div>
                              <div className="panel-body">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="age_restrict"
                                  id="age_restrict"
                                  onChange={editUser?.age_restrict}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Artists</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Artists Below:
                                </p>
                                <select
                                  id="artists"
                                  name="artists"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.artists}>
                                    {editUser?.artists}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Category</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Category Below:
                                </p>

                                {/* <select className="form-control js-example-basic-multiple" name="audio_category_id[]" id="audio_category_id" multiple="multiple" >

																	<option value="{{ $category->id }}" selected="true"></option>

																	<option value="{{ $category->id }}"></option>

																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Ratings</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1"> IMDB Ratings 10 out of 10</p>
                                <select
                                  value={editUser?.rating}
                                  className="form-control"
                                  name="rating"
                                  id="rating"
                                  aria-label="Default select example"
                                  onChange={handleInput}
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                                {/* <input className="form-control" name="rating" id="rating" value="" onkeyup="NumAndTwoDecimals(event , this);" /> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Language</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Language Below:
                                </p>
                                {/* <select className="form-control js-example-basic-multiple" id="language" name="language[]" multiple="multiple" >

																	<option value="{{ $language->id }}" selected="true"></option>

																	<option value="{{ $language->id }}" ></option>

																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Year</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Audio Released Year</p>
                                <input
                                  className="form-control"
                                  name="year"
                                  id="year"
                                  onChange={handleInput}
                                  value={editUser?.year}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Duration</label>
                                </div>
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Enter the audio duration in the following
                                  format (Hours : Minutes : Seconds)
                                </p>
                                <input
                                  className="form-control"
                                  name="duration"
                                  id="duration"
                                  maxlength="12"
                                  onChange={handleInput}
                                  value={editUser?.duration}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  {" "}
                                  <label>User Access</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Who is allowed to view this audio?
                                </p>
                                <select
                                  value={editUser?.user_access}
                                  id="access"
                                  name="access"
                                  className="form-control"
                                >
                                  {/* <option value={editUser.user_access}>{editUser.user_access}</option> */}

                                  {data?.map((item) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="mt-3 row ">
                          <div className="col-lg-6 ">
                            <div className="col-lg-12 row">
                              <div className="col-lg-10 col-10">
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-lg-2 col-2">
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    value={
                                      editUser?.ppv_status === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div>
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      value={editUser?.banner === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      value={editUser?.active === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      value={editUser?.status === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="row mt-4">
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-9">
                                {" "}
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-sm-3">
                                <label className="switch">
                                  <input
                                    name="global_ppv"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser.global_ppv == 1 ? true : false
                                    }
                                    checked={
                                      editUser.global_ppv == 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="global_ppv"
                                    onChange={handleInput}
                                    value={editUser.global_ppv == 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.featured == 1 ? true : false
                                      }
                                      checked={
                                        editUser.featured == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      onChange={handleInput}
                                      value={editUser.featured == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  {" "}
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.banner == 1 ? true : false
                                      }
                                      checked={
                                        editUser.banner == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      onChange={handleInput}
                                      value={editUser.banner == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.active == 1 ? true : false
                                      }
                                      checked={
                                        editUser.active == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      onChange={handleInput}
                                      value={editUser.active == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="status">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.status == 1 ? true : false
                                      }
                                      checked={
                                        editUser.status == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      onChange={handleInput}
                                      value={editUser.status == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this audio Views:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="views"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.views == 1 ? true : false
                                      }
                                      checked={
                                        editUser.views == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="views"
                                      onChange={handleInput}
                                      value={editUser.views == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this PPV Status:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="ppv_status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser.ppv_status == 1 ? true : false
                                      }
                                      checked={
                                        editUser.ppv_status == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="ppv_status"
                                      onChange={handleInput}
                                      value={
                                        editUser.ppv_status == 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="row mt-3 align-items-center ml-3">
                            <div className="col-sm-12">
                              <label className="switch" hidden>
                                <input
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.draft === 1 ? true : false
                                  }
                                  checked={editUser?.draft === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  value={editUser?.draft === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              <label className="switch" hidden>
                                <input
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.views === 1 ? true : false
                                  }
                                  checked={editUser?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  value={editUser?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2 p-2 d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Update"
                            className="btn btn-primary "
                            onClick={handleUpdate}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit_new_audio;
