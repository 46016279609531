import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditMobileMenu.css";

function EditMobileMenu() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    user_id: "",
    id: "",
    image: "",
    name: "",
    short_note: "",
    order: "",
    parent_id: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.mobile_side_menus[0];
      // console.log(res);
      setEditUser(res);
      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      name: editUser.name,
      parent_id: editUser.parent_id,
      short_note: editUser.short_note,
      order: editUser.order,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      var MobileSideMenu_id = resjson.MobileSideMenu_id;

      console.log(MobileSideMenu_id);

      // console.log('slider_id', slider_id)
      const formDatas = new FormData();

      formDatas.append("mobile_side_menu_id", MobileSideMenu_id);
      formDatas.append("image", image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            navigate("/admin/sliders");
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className="mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div>
                    <div className="">
                      <h4 className="modal-title">New Mobile Side Menu</h4>
                    </div>
                    <div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label>Upload Icon Image:</label> <br />
                          <img src={editUser?.image} width="100" height="100" />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            id="image"
                          />
                        </div>

                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Enter the Name"
                            value={editUser?.name}
                            onChange={handleInput}
                          />
                          <input
                            type="text"
                            id="parent_id"
                            name="parent_id"
                            className="form-control"
                            placeholder="Enter the parent_id"
                            value={editUser?.parent_id}
                            hidden
                            onChange={handleInput}
                          />
                        </div>

                        <div className="form-group">
                          <label>Short Note:</label>
                          <input
                            type="text"
                            id="short_note"
                            name="short_note"
                            className="form-control"
                            placeholder="Enter the Short Note"
                            value={editUser?.short_note}
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-mobilemenu"
                          onClick={handleUpdate}
                        >
                          Update Mobile Menu
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditMobileMenu;
