import React from "react";

const EditContentPartnerView = () => {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="">
              <div className="iq-card">
                <div id="user_roles-container">
                  <div className="user_roles-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Update Roles
                    </h4>{" "}
                  </div>
                  <hr />

                  <form
                    method="POST"
                    action=""
                    accept-charset="UTF-8"
                    file="1"
                    enctype="multipart/form-data"
                  >
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label
                          for="name"
                          className=" col-form-label text-md-right"
                        >
                          Role Name
                        </label>

                        <input
                          id="role_name"
                          type="text"
                          className="form-control"
                          name="role_name"
                          autocomplete="role_name"
                          autofocus
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      {" "}
                      <div>
                        <label
                          for="user_permission"
                          className=" col-form-label text-md-right"
                        >
                          User Permission
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label> permissions name</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="user_permission[]"
                            value="permissions"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label> permissions name</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="user_permission[]"
                            value="permissions"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label> permissions name</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="user_permission[]"
                            value="permissions"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label> permissions name</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="user_permission[]"
                            value="permissions"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group row mb-0">
                      <div className="col-md-12 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Register
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditContentPartnerView;
