import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditVideoLanguage.css";

function EditVideoLanguage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [slider, setSlider] = useState([]);
  const [editUser, setEditUser] = useState({ name: "" });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [languageimage, setLanguageimage] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    'Access-Control-Allow-Origin': '*',
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/language/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Languages[0];
      // console.log(res);
      setEditUser(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = { name: editUser.name };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/language/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')

      var Language_id = resjson.Language_id;

      // console.log('slider_id', slider_id)
      const formDatas = new FormData();

      formDatas.append("language_id", Language_id);
      formDatas.append("Language_image", languageimage);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/language/image-update`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate("/admin/sliders");
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-3">
              <div className="">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Language
                </h5>
              </div>
              <div className="modal-body">
                <div>
                  <label>Name: </label>

                  <input
                    type="text"
                    name="name"
                    value={editUser?.name}
                    onChange={handleInput}
                    placeholder="Language Name"
                    className="form-control rtmp_urls"
                  />
                </div>

                <div className="mt-3">
                  <label>Thumbnail</label>
                  <p>
                    Select The Language Image ( 1:1 Ratio Or 1024 X 1024px ) :
                  </p>
                </div>

                <div>
                  <img
                    src={editUser?.image_url}
                    alt="Thumbnail"
                    width={200}
                    height={200}
                  />
                </div>

                <div className="mt-3">
                  <label>Image:</label>
                  <input
                    type="file"
                    multiple="true"
                    className="form-control"
                    name="image"
                    id="image"
                    onChange={(e) => setLanguageimage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary ml-3"
                  onClick={handleUpdate}
                >
                  Update Language
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default EditVideoLanguage;
