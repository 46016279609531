import React from 'react'
import { Link } from 'react-router-dom'


const Manage_LiveCategory = () => {
  return (
    <div>
         <div className=" m-0 p-0">
        <div className="">
          <section className="section all-video-stream container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <i className="entypo-archive"></i> Live Stream
                            Categories
                          </h4>
                          <p>Organize The Categories Below:</p>
                        </div>
                        <div className="col-md-6" align="right">
                          <Link to="/cpp/add-manage-live-category">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <table
                        className="table table-bordered iq-card text-center"
                        id="categorytbl"
                      >
                        <tr className="table-header r1">
                          <th>
                            <label>Video Category Name</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>

                        {/* {liveCategory.map((item, key) => (
                          <tr>
                            <td className="table-content">
                              <p>{item.name}</p>
                            </td>
                            <td className="table-content">
                              <Link
                                to={"/admin/livestream-edit-categories/" + item.id}
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                  onClick={() => handleDelete(item.id)}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))} */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
          </section>
        </div>
      </div>
    </div>
  )
}

export default Manage_LiveCategory