import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./ContactRequest.css";

function ContactRequest() {
  const [contactrequest, setContactrequest] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/contact-us`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.contact_us?.data;
        setContactrequest(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete("" + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/contact-us?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.videos?.data);
        setContactrequest(res?.data?.videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/contact-us?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.videos?.data);
        setContactrequest(res?.data?.videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/contact-us?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.videos?.data);
        setContactrequest(res?.data?.videos?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="row pt-3">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Contact Request</h4>
                  </div>
                </div>
                <div className="iq-card-body ">
                  <div className="table-view">
                    <table
                      className="table table-striped table-bordered table movie_table text-center "
                      id="player_table"
                    >
                      <thead>
                        <tr className="r1 ">
                          <th>S.NO</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Subject</th>
                          <th>Message</th>
                          <th>Attach Screenshot</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactrequest?.map((item, key) => (
                          <tr key={item?.id}>
                            <td key={key}> {key + 1} </td>
                            <td>{item?.fullname}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phone_number}</td>
                            <td>{item?.subject}</td>
                            <td>{item?.message}</td>
                            <td>
                              <Link to="">
                                <img
                                  src={item?.contact_image}
                                  width="150"
                                  height="150"
                                  alt="contact_image"
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClick}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{page}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClick}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactRequest;
