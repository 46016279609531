import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ViewContentPartner() {
  const sub = [
    {
      name: "Sanjai Kumar",
      email: "Demos@Webnexs.In",
      number: "9876543211",
      des: "Chennaimnbnn",
    },
  ];

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="admin-section-title">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className="entypo-archive"></i> All Moderators
                      </h4>
                    </div>
                    <div className="col-md-6" align="right">
                      <Link
                        to="/admin/moderator"
                        onclick="jQuery('#add-new').modal('show');"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus-circle"></i> Add New
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="modal fade" id="update-category">
                  <div className="modal-dialog">
                    <div className="modal-content"></div>
                  </div>
                </div>

                <div className="clear"></div>

                <div
                  className="panel panel-primary category-panel"
                  data-collapsed="0"
                >
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Organize the Categories below:</label>
                    </div>

                    <div className="panel-options">
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="panel-body mt-3">
                    <div id="nestable" className="nested-list dd with-margins">
                      <table
                        className="table table-bordered text-center iq-card"
                        id="categorytbl"
                      >
                        <tr className="table-header r1">
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        {sub.map((item, key) => (
                          <tr key={item?.id}>
                            {/* <td className="table-content" key={key}>
                              {key + 1}
                            </td> */}
                            <td>{item.profile}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.number}</td>
                            <td>{item.des}</td>

                            <td>
                              <div>
                                <button className="btn btn-success">
                                  Approved
                                </button>
                              </div>
                            </td>
                            <td>
                              <Link to={"/admin/edit-allmoderator/:id"}>
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewContentPartner;
