import React from "react";
import { Link } from "react-router-dom";

function FileManager() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">FileManager</section>
      </div>
    </div>
  );
}

export default FileManager;
