import React from "react";
import "../Signin/Header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/flicklogo.png";
function Header() {
  return (
    <section className="header1">
      <div className="container text-center p-3">
        <Link to="/">
          <img src={logo} className="p-1" alt="flogo" />
        </Link>
      </div>
    </section>
  );
}

export default Header;

// import React, { useState, useEffect } from 'react';

// function Header() {

//     const [posts, setPosts] = useState([]);
//     const access_token = localStorage.getItem('access_token');
//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + access_token,
//     };

//     useEffect(() => {
//         fetch('https://api.flicknexs.com/admin/compress_image', {mode:'no-cors'}, {headers})
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setPosts(data);
//             })
//             .catch((err) => {
//                 console.log(err.message);
//             });
//     }, []);

//     return (
//         <>
//             {Object.values(posts).map((value, index) => {
//                 return (
//                     <div key={index}>
//                         <div>{posts.status}</div>
//                     </div>
//                 );
//             })}
//         </>
//     );
// }

// export default Header
