import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./StorefrontSettings.css";

function StorefrontSettings() {
  const [message, setMessage] = useState("");
  const [time_zone, setTime_zone] = useState([]);
  const [logo, setLogo] = useState("");
  const [system_email, setSystem_email] = useState("");
  const [coupon_code, setCoupon_code] = useState("");
  const [signature, setSignature] = useState("");
  const [favicon, setFavicon] = useState("");
  const [login_content, setLogin_content] = useState("");
  const [notification_icon, setNotification_icon] = useState("");
  const [default_horizontal_image, setDefault_horizontal_image] = useState("");
  const [default_video_image, setDefault_video_image] = useState("");
  const [watermark, setWatermark] = useState("");
  const [script, setScript] = useState({
    header_script: "",
    footer_script: "",
  });
  const [editrtmp, setEditrtmp] = useState({ rtmp_url: "", hls_url: "" });
  const [capcha, setCapcha] = useState({
    captcha_site_key: "",
    captcha_secret_key: "",
  });
  const [rtmp, setRtmp] = useState([]);
  const [rtmp_url, setRtmp_url] = useState("");
  const [hls_url, setHls_url] = useState("");
  const [app_settings, setApp_settings] = useState({
    android_url: "",
    ios_url: "",
    android_tv: "",
    status: "",
  });
  const [setting, setSetting] = useState({
    website_name: "",
    ppv_status: "",
    ads_on_videos: "",
    transcoding_access: "",
    transcoding_resolution: "",
    google_oauth_key: "",
    series_season: "",
    login_text: "",
    notification_key: "",
    login_content: "",
    discount_percentage: "",
    ppv_price: "",
    ppv_hours: "",
    coupon_status: "",
    google_tracking_id: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    coupon_status: "",
    favicon: "",
    system_email: "",
    earn_amount: "",
    signature: "",
    demo_mode: "",
    enable_https: "",
    theme: "",
    ppv_status: "",
    ppv_hours: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    watermark_right: "",
    ppv_price: "",
    discount_percentage: "",
    new_subscriber_coupon: "",
    login_text: "",
    signature: "",
    coupon_code: "",
    new_subscriber_coupon: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    videos_per_page: "",
    posts_per_page: "",
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    default_ads_url: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    payout_method: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/setting/index", { headers: headers })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.TimeZone;
        var setting = response?.data?.settings[0];
        var logo = response?.data?.settings[0]?.logo;
        var favicon = response?.data?.settings[0]?.favicon;
        var login_content = response?.data?.settings[0]?.login_content;
        var notification_icon = response?.data?.settings[0]?.notification_icon;
        var watermark = response?.data?.settings[0]?.watermark;
        var default_video_image =
          response?.data?.settings[0]?.default_video_image;
        var default_horizontal_image =
          response?.data?.settings[0]?.default_horizontal_image;
        setTime_zone(result);
        setSetting(setting);
        setFavicon(favicon);
        setLogin_content(login_content);
        setNotification_icon(notification_icon);
        setLogo(logo);
        setWatermark(watermark);
        setDefault_horizontal_image(default_horizontal_image);
        setDefault_video_image(default_video_image);
        // console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get("http://api.flicknexs.com/admin/setting/index", { headers: headers })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var script = response.data.script;
        var app_settings = response.data.app_settings;
        var rtmp_url = response.data.rtmp_url;
        var editrtmp = response.data.rtmp_url;
        var captchas = response.data.captchas;
        setScript(script);
        setApp_settings(app_settings);
        setRtmp(rtmp_url);
        setEditrtmp(editrtmp);
        setCapcha(captchas);
        console.log(editrtmp);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setSetting({ ...setting, [e.target.name]: e.target.value });
    setScript({ ...script, [e.target.name]: e.target.value });
    setApp_settings({ ...script, [e.target.name]: e.target.value });
    setCapcha({ ...capcha, [e.target.name]: e.target.value });
    setRtmp({ ...rtmp, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
      setCapcha({ ...capcha, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
      setSetting({ ...setting, [e.target.name]: e.target.value });
      setCapcha({ ...capcha, [e.target.name]: 0 });
      setCapcha({ ...capcha, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      time_zone: time_zone.time_zone,
      header_script: setting.header_script,
      footer_script: setting.footer_script,
      website_name: setting.website_name,
      ads_on_videos: setting.ads_on_videos,
      transcoding_resolution: setting.transcoding_resolution,
      transcoding_access: setting.transcoding_access,
      ppv_status: setting.ppv_status,
      login_text: setting.login_text,
      series_season: setting.series_season,
      notification_key: setting.notification_key,
      ppv_price: setting.ppv_price,
      discount_percentage: setting.discount_percentage,
      ppv_hours: setting.ppv_hours,
      coupon_status: setting.coupon_status,
      google_tracking_id: setting.google_tracking_id,
      google_oauth_key: setting.google_oauth_key,
      coupon_code: setting.coupon_code,
      new_subscriber_coupon: setting.new_subscriber_coupon,
      website_description: setting.website_description,
      logo: setting.logo,
      logo_height: setting.logo_height,
      logo_width: setting.logo_width,
      favicon: setting.favicon,
      system_email: setting.system_email,
      earn_amount: setting.earn_amount,
      signature: setting.signature,
      ios_plan_price: setting.ios_plan_price,
      ios_product_id: setting.ios_product_id,
      expiry_time_started: setting.expiry_time_started,
      expiry_day_notstarted: setting.expiry_day_notstarted,
      expiry_hours_notstarted: setting.expiry_hours_notstarted,
      expiry_min_notstarted: setting.expiry_min_notstarted,
      videos_per_page: setting.videos_per_page,
      posts_per_page: setting.posts_per_page,
      facebook_page_id: setting.facebook_page_id,
      google_page_id: setting.google_page_id,
      twitter_page_id: setting.twitter_page_id,
      instagram_page_id: setting.instagram_page_id,
      linkedin_page_id: setting.linkedin_page_id,
      whatsapp_page_id: setting.whatsapp_page_id,
      skype_page_id: setting.skype_page_id,
      youtube_page_id: setting.youtube_page_id,
      cpv_admin: setting.cpv_admin,
      cpv_advertiser: setting.cpv_advertiser,
      cpc_admin: setting.cpc_admin,
      cpc_advertiser: setting.cpc_advertiser,
      featured_post_ad: setting.featured_post_ad,
      featured_mid_ad: setting.featured_mid_ad,
      featured_pre_ad: setting.featured_pre_ad,
      default_ads_url: setting.default_ads_url,
      enable_landing_page: setting.enable_landing_page,
      free_registration: setting.free_registration,
      activation_email: setting.activation_email,
      premium_upgrade: setting.premium_upgrade,
      access_free: setting.access_free,
      payout_method: setting.payout_method,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    // console.log('append data',  logo,  favicon, );

    const formData = new FormData();

    formData.append("logo", logo);
    formData.append("favicon", favicon);
    formData.append("login_content", login_content);
    formData.append("notification_icon", notification_icon);
    formData.append("watermark", watermark);
    formData.append("default_video_image", default_video_image);
    formData.append("default_horizontal_image", default_horizontal_image);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store_image`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          cache: "no-cache",
          credentials: "same-origin",
          redirect: "follow",
          referrer: "no-referrer",
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/age/index')
          // window.location.reload();
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });

    const form = new FormData();

    form.append("rtmp_url", rtmp_url);
    form.append("hls_url", hls_url);

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/store`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/age/index')
          // window.location.reload();
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });

    const editInput = {
      header_script: script.header_script,
      footer_script: script.footer_script,
    };
    // console.log(editInputvalue);
    let response = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/script_setting/store`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(editInput),
      }
    );

    // console.log(resjson)
    if (res.status === 200) {
      setMessage(response.success);
      // alert('Success')
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    const editInputrtmp = {
      rtmp_url: editrtmp.rtmp_url,
      hls_url: editrtmp.hls_url,
    };
    // console.log(editInputvalue);
    let responsertmp = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/store`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(editInputrtmp),
      }
    );

    // console.log(resjson)
    if (responsertmp.status === 200) {
      setMessage(responsertmp.success);
      // alert('Success')
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    // const editInputcapchass = { captcha_site_key: capcha.captcha_site_key, captcha_secret_key: capcha.captcha_secret_key, enable_captcha: capcha.enable_captcha, };
    // // console.log(editInputvalue);
    // let responsecapcha = await fetch("https://api.flicknexs.com/admin/captcha/store", {
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify(editInputcapchass)
    // });

    // // console.log(resjson)
    // if (responsecapcha.status ==== 200) {
    //   setMessage(responsecapcha.success);
    //   // alert('Success')
    //   // window.location.reload();
    //   setTimeout(() => {
    //   }, 2000);
    // } else {
    //   setMessage("Some error Occured");
    //   console.log('Error');
    //   alert('Enter Correct Details')
    // }

    const editInputapp = {
      android_url: app_settings.android_url,
      ios_url: app_settings.ios_url,
      android_tv: app_settings.android_tv,
      status: app_settings.status,
    };
    // console.log(editInputvalue);
    let respon = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/app_setting/store`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(editInputapp),
      }
    );

    // console.log(resjson)
    if (res.status === 200) {
      setMessage(respon.success);
      // alert('Success')
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    const editInputcapcha = {
      captcha_site_key: capcha.captcha_site_key,
      captcha_secret_key: capcha.captcha_secret_key,
      enable_captcha: capcha.enable_captcha,
    };
    // console.log(editInputvalue);
    let capchas = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/captcha/store`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify(editInputcapcha),
      }
    );

    // console.log(resjson)
    if (res.status === 200) {
      setMessage(capchas.success);
      // alert('Success')
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    // const editInputrtmp = { rtmp_url: rtmp.rtmp_url, hls_url: rtmp.hls_url, };
    // // console.log(editInputvalue);
    // let rtmps = await fetch("https://api.flicknexs.com/admin/setting/RTMP/store", {
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify(editInputrtmp)
    // });

    // // console.log(resjson)
    // if (res.status ==== 200) {
    //   setMessage(rtmps.success);
    //   // alert('Success')
    //   // window.location.reload();
    //   setTimeout(() => {
    //   }, 2000);
    // } else {
    //   setMessage("Some error Occured");
    //   console.log('Error');
    //   alert('Enter Correct Details')
    // }
  };

  const options = [
    { label: "240 P ", value: "240 p" },
    { label: "360 P", value: "360 p" },
    { label: "480 P", value: "480 p" },
    { label: "720 P", value: "720 p" },
    { label: "1080 P", value: "1080 p" },
  ];

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };
  const editOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/edit/` + id, {
        headers: headers,
      })
      .then((response) => {
        // alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        // window.location.reload();
      });
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid dashboard p-2">
            <div className="iq-card">
              <div className="d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    RTMP handleUpdateing URL Settings{" "}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Site Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    PPV Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Video Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-reg-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-reg"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-reg"
                    aria-selected="false"
                  >
                    Registration Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-email-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-email"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-email"
                    aria-selected="false"
                  >
                    Email Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-soc-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-soc"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-soc"
                    aria-selected="false"
                  >
                    Social Networks Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-series-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-series"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-series"
                    aria-selected="false"
                  >
                    Series Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-code-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-code"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-code"
                    aria-selected="false"
                  >
                    {" "}
                    Transcoding Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-coupon-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-coupon"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-coupon"
                    aria-selected="false"
                  >
                    Coupon Code Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-login-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-login"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-login"
                    aria-selected="false"
                  >
                    Login Page Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-ads-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-ads"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-ads"
                    aria-selected="false"
                  >
                    Advertisement Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-app-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-app"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-app"
                    aria-selected="false"
                  >
                    APP Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-scp-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-scp"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-scp"
                    aria-selected="false"
                  >
                    Script Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-img-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-img"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-img"
                    aria-selected="false"
                  >
                    {" "}
                    Default Image Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-pay-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-pay"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-pay"
                    aria-selected="false"
                  >
                    Payouts Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-rec-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-rec"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-rec"
                    aria-selected="false"
                  >
                    Recaptcha Settings
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-time-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-time"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-time"
                    aria-selected="false"
                  >
                    TimeZone Settings
                  </button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="" id="rtmp_url">
                      <h5>RTMP Video handleUpdateing</h5>
                      <div className="row p-0">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div
                              className="panel panel-primary p-0"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title ml-3 d-flex justify-content-between">
                                  <label>RTMP URL</label>
                                </div>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    name="add"
                                    id="add"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    className="btn btn-success add"
                                  >
                                    Add{" "}
                                  </button>
                                </div>

                                <div
                                  className="modal fade"
                                  id="exampleModal"
                                  tabindex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          ADD RTMP
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <div>
                                          <label>RTMP URL</label>

                                          <input
                                            type="text"
                                            name="rtmp_url"
                                            placeholder="rtmp://75.119.145.126:1935/show/"
                                            className="form-control rtmp_urls"
                                            onChange={(e) =>
                                              setRtmp_url(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <label>HLS URL</label>
                                          <input
                                            type="text"
                                            name="hls_url"
                                            placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8"
                                            className="form-control rtmp_urls"
                                            onChange={(e) =>
                                              setHls_url(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        {/* <button  onClick={handleUpdate} >submit</button> */}
                                        <input
                                          type="submit"
                                          id="appupdate"
                                          value="Update RTMP URL Settings"
                                          onClick={handleUpdate}
                                          className="btn btn-primary ml-3 "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <table className="table mt-4" id="dynamicTable">
                                  {/* <tr>
                                  <td className="col-md-4" >
                                    <input type="text" name="addmore[0][rtmp_url]" placeholder="rtmp://75.119.145.126:1935/show/" className="form-control rtmp_urls" value="" /></td>
                                  <td className="col-md-4" > 
                                  <input type="text" name="addmore[0][hls_url]" placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8" className="form-control rtmp_urls" value="" readonly /></td>
                                
                                  <td className="col-md-4">
                                    <button type="button" name="add" id="add" className="btn btn-success add">Add </button>
                                    <button type="button" name="remove_url" id="remove_url" className="btn btn-danger remove_url" data-name="{{ $url->id }}" value="{{ $url->rtmp_url }}" onclick="addRow(this)" >Remove</button>
                                  </td>
                                </tr>
                             

                                <tr>
                                  <td ><input type="text" name="addmore[0][rtmp_url]" placeholder=" rtmp://75.119.145.126:1935/show" className="form-control" /></td>
                                  <td ><input type="text" name="addmore[0][hls_url]" placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8" className="form-control" /></td>

                                  <td >
                                    <button type="button" name="add" id="add" className="btn btn-success add">Add </button>
                                  </td>
                                </tr> */}

                                  {rtmp?.map((item) => (
                                    <thead key={item?.id}>
                                      <tr>
                                        <td className="col-md-4">
                                          <input
                                            type="text"
                                            name="rtmp_url"
                                            value={item?.rtmp_url}
                                            placeholder="rtmp://75.119.145.126:1935/show/"
                                            className="form-control rtmp_urls"
                                            readOnly
                                          />
                                        </td>
                                        <td className="col-md-4">
                                          <input
                                            type="text"
                                            name="hls_url"
                                            value={item?.hls_url}
                                            placeholder="rtmp://75.119.145.126:1935/show/"
                                            className="form-control rtmp_urls"
                                            readOnly
                                          />
                                        </td>

                                        <td className="col-md-4">
                                          {/* <button type="button" name="edit" className="btn btn-primary edit"  onClick={() => editOperation(item.id)} data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Edit</button> */}
                                          <button
                                            type="button"
                                            name="remove_url"
                                            onClick={() =>
                                              deleteOperation(item?.id)
                                            }
                                            className="btn btn-danger remove_url"
                                          >
                                            Remove
                                          </button>
                                        </td>
                                      </tr>
                                    </thead>
                                  ))}
                                </table>
                              </div>
                              <div
                                className="modal fade"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="staticBackdropLabel"
                                      >
                                        EDIT RTMP
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div>
                                        <label>RTMP URL</label>

                                        <input
                                          type="text"
                                          name="rtmp_url"
                                          placeholder="rtmp://75.119.145.126:1935/show/"
                                          value={editrtmp?.rtmp_url}
                                          className="form-control rtmp_urls"
                                          onChange={(e) =>
                                            setRtmp_url(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label>HLS URL</label>
                                        <input
                                          type="text"
                                          name="hls_url"
                                          placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8"
                                          value={editrtmp?.hls_url}
                                          className="form-control rtmp_urls"
                                          onChange={(e) =>
                                            setHls_url(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      {/* <button  onClick={handleUpdate} >submit</button> */}
                                      <input
                                        type="submit"
                                        id="appupdate"
                                        value="Update RTMP URL Settings"
                                        onClick={handleUpdate}
                                        className="btn btn-primary ml-3 "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="row mt-4 justify-content-end">
                            <div className="col-md-6 " >
                              <input type="submit" id="appupdate" value="Update RTMP URL Settings" className="btn btn-primary ml-3 " />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="" id="site">
                      <div className="panel-title">
                        <label>Site Description</label>
                      </div>
                      <hr />
                      <div className="row p-2">
                        <div className="col-md-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-body">
                              <p className="">Enter Your Website Name Below:</p>
                              <input
                                type="text"
                                className="form-control"
                                name="website_name"
                                id="website_name"
                                placeholder="Site Title"
                                onChange={handleInput}
                                value={setting?.website_name}
                              />
                            </div>
                          </div>

                          <div
                            className="panel panel-primary mt-4"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>
                                  Logo
                                  <small>(Dimensions: 180px X 29px)</small>
                                </label>
                              </div>

                              <div className="row d-flex align-items-center">
                                <div className="panel-body col-md-6">
                                  <p className="">
                                    Enter Your Logo Width Below:
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="logo_width"
                                    id="logo_width"
                                    placeholder="Logo Width"
                                    value={setting?.logo_width}
                                    onChange={handleInput}
                                  />
                                </div>

                                <div className="panel-body col-md-6">
                                  <p className="">
                                    Enter Your Logo Height Below:
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="logo_height"
                                    id="logo_height"
                                    placeholder="Logo Height"
                                    value={setting?.logo_height}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>

                              <div className="panel-body">
                                <img src={logo} />

                                <p className="">Upload Your Site Logo:</p>
                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="logo"
                                  id="logo"
                                  onChange={(e) => setLogo(e.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 ">
                          <div className="panel-body">
                            <p className="">
                              Enter Your Website Description Below:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="website_description"
                              id="website_description"
                              placeholder="Site Description"
                              value={setting?.website_description}
                              onChange={handleInput}
                            />
                          </div>

                          <div className=" mt-4 pt-3  p-0" data-collapsed="0">
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Favicon</label>
                              </div>
                            </div>
                            <div className="panel-body">
                              <img src={favicon} />

                              <p className="">Upload Your Site Favicon:</p>
                              <input
                                type="file"
                                multiple="true"
                                className="form-control"
                                name="favicon"
                                id="favicon"
                                onChange={(e) => setFavicon(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div className=" row align-items-center " id="ppv">
                      <div className="col-lg-12">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Pay per View</label>
                            </div>
                          </div>
                          <div className="panel-body row">
                            <div className="col-lg-6">
                              <p className="mt-2">Enable Pay per View:</p>
                            </div>
                            <div className="form-group col-lg-4">
                              {/* <input type="checkbox" name="ppv_status" id="ppv_status" /> */}
                              {/* <select className="form-select" name='ppv_status' onChange={handleInput} >
                                <option defaultValue={setting.ppv_status}>Choose (Yes or No)</option>
                                <option value="1" >Yes</option>
                                <option value="0">No</option>
                              </select> */}
                              <label className="switch">
                                <input
                                  name="ppv_status"
                                  onChange={handleInput}
                                  defaultChecked={
                                    setting?.ppv_status === 1 ? true : false
                                  }
                                  checked={
                                    setting?.ppv_status === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="ppv_status"
                                  onChange={handleInput}
                                  value={setting?.ppv_status === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 p-2">
                        <div className="col-sm-6" id="Pay_Per_view_Hours">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Pay Per view Hours</label>
                              </div>
                            </div>
                            <div className="panel-body">
                              <p className="">Hours :</p>
                              <div className="form-group">
                                <div
                                  className="make-switch"
                                  data-on="success"
                                  data-off="warning"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="ppv_hours"
                                    id="ppv_hours"
                                    placeholder="# of pay Per view hours"
                                    onChange={handleInput}
                                    value={setting?.ppv_hours}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6" id="PPV_Global_Price">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>PPV Global Price</label>{" "}
                              </div>
                            </div>
                            <div className="panel-body">
                              <p className="">PPV / Movie Price (USD):</p>
                              <div className="form-group">
                                <div
                                  className="make-switch"
                                  data-on="success"
                                  data-off="warning"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ppv_price"
                                    id="ppv_price"
                                    placeholder="# of PPV Global Price"
                                    onChange={handleInput}
                                    value={setting?.ppv_price}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 p-2">
                        <div className="col-sm-6" id="Pay_Per_view_Hours">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>IOS PRODUCT ID</label>
                              </div>
                            </div>
                            <div className="panel-body">
                              <p className="">IOS Product Id :</p>
                              <div className="form-group">
                                <div
                                  className="make-switch"
                                  data-on="success"
                                  data-off="warning"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ios_product_id"
                                    id="ios_product_id"
                                    placeholder=""
                                    onChange={handleInput}
                                    value={setting?.ios_product_id}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6" id="PPV_Global_Price">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>IOS Global Price</label>{" "}
                              </div>
                            </div>
                            <div className="panel-body">
                              <p className="">IOS Plan Price:</p>
                              <div className="form-group">
                                <div
                                  className="make-switch"
                                  data-on="success"
                                  data-off="warning"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ios_plan_price"
                                    id="ios_plan_price"
                                    placeholder="# of PPV Global IOS Price"
                                    onChange={handleInput}
                                    value={setting?.ios_plan_price}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label> Set Expiry time </label>{" "}
                                <span> (If Live Video Started) </span>
                              </div>
                            </div>
                            <div className="panel-body">
                              <div className="form-group">
                                <div
                                  className="make-switch"
                                  data-on="success"
                                  data-off="warning"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="expiry_time_started"
                                    id="expiry_time_started"
                                    placeholder="Set Expiry time (HH)"
                                    onChange={handleInput}
                                    value={setting?.expiry_time_started}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label> Set Expiry time </label>{" "}
                                <span> (If Live Video Not Started) </span>{" "}
                              </div>
                            </div>

                            <div className="row">
                              <div className="panel-body col-md-4">
                                <div className="form-group">
                                  <p className=""> Days </p>
                                  <div
                                    className="make-switch"
                                    data-on="success"
                                    data-off="warning"
                                  >
                                    <select
                                      id="expiry_day_notstarted"
                                      className="form-control"
                                      name="expiry_day_notstarted"
                                      onChange={handleInput}
                                      value={setting?.expiry_day_notstarted}
                                    >
                                      <option
                                        value={setting?.expiry_day_notstarted}
                                      >
                                        {setting?.expiry_day_notstarted}
                                      </option>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="panel-body col-md-4">
                                <div className="form-group">
                                  <p className=""> Hours </p>
                                  <div
                                    className="make-switch"
                                    data-on="success"
                                    data-off="warning"
                                  >
                                    <select
                                      id="expiry_hours_notstarted"
                                      className="form-control"
                                      name="expiry_hours_notstarted"
                                      onChange={handleInput}
                                      value={setting?.expiry_hours_notstarted}
                                    >
                                      <option
                                        value={setting?.expiry_hours_notstarted}
                                      >
                                        {setting?.expiry_hours_notstarted}
                                      </option>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="panel-body col-md-4">
                                <div className="form-group">
                                  <p className=""> Minutes </p>
                                  <div
                                    className="make-switch"
                                    data-on="success"
                                    data-off="warning"
                                  >
                                    <select
                                      id="expiry_min_notstarted"
                                      className="form-control"
                                      name="expiry_min_notstarted"
                                      onChange={handleInput}
                                      value={setting?.expiry_min_notstarted}
                                    >
                                      <option
                                        value={setting?.expiry_min_notstarted}
                                      >
                                        {setting?.expiry_min_notstarted}
                                      </option>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-end p-3">
                          <input
                            type="submit"
                            id="appupdate"
                            value="Update Setting"
                            onClick={handleUpdate}
                            className="btn btn-primary ml-3 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="  " id="videos_settings">
                      <div className="row">
                        <div className="col-sm-6 ">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Videos Per Page</label>
                              </div>
                              <div className="panel-options">
                                {" "}
                                <p className="">
                                  Default number of videos to show per page:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="videos_per_page"
                                  id="videos_per_page"
                                  placeholder="# of Videos Per Page"
                                  onChange={handleInput}
                                  value={setting?.videos_per_page}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Posts Per Page</label>
                              </div>
                              <div className="panel-options">
                                {" "}
                                <p className="">
                                  Default number of posts to show per page:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="posts_per_page"
                                  id="posts_per_page"
                                  placeholder="# of Posts Per Page"
                                  onChange={handleInput}
                                  value={setting?.posts_per_page}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end p-3">
                      <input
                        type="submit"
                        id="appupdate"
                        value="Update Setting"
                        onClick={handleUpdate}
                        className="btn btn-primary ml-3 "
                      />
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-reg"
                    role="tabpanel"
                    aria-labelledby="v-pills-reg-tab"
                  >
                    <div className="" id="registration">
                      <div className="panel panel-primary " data-collapsed="0">
                        <div className="panel-heading">
                          <h5 className="panel-title mb-4">Registration</h5>
                          <hr />
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-sm-10">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Enable Free Registration{" "}
                                  </label>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>OFF</div>

                                  <div className="mt-1">
                                    <label className="switch">
                                      <input
                                        name="free_registration"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.free_registration === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.free_registration === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="free_registration"
                                        onChange={handleInput}
                                        value={
                                          setting?.free_registration === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  <div>ON</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-10">
                              <div className="input-group color-picker align-items-center justify-content-between">
                                <label className="mt-1">
                                  {" "}
                                  Require users to verify account by email:{" "}
                                </label>
                                <div className="d-flex">
                                  <div>OFF</div>
                                  <div className="mt-1">
                                    <label className="switch">
                                      <input
                                        name="activation_email"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.activation_email === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.activation_email === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="activation_email"
                                        onChange={handleInput}
                                        value={
                                          setting?.activation_email === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  <div>ON</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-10">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <label className="mt-1 col-lg-7 p-0">
                                  {" "}
                                  Enable registered users ability to upgrade to
                                  subscriber:
                                </label>
                                <div className="d-flex">
                                  <div>OFF</div>
                                  <div className="mt-1">
                                    <label className="switch">
                                      <input
                                        name="premium_upgrade"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.premium_upgrade === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.premium_upgrade === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="premium_upgrade"
                                        onChange={handleInput}
                                        value={
                                          setting?.premium_upgrade === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  <div>ON</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-10">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Can Access Free Content:{" "}
                                  </label>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>OFF</div>
                                  <div className="mt-1">
                                    <label className="switch">
                                      <input
                                        name="access_free"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.access_free === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.access_free === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="access_free"
                                        onChange={handleInput}
                                        value={
                                          setting?.access_free === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  <div>ON</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-10">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Enable Landing Page:{" "}
                                  </label>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>OFF</div>
                                  <div className="mt-1">
                                    <label className="switch">
                                      <input
                                        name="enable_landing_page"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.enable_landing_page === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.enable_landing_page === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="enable_landing_page"
                                        onChange={handleInput}
                                        value={
                                          setting?.enable_landing_page === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  <div>ON</div>
                                </div>
                              </div>
                            </div>
                            <div className="text-end p-3">
                              <input
                                type="submit"
                                id="appupdate"
                                value="Update Setting"
                                onClick={handleUpdate}
                                className="btn btn-primary ml-3 "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-email"
                    role="tabpanel"
                    aria-labelledby="v-pills-email-tab"
                  >
                    <div className="  " id="email">
                      <div className="row">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>System Email</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="panel-body">
                            <p className="">
                              Email address to be used to send system emails:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="system_email"
                              id="system_email"
                              placeholder="Email Address"
                              value={setting?.system_email}
                              onChange={handleInput}
                            />
                          </div>
                          <div
                            className="panel panel-primary mt-3"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Google Analytics Tracking ID</label>
                              </div>
                            </div>
                            <div className="panel-body">
                              <p className="">
                                Google Analytics Tracking ID (ex.
                                UA-12345678-9)::
                              </p>
                              <input
                                type="text"
                                className="form-control"
                                name="google_tracking_id"
                                id="google_tracking_id"
                                placeholder="Google Analytics Tracking ID"
                                onChange={handleInput}
                                value={setting?.google_tracking_id}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <p className="">
                                  Google Analytics API Integration (This will
                                  integrate with your dashboard analytics)
                                </p>
                              </div>
                            </div>
                            <div className="panel-body mt-1">
                              <label>Google Oauth Client ID Key:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="google_oauth_key"
                                id="google_oauth_key"
                                placeholder="Google Client ID Key"
                                onChange={handleInput}
                                value={setting?.google_oauth_key}
                              />
                            </div>
                          </div>
                          <div
                            className="panel panel-primary mt-3"
                            data-collapsed="0"
                          >
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Refferal Settings</label>{" "}
                              </div>
                            </div>
                            <div className="panel-body d-flex justify-content-between">
                              <label className="panel-title">
                                Enable / Disable:
                              </label>
                              {/* <label className="switch">
                                <input name="coupon_status" onChange={handleInput} value={(setting.coupon_status === 1 || setting.coupon_status === null) ? '0' : (setting.coupon_status === 1) ? '0' : '1'} className="coupon_status" id="coupon_status" type="checkbox" />
                                <span className="slider round" name="coupon_status" onChange={handleInput} value={(setting.coupon_status === 1 || setting.coupon_status === null) ? '0' : (setting.coupon_status === 1) ? '0' : '1'} ></span>
                              </label> */}

                              <label className="switch">
                                <input
                                  name="coupon_status"
                                  onChange={handleInput}
                                  defaultChecked={
                                    setting?.coupon_status === 1 ? true : false
                                  }
                                  checked={
                                    setting?.coupon_status === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="coupon_status"
                                  onChange={handleInput}
                                  value={
                                    setting?.coupon_status === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-end p-3">
                          <input
                            type="submit"
                            id="appupdate"
                            value="Update Setting"
                            onClick={handleUpdate}
                            className="btn btn-primary ml-3 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-soc"
                    role="tabpanel"
                    aria-labelledby="v-pills-soc-tab"
                  >
                    <div className="" id="social">
                      <div className="panel panel-primary" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Social Networks</label>
                          </div>
                          <hr />
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-body">
                              <p className="">
                                Facebook Page ID: ex. facebook.com/page_id
                                (without facebook.com):
                              </p>
                              <input
                                type="text"
                                className="form-control"
                                name="facebook_page_id"
                                id="facebook_page_id"
                                placeholder="Facebook Page"
                                value={setting?.facebook_page_id}
                                onChange={handleInput}
                              />
                              <br />
                              <p className="">Google Plus User ID:</p>
                              <input
                                type="text"
                                className="form-control"
                                name="google_page_id"
                                id="google_page_id"
                                placeholder="Google Plus Page"
                                value={setting?.google_page_id}
                                onChange={handleInput}
                              />
                              <br />
                              <p className="">Twitter Username:</p>
                              <input
                                type="text"
                                className="form-control "
                                name="twitter_page_id"
                                id="twitter_page_id"
                                placeholder="Twitter Username"
                                value={setting?.twitter_page_id}
                                onChange={handleInput}
                              />
                              <br />
                              <p className=""> Instagram :</p>
                              <input
                                type="text"
                                className="form-control "
                                name="instagram_page_id"
                                id="instagram_page_id"
                                placeholder="Instagram "
                                value={setting?.instagram_page_id}
                                onChange={handleInput}
                              />
                              <br />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p className=""> Linkedin:</p>
                            <input
                              type="text"
                              className="form-control mt-4"
                              name="linkedin_page_id"
                              id="linkedin_page_id"
                              placeholder="Linkedin "
                              value={setting?.linkedin_page_id}
                              onChange={handleInput}
                            />
                            <br />
                            <p className="">Whatsapp :</p>
                            <input
                              type="text"
                              className="form-control"
                              name="whatsapp_page_id"
                              id="whatsapp_page_id"
                              placeholder="Whatsapp "
                              value={setting?.whatsapp_page_id}
                              onChange={handleInput}
                            />
                            <br />
                            <p className="">Skype :</p>
                            <input
                              type="text"
                              className="form-control"
                              name="skype_page_id"
                              id="skype_page_id"
                              placeholder="Skype "
                              value={setting?.skype_page_id}
                              onChange={handleInput}
                            />
                            <br />
                            <p className="">
                              YouTube Channel ex. youtube.com/channel_name:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="youtube_page_id"
                              id="youtube_page_id"
                              placeholder="YouTube Channel"
                              value={setting?.youtube_page_id}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-series"
                    role="tabpanel"
                    aria-labelledby="v-pills-series-tab"
                  >
                    <div className="" id="seasonsetting">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="panel-heading ">
                            <div className="panel-title">
                              <label>Series Setting</label>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div>
                                  <div>
                                    <p className="mt-1">Enable PPV Season</p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="mr-2">OFF</div>

                                    <div className="mt-1">
                                      <label className="switch">
                                        <input
                                          name="series_season"
                                          onChange={handleInput}
                                          defaultChecked={
                                            setting?.series_season === 1
                                              ? true
                                              : false
                                          }
                                          checked={
                                            setting?.series_season === 1
                                              ? true
                                              : false
                                          }
                                          type="checkbox"
                                        />
                                        <span
                                          className="slider round"
                                          name="series_season"
                                          onChange={handleInput}
                                          value={
                                            setting?.series_season === 1
                                              ? "1"
                                              : "0"
                                          }
                                        ></span>
                                      </label>
                                      {/* <label className="switch">
                                        <input name="series_season" onChange={handleInput} value={(setting.series_season === 1 || setting.series_season === null) ? '0' : (setting.series_season === 1) ? '0' : '1'} type="checkbox" />
                                        <span className="slider round" name="series_season" onChange={handleInput} value={(setting.series_season === 1 || setting.series_season === null) ? '0' : (setting.series_season === 1) ? '0' : '1'} ></span>
                                      </label> */}
                                    </div>
                                    <div className="ml-2">On</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-end p-3">
                          <input
                            type="submit"
                            id="appupdate"
                            value="Update Setting"
                            onClick={handleUpdate}
                            className="btn btn-primary ml-3 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-code"
                    role="tabpanel"
                    aria-labelledby="v-pills-code-tab"
                  >
                    <div className="" id="transcodingsetting">
                      <div
                        className="panel panel-primary mt-3"
                        data-collapsed="0"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Transcoding Setting</label>
                          </div>
                          <hr />
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-12 align-center">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    {/* <label className="switch">
                                      <input name="transcoding_access" onChange={handleInput} value={(setting.transcoding_access === 1 || setting.transcoding_access === null) ? '0' : (setting.transcoding_access === 1) ? '0' : '1'} type="checkbox" />
                                      <span className="slider round" name="transcoding_access" onChange={handleInput} value={(setting.transcoding_access === 1 || setting.transcoding_access === null) ? '0' : (setting.transcoding_access === 1) ? '0' : '1'} ></span>
                                    </label> */}
                                    <label className="switch">
                                      <input
                                        name="transcoding_access"
                                        onChange={handleInput}
                                        defaultChecked={
                                          setting?.transcoding_access === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          setting?.transcoding_access === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="transcoding_access"
                                        onChange={handleInput}
                                        value={
                                          setting?.transcoding_access === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>

                                <div className="mt-3  align-items-center justify-content-around">
                                  <label className="m-0">
                                    Select Transcoding Resolution :
                                  </label>
                                  {/* <select className="form-control js-example-basic-multiple" value={setting.transcoding_resolution} onChange={handleInput}>
                                    <option value="240p"  onChange={handleInput} > 240 P </option>
                                    <option value="360p"  onChange={handleInput}> 360 P </option>
                                    <option value="480p" onChange={handleInput}> 480 P </option>
                                    <option value="720p" onChange={handleInput}> 720 P </option>
                                    <option value="1080p" onChange={handleInput}> 1080 P </option>
                                  </select> */}
                                  {/* <select className="form-select" name='ppv_status' onChange={handleInput} >
                                    <option value="240p" onChange={handleInput} > 240 P </option>
                                    <option value="360p" onChange={handleInput}> 360 P </option>
                                    <option value="480p" onChange={handleInput}> 480 P </option>
                                    <option value="720p" onChange={handleInput}> 720 P </option>
                                    <option value="1080p" onChange={handleInput}> 1080 P </option>
                                  </select> */}

                                  <Multiselect
                                    onChange={handleInput}
                                    options={options}
                                    name="transcoding_resolution"
                                    displayValue={"value"}
                                    showCheckbox
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-coupon"
                    role="tabpanel"
                    aria-labelledby="v-pills-coupon-tab"
                  >
                    <div className="" id="subscription">
                      <div>
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              {" "}
                              <label>
                                Settings For New Subscription Coupon Code{" "}
                              </label>{" "}
                            </div>
                            <hr />
                          </div>
                          <div className="row align-items-center">
                            <div className="panel-body d-flex align-items-baseline">
                              <div className="col-lg-6">
                                <p className="panel-title mr-2">
                                  Coupon Enable / Disable:
                                </p>
                              </div>
                              <div className="col-lg-4">
                                {/* <input type="type" name='coupon_code' value={setting.coupon_code} onChange={handleInput} /> */}
                                {/* <input type="type" name='earn_amount' value={setting.earn_amount} onChange={handleInput} hidden /> */}
                                {/* <select className="form-select" name='new_subscriber_coupon' onChange={handleInput} >
                                  <option defaultValue={setting.new_subscriber_coupon}>Choose (Yes or No)</option>
                                  <option value="1" >Yes</option>
                                  <option value="0">No</option>
                                </select> */}

                                <label className="switch">
                                  <input
                                    name="earn_amount"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.earn_amount === 1 ? true : false
                                    }
                                    checked={
                                      setting?.earn_amount === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="earn_amount"
                                    onChange={handleInput}
                                    value={
                                      setting?.earn_amount === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="panel-body mt-3">
                                <label className="panel-title">
                                  Discount %:
                                </label>
                                <div className="form-group add-profile-pic checkbox">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="discount_percentage"
                                    value={setting?.discount_percentage}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 mt-3">
                              <div className="panel-body">
                                <label className="panel-title">
                                  Coupon Code:
                                </label>
                                <div className="form-group add-profile-pic checkbox">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="coupon_code"
                                    value={setting?.coupon_code}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-login"
                    role="tabpanel"
                    aria-labelledby="v-pills-login-tab"
                  >
                    <div className="" id="login">
                      <div className="col-md-12">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Login Page Content Image</label>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body mt-3">
                            <p>Login Page Content:</p>
                            <div className="form-group add-profile-pic">
                              <img src={setting?.login_content} />
                              <input
                                id="f02"
                                className="ms-2"
                                type="file"
                                onChange={(e) =>
                                  setLogin_content(e.target.files[0])
                                }
                                name="login_content"
                                placeholder="Upload Image"
                              />
                              <p className="padding-top-20 mt-2">
                                Must be JPEG, PNG, or GIF and cannot exceed
                                10MB.
                              </p>
                            </div>
                            <div className="form-group add-profile-pic">
                              <label>Login Text:</label>
                              <input
                                id="login_text"
                                type="text"
                                name="login_text"
                                className="form-control"
                                placeholder="Login Text"
                                value={setting?.login_text}
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Email Signature </label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <div className="form-group add-profile-pic">
                              <p className="">Email Signature:</p>
                              <textarea
                                id="summary-ckeditor"
                                name="signature"
                                className="form-control"
                                placeholder="Email signature"
                                value={setting?.signature}
                                onChange={handleInput}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Pusher Notification</label>
                                </div>
                              </div>
                              <div className="panel-body">
                                <p className="">Notification Server Key:</p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="notification_key"
                                  id="notification_key"
                                  placeholder="Notification Server Key"
                                  value={setting?.notification_key}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          <div className="panel-heading">
                            <div className="panel-title">Notification Icon</div>
                          </div>
                          <div className="panel-body">
                            <img src="" />

                            <p>Upload Your Site Notification Icon:</p>
                            {/* <input type="file" multiple="true" className="form-control" name="notification_icon"
                              onChange={e => setNotification_icon(e.target.files[0])}
                              id="notification_icon" /> */}
                            <div className="">
                              <img
                                src={setting?.notification_icon}
                                alt="Upload Your Site Notification Icon:"
                              />
                            </div>
                            <div className="mt-3">
                              <input
                                id="notification_icon"
                                className="ms-2"
                                type="file"
                                onChange={(e) =>
                                  setNotification_icon(e.target.files[0])
                                }
                                name="notification_icon"
                                placeholder="Notification Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-ads"
                    role="tabpanel"
                    aria-labelledby="v-pills-ads-tab"
                  >
                    <div className="" id="advertisement">
                      <div
                        className="panel panel-primary mt-3"
                        data-collapsed="0"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Advertisement</label>
                          </div>
                          <hr />
                        </div>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <div className="col-lg-6">
                          <p className="">Enable Advertisement on Videos:</p>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <div
                              className="make-switch"
                              data-on="success"
                              data-off="warning"
                            >
                              {/* <select className="form-select" name='ads_on_videos' onChange={handleInput} >
                                <option defaultValue={setting.ads_on_videos}>Choose (Yes or No)</option>
                                <option value="1" >Yes</option>
                                <option value="0">No</option>
                              </select> */}
                              <label className="switch">
                                <input
                                  name="ads_on_videos"
                                  onChange={handleInput}
                                  defaultChecked={
                                    setting?.ads_on_videos === 1 ? true : false
                                  }
                                  checked={
                                    setting?.ads_on_videos === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="ads_on_videos"
                                  onChange={handleInput}
                                  value={
                                    setting?.ads_on_videos === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Default Ads url</label>
                            <input
                              id="default_ads"
                              type="text"
                              name="default_ads_url"
                              className="form-control"
                              placeholder="Default Ads in videos"
                              value={setting?.default_ads_url}
                              onChange={handleInput}
                            />
                          </div>

                          <div className="form-group add-profile-pic">
                            <label>Featured Ad Pre Roll:</label>
                            <input
                              id="featured_pre_ad"
                              type="text"
                              name="featured_pre_ad"
                              className="form-control"
                              placeholder="Featured Ad Pre Roll"
                              value={setting?.featured_pre_ad}
                              onChange={handleInput}
                            />
                          </div>
                          <div className="form-group add-profile-pic">
                            <label>Featured Ad Mid Roll:</label>
                            <input
                              id="featured_mid_ad"
                              type="text"
                              name="featured_mid_ad"
                              className="form-control"
                              placeholder="Featured Ad Mid Roll"
                              value={setting?.featured_mid_ad}
                              onChange={handleInput}
                            />
                          </div>
                          <div className="form-group add-profile-pic">
                            <label>Featured Ad Post Roll:</label>
                            <input
                              id="featured_post_ad"
                              type="text"
                              name="featured_post_ad"
                              className="form-control"
                              placeholder="Featured Ad Post Roll"
                              value={setting?.featured_post_ad}
                              onChange={handleInput}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-6">
                          <div className="form-group add-profile-pic">
                            <label>Cost Per Click Advertiser:</label>
                            <input
                              id="cpc_advertiser"
                              type="text"
                              name="cpc_advertiser"
                              className="form-control"
                              placeholder="Cost Per Click Advertiser"
                              value={setting?.cpc_advertiser}
                              onChange={handleInput}
                            />
                          </div>

                          <div className="form-group add-profile-pic">
                            <label>Cost Per Click Admin:</label>
                            <input
                              id="cpc_admin"
                              type="text"
                              name="cpc_admin"
                              className="form-control"
                              placeholder="Cost Per Click Admin"
                              value={setting?.cpc_admin}
                              onChange={handleInput}
                            />
                          </div>
                          <div className="form-group add-profile-pic">
                            <label>Cost Per View Advertiser:</label>
                            <input
                              id="cpv_advertiser"
                              type="text"
                              name="cpv_advertiser"
                              className="form-control"
                              placeholder="Cost Per View Advertiser"
                              value={setting?.cpv_advertiser}
                              onChange={handleInput}
                            />
                          </div>
                          <div className="form-group add-profile-pic">
                            <label>Cost Per View Admin:</label>
                            <input
                              id="cpv_admin"
                              type="text"
                              name="cpv_admin"
                              className="form-control"
                              placeholder="Cost Per View Admin"
                              value={setting?.cpv_admin}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-app"
                    role="tabpanel"
                    aria-labelledby="v-pills-app-tab"
                  >
                    <div className="" id="app">
                      <h5>App URL Settings for Playstore/Appstore:</h5>
                      <hr />
                      <div className="row">
                        <form
                          method="POST"
                          action=""
                          accept-charset="UTF-8"
                          file="1"
                          enctype="multipart/form-data"
                        >
                          <div className="row mt-4">
                            <div className="col-md-6">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label>Android URL</label>
                                  </div>{" "}
                                </div>
                                <div className="panel-body">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="android_url"
                                    id="android_url"
                                    value={app_settings?.android_url}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label>IOS URL</label>
                                  </div>{" "}
                                </div>
                                <div className="panel-body">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ios_url"
                                    id="ios_url"
                                    value={app_settings?.ios_url}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label>Android TV</label>
                                  </div>{" "}
                                </div>
                                <div className="panel-body">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="android_tv"
                                    id="android_tv"
                                    value={app_settings?.android_tv}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-end mt-3">
                              <input
                                type="submit"
                                id="appupdate"
                                value="Update Setting"
                                onClick={handleUpdate}
                                className="btn btn-primary ml-3 "
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-scp"
                    role="tabpanel"
                    aria-labelledby="v-pills-scp-tab"
                  >
                    <div className="" id="script">
                      <h5 className="mb-4">Header/Footer Scripts:</h5>
                      <hr />
                      <div className="row p-0">
                        <form>
                          <div className="col-md-12">
                            <h5>Header Script CDN:</h5>
                            <textarea
                              rows="5"
                              className="form-control"
                              name="header_script"
                              value={script?.header_script}
                              onChange={handleInput}
                              placeholder="Header Script"
                            ></textarea>

                            <h5 className="mt-3">Footer Script CDN:</h5>
                            <textarea
                              rows="5"
                              className="form-control"
                              name="footer_script"
                              placeholder="Footer Script"
                              value={script?.footer_script}
                              onChange={handleInput}
                            ></textarea>
                          </div>
                          <div className="col-md-12 mt-3 text-end">
                            <input
                              type="submit"
                              id="appupdate"
                              value="Update Setting"
                              onClick={handleUpdate}
                              className="btn btn-primary ml-3 "
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-img"
                    role="tabpanel"
                    aria-labelledby="v-pills-img-tab"
                  >
                    <div className="" id="Defaut_image_setting">
                      <div className="panel panel-primary " data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Default Image</label>
                          </div>
                          <hr />
                        </div>
                        <div className="">
                          <div className="">
                            <div className="">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="default_video_image">
                                    <img
                                      src={default_video_image}
                                      alt="default_video_image"
                                    />
                                  </div>
                                  <p className="">Upload Your Default Image:</p>
                                  <input
                                    type="file"
                                    multiple="true"
                                    className="form-control"
                                    name="default_video_image"
                                    id="default_video_image"
                                    onChange={(e) =>
                                      setDefault_video_image(e.target.files[0])
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="panel-heading">
                          <div className="panel-title">
                            <label> Horizontal Default Image</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="default_horizontal_image">
                              <img
                                src={default_horizontal_image}
                                alt="default_horizontal_image"
                              />
                            </div>
                            <p className="">Upload Your Default Image:</p>
                            <input
                              type="file"
                              multiple="true"
                              className="form-control"
                              name="default_horizontal_image"
                              id="default_horizontal_image"
                              onChange={(e) =>
                                setDefault_horizontal_image(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-pay"
                    role="tabpanel"
                    aria-labelledby="v-pills-pay-tab"
                  >
                    <div className="" id="cpp_payouts">
                      <div className="row" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Payouts Settings </label>
                          </div>
                          <hr />
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div>
                              <p className="mt-1"> </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="mr-2">Manual</div>

                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="payout_method"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.payout_method === 1 ? true : false
                                    }
                                    checked={
                                      setting?.payout_method === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="payout_method"
                                    onChange={handleInput}
                                    value={
                                      setting?.payout_method === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                              <div className="ml-2">PaymentGateWay</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-rec"
                    role="tabpanel"
                    aria-labelledby="v-pills-rec-tab"
                  >
                    <div className="" id="recpatcha_settings">
                      <h5>Re-captcha Settings</h5>
                      <hr />
                      <div className="row">
                        <form>
                          <div className="row mt-4">
                            <div className="col-sm-6" id="">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label> Captcha Site Key</label>
                                  </div>
                                </div>

                                <div className="panel-body">
                                  <div className="form-group">
                                    <div
                                      className="make-switch"
                                      data-on="success"
                                      data-off="warning"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="captcha_site_key"
                                        placeholder="Captcha Site Key"
                                        value={capcha?.captcha_site_key}
                                        onChange={handleInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6" id="">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label> Captcha Secret Key </label>{" "}
                                  </div>
                                </div>

                                <div className="panel-body">
                                  <div className="form-group">
                                    <div
                                      className="make-switch"
                                      data-on="success"
                                      data-off="warning"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="captcha_secret_key"
                                        s
                                        placeholder="Captcha Secret Key"
                                        required
                                        value={capcha?.captcha_secret_key}
                                        onChange={handleInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6" id="">
                              <div
                                className="panel panel-primary"
                                data-collapsed="0"
                              >
                                <div className="panel-heading mt-2">
                                  <div className="panel-title">
                                    <label>Enable Captcha </label>
                                  </div>
                                </div>

                                <div className="panel-body">
                                  <div className="form-group mt-2">
                                    <div className="d-flex ">
                                      <div>OFF</div>
                                      <div className="">
                                        <label className="switch">
                                          <input
                                            name="enable_captcha"
                                            onChange={handleInput}
                                            defaultChecked={
                                              capcha?.enable_captcha === 1
                                                ? true
                                                : false
                                            }
                                            checked={
                                              capcha?.enable_captcha === 1
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                          />
                                          <span
                                            className="slider round"
                                            name="enable_captcha"
                                            onChange={handleInput}
                                            value={
                                              capcha?.enable_captcha === 1
                                                ? "1"
                                                : "0"
                                            }
                                          ></span>
                                        </label>
                                      </div>
                                      <div>ON</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                              <input
                                type="submit"
                                id="appupdate"
                                value="Update Setting"
                                onClick={handleUpdate}
                                className="btn btn-primary ml-3 "
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-time"
                    role="tabpanel"
                    aria-labelledby="v-pills-time-tab"
                  >
                    <div className="" id="time_zone_setting">
                      <div
                        className="panel panel-primary mt-3"
                        data-collapsed="0"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Time Zone Setting</label>
                          </div>
                          <hr />
                        </div>
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-md-12 align-center">
                              <div className="row">
                                <div>
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <label className="m-0">
                                      Choose TimeZone to Schedule{" "}
                                    </label>

                                    <select
                                      className="form-control mb-3"
                                      id="time_zone"
                                      name="time_zone"
                                      onChange={handleInput}
                                    >
                                      {time_zone?.map((item) => (
                                        <option
                                          value={item?.time_zone}
                                          onChange={handleInput}
                                        >
                                          {" "}
                                          {item?.time_zone}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="text-end p-3">
                                <input
                                  type="submit"
                                  id="appupdate"
                                  value="Update Setting"
                                  onClick={handleUpdate}
                                  className="btn btn-primary ml-3 "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default StorefrontSettings;
