import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Mastervideolist.css";

function Mastervideolist() {
  const [mastervideolist, setMastervideolist] = useState([]);
  const [videos, setVideos] = useState([]);
  const [livestream, setLiveStream] = useState([]);
  const [audios, setAudios] = useState([]);
  const [episode, setEpisode] = useState([]);
  const [master_count, setMaster_count] = useState([]);
  const [livestream_count, setLivestream_count] = useState([]);
  const [audio_count, setAudios_count] = useState([]);
  const [episode_count, setEpisode_count] = useState([]);
  const [video_count, setVideo_count] = useState([]);

  const [datavideo, setDatavideo] = useState([]);
  const [previousvideo, setPreviousvideo] = useState([]);
  const [nextvideo, setNextvideo] = useState([]);
  const [pagevideo, setPagevideo] = useState(1);
  const [togglevideo, setTogglevideo] = useState(true);
  const [isOffvideo, setIsOffvideo] = useState(false);

  const [datalivestream, setDatalivestream] = useState([]);
  const [previouslivestream, setPreviouslivestream] = useState([]);
  const [nextlivestream, setNextlivestream] = useState([]);
  const [pagelivestream, setPagelivestream] = useState(1);
  const [togglelivestream, setTogglelivestream] = useState(true);
  const [isOfflivestream, setIsOfflivestream] = useState(false);

  const [dataaudio, setDataaudio] = useState([]);
  const [previousaudio, setPreviousaudio] = useState([]);
  const [nextaudio, setNextaudio] = useState([]);
  const [pageaudio, setPageaudio] = useState(1);
  const [toggleaudio, setToggleaudio] = useState(true);
  const [isOffaudio, setIsOffaudio] = useState(false);

  const [dataepisode, setDataepisode] = useState([]);
  const [previousepisode, setPreviousepisode] = useState([]);
  const [nextepisode, setNextepisode] = useState([]);
  const [pageepisode, setPageepisode] = useState(1);
  const [toggleepisode, setToggleepisode] = useState(true);
  const [isOffepisode, setIsOffepisode] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/Master-list", { headers: headers })
      .then((response) => {
        var videos = response?.data?.Videos?.data;
        var LiveStream = response?.data?.LiveStream?.data;
        var audios = response?.data?.audios?.data;
        var Episode = response?.data?.Episode?.data;
        var master_count = response?.data?.master_count;
        var livestream_count = response?.data?.livestream_count;
        var audios_count = response?.data?.audios_count;
        var Episode_count = response?.data?.Episode_count;
        var video_count = response?.data?.video_count;
        // console.log(Episode);
        setVideos(videos);
        setLiveStream(LiveStream);
        setAudios(audios);
        setEpisode(Episode);
        setMaster_count(master_count);
        setLivestream_count(livestream_count);
        setAudios_count(audios_count);
        setEpisode_count(Episode_count);
        setVideo_count(video_count);
      })
      .catch((error) => console.log(error));
  }, []);

  const videosdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete("" + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };
  const livestreamdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete("" + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };
  const audiosdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete("" + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };
  const episodedeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete("" + id, { headers: headers }).then((response) => {
      alert("Item Deleted Successfully !");
      //  navigate('/allvideo')
      window.location.reload();
    });
  };

  function handlePreClickvideo() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagevideo - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.Videos?.data);
        setPreviousvideo(res?.data?.Videos?.data);
        setVideos(res?.data?.Videos?.data);
        setPagevideo(pagevideo - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpagevideo() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagevideo - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.Videos?.data);
        setPreviousvideo(res?.data?.Videos?.data);
        setPagevideo(pagevideo - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickvideo() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagevideo + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res.data.Videos.data);
        setNextvideo(res?.data?.Videos?.data);
        setVideos(res?.data?.Videos?.data);
        setPagevideo(pagevideo + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handlePreClicklivestream() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagelivestream - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.LiveStream?.data);
        setPreviouslivestream(res?.data?.LiveStream?.data);
        setLiveStream(res?.data?.LiveStream?.data);
        setPagelivestream(pagelivestream - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpagelivestream() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagelivestream - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res?.data?.LiveStream?.data);
        setPreviouslivestream(res?.data?.LiveStream?.data);
        setPagelivestream(pagelivestream - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClicklivestream() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pagelivestream + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log("next", res?.data?.LiveStream?.data);
        setNextlivestream(res?.data?.LiveStream?.data);
        setLiveStream(res?.data?.LiveStream?.data);
        setPagelivestream(pagelivestream + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handlePreClickaudio() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageaudio - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.audios.data);
        setPreviousaudio(res?.data?.audios?.data);
        setAudios(res?.data?.audios?.data);
        setPageaudio(pageaudio - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpageaudio() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageaudio - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.Videos.data);
        setPreviousaudio(res?.data?.Videos?.data);
        setPageaudio(pageaudio - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickaudio() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageaudio + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.audios.data);
        setNextaudio(res?.data?.audios?.data);
        setAudios(res?.data?.audios?.data);
        setPageaudio(pageaudio + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handlePreClickepisode() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageepisode - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res?.data?.audios?.data);
        setPreviousepisode(res?.data?.Episode?.data);
        setEpisode(res?.data?.Episode?.data);
        setPageepisode(pageepisode - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpageepisode() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageepisode - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res?.data?.Videos?.data);
        setPreviousepisode(res?.data?.Episode?.data);
        setPageepisode(pageepisode - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickepisode() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Master-list?page=${
          pageepisode + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log("next", res.data.audios.data);
        setNextepisode(res?.data?.Episode?.data);
        setEpisode(res?.data?.Episode?.data);
        setPageepisode(pageepisode + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section Master container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Master Lists</h4>
                      </div>
                    </div>
                    <hr />

                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="iq-card-body">
                          <div className="media align-items-center d-flex justify-content-around">
                            <div className="iq-user-box1">
                              <p className="mb-0 text-white fw-bold">
                                {master_count}
                              </p>
                            </div>
                            <div className="media-body ">
                              <p className="mb-0 font-size-14 line-height">
                                Total Master list Counts
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="iq-card-body">
                          <div className="media align-items-center d-flex justify-content-around">
                            <div className="iq-user-box2">
                              <p className="mb-0 text-white fw-bold">
                                {video_count}
                              </p>
                            </div>
                            <div className="media-body ">
                              <p className="mb-0 font-size-14 line-height">
                                Total Videos
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="iq-card-body">
                          <div className="media align-items-center d-flex justify-content-around">
                            <div className="iq-user-box3">
                              <p className="mb-0 text-white fw-bold">
                                {livestream_count}
                              </p>
                            </div>
                            <div className="media-body ">
                              <p className="mb-0 font-size-14 line-height">
                                Total Live Stream Videos
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="iq-card-body">
                          <div className="media align-items-center d-flex ">
                            <div className="iq-user-box5">
                              <p className="mb-0 text-white fw-bold">
                                {audio_count}
                              </p>
                            </div>
                            <div className="media-body ">
                              <p className="mb-0 font-size-14 line-height">
                                Total Audios
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="iq-card-body">
                          <div className="media align-items-center d-flex justify-content-around">
                            <div className="iq-user-box4">
                              <p className="mb-0 text-white fw-bold">
                                {episode_count}
                              </p>
                            </div>
                            <div className="media-body ">
                              <p className="mb-0 font-size-14 line-height">
                                Total Episodes
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="iq-card-body table-responsive iq-card">
                    <div className="table-view">
                      <ul
                        className="nav nav-pills justify-content-around"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Videos
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Live Stream Videos
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            Audios
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-episode-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-episode"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            Episode
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <table
                            className="table table-striped table-bordered table movie_table text-center"
                            id="master_list"
                          >
                            <thead>
                              <tr className="r1">
                                <th>Title</th>
                                <th>Rating</th>
                                <th>Uploaded by</th>
                                <th>Video Type</th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {videos?.map((item, key) => (
                                <tr className=" text-center ">
                                  <td>
                                    {" "}
                                    <div className="row text-center align-items-end">
                                      <div className="col-lg-2">
                                        <img
                                          src={item?.Thumbnail}
                                          width="50"
                                          height="50"
                                        />{" "}
                                      </div>
                                      <div className="col-lg-10">
                                        {item?.title}
                                      </div>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <span className="ms-2">{item?.rating}</span>{" "}
                                  </td>
                                  <td>{item?.uploaded_by}</td>
                                  <td> {item?.type}</td>
                                  {/* <td > {item.status}</td> */}
                                  {/* <td className='text-center masterstatus'>
                                                {(item.status == 0 || item.status == null) ? <td className="bg-warning video_active">Draft</td> : (item.status == 1 && item.status == 1 && item.status == 1) ? <td className="bg-success video_active">Approved</td> : <td className="bg-warning video_active">Draft</td>}
                                             </td> */}
                                  <td className="text-center masterstatus">
                                    {item?.status == 0 ||
                                    item?.status == null ? (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    ) : item?.status == 1 &&
                                      item?.status == 1 &&
                                      item?.status == 1 ? (
                                      <span className="bg-success video_active">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    )}
                                  </td>

                                  <td> {item?.access}</td>
                                  <td>
                                    {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                    <Link
                                      to={"/admin/editvideo/" + item?.id}
                                      className="edit ms-2"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-2">
                                      <span
                                        onClick={() =>
                                          videosdeleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClickvideo}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{pagevideo}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClickvideo}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <table
                            className="table table-striped table-bordered table movie_table text-center"
                            id="master_list"
                          >
                            <thead>
                              <tr className="r1">
                                <th>Title</th>
                                <th>Rating</th>
                                <th>Uploaded by</th>
                                <th>Video Type</th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {livestream?.map((item, key) => (
                                <tr className=" text-center ">
                                  <td>
                                    {" "}
                                    <div className="row text-center align-items-end">
                                      <div className="col-lg-2">
                                        <img
                                          src={item?.Thumbnail}
                                          width="50"
                                          height="50"
                                        />{" "}
                                      </div>
                                      <div className="col-lg-10">
                                        {item?.title}
                                      </div>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <span className="ms-2">{item?.rating}</span>{" "}
                                  </td>
                                  <td>{item?.uploaded_by}</td>
                                  <td> {item?.type}</td>
                                  {/* <td > {item.status}</td> */}
                                  {/* <td className='text-center masterstatus'>
                                                {(item.status == 0 || item.status == null) ? <td className="bg-warning video_active">Draft</td> : (item.status == 1 && item.status == 1 && item.status == 1) ? <td className="bg-success video_active">Approved</td> : <td className="bg-warning video_active">Draft</td>}
                                             </td> */}
                                  <td className="text-center masterstatus">
                                    {item?.status == 0 ||
                                    item?.status == null ? (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    ) : item?.status == 1 &&
                                      item?.status == 1 &&
                                      item?.status == 1 ? (
                                      <span className="bg-success video_active">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    )}
                                  </td>

                                  <td> {item?.access}</td>
                                  <td>
                                    {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                    <Link
                                      to={
                                        "/admin/edit/add-livestream/" + item?.id
                                      }
                                      className="edit ms-2"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-2">
                                      <span
                                        onClick={() =>
                                          livestreamdeleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClicklivestream}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{pagelivestream}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClicklivestream}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-contact"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <table
                            className="table table-striped table-bordered table movie_table text-center"
                            id="master_list"
                          >
                            <thead>
                              <tr className="r1">
                                <th>Title</th>
                                <th>Rating</th>
                                <th>Uploaded by</th>
                                <th>Video Type</th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {audios?.map((item, key) => (
                                <tr className=" text-center ">
                                  <td>
                                    {" "}
                                    <div className="row text-center align-items-end">
                                      <div className="col-lg-2">
                                        <img
                                          src={item?.Thumbnail}
                                          width="50"
                                          height="50"
                                        />{" "}
                                      </div>
                                      <div className="col-lg-10">
                                        {item?.title}
                                      </div>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <span className="ms-2">{item?.rating}</span>{" "}
                                  </td>
                                  <td>{item?.uploaded_by}</td>
                                  <td> {item?.type}</td>
                                  {/* <td > {item.status}</td> */}
                                  {/* <td className='text-center masterstatus'>
                                                {(item.status == 0 || item.status == null) ? <td className="bg-warning video_active">Draft</td> : (item.status == 1 && item.status == 1 && item.status == 1) ? <td className="bg-success video_active">Approved</td> : <td className="bg-warning video_active">Draft</td>}
                                             </td> */}
                                  <td className="text-center masterstatus">
                                    {item?.status == 0 ||
                                    item?.status == null ? (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    ) : item?.status == 1 &&
                                      item?.status == 1 &&
                                      item?.status == 1 ? (
                                      <span className="bg-success video_active">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    )}
                                  </td>

                                  <td> {item.access}</td>
                                  <td>
                                    {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                    <Link
                                      to={"/admin/edit-audio/" + item?.id}
                                      className="edit ms-2"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-2">
                                      <span
                                        onClick={() =>
                                          audiosdeleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClickaudio}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{pageaudio}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClickaudio}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-episode"
                          role="tabpanel"
                          aria-labelledby="pills-episode-tab"
                        >
                          <table
                            className="table table-striped table-bordered table movie_table text-center"
                            id="master_list"
                          >
                            <thead>
                              <tr className="r1">
                                <th>Title</th>
                                <th>Rating</th>
                                <th>Uploaded by</th>
                                <th>Video Type</th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {episode?.map((item, key) => (
                                <tr className=" text-center ">
                                  <td>
                                    {" "}
                                    <div className="row text-center align-items-end">
                                      <div className="col-lg-2">
                                        <img
                                          src={item?.Thumbnail}
                                          width="50"
                                          height="50"
                                        />{" "}
                                      </div>
                                      <div className="col-lg-10">
                                        {item?.title}
                                      </div>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <span className="ms-2">{item?.rating}</span>{" "}
                                  </td>
                                  <td>{item?.uploaded_by}</td>
                                  <td> {item?.type}</td>
                                  {/* <td > {item.status}</td> */}
                                  {/* <td className='text-center masterstatus'>
                                                {(item.status == 0 || item.status == null) ? <td className="bg-warning video_active">Draft</td> : (item.status == 1 && item.status == 1 && item.status == 1) ? <td className="bg-success video_active">Approved</td> : <td className="bg-warning video_active">Draft</td>}
                                             </td> */}
                                  <td className="text-center masterstatus">
                                    {item?.status == 0 ||
                                    item?.status == null ? (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    ) : item?.status == 1 &&
                                      item?.status == 1 &&
                                      item?.status == 1 ? (
                                      <span className="bg-success video_active">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="bg-warning video_active">
                                        Draft
                                      </span>
                                    )}
                                  </td>

                                  <td> {item?.access}</td>
                                  <td>
                                    {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                    <Link
                                      to={
                                        "/admin/edit-episode/manage/" + item?.id
                                      }
                                      className="edit ms-2"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-2">
                                      <span
                                        onClick={() =>
                                          episodedeleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClickepisode}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{pageepisode}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClickepisode}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Mastervideolist;
