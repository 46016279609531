import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ChannelPackage() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h4>
                          <i className="entypo-video"></i> Channel Package{" "}
                        </h4>{" "}
                      </div>

                      <div className="col-md-6" align="right">
                        <Link
                          to="/admin/add-channel-package-index"
                          className="btn btn-primary mb-3"
                        >
                          <i className="fa fa-plus-circle"></i> Add New
                        </Link>
                      </div>
                    </div>

                    <div className="gallery-env mt-2">
                      <table className="data-tables table livestream_table iq-card text-center p-0">
                        <thead>
                          <tr className="r1">
                            <th> S.No </th>
                            <th>PACKAGE NAME </th>
                            <th>PACKAGE PRICE </th>
                            <th>PACKAGE PLAN ID </th>
                            <th> PLAN INTERVAL </th>
                            <th>STATUS </th>
                            <th>ACTION</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td> </td>
                            <td></td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td></td>

                            <td className=" align-items-center list-inline">
                              <Link
                                to="{{ route('channel_package_edit', $Channel_lists->id) }}"
                                className="iq-bg-success ml-2 mr-2"
                              >
                                <img className="ply " src="" />
                              </Link>
                              <Link
                                to="{{ route('channel_package_delete', $Channel_lists->id) }}"
                                onclick="return confirm('Are you sure?')"
                                className="iq-bg-danger"
                              >
                                <img className="ply" src="" />
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChannelPackage;
