import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SignupMenu.css";

function SignupMenu() {
  // Get Method to get store data
  // const [Menu, setMenus] = useState([]);

  // const [editUser, setEditUser] = useState({ username: '', id: '', })

  // // console.log(Menu)

  // const access_token = localStorage.getItem("access_token");

  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + access_token,
  // };

  // useEffect(async () => {
  //   await axios
  //     .get("http://api.flicknexs.com/admin/signup_menu", { headers: headers })
  //     .then((response) => {
  //       var result = response.data.signup_menu;
  //       setMenus(result);
  //       setEditUser(result);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  // // Post data to store action
  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [avatar, setAvatar] = useState("");
  // const [password, setPassword] = useState("");
  // const [password_confirm, setPasswordConfirm] = useState("");

  // const handleInput = (e) => {
  //   if (e.target.checked === true) {
  //     setUsername({ ...username, [e.target.name]: 1 });
  //   } else {
  //     setUsername({ ...username, [e.target.name]: 0 });
  //   }
  // };

  // async function signup_menu() {
  //   console.log(username, email, mobile, avatar, password, password_confirm);

  //   const formData = new FormData();

  //   formData.append("username", username);
  //   formData.append("email", email);
  //   formData.append("mobile", mobile);
  //   formData.append("avatar", avatar);
  //   formData.append("password", password);
  //   formData.append("password_confirm", password_confirm);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //   };

  //   await axios
  //     .post("https://api.flicknexs.com/admin/signup_menu_store", formData, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       var result = response.data;
  //       console.log("result", result);
  //     });
  // }

  const [editUser, setEditUser] = useState({
    username: "",
    id: "",
    user_id: "",
    email: "",
    mobile: "",
    avatar: "",
    password: "",
    password_confirm: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/signup_menu`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.signup_menu;

      setEditUser(res);
      // console.log(res)

      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      user_id: editUser.user_id,
      id: editUser.id,
      username: editUser.username,
      email: editUser.email,
      mobile: editUser.mobile,
      avatar: editUser.avatar,
      password: editUser.password,
      password_confirm: editUser.password_confirm,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/signup_menu_store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div className="iq-card ">
            <h4>Signup Menu</h4>
            <hr />

            <div className="col-lg-12">
              <div className="card text-center">
                <div className=""></div>
                <table>
                  <thead>
                    <tr className="r1">
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {/* { Menu.map((item) =>  { console.log(item.username); }  ) } */}

                  <tbody>
                    <td> 1 </td>
                    <td> Profile Name </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="username"
                            className="username"
                            id="username"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}

                        <label className="switch">
                          <input
                            name="username"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.username === 1 ? true : false
                            }
                            checked={editUser?.username === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="username"
                            onChange={handleInput}
                            value={editUser?.username === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                  <tbody>
                    <td> 2 </td>
                    <td> Profile Email </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="email"
                            className="email"
                            id="email"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}
                        <label className="switch">
                          <input
                            name="email"
                            onChange={handleInput}
                            defaultChecked={editUser?.email === 1 ? true : false}
                            checked={editUser?.email === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="email"
                            onChange={handleInput}
                            value={editUser?.email === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                  <tbody>
                    <td> 3 </td>
                    <td> Profile Mobile Number </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="mobile"
                            className="mobile"
                            id="mobile"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}

                        <label className="switch">
                          <input
                            name="mobile"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.mobile === 1 ? true : false
                            }
                            checked={editUser?.mobile === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="mobile"
                            onChange={handleInput}
                            value={editUser?.mobile === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                  <tbody>
                    <td> 4 </td>
                    <td> Profile Image </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="avatar"
                            className="avatar"
                            id="avatar"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}

                        <label className="switch">
                          <input
                            name="avatar"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.avatar === 1 ? true : false
                            }
                            checked={editUser?.avatar === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="avatar"
                            onChange={handleInput}
                            value={editUser?.avatar === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                  <tbody>
                    <td> 5 </td>
                    <td> Profile Password </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="password"
                            className="password"
                            id="password"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}

                        <label className="switch">
                          <input
                            name="password"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.password === 1 ? true : false
                            }
                            checked={editUser?.password === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="password"
                            onChange={handleInput}
                            value={editUser?.password === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                  <tbody>
                    <td> 6 </td>
                    <td> Profile Password Confirm </td>
                    <td>
                      <div className="mt-1">
                        {/* <label className="switch">
                          <input
                            name="password_confirm"
                            className="password_confirm"
                            id="password_confirm"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label> */}

                        <label className="switch">
                          <input
                            name="password_confirm"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.password_confirm === 1 ? true : false
                            }
                            checked={
                              editUser?.password_confirm === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="password_confirm"
                            onChange={handleInput}
                            value={editUser?.password_confirm === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button onClick={handleUpdate} className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SignupMenu;
