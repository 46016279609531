import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

function Edit_album() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [audioalbums, setAudioalbums] = useState([]);
  const [editUser, setEditUser] = useState({
    albumname: "",
    parent_id: "",
    slug: "",
    albums: "",
    uploaded_by: "admin",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  const [album, setAlbum] = useState("");
  const [imageurl, setImageurl] = useState([]);

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audio_album[0];

      setEditUser(res);

      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.audio_albums;
        setAudioalbums(result);
        // console.log(result);
        // console.log(response);
        // console.log(response);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      uploaded_by: editUser.uploaded_by,
      albumname: editUser.albumname,
      parent_id: editUser.parent_id,
      albums: editUser.albums,
      slug: editUser.slug,
      Channel_Partner_id: Channel_Partner_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/update/` +
        id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");

      var album_id = resjson.AudioAlbum_id;

      // console.log(album_id);

      console.log("data", album);
      const formDatas = new FormData();
      formDatas.append("album", album);
      formDatas.append("audio_album_id", album_id);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Edit Album</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="albumname"
                      name="albumname"
                      value={editUser?.albumname}
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group add-profile-pic mt-2">
                    <label>Cover Image:</label>
                    <div>
                      <img
                        src={editUser.image_url}
                        alt="image"
                        width={200}
                        height={200}
                      />
                    </div>
                    <input
                      id="f02"
                      type="file"
                      name="album"
                      className="form-control"
                      onChange={(e) => setAlbum(e.target.files[0])}
                      placeholder="Add profile picture"
                    />
                    <p className="">
                      Must be JPEG, PNG, or GIF and cannot exceed 10MB.
                    </p>
                  </div>
                  <div className="form-group">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      placeholder="Enter Slug"
                      value={editUser?.slug}
                      onChange={handleInput}
                    />
                    <input
                      type="text"
                      hidden
                      id="uploaded_by"
                      name="uploaded_by"
                      className="form-control"
                      value={editUser?.uploaded_by}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      onChange={handleInput}
                      value={editUser?.parent_id}
                      name="parent_id"
                      id="parent_id"
                    >
                      {audioalbums?.map((item) => (
                        <option value={item?.parent_id}>
                          {item?.albumname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id=""
                      onClick={handleUpdate}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Edit_album;
