import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

function Addnewseries() {
  const [genre_id, setGenre_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setUrl_type] = useState("");
  const [access, setAccess] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [featured, setFeatured] = useState("");
  const [duration, setDuration] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [language, setLanguage] = useState([]);
  const [year, setYear] = useState("");
  const [trailer, setTrailer] = useState("");
  const [banner, setBanner] = useState("");
  const [deleted_at, setDeleted_at] = useState("");
  const [search_tag, setSearch_tag] = useState("");
  const [series_trailer, setSeries_trailer] = useState("");
  const [season_trailer, setSeason_trailer] = useState("");
  const [status, setStatus] = useState("");
  const [season, setSeason] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [url, setUrl] = useState("");
  const [Thumbnail, setThumbnail] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");
  const [series_id, setSeries_id] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const [section, setSection] = useState();

  const config = {
    placeholder: "Start typings...",
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function series() {
    console.log("append data", title);

    const formData = new FormData();

    formData.append("title", title);
    formData.append("genre_id", genre_id);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", active);
    formData.append("ppv_status", ppv_status);
    formData.append("status", status);
    formData.append("featured", featured);
    formData.append("duration", duration);
    formData.append("views", views);
    formData.append("rating", rating);
    // formData.append('image', image);
    // formData.append('player_image', player_image);
    // formData.append('tv_image', tv_image);
    formData.append("embed_code", embed_code);
    formData.append("mp4_url", mp4_url);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    formData.append("language", language);
    formData.append("year", year);
    formData.append("trailer", trailer);
    formData.append("banner", banner);
    formData.append("search_tag", search_tag);
    formData.append("series_trailer", series_trailer);
    formData.append("season_trailer", season_trailer);
    formData.append("uploaded_by", uploaded_by);
    formData.append("url", url);
    formData.append("deleted_at", deleted_at);
    formData.append("Thumbnail", Thumbnail);
    formData.append("Player_thumbnail", Player_thumbnail);
    formData.append("TV_Thumbnail", TV_Thumbnail);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/series/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        console.log("api", response);
        if (response.data.status === true) {
          var result = response.data;
          console.log("result", result);
          // navigate('/admin/series-list')
          alert("Added successfully  !");

          var series_id = response.data.Series_id;
          var resssss = response.data;

          setSeries_id(series_id);
          // console.log('resssss', resssss);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          formDatas.append("series_id", series_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  // const [access, access] = useState('');

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    // {e => setActive(e.target.value)}
    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    // setActive({ ...active, [e.target.name]: e.target.value });
    setSeason_trailer({ ...season_trailer, [e.target.name]: e.target.value });
    setSeries_trailer({ ...series_trailer, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setTrailer({ ...trailer, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 1 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setTrailer({ ...trailer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 0 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setTrailer({ ...trailer, [e.target.name]: 0 });
    }
  };

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          console.log(data.user_access, "user");
          const userAcces = data?.user_access.map((userAcc) => ({
            value: userAcc?.role,
            label: userAcc?.name,
          }));

          const ppv_price = data?.InappPurchase.map((userAcc) => ({
            value: userAcc?.id,
            label: userAcc?.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();

    const getcategoryname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_category)
        var result = response?.data?.series_categories;
        setOrder(result);
        // console.log('result', result)
        // console.log('result', result);
        for (let i = 0; i < result?.length; i++) {
          getcategoryname?.push(result[i]?.name);
        }
        setCategory(getcategoryname);
      })
      .catch((error) => console.log(error));

    const getartistname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response?.data?.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result?.length; i++) {
          getartistname?.push(result[i]?.artist_name);
        }
        setArtists(getartistname);
      })
      .catch((error) => console.log(error));

    const getlanguagename = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getlanguagename?.push(result[i]?.name);
        }
        setLanguage(getlanguagename);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.InappPurchase;
        setPublish(result);
        // console.log('result', result)
        // console.log('Publish_type', result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i> Add New Series
              </h4>
              <hr />
              <div className="p-3">
                <div className="row mt-1">
                  <div className="col-sm-6 mb-3">
                    <label className="m-0">Title</label>
                    <p className="p1">
                      Add the series title in the textbox below.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Series Title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  {/* <div className="col-sm-6">
										<label className="m-0">Created Date</label>
										<div className="panel-body" >
											<p className="p1">Select Date/Time Below</p>
											<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
										</div>
									</div> */}

                  <div className="col-sm-6" data-collapsed="0">
                    <label className="m-0">Slug</label>
                    <div className="panel-body">
                      <p className="p1">Add a URL Slug</p>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        id="slug"
                        placeholder="Series Slug"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the series image ( 9:16 Ratio or 1080X1920px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control image series_image"
                          name="image"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Player Image </label>
                        </div>
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the Player image ( 16:9 Ratio or 1280X720px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control"
                          name="player_image"
                          id="player_image"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series TV Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the TV series image ( 16:9 Ratio or 1920 X 1080
                          px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control image"
                          name="tv_image"
                          id="tv_image"
                          onChange={(e) => setTv_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-primary mt-3" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">
                            Series Details, Links, and Info
                          </label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <textarea
                          className="form-control"
                          name="details"
                          id="summary-ckeditor"
                          onChange={(e) => setDetails(e.target.value)}
                        ></textarea>

                        <JoditEditor
                          // ref={editor}
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Short Description</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Add a short description of the series below:
                        </p>
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Cast and Crew</label>{" "}
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add artists for the series below:
                            </p>
                            {/* <select className="form-control js-example-basic-multiple" name="artists[]" multiple="multiple">

														<option></option>

														<option ></option>

													</select> */}

                            <Multiselect
                              isObject={false}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={artist}
                              onChange={(e) => setArtists(e.target.value)}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Category</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Category Below:
                            </p>
                            {/* <select name="genre_id[]" id="genre_id" className="js-example-basic-multiple" multiple="multiple">

														<option value="{{ $category->id }}" selected="true"></option>

														<option value="{{ $category->id }}"></option>

													</select> */}

                            <Multiselect
                              isObject={false}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={category}
                              onChange={(e) => setCategory(e.target.value)}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Ratings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body p-0 ">
                            <p className="p1">IMDb Ratings 10 out of 10</p>
                            <select
                              className="form-control"
                              name="rating"
                              id="rating"
                              onChange={(e) => setRating(e.target.value)}
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Language</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Language Below:
                            </p>
                            {/* <select className="form-control js-example-basic-multiple" id="language" name="language[]" multiple="multiple" >

														<option value="" selected="true"></option>

														<option value="" ></option>

													</select> */}

                            <Multiselect
                              isObject={false}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={language}
                              onChange={(e) => setLanguage(e.target.value)}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Year</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body  p-0">
                            <p className="p1">Series Created Year</p>
                            <input
                              className="form-control"
                              name="year"
                              id="year"
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title font-weight-bold">
                            <label className="m-0">Search Tags</label>
                          </div>

                          <div className="">
                            <input
                              className="form-control "
                              type="search"
                              placeholder="search_tags"
                              aria-label="Search"
                              onChange={(e) => setSearch_tag(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* <div className="panel-body  p-0" >
												<p className="p1">Add series tags below:</p>
												<input type="text" className="form-control" id="tag-input1" name="search_tag" />
											</div> */}
                      </div>
                    </div>

                    <div className="row text-start mt-4">
                      <div className="col-sm-6">
                        <label className="m-0">Choose Ads Position</label>

                        <div className="">
                          {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                          <Select
                            options={adsPositions}
                            onChange={(e) => handleAdsPositionChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label className="m-0">Choose Advertisement</label>

                        <div className="">
                          <Select
                            options={secondSelectOptions}
                            onChange={(e) => setSecondSelectOptions(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              {" "}
                              <label className="m-0">Duration</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Enter the duration in the (HH: MM : SS) format{" "}
                            </p>
                            <input
                              className="form-control"
                              name="duration"
                              id="duration"
                              onChange={(e) => setDuration(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label className="m-0">User Access</label>
                        <p className="p1">
                          Who Is Allowed To View This Live Stream ?
                        </p>
                        <Select
                          options={userAccesses}
                          onChange={(e) => handleAccessChange(e)}
                          className="abcd"
                        />
                      </div>

                      {userAccessesValue?.value === "ppv" ? (
                        <>
                          <div class="row mt-3 text-start ">
                            <div class="col-sm-6">
                              <label class="m-0">PPV Price</label>
                              <div className="col-lg-12 row">
                                <div className="col-6 col-lg-6">
                                  <p class="p1">
                                    Apply PPV Price from Global Settings?{" "}
                                  </p>
                                </div>
                                <div className="col-6 col-lg-6">
                                  <label className="switch">
                                    <input
                                      name="ppv_status"
                                      defaultChecked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="ppv_status"
                                      checked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              {/* <p class="p1">
                                Apply PPV Price from Global Settings?
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    defaultChecked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                  ></span>
                                </label>
                                <input
                                  type="checkbox"
                                  name="ppv_gobal_price"
                                  id="ppv_gobal_price"
                                  checked={checkboxChecked}
                                  onChange={handleCheckboxChange}
                                />
                              </p> */}

                              <div class="panel-body">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="PPV Price"
                                  name="ppv_price"
                                  id="price"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div class="col-sm-6">
                              <label class="m-0"> IOS PPV Price</label>
                              <p class="p1">
                                Apply IOS PPV Price from Global Settings?
                              </p>
                              <div class="panel-body ppv_price_ios">
                                <Select
                                  name="ios_ppv_price"
                                  className="form-control"
                                  options={ios_ppv_price}
                                  onChange={(e) =>
                                    setIos_ppv_price(e.target.value)
                                  }
                                />

                                {/* <select
                                    name="ios_ppv_price"
                                    class="form-control"
                                    id="ios_ppv_price"
                                  >
                                    <option value="">
                                      Select IOS PPV Price:{" "}
                                    </option>
                                    <option value="ppv2"> 349</option>
                                    <option value="multiverse"> 179</option>
                                  </select> */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="col-sm-6 mt-4">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <label className="m-0 p2">
                            Apply Global PPV Price:
                          </label>
                          {/* <label className="switch" >
														<input name="ppv_status" onChange={handleInput} value={(ppv_status.ppv_status === 0 || ppv_status.ppv_status === 0) ? '0' : (ppv_status.ppv_status === 0) ? '0' : '1'} type="checkbox" />
														<span className="slider round" name="ppv_status" onChange={handleInput} value={(ppv_status.ppv_status === 0 || ppv_status.ppv_status === 0) ? '0' : (ppv_status.ppv_status === 0) ? '0' : '1'} ></span>
													  </label> */}

                          <label className="switch">
                            <input
                              name="ppv_status"
                              onChange={handleInput}
                              defaultChecked={
                                ppv_status?.ppv_status === 1 ? true : false
                              }
                              checked={
                                ppv_status?.ppv_status === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ppv_status"
                              onChange={handleInput}
                              value={ppv_status?.ppv_status === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 mt-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Status Settings</label>
                            </div>
                          </div>
                          <div className="panel-body mt-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Is this series Featured:
                              </label>

                              <label className="switch">
                                <input
                                  name="featured"
                                  onChange={handleInput}
                                  defaultChecked={
                                    featured?.featured === 1 ? true : false
                                  }
                                  checked={
                                    featured?.featured === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="featured"
                                  onChange={handleInput}
                                  value={featured?.featured === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              {/* <label className="switch" >
															<input name="featured" onChange={handleInput} value={(featured.featured === 0 || featured.featured === 0) ? '0' : (featured.featured === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="status" onChange={handleInput} value={(featured.featured === 0 || featured.featured === 0) ? '0' : (featured.featured === 0) ? '0' : '1'}  ></span>
														</label> */}
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="banner" name="banner">
                                Banner :
                              </label>

                              <label className="switch">
                                <input
                                  name="banner"
                                  onChange={handleInput}
                                  defaultChecked={
                                    banner?.banner === 1 ? true : false
                                  }
                                  checked={banner?.banner === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                  onChange={handleInput}
                                  value={banner?.banner === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              {/* <label className="switch" >
															<input name="banner" onChange={handleInput} value={(banner.banner === 0 || banner.banner === 0) ? '0' : (banner.banner === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="banner" onChange={handleInput} value={(banner.banner === 0 || banner.banner === 0) ? '0' : (banner.banner === 0) ? '0' : '1'} ></span>
														</label> */}
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="active">
                                Is this series Active:
                              </label>

                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    active?.active === 1 ? true : false
                                  }
                                  checked={active?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={active?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                              {/* <label className="switch" >
															<input name="active" onChange={handleInput} value={(active.active === 0 || active.active === 0) ? '0' : (active.active === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="active"  onChange={handleInput}  value={(active.active === 0 || active.active === 0) ? '0' : (active.active === 0) ? '0' : '1'} ></span>
														</label> */}
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-baseline">
														<label className="p2" for="featured" >Enable this series as Slider:</label>
														<label className="switch" >
															<input name="featured" onChange={handleInput} value={(ppv_status.ppv_status === 0 || ppv_status.ppv_status === 0) ? '0' : (ppv_status.ppv_status === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="featured" onChange={handleInput} value={(ppv_status.ppv_status === 0 || ppv_status.ppv_status === 0) ? '0' : (ppv_status.ppv_status === 0) ? '0' : '1'} ></span>
														</label>
													</div> */}
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="trailer">
                                  Season Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    trailer?.trailer === 1 ? true : false
                                  }
                                  checked={trailer?.trailer === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="trailer"
                                  onChange={handleInput}
                                  value={trailer?.trailer === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              {/* <label className="switch" >
															<input name="trailer" onChange={handleInput} value={(trailer.trailer === 0 || trailer.trailer === 0) ? '0' : (trailer.trailer === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="trailer" onChange={handleInput} value={(trailer.trailer === 0 || trailer.trailer === 0) ? '0' : (trailer.trailer === 0) ? '0' : '1'} ></span>
														</label> */}
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="active">
                                  Series Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="series_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    series_trailer?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    series_trailer?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="series_trailer"
                                  onChange={handleInput}
                                  value={
                                    series_trailer?.series_trailer === 1
                                      ? "1"
                                      : "0"
                                  }
                                ></span>
                              </label>

                              {/* <label className="switch" >
															<input name="series_trailer" onChange={handleInput} value={(series_trailer.series_trailer === 0 || series_trailer.series_trailer === 0) ? '0' : (series_trailer.series_trailer === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="series_trailer" onChange={handleInput} value={(series_trailer.series_trailer === 0 || series_trailer.series_trailer === 0) ? '0' : (series_trailer.series_trailer === 0) ? '0' : '1'} ></span>
														</label> */}
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div className="">
                                <label className="p2" for="active">
                                  Season 1 :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="season_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    season_trailer?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    season_trailer?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="season_trailer"
                                  onChange={handleInput}
                                  value={
                                    season_trailer?.season_trailer === 1
                                      ? "1"
                                      : "0"
                                  }
                                ></span>
                              </label>
                              {/* <label className="switch" >
															<input name="season_trailer"onChange={handleInput} value={(season_trailer.season_trailer === 0 || season_trailer.season_trailer === 0) ? '0' : (season_trailer.season_trailer === 0) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="season_trailer" onChange={handleInput} value={(season_trailer.season_trailer === 0 || season_trailer.season_trailer === 0) ? '0' : (season_trailer.season_trailer === 0) ? '0' : '1'} ></span>
														</label> */}
                            </div>

                            <div className="d-flex justify-content-between align-items-baseline mr-2 views">
                              <div className="">
                                <label className="p2" for="active">
                                  Views :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    views?.views === 1 ? true : false
                                  }
                                  checked={views?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  onChange={handleInput}
                                  value={views?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              {/* <label className="switch" >
																<input name="views" onChange={handleInput} value={(views.views === 0 || views.views === 0) ? '0' : (views.views === 0) ? '0' : '1'} type="checkbox" />
																<span className="slider round" name="views" onChange={handleInput} value={(views.views === 0 || views.views === 0) ? '0' : (views.views === 0) ? '0' : '1'} ></span>
															</label> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row  mt-3 p-3">
										<div className="col-sm-3 p-0">

											<div className="panel-body">
												<div className="d-flex justify-content-between align-items-baseline">
													<label className="m-0 p2">Apply Global PPV Price:</label>

													<input type="checkbox" name="ppv_status" value="1" id="ppv_status" />
													<div className="global_ppv_status ml-2">
														<input type="checkbox" name="ppv_status" value="1" id="ppv_status" onChange={e => setPpv_status(e.target.value)} />
													</div>

												</div>
											</div>
										</div>
									</div> */}
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        onClick={series}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Addnewseries;
