import "./Sidebar.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../components/Images/flicklogo.png";
import add_list_01 from "../../components/Images/sidebar_icon/add_list_01.svg";
import master_list_01 from "../../components/Images/sidebar_icon/master_list_01.svg";
import Ads_Categories_01 from "../../components/Images/sidebar_icon/Ads_Categories_01.svg";
import Ads_Compaigns_01 from "../../components/Images/sidebar_icon/Ads_Compaigns_01.svg";
import Ads_Plans_01 from "../../components/Images/sidebar_icon/Ads_Plans_01.svg";
import ads_revenue_01 from "../../components/Images/sidebar_icon/ads_revenue_01.svg";
import ads_time_slot_01 from "../../components/Images/sidebar_icon/ads_time_slot_01.svg";
import analytics_01 from "../../components/Images/sidebar_icon/analytics_01.svg";
import Artist_Management_01 from "../../components/Images/sidebar_icon/Artist_Management_01.svg";
import Audio_Management_01 from "../../components/Images/sidebar_icon/Audio_Management_01.svg";
import Cache_Management_01 from "../../components/Images/sidebar_icon/Cache_Management_01.svg";
import Calender_Events_01 from "../../components/Images/sidebar_icon/Calender_Events_01.svg";
import channel_partner_01 from "../../components/Images/sidebar_icon/channel_partner_01.svg";
import Contact_Request_01 from "../../components/Images/sidebar_icon/Contact_Request_01.svg";
import content_partner_01 from "../../components/Images/sidebar_icon/content_partner_01.svg";
import Dashboard_01 from "../../components/Images/sidebar_icon/Dashboard_01.svg";
import debug_01 from "../../components/Images/sidebar_icon/debug_01.svg";
import File_Manager_01 from "../../components/Images/sidebar_icon/File_Manager_01.svg";
import Language_01 from "../../components/Images/sidebar_icon/Language_01.svg";
import library_01 from "../../components/Images/sidebar_icon/library_01.svg";
import Manage_Advertiser_01 from "../../components/Images/sidebar_icon/Manage_Advertiser_01.svg";
import Manage_Blocked_Country_01 from "../../components/Images/sidebar_icon/Manage_Blocked_Country_01.svg";
import Manage_Geo_Fencing_01 from "../../components/Images/sidebar_icon/Manage_Geo_Fencing_01.svg";
import Manage_LiveStream_01 from "../../components/Images/sidebar_icon/Manage_LiveStream_01.svg";
import Menu_01 from "../../components/Images/sidebar_icon/Menu_01.svg";
import footer_menu_01 from "../../components/Images/sidebar_icon/footer_menu_01.svg";
import mobile_footer_menu_01 from "../../components/Images/sidebar_icon/mobile_footer_menu_01.svg";
import Pages_01 from "../../components/Images/sidebar_icon/Pages_01.svg";
import Payment_Management_01 from "../../components/Images/sidebar_icon/Payment_Management_01.svg";
import plans_01 from "../../components/Images/sidebar_icon/plans_01.svg";
import Player_ui_01 from "../../components/Images/sidebar_icon/Player_ui_01.svg";
import See_Log_Activity_01 from "../../components/Images/sidebar_icon/See_Log_Activity_01.svg";
import Series_Episode_01 from "../../components/Images/sidebar_icon/Series_Episode_01.svg";
import Settings_01 from "../../components/Images/sidebar_icon/Settings_01.svg";
import Signup_Menu_01 from "../../components/Images/sidebar_icon/Signup_Menu_01.svg";
import slider_01 from "../../components/Images/sidebar_icon/slider_01.svg";
import user_01 from "../../components/Images/sidebar_icon/user_01.svg";
import Video_Management_01 from "../../components/Images/sidebar_icon/Video_Management_01.svg";
import contact_request from "../../components/Images/sidebar_icon/contact_request.svg";
import Seeding_01 from "../../components/Images/sidebar_icon/seeding_01.svg";

function Sidebar() {
  return (
    <section className="Sidebar">
      <div id="sidebar" className="sidebar">
        <Link className="" to="/channel/home">
          <img src={logo} alt="flogo" className="w-100 py-2" />
          {/* <div className="logo">
              {isLoading ? (
                <p>Loading...</p>
              ) : data1 ? (
                <div>
                  <p>
                    <img src={data1} className="w-100" />
                  </p>
                </div>
              ) : data2 ? (
                <p>
                  <img src={data2} className="w-100" />
                </p>
              ) : data3 ? (
                <p>
                  <img src={data3} className="w-100" />
                </p>
              ) : (
                <p></p>
              )}
            </div> */}
        </Link>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link " to="/channel/home">
            <img src={Dashboard_01} alt="flogo" className="" />
              <span className="ms-2">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Video-Management"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={Video_Management_01} alt="flogo" className="" />
              <span className="ms-2">Video Management</span>
            </Link>
            <ul
              id="Video-Management"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/allvideos" className="nav-lists">
                  <i className="bi bi-circle"></i>
                  <span>All Video</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/add-new-videos" className="nav-lists">
                  <i className="bi bi-circle"></i>
                  <span>Add New Video</span>
                </Link>
              </li>

              <li>
                <Link to="/channel/video-categories">
                  <i className="bi bi-circle"></i>
                  <span>Manage Video Categories</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Series-Episode"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={Series_Episode_01} alt="flogo" className="" />
              <span className="ms-2">Series & Episode</span>
            </Link>
            <ul
              id="Series-Episode"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/series-lists">
                  <i className="bi bi-circle"></i>
                  <span>Series List</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/add-new-series">
                  <i className="bi bi-circle"></i>
                  <span>Add Series List</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Audio-Management"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={Audio_Management_01} alt="flogo" className="" />
              <span className="ms-2">Audio Management</span>
            </Link>
            <ul
              id="Audio-Management"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/audio-lists">
                  <i className="bi bi-circle"></i>
                  <span>Audio List</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/add-new-audio">
                  <i className="bi bi-circle"></i>
                  <span>Add New Audio</span>
                </Link>
              </li>

              <li>
                <Link to="/channel/manage-audio-category">
                  <i className="bi bi-circle"></i>
                  <span>Manage Audio Categories</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/manage-albums">
                  <i className="bi bi-circle"></i>
                  <span>Manage Albums</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Live-Stream"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={Manage_LiveStream_01} alt="flogo" className="" />
              <span className="ms-2">Manage Live Stream</span>
            </Link>
            <ul
              id="Live-Stream"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/all-livevideos">
                  <i className="bi bi-circle"></i>
                  <span>All Live Stream</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/add-livestream">
                  <i className="bi bi-circle"></i>
                  <span>Add New Live Stream</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/manage-live-category">
                  <i className="bi bi-circle"></i>
                  <span>Manage Live Video Categories</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/manage-live-event">
                  <i className="bi bi-circle"></i>
                  <span>Live Event Create</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Content-Partner"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={Settings_01} alt="flogo" className="" />
              <span className="ms-2">Manage Channel Setting</span>
            </Link>
            <ul
              id="Content-Partner"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/mychannel-setting">
                  <i className="bi bi-circle"></i>
                  <span>My Channel Setting</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/about-my-channel">
                  <i className="bi bi-circle"></i>
                  <span>About My Channel</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Analytics"
              data-bs-toggle="collapse"
              to="#"
            >
              <img src={analytics_01} alt="flogo" className="" />
              <span className="ms-2">Analytics</span>
            </Link>
            <ul
              id="Analytics"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/channel/video-analytics">
                  <i className="bi bi-circle"></i>
                  <span>Video Analytics</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/video-region">
                  <i className="bi bi-circle"></i>
                  <span>Video View By Region</span>
                </Link>
              </li>
              <li>
                <Link to="/channel/payouts">
                  <i className="bi bi-circle"></i>
                  <span>Payouts</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Sidebar;
