import React from "react";

function AddVideoschedule() {
  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" class="content-page">
              <div class="mt-4">
                <div class="admin-section-title">
                  <div class="iq-card">
                    <div class="" id="add-new">
                      <div class=" d-flex ">
                        <h4 class="modal-title">Create New Schedule</h4>
                      </div>

                      <div class="modal-body">
                        <div>
                          <label for="name">Name *</label>
                          <input
                            name="name"
                            id="name"
                            placeholder="Schedule Name"
                            class="form-control"
                            value=""
                          />
                          <br />
                          <label for="description">Description *</label>
                          <input
                            name="description"
                            id="description"
                            placeholder="Description"
                            class="form-control"
                            value=""
                          />
                          <br />

                          <label class="m-0">
                            Home Thumbnail:{" "}
                            <small>(9:16 Ratio or 1080X1920px)</small>
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            name="image"
                            id="image"
                          />
                        </div>
                        <br />
                        <div>
                          <label class="m-0">
                            Player Thumbnail:{" "}
                            <small>(16:9 Ratio or 1280X720px)</small>
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            name="player_image"
                            id="player_image"
                          />
                          <input type="hidden" name="_token" value="" />
                        </div>
                        <br />
                        <div class="">
                          <div>
                            <label class="mt-1">Display on Home page</label>

                            <span class="mr-2">OFF</span>
                            <label class="switch mt-2">
                              <input name="in_home" type="checkbox" />
                              <span class="slider round"></span>
                            </label>
                            <span class="ml-2">ON</span>
                          </div>
                        </div>
                      </div>

                      <div className="text-end">
                        <input type="submit" className="btn btn-primary" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddVideoschedule;
