import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function CommentSetting() {
  const [order, setOrder] = useState({});
  const [editUser, setEditUser] = useState({
    id: "",
    videos: "",
    livestream: "",
    episode: "",
    audios: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Comment-section/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log("api checks", response.data.comment_section);
        var result = response?.data?.comment_section;
        setOrder(result);
        setEditUser(result);
        // console.log('result', result)
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      videos: editUser.videos,
      livestream: editUser.livestream,
      episode: editUser.episode,
      audios: editUser.audios,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Comment-section/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="container-fluid p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i>Comment Section
                      </h4>
                    </div>
                    <hr />
                  </div>

                  <div className=" p-3 ">
                    <div className=" row ">
                      <div className="col-lg-6">
                        <p className="fs-5 fw-bold">
                          Enable Comment Section for Videos
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="">
                          {/* <label className="switch">
                            <input name="videos" onChange={handleInput} value={(editUser.videos === 1 || editUser.videos === null) ? '0' : (editUser.videos === 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" name="videos" onChange={handleInput} value={(editUser.videos === 1 || editUser.videos === null) ? '0' : (editUser.videos === 1) ? '0' : '1'}></span>
                          </label> */}
                          <label className="switch">
                            <input
                              name="videos"
                              onChange={handleInput}
                              defaultChecked={
                                editUser?.videos === 1 ? true : false
                              }
                              checked={editUser?.videos === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="videos"
                              onChange={handleInput}
                              value={editUser?.videos === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <p>Enable Comment Section for Live Videos</p>
                      </div>
                      <div className="col-lg-6">
                        <div className="">
                          {/* <label className="switch">
                            <input name="livestream" onChange={handleInput} value={(editUser.livestream === 1 || editUser.livestream === null) ? '0' : (editUser.livestream === 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" name="livestream" onChange={handleInput} value={(editUser.livestream === 1 || editUser.livestream === null) ? '0' : (editUser.livestream === 1) ? '0' : '1'}></span>
                          </label> */}
                          <label className="switch">
                            <input
                              name="livestream"
                              onChange={handleInput}
                              defaultChecked={
                                editUser?.livestream === 1 ? true : false
                              }
                              checked={editUser?.livestream === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="livestream"
                              onChange={handleInput}
                              value={editUser?.livestream === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <p>Enable Comment Section for Episode</p>
                      </div>
                      <div className="col-lg-6">
                        <div className="">
                          {/* <label className="switch">
                            <input name="episode" onChange={handleInput} value={(editUser.episode === 1 || editUser.episode === null) ? '0' : (editUser.episode === 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" name="episode" onChange={handleInput} value={(editUser.episode === 1 || editUser.episode === null) ? '0' : (editUser.episode === 1) ? '0' : '1'}></span>
                          </label> */}
                          <label className="switch">
                            <input
                              name="episode"
                              onChange={handleInput}
                              defaultChecked={
                                editUser?.episode === 1 ? true : false
                              }
                              checked={editUser?.episode === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="episode"
                              onChange={handleInput}
                              value={editUser?.episode === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <p>Enable Comment Section for Audios</p>
                      </div>
                      <div className="col-lg-6">
                        <div className="">
                          {/* <label className="switch">
                            <input name="audios" onChange={handleInput} value={(editUser.audios === 1 || editUser.audios === null) ? '0' : (editUser.audios === 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" name="audios" onChange={handleInput} value={(editUser.audios === 1 || editUser.audios === null) ? '0' : (editUser.audios === 1) ? '0' : '1'}></span>
                          </label> */}
                          <label className="switch">
                            <input
                              name="audios"
                              onChange={handleInput}
                              defaultChecked={
                                editUser?.audios === 1 ? true : false
                              }
                              checked={editUser?.audios === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="audios"
                              onChange={handleInput}
                              value={editUser?.audios === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-end p-3">
                    <button
                      className="btn btn-primary"
                      onClick={handleUpdate}
                      name="submit"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CommentSetting;
