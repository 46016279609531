import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function NewArtist() {
  const [user_id, setUser_id] = useState("");
  const [artist_name, setArtist_name] = useState("");
  const [artist_slug, setArtist_slug] = useState("");
  const [description, setDescription] = useState("");
  const [artist_type, setArtist_type] = useState("");
  const [artisttype, setArtisttype] = useState([]);
  const [image, setImage] = useState("");
  const [artist_id, setArtist_id] = useState("");
  const [interval, setInterval] = useState("");
  const [interval1, setInterval1] = useState("");

  // console.log('artist_id',artist_id)
  const [data, setData] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function artist() {
    console.log("append data");

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("artist_name", artist_name);
    formData.append("artist_slug", artist_slug);
    formData.append("description", description);
    formData.append("artist_type", artist_type);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/artist/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.artist.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          // setArtist_id(result)
          // alert("Added successfully  !")
          setInterval(10000);

          console.log("data", artist_id, image);
          const formDatas = new FormData();

          formDatas.append("image", image);
          formDatas.append("artist_id", result);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-artist`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
                setInterval1(100000);
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });

    // console.log('data', artist_id, image)
    // const formDatas = new FormData();

    // formDatas.append('image', image);
    // formDatas.append('artist_id', artist_id);

    // await axios.post('https://api.flicknexs.com/admin/Image-upload-artist', formDatas, { headers: headers })
    //   .then(response => {
    //     // console.log('api' , response);
    //     if (response.data.status === true) {
    //       var result = response.data;
    //       // console.log('result', response);
    //       // navigate('/admin/all-artist')
    //       alert("Added successfully  !")
    //       setInterval1(100000);
    //     }
    //     else {
    //       console.log('Error');
    //       alert('Enter Correct Details')
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Error');
    //     alert('Enter Correct Details')
    //   });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/artist/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.artist_type;
        setArtisttype(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="admin-section-title">
                  <h4 className="fs-title">Create Artist</h4>
                </div>
                <hr />
                <div className="f">
                  <div className="row mt-3 p-0">
                    <div className="col-sm-6 mt-3" data-collapsed="0">
                      <label className="m-0"> Artist </label>
                      <div className="panel-body">
                        <input
                          type="text"
                          onChange={(e) => setArtist_name(e.target.value)}
                          placeholder="Artist Name"
                          className="form-control"
                          name="artist_name"
                          id="artist_name"
                        />
                        <input
                          type="text"
                          hidden
                          defaultValue="4"
                          onChange={(e) => setUser_id(e.target.value)}
                          placeholder="Artist Name"
                          className="form-control"
                          name="artist_name"
                          id="artist_name"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 mt-3" data-collapsed="0">
                      <label className="m-0"> Artist Slug </label>
                      <div className="panel-body">
                        <input
                          type="text"
                          onChange={(e) => setArtist_slug(e.target.value)}
                          placeholder="Artist Slug"
                          className="form-control"
                          name="artist_slug"
                          id="artist_slug"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 p-0 align-items-center">
                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Artist Description</label>
                    <div className="panel-body">
                      <textarea
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Artist Description"
                        name="description"
                        id="description"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Artist Type</label>
                    <div className="panel-body">
                      <select
                        className="form-control"
                        name="artist_type"
                        id="artist_type"
                        onChange={(e) => setArtist_type(e.target.value)}
                      >
                        <option defaultValue="">  Select the Artist Type </option>
                        {artisttype?.map((item) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <label className="m-0">Picture</label>
                    <p className="p1">
                      Select the artist image (300x300 px or 2:2 ratio):
                    </p>
                    <div className="panel-body">
                      <input
                        type="file"
                        multiple={true}
                        className="form-control"
                        name="image"
                        onChange={(e) => setImage(e.target.files[0])}
                        id="image"
                      />
                      <input
                        type="text"
                        multiple={true}
                        className="form-control"
                        name="image"
                        hidden
                        value={artist_id}
                        id="image"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mt-3" data-collapsed="0">
                    <div className="panel-body">
                      {/* <img src={data.Thumbnail} className="movie-img" width="200" /> */}
                    </div>
                  </div>
                </div>

                <div className=" p-0 mt-4 d-flex justify-content-end">
                  <input
                    type="submit"
                    defaultValue="Update"
                    onClick={artist}
                    className="btn btn-primary mr-2"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default NewArtist;
