import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function EditLiveStream() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-video-stream container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card text-center">
                <div className="card-body p-4 text-start">
                  <div className="row mt-3 ">
                    <div className="col-sm-12">
                      <label className="m-0">Name : </label>
                      <div>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12  mt-4">
                    <label className="m-0">Slug : </label>
                    <div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Enter Slug"
                      />
                    </div>
                  </div>

                  <div className="row mt-3 text-start">
                    <div className="col-sm-12">
                      <label className="m-0">Display In Home page </label>
                      <div className="p2">
                        <div className="mt-2">
                          <input
                            type="radio"
                            value="publish_now"
                            name="type"
                            className="form-group"
                            placeholder="1"
                          />{" "}
                          Yes
                          <input
                            type="radio"
                            value="0"
                            name="type"
                            className="form-group ms-5"
                            placeholder="publish_type"
                          />{" "}
                          No
                        </div>
                        <div>
                          <input
                            type="file"
                            name="type"
                            className="form-control mt-3"
                            placeholder="publish_type"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row text-start mt-4 mb-5">
                    <div className="col-sm-12">
                      <label className="m-0">Live Stream Ratings</label>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="Drama">Drama</option>
                          <option value="Kids">Kids</option>
                          <option value="Entertainment">Entertainment</option>
                          <option value="Videos">Videos</option>
                          <option value="Music">Music</option>
                          <option value="Movies">Movies</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="col-sm-12 text-end">
                    <Link to="/admin/livestream-categories">
                      {" "}
                      <button className="btn btn-primary ms-5">close</button>
                    </Link>
                    <button className="btn btn-primary ms-5">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditLiveStream;
