import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function RevenueSettings() {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    user_id: "",
    admin_commission: "",
    user_commission: "",
    vod_admin_commission: "",
    vod_user_commission: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Revenue-Setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Revenue_Setting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      user_id: editUser.user_id,
      admin_commission: editUser.admin_commission,
      user_commission: editUser.user_commission,
      vod_admin_commission: editUser.vod_admin_commission,
      vod_user_commission: editUser.vod_user_commission,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      "http://api.flicknexs.com/admin/Revenue-Setting/update",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className=" p-2">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i> Revenue Setting{" "}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="p-2">
                    <form>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Admin Commission in %</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <input
                                type="text"
                                className="form-control"
                                name="admin_commission"
                                id="admin_commission"
                                onChange={handleInput}
                                value={editUser?.admin_commission}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>User Commission in %</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <input
                                type="text"
                                className="form-control"
                                name="user_commission"
                                id="user_commission"
                                onChange={handleInput}
                                value={editUser?.user_commission}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Admin Commission in %</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <input
                                type="text"
                                className="form-control"
                                name="vod_admin_commission"
                                id="vod_admin_commission"
                                onChange={handleInput}
                                value={editUser?.vod_admin_commission}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div
                            className="panel panel-primary"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>User Commission in % </label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <input
                                type="text"
                                className="form-control"
                                name="vod_user_commission"
                                id="vod_user_commission"
                                onChange={handleInput}
                                value={editUser?.vod_user_commission}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end mt-4">
                        <input
                          type="submit"
                          value="UpdateRevenue Settings"
                          onClick={handleUpdate}
                          className="btn btn-primary "
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default RevenueSettings;
