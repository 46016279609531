import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddSlider.css";

function AddSlider() {
  const [user_id, setUser_id] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [trailer_link, setTrailer_link] = useState("");
  const [order_position, setOrder_position] = useState("");
  const [active, setActive] = useState("");
  const [slider_image, setSlider_image] = useState("");
  const [player_image, setPlayer_image] = useState("");

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
    }
  };

  async function submituser() {
    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const formData = new FormData();

    formData.append("title", title);
    formData.append("link", link);
    formData.append("trailer_link", trailer_link);
    formData.append("order_position", order_position);
    formData.append("active", aactivedata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/slider/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          // alert("Added successfully  !")
          setInterval(10000);

          var slider_id = response.data.slider_id;
          var resssss = response.data;
          // console.log('resssss', resssss);
          // console.log('slider_id', slider_id);

          // console.log('data', slider_id,)
          const formDatas = new FormData();

          formDatas.append("slider_id", slider_id);
          formDatas.append("slider_image", slider_image);
          formDatas.append("player_image", player_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/slider/slider_image`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added Successfully");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.role;
        setData(result);
        var results = response?.data?.country;
        setNumber(results);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div className="admin-section-title">
            <div className="iq-card">
              <div className="row align-items-center ">
                <div className="modal-header">
                  <h4 className="modal-title">New Slider</h4>
                </div>

                <div className="modal-body">
                  <div>
                    <div className="form-group ">
                      <label>
                        Select the Slider Image{" "}
                        <span>(1280x720 px or 16:9 ratio):</span>
                      </label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="slider"
                        id="slider"
                        onChange={(e) => setSlider_image(e.target.files[0])}
                      />
                    </div>

                    <div className="form-group ">
                      <label className="mb-1">
                        Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
                      </label>
                      <br />
                      <input
                        type="file"
                        className="form-control"
                        name="player_image"
                        id="player_image"
                        onChange={(e) => setPlayer_image(e.target.files[0])}
                      />
                    </div>

                    <div className="form-group ">
                      <label>Target Link:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="link"
                        id="link"
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </div>
                    <div className="form-group ">
                      <label>Trailer Link:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="trailer_link"
                        id="trailer_link"
                        onChange={(e) => setTrailer_link(e.target.value)}
                      />
                    </div>

                    <div className="form-group ">
                      <label>Title:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>Status:</label>
                            </div>
                            <div className="col-lg-6">
                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    active?.active === 1 ? true : false
                                  }
                                  checked={active?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={active?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={submituser}
                        id="submit-new-cat"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddSlider;
