import React from 'react'
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import ChannelPage from "./Channel_Partner/ChannelPage";


const ChannelRoute = () => {
  return (
    <>
    <Router>
          
            <Routes>

              {/* ChannelPartner */}
              <Route path="/admin/channel" element={<ChannelPage></ChannelPage>}></Route>
              
            </Routes>
          
        </Router>
    </>
  )
}

export default ChannelRoute