import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./MobileSettings.css";

function EditMobileSettings() {
  const [data, setData] = useState([]);

  const [name, setName] = useState("");
  const [welcome_images, setWelcome_images] = useState("");
  const [welcome_images_url, setWelcome_images_url] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        var resultsname = res?.data?.Welcome_Screen[0]?.name;
        var resultsimage = res?.data?.Welcome_Screen[0]?.welcome_images;
        var welcome_images_url = res?.data?.Welcome_Screen[0]?.welcome_images_url;

        setData(res?.data?.Welcome_Screen);
        setName(resultsname);
        setWelcome_images(resultsimage);
        setWelcome_images_url(welcome_images_url);
        // console.log(resultsname)
        // console.log(resultsimage)
      });
  }, []);

  function editProduct() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    // console.log(name, welcome_images)
    const formData = new FormData();
    formData.append("name", name);
    formData.append("welcome_images", welcome_images);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('response', response.data);
        alert("success");
        navigate("/admin/mobileapp");
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2">
              <h4>Edit Mobile Setting</h4>
              <hr />

              <div class="container-fluid row" id="rtmp_url">
                <div class="col-lg-7 ">
                  <div className="mt-3">
                    <img src={welcome_images_url?.welcome_images} width={100} />
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(e) => setWelcome_images(e.target.files[0])}
                    />
                  </div>
                </div>
                <div class="text-end mt-4">
                  <input
                    type="submit"
                    value="Update"
                    class="btn btn-primary"
                    onClick={editProduct}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditMobileSettings;
