import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <div className="admin-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-7">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <Link href="">Terms and Conditions</Link>
                </li>
                <li class="list-inline-item">
                  <Link href="">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div class="col-md-5 text-right copyrights">
              <lable> Copyright 2023 </lable>
              <Link to="/" className="webnexs">
                Webnexs
              </Link>
              <lable>. All Rights Reserved.</lable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
