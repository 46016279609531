import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ChannelCommission() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Add Commission</h4>
                        </div>
                      </div>
                      <hr />

                      <div className="iq-card-body container-fluid">
                        <h5></h5>
                        <form
                          method="POST"
                          action=""
                          accept-charset="UTF-8"
                          file="1"
                          enctype="multipart/form-data"
                        >
                          <div className="row mt-12 align-items-center">
                            <div className="col-md-6 p-0">
                              <div
                                className="panel panel-primary "
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label>Percentage</label>
                                  </div>{" "}
                                  <div className="panel-options">
                                    {" "}
                                    <Link to="#" data-rel="collapse">
                                      <i className="entypo-down-open"></i>
                                    </Link>{" "}
                                  </div>
                                </div>
                                <div className="panel-body">
                                  <p className="p1">
                                    Add the Commissiom Account For Videos:
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="percentage"
                                    id="percentage"
                                    placeholder="Add Commission"
                                    defaultValue=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 mt-3">
                              <input
                                type="submit"
                                defaultValue="Update Percentage"
                                className="btn btn-primary pull-right"
                              />
                            </div>
                          </div>
                        </form>

                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChannelCommission;
