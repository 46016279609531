import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AddSeason() {
  const [user_id, setUser_id] = useState("");
  const [series_id, setSeries_id] = useState("");
  const [trailer, setTrailer] = useState("");
  const [image, setImage] = useState("");
  const [access, setAccess] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ppv_interval, setPpv_interval] = useState([]);
  const [trailer_type, setTrailer_type] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");

  const [data, setData] = useState([]);

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function season() {
    console.log("append data");

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("series_id", series_id);
    formData.append("trailer", trailer);
    formData.append("image", image);
    formData.append("access", access);
    formData.append("ppv_price", ppv_price);
    formData.append("ppv_interval", ppv_interval);
    formData.append("trailer_type", trailer_type);
    formData.append("ios_product_id", ios_product_id);
    formData.append("uploaded_by", uploaded_by);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/series_season/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const handleInput = (e) => {

  // 	setPpv_status({ ...ppv_status, [e.target.name]: e.target.checked });

  // 	if (e.target.checked === true) {
  // 		setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });

  // 	} else {
  // 		setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
  // 	}
  // }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series_season/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.SeriesSeason[0];
        setData(result);
        console.log("result", result);
        // console.log('Publish_type', result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h4 className="modal-title">Add Season</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <div className="form-group">
                    <label> Season Trailer :</label>
                    <div className="new-video-file form_video-upload">
                      <input
                        type="file"
                        className="form-control"
                        name="trailer"
                        onChange={(e) => setTrailer(e.target.files[0])}
                      />
                      <input
                        type="text"
                        hidden
                        value="1"
                        name="trailer"
                        onChange={(e) => setUser_id(e.target.value)}
                      />
                      <input
                        type="text"
                        hidden
                        value="1"
                        name="trailer"
                        onChange={(e) => setSeries_id(e.target.value)}
                      />
                      <p>Drop and drag the video file</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Season Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
                    </label>
                    <br />
                    <input
                      type="file"
                      className="season_image form-control"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>

                  <div className="form-group">
                    <label> Choose User Access:</label>

                    <select
                      className="form-control"
                      name="access"
                      onChange={(e) => setAccess(e.target.value)}
                    >
                      <option value="free">Free (everyone)</option>
                      <option value="ppv">
                        PPV (Pay Per Season(Episodes))
                      </option>
                    </select>
                  </div>

                  <div className="form-group" id="ppv_price">
                    <label className="">PPV Price:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="PPV Price"
                      name="ppv_price"
                      onChange={(e) => setPpv_price(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="m-0">IOS PPV Price:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="IOS PPV Price"
                      name="ios_product_id"
                      onChange={(e) => setIos_product_id(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label>PPV Interval:</label>
                    <p className="p1">
                      Please Mention How Many Episodes are Free:
                    </p>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="PPV Interval"
                      name="ppv_interval"
                      onChange={(e) => setPpv_interval(e.target.value)}
                    />
                  </div>

                  <div className="modal-footer">
                    <input
                      type="submit"
                      value="Upload"
                      onClick={season}
                      className="btn btn-primary "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddSeason;
