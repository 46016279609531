import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Videoforapproval() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid Videopartner">
            <div className="iq-card mt-4">
              <ul
                class="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    CPP Uploaded Series Videos
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Channel Uploaded Series Videos
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div>
                    <div class="col-md-12">
                      <div class="row">
                        <div className="mb-3">
                          <h4>Content Series Video Lists</h4>
                        </div>

                        <div class="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            class="form-control"
                            id="channel_users"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            class="form-control"
                            id="video_data"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12 text-end">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        value="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div>
                    <div class="col-md-12">
                      <div class="row">
                        <div className="mb-3">
                          <h4>Channel Series Video Lists</h4>
                        </div>

                        <div class="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            class="form-control"
                            id="channel_users"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            class="form-control"
                            id="video_data"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12 text-end">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        value="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default Videoforapproval;
