import React, { useState } from "react";
import "./UsersRevenue.css";

// import PieChart from 'react-pie-graph-chart';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  // PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function UsersRevenue() {
  const sub = [
    {
      user: "srinivasan",
      ref: "No REF",
      Type: "subscriber",
      plan: "Yearly",
      content: "",
      price: "49.99",
      Country: "Antarctica",
      date: "2021-12-23 10:23:36",
      Source: "No Transaction",
    },
  ];

  const pay = [
    {
      user: "Flicknexs",
      ref: "cus_IVKRYwYv1Kj4IH",
      Type: "subscriber",
      plan: "Quarterly",
      content: "Video",
      price: "",
      Country: "India",
      date: "2021-12-28 20:19:22",
      Source: "No Transaction",
    },
  ];

  const [isTrue, setIsTrue] = useState(true);

  const handleChange = () => {
    setIsTrue(false);
  };
  const handleOpen = () => {
    setIsTrue(true);
  };

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  // const option1 = {
  //     type: 'line',
  //     responsive: true,
  //     plugins: {
  //         legend: {
  //             position: 'top',
  //         },

  //     },
  // };

  const labels = [
    "December",
    "December",
    "December",
    "January",
    "February",
    "February",
    "February",
    "December",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Subscriber Users Revenue",

        data: [50, 200, 200, 30, 50, 200, 30, 50, 100, 250],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const label = ["July", "December", "March", "April"];

  const datas = {
    label,
    datasets: [
      {
        label: "PayPer View Revenue",

        data: [0, 40, 10],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="col-md-12">
                  <div className="iq-card-header  justify-content-between">
                    {isTrue ? (
                      <div className="iq-header-title">
                        <h4 className="card-title">Users Revenue :</h4>
                      </div>
                    ) : (
                      <div className="iq-header-title">
                        <h4 className="card-title">
                          PayPerView Users Revenue :
                        </h4>
                      </div>
                    )}
                  </div>

                  <div className="clear"></div>

                  <div className="row p-3">
                    <hr />
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <div className="col-md-3">
                          <label for="start_time"> Start Date: </label>
                          <input
                            type="date"
                            className="form-control"
                            id="start_time"
                            name="start_time"
                          />
                        </div>
                        <div className="col-md-3">
                          <label for="start_time"> End Date: </label>
                          <input
                            type="date"
                            className="form-control"
                            id="end_time"
                            name="end_time"
                          />
                        </div>

                        <div className="col-md-3 d-flex align-items-end">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-dark filter-btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Filter
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Day
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Week
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Month{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-3 d-flex align-items-end">
                          <span
                            id="export"
                            className="btn btn-success download-btn btn-sm"
                          >
                            Download CSV
                          </span>
                        </div>
                      </div>

                      {isTrue ? (
                        <div>
                          <div className="row mt-5">
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                              <Line options={options} data={data} />
                              <p className="text-center mt-2">
                                Subscriber Users Revenue
                              </p>
                            </div>
                            <div className="col-md-5"></div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row mt-5">
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                              <Line options={options} data={datas} />
                              <p className="text-center mt-2">
                                PayPer View Revenue
                              </p>
                            </div>
                            <div className="col-md-5"></div>
                          </div>
                        </div>
                      )}

                      {/* <PieChart data={[
    {
      type: "Bus",
      value: 135,
      color: "#E97D30"
    },
    {
      type: "Bicycle",
      value: 145,
      color: "#62B170"
    },
    {
      type: "Train",
      value: 90,
      color: "#F1AF13"
    },
    {
      type: "Car",
      value: 345,
      color: "#4BA2DA"
    }
                     ]} /> */}
                      <div id="Subscription_content">
                        <div className="row mt-5">
                          <div className="tab">
                            <button
                              className="tablinks btn btn-primary"
                              id="openSubscription1"
                              onClick={handleOpen}
                            >
                              Subscription
                            </button>
                            {/* <a href="{{ URL::to('admin/users/PayPerview_Revenue/')  }}"> */}
                            <button
                              className="tablinks btn btn-primary"
                              id="openPayPerView"
                              onClick={handleChange}
                            >
                              PayPer View
                            </button>
                            {/* </a> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between p-3">
                          <div className="d-flex">
                            <p>Show 10</p>
                            <p>&nbsp; &nbsp; entries</p>
                          </div>
                          <div className="d-flex">
                            <p>Search:</p>
                            <input
                              type="search"
                              id="gsearch"
                              name="gsearch"
                              className="border-0 search-rev"
                            />
                          </div>
                        </div>
                        {isTrue ? (
                          <div className="row ">
                            <div className="col-md-12">
                              <table className="table" id="cpp_revenue_table">
                                <thead>
                                  <tr className="r1">
                                    <th>#</th>
                                    <th>User </th>
                                    <th>Transaction REF</th>
                                    <th>User Type</th>
                                    <th>Plan</th>
                                    <th>Content</th>
                                    <th>Price</th>
                                    <th>Country</th>
                                    <th>Date Time</th>
                                    <th>Source</th>
                                  </tr>
                                </thead>
                                {sub.map((item, key) => (
                                  <thead>
                                    <tr  key={item?.id} className="">
                                      <td key={key}> {key + 1} </td>
                                      <th>{item.user}</th>
                                      <th>{item.ref}</th>
                                      <th>{item.Type}</th>
                                      <th>{item.plan} </th>
                                      <th>{item.content} </th>
                                      <th>{item.price} </th>
                                      <th>{item.Country}</th>
                                      <th>{item.date} </th>
                                      <th>{item.Source}</th>
                                    </tr>
                                  </thead>
                                ))}
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className="row ">
                            <div className="col-md-12">
                              <table className="table" id="cpp_revenue_table">
                                <thead>
                                  <tr className="r1">
                                    <th>#</th>
                                    <th>Usersss </th>
                                    <th>Transaction REF</th>
                                    <th>User Type</th>
                                    <th>Plan</th>
                                    <th>Content</th>
                                    <th>Price</th>
                                    <th>Country</th>
                                    <th>Date Time</th>
                                    <th>Source</th>
                                  </tr>
                                </thead>
                                {pay.map((item, key) => (
                                  <thead>
                                    <tr  key={item?.id} className="r1">
                                      <td key={key}> {key + 1} </td>
                                      <td>{item.user}</td>
                                      <td>{item.ref}</td>
                                      <td>{item.Type}</td>
                                      <td>{item.plan} </td>
                                      <td>{item.content} </td>
                                      <td>{item.price} </td>
                                      <td>{item.Country}</td>
                                      <td>{item.date} </td>
                                      <td>{item.Source}</td>
                                    </tr>
                                  </thead>
                                ))}
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                      <div id="PayPerView_content">
                        <div className="clear"></div>
                      </div>
                    </div>
                    {/* {isTrue ====2 ?
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table" id="cpp_revenue_table" >
                                    <thead>
                                        <tr className="r1">
                                            <th>#</th>
                                            <th>Usersss </th>
                                            <th>Transaction REF</th>
                                            <th>User Type</th>
                                            <th>Plan</th>
                                            <th>Content</th>
                                            <th>Price</th>
                                            <th>Country</th>
                                            <th>Date Time</th>
                                            <th>Source</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                
                                </tbody>
                           </table>
                        </div>
                    </div> : null } */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UsersRevenue;
