import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ContentPayout() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="col-md-12">
                  <div className="iq-card-header  justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Content Partners Payouts :</h4>
                    </div>
                  </div>
                  <hr />

                  <div className="clear"></div>

                  <h4 className="card-title">Content Partners </h4>

                  <div className="d-flex justify-content-between p-3">
                    <div className="d-flex">
                      <p>Show 10</p>
                      <p>&nbsp; &nbsp; entries</p>
                    </div>
                    <div className="d-flex">
                      <p>Search:</p>
                      <input
                        type="search"
                        id="gsearch"
                        name="gsearch"
                        className="border-0 search-rev"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <table
                        className="table text-center"
                        id="cpp_payouts_table"
                      >
                        <thead>
                          <tr className="r1">
                            <th>#</th>
                            <th>Username Name</th>
                            <th>Purchases Count</th>
                            <th>Total Commission</th>
                            <th>Admin Commission</th>
                            <th>Moderator Commission</th>
                            <th>Paid Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>
                              <Link
                                to=""
                                className="iq-bg-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                              >
                                <img className="ply" src="" />
                              </Link>
                              <Link
                                to=""
                                className="iq-bg-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                              >
                                <img className="ply" src="" />
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentPayout;
