import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AllUser.css";

function AllUser() {
  const [user, setUser] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/list`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.users_list?.data;
        setUser(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/users/destroy/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.users_list?.data);
        setPrevious(res?.data?.users_list?.data);
        setUser(res?.data?.users_list?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.users_list?.data);
        setPrevious(res?.data?.users_list?.data);
        setUser(res?.data?.users_list?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.users_list?.data);
        setNext(res?.data?.users_list?.data);
        setUser(res?.data?.users_list?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page iq-card">
              <div className="pt-3 ">
                <label>
                  <h4>ALL USERS</h4>
                </label>
                <div className="row ">
                  {/* <div className="col-sm-4 mb-4 col-xs-6 text-center">
                              <div className="iq-card" >
                                 <div className="tile-stats tile-red">
                                    <div className="icon"><i className="entypo-users"></i></div>
                                    <div className="num" data-start="0" data-end="" data-postfix="" data-duration="1500" data-delay="0"></div>
                                    <h4>Total Subscribers</h4>
                                    <p className="p1">The total amount of subscribers on your site.</p>
                                 </div>
                              </div>
                           </div>

                           <div className="col-sm-4 col-xs-6 mb-4 text-center">
                              <div className="iq-card" >
                                 <div className="tile-stats tile-green">
                                    <div className="icon"><i className="entypo-user-add"></i></div>
                                    <div className="num" data-start="0" data-end="{{ $total_recent_subscription }}" data-postfix="" data-duration="1500" data-delay="600"></div>
                                    <h4>New Subscribers</h4>
                                    <p className="p1">New Subscribers for today.</p>
                                 </div>
                              </div>
                           </div>

                           <div className="col-sm-4 col-xs-6 mb-4 text-center">
                              <div className="iq-card" >
                                 <div className="tile-stats tile-aqua">
                                    <div className="icon"><i className="entypo-video"></i></div>
                                    <div className="num" data-start="0" data-end="{{ $total_revenew }}" data-postfix="" data-duration="1500" data-delay="1200"></div>
                                    <h4>Revenue</h4>
                                    <p className="p1">Total Revenue</p>
                                 </div>
                              </div>
                           </div> */}

                  {/* <div className="col-md-12">

                              <form accept-charset="UTF-8" action="" method="post">
                                 <div className="row justify-content-between align-items-center">
                                    <div className="col-md-4">
                                       <label className="mb-1">  Start Date:</label>
                                       <input type="date" id="start_date" name="start_date" value="" className="form-control" />
                                    </div>
                                    <div className="col-md-4">
                                       <label className="mb-1">  End Date:</label>
                                       <input type="date" id="end_date" name="end_date" value="" className="form-control" />
                                    </div>

                                    <div className="col-md-2 mt-4" >
                                       <label className="mb-1">  </label>
                                       <input type="hidden" name="_token" value="<?= csrf_token() ?>" />
                                       <input type="submit" className="btn btn-primary" id="Export" value="Export" />
                                    </div>
                                 </div>
                              </form>
                           </div> */}
                  <div className="col-sm-12 mt-3">
                    <div className="">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title"></div>
                      </div>
                      <div className="iq-card-body">
                        <div className="table-view">
                          <table
                            id="users_table"
                            className="table movie_table text-center"
                          >
                            <thead>
                              <tr className="r1">
                                <th>S.No</th>
                                <th>Profile</th>
                                <th>Name</th>
                                <th>Contact</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {user?.map((item, key) => (
                              <tbody>
                                <tr>
                                  <td  key={item?.id}> {key + 1} </td>
                                  <td>
                                    <img
                                      src={item?.user_avatar}
                                      className="img-fluid avatar-50"
                                      alt="author-profile"
                                      width="150px"
                                    />
                                  </td>
                                  <td> {item?.name} </td>
                                  <td>{item?.mobile} </td>
                                  <td>{item?.email} </td>
                                  <td>{item?.role} </td>
                                  <td>
                                    {item?.active === 0 ||
                                    item?.active === null ? (
                                      <td className="bg-warning video_active">
                                        Inactive
                                      </td>
                                    ) : item?.active === 1 &&
                                      item?.active === 1 &&
                                      item?.active === 1 ? (
                                      <td className="bg-success video_active">
                                        Active
                                      </td>
                                    ) : (
                                      <td className="bg-warning video_active">
                                        Draft
                                      </td>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={"/admin/edit-users/" + item?.id}
                                      className="edit ms-1"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-3">
                                      <span
                                        onClick={() =>
                                          deleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>

                          <div className="row text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClick}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{page}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClick}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AllUser;
