import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function VideoLanguage() {
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [language, setLanguage] = useState([]);
  const [name, setName] = useState([]);
  const [languageimage, setLanguageimage] = useState("");

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/language/index`, {
        headers: headers,
      })
      .then((getData) => {
        setLanguage(getData?.data?.Language?.data);
        // console.log(getData.data.Language);
      });
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("name", name);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/language/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          var Language_id = response.data.Language_id;

          // console.log("slider_id", Language_id);

          // console.log('data', slider_id,)
          const formDatas = new FormData();

          formDatas.append("language_id", Language_id);
          formDatas.append("Language_image", languageimage);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/language/image-update`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added Successfully");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/language/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        // alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        // window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/language/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.Language?.data);
        setLanguage(res?.data?.Language?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/language/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.Language?.data);
        setLanguage(res?.data?.Language?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/language/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.slider.data);
        setNext(res?.data?.Language?.data);
        setLanguage(res?.data?.Language?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="iq-card mt-4">
              <div className="row mt-5 ">
                <div className="col-md-6">
                  <h4>
                    <i className="entypo-archive"></i> Languages
                  </h4>
                  <small>Organize the Languages below:</small>
                </div>

                <div className="col-md-6" align="right">
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="fa fa-plus-circle"></i> Add New
                  </button>
                </div>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          New Language
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <label>Name: </label>

                          <input
                            type="text"
                            name="name"
                            placeholder="Language Name"
                            className="form-control rtmp_urls"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="mt-3">
                          <label>Thumbnail</label>
                          <p>
                            Select The Language Image ( 1:1 Ratio Or 1024 X
                            1024px ) :
                          </p>

                          <div className=" ">
                            <label>Image:</label>
                            <input
                              type="file"
                              multiple="true"
                              className="form-control"
                              name="image"
                              id="image"
                              onChange={(e) =>
                                setLanguageimage(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary ml-3 "
                          onClick={handleSubmit}
                        >
                          Save Changes{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                className="table table-bordered iq-card text-center"
                id="categorytbl"
              >
                <tr className="table-header r1">
                  <th>
                    <label>S.No</label>
                  </th>
                  <th>
                    <label>Thumbnail</label>
                  </th>
                  <th>
                    <label>Name</label>
                  </th>
                  <th>
                    <label>Action</label>
                  </th>
                </tr>

                {language?.map((item, key) => (
                  <tr>
                    <td className="table-content"  key={item?.id}>
                      {key + 1}
                    </td>

                    <td className="table-content">
                      <img
                        src={item?.image_url}
                        width={150}
                        height={150}
                        className="img-fluid img-thumbnail img-manage"
                        alt="image"
                      />
                    </td>
                    <td className="table-content">
                      <p>{item?.name}</p>
                    </td>
                    <td className="table-content">
                      <Link to={"/admin/admin-editlanguages/" + item?.id}>
                        <i
                          className="fa fa-pencil-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <Link onClick={() => deleteOperation(item?.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>

              <div className="row text-center d-flex">
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handlePreClick}
                  >
                    {"<< Previous"}
                  </button>
                </div>
                <div className="col-lg-4">
                  <p className="">{page}</p>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleNxtClick}
                  >
                    {"Next >>"}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default VideoLanguage;
