import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ManageSeries.css";

function ManageSeries() {
  const [seriesgen, setSeriesgen] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series-category/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.SeriesGenre?.data;
        setSeriesgen(result);
        // console.log(result)
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data);
        setPrevious(res?.data?.SeriesGenre?.data);
        setSeriesgen(res?.data?.SeriesGenre?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data);
        setPrevious(res?.data?.SeriesGenre?.data);
        setSeriesgen(res?.data?.SeriesGenre?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        console.log("next", res?.data?.SeriesGenre);
        setNext(res?.data?.SeriesGenre?.data);
        setSeriesgen(res?.data?.SeriesGenre?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="p-2">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      {" "}
                      <h4>
                        <i className="entypo-archive"></i> Series Genre
                      </h4>{" "}
                    </div>

                    <div className="col-md-6" align="right">
                      <Link to="/admin/add-Genre">
                        {" "}
                        <button type="button" className="btn btn-primary">
                          Add New
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                  <hr />

                  <div
                    className="panel panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-heading">
                      <div className="panel-title">
                        <p>Organize the Categories below: </p>
                      </div>
                      <div className="panel-options">
                        <Link to="#" data-rel="collapse">
                          <i className="entypo-down-open"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins"
                      >
                        <table
                          className="table table-bordered iq-card text-center"
                          id="categorytbl"
                        >
                          <tr className="table-header r1">
                            <th>
                              <label>Series Genre Image</label>
                            </th>
                            <th>
                              <label>Series Genre Name</label>
                            </th>
                            <th>
                              <label>Operation</label>
                            </th>
                          </tr>

                          {seriesgen?.map((item) => (
                            <tr >
                              <td className="">
                                <img src={item?.image_url} width="200" alt="" />
                              </td>
                              <td className="edit_genre" key={item?.id}>
                                <p  key={item?.id}>{item?.name}</p>
                              </td>
                              <td className="edit_genre">
                                <Link
                                  to={"/admin/edit-genre/" + item?.id}
                                  className="edit ms-1"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-1">
                                  <span
                                    onClick={() => deleteOperation(item?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </table>

                        <div className="text-center">
                          <div className="row d-flex ">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClick}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{page}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClick}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageSeries;
