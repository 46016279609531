import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./SocialLoginSettings.css";

function SocialLoginSettings() {
  // const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    facebook: "",
    facebook_client_id: "",
    google: "",
    facebook_secrete_key: "",
    facebook_callback: "",
    google_client_id: "",
    google_secrete_key: "",
    google_callback: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/social_setting`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.social_setting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      facebook: editUser.facebook,
      facebook_client_id: editUser.facebook_client_id,
      facebook_secrete_key: editUser.facebook_secrete_key,
      facebook_callback: editUser.facebook_callback,
      google: editUser.google,
      google_client_id: editUser.google_client_id,
      google_secrete_key: editUser.google_secrete_key,
      google_callback: editUser.google_callback,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/social_setting_store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const facebook = () => {
    window.open(
      "https://developers.facebook.com/docs/development/create-an-app",
      "_blank"
    );
  };
  const google = () => {
    window.open(
      "https://developers.google.com/identity/sign-in/web/sign-in",
      "_blank"
    );
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section dashboard pt-2">
            <div className="container-fluid  p-3">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-credit-card"></i> Social Login
                      Settings
                    </h4>
                  </div>

                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          <div className="panel-heading">
                            <h6 className="panel-title">
                              Facebook Login Details{" "}
                            </h6>
                            <div className="panel-options">
                              <small>
                                To Create Facebook{" "}
                                <span
                                  className="fs-6 fglogin"
                                  onClick={facebook}
                                >
                                  Click Here
                                </span>
                                .
                              </small>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <p className="col-md-6">
                                <label>Enable Facebook:</label>
                              </p>
                              <div className=" col-md-6 d-flex justify-content-around align-items-center">
                                <div>
                                  <label>ON</label>
                                </div>
                                <div>
                                  {/* <label className="switch">
                                    <input name="facebook" onChange={handleInput} value={(editUser.facebook === 1 || editUser.facebook === null) ? '0' : (editUser.facebook === 1) ? '0' : '1'} type="checkbox" />
                                    <span className="slider round" name="facebook" onChange={handleInput} value={(editUser.facebook === 1 || editUser.facebook === null) ? '0' : (editUser.facebook === 1) ? '0' : '1'} ></span>
                                  </label> */}
                                  <label className="switch">
                                    <input
                                      name="facebook"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.facebook === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.facebook === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="facebook"
                                      onChange={handleInput}
                                      value={editUser?.facebook === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                                <div>
                                  <label>OFF</label>
                                </div>
                              </div>
                            </div>

                            <label>Client ID:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_client_id"
                              id="facebook_client_id"
                              placeholder="Client ID"
                              onChange={handleInput}
                              value={editUser?.facebook_client_id}
                            />

                            <br />
                            <label>Secrete Key:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_secrete_key"
                              id="facebook_secrete_key"
                              placeholder="Secrete Key"
                              onChange={handleInput}
                              value={editUser?.facebook_secrete_key}
                            />

                            <br />
                            <label>Call Back URL:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_callback"
                              id="facebook_callback"
                              placeholder="Call Back URL"
                              onChange={handleInput}
                              value={editUser?.facebook_callback}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <h6 className="panel-title">
                              Google Login Details{" "}
                            </h6>
                            <div className="panel-options">
                              <small>
                                To Create Google{" "}
                                <span
                                  className="fs-6  fglogin"
                                  onClick={google}
                                >
                                  Click Here
                                </span>
                                .
                              </small>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <p className="col-md-6">
                                <label>Enable Google:</label>
                              </p>
                              <div className="col-md-6 d-flex justify-content-around align-items-center">
                                <div>
                                  <label>ON</label>
                                </div>
                                <div>
                                  {/* <label className="switch">
                                    <input name="google" onChange={handleInput} value={(editUser.google === 1 || editUser.google === null) ? '0' : (editUser.google === 1) ? '0' : '1'} type="checkbox" />
                                    <span className="slider round" name="google" onChange={handleInput} value={(editUser.google === 1 || editUser.google === null) ? '0' : (editUser.google === 1) ? '0' : '1'} ></span>
                                  </label> */}
                                  <label className="switch">
                                    <input
                                      name="google"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.google === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.google === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="google"
                                      onChange={handleInput}
                                      value={editUser?.google === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                                <div>
                                  <label>OFF</label>
                                </div>
                              </div>
                            </div>

                            <label>Client ID:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_client_id"
                              id="google_client_id"
                              placeholder="Client ID"
                              onChange={handleInput}
                              value={editUser?.google_client_id}
                            />

                            <br />
                            <label>Secrete Key:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_secrete_key"
                              id="google_secrete_key"
                              placeholder="Secrete Key"
                              onChange={handleInput}
                              value={editUser?.google_secrete_key}
                            />

                            <br />
                            <label>Call Back URL:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_callback"
                              id="google_callback"
                              placeholder="Call Back URL"
                              onChange={handleInput}
                              value={editUser?.google_callback}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <input type="hidden" name="_token" value="" />
                    <div className="panel-body mt-3 text-end p-3">
                      <input
                        type="submit"
                        value="Update Settings"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SocialLoginSettings;
