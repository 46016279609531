import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

const EditNewSeries = () => {
  const { id } = useParams();
  // const { params } = useParams();
  var seriesid = id;

  // const [seriesid, setSeriesid] = useState('');
  // setSeriesid(id);
  // console.log(seriesid)

  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    genre_id: "",
    user_id: "",
    type: "",
    access: "",
    active: "",
    ppv_status: "",
    details: "",
    description: "",
    featured: "",
    duration: "",
    views: "",
    rating: "",
    image: "",
    player_image: "",
    tv_image: "",
    embed_code: "",
    mp4_url: "",
    webm_url: "",
    ogg_url: "",
    language: "",
    year: "",
    trailer: "",
    banner: "",
    search_tag: "",
    series_trailer: "",
    season_trailer: "",
    uploaded_by: "",
    url: "",
    Thumbnail: "",
    deleted_at: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
  });

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [language, setLanguage] = useState([]);

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const [series_id, setSeries_id] = useState("");
  const [img, setImg] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [manageseason, setManageseason] = useState([]);
  const [editseason, setEditseason] = useState([]);
  const [useraccess, setUseraccess] = useState([]);

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/edit/${seriesid}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Series[0];
      var image = resData?.Series[0];
      setEditUser(res);
      // setSeries_id(id)
      setImg(image);
      // console.log(id)
      // console.log(res);
    };
    getUser();

    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    // const series_season = async () => {
    // 	const reqData = await fetch("https://api.flicknexs.com/admin/series_season/create", { headers: headers });
    // 	const resData = await reqData.json();
    // 	var res = resData.user_access
    // 	setUseraccess(res);
    // 	// console.log(resData)
    // 	// console.log(res)
    // 	// console.log(res)
    // }
    // series_season();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.user_access;
        setUseraccess(result);
        // console.log('result', result)
        // console.log('Publish_type', result);
      })
      .catch((error) => console.log(error));
  }, [seriesid]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };
  // const handleUpdate = async (e) => {
  // 	e.preventDefault();
  // 	const editInputvalue = { title: editUser.title, slug: editUser.slug, id: editUser.id, genre_id: editUser.genre_id, user_id: editUser.user_id, access: editUser.access, details: editUser.details, description: editUser.description, active: editUser.active, ppv_status: editUser.ppv_status, featured: editUser.featured, duration: editUser.duration, views: editUser.views, rating: editUser.rating, image: editUser.image, player_image: editUser.player_image, tv_image: editUser.tv_image, embed_code: editUser.embed_code, mp4_url: editUser.mp4_url, webm_url: editUser.webm_url, ogg_url: editUser.ogg_url, language: editUser.language, year: editUser.year, trailer: editUser.trailer, banner: editUser.banner, search_tag: editUser.search_tag, series_trailer: editUser.series_trailer, season_trailer: editUser.season_trailer, uploaded_by: editUser.uploaded_by, url: editUser.url, deleted_at: editUser.deleted_at, image_url: editUser.image_url };
  // 	// console.log(editInputvalue);
  // 	let res = await fetch("https://api.flicknexs.com/admin/series/update/" + id, {
  // 		method: "POST",
  // 		headers: headers,
  // 		body: JSON.stringify(editInputvalue)
  // 	});

  // 	let resjson = await res.json();
  // 	// console.log(resjson)
  // 	if (res.status ==== 200) {
  // 		setMessage(resjson.success);
  // 		// alert('Success')
  // 		setTimeout(() => {
  // 			// navigate('/admin/series-list');
  // 		}, 2000);
  // 	} else {
  // 		setMessage("Some error Occured");
  // 		console.log('Error');
  // 		alert('Enter Correct Details')
  // 	}

  // }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      type: editUser.type,
      slug: editUser.slug,
      id: editUser.id,
      genre_id: editUser.genre_id,
      user_id: editUser.user_id,
      access: editUser.access,
      details: editUser.details,
      description: editUser.description,
      active: editUser.active,
      ppv_status: editUser.ppv_status,
      featured: editUser.featured,
      duration: editUser.duration,
      views: editUser.views,
      rating: editUser.rating,
      image: editUser.image,
      player_image: editUser.player_image,
      tv_image: editUser.tv_image,
      embed_code: editUser.embed_code,
      mp4_url: editUser.mp4_url,
      webm_url: editUser.webm_url,
      ogg_url: editUser.ogg_url,
      language: editUser.language,
      year: editUser.year,
      trailer: editUser.trailer,
      banner: editUser.banner,
      search_tag: editUser.search_tag,
      series_trailer: editUser.series_trailer,
      season_trailer: editUser.season_trailer,
      uploaded_by: editUser.uploaded_by,
      url: editUser.url,
      deleted_at: editUser.deleted_at,
      image_url: editUser.image_url,
      ads_position: editUser.ads_position,
      Channel_Partner_id: Channel_Partner_id,
    };

    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/update/` +
        seriesid,
      {
        method: "POST",
        headers: headers,

        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (resjson.status === true) {
      setMessage(resjson.success);
      setTimeout(() => {
        //   navigate('/admin/livestream');
      }, 2000);
      var result = resjson;
      // console.log('result', result);
      // navigate('/admin/series-list')
      alert("Added successfully  !");

      // var series_id = resjson.Series_id
      // // console.log(series_id)
      // var resssss = resjson.data

      // // console.log(series_id)

      // setSeries_id(series_id)

      const formDatas = new FormData();

      formDatas.append("series_id", seriesid);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("tv_image", tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/Image_upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const [access, setAccess] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ppv_interval, setPpv_interval] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  const [trailers, setTrailers] = useState("");
  const [trailerimage, setTrailerImage] = useState("");

  async function series_season() {
    // console.log("append data");

    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("ppv_price", ppv_price);
    formData.append("access", access);
    formData.append("ppv_interval", ppv_interval);
    formData.append("Channel_Partner_id", Channel_Partner_id);
    formData.append("ios_product_id", ios_product_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api', response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log("result", result);
          // navigate('/admin/series-list')
          alert("Added successfully  !");

          var SeriesSeason_id = response.data.SeriesSeason_id

          const formDat = new FormData();

          formDat.append("season_id", SeriesSeason_id);
          formDat.append("image", trailerimage);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Image_upload`,
              formDat,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api', response);
              if (response.data.status === true) {
                var result = response.data;
                console.log("result", result);
                alert("Added successfully  !");
              }
            });

          const formDa = new FormData();

          formDa.append("season_id", SeriesSeason_id);
          formDa.append("trailer", trailers);
          formDa.append("trailer_type", trailer_type);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Trailer_upload`,
              formDa,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api', response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log("result", result);
                alert("Added successfully  !");
              }
            });
        }
      })

      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response?.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          // console.log(data.user_access, "user");
          const userAcces = data?.user_access?.map((userAcc) => ({
            value: userAcc?.role,
            label: userAcc?.name,
          }));

          const ppv_price = data?.InappPurchase?.map((userAcc) => ({
            value: userAcc?.id,
            label: userAcc?.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();

    const getcategoryname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_category)
        var result = response?.data?.series_categories;
        setOrder(result);
        // console.log('result', result)
        // console.log('result', result);
        for (let i = 0; i < result?.length; i++) {
          getcategoryname?.push(result[i]?.name);
        }
        setCategory(getcategoryname);
      })
      .catch((error) => console.log(error));

    const getartistname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response?.data?.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result?.length; i++) {
          getartistname?.push(result[i]?.artist_name);
        }
        setArtists(getartistname);
      })
      .catch((error) => console.log(error));

    const getlanguagename = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getlanguagename?.push(result[i]?.name);
        }
        setLanguage(getlanguagename);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.InappPurchase;
        setPublish(result);
        // console.log('result', result)
        // console.log('Publish_type', result);
      })
      .catch((error) => console.log(error));

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/list`,
        { Channel_Partner_id: Channel_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.SeriesSeason?.data;
        // setSeries(result)
        // console.log(result);
        setEditseason(result);
        setManageseason(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/delete/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, content: e });
    // console.log(e, "onchnageddddd");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-3">
              <h4>
                <i className="entypo-plus"></i> Edit Series
              </h4>
              <hr />
              <form>
                <div className="row mt-3">
                  <div className="col-sm-6 mb-3">
                    <label className="m-0">Title</label>
                    <p className="p1">
                      Add the series title in the textbox below.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Series Title"
                      value={editUser?.title}
                      onChange={handleInput}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="type"
                      hidden
                      id="type"
                      placeholder="Series type"
                      value={editUser?.type}
                      onChange={handleInput}
                    />
                  </div>

                  {/* <div className="col-sm-6">
										<label className="m-0">Created Date</label>
										<div className="panel-body" >
											<p className="p1">Select Date/Time Below</p>
											<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
										</div>
									</div> */}

                  <div className="col-sm-6" data-collapsed="0">
                    <label className="m-0">Slug</label>
                    <div className="panel-body">
                      <p className="p1">Add a URL Slug</p>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        id="slug"
                        placeholder="Series Slug"
                        value={editUser?.slug}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the series image ( 9:16 Ratio or 1080X1920px ):
                        </p>
                        <img
                          src={img?.image_url}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setImage(e.target.files[0])}
                          className="form-control image series_image"
                          name="image"
                          id="image"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Player Image </label>
                        </div>
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the Player image ( 16:9 Ratio or 1280X720px ):
                        </p>
                        <img
                          src={img?.player_image}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                          className="form-control"
                          name="player_image"
                          id="player_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series TV Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the TV series image ( 16:9 Ratio or 1920 X 1080
                          px ):
                        </p>
                        <img
                          src={img?.tv_image}
                          className="series-img"
                          width="200"
                        />
                        <input
                          type="file"
                          multiple="true"
                          onChange={(e) => setTv_image(e.target.files[0])}
                          className="form-control image"
                          name="tv_image"
                          id="tv_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-primary mt-3" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">
                            Series Details, Links, and Info
                          </label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <JoditEditor
                          // ref={editor}
                          value={editUser?.details}
                          //   onChange={(newContent) => setContent(newContent)}
                          // onChange={handleInput}

                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Short Description</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Add a short description of the series below:
                        </p>
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"
                          onChange={handleInput}
                          value={editUser?.description}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Cast and Crew</label>{" "}
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add artists for the series below:
                            </p>
                            {/* <select className="form-control js-example-basic-multiple" name="artists" multiple="multiple">
														<option value={editUser.artists}>{editUser.artists}</option>
													</select> */}
                            <Multiselect
                              isObject={false}
                              value={editUser?.artists}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={artist}
                              onChange={handleInput}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Category</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Category Below:
                            </p>

                            <Multiselect
                              isObject={false}
                              onChange={handleInput}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={category}
                              value={editUser?.category}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Ratings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body p-0 ">
                            <p className="p1">IMDb Ratings 10 out of 10</p>
                            <select
                              value={editUser?.rating}
                              className="form-select"
                              name="rating"
                              id="rating"
                              onChange={handleInput}
                            >
                              {/* <option value={editUser.rating}>{editUser.rating}</option> */}
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Language</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Language Below:
                            </p>
                            {/* <select className="form-control js-example-basic-multiple" id="language" name="language[]" multiple="multiple" >

														<option value="" selected="true"></option>

														<option value="" ></option>

													</select> */}

                            <Multiselect
                              onChange={handleInput}
                              isObject={false}
                              // onRemove={(event) => { console.log(event) }}
                              // onSelect={(event) => { console.log(event) }}
                              options={language}
                              value={editUser?.language}
                              showCheckbox
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Year</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body  p-0">
                            <p className="p1">Series Created Year</p>
                            <input
                              className="form-control"
                              name="year"
                              id="year"
                              onChange={handleInput}
                              value={editUser?.year}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title font-weight-bold">
                            <label className="m-0">Search Tags</label>
                          </div>

                          <div className="">
                            <input
                              className="form-control "
                              type="search"
                              placeholder="search_tags"
                              aria-label="Search"
                              value={editUser?.search_tag}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                        {/* <div className="panel-body  p-0" >
												<p className="p1">Add series tags below:</p>
												<input type="text" className="form-control" id="tag-input1" name="search_tag" />
											</div> */}
                      </div>
                    </div>

                    <div className="row text-start mt-4">
                      <div className="col-sm-6">
                        <label className="m-0">Choose Ads Position</label>

                        <div className="">
                          {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                          <Select
                            options={adsPositions}
                            onChange={(e) => handleAdsPositionChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label className="m-0">Choose Advertisement</label>

                        <div className="">
                          <Select
                            options={secondSelectOptions}
                            onChange={(e) => setSecondSelectOptions(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row p-3">
                      <div className="col-sm-4 p-0">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              {" "}
                              <label className="m-0">Duration</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Enter the duration in the (HH: MM : SS) format{" "}
                            </p>
                            <input
                              className="form-control"
                              name="duration"
                              id="duration"
                              value={editUser?.duration}
                            />
                          </div>
                        </div>

                        <div className="panel-body mt-3">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <label className="m-0 p2">
                              Apply Global PPV Price:
                            </label>
                            {/* <label className="switch" onChange={handleInput}>
														<input name="ppv_status" value={(editUser.ppv_status === 1 || editUser.ppv_status === null) ? '0' : (editUser.ppv_status === 1) ? '0' : '1'} type="checkbox" />
														<span className="slider round" name="ppv_status" value={(editUser.ppv_status === 1 || editUser.ppv_status === null) ? '0' : (editUser.ppv_status === 1) ? '0' : '1'} ></span>
													</label> */}
                            <label className="switch">
                              <input
                                name="ppv_status"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.ppv_status === 1 ? true : false
                                }
                                checked={
                                  editUser?.ppv_status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="ppv_status"
                                onChange={handleInput}
                                value={editUser?.ppv_status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                        {/* <div className="panel-body ">
												<div className="d-flex justify-content-between align-items-baseline">
													<label className="p2" for="featured" >Is this series Featured:</label>

													<label className="switch" onChange={handleInput}>
														<input name="status" checked value={(editUser.featured === 0 || editUser.featured === null) ? '1' : (editUser.featured === 0) ? '1' : '0'} type="checkbox" />
														<span className="slider round" name="status" checked value={(editUser.featured === 0 || editUser.featured === null) ? '1' : (editUser.featured === 0) ? '1' : '0'} ></span>
													</label>
												</div>
											</div> */}
                      </div>

                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">User Access</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Who is allowed to view this series?
                            </p>
                            <select
                              value={editUser?.access}
                              className="form-control"
                              onChange={handleInput}
                            >
                              {/* <option value={editUser.access}>{editUser.access}</option> */}
                              {data?.map((item) => (
                                <option value={item?.value}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Status Settings</label>
                            </div>
                          </div>
                          <div className="panel-body mt-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Is this series Featured:
                              </label>
                              {/* <label className="switch" onChange={handleInput} >
															<input name="status" checked value={(editUser.featured === 0 || editUser.featured === null) ? '1' : (editUser.featured === 0) ? '1' : '0'} type="checkbox" />
															<span className="slider round" name="status" checked value={(editUser.featured === 0 || editUser.featured === null) ? '1' : (editUser.featured === 0) ? '1' : '0'} ></span>
														</label> */}
                              <label className="switch">
                                <input
                                  name="featured"
                                  id="featured"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.featured === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.featured === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="featured"
                                  id="featured"
                                  onChange={handleInput}
                                  value={editUser?.featured === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="banner" name="banner">
                                Banner :
                              </label>
                              <label className="switch" onChange={handleInput}>
															<input name="banner" value={(editUser.banner === 1 || editUser.banner === null) ? '0' : (editUser.banner === 1) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="banner" value={(editUser.banner === 1 || editUser.banner === null) ? '0' : (editUser.banner === 1) ? '0' : '1'} ></span>
														  </label>
                              <label className="switch">
                                <input
                                  name="banner"
                                  id="banner"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.banner === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.banner === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                  id="banner"
                                  onChange={handleInput}
                                  value={editUser?.banner === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div> */}
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="active">
                                Is this series Active:
                              </label>

                              {/* <label className="switch" onChange={handleInput} >
															<input name="active" value={(editUser.active === 1 || editUser.active === null) ? '0' : (editUser.active === 1) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="active" value={(editUser.active === 1 || editUser.active === null) ? '0' : (editUser.active === 1) ? '0' : '1'} ></span>
														</label> */}
                              <label className="switch">
                                <input
                                  name="active"
                                  id="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.active === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.active === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  id="active"
                                  onChange={handleInput}
                                  value={editUser?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Enable this series as Slider:
                              </label>
                              {/* <label className="switch" onChange={handleInput}>
															<input name="featured" value={(editUser.featured === 1 || editUser.featured === null) ? '0' : (editUser.featured === 1) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="featured" value={(editUser.featured === 1 || editUser.featured === null) ? '0' : (editUser.featured === 1) ? '0' : '1'} ></span>
														</label> */}
                              <label className="switch">
                                <input
                                  name="banner"
                                  id="banner"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.banner === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.banner === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                  id="banner"
                                  onChange={handleInput}
                                  value={editUser?.banner === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="trailer">
                                  Season Trailer:
                                </label>
                              </div>
                              {/* <label className="switch" onChange={handleInput} >
															<input name="trailer" value={(editUser.trailer === 1 || editUser.trailer === null) ? '0' : (editUser.trailer === 1) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="trailer" value={(editUser.trailer === 1 || editUser.trailer === null) ? '0' : (editUser.trailer === 1) ? '0' : '1'} ></span>
														</label> */}
                              <label className="switch">
                                <input
                                  name="season_trailer"
                                  id="season_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    editUser?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="season_trailer"
                                  id="season_trailer"
                                  onChange={handleInput}
                                  value={
                                    editUser?.season_trailer === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="active">
                                  Series Trailer:
                                </label>
                              </div>
                              {/* <label className="switch" onChange={handleInput} >
															<input name="series_trailer" value={(editUser.series_trailer === 1 || editUser.series_trailer === null) ? '0' : (editUser.series_trailer === 1) ? '0' : '1'} type="checkbox" />
															<span className="slider round" name="series_trailer" value={(editUser.series_trailer === 1 || editUser.series_trailer === null) ? '0' : (editUser.series_trailer === 1) ? '0' : '1'} ></span>
														</label> */}
                              <label className="switch">
                                <input
                                  name="series_trailer"
                                  id="series_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    editUser?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="series_trailer"
                                  id="series_trailer"
                                  onChange={handleInput}
                                  value={
                                    editUser?.series_trailer === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div className="">
                                <label className="p2" for="active">
                                  Season 1 :
                                </label>
                              </div>
                              {/* <label className="switch" onChange={handleInput} >
                                <input name="season_trailer" value={(editUser.season_trailer === 1 || editUser.season_trailer === null) ? '0' : (editUser.season_trailer === 1) ? '0' : '1'} type="checkbox" />
                                <span className="slider round" name="season_trailer" value={(editUser.season_trailer === 1 || editUser.season_trailer === null) ? '0' : (editUser.season_trailer === 1) ? '0' : '1'} ></span>
                              </label> */}

                              <label className="switch">
                                <input
                                  name="trailer"
                                  id="trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.trailer === 1 ? true : false
                                  }
                                  checked={
                                    editUser?.trailer === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="trailer"
                                  id="trailer"
                                  onChange={handleInput}
                                  value={editUser?.trailer === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>

                            <div className="row justify-content-between align-items-baseline mr-2 views">
                              <div className="col-md-5">
                                <label className="p2" for="active">
                                  Views :
                                </label>
                              </div>
                              <div className="col-md-3 ">
                                {/* <label className="switch" onChange={handleInput} >
																<input name="views" value={(editUser.views === 1 || editUser.views === null) ? '0' : (editUser.views === 1) ? '0' : '1'} type="checkbox" />
																<span className="slider round" name="views" value={(editUser.views === 1 || editUser.views === null) ? '0' : (editUser.views === 1) ? '0' : '1'} ></span>
															</label> */}
                                <label className="switch">
                                  <input
                                    name="views"
                                    id="views"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.views === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.views === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="views"
                                    id="views"
                                    onChange={handleInput}
                                    value={editUser?.views === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="iq-card">
              <div className="admin-section-title ">
                <div className="row p-3">
                  <div className="col-md-8">
                    <h3 className="fs-title">Manage Season &amp; Episodes</h3>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                    <Link
                      to=""
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Create Season
                    </Link>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Add Season</h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="modal-body">
                        <form
                          id="new-cat-form"
                          accept-charset="UTF-8"
                          action=""
                          enctype="multipart/form-data"
                          method="post"
                        >
                          <div className="form-group">
                            <label> Season Trailer :</label>
                            <div className="new-video-file form_video-upload">
                              <input
                                type="file"
                                className="form-control"
                                accept="video/mp4,video/x-m4v,video/*"
                                name="trailer"
                                id="trailer"
                                onChange={(e) => setTrailers(e.target.files[0])}
                              />
                              <p>Drop and drag the video file</p>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>
                              Season Thumbnail{" "}
                              <span>(16:9 Ratio or 1280X720px)</span>
                            </label>
                            <br />
                            <input
                              type="file"
                              className="season_image form-control"
                              name="trailerimage"
                              id="trailerimage"
                              onChange={(e) =>
                                setTrailerImage(e.target.files[0])
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label> Choose User Access:</label>
                            <select
                              className="form-control"
                              id="access"
                              name="access"
                              onChange={(e) => setAccess(e.target.value)}
                            >
                              {useraccess?.map((item) => (
                                <option value={item?.name}>{item?.name}</option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group" id="ppv_price">
                            <label className="">PPV Price:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="PPV Price"
                              name="ppv_price"
                              id="price"
                              onChange={(e) => setPpv_price(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label className="m-0">IOS PPV Price:</label>
                            <select
                              name="ios_ppv_price"
                              className="form-control"
                              id="ios_ppv_price"
                              onChange={(e) =>
                                setIos_product_id(e.target.value)
                              }
                            >
                              <option value="">Select IOS PPV Price: </option>
                              <option value=""></option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label>PPV Interval:</label>
                            <p className="p1">
                              Please Mention How Many Episodes are Free:
                            </p>
                            <input
                              type="text"
                              id="ppv_interval"
                              name="ppv_interval"
                              onChange={(e) => setPpv_interval(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </form>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={series_season}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Seasons</th>
                        <th>Operation</th>
                      </tr>
                      {editseason?.map((edit) => (
                        <tr>
                          <td valign="bottom">
                            <p>Season {edit?.id} </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={"/channel/edit-season/" + edit?.id}
                                className="edit ms-1"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                >
                                  {" "}
                                  Edit Season
                                </i>
                              </Link>
                              <Link to="" className="delete ms-1">
                                <span onClick={() => deleteOperation(edit?.id)}>
                                  <i className="fa fa-trash" aria-hidden="true">
                                    Delete
                                  </i>{" "}
                                </span>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div className="col-lg-6 p-0">
                    <table className="table table-bordered genres-table">
                      <tr className="table-header">
                        <th>Episodes</th>
                        <th>Operation</th>
                      </tr>
                      {manageseason?.map((manage) => (
                        <tr>
                          <td valign="bottom">
                            <p> Episodes {manage?.id} </p>
                          </td>
                          <td>
                            <p>
                              <Link
                                to={
                                  "/channel/manage-episode/" +
                                  seriesid +
                                  "/" +
                                  manage?.id
                                }
                                className="edit ms-1"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                >
                                  Manage Episodes
                                </i>
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default EditNewSeries;
