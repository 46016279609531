import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function CurrencySettings() {
  const [currency, setCurrency] = useState([]);

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.allCurrency)
        var result = response?.data?.CurrencySetting;
        setCurrency(result);
        // console.log('result', result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/` + id, { headers: headers })
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="container-fluid p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i> Currency Setting{" "}
                      </h4>
                    </div>

                    {/* <div className="col-md-8 mb-3" align="right">
											<Link to="" className="btn btn-primary"><i className="fa fa-plus-circle"></i> Add Currency</Link></div> */}
                    <hr />
                  </div>

                  <div
                    className="panel panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-heading">
                      <div className="panel-title">
                        <p className="p1">Organize the sliders below: </p>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div id="nestable" className="nested-list with-margins">
                        <table className="table table-bordered" id="slidertbl">
                          <tr className="table-header r1">
                            <th className="text-center">ID</th>
                            <th className="text-center">Currency Symbol</th>
                            <th className="text-center">Currency Country</th>
                            <th className="text-center">Action</th>
                          </tr>
                          {currency?.map((item) => (
                            <tr className="dd" id="" key={item?.id} >
                              <td valign="bottom" className="text-center">
                                {item?.id}
                              </td>
                              <td valign="bottom" className="text-center">
                                {item?.symbol}
                              </td>
                              <td valign="bottom" className="text-center">
                                {item?.country}
                              </td>
                              <td className="text-center">
                                <div className="align-items-center list-user-action">
                                  <Link
                                    to={
                                      "/admin/update_currency_settings/" +
                                      item?.id
                                    }
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CurrencySettings;
