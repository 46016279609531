import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function ManageCountry() {
  const [countries, setCountries] = useState([]);
  const [id, setId] = useState("");
  const [countriesdrop, setCountriesdrop] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Block-countries/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.countries?.data;
        setCountries(result);
        // console.log(response.data.countries)
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Block-countries/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.countries?.data;
        setCountriesdrop(result);
        // console.log(response.data.countries)
      })
      .catch((error) => console.log(error));
  }, []);

  async function addcountries() {

    const formData = new FormData();

    formData.append("id", id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/series-list')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.countries?.data);
        setCountries(res?.data?.countries?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.countries?.data);
        setCountries(res?.data?.countries?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.countries?.data);
        setCountries(res?.data?.countries?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="iq-card mt-4">
            <div id="content-page" class="content-page">
              <div class="container-fluid">
                <div class="admin-section-title">
                  <div class="">
                    <div class="row">
                      <div class="col-md-4">
                        <h4>
                          <i class="entypo-archive"></i> Blocked Countries{" "}
                        </h4>
                      </div>
                      <div class="col-md-8" align="right">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i class="fa fa-plus-circle"></i> Add New
                        </button>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Select a Country to Block
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <form>
                              <select
                                class="selectpicker countrypicker form-control"
                                name="country_name"
                                data-live-search="true"
                                data-default="United States"
                                data-flag="true"
                                onChange={(e) => setId(e.target.value)}
                              >
                                {countriesdrop?.map((item, key) => (
                                  <option  key={item?.id} value={item.id}>
                                    {item.country_name}
                                  </option>
                                ))}
                              </select>
                            </form>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addcountries}
                            >
                              Add Country
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="modal fade" id="add-new">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4 class="modal-title">
                              Select a Country to Block
                            </h4>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                          </div>

                          <div class="modal-body">
                            <form id="new-cat-form" accept-charset="UTF-8">
                              <select
                                class="selectpicker countrypicker form-control"
                                name="country_name"
                                data-live-search="true"
                                data-default="United States"
                                data-flag="true"
                              ></select>
                            </form>
                          </div>

                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              id="submit-new-cat"
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="panel panel-primary category-panel"
                      data-collapsed="0"
                    >
                      <div class="panel-heading">
                        <div class="panel-title">
                          <p class="p1">
                            Organize the Blocked Countries below:{" "}
                          </p>
                        </div>

                        <div class="panel-options">
                          <a href="#" data-rel="collapse">
                            <i class="entypo-down-open"></i>
                          </a>
                        </div>
                      </div>

                      <div class="panel-body">
                        <div
                          id="nestable"
                          class="nested-list dd with-margins p-0"
                        >
                          <table class="table table-bordered iq-card">
                            <thead>
                              <tr class="table-header r1">
                                <th class="text-center">
                                  <label>#ID</label>
                                </th>
                                <th class="text-center">
                                  <label>Country Name</label>
                                </th>
                                <th class="text-center">
                                  <label>Action</label>
                                </th>
                              </tr>
                            </thead>

                            {countries?.map((item, key) => (
                              <thead className="text-center">
                                <tr>
                                  <td key={item?.id}> {key + 1} </td>
                                  <td className="title">
                                    {item?.country_name}
                                  </td>

                                  <td>
                                    {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}

                                    <Link to="" className="delete ms-2">
                                      <span
                                        onClick={() =>
                                          deleteOperation(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              </thead>
                            ))}
                          </table>
                        </div>
                      </div>
                      <div className="row text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClick}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{page}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClick}
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ManageCountry;
