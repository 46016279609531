import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddUser.css";

function AddUser() {
  const [user_id, setUser_id] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [ccode, setCcode] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [avatar, setAvatar] = useState("");
  const [role, setRole] = useState("");
  const [active, setActive] = useState("");
  const [password, setPassword] = useState("");

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function submituser() {
    console.log("append data");

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("username", username);
    formData.append("email", email);
    formData.append("ccode", ccode);
    formData.append("mobile_number", mobile_number);
    formData.append("avatar", avatar);
    formData.append("role", role);
    formData.append("active", active);
    formData.append("password", password);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/users/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')

          alert("Added successfully  !");
          setInterval(10000);

          // console.log('data', artist_id, image)
          // const formDatas = new FormData();

          // formDatas.append('image', image);
          // formDatas.append('artist_id', result);

          // axios.post('https://api.flicknexs.com/admin/Image-upload-artist', formDatas, { headers: headers })
          // 	.then(response => {
          // 		// console.log('api' , response);
          // 		if (response.data.status === true) {
          // 			var result = response.data;
          // 			// console.log('result', response);
          // 			// navigate('/admin/all-artist')
          // 			alert("Added successfully  !")
          // 			setInterval1(100000);
          // 		}
          // 	})
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
    }
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.role;
        setData(result);
        var results = response?.data?.country;
        setNumber(results);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/list`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.users_list;
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div>
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <div id="user-badge">
                      {/* <img height="100" width="100" src={user} /> */}
                      <label for="avatar">Profile Image</label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control mt-2 mb-3"
                        name="avatar"
                        id="avatar"
                        onChange={(e) => setAvatar(e.target.files[0])}
                      />
                    </div>
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1"> Username</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          name="username"
                          id="username"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1">User's Mobile</label>
                        <div className="row">
                          <div className="col-sm-4">
                            <select
                              className="form-control"
                              onChange={(e) => setCcode(e.target.value)}
                            >
                              {number?.map((item) => (
                                <option  key={item?.id}
                                  value={item?.country_name + item?.phonecode}
                                >
                                  {item?.country_name} ({item?.phonecode})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control mb-3"
                              name="mobile_number"
                              id="mobile_number"
                              onChange={(e) => setMobile_number(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1">User Email Address</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          name="email"
                          id="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="mb-1">Password</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          (leave empty to keep your original password)
                        </p>

                        <p className="p1">Enter users password:</p>

                        <input
                          type="password"
                          className="form-control mb-3"
                          name="passwords"
                          id="passwords"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="mb-1">User Role</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body">
                        <p className="p1">Select the user's role below</p>
                        <select
                          className="form-control"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          {data?.map((item) => (
                            <option  key={item?.id} value={item?.role_name}>
                              {item?.role_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-2">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">User Active Status </label>
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active?.active === 1 ? true : false}
                          checked={active?.active === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active?.active === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-2 p-2 d-flex justify-content-end">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Update"
                    onClick={submituser}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default AddUser;
