import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Addcategory.css";

function Addcategory() {
  const [parent_id, setParent_id] = useState("");
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function submitcategory() {
    console.log("append data");

    const formData = new FormData();

    formData.append("parent_id", parent_id);
    formData.append("name", name);
    formData.append("slug", slug);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/audio-category/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          alert("Added successfully  !");
          setInterval(10000);

          var resultss = response.data.AudioGenreid;
          console.log("resultsss", resultss);
          // navigate('/admin/all-artist')
          // setArtist_id(result)

          console.log("data", image);
          const formDatas = new FormData();

          formDatas.append("image", image);
          formDatas.append("resultss", resultss);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audiogenre`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.role;
        setData(result);
        var results = response?.data?.country;
        setNumber(results);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio-category/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.AudioGenre?.data;
        setUser(result);
        // {
        //   result.map((item) =>
        //     setUser(item.image_url),
        //   )
        // }
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Add Category</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group ">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      onChange={(e) => setSlug(e.target.value)}
                      placeholder="Enter Slug"
                    />
                  </div>
                  <div className="form-group ">
                    <label>Image:</label>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                      id="image"
                    />
                  </div>

                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                      onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {user?.map((item) => (
                        <option value={item?.parent_id}>{item?.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="submit-new-cat"
                      onClick={submitcategory}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Addcategory;
