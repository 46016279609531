import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { fontSize } from "@mui/system";

const AddLandingPages = () => {
  const editor = useRef(null);

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [css, setCss] = useState("");
  const [bootstrap, setBootstrap] = useState("");
  const [script, setScript] = useState("");
  const [isHeader, setIsHeader] = useState(false);
  const [isFooter, setIsFooter] = useState(false);
  console.log(isHeader, "check");
  const [section, setSection] = useState();

  const access_token = localStorage.getItem("access_token");

  const config = {
    placeholder: "Start typings...",
  };

  const checkHandler = () => {
    setIsHeader(!isHeader);
  };
  const checkHandler1 = () => {
    setIsFooter(!isFooter);
  };

  const contentFieldChanaged = (data) => {
    setSection(data);
    // console.log("datasssssss", data);
  };
  // console.log(section, "sss");

  // const sectionedit = section;
  // var sectiondata = JSON.stringify(sectionedit);
  // var sectionsubmit = sectiondata;

  console.log(section, "section");
  const handleSave = async () => {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("custom_css", css);
    formData.append("script_content", script);
    formData.append("bootstrap_link", bootstrap);
    formData.append("header", isHeader === true ? 1 : 0);
    formData.append("footer", isFooter === true ? 1 : 0);
    formData.append("section_1", JSON.stringify(section));

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status === true) {
          var result = response.data;
          console.log("result", response);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i>Add New Landing Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">
                      <div className="col-md-5 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Slug</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                              onChange={(e) => setSlug(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div class="mb-3 mt-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Custom CSS :
                      </label>

                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={(e) => setCss(e.target.value)}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea2"
                        class="form-label"
                      >
                        Bootstrap & Script Link :
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea2"
                        rows="3"
                        onChange={(e) => setBootstrap(e.target.value)}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea3"
                        class="form-label"
                      >
                        Custom Script Content :
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea3"
                        rows="3"
                        onChange={(e) => setScript(e.target.value)}
                      ></textarea>
                    </div>

                    <div class="mb-3 mt-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Header & Footer :
                      </label>
                      <div>
                        <label> Header</label>
                        <div className="mt-3">
                          <label className="text-danger">Disable </label>
                          <label className="switch">
                            <input
                              name="active"
                              // value={item.id}
                              // id="checkbox"
                              type="checkbox"
                              checked={isHeader}
                              onChange={checkHandler}
                            />
                            <span
                              className="slider round"
                              name="active"
                              // value={item.id}
                            ></span>
                          </label>
                          <label className="text-success">Enable</label>
                        </div>
                        <label> Footer</label>
                        <div className="mt-3">
                          <label className="text-danger">Disable</label>
                          <label className="switch">
                            <input
                              name="active"
                              // value={item.id}
                              type="checkbox"
                              checked={isFooter}
                              onChange={checkHandler1}
                            />
                            <span
                              className="slider round"
                              name="active"
                              // value={item.id}
                            ></span>
                          </label>
                          <label className="text-success">Enable</label>
                        </div>
                      </div>
                    </div>

                    <div>
                      {/* <div className="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Section 1
                            <TbPlus />
                          </label>
                        </div>
                      </div> */}

                      <div className="row d-flex align-items-center my-3">
                        <label style={{ fontSize: "22px" }}>Section</label>
                        <div
                          className="panel panel-primary col-md-10"
                          data-collapsed="0"
                        >
                          <div className="panel-title mt-3">
                            <label>Page Content</label>
                          </div>
                          <div
                            className="panel-body mt-3"
                            // onChange={(evnt) => handleChange(index, evnt)} value={pagecontent}
                          >
                            <JoditEditor
                              // ref={editor}
                              value={section}
                              onChange={(newContent) =>
                                contentFieldChanaged(newContent)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <RiDeleteBin6Line />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary pull-right"
                          onClick={handleSave}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddLandingPages;
