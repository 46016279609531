// import React from 'react'
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';

// function Manageepisode() {

//   return (

//     <>
//       <div className='row m-0 p-0'>
//         <div className='col-lg-2'>  </div>
//         <div className='col-lg-10'>
//
//           <section className="section container-fluid">

//             <div className="iq-card p-3 mt-3" >

//               <h4><i className="entypo-plus"></i> Manage Series</h4>
//               <hr />

//               <div id="episode_uploads">
//                 <div className="content file">
//                   <h3 className="card-title upload-ui">Upload Full Episode Here</h3>

//                   <p className="p1">Trailers Can Be Uploaded From Video Edit Screen</p>
//                 </div>
//               </div>

//               <div className="p-4">

//                 <h3 className="card-title">Seasons &amp; Episodes</h3>
//                 <div className="admin-section-title">
//                   <div className="row">

//                     <table className="table table-bordered iq-card text-center" id="categorytbl">
//                       <tr className="table-header r1">
//                         <th><label>Episode </label></th>
//                         <th><label>Episode Name</label></th>
//                         <th><label>Slider</label></th>
//                         <th><label>Status</label></th>
//                         <th><label>Action</label></th>
//                       </tr>

//                       <tr id="{{ $episode->id }}">
//                         <td valign="bottom"><p> Episode</p></td>
//                         <td valign="bottom"><p>episode-title</p></td>
//                         <td valign="bottom">
//                           <div className="mt-1">
//                             <label className="switch">
//                               <input name="video_status" className="video_status" id="{{ 'video_'.$episode->id }}" type="checkbox" />
//                               <span className="slider round"></span>
//                             </label>
//                           </div>
//                         </td>

//                         <td > <p className="bg-success video_active"> echo "Approved</p></td>

//                         <td>
//                           <div className=" align-items-center">
//                             <Link href="{{ URL::to('admin/episode/episode_edit') . '/' . $episode->id }}" className="btn btn-xs btn-primary"><span className="fa fa-edit"></span>Edit Video</Link>
//                             <Link href="{{ URL::to('admin/episode/edit') . '/' . $episode->id }}" className="btn btn-xs btn-primary"><span className="fa fa-edit"></span> Edit</Link>
//                             <Link href="{{ URL::to('admin/episode/delete') . '/' . $episode->id }}" className="btn btn-xs btn-danger delete" onclick="return confirm('Are you sure?')" ><span className="fa fa-trash"></span> Delete</Link>
//                           </div>
//                         </td>
//                       </tr>

//                     </table>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Manageepisode;

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import "./Manageepisode.css";
import Select from "react-select";

export default function Manageepisode() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();
  const { seriesid } = useParams();

  // console.log(seriesid)
  // console.log(id)

  const [file, setFile] = useState("");
  const [episode_id, setEpisode_id] = useState("");

  // console.log(episode_id)

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [episode_description, setEpisode_description] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [disk, setDisk] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [active, setActive] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [featured, setFeatured] = useState("");

  const [banner, setBanner] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [duration, setDuration] = useState("");
  const [age_restrict, setAge_restrict] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");
  const [free_content_duration, setFree_content_duration] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [episode_ads, setEpisode_ads] = useState("");
  const [ads_position, setAds_position] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});

  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);

  const [message, setMessage] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    // {e => setActive(e.target.value)}
    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setFooter({ ...footer, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
    }
  };

  // async function audiosubmit() {
  //   // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

  //   const formData = new FormData();

  //   formData.append('file', file);

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + access_token,
  //     'Content-Type': 'application/json',
  //     "Accept": 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   };

  //   await axios.post('https://api.flicknexs.com/admin/uploadAudio', formData, { headers: headers })
  //     .then(response => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         var result = response.data.Audio_id;
  //         console.log('result', result);
  //         console.log('result', result);
  //         setAudio_id(result)
  //         // navigate('/admin/livestream')
  //         alert("Added successfully  !")
  //       }
  //       else {
  //         console.log('Error');
  //         alert('Enter Correct Details')
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Error');
  //       alert('Enter Correct Details')
  //     });
  // };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  // const uploadFile = ({ target: { files } }) => {
  //   let data = new FormData();
  //   data.append("file", files[0]);

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;

  //       let percent = Math.floor((loaded * 100) / total);

  //       if (percent < 100) {
  //         setUploadPercentage(percent);
  //       }
  //     },
  //     cancelToken: new CancelToken(
  //       (cancel) => (cancelFileUpload.current = cancel)
  //     ),
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`, data, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status === true) {
  //         var data = res.data;
  //         var episode_id = res.data.Episode_id;
  //         // console.log(data)
  //         // console.log(episode_id)
  //         setEpisode_id(episode_id);
  //         setUploadPercentage(100);
  //         alert("Added successfully  !");
  //         setTimeout(() => {
  //           setUploadPercentage(0);
  //         }, 1000);
  //       } else {
  //         setMessage("Some error Occured");
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);

  //       if (isCancel(err)) {
  //         alert(err.message);
  //       }
  //       setUploadPercentage(0);
  //     });
  // };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function Manageepisode() {
    // console.log('formData', formData)

    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    formData.append("id", episode_id);
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("episode_description", episode_description);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("ppv_status", ppv_status);
    formData.append("ppv_price", ppv_price);
    formData.append("mp4_url", mp4_url);
    formData.append("url", url);
    formData.append("path", path);
    formData.append("disk", disk);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    formData.append("active", active);
    formData.append("skip_recap", skip_recap);
    formData.append("skip_intro", skip_intro);
    formData.append("recap_start_time", recap_start_time);
    formData.append("recap_end_time", recap_end_time);
    formData.append("intro_start_time", intro_start_time);
    formData.append("intro_end_time", intro_end_time);
    formData.append("featured", featured);
    formData.append("banner", banner);
    formData.append("footer", footer);
    formData.append("duration", duration);
    formData.append("age_restrict", age_restrict);
    formData.append("views", views);
    formData.append("rating", rating);
    formData.append("status", status);
    formData.append("free_content_duration", free_content_duration);
    formData.append("search_tags", search_tags);
    formData.append("uploaded_by", uploaded_by);
    formData.append("ads_position", ads_position);
    formData.append("episode_ads", episode_ads);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/episode/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          console.log("result1", response);
          console.log("result2", result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");

          var episode_id = response.data.Episode_id;
          var resssss = response.data;
          console.log("resssss", resssss);
          console.log("episode_id", episode_id);

          const formDatas = new FormData();

          formDatas.append("episode_id", episode_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-episode`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response?.ok) {
          const data = await response?.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          // console.log(data.user_access, "user");
          const userAcces = data?.user_access?.map((userAcc) => ({
            value: userAcc?.role,
            label: userAcc?.name,
          }));

          const ppv_price = data?.InappPurchase?.map((userAcc) => ({
            value: userAcc?.id,
            label: userAcc?.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();

    const getcategoryname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_category)
        var result = response?.data?.audio_categories;
        setOrder(result);
        // console.log('result', result)
        // console.log('result', result);
        for (let i = 0; i < result?.length; i++) {
          getcategoryname?.push(result[i]?.name);
        }
        setCategory(getcategoryname);
      })
      .catch((error) => console.log(error));

    const getartistname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response?.data?.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result?.length; i++) {
          getartistname?.push(result[i]?.artist_name);
        }
        // setArtistss(getartistname);
      })
      .catch((error) => console.log(error));

    const getlanguagename = [];

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getlanguagename?.push(result[i]?.name);
        }
        setLanguage(getlanguagename);
      })
      .catch((error) => console.log(error));

    const getaudio_albums = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.audio_albums;
        setAudio_albums(result);
        // console.log('result', result)
        for (let i = 0; i < result?.length; i++) {
          getaudio_albums?.push(result[i]?.albumname);
        }
        setAudio_albums(getaudio_albums);
        // console.log('livestream_language', getaudio_albums);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/episode/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.episodes;
        setData(result);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/episode/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div>
            <label>Upload Audio </label>

            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-file"
                      onChange={uploadFile}
                      accept="audio/mp3"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
              <div className="mb-5">
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
                <br />
                <div className="text-center">
                  <button onClick={handleNext} className="btn btn-primary">
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div>
            <label>Upload Full Episode Here</label>

            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Trailers Can Be Uploaded From Video Edit Screen</p>
                  <p>
                    <input
                      type="file"
                      accept="video/mp4"
                      id="input-file-upload"
                      name="addvideo"
                      multiple
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </p>
                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
                <br />
              </label>
              <div className="mb-5">
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
                <br />
                <div className="text-center">
                  <button onClick={handleNext} className="btn btn-primary">
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            <div className="row d-flex mt-5">
              <div className="col-md-10 offset-md-2 mt-3"> </div>{" "}
            </div>

            <div className="panel-body mt-5">
              <div id="nestable" className="nested-list dd with-margins">
                <table
                  className="table table-bordered iq-card text-center"
                  id="categorytbl"
                >
                  <tr className="table-header r1">
                    <th>
                      <label>Episode</label>
                    </th>
                    <th>
                      <label>Episode Name</label>
                    </th>
                    <th>
                      <label>Slider</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th>
                      <label>Action</label>
                    </th>
                  </tr>

                  {data?.map((item) => (
                    <tr>
                      <td className="">
                        <img src={item?.image_url} width="200" alt="" />
                      </td>
                      <td className="edit_genre">
                        <p>{item?.title}</p>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            name="ppv_status"
                            value={
                              item?.ppv_status === 1 || item?.ppv_status === null
                                ? "0"
                                : item?.status === 1
                                ? "0"
                                : "1"
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="ppv_status"
                            value={
                              item?.ppv_status === 1 || item?.ppv_status === null
                                ? "0"
                                : item.status === 1
                                ? "0"
                                : "1"
                            }
                          ></span>
                        </label>
                      </td>
                      <td className="text-center">
                        {item?.draft === 0 || item?.draft === null ? (
                          <td className="bg-warning video_active">Draft</td>
                        ) : item?.draft === 1 &&
                          item?.status === 1 &&
                          item?.active === 1 ? (
                          <td className="bg-success video_active">Approved</td>
                        ) : (
                          <td className="bg-warning video_active">Draft</td>
                        )}
                      </td>
                      <td className="edit_genre">
                        <Link
                          to={"/admin/edit-episode/video-manage/" + item?.id}
                          className="edit ms-1"
                        >
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                          Edit Video
                        </Link>
                        <Link
                          to={"/admin/edit-episode/manage/" + item?.id}
                          className="edit ms-1"
                        >
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                          Edit
                        </Link>
                        <Link to="" className="delete ms-1">
                          <span onClick={() => deleteOperation(item?.id)}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <div id="episode_video_data" className="episode_video_data">
            <div>
              <div className="row mt-4">
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Title</label>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes title in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Title"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Slug</label>
                      </div>
                      <div className="panel-options">
                        <Link href="#" data-rel="collapse">
                          <i className="entypo-down-open"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="panel-body ">
                      <p className="p1">
                        Add the episodes slug in the textbox below:
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Episode Slug"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-3">
                                <div className="panel panel-primary" data-collapsed="0">
                                    <div className="panel-heading">
                                        <div className="panel-title">Created Date</div>
                                        <div className="panel-options">
                                            <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link>
                                        </div>
                                    </div>
                                    <div className="panel-body" >
                                        <p>Select Date/Time Below</p>
                                        <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="@if(!empty($episodes->created_at)){{ $episodes->created_at }}@endif" />
                                    </div>
                                </div>
                            </div> */}
              </div>

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <label>Episode Image Cover</label>
                      </div>
                    </div>
                    <div className="panel-body">
                      <p className="p1">
                        Select the episodes image (1080 X 1920px or 9:16 ratio):
                      </p>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="image"
                        id="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <label className="m-0">Episode Player Image</label>
                  <p className="p1">
                    Select the player image ( 1280 X 720px or 16:9 Ratio )
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <label className="m-0">Episode TV Image</label>
                  <p className="p1">
                    Select the player image ( 16:9 Ratio or 1920 X 1080 px)
                  </p>

                  <div className="panel-body">
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="tv_image"
                      id="tv_image"
                      onChange={(e) => setTv_image(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="row mt-4 mb-3">
                  <div className="col-sm-12">
                    <label className="m-0"> Episode Description </label>
                    <p className="p1">
                      {" "}
                      Add a description of the Episode below:{" "}
                    </p>
                    <div className="panel-body">
                      <textarea
                        className="form-control description_editor"
                        name="episode_description"
                        onChange={(e) => setEpisode_description(e.target.value)}
                        id="description_editor"
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Episode Ratings</label>
                        </div>
                      </div>
                      <div className="panel-body p-0">
                        <p className="p1">IMDb Ratings 10 out of 10</p>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <img alt="dfghj" />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Age Restrict :</h6>
                    </label>
                    <select
                      className="form-control"
                      id="age_restrict"
                      name="age_restrict"
                    >
                      <option selected disabled="">
                        Choose Age
                      </option>

                      <option value="{{ $age->id }}"></option>
                    </select>
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Search Tags :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="tag-input1"
                        name="searchtags"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="panel-body col-sm-6">
                    <label>
                      <h6>Ads Position:</h6>
                    </label>
                    <input
                      name="ads_position"
                      className="form-control"
                      onChange={(e) => setAds_position(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6 p-0">
                    <label>Episode Ads :</label>
                    <div className="panel-body">
                      <input
                        className="form-control"
                        type="text"
                        id="episode_ads"
                        name="episode_ads"
                        onChange={(e) => setEpisode_ads(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Intro Time <small>(Please Give In Seconds)</small>
                      </label>

                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_intro"
                          id="skip_intro"
                          onChange={(e) => setSkip_intro(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_start_time"
                          id="intro_start_time"
                          onChange={(e) => setIntro_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Intro End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_end_time"
                          id="intro_end_time"
                          onChange={(e) => setIntro_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Skip Recap Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_recap"
                          id="skip_recap"
                          onChange={(e) => setSkip_recap(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 ">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_start_time"
                          id="recap_start_time"
                          onChange={(e) => setRecap_start_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="panel panel-primary" data-collapsed="0">
                      <label className="m-0">
                        Recap End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_end_time"
                          id="recap_end_time"
                          onChange={(e) => setRecap_end_time(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-start mt-4">
                  <div className="col-sm-6">
                    <label className="m-0">Choose Ads Position</label>

                    <div className="">
                      {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                      <Select
                        options={adsPositions}
                        onChange={(e) => handleAdsPositionChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label className="m-0">Choose Advertisement</label>

                    <div className="">
                      <Select
                        options={secondSelectOptions}
                        onChange={(e) => setSecondSelectOptions(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Duration</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          Enter the episode duration in the following format
                          (Hours : Minutes : Seconds)
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          name="duration"
                          id="duration"
                          onChange={(e) => setDuration(e.target.value)}
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 ">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>User Access</label>
                        </div>
                        <div className="panel-options">
                          <Link href="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          Who is allowed to view this episode?
                        </p>
                        <select
                          id="access"
                          className="form-control"
                          name="access"
                          onChange={(e) => setAccess(e.target.value)}
                        >
                          <option value="guest">Guest (everyone)</option>
                          <option value="registered"></option>
                          <option value="subscriber"></option>
                          <option value="ppv">PPV Users (Pay per movie)</option>
                          <option value="ppv">PPV Users (Pay per movie)</option>
                        </select>
                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-sm-6">
                    <div className="panel panel-primary" data-collapsed="0">
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="fs-5">Status Settings</label>
                        </div>
                      </div>

                      <div className="panel-body">
                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Featured:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="featured"
                                onChange={handleInput}
                                defaultChecked={
                                  featured?.featured === 1 ? true : false
                                }
                                checked={featured?.featured === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="featured"
                                onChange={handleInput}
                                value={featured?.featured === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">
                              Is this episode Status:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  status?.status === 1 ? true : false
                                }
                                checked={status?.status === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={status?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="featured">Is this episode Views:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="views"
                                onChange={handleInput}
                                defaultChecked={
                                  views?.views === 1 ? true : false
                                }
                                checked={views?.views === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="views"
                                onChange={handleInput}
                                value={views?.views === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="active">Is this episode Active:</label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="active"
                                onChange={handleInput}
                                defaultChecked={
                                  active?.active === 1 ? true : false
                                }
                                checked={active?.active === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="active"
                                onChange={handleInput}
                                value={active?.active === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="banner">
                              Is this episode display in Banner:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="banner"
                                onChange={handleInput}
                                defaultChecked={
                                  banner?.banner === 1 ? true : false
                                }
                                checked={banner?.banner === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="banner"
                                onChange={handleInput}
                                value={banner?.banner === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="row col-lg-12">
                          <div className="col-lg-9">
                            <label for="footer">
                              Is this episode display in Footer:
                            </label>
                          </div>
                          <div className="col-lg-3 text-end">
                            <label className="switch">
                              <input
                                name="footer"
                                onChange={handleInput}
                                defaultChecked={
                                  footer?.footer === 1 ? true : false
                                }
                                checked={footer?.footer === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="footer"
                                onChange={handleInput}
                                value={footer?.footer === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <input
                    type="submit"
                    id="submit"
                    value="Create Episode"
                    className="btn btn-primary pull-right"
                    onClick={Manageepisode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add New Episode</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepContent>
                          <Typography>{step?.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index ==== 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index ==== 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Multiselect from 'multiselect-react-dropdown';
// import './Addnewaudio.css'
// import Select from 'react-select'
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';
// import {Step, Stepper, StepLabel} from '@material-ui/core'

// function Addnewaudio() {

// 	const [title, setTitle] = useState('');
// 	const [slug, setSlug] = useState('');
// 	const [ppv_status, setPpv_status] = useState('');
// 	const [ppv_price, setPpv_price] = useState('');
// 	const [type, setType] = useState('');
// 	const [access, setAccess] = useState('');
// 	const [album_id, setAlbum_id] = useState('');
// 	const [artists, setArtists] = useState('');
// 	const [rating, setRating] = useState('');
// 	const [details, setDetails] = useState('');
// 	const [description, setDescription] = useState('');
// 	const [active, setActive] = useState('');
// 	const [status, setStatus] = useState('');
// 	const [draft, setDraft] = useState('');
// 	const [featured, setFeatured] = useState('');
// 	const [banner, setBanner] = useState('');
// 	const [duration, setDuration] = useState('');
// 	const [views, setViews] = useState('');
// 	const [year, setYear] = useState('');
// 	const [age_restrict, setAge_restrict] = useState('');
// 	const [mp3_url, setMp3_url] = useState('');
// 	const [image, setImage] = useState('');
// 	const [player_image, setPlayer_image] = useState('');
// 	const [tv_image, setTv_image] = useState('');
// 	const [search_tags, setSearch_tags] = useState('');
// 	const [ios_ppv_price, setIos_ppv_price] = useState('');
// 	const [uploaded_by, setUploaded_by] = useState('');
// 	const [image_url, setImage_url] = useState('');
// 	const [Player_thumbnail, setPlayer_thumbnail] = useState('');
// 	const [TV_Thumbnail, setTV_Thumbnail] = useState('');

// 	const [order, setOrder] = useState({});
// 	const [data, setData] = useState([]);
// 	const [publish, setPublish] = useState([]);
// 	const [audio_albums, setAudio_albums] = useState([]);
// 	const [order1, setOrder1] = useState({});
// 	const [order2, setOrder2] = useState({});

// 	const [category, setCategory] = useState([]);
// 	const [language, setLanguage] = useState([]);

// 	const navigate = useNavigate();
// 	const access_token = localStorage.getItem('access_token');

// 	async function Audio() {
// 		// console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

// 		const formData = new FormData();

// 		formData.append('title', title);
// 		formData.append('ppv_status', ppv_status);
// 		formData.append('ppv_price', ppv_price);
// 		formData.append('slug', slug);
// 		formData.append('type', type);
// 		formData.append('access', access);
// 		formData.append('album_id', album_id);
// 		formData.append('artists', artists);
// 		formData.append('category', category);
// 		formData.append('language', language);
// 		formData.append('audio_albums', audio_albums);
// 		formData.append('rating', rating);
// 		formData.append('details', details);
// 		formData.append('description', description);
// 		formData.append('active', active);
// 		formData.append('status', status);
// 		formData.append('draft', draft);
// 		formData.append('featured', featured);
// 		formData.append('banner', banner);
// 		formData.append('duration', duration);
// 		formData.append('views', views);
// 		formData.append('year', year);
// 		formData.append('age_restrict', age_restrict);
// 		formData.append('mp3_url', mp3_url);
// 		formData.append('image', image);
// 		formData.append('player_image', player_image);
// 		formData.append('tv_image', tv_image);
// 		formData.append('search_tags', search_tags);
// 		formData.append('ios_ppv_price', ios_ppv_price);
// 		formData.append('uploaded_by', uploaded_by);
// 		formData.append('image_url', image_url);
// 		formData.append('Player_thumbnail', Player_thumbnail);
// 		formData.append('TV_Thumbnail', TV_Thumbnail);

// 		const headers = {
// 			'Content-Type': 'application/json',
// 			Authorization: 'Bearer ' + access_token,
// 			'Content-Type': 'application/json',
// 			"Accept": 'application/json',
// 			'Access-Control-Allow-Origin': '*',
// 		};

// 		await axios.post('https://api.flicknexs.com/admin/audio/store', formData, { headers: headers })
// 			.then(response => {
// 				// console.log('api' , response);
// 				if (response.data.status === true) {
// 					var result = response.data;
// 					// console.log('result', response);
// 					navigate('/admin/livestream')
// 					alert("Added successfully  !")
// 				}
// 				else {
// 					console.log('Error');
// 					alert('Enter Correct Details')
// 				}
// 			})
// 			.catch(err => {
// 				console.log('Error');
// 				alert('Enter Correct Details')
// 			});
// 	};

// 	const headers = {
// 		'Content-Type': 'application/json',
// 		Authorization: 'Bearer ' + access_token,
// 		'Content-Type': 'application/json',
// 		"Accept": 'application/json',
// 		'Access-Control-Allow-Origin': '*',
// 	};

// 	useEffect(async () => {
// 		const getcategoryname = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_category)
// 			var result = response.data.audio_categories
// 			setOrder(result)
// 			// console.log('result', result)
// 			// console.log('result', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getcategoryname.push(result[i].name);
// 			}
// 			setCategory(getcategoryname);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getartistname = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_artist)
// 			var result = response.data.artists
// 			setOrder1(result)
// 			// console.log('result', result)
// 			// console.log('artist', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getartistname.push(result[i].artist_name);
// 			}
// 			setArtists(getartistname);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getlanguagename = [];

// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_language)
// 			var result = response.data.languages
// 			setOrder2(result)
// 			// console.log('result', result)
// 			// console.log('livestream_language', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getlanguagename.push(result[i].name);
// 			}
// 			setLanguage(getlanguagename);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getaudio_albums = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_language)
// 			var result = response.data.audio_albums
// 			setAudio_albums(result)
// 			// console.log('result', result)
// 			for (let i = 0; i < result.length; i++) {
// 				getaudio_albums.push(result[i].albumname);
// 			}
// 			setAudio_albums(getaudio_albums);
// 			// console.log('livestream_language', getaudio_albums);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.user_access)
// 			var result = response.data.user_access
// 			setData(result)
// 			// console.log('result', result)
// 			// console.log('orders', result);
// 		})
// 			.catch(error =>
// 				console.log(error));

// 	}, [])

// 	// const [livesource, setLivesource] = useState('');

// 	// function live_stream_source() {
// 	// 	switch (livesource) {
// 	// 		case "0":
// 	// 			return <div></div>;
// 	// 		case "mp4":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setMp4_url(e.target.value)}
// 	// 					placeholder="setMp4_url"
// 	// 				/>
// 	// 			</div>;
// 	// 		case "Embed_url":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setEmbed_url(e.target.value)}
// 	// 					placeholder="setEmbed_url"
// 	// 				/>
// 	// 			</div>;
// 	// 		case "Mp3":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setM3u_url(e.target.value)}
// 	// 					placeholder="setM3u_url"
// 	// 				/>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }
// 	// const options = [
// 	// 	{ label: "Youtube", value: "youtube" },
// 	// 	{ label: "Facebook", value: "facebook" },
// 	// 	{ label: "Twitter", value: "twitter", },
// 	// ];

// 	// const [enablestreamvisible, setEnablestreamvisible] = useState('');

// 	// function enablestreams() {
// 	// 	switch (enable_restream) {
// 	// 		case "0":
// 	// 			return <div></div>;
// 	// 		case "1":
// 	// 			return <div>
// 	// 				<select className="form-select url_type mt-4">
// 	// 					<option value="Choose_Your_Stream" selected>Choose_Your_Stream</option>
// 	// 					<option value="youtube" >Youtube</option>
// 	// 					<option value="facebook">Facebook</option>
// 	// 					<option value="twitter">Twitter</option>
// 	// 				</select>

// 	// 				<Multiselect
// 	// 					options={options}
// 	// 					displayValue="label"
// 	// 					showCheckbox
// 	// 				/>

// 	// 			</div>;
// 	// 		case "0":
// 	// 			return <div>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }
// 	// function enablestreamvisibles() {
// 	// 	switch (enablestreamvisible) {
// 	// 		case "Choose_Your_Stream":
// 	// 			return <div></div>;
// 	// 		case "youtube":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6 ">
// 	// 						<div className="" >
// 	// 							<label className="mb-1"> YouTube Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setYoutube_restream_url(e.target.value)}
// 	// 								placeholder="youtube_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6">
// 	// 						<div className="" id="">
// 	// 							<label className="mb-1"> YouTube Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setYoutube_streamkey(e.target.value)}
// 	// 								placeholder="youtube_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;
// 	// 		case "facebook":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2">
// 	// 							<label className="mb-1"> FaceBook Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setFb_restream_url(e.target.value)}
// 	// 								placeholder="fb_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6" >
// 	// 						<div className="mt-2" id="">
// 	// 							<label className="mb-1"> FaceBook Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setFb_streamkey(e.target.value)}
// 	// 								placeholder="fb_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;
// 	// 		case "twitter":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2" >
// 	// 							<label className="mb-1"> Twitter Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setTwitter_restream_url(e.target.value)}
// 	// 								placeholder="twitter_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2" >
// 	// 							<label className="mb-1"> Twitter Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setTwitter_streamkey(e.target.value)}
// 	// 								placeholder="twitter_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }

// 	function showStep(step){
// switch(step){
// 	case 1:
// 	return <div>
// 		<label>Upload Audio </label>
// 	 <button>Next Step </button>
// 	</div>
// 	case 2:
// 	return <div>
// 	<label>Upload Audio </label>
//  <button>Next Step </button>
// </div>
// 	case 3:
// 	return <div>
// 	<label>Upload Audio </label>
//  <button>Next Step </button>
// </div>
// }
// 	}

// 	return (

// 		<>
// 			<div className='row m-0 p-0'>
// 				<div className='col-lg-2'>  </div>
// 				<div className='col-lg-10'>
//
// 					<section className="section container-fluid">
// 						<div className="pt-3">
// 							<div className="row">
// 								<div className="col-sm-12">
// 									<div className="iq-card">
// 										<div className="iq-card-header d-flex justify-content-between">
// 											<div className="iq-header-title">
// 												<h4 className="card-title">Add Audio</h4>
// 											</div>
// 										</div><hr />
// 										<div className="iq-card-body">
// 											<h5>Audio Info Details</h5>
// 											<div>
// 												<div>
// 												<label>Upload Audio </label>
// 												<div>
// 													<input type='file' />
// 												</div>
// 												</div>

// 												<div>

// 												<Stepper activeStep='1' orientaion='horizontal' >

// 													</Stepper>
// 													{showStep(2)}

// 												</div>

// 												<div className="row mt-3">
// 													<div className="col-md-6">
// 														<div className="panel panel-primary " data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Title</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Add the audio title in the textbox below:</p>
// 																<input type="text" className="form-control" name="title" id="title" placeholder="Audio Title"
// 																	onChange={e => setTitle(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-md-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Slug</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Add the Audio slug:</p>
// 																<input type="text" className="form-control" name="slug" id="slug" placeholder=""
// 																	onChange={e => setSlug(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>

// 													{/* <div className="col-md-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select Date/Time Below</p>
// 																<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
// 															</div>
// 														</div>
// 													</div> */}

// 												</div>
// 												<div className="row">
// 													<div className="col-md-6">
// 														<div className="panel panel-primary  p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Image Cover</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >

// 																<img src="" className="audio-img" width="200" />

// 																<p className="p1">Select the audio image ( 9:16 Ratio or 1080X1920px ):</p>
// 																<input type="file" multiple="true" className="form-control" name="image" id="image" />

// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-md-6">
// 														<div className="panel panel-primary  p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Player Image Cover</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >

// 																<img src="" className="audio-img" width="200" />

// 																<p className="p1">Select the audio image ( 16:9 Ratio or 1280X720px ):</p>
// 																<input type="file" multiple="true" className="form-control" name="player_image" id="player_image" />

// 															</div>
// 														</div>
// 													</div>

// 												</div>

// 												<div className="row container-fluid">
// 													<div className="panel panel-primary col-sm-6 p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 														<div className="panel-title"><label>Country</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 														<div className="panel-body" >
// 															<p className="p1">Block the Audio for Selected Country:</p>
// 															{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={artists}
// 																	onChange={e => setArtists(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 														</div>
// 													</div>

// 													<div className="col-sm-6 mt-3">
// 														<div className="">
// 															<label className="m-0">Search Tags</label>

// 															<div className="panel-body mt-3">

// 																<input type="text" className='form-control' name="search_tags"
// 																	onChange={e => setSearch_tags(e.target.value)} />

// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="panel panel-primary mt-3" data-collapsed="0"> <div className="panel-heading">
// 													<div className="panel-title"><label>Audio Details, Links, and Info</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 													<div className="panel-body" >
// 														<textarea className="form-control" name="details" id="details" onChange={e => setDetails(e.target.value)}></textarea>
// 													</div>
// 												</div>

// 												<div className="panel panel-primary mt-3" data-collapsed="0"> <div className="panel-heading">
// 													<div className="panel-title"><label>Short Description</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 													<div className="panel-body" >
// 														<p className="p1">Add a short description of the audio below:</p>
// 														<textarea className="form-control" name="description" id="description" onChange={e => setDescription(e.target.value)}></textarea>
// 													</div>
// 												</div>
// 												<div className="row mt-3">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Cast and Crew</label> </div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p>Add artists for the audio below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={artists}
// 																	onChange={e => setArtists(e.target.value)}
// 																	showCheckbox
// 																/> */}

// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Album</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Album Below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={audio_albums}
// 																	onChange={e => setAudio_albums(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 												<div className="row p-0 mt-3 align-items-center">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Category</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Category Below:</p>

// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={category}
// 																	onChange={e => setCategory(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Ratings</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1"> IMDB Ratings 10 out of 10</p>
// 																<select className="form-select" aria-label="Default select example" onChange={e => setRating(e.target.value)}>
// 																	<option value="0">0</option>
// 																	<option value="1">1</option>
// 																	<option value="2">2</option>
// 																	<option value="3">3</option>
// 																	<option value="4">4</option>
// 																	<option value="5">5</option>
// 																	<option value="6">6</option>
// 																	<option value="7">7</option>
// 																	<option value="8">8</option>
// 																	<option value="9">9</option>
// 																	<option value="10">10</option>
// 																</select>
// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="row mt-3">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Language</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Language Below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={language}
// 																	onChange={e => setLanguage(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6 ">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Year</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Audio Released Year</p>
// 																<input className="form-control" name="year" id="year" onChange={e => setYear(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="clear"></div>

// 												<div className="row mt-3 align-items-center">

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"><label> Duration</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<p className="p1">Enter the audio duration in the following format (Hours : Minutes : Seconds)</p>
// 																<input className="form-control" name="duration" id="duration" maxlength="12"
// 																	onChange={e => setDuration(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"> <label>User Access</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<p className="p1">Who is allowed to view this audio?</p>
// 																<select id="access" name="access" className="form-control">
// 																	{data.map((item) =>
// 																		<option value={item.value}>{item.name}</option>
// 																	)}
// 																</select>
// 																<div className="clear"></div>
// 															</div>
// 														</div>
// 													</div>

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"><label> Status Settings</label></div> <div className="panel-options"> <Link href="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="featured" >Is this audio Featured:</label>
// 																	<input type="checkbox" onChange={e => setFeatured(e.target.value)} />
// 																</div>
// 																<div className="clear"></div>
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="banner" >Is this Audio display in Banner:</label>
// 																	<input type="checkbox" onChange={e => setBanner(e.target.value)} />
// 																</div>
// 																<div className="clear"></div>
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="active" >Is this audio Active:</label>
// 																	<input type="checkbox" onChange={e => setActive(e.target.value)} />
// 																</div>
// 															</div>
// 														</div>
// 													</div>

// 													{/* <div className="row col-sm-12" id="ppv_price">
// 														<div className="col-sm-6">
// 															<label className="p2">PPV Price:</label>
// 															<input type="text" className="form-control" placeholder="PPV Price" name="ppv_price" id="price"
// 															onChange={e => setPpv_price(e.target.value)} />
// 														</div>

// 														<div className="col-sm-6">
// 															<label className="p2"> IOS PPV Price:</label>
// 															<select name="ios_ppv_price" className="form-control" id="ios_ppv_price" onChange={e => setIos_ppv_price(e.target.value)} >
// 																<option value="0" >Select IOS PPV Price: </option>
// 																<option value="123" >123</option>

// 															</select>
// 														</div>
// 													</div> */}

// 													<div className="row">
// 														<div className="row mt-3 align-items-center ml-3">

// 															<div className="col-sm-12" >

// 																<label className="p2" for="global_ppv">Is this video Is Global PPV:</label>
// 																<input type="checkbox" name="ppv_status" id="ppv_status"
// 																	onChange={e => setPpv_status(e.target.value)} />

// 																<div className="global_ppv_status">
// 																	<label className="p2" for="global_ppv">Is this video Is PPV:</label>
// 																	<input type="checkbox" name="ppv_status" onChange={e => setPpv_status(e.target.value)} id="ppv_status" />
// 																</div>

// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="mt-2 p-2 d-flex justify-content-end" >
// 													<input type="submit" value="Update" onClick={Audio} className="btn btn-primary" />
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</section>
// 				</div></div>
// 		</>
// 	);
// }

// export default Addnewaudio;

// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';
// import { Link } from 'react-router-dom';
// import React, { useState, useRef } from "react";
// import axios, { CancelToken, isCancel } from "axios";
// import { ProgressBar } from "react-bootstrap";

// const access_token = localStorage.getItem('access_token');

// function ManageGeoFencing() {

//   const [uploadPercentage, setUploadPercentage] = useState(0);
//   const cancelFileUpload = useRef(null);

//   const headers = {
//     'Content-Type': 'multipart/form-data',
//     Authorization: 'Bearer' + access_token,
//     'Content-Type': 'application/json',
//     "Accept": 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   }

//   const uploadFile = ({ target: { files } }) => {
//     let data = new FormData();
//     data.append("file", files[0]);

//     const options = {
//       onUploadProgress: progressEvent => {
//         const { loaded, total } = progressEvent;

//         let percent = Math.floor((loaded * 100) / total);

//         if (percent < 100) {
//           setUploadPercentage(percent);
//         }
//       },
//       cancelToken: new CancelToken(
//         cancel => (cancelFileUpload.current = cancel)
//       )
//     };

//     axios.post('https://api.flicknexs.com/admin/uploadAudio', data, { headers: headers })

//       // axios
//       //   .post(
//       //     "https://api.flicknexs.com/admin/uploadAudio",
//       //     data,
//       //     options, {enctype : "multipart/formdata"},
//       //     {headers:{
//       //       Authorization: 'Bearer' + access_token,
//       //       "Content-Type" : "application/json; charset=utf-8",
//       //       "credentials": "include",
//       //     }
//       //     }
//       //   )

//       .then(res => {
//         console.log(res);
//         var data = res.data;
//         var Audio_id = res.data.Audio_id;
//         console.log(data)
//         console.log(Audio_id)
//         setUploadPercentage(100);

//         setTimeout(() => {
//           setUploadPercentage(0);
//         }, 1000);
//       })
//       .catch(err => {
//         console.log(err);

//         if (isCancel(err)) {
//           alert(err.message);
//         }
//         setUploadPercentage(0);
//       });
//   };

//   const cancelUpload = () => {
//     if (cancelFileUpload.current)
//       cancelFileUpload.current("User has canceled the file upload.");
//   };

//   return (

//     <div className='row m-0 p-0'>
//       <div className='col-lg-2'>  </div>
//       <div className='col-lg-10'>
//
//         <section className="section container-fluid">

//           <div className="row justify-content-center bg-light">
//             <div className="col-md-6 text-center">
//               <h2>Upload your profile picture</h2>

//               <p>
//                 You can upload a sample file to see the progress bar
//                 with cancel file upload button
//               </p>
//               <p>
//                 <input
//                   type="file"
//                   className="form-control-file"
//                   onChange={uploadFile} accept="audio/mp3"
//                 />
//               </p>
//               {uploadPercentage > 0 && (
//                 <div className="row mt-3">
//                   <div className="col pt-1">
//                     <ProgressBar
//                       now={uploadPercentage}
//                       striped={true}
//                       label={`${uploadPercentage}%`}
//                     />
//                   </div>
//                   <div className="col-auto">
//                     <span
//                       className="text-primary cursor-pointer"
//                       onClick={() => cancelUpload()}
//                     >
//                       Cancel
//                     </span>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </section>
//       </div></div>

//   );
// }

// export default ManageGeoFencing;
