import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function AdsCategories() {
  const [adscategory, setAdscategory] = useState([]);

  const [name, setName] = useState("");

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-categories-list`,
        { headers: headers }
      )
      .then((response) => {
        var result = response?.data?.Advertisement_categories;
        setAdscategory(result);
        // console.log(response)
      })
      .catch((error) => console.log(error));
  }, []);

  async function submitadscategory() {
    const formData = new FormData();

    formData.append("name", name);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-categories-store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          alert("Added successfully  !");
          setInterval(10000);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-categories-delete/` +
          id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/Advertisement-categories-list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.Advertisement_categories.data);
        setPrevious(res?.data?.Advertisement_categories?.data);
        setAdscategory(res?.data?.Advertisement_categories?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/Advertisement-categories-list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.Advertisement_categories?.data);
        setPrevious(res?.data?.Advertisement_categories?.data);
        setAdscategory(res?.data.Advertisement_categories?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/Advertisement-categories-list?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.Advertisement_categories?.data);
        setNext(res?.data?.Advertisement_categories?.data);
        setAdscategory(res?.data?.Advertisement_categories?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Ads Category List</h4>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="btn btn-primary create_category"
                        >
                          Create Ads Category
                        </Link>
                      </div>
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div>
                                <label>Category Name </label>

                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Ads Category List"
                                  className="form-control rtmp_urls"
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="submit"
                                className="btn btn-primary ml-3 "
                                onClick={submitadscategory}
                              >
                                Add Category
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body table-responsive mt-3">
                      <div className="table-view">
                        <table className="table table-striped table-bordered table movie_table ">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>Category Name</th>
                              <th>Created at</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {adscategory?.map((item, key) => (
                              <tr key={item?.id} className="r1">
                                <td key={key}> {key + 1} </td>
                                <td>{item?.name}</td>
                                <td>{item?.created_at}</td>
                                <td>
                                  <Link
                                    to={
                                      "/admin/edit_ads_categories/" + item?.id
                                    }
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-3">
                                    <span
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClick}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{page}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClick}
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsCategories;
