import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./MobileSettings.css";

function EditSplashMobileSettings() {
  const [data, setData] = useState([]);

  const [splash_image, setSplash_image] = useState("");
  const [andriod_splash_image, setAndriod_splash_image] = useState("");
  const [splash_image_url, setSplash_image_url] = useState("");
  const [andriod_splash_image_url, setAndriod_splash_image_url] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        var resultsname = res?.data?.splash_Screen[0]?.splash_image;
        var resultsimage = res?.data?.splash_Screen[0]?.andriod_splash_image;
        var splash_image_url = res?.data?.splash_Screen[0]?.splash_image_url;
        var andriod_splash_image_url =
          res?.data?.splash_Screen[0]?.andriod_splash_image_url;

        setData(res?.data?.splash_Screen);
        setSplash_image(resultsname);
        setAndriod_splash_image(resultsimage);
        setSplash_image_url(splash_image_url);
        setAndriod_splash_image_url(andriod_splash_image_url);
        // console.log(resultsname)
        // console.log(resultsimage)
      });
  }, []);

  function editProduct() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    // console.log(splash_image, andriod_splash_image)
    const formData = new FormData();
    formData.append("splash_image", splash_image);
    formData.append("andriod_splash_image", andriod_splash_image);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response", response.data);
        alert("success");
        navigate("/admin/mobileapp");
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2">
              <h4>Edit Mobile Setting</h4>
              <hr />

              <div className="container-fluid row" id="rtmp_url">
                <h4 className="mt-3 ">Splash Image</h4>

                <div className="col-lg-7 ">
                  <div className="mt-2">
                    <img src={splash_image_url} width={100} />
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(e) => setSplash_image(e.target.files[0])}
                    />
                  </div>
                </div>

                <h4 className="mt-3 ">Andriod Splash Image</h4>

                <div className="col-lg-7 ">
                  <div className="mt-2">
                    <img src={andriod_splash_image_url} width={100} />
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(e) =>
                        setAndriod_splash_image(e.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="text-end mt-4">
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                    onClick={editProduct}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditSplashMobileSettings;
