// import React from "react";
// import { Link } from "react-router-dom";
// import "./CacheManagement.css";

// function CacheManagement() {
//   return (
//     <div className=" m-0 p-0">
//       <div className="">
//         <section className="section  container-fluid pt-3">
//           <div id="content-page" className="content-page">
//             <div className="container-fluid p-0">
//               <div id="webhomesetting">
//                 <div className="iq-card">
//                   <div className="iq-card-header d-flex justify-content-between">
//                     <div className="iq-header-title">
//                       <h4 className="card-title">Cache Management</h4>
//                     </div>
//                   </div>
//                   <hr />
//                   <div className="panel panel-primary mt-3" data-collapsed="0">
//                     <div className="panel-body">
//                       <div className="row align-items-center p-2">
//                         <div className="col-sm-3">
//                           <button
//                             class="clear-btn"
//                             role="button"
//                             id="clear_cache"
//                           >
//                             {" "}
//                             Clear Cache{" "}
//                           </button>
//                         </div>
//                         <div className="col-sm-4">
//                           <button
//                             class="clear-btn"
//                             role="button"
//                             id="clear_cache"
//                           >
//                             {" "}
//                             Clear Views Cache{" "}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

// export default CacheManagement;

import React, { useState, useEffect } from "react";
import axios from "axios";

const CacheManagement = () => {
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, {
        headers: headers,
      })
      .then((response) => {
        setData(response?.data?.Site_theme_setting[0]?.dark_mode_logo);
        setData2(response?.data?.Site_theme_setting[0]?.light_mode_logo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        setData3(response?.data?.settings[0]?.logo);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : data ? (
        <div>
          <p>
            <img src={data} />
          </p>
        </div>
      ) : data2 ? (
        <p>
          <img src={data2} />
        </p>
      ) : data3 ? (
        <p>
          <img src={data3} />
        </p>
      ) : (
        <p>No data available. {data2}</p>
      )}
    </div>
  );
};

export default CacheManagement;
