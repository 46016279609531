import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";

function LandingPages() {
  const [landing, setLanding] = useState([]);

  const access_token = localStorage.getItem("access_token");

  // console.log(landing);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios

      .get(`${process.env.REACT_APP_Baseurl}/admin/landing-page/index`, {
        headers: headers,
      })

      .then((getData) => {
        setLanding(getData?.data?.landing_pages);
      });
  };
  console.log(landing, "111");

  const handleDelete = async (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    await axios.delete(
      `https://api.flicknexs.com/admin/landing-page/delete/${id}`,
      { headers: headers }
    );
    getData();

    // window.location.reload(true);
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <h4>
                            <i className="entypo-archive"></i> Landing Page
                          </h4>
                        </div>
                        <div className="col-md-9" align="right">
                          <Link to="/admin/add-landing-page/index">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>
                      <table
                        className="table table-bordered iq-card text-center mt-5"
                        id="categorytbl"
                      >
                        <tr className="table-header r1">
                          <th>
                            <label>S.No</label>
                          </th>

                          <th>
                            <label>NAME</label>
                          </th>
                          <th>
                            <label>SET AS FRONT PAGE</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>

                        {landing?.map((item, key) => (
                          <tr>
                            <td className="table-content" key={item?.id}>
                              {key + 1}
                            </td>

                            <td className="table-content">
                              <p>{item?.title}</p>
                            </td>
                            <td>
                              <label class="switch">
                                <input type="checkbox" />
                                <span class="slider round"></span>
                              </label>
                            </td>
                            <td className="table-content">
                              <Link>
                                <AiOutlineEye />
                              </Link>
                              <Link to={"/admin/edit-landing-page/" + item?.id}>
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                  onClick={() => handleDelete(item?.id)}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LandingPages;
