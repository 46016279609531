import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddManageSeries.css";

function AddManageSeries() {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [parent_id, setParent_id] = useState([]);
  const [in_home, setIn_home] = useState("");
  const [footer, setFooter] = useState("");
  const [banner, setBanner] = useState("");
  const [banner_image, setBanner_image] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");

  // const [series_genre_id, setSeries_genre_id] = useState('');

  const [data, setData] = useState([]);

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function series_genre() {
    // console.log('append data')

    const formData = new FormData();

    formData.append("parent_id", parent_id);
    formData.append("name", name);
    formData.append("slug", slug);
    formData.append("in_home", in_home);
    formData.append("footer", footer);
    formData.append("in_menu", in_menu);
    formData.append("uploaded_by", uploaded_by);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("api", response);
        if (response.data.status === true) {
          var result = response.data;
          console.log("result", response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
          var SeriesGenre_id = response.data.SeriesGenre_id;
          var resssss = response.data;
          // setSeries_genre_id(series_id)
          console.log("resssss", SeriesGenre_id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          // formDatas.append('series_id', series_genre_id);
          formDatas.append("image", image);
          formData.append("banner_image", banner);
          formData.append("series_genre_id", SeriesGenre_id);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series-category`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              console.log("api", response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              } else {
                console.log("Error");
                alert("Enter Correct Details");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const handleInput = (e) => {

  // 	setPpv_status({ ...ppv_status, [e.target.name]: e.target.checked });

  // 	if (e.target.checked === true) {
  // 		setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });

  // 	} else {
  // 		setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
  // 	}
  // }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series-category/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.SeriesGenre?.data;
        // var resultss = response.data.SeriesGenre[0].name
        setData(result);
        // console.log('result', result)
        // console.log('SeriesGenre', result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="AddManageSeries container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New Series Genre
                </h5>
              </div>
              <div className="modal-body">
                {/* <input type="hidden" name="parent_id" onChange={e => setParent_id(e.target.value)} /> */}

                <div className="form-group ">
                  <label>Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Slug:</label>
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                    placeholder="Enter Slug"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>
                {/* <input type="hidden" name="in_home" onChange={e => setIn_home(e.target.value)} />
								<input type="hidden" name="footer" onChange={e => setFooter(e.target.value)} /> */}

                <div className="mt-3">
                  <label>Display In Menu :</label>
                  <select
                    id="in_menu"
                    name="in_menu"
                    onChange={(e) => setIn_menu(e.target.value)}
                    className="form-control"
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div className="mt-3">
                  <label>Display In Home :</label>
                  <select
                    id="in_home"
                    name="in_home"
                    onChange={(e) => setIn_home(e.target.value)}
                    className="form-control"
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div className="mt-3">
                  <label>Display In Footer :</label>
                  <select
                    id="footer"
                    name="footer"
                    onChange={(e) => setFooter(e.target.value)}
                    className="form-control"
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label>Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    id="image"
                  />
                </div>

                <div className=" mt-3">
                  <label>Category:</label>
                  <select
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={(e) => setParent_id(e.target.value)}
                  >
                    <option value="0">Select</option>
                    {data?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={series_genre}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddManageSeries;
