import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import "./Addnewaudio.css";
import Select from "react-select";

export default function SeeLogActivity() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [file, setFile] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [ppv_status, setPpv_status] = useState("1");
  const [ppv_price, setPpv_price] = useState("");
  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [album_id, setAlbum_id] = useState("");
  const [artists, setArtists] = useState("");
  const [artistss, setArtistss] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [duration, setDuration] = useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [age_restrict, setAge_restrict] = useState("");
  const [mp3_url, setMp3_url] = useState("");
  const [mp3_live_url, setMp3_live_url] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [image_url, setImage_url] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});

  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);

  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setDraft({ ...draft, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setStatus({ ...status, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setDraft({ ...draft, [e.target.name]: 0 });
    }
  };

  // async function audiosubmit() {
  //   // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

  //   const formData = new FormData();

  //   formData.append('file', file);

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + access_token,
  //     'Content-Type': 'application/json',
  //     "Accept": 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   };

  //   await axios.post('https://api.flicknexs.com/admin/uploadAudio', formData, { headers: headers })
  //     .then(response => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         var result = response.data.Audio_id;
  //         console.log('result', result);
  //         console.log('result', result);
  //         setAudio_id(result)
  //         // navigate('/admin/livestream')
  //         alert("Added successfully  !")
  //       }
  //       else {
  //         console.log('Error');
  //         alert('Enter Correct Details')
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Error');
  //       alert('Enter Correct Details')
  //     });
  // };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };
  // const uploadFile = ({ target: { files } }) => {
  //   let data = new FormData();
  //   data.append("file", files[0]);

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;

  //       let percent = Math.floor((loaded * 100) / total);

  //       if (percent < 100) {
  //         setUploadPercentage(percent);
  //       }
  //     },
  //     cancelToken: new CancelToken(
  //       (cancel) => (cancelFileUpload.current = cancel)
  //     ),
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_Baseurl}/admin/uploadAudio`, data, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status === true) {
  //         var data = res.data;
  //         var Audio_id = res.data.Audio_id;
  //         // console.log(data)
  //         // console.log(Audio_id)
  //         setAudio_id(Audio_id);
  //         setUploadPercentage(100);

  //         setTimeout(() => {
  //           setUploadPercentage(0);
  //         }, 1000);
  //       } else {
  //         setMessage("Some error Occured");
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);

  //       if (isCancel(err)) {
  //         alert(err.message);
  //       }
  //       setUploadPercentage(0);
  //     });
  // };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const uploadFilefile = ({ target: { mp3_url } }) => {
    let data = new FormData();
    data.append("mp3_url", mp3_url);

    console.log("mp3_url", mp3_url);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentagefile(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/Audio_URL`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          var data = res.data;
          var Audio_id = res.data.Audio_id;
          // console.log(data)
          // console.log(Audio_id)
          setAudio_id(Audio_id);
          setUploadPercentagefile(100);

          setTimeout(() => {
            setUploadPercentagefile(0);
          }, 1000);
        } else {
          setMessage("Some error Occured");
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);

        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentagefile(0);
      });
  };

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function audiofilesubmit() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("mp3_url", mp3_url);
    console.log("append data", mp3_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/Audio_URL`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          var result = response.data.Audio_id;
          console.log("result", result);
          console.log("result", result);
          setAudio_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function audiolivefilesubmit() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("mp3_live_url", mp3_live_url);
    console.log("mp3_live_url data", mp3_live_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/LiveAudioURL`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          var result = response.data.Audio_id;
          console.log("result", result);
          console.log("result", result);
          setAudio_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function Audio() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("title", title);
    formData.append("audio_id", audio_id);
    formData.append("ppv_status", ppv_status);
    formData.append("ppv_price", ppv_price);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("album_id", album_id);
    formData.append("artists", artists);
    formData.append("category", category);
    formData.append("language", language);
    formData.append("audio_albums", audio_albums);
    formData.append("rating", rating);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", active);
    formData.append("status", status);
    formData.append("draft", draft);
    formData.append("featured", featured);
    formData.append("banner", banner);
    formData.append("duration", duration);
    formData.append("views", views);
    formData.append("year", year);
    formData.append("age_restrict", age_restrict);
    formData.append("mp3_url", mp3_url);
    // formData.append('image', image);
    // formData.append('player_image', player_image);
    // formData.append('tv_image', tv_image);
    formData.append("search_tags", search_tags);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("uploaded_by", uploaded_by);
    // formData.append('image_url', image_url);
    // formData.append('Player_thumbnail', Player_thumbnail);
    // formData.append('TV_Thumbnail', TV_Thumbnail);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/audio/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/livestream')
          alert("Added successfully  !");

          var audio_id = response.data.Audio_id;
          var resssss = response.data;
          console.log("resssss", resssss);
          console.log("audio_id", audio_id);

          const formDatas = new FormData();

          formDatas.append("audio_id", audio_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audio`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getcategoryname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_category)
        var result = response?.data?.audio_categories;
        setOrder(result);
        // console.log('result', result)
        // console.log('result', result);
        for (let i = 0; i < result?.length; i++) {
          getcategoryname?.push(result[i]?.name);
        }
        setCategory(getcategoryname);
      })
      .catch((error) => console.log(error));

    const getartistname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response?.data?.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result?.length; i++) {
          getartistname?.push(result[i]?.artist_name);
        }
        setArtistss(getartistname);
      })
      .catch((error) => console.log(error));

    const getlanguagename = [];

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getlanguagename?.push(result[i]?.name);
        }
        setLanguage(getlanguagename);
      })
      .catch((error) => console.log(error));

    const getaudio_albums = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response?.data?.audio_albums;
        setAudio_albums(result);
        // console.log('result', result)
        for (let i = 0; i < result?.length; i++) {
          getaudio_albums?.push(result[i]?.albumname);
        }
        setAudio_albums(getaudio_albums);
        // console.log('livestream_language', getaudio_albums);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div>
            <label>Upload Audio </label>

            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  {/* <p>

                    
                    <input
                      type="file"
                      className="form-control"
                      onChange={uploadFile}
                      accept="audio/mp3"
                      id="input-file-upload"
                    />
                  </p> */}

                  <p>
                    <input
                      type="file"
                      accept="audio/mp3"
                      id="input-file-upload"
                      name="addvideo"
                      multiple
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </p>
                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
                <br />
              </label>
              <div className="mb-5 mt-3">
                <div className="text-center">
                  <button onClick={handleNext} className="btn btn-primary">
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "2":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setMp3_url(e.target.value)}
                    placeholder="Mp3 File URL"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Live Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Live Mp3 File URL"
                    onChange={(e) => setMp3_live_url(e.target.value)}
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiolivefilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
	<input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
	<input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

            <div className="row d-flex mt-5">
              <div className="col-md-10 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="1"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="flexRadioDefault1"
                >
                  Audio Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="2"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="flexRadioDefault2"
                >
                  Audio File
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="3"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="flexRadioDefault3"
                >
                  Live Audio File
                </label>
              </div>
            </div>

            {/* <select className="form-select url_type mt-3" onClick={(event) => {setEnable_restream(event.target.value); }}>
  <option defaultValue="0">Choose Enable</option>
  <option value="1" >Yes</option>
  <option value="0">No</option>
</select> */}
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <h5>Audio Info Details</h5>
          <div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="panel panel-primary " data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Title</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Add the audio title in the textbox below:
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <input
                      type="text"
                      hidden
                      className="form-control"
                      value={file}
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      onChange={(e) => setFile(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Slug</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">Add the Audio slug:</p>
                    <input
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      placeholder=""
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
            <div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
              <div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
              <div className="panel-body" >
                <p className="p1">Select Date/Time Below</p>
                <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
              </div>
            </div>
          </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Image Cover</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <img src="" className="audio-img" width="200" />

                    <p className="p1">
                      Select the audio image ( 9:16 Ratio or 1080X1920px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="image"
                      id="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Player Image Cover</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <img src="" className="audio-img" width="200" />

                    <p className="p1">
                      Select the audio image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div
                className="panel panel-primary col-sm-6 mt-3"
                data-collapsed="0"
              >
                {" "}
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>Country</label>
                  </div>{" "}
                  
                </div>
                <div className="panel-body">
                  <p className="p1">Block the Audio for Selected Country:</p>
                  {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="">
                  <label className="m-0">Search Tags</label>

                  <div className="panel-body mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="search_tags"
                      onChange={(e) => setSearch_tags(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Audio Details, Links, and Info</label>
                </div>{" "}
                
              </div>
              <div className="panel-body">
                <textarea
                  className="form-control"
                  name="details"
                  id="details"
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Short Description</label>
                </div>{" "}
                
              </div>
              <div className="panel-body">
                <p className="p1">
                  Add a short description of the audio below:
                </p>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Cast and Crew</label>{" "}
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p>Add artists for the audio below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}
                  </div>
                  {/* <input  name="album_id" onChange={e => setAlbum_id(e.target.value)} />  */}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Album</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Album Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={audio_albums}
                  onChange={e => setAudio_albums(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                    <input
                      name="album_id"
                      className="form-control"
                      onChange={(e) => setAlbum_id(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-0 mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Category</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Category Below:</p>

                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={category}
                  onChange={e => setCategory(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Ratings</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1"> IMDB Ratings 10 out of 10</p>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setRating(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Language</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Language Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={language}
                  onChange={e => setLanguage(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Year</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">Audio Released Year</p>
                    <input
                      className="form-control"
                      name="year"
                      id="year"
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <label className="p2" htmlFor="global_ppv">
                      Age Restrict:
                    </label>
                  </div>
                  <div className="panel-body">
                    <input
                      type="text"
                      className="form-control"
                      name="age_restrict"
                      id="age_restrict"
                      onChange={(e) => setAge_restrict(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Artists</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={audio_albums}
                  onChange={e => setAudio_albums(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                    <input
                      name="artists"
                      className="form-control"
                      onChange={(e) => setArtists(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      <label> Duration</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Enter the audio duration in the following format (Hours :
                      Minutes : Seconds)
                    </p>
                    <input
                      className="form-control"
                      name="duration"
                      id="duration"
                      maxlength="12"
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      {" "}
                      <label>User Access</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <p className="p1">Who is allowed to view this audio?</p>
                    <select id="access" name="access" className="form-control">
                      {data?.map((item) => (
                        <option value={item?.value}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 col-6">
                <div className="row col-lg-12">
                  <div className="col-lg-10 col-10 global_ppv_status">
                    <div>
                      <label className="p2" htmlFor="global_ppv">
                        Is this video Is Global PPV:
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 text-end col-2">
                    <label className="switch">
                      <input
                        name="ppv_status"
                        onChange={handleInput}
                        defaultChecked={
                          ppv_status?.ppv_status === 1 ? true : false
                        }
                        checked={ppv_status?.ppv_status === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="ppv_status"
                        onChange={handleInput}
                        value={ppv_status?.ppv_status === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      <label> Status Settings</label>
                    </div>{" "}
                    
                  </div>
                  <div className="panel-body">
                    <div className="row col-lg-12">
                      <div className="col-lg-10 col-10">
                        <label className="p2" htmlFor="featured">
                          Is this audio Featured:
                        </label>
                      </div>
                      <div className="col-lg-2 col-2 text-end">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            defaultChecked={
                              featured?.featured === 1 ? true : false
                            }
                            checked={featured?.featured === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row col-lg-12">
                      <div className="col-lg-10 col-10">
                        <label className="p2" htmlFor="banner">
                          Is this Audio display in Banner:
                        </label>
                      </div>
                      <div className="col-lg-2 col-2 text-end">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            defaultChecked={banner?.banner === 1 ? true : false}
                            checked={banner?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row col-lg-12">
                      <div className="col-lg-10 col-10">
                        <label className="p2" htmlFor="active">
                          Is this audio Active:
                        </label>
                      </div>
                      <div className="col-lg-2 col-2 text-end">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active === 1 ? true : false}
                            checked={active?.active === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="row col-lg-12">
                      <div className="col-lg-10 col-10">
                        <label className="p2" htmlFor="active">
                          Is this audio Status:
                        </label>
                      </div>
                      <div className="col-lg-2 col-2 text-end">
                        <label className="switch">
                          <input
                            name="status"
                            onChange={handleInput}
                            defaultChecked={status?.status === 1 ? true : false}
                            checked={status?.status === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="status"
                            onChange={handleInput}
                            value={status?.status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row mt-3 align-items-center ml-3">
                <div className="col-sm-12">
                  <label className="switch" hidden>
                    <input
                      name="draft"
                      onChange={handleInput}
                      defaultChecked={draft?.draft === 1 ? true : false}
                      checked={draft?.draft === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="draft"
                      onChange={handleInput}
                      value={draft?.draft === 1 ? "1" : "0"}
                    ></span>
                  </label>

                  <label className="switch" hidden>
                    <input
                      name="views"
                      onChange={handleInput}
                      defaultChecked={views?.views === 1 ? true : false}
                      checked={views?.views === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="views"
                      onChange={handleInput}
                      value={views?.views === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-2 p-2 d-flex justify-content-end">
              <input
                type="submit"
                value="Update"
                onClick={Audio}
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add Audio</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepContent>
                          <Typography>{step?.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index ==== 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index ==== 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Multiselect from 'multiselect-react-dropdown';
// import './Addnewaudio.css'
// import Select from 'react-select'
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';
// import {Step, Stepper, StepLabel} from '@material-ui/core'

// function Addnewaudio() {

// 	const [title, setTitle] = useState('');
// 	const [slug, setSlug] = useState('');
// 	const [ppv_status, setPpv_status] = useState('');
// 	const [ppv_price, setPpv_price] = useState('');
// 	const [type, setType] = useState('');
// 	const [access, setAccess] = useState('');
// 	const [album_id, setAlbum_id] = useState('');
// 	const [artists, setArtists] = useState('');
// 	const [rating, setRating] = useState('');
// 	const [details, setDetails] = useState('');
// 	const [description, setDescription] = useState('');
// 	const [active, setActive] = useState('');
// 	const [status, setStatus] = useState('');
// 	const [draft, setDraft] = useState('');
// 	const [featured, setFeatured] = useState('');
// 	const [banner, setBanner] = useState('');
// 	const [duration, setDuration] = useState('');
// 	const [views, setViews] = useState('');
// 	const [year, setYear] = useState('');
// 	const [age_restrict, setAge_restrict] = useState('');
// 	const [mp3_url, setMp3_url] = useState('');
// 	const [image, setImage] = useState('');
// 	const [player_image, setPlayer_image] = useState('');
// 	const [tv_image, setTv_image] = useState('');
// 	const [search_tags, setSearch_tags] = useState('');
// 	const [ios_ppv_price, setIos_ppv_price] = useState('');
// 	const [uploaded_by, setUploaded_by] = useState('');
// 	const [image_url, setImage_url] = useState('');
// 	const [Player_thumbnail, setPlayer_thumbnail] = useState('');
// 	const [TV_Thumbnail, setTV_Thumbnail] = useState('');

// 	const [order, setOrder] = useState({});
// 	const [data, setData] = useState([]);
// 	const [publish, setPublish] = useState([]);
// 	const [audio_albums, setAudio_albums] = useState([]);
// 	const [order1, setOrder1] = useState({});
// 	const [order2, setOrder2] = useState({});

// 	const [category, setCategory] = useState([]);
// 	const [language, setLanguage] = useState([]);

// 	const navigate = useNavigate();
// 	const access_token = localStorage.getItem('access_token');

// 	async function Audio() {
// 		// console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

// 		const formData = new FormData();

// 		formData.append('title', title);
// 		formData.append('ppv_status', ppv_status);
// 		formData.append('ppv_price', ppv_price);
// 		formData.append('slug', slug);
// 		formData.append('type', type);
// 		formData.append('access', access);
// 		formData.append('album_id', album_id);
// 		formData.append('artists', artists);
// 		formData.append('category', category);
// 		formData.append('language', language);
// 		formData.append('audio_albums', audio_albums);
// 		formData.append('rating', rating);
// 		formData.append('details', details);
// 		formData.append('description', description);
// 		formData.append('active', active);
// 		formData.append('status', status);
// 		formData.append('draft', draft);
// 		formData.append('featured', featured);
// 		formData.append('banner', banner);
// 		formData.append('duration', duration);
// 		formData.append('views', views);
// 		formData.append('year', year);
// 		formData.append('age_restrict', age_restrict);
// 		formData.append('mp3_url', mp3_url);
// 		formData.append('image', image);
// 		formData.append('player_image', player_image);
// 		formData.append('tv_image', tv_image);
// 		formData.append('search_tags', search_tags);
// 		formData.append('ios_ppv_price', ios_ppv_price);
// 		formData.append('uploaded_by', uploaded_by);
// 		formData.append('image_url', image_url);
// 		formData.append('Player_thumbnail', Player_thumbnail);
// 		formData.append('TV_Thumbnail', TV_Thumbnail);

// 		const headers = {
// 			'Content-Type': 'application/json',
// 			Authorization: 'Bearer ' + access_token,
// 			'Content-Type': 'application/json',
// 			"Accept": 'application/json',
// 			'Access-Control-Allow-Origin': '*',
// 		};

// 		await axios.post('https://api.flicknexs.com/admin/audio/store', formData, { headers: headers })
// 			.then(response => {
// 				// console.log('api' , response);
// 				if (response.data.status === true) {
// 					var result = response.data;
// 					// console.log('result', response);
// 					navigate('/admin/livestream')
// 					alert("Added successfully  !")
// 				}
// 				else {
// 					console.log('Error');
// 					alert('Enter Correct Details')
// 				}
// 			})
// 			.catch(err => {
// 				console.log('Error');
// 				alert('Enter Correct Details')
// 			});
// 	};

// 	const headers = {
// 		'Content-Type': 'application/json',
// 		Authorization: 'Bearer ' + access_token,
// 		'Content-Type': 'application/json',
// 		"Accept": 'application/json',
// 		'Access-Control-Allow-Origin': '*',
// 	};

// 	useEffect(async () => {
// 		const getcategoryname = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_category)
// 			var result = response.data.audio_categories
// 			setOrder(result)
// 			// console.log('result', result)
// 			// console.log('result', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getcategoryname.push(result[i].name);
// 			}
// 			setCategory(getcategoryname);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getartistname = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_artist)
// 			var result = response.data.artists
// 			setOrder1(result)
// 			// console.log('result', result)
// 			// console.log('artist', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getartistname.push(result[i].artist_name);
// 			}
// 			setArtists(getartistname);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getlanguagename = [];

// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_language)
// 			var result = response.data.languages
// 			setOrder2(result)
// 			// console.log('result', result)
// 			// console.log('livestream_language', result);
// 			for (let i = 0; i < result.length; i++) {
// 				getlanguagename.push(result[i].name);
// 			}
// 			setLanguage(getlanguagename);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		const getaudio_albums = [];
// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.livestream_language)
// 			var result = response.data.audio_albums
// 			setAudio_albums(result)
// 			// console.log('result', result)
// 			for (let i = 0; i < result.length; i++) {
// 				getaudio_albums.push(result[i].albumname);
// 			}
// 			setAudio_albums(getaudio_albums);
// 			// console.log('livestream_language', getaudio_albums);

// 		})
// 			.catch(error =>
// 				console.log(error));

// 		await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
// 		).then(response => {
// 			// console.log('api checks', response.data.user_access)
// 			var result = response.data.user_access
// 			setData(result)
// 			// console.log('result', result)
// 			// console.log('orders', result);
// 		})
// 			.catch(error =>
// 				console.log(error));

// 	}, [])

// 	// const [livesource, setLivesource] = useState('');

// 	// function live_stream_source() {
// 	// 	switch (livesource) {
// 	// 		case "0":
// 	// 			return <div></div>;
// 	// 		case "mp4":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setMp4_url(e.target.value)}
// 	// 					placeholder="setMp4_url"
// 	// 				/>
// 	// 			</div>;
// 	// 		case "Embed_url":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setEmbed_url(e.target.value)}
// 	// 					placeholder="setEmbed_url"
// 	// 				/>
// 	// 			</div>;
// 	// 		case "Mp3":
// 	// 			return <div>
// 	// 				<input
// 	// 					type="text"
// 	// 					className="form-control mt-4"
// 	// 					onChange={e => setM3u_url(e.target.value)}
// 	// 					placeholder="setM3u_url"
// 	// 				/>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }
// 	// const options = [
// 	// 	{ label: "Youtube", value: "youtube" },
// 	// 	{ label: "Facebook", value: "facebook" },
// 	// 	{ label: "Twitter", value: "twitter", },
// 	// ];

// 	// const [enablestreamvisible, setEnablestreamvisible] = useState('');

// 	// function enablestreams() {
// 	// 	switch (enable_restream) {
// 	// 		case "0":
// 	// 			return <div></div>;
// 	// 		case "1":
// 	// 			return <div>
// 	// 				<select className="form-select url_type mt-4">
// 	// 					<option value="Choose_Your_Stream" selected>Choose_Your_Stream</option>
// 	// 					<option value="youtube" >Youtube</option>
// 	// 					<option value="facebook">Facebook</option>
// 	// 					<option value="twitter">Twitter</option>
// 	// 				</select>

// 	// 				<Multiselect
// 	// 					options={options}
// 	// 					displayValue="label"
// 	// 					showCheckbox
// 	// 				/>

// 	// 			</div>;
// 	// 		case "0":
// 	// 			return <div>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }
// 	// function enablestreamvisibles() {
// 	// 	switch (enablestreamvisible) {
// 	// 		case "Choose_Your_Stream":
// 	// 			return <div></div>;
// 	// 		case "youtube":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6 ">
// 	// 						<div className="" >
// 	// 							<label className="mb-1"> YouTube Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setYoutube_restream_url(e.target.value)}
// 	// 								placeholder="youtube_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6">
// 	// 						<div className="" id="">
// 	// 							<label className="mb-1"> YouTube Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setYoutube_streamkey(e.target.value)}
// 	// 								placeholder="youtube_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;
// 	// 		case "facebook":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2">
// 	// 							<label className="mb-1"> FaceBook Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setFb_restream_url(e.target.value)}
// 	// 								placeholder="fb_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6" >
// 	// 						<div className="mt-2" id="">
// 	// 							<label className="mb-1"> FaceBook Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setFb_streamkey(e.target.value)}
// 	// 								placeholder="fb_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;
// 	// 		case "twitter":
// 	// 			return <div>
// 	// 				<div className="row mt-3 text-start">
// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2" >
// 	// 							<label className="mb-1"> Twitter Stream (RTMP URL) </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setTwitter_restream_url(e.target.value)}
// 	// 								placeholder="twitter_restream_url"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>

// 	// 					<div className="col-sm-6">
// 	// 						<div className="mt-2" >
// 	// 							<label className="mb-1"> Twitter Stream Key </label>
// 	// 							<input
// 	// 								type="text"
// 	// 								className="form-control"
// 	// 								onChange={e => setTwitter_streamkey(e.target.value)}
// 	// 								placeholder="twitter_streamkey"
// 	// 							/>
// 	// 						</div>
// 	// 					</div>
// 	// 				</div>
// 	// 			</div>;

// 	// 		default:
// 	// 			return null;
// 	// 	}
// 	// }

// 	function showStep(step){
// switch(step){
// 	case 1:
// 	return <div>
// 		<label>Upload Audio </label>
// 	 <button>Next Step </button>
// 	</div>
// 	case 2:
// 	return <div>
// 	<label>Upload Audio </label>
//  <button>Next Step </button>
// </div>
// 	case 3:
// 	return <div>
// 	<label>Upload Audio </label>
//  <button>Next Step </button>
// </div>
// }
// 	}

// 	return (

// 		<>
// 			<div className='row m-0 p-0'>
// 				<div className='col-lg-2'>  </div>
// 				<div className='col-lg-10'>
//
// 					<section className="section container-fluid">
// 						<div className="pt-3">
// 							<div className="row">
// 								<div className="col-sm-12">
// 									<div className="iq-card">
// 										<div className="iq-card-header d-flex justify-content-between">
// 											<div className="iq-header-title">
// 												<h4 className="card-title">Add Audio</h4>
// 											</div>
// 										</div><hr />
// 										<div className="iq-card-body">
// 											<h5>Audio Info Details</h5>
// 											<div>
// 												<div>
// 												<label>Upload Audio </label>
// 												<div>
// 													<input type='file' />
// 												</div>
// 												</div>

// 												<div>

// 												<Stepper activeStep='1' orientaion='horizontal' >

// 													</Stepper>
// 													{showStep(2)}

// 												</div>

// 												<div className="row mt-3">
// 													<div className="col-md-6">
// 														<div className="panel panel-primary " data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Title</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Add the audio title in the textbox below:</p>
// 																<input type="text" className="form-control" name="title" id="title" placeholder="Audio Title"
// 																	onChange={e => setTitle(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-md-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Slug</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Add the Audio slug:</p>
// 																<input type="text" className="form-control" name="slug" id="slug" placeholder=""
// 																	onChange={e => setSlug(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>

// 													{/* <div className="col-md-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select Date/Time Below</p>
// 																<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
// 															</div>
// 														</div>
// 													</div> */}

// 												</div>
// 												<div className="row">
// 													<div className="col-md-6">
// 														<div className="panel panel-primary  p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Image Cover</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >

// 																<img src="" className="audio-img" width="200" />

// 																<p className="p1">Select the audio image ( 9:16 Ratio or 1080X1920px ):</p>
// 																<input type="file" multiple="true" className="form-control" name="image" id="image" />

// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-md-6">
// 														<div className="panel panel-primary  p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Player Image Cover</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >

// 																<img src="" className="audio-img" width="200" />

// 																<p className="p1">Select the audio image ( 16:9 Ratio or 1280X720px ):</p>
// 																<input type="file" multiple="true" className="form-control" name="player_image" id="player_image" />

// 															</div>
// 														</div>
// 													</div>

// 												</div>

// 												<div className="row container-fluid">
// 													<div className="panel panel-primary col-sm-6 p-0 mt-3" data-collapsed="0"> <div className="panel-heading">
// 														<div className="panel-title"><label>Country</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 														<div className="panel-body" >
// 															<p className="p1">Block the Audio for Selected Country:</p>
// 															{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={artists}
// 																	onChange={e => setArtists(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 														</div>
// 													</div>

// 													<div className="col-sm-6 mt-3">
// 														<div className="">
// 															<label className="m-0">Search Tags</label>

// 															<div className="panel-body mt-3">

// 																<input type="text" className='form-control' name="search_tags"
// 																	onChange={e => setSearch_tags(e.target.value)} />

// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="panel panel-primary mt-3" data-collapsed="0"> <div className="panel-heading">
// 													<div className="panel-title"><label>Audio Details, Links, and Info</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 													<div className="panel-body" >
// 														<textarea className="form-control" name="details" id="details" onChange={e => setDetails(e.target.value)}></textarea>
// 													</div>
// 												</div>

// 												<div className="panel panel-primary mt-3" data-collapsed="0"> <div className="panel-heading">
// 													<div className="panel-title"><label>Short Description</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 													<div className="panel-body" >
// 														<p className="p1">Add a short description of the audio below:</p>
// 														<textarea className="form-control" name="description" id="description" onChange={e => setDescription(e.target.value)}></textarea>
// 													</div>
// 												</div>
// 												<div className="row mt-3">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Cast and Crew</label> </div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p>Add artists for the audio below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={artists}
// 																	onChange={e => setArtists(e.target.value)}
// 																	showCheckbox
// 																/> */}

// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Album</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Album Below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={audio_albums}
// 																	onChange={e => setAudio_albums(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 												<div className="row p-0 mt-3 align-items-center">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Category</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Category Below:</p>

// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={category}
// 																	onChange={e => setCategory(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Ratings</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1"> IMDB Ratings 10 out of 10</p>
// 																<select className="form-select" aria-label="Default select example" onChange={e => setRating(e.target.value)}>
// 																	<option value="0">0</option>
// 																	<option value="1">1</option>
// 																	<option value="2">2</option>
// 																	<option value="3">3</option>
// 																	<option value="4">4</option>
// 																	<option value="5">5</option>
// 																	<option value="6">6</option>
// 																	<option value="7">7</option>
// 																	<option value="8">8</option>
// 																	<option value="9">9</option>
// 																	<option value="10">10</option>
// 																</select>
// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="row mt-3">
// 													<div className="col-sm-6">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Language</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Select a Audio Language Below:</p>
// 																{/* <Multiselect
// 																	isObject={false}
// 																	// onRemove={(event) => { console.log(event) }}
// 																	// onSelect={(event) => { console.log(event) }}
// 																	options={language}
// 																	onChange={e => setLanguage(e.target.value)}
// 																	showCheckbox
// 																/> */}
// 															</div>
// 														</div>
// 													</div>
// 													<div className="col-sm-6 ">
// 														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
// 															<div className="panel-title"><label>Audio Year</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body" >
// 																<p className="p1">Audio Released Year</p>
// 																<input className="form-control" name="year" id="year" onChange={e => setYear(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="clear"></div>

// 												<div className="row mt-3 align-items-center">

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"><label> Duration</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<p className="p1">Enter the audio duration in the following format (Hours : Minutes : Seconds)</p>
// 																<input className="form-control" name="duration" id="duration" maxlength="12"
// 																	onChange={e => setDuration(e.target.value)} />
// 															</div>
// 														</div>
// 													</div>

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"> <label>User Access</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<p className="p1">Who is allowed to view this audio?</p>
// 																<select id="access" name="access" className="form-control">
// 																	{data.map((item) =>
// 																		<option value={item.value}>{item.name}</option>
// 																	)}
// 																</select>
// 																<div className="clear"></div>
// 															</div>
// 														</div>
// 													</div>

// 													<div className="col-sm-4">
// 														<div className="panel panel-primary" data-collapsed="0">
// 															<div className="panel-heading"> <div className="panel-title"><label> Status Settings</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
// 															<div className="panel-body">
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="featured" >Is this audio Featured:</label>
// 																	<input type="checkbox" onChange={e => setFeatured(e.target.value)} />
// 																</div>
// 																<div className="clear"></div>
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="banner" >Is this Audio display in Banner:</label>
// 																	<input type="checkbox" onChange={e => setBanner(e.target.value)} />
// 																</div>
// 																<div className="clear"></div>
// 																<div className="d-flex align-items-baseline">
// 																	<label className="p2" for="active" >Is this audio Active:</label>
// 																	<input type="checkbox" onChange={e => setActive(e.target.value)} />
// 																</div>
// 															</div>
// 														</div>
// 													</div>

// 													{/* <div className="row col-sm-12" id="ppv_price">
// 														<div className="col-sm-6">
// 															<label className="p2">PPV Price:</label>
// 															<input type="text" className="form-control" placeholder="PPV Price" name="ppv_price" id="price"
// 															onChange={e => setPpv_price(e.target.value)} />
// 														</div>

// 														<div className="col-sm-6">
// 															<label className="p2"> IOS PPV Price:</label>
// 															<select name="ios_ppv_price" className="form-control" id="ios_ppv_price" onChange={e => setIos_ppv_price(e.target.value)} >
// 																<option value="0" >Select IOS PPV Price: </option>
// 																<option value="123" >123</option>

// 															</select>
// 														</div>
// 													</div> */}

// 													<div className="row">
// 														<div className="row mt-3 align-items-center ml-3">

// 															<div className="col-sm-12" >

// 																<label className="p2" for="global_ppv">Is this video Is Global PPV:</label>
// 																<input type="checkbox" name="ppv_status" id="ppv_status"
// 																	onChange={e => setPpv_status(e.target.value)} />

// 																<div className="global_ppv_status">
// 																	<label className="p2" for="global_ppv">Is this video Is PPV:</label>
// 																	<input type="checkbox" name="ppv_status" onChange={e => setPpv_status(e.target.value)} id="ppv_status" />
// 																</div>

// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 												<div className="mt-2 p-2 d-flex justify-content-end" >
// 													<input type="submit" value="Update" onClick={Audio} className="btn btn-primary" />
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</section>
// 				</div></div>
// 		</>
// 	);
// }

// export default Addnewaudio;

// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';
// import { Link } from 'react-router-dom';
// import React, { useState, useRef } from "react";
// import axios, { CancelToken, isCancel } from "axios";
// import { ProgressBar } from "react-bootstrap";

// const access_token = localStorage.getItem('access_token');

// function ManageGeoFencing() {

//   const [uploadPercentage, setUploadPercentage] = useState(0);
//   const cancelFileUpload = useRef(null);

//   const headers = {
//     'Content-Type': 'multipart/form-data',
//     Authorization: 'Bearer' + access_token,
//     'Content-Type': 'application/json',
//     "Accept": 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   }

//   const uploadFile = ({ target: { files } }) => {
//     let data = new FormData();
//     data.append("file", files[0]);

//     const options = {
//       onUploadProgress: progressEvent => {
//         const { loaded, total } = progressEvent;

//         let percent = Math.floor((loaded * 100) / total);

//         if (percent < 100) {
//           setUploadPercentage(percent);
//         }
//       },
//       cancelToken: new CancelToken(
//         cancel => (cancelFileUpload.current = cancel)
//       )
//     };

//     axios.post('https://api.flicknexs.com/admin/uploadAudio', data, { headers: headers })

//       // axios
//       //   .post(
//       //     "https://api.flicknexs.com/admin/uploadAudio",
//       //     data,
//       //     options, {enctype : "multipart/formdata"},
//       //     {headers:{
//       //       Authorization: 'Bearer' + access_token,
//       //       "Content-Type" : "application/json; charset=utf-8",
//       //       "credentials": "include",
//       //     }
//       //     }
//       //   )

//       .then(res => {
//         console.log(res);
//         var data = res.data;
//         var Audio_id = res.data.Audio_id;
//         console.log(data)
//         console.log(Audio_id)
//         setUploadPercentage(100);

//         setTimeout(() => {
//           setUploadPercentage(0);
//         }, 1000);
//       })
//       .catch(err => {
//         console.log(err);

//         if (isCancel(err)) {
//           alert(err.message);
//         }
//         setUploadPercentage(0);
//       });
//   };

//   const cancelUpload = () => {
//     if (cancelFileUpload.current)
//       cancelFileUpload.current("User has canceled the file upload.");
//   };

//   return (

//     <div className='row m-0 p-0'>
//       <div className='col-lg-2'>  </div>
//       <div className='col-lg-10'>
//
//         <section className="section container-fluid">

//           <div className="row justify-content-center bg-light">
//             <div className="col-md-6 text-center">
//               <h2>Upload your profile picture</h2>

//               <p>
//                 You can upload a sample file to see the progress bar
//                 with cancel file upload button
//               </p>
//               <p>
//                 <input
//                   type="file"
//                   className="form-control-file"
//                   onChange={uploadFile} accept="audio/mp3"
//                 />
//               </p>
//               {uploadPercentage > 0 && (
//                 <div className="row mt-3">
//                   <div className="col pt-1">
//                     <ProgressBar
//                       now={uploadPercentage}
//                       striped={true}
//                       label={`${uploadPercentage}%`}
//                     />
//                   </div>
//                   <div className="col-auto">
//                     <span
//                       className="text-primary cursor-pointer"
//                       onClick={() => cancelUpload()}
//                     >
//                       Cancel
//                     </span>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </section>
//       </div></div>

//   );
// }

// export default ManageGeoFencing;
