import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { Link } from "react-router-dom";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";

function CreateLandingPages() {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    placeholder: "Start typings...",
  };

  const [inputFields, setInputFields] = useState([
    {
      pagecontent: "",
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        pagecontent: "",
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt, data) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i> Add New Landing Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">
                      <div className="col-md-5 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                            
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Slug</label>
                            </div>{" "}
                            
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>

                    <div class="mb-3 mt-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        Custom CSS :
                      </label>

                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea2"
                        class="form-label"
                      >
                        Bootstrap & Script Link :
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea2"
                        rows="3"
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea3"
                        class="form-label"
                      >
                        Custom Script Content :
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea3"
                        rows="3"
                      ></textarea>
                    </div>

                    <div>
                      <div>
                        <div className="row">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Section 1
                              <TbPlus onClick={addInputField} />
                            </label>
                          </div>
                        </div>
                        {inputFields?.map((data, index) => {
                          const { pagecontent } = data;
                          return (
                            <div
                              className="row d-flex align-items-center my-3"
                              key={index}
                            >
                              <div
                                className="panel panel-primary col-md-10"
                                data-collapsed="0"
                              >
                                <div className="panel-title">
                                  <label>Page Content</label>
                                </div>
                                <div
                                  className="panel-body mt-3"
                                  onChange={(evnt) => handleChange(index, evnt)}
                                  value={pagecontent}
                                >
                                  <JoditEditor
                                    ref={editor}
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                {inputFields?.length !== 0 ? (
                                  <RiDeleteBin6Line
                                    onClick={removeInputFields}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="row">
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary pull-right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CreateLandingPages;
