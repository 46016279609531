import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function EditSubtitleLanguage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    language: "",
    short_code: "",
    id: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Subtitle;
      // console.log(res);
      setEditUser(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      language: editUser.language,
      short_code: editUser.short_code,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/subtitles/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Item Updated Successfully !");
      // alert('Success')
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="iq-card mt-4">
              <div className="row mt-2 ">
                <div className="col-md-6">
                  <h4>
                    <i className="entypo-archive"></i>Edit Subtitles
                  </h4>
                  <small></small>
                </div>
                <hr className="mt-2"></hr>
              </div>

              <div className=""></div>
              <div className="modal-body">
                <div>
                  <label>Name: </label>
                  <input
                    type="text"
                    name="language"
                    value={editUser?.language}
                    onChange={handleInput}
                    placeholder="Enter Language Name"
                    className="form-control rtmp_urls"
                  />
                </div>
              </div>

              <div className="modal-body">
                <div>
                  <label>Slug: </label>
                  <input
                    type="text"
                    name="short_code"
                    value={editUser?.short_code}
                    onChange={handleInput}
                    placeholder="Enter Short Code"
                    className="form-control rtmp_urls"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary ml-3 "
                  onClick={handleUpdate}
                >
                  Update Subtitle{" "}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default EditSubtitleLanguage;
