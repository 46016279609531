import React from "react";

function ManageTranslation() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4 ">
              <div className="container table-responsive">
                <h4>Language Translation</h4>

                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Key:</label>
                      <input
                        type="text"
                        name="key"
                        className="form-control"
                        placeholder="Enter Key..."
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Value:</label>
                      <input
                        type="text"
                        name="value"
                        className="form-control"
                        placeholder="Enter Value..."
                      />
                    </div>
                    <div className="col-md-4 text-center align-self-center">
                      <button type="submit" className="btn btn-success">
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <table className="table table-hover table-bordered mt-4 ">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>English(english)</th>
                      <th>Hindi(hindi)</th>
                      <th>Tamil(tamil)</th>
                      <th>Telugu(lugu)</th>
                      <th>Kannada()</th>
                      <th>Bengali()</th>
                      <th>Marathi()</th>
                      <th>Malayalam()</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="#" className="translate-key">
                          afasf
                        </a>
                      </td>

                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>
                      <td>
                        <a href="#" className="translate">
                          afasf
                        </a>
                      </td>

                      <td>
                        <button className="btn btn-danger btn-xs remove-key">
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageTranslation;
