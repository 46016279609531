import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function AddManageAge() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [age, setAge] = useState("");
  const [active, setActive] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  async function stream() {
    console.log("append data", id, slug, age, active);

    const formData = new FormData();

    formData.append("id", id);
    formData.append("slug", slug);
    formData.append("age", age);
    formData.append("active", active);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          navigate("/admin/age/index");
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="container-fluid p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i>Add Age Category
                      </h4>
                    </div>
                    <hr />
                  </div>

                  <div className=" p-3 ">
                    <h5 className="">Age: </h5>
                    <div>
                      <input
                        type="text"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                        className="form-control"
                        placeholder="age"
                      />
                    </div>
                  </div>
                  <div className=" p-3 ">
                    <h5 className="">Status: </h5>

                    <div name="active">
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="1"
                        onChange={(e) => setActive(e.target.value)}
                      />{" "}
                      Active &nbsp;&nbsp;
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="0"
                        onChange={(e) => setActive(e.target.value)}
                      />{" "}
                      In Active
                    </div>
                  </div>

                  <div className="text-end p-3">
                    <button className="btn btn-primary " onClick={stream}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddManageAge;
