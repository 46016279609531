import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function ThemeSettings() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [dark_mode_logo, setDark_mode_logo] = useState("");
  const [light_mode_logo, setLight_mode_logo] = useState("");
  const [dark_bg_color, setDark_bg_color] = useState("");
  const [light_bg_color, setLight_bg_color] = useState("");
  const [button_bg_color, setButton_bg_color] = useState("");
  const [dark_text_color, setDark_text_color] = useState("");
  const [light_text_color, setLight_text_color] = useState("");
  const [theme_mode, setTheme_mode] = useState("");
  const [signup_step2_title, setSignup_step2_title] = useState("");
  const [signup_payment_content, setSignup_payment_content] = useState("");
  const [signup_theme, setSignup_theme] = useState("");
  const [prevent_inspect, setPrevent_inspect] = useState("");
  const [loader_setting, setLoader_setting] = useState("");
  const [my_profile_theme, setMy_profile_theme] = useState("");
  const [style_sheet_link, setStyle_sheet_link] = useState("");
  const [typography_link, setTypography_link] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/Site-ThemeSetting/index", {
        headers: headers,
      })
      .then((res) => {
        var dark_bg_color = res?.data?.Site_theme_setting[0]?.dark_bg_color;
        var light_bg_color = res?.data?.Site_theme_setting[0]?.light_bg_color;
        var dark_text_color = res?.data?.Site_theme_setting[0]?.light_bg_color;
        var light_text_color = res?.data?.Site_theme_setting[0]?.light_bg_color;
        var dark_mode_logo = res?.data?.Site_theme_setting[0]?.dark_mode_logo;
        var light_mode_logo = res?.data?.Site_theme_setting[0]?.light_mode_logo;
        var theme_mode = res?.data?.Site_theme_setting[0]?.theme_mode;
        var button_bg_color = res?.data?.Site_theme_setting[0]?.button_bg_color;
        setDark_bg_color(dark_bg_color);
        setLight_bg_color(light_bg_color);
        setDark_text_color(dark_text_color);
        setLight_text_color(light_text_color);
        setDark_mode_logo(dark_mode_logo);
        setButton_bg_color(button_bg_color);
        setLight_mode_logo(light_mode_logo);
        setTheme_mode(theme_mode);
        // console.log(light_mode_logo)
        // setProfile_name(res.data.Welcome_Screen[0].name);
        // setWelcome_images(res.data.Welcome_Screen[0].welcome_images);
      });
  }, []);

  async function stream() {
    console.log(
      "append data",
      id,
      dark_mode_logo,
      light_mode_logo,
      dark_bg_color,
      light_bg_color,
      button_bg_color,
      dark_text_color,
      light_text_color,
      theme_mode,
      signup_step2_title,
      signup_payment_content,
      signup_theme,
      prevent_inspect,
      loader_setting,
      my_profile_theme,
      style_sheet_link,
      typography_link
    );

    const formData = new FormData();

    formData.append("id", id);
    formData.append("dark_mode_logo", dark_mode_logo);
    formData.append("light_mode_logo", light_mode_logo);
    formData.append("dark_bg_color", dark_bg_color);
    formData.append("light_bg_color", light_bg_color);
    formData.append("button_bg_color", button_bg_color);
    formData.append("dark_text_color", dark_text_color);
    formData.append("light_text_color", light_text_color);
    formData.append("theme_mode", theme_mode);
    formData.append("signup_step2_title", signup_step2_title);
    formData.append("signup_payment_content", signup_payment_content);
    formData.append("signup_theme", signup_theme);
    formData.append("prevent_inspect", prevent_inspect);
    formData.append("loader_setting", loader_setting);
    formData.append("my_profile_theme", my_profile_theme);
    formData.append("style_sheet_link", style_sheet_link);
    formData.append("typography_link", typography_link);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/update`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          console.log("result", response);
          // navigate('/admin/age/index')
          window.location.reload();
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page p-2">
              <div className="container-fluid p-0">
                <div className="iq-card">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-monitor"></i> Theme Settings for
                      Default Theme
                    </h4>
                  </div>

                  <div className="panel-body">
                    <div className="row mt-4 align-items-center">
                      <div className="col-md-6">
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <div className="panel-heading">
                              {" "}
                              <div className="panel-title">
                                {" "}
                                <p>Site Background Color</p>
                              </div>{" "}
                            </div>
                            <div className="input-group color-picker">
                              <label className="mt-3">Dark Mode</label>

                              <input
                                type="color"
                                className="form-control ml-1"
                                name="dark_bg_color"
                                data-format="hex"
                                onChange={(e) =>
                                  setDark_bg_color(e.target.value)
                                }
                                value={dark_bg_color}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 mt-5">
                            <div className="input-group color-picker">
                              <label className="mt-3">Light Mode</label>
                              <input
                                type="color"
                                className="form-control ml-1"
                                name="light_bg_color"
                                data-format="hex"
                                onChange={(e) =>
                                  setLight_bg_color(e.target.value)
                                }
                                value={light_bg_color}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <div className="panel-heading">
                              {" "}
                              <div className="panel-title mb-0">
                                {" "}
                                <p>Site Text Color</p>
                              </div>{" "}
                            </div>
                            <div className="input-group color-picker">
                              <label className="mt-3"> Dark Mode</label>
                              <input
                                type="color"
                                className="form-control  ml-1"
                                name="dark_text_color"
                                data-format="hex"
                                onChange={(e) =>
                                  setDark_text_color(e.target.value)
                                }
                                value={dark_text_color}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 mt-5">
                            <div className="input-group color-picker">
                              <label className="mt-3"> Light Mode</label>
                              <input
                                type="color"
                                className="form-control ml-1"
                                name="light_text_color"
                                data-format="hex"
                                onChange={(e) =>
                                  setLight_text_color(e.target.value)
                                }
                                value={light_text_color}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel-body">
                    <div className="row mt-2"></div>
                  </div>
                  <div className="panel-heading mt-3">
                    <div className="panel-title">
                      {" "}
                      <label>Site Logo</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <div className="row py-3">
                      <div className="col-sm-6">
                        <div className="input-group color-picker">
                          <label className="mt-2">Dark Mode</label>
                          <input
                            type="file"
                            className="form-control ms-3"
                            name="dark_mode_logo"
                            onChange={(e) =>
                              setDark_mode_logo(e.target.files[0])
                            }
                          />
                        </div>
                        <img
                          src={dark_mode_logo}
                          width="100"
                          className="mt-3"
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="input-group color-picker">
                          <label className="mt-2">Light Mode</label>
                          <input
                            type="file"
                            className="form-control ms-3"
                            name="light_mode_logo"
                            onChange={(e) =>
                              setLight_mode_logo(e.target.files[0])
                            }
                          />
                        </div>
                        <div>
                          <img
                            src={light_mode_logo}
                            width="100"
                            className="mt-3 text-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="panel-heading mt-6">
                        <div className="panel-title">
                          <h4>
                            <i className="entypo-monitor"></i> Theme Settings
                            for Default Button Color
                          </h4>
                        </div>
                        <p>Button Background Color</p>
                      </div>
                    </div>
                  </div>

                  <div className="panel-body">
                    <div className="row ">
                      <div className="col-sm-4">
                        <div className="input-group color-picker">
                          <label className="mt-3">Color</label>
                          <input
                            type="color"
                            className="form-control ml-1"
                            name="button_bg_color"
                            data-format="hex"
                            onChange={(e) => setButton_bg_color(e.target.value)}
                            value={button_bg_color}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel-heading mt-3">
                    <div className="panel-title">
                      <h4>
                        <i className="entypo-monitor"></i> Checkout Theme
                        Setting
                      </h4>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <p>signup page theme</p>
                        <div className="mt-1">
                          {/* <label className="switch">
                              <input name="signup_theme" className="signup_theme" id="signup_theme" type="checkbox" />
                              <span className="slider round"></span>
                            </label> */}
                          <select
                            className="form-control "
                            onChange={(e) => setSignup_theme(e.target.value)}
                            required
                          >
                            <option defaultValue="0">
                              Choose Enable (Yes or No)
                            </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <p>signup Agree Message</p>
                        <input
                          type="text"
                          placeholder="Signup Agree Message"
                          name="signup_payment_content"
                          onChange={(e) =>
                            setSignup_payment_content(e.target.value)
                          }
                          value={signup_payment_content}
                          className="form-control signup_payment_content"
                          id="signup_payment_content"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <p>Sign up - Step 2 Title</p>
                        <input
                          type="text"
                          placeholder="Sign up - Step 2 Title"
                          name="signup_step2_title"
                          onChange={(e) =>
                            setSignup_step2_title(e.target.value)
                          }
                          value={signup_step2_title}
                          className="form-control signup_step2_title"
                          id="signup_step2_title"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="panel-heading mt-3">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>Enable Developer Tools</p>
                        <div className="mt-1">
                          {/* <label className="switch">
                              <input name="prevent_inspect" className="prevent_inspect" id="prevent_inspect" type="checkbox" />
                              <span className="slider round"></span>
                            </label> */}
                          <select
                            className="form-control "
                            onChange={(e) => setPrevent_inspect(e.target.value)}
                            required
                          >
                            <option defaultValue="0">
                              Choose Enable (Yes or No)
                            </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <p>Enable Loader</p>
                        <div className="mt-1">
                          {/* <label className="switch">
                              <input name="loader_setting" className="loader_setting" id="loader_setting" type="checkbox" />
                              <span className="slider round"></span>
                            </label> */}
                          <select
                            className="form-control "
                            onChange={(e) => setLoader_setting(e.target.value)}
                            required
                          >
                            <option defaultValue="0">
                              Choose Enable (Yes or No)
                            </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="panel-heading mt-3 ">
                          <div className="panel-title">
                            <h4>
                              <i className="entypo-monitor"></i> Style Sheet
                              Link
                            </h4>
                          </div>
                        </div>

                        <div className="panel-body">
                          <div className="">
                            <input
                              name="style_sheet_link"
                              className="form-control"
                              placeholder="style.css"
                              onChange={(e) =>
                                setStyle_sheet_link(e.target.value)
                              }
                              value={style_sheet_link}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="panel-heading mt-3 ">
                          <div className="panel-title">
                            <h4>
                              <i className="entypo-monitor"></i> Typography Link
                            </h4>
                          </div>
                        </div>

                        <div className="panel-body">
                          <div className="">
                            <input
                              name="typography_link"
                              className="form-control"
                              placeholder="typography.css"
                              onChange={(e) =>
                                setTypography_link(e.target.value)
                              }
                              value={typography_link}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <h4>
                        <i className="entypo-monitor"></i>MyProfile page theme
                      </h4>
                      <div className="mt-1">
                        {/* <label className="switch">
                            <input name="my_profile_theme" className="my_profile_theme" id="my_profile_theme" type="checkbox" />
                            <span className="slider round"></span>
                          </label> */}
                        <select
                          className="form-control "
                          onChange={(e) => setMy_profile_theme(e.target.value)}
                          required
                        >
                          <option defaultValue="0">
                            Choose Enable (Yes or No)
                          </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="panel-body mt-2 text-end p-2">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      name="submit"
                      onClick={stream}
                    >
                      {" "}
                      Save Settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ThemeSettings;
