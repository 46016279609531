import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EmailSettings() {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    admin_email: "",
    email_password: "",
    host_email: "",
    email_port: "",
    secure: "",
    user_email: "",
  });
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Email-index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.email_settings;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)

      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/Email-Template/index`, {
          headers: headers,
        })
        .then((response) => {
          var result = response?.data?.email_template;
          setTemplate(result);
          // console.log(result)
        })
        .catch((error) => console.log(error));
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      admin_email: editUser.admin_email,
      host_email: editUser.host_email,
      email_port: editUser.email_port,
      secure: editUser.secure,
      user_email: editUser.user_email,
      email_password: editUser.email_password,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Email-update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Email Settings
                    </h4>{" "}
                    <hr />
                  </div>
                  <form>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Admin Email</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="admin_email"
                              id="admin_email"
                              onChange={handleInput}
                              value={editUser?.admin_email}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Email Host</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="host_email"
                              id="host_email"
                              value={editUser?.host_email}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Email Port</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="email_port"
                              id="email_port"
                              value={editUser?.email_port}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Secure </label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <select
                              id="secure"
                              name="secure"
                              className="form-control"
                              required
                              onChange={handleInput}
                            >
                              <option value={editUser?.secure}>
                                {editUser?.secure}
                              </option>
                              <option value="True">True</option>
                              <option value="False">False</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Email User</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="user_email"
                              id="user_email"
                              onChange={handleInput}
                              value={data?.user_email}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Email Password</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="password"
                              className="form-control"
                              name="email_password"
                              id="password"
                              onChange={handleInput}
                              value={data?.email_password}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel-body mt-4 ml-2 d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Update Email Settings"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="container-fluid p-0">
                     <div className="iq-card">
                        <div id="admin-container">
                           <div className="admin-section-title">
                              <h4><i className="entypo-globe"></i> Test Email Settings</h4> <hr />
                           </div>
                           <div className="clear"></div>

                           <form method="post" action="" accept-charset="UTF-8" file="1" enctype="multipart/form-data">
                              <div className="row mt-4">
                                 <div className="col-md-6">
                                    <div className="panel panel-primary" data-collapsed="0">
                                       <div className="panel-heading">
                                          <div className="panel-title"><label>Email</label></div>
                                          <div className="panel-options">
                                             <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link>
                                          </div>
                                       </div>
                                       <div className="panel-body" >
                                          <input type="text" className="form-control" name="test_mail" id="test_mail" required />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <input type="hidden" name="_token" value="" />
                              <div className="panel-body mt-3 ml-2 d-flex justify-content-end">
                                 <button type="submit" className="btn btn-primary" onClick={handleUpdate}> Send Mail</button>
                              </div>
                           </form>

                        </div>
                     </div>
                  </div> */}
            <div id="content-page" className="content-page">
              <div className="container-fluid p-0 m-0">
                <div className="row  m-0">
                  <div className="col-sm-12 m-0 p-0">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Email Templates</h4>
                        </div>
                      </div>
                      <hr />
                      <div className="iq-card-body table-responsive">
                        <div className="table-view">
                          <table
                            id="template"
                            className="table table-striped table-bordered text-center table movie_table "
                          >
                            <thead>
                              <tr className="r1">
                                <th>ID</th>
                                <th>Template</th>
                                <th>Subject</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {template?.map((item) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <p className="">{item?.id}</p>
                                  </td>
                                  <td>
                                    <div className="media align-items-center">
                                      <div className="media-body text-white  ml-3">
                                        <p className="mb-0">
                                          {item?.template_type}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <p className="mb-0">{item?.heading}</p>
                                  </td>
                                  <td>
                                    <div className="align-items-center list-user-action">
                                      <Link
                                        to={
                                          "/admin/template-email/view/" +
                                          item?.id
                                        }
                                        className="delete ms-1"
                                      >
                                        <span>
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </Link>
                                      <Link
                                        to={
                                          "/admin/edit-template-email/" +
                                          item.id
                                        }
                                        className="edit ms-2"
                                      >
                                        <i
                                          className="fa fa-pencil-square"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EmailSettings;
