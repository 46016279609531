import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AudioList.css";

function AudioList() {
  const [audio, setAudio] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.audios?.data;
        setAudio(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/audio/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        // window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/audio/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.audios?.data);
        setAudio(res?.data?.audios?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/audio/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data);
        setPrevious(res?.data?.audios?.data);
        setAudio(res?.data?.audios?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/audio/index?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.audios);
        setNext(res?.data?.audios?.data);
        setAudio(res?.data?.audios?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section ">
            <div className="container-fluid p-2">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between mb-3">
                      <div className="iq-header-title">
                        <h4 className="card-title">Audio Lists</h4>
                      </div>
                      {/* <div className="iq-card-header-toolbar d-flex align-items-center col-md-6">
                           <a href="" className="btn btn-primary">Add Audio</a>
                        </div>                    
			 	            <button  className="btn btn-primary delete_all"> Delete Selected Video </button> */}
                    </div>
                    <hr />
                    <div className=" p-0 mt-4">
                      <div className="table-view">
                        <table className="data-tables table audio_table iq-card text-center p-0 w-100">
                          <thead>
                            <tr className="r1">
                              {/* <th>Select All <input type="checkbox" id="select_all" /></th> */}
                              <th>S.No</th>
                              <th>Title</th>
                              <th>Image</th>
                              <th>Rating</th>
                              <th>Category</th>
                              <th>Views</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {audio?.map((item, key) => (
                            <tbody>
                              <tr key={item?.id}>
                                <td key={key}> {key + 1} </td>
                                <td>{item?.title}</td>
                                <td>
                                  <img
                                    src={item?.image_url}
                                    width={250}
                                    alt=""
                                  />
                                </td>
                                <td>{item?.rating}</td>
                                <td>{item?.rating}</td>
                                <td>{item?.views}</td>
                                <td>
                                  <Link
                                    to={"/admin/edit-audio/" + item?.id}
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AudioList;
