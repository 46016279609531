// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
// import Multiselect from 'multiselect-react-dropdown';
// import './AddNewLiveStream.css'
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';

// const EditAddNewLiveStream = () => {
//   const { id } = useParams();
//   const [editUser, setEditUser] = useState({ title: '', slug: '', enable_restream: '', Stream_key: '', Tv_live_image: '', access: '', active: '', banner: '', details: '', embed_url: '', fb_restream_url: '', fb_streamkey: '', featured: '', footer: '', youtube_streamkey: '', youtube_restream_url: '', year: '', video_category_id: '', url_type: '', twitter_streamkey: '', twitter_restream_url: '', status: '', rating: '', publish_type: '', ppv_price: '', mp4_url: '', m3u_url: '', live_stream_video: '', linkedin_streamkey: '', linkedin_restream_url: '', language: '', ios_ppv_price: '', image: '', hls_url: '', })

//   console.log(id)
//   const access_token = localStorage.getItem("access_token");

//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Accept": 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   };

//    useEffect(async () => {
//     await axios.get(`https://api.flicknexs.com/admin/live-stream/edit/${id}`, { headers: headers }
//     ).then(response => {
//       console.log('api checks', response.data.Live_Stream)
//       var result = response.data.Live_Stream
//       setEditUser(result)
//       console.log(result)
//     })
//       .catch(error =>
//         console.log(error));
//   }, [])

// const handleInput= (e)=>{
//     setEditUser({...editUser, [e.target.name]:e.target.value});
// }
// const handleUpdate= async(e)=>{
//     e.preventDefault();
//     const editInputvalue= {title:editUser.title, };
//     console.log(editInputvalue);
//     let res=  await fetch("https://api.flicknexs.com/admin/live-stream/update/"+id,{
//         method:"POST",
//         headers:headers,
//         body: JSON.stringify(editInputvalue)
//     }, {headers:headers});

//     let resjson = await res.json();
//     if(res.status====200)
//     {
//         // setMessage(resjson.success);
//       setTimeout(() => {
//         // navigate('/userdata');
//       }, 2000);

//     } else {
//         // setMessage("Some error Occured");
//     }

// }

//   //   useEffect( () =>{
//   //     const edituserid= async ()=>{
//   //     const reqdata = await fetch(`https://api.flicknexs.com/admin/live-stream/edit/${id}` , { headers: headers });
//   //     // const res = reqdata.json();
//   //     console.log('reqq', reqdata)
//   //     // console.log('json', res)
//   //     // seteditUser(await res)
//   //   }
//   //   edituserid();
//   // },[]);

//   // useEffect(async () => {
//   //   await axios.get(`https://api.flicknexs.com/admin/live-stream/edit/${id}`, { headers: headers }
//   //   ).then(response => {
//   //     console.log('api checks', response.data.Live_Stream)
//   //     var result = response.data.Live_Stream
//   //     seteditUser(result)
//   //     // console.log(result)
//   //   })
//   //     .catch(error =>
//   //       console.log(error));
//   // }, [])

//   const [category, setCategory] = useState(
//     ["Drama", "News", "Kids", "Entertaiment", "Music"]
//   );
//   const [language, setLanguage] = useState(["Tamil", "English", "Hindi", "Telugu", "Kannada"]);
//   const [artists, setArtists] = useState(["ARR", "HHT", "Anirudh", "Vijay", "Ajith"]);

//   return (
//     <div>

//       <div className='row m-0 p-0'>
//         <div className='col-lg-3'>  </div>
//         <div className='col-lg-9 p-0'>
//
//           <section>
//             <div className="col-lg-12">
//               <div className="card text-center">
//                 <div className="card-body">
//                   <div className="container-fluid p-0">
//                     <div className="p-3">

//                       <div>
//                         <h4 className=''>Add New LiveStream </h4>
//                         <hr />

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Title</label>
//                             <p className="">Add the Live stream title in the textbox below:</p>
//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 value={ editUser.title} onChange={ handleInput }

//                                 placeholder="Title"
//                               />
//                             </div>
//                           </div>

//                           <div className="col-sm-6">
//                             <label className="m-0">Slug</label>
//                             <p className="">Add the Live stream  slug in the textbox below:</p>
//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 value={editUser.slug}
//                                 placeholder="slug"
//                               />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start align-items-center">
//                           <div className="col-md-6">
//                             <label className="m-0">Live Stream Video</label>
//                             <p className="p1">Select the Live stream  image ( 9:16 Ratio or 1080X1920px ):</p>
//                             <div>
//                               <input
//                                 type="file"
//                                 className="form-control"

//                                 placeholder="Live_stream_video"
//                               />
//                             </div>
//                           </div>

//                           <div className="col-md-6">
//                             <div className="row">
//                               <label className="m-0">Player Image Cover</label>
//                               <p className="">Select the Live stream  image( 16:9 Ratio or 1280X720px ):</p>
//                               <div>
//                                 <input
//                                   type="file"
//                                   className="form-control"

//                                   placeholder="player_image"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Live Stream Source</label>
//                             <p className="p1">Select the Live Stream Source :</p>
//                             <div className="">
//                               <div>
//                                 <select className="form-select url_type">
//                                   <option defaultValue="0" selected>Choose URL Format</option>
//                                   <option value="mp4" selected>mp4</option>
//                                   <option value="Embed_url">Embed_url</option>
//                                   <option value="Mp3">Mp3</option>
//                                 </select>
//                               </div>

//                               {/*  <select className="form-select url_type" aria-label="Default select example" name="url_type" onChange={e => setUrl_type(e.target.value)}>
//                                 <option selected>Choose URL Format</option>
//                                 <option value="mp4">mp4</option>
//                               </select>
//                             </div>

//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control mt-2"
//                                 onChange={e => setMp4_url(e.target.value)}
//                                 placeholder="setMp4_url"
//                               />
//                             </div>

//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control mt-2"
//                                 onChange={e => setEmbed_url(e.target.value)}
//                                 placeholder="setEmbed_url"
//                               />
//                             </div>
//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control mt-2"
//                                 onChange={e => setM3u_url(e.target.value)}
//                                 placeholder="setM3u_url"
//                               />
//                             */}
//                             </div>
//                           </div>

//                           <div className="col-sm-6">
//                             <label className="m-0">TV Image Cover</label>
//                             <div className="new-video-upload" id="">
//                               <p className="p1">Select The Live Stream TV Image  (1920 X 1080  Or 16:9 Ratio) :</p>
//                               <input
//                                 type="file"
//                                 className="form-control"

//                                 placeholder="Tv_live_image"
//                               />
//                             </div>
//                             <div>
//                             </div>
//                           </div>

//                           <div className="row mt-3 text-start">
//                             <div className="col-sm-6">
//                               <label className="m-0">Enable ReStream</label>
//                               {/*<div className="mt-1">
//                                  <label className="switch">
//                                     <input name="enable_restream" className="enable_restream" id="enable_restream" type="checkbox" />
//                                     <span className="slider round"></span>
//                                 </label>
//                                 <input
//                                   type="checkbox" value={setEnable_restream ? 1 : ''}
//                                   className="form-group"
//                                   onChange={e => setEnable_restream(e.target.value)}
//                                   placeholder="Enable_restream"
//                                 />
//                               </div> */}
//                               <div>

//                                 <select className="form-select url_type mt-3"
//                                 >
//                                   <option value={editUser.enable_restream}></option>
//                                   <option value="1" >Yes</option>
//                                   <option value="0">No</option>
//                                 </select>
//                               </div>
//                               <div >

//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div className='row mt-3 text-start'>

//                         </div>
//                         {/*
//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6 ">
//                             <div className="mt-2" >
//                               <label className="mb-1"> YouTube Stream (RTMP URL) </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setYoutube_restream_url(e.target.value)}
//                                 placeholder="youtube_restream_url"
//                               />
//                             </div>
//                           </div>

//                           <div className="col-sm-6">
//                             <div className="mt-2" id="">
//                               <label className="mb-1"> YouTube Stream Key </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setYoutube_streamkey(e.target.value)}
//                                 placeholder="youtube_streamkey"
//                               />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <div className="mt-2">
//                               <label className="mb-1"> FaceBook Stream (RTMP URL) </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setFb_restream_url(e.target.value)}
//                                 placeholder="fb_restream_url"
//                               />
//                             </div>
//                           </div>

//                           <div className="col-sm-6" >
//                             <div className="mt-2" id="">
//                               <label className="mb-1"> FaceBook Stream Key </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setFb_streamkey(e.target.value)}
//                                 placeholder="fb_streamkey"
//                               />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <div className="mt-2" >
//                               <label className="mb-1"> Twitter Stream (RTMP URL) </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setTwitter_restream_url(e.target.value)}
//                                 placeholder="twitter_restream_url"
//                               />
//                             </div>
//                           </div>

//                           <div className="col-sm-6">
//                             <div className="mt-2" >
//                               <label className="mb-1"> Twitter Stream Key </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 onChange={e => setTwitter_streamkey(e.target.value)}
//                                 placeholder="twitter_streamkey"
//                               />
//                             </div>
//                           </div>
//                         </div> */}

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-12">
//                             <label className="m-0">Short Description</label>
//                             <p className="">Add a short description of the Livestream below:</p>
//                             <div className="">
//                               <textarea
//                                 className="form-control"
//                                 value={editUser.description}
//                                 placeholder="Description"
//                               />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Search Tags</label>
//                             <div className="">
//                               <input className="form-control me-2" type="search" placeholder="search_tags" aria-label="Search"
//                                 value={editUser.search_tags} />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Live Stream Details, Links, and Info</label>
//                             <div className="">
//                               <textarea
//                                 className="form-control"
//                                 value={editUser.details}
//                                 placeholder="setDetails"
//                               />
//                             </div>
//                           </div>
//                           <div className="col-sm-6">
//                             <label className="m-0">Artists</label>
//                             <div className="">
//                               <Multiselect isObject={false} options={artists} showCheckbox >
//                               </Multiselect>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Category</label>
//                             <p className="p1">Select A Live Stream Category Below:</p>

//                             <div className="">
//                               <Multiselect isObject={false} options={category} showCheckbox >
//                               </Multiselect>

//                             </div>
//                           </div>
//                           <div className="col-sm-6">
//                             <label className="m-0">Language</label>
//                             <p className="p1">Select A Live Stream Language Below:</p>
//                             <div className="">
//                               <Multiselect isObject={false} options={language} showCheckbox name="language">
//                               </Multiselect>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row text-start mt-4">
//                           <div className="col-sm-6">
//                             <label className="m-0">Live Stream Ratings</label>
//                             <p className="p1">Live Stream Ratings 10 Out Of 10 </p>
//                             <div className="">
//                               <select className="form-select" aria-label="Default select example" >
//                                 <option value={editUser.rating}></option>

//                               </select>
//                             </div>
//                           </div>

//                           <div className="col-sm-6">
//                             <label className="m-0">Live Stream Year</label>
//                             <p className="p1">Live Stream Released Year</p>
//                             <div className="">
//                               <input
//                                 type="text"
//                                 name="year"
//                                 className="form-control"
//                                 value={editUser.year}
//                                 placeholder="Set Year"
//                               />
//                             </div>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-6">
//                             <label className="m-0">Duration</label>
//                             <p className="p1">Enter The Live Stream Duration In (HH : MM : SS)</p>
//                             <div>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 value={editUser.duration}
//                                 placeholder="duration"
//                               />
//                             </div>
//                           </div>
//                           <div className="col-sm-6">
//                             <label className="m-0">User Access</label>
//                             <p className="p1">Who Is Allowed To View This Live Stream ?</p>
//                             <select className="form-control" >
//                               <option value={editUser.access}>
//                               </option>

//                             </select>
//                           </div>
//                         </div>

//                         <div className="row mt-3 text-start">
//                           <div className="col-sm-4">
//                             <label className="m-0">Publish Type</label>
//                             <div className="p2" >
//                               <div>
//                                 <input
//                                   type="radio" name='type'
//                                   className="form-group"
//                                   value={editUser.publish_type}
//                                   placeholder="publish_type"
//                                 />
//                               </div>

//                             </div>
//                           </div>

//                           <div className="col-sm-3">
//                             <div>
//                               {/* <label className="m-0">Publish Time</label>
//                                <div className="">
//                                     <input type="datetime-local" className="form-control" id="publish_time" name="publish_time" />
//                                 </div> */}
//                             </div>
//                           </div>

//                           <div className="col-sm-5" id="publishlater">
//                             <label className="m-0">Status Settings</label>
//                             <div>
//                               <p className="p2">Is this Live stream Featured:

//                                 <label className="switch">
//                                   <input name="email" className="email form-group" id="email" type="checkbox" value={editUser.featured}/>
//                                   <span className="slider round"></span>
//                                 </label>
//                               </p>
//                             </div>
//                             <div>
//                               <p className="p2">Is this Live stream Active:

//                                 <label className="switch">
//                                   <input name="email" className="email form-group" id="email" type="checkbox"  value={editUser.active}/>
//                                   <span className="slider round"></span>
//                                 </label>
//                               </p>
//                             </div>

//                             <div>
//                               <p className="p2">Is this Live stream display in Banner:
//                                 <label className="switch">
//                                   <input name="email" className="email form-group" id="email" type="checkbox" value={editUser.banner}/>
//                                   <span className="slider round"></span>
//                                 </label>
//                               </p>
//                             </div>

//                           </div>
//                         </div>
//                         <div className='text-end' >
//                           <button className='btn btn-primary' onClick={handleUpdate}>
//                             Submit
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div></div>
//     </div>
//   );
// };

// export default EditAddNewLiveStream;

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import JoditEditor from "jodit-react";
import "./AddNewLiveStream.css";
import Select from "react-select";

function EditAddNewLiveStream() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    enable_restream: "",
    Stream_key: "",
    TV_image: "",
    player_image: "",
    access: "",
    active: "",
    banner: "",
    details: "",
    embed_url: "",
    fb_restream_url: "",
    fb_streamkey: "",
    featured: "",
    footer: "",
    youtube_streamkey: "",
    youtube_restream_url: "",
    year: "",
    video_category_id: "",
    url_type: "",
    twitter_streamkey: "",
    twitter_restream_url: "",
    status: "",
    rating: "",
    publish_type: "",
    ppv_price: "",
    mp4_url: "",
    m3u_url: "",
    live_stream_video: "",
    linkedin_streamkey: "",
    linkedin_restream_url: "",
    language: "",
    ios_ppv_price: "",
    image: "",
    hls_url: "",
    description: "",
    duration: "",
    search_tags: "",
    Video_thumbnail: "",
    Player_thumbnail: "",
    Video_TV_Thumbnail: "",
  });

  const [message, setMessage] = useState("");
  const [Tv_image, setTv_live_image] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [editedData, seteditedData] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [image, setImage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  const [order, setOrder] = useState({});
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [order3, setOrder3] = useState({});

  const [category, setCategory] = useState([]);
  const [cate, setCate] = useState([]);
  const [artist, setArtists] = useState([]);
  const [language, setLanguage] = useState([]);

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    console.log(e, "onchnageddddd");
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Live_Stream[0];
      const options = resData?.livestream_category?.map((option) => ({
        value: option?.id,
        label: option?.name,
      }));

      setSelectedOptions(options);
      var ress = resData?.Live_Stream;
      setEditUser(res);
      // console.log(res, "kkk");
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      fb_streamkey: editUser.fb_streamkey,
      title: editUser.title,
      slug: editUser.slug,
      url_type: editUser.url_type,
      mp4_url: editUser.mp4_url,
      player_image: editUser.player_image,
      enable_restream: editUser.enable_restream,
      Stream_key: editUser.Stream_key,
      Tv_live_image: editUser.Tv_live_image,
      youtube_streamkey: editUser.youtube_streamkey,
      video_category_id: editUser.video_category_id,
      twitter_streamkey: editUser.twitter_streamkey,
      twitter_restream_url: editUser.twitter_restream_url,
      url_type: editUser.url_type,
      youtube_restream_url: editUser.youtube_restream_url,
      status: editUser.status,
      rating: editUser.rating,
      search_tags: editUser.search_tags,
      publish_type: editUser.publish_type,
      year: editUser.year,
      description: editUser.description,
      access: editUser.access,
      ppv_price: editUser.ppv_price,
      footer: editUser.footer,
      mp4_url: editUser.mp4_url,
      duration: editUser.duration,
      active: editUser.active,
      banner: editUser.banner,
      details: editUser.details,
      embed_url: editUser.embed_url,
      fb_restream_url: editUser.fb_restream_url,
      featured: editUser.featured,
      m3u_url: editUser.m3u_url,
      live_stream_video: editUser.live_stream_video,
      linkedin_streamkey: editUser.linkedin_streamkey,
      linkedin_restream_url: editUser.linkedin_restream_url,
      language: editUser.language,
      image: editUser.image,
      ios_ppv_price: editUser.ios_ppv_price,
      hls_url: editUser.hls_url,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/live-stream/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        navigate("/admin/livestream");
      }, 2000);

      var livestream_id = resjson.data.LiveStream.id;

      // setLivestream_id(livestream_id);
      console.log("livestream_id", livestream_id);

      const formDatas = new FormData();

      formDatas.append("livestream_id", livestream_id);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("TV_image", Tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
          {
            headers: headers,
          }
        );
        const data = await response?.json();
        const options = data?.livestream_category?.map((category) => ({
          value: category?.id,
          label: category?.name,
        }));
        setAllOptions(options);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);
  // console.log(selectedCategory, "ss");

  // console.log(selectedOptions, "selectedCategory");
  return (
    // <React.Fragment>
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-md-12">
    //             <h5 className="mt-2">Edit User { id }</h5>
    //       <p className="text-success"> { message} </p>
    //             <form onSubmit={ handleUpdate}>
    //                 <div className="row">
    //                     <div className="col-md-6">
    //                         <div className="mb-3">
    //                            <label className="form-lable">Username</label>
    //                            <input type="text" name="title" className="form-control" value={ editUser.title } onChange={ handleInput } />
    //                         </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                         <div className="mb-3">
    //                            <label className="form-lable">slug</label>
    //                            <input type="text" name="slug" className="form-control" value={ editUser.slug} onChange={ handleInput }/>
    //                         </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                         <div className="mb-3">
    //                            <label className="form-lable">Phone No</label>
    //                            <input type="text" name="phone" className="form-control" value={ editUser} onChange={ handleInput }/>
    //                         </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                         <div className="mb-3">
    //                            <label className="form-lable">Address</label>
    //                            <input type="text" name="address" className="form-control" value={ editUser} onChange={ handleInput }/>
    //                         </div>
    //                     </div>
    //                     <div className="col-md-6">
    //                         <div className="mb-3">
    //                            <label className="form-lable">Status</label>
    //                           <select name="status" className="form-control" value={ editUser} onChange={ handleInput }>
    //                             <option value="">--Please Select--</option>
    //                             <option value="1">Active</option>
    //                             <option value="0">Inactive</option>
    //                             </select>
    //                         </div>
    //                     </div>

    //                     <div className="col-md-12">
    //                         <div className="mb-3">
    //                            <label className="form-lable"></label>
    //                           <button name="submit" className="btn btn-success btn-lg">Update</button>
    //                            </div>
    //                     </div>
    //                 </div>
    //             </form>

    //             </div>
    //         </div>
    //     </div>

    // </React.Fragment>

    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12">
              <div className="card text-center">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <form onSubmit={handleUpdate}>
                        <div>
                          <h4 className="">Add New LiveStream </h4>
                          <hr />

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Title</label>
                              <p className="">
                                Add the Live stream title in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  onChange={handleInput}
                                  placeholder="Title"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">Slug</label>
                              <p className="">
                                Add the Live stream slug in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  name="slug"
                                  className="form-control"
                                  value={editUser?.slug}
                                  onChange={handleInput}
                                  placeholder="slug"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start align-items-center">
                            <div className="col-md-6">
                              <label className="m-0">Live Stream Video</label>
                              <p className="p1">
                                Select the Live stream image ( 9:16 Ratio or
                                1080X1920px ):
                              </p>
                              <div>
                                <img
                                  src={editUser?.Video_thumbnail}
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Live_stream_video"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <label className="m-0">
                                  Player Image Cover
                                </label>
                                <p className="">
                                  Select the Live stream image( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <div>
                                  <img
                                    src={editUser?.Player_thumbnail}
                                    width={150}
                                    height={150}
                                  />

                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder="player_image"
                                    name="player_image"
                                    id="player_image"
                                    onChange={(e) =>
                                      setPlayer_image(e.target.files[0])
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Source</label>
                              <p className="p1">
                                Select the Live Stream Source :
                              </p>
                              <div className="">
                                <div>
                                  <select
                                    className="form-select url_type"
                                    onChange={handleInput}
                                  >
                                    <option
                                      name="url_type"
                                      value={editUser?.url_type}
                                    >
                                      Choose URL Format
                                    </option>
                                    <option
                                      name="mp4_url"
                                      value={editUser?.mp4_url}
                                    >
                                      mp4
                                    </option>
                                    <option value="Embed_url">Embed_url</option>
                                    <option value="Mp3">Mp3</option>
                                  </select>
                                </div>

                                {/*  <select className="form-select url_type" aria-label="Default select example" name="url_type" onChange={e => setUrl_type(e.target.value)}>
                                        <option selected>Choose URL Format</option>
                                        <option value="mp4">mp4</option>
                                      </select>
                                    </div>
        
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control mt-2"
                                        onChange={e => setMp4_url(e.target.value)}
                                        placeholder="setMp4_url"
                                      />
                                    </div>
        
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control mt-2"
                                        onChange={e => setEmbed_url(e.target.value)}
                                        placeholder="setEmbed_url"
                                      />
                                    </div>
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control mt-2"
                                        onChange={e => setM3u_url(e.target.value)}
                                        placeholder="setM3u_url"
                                      />
                                    */}
                              </div>
                            </div>
                            {/* {live_stream_source()} */}

                            <div className="col-sm-6">
                              <label className="m-0">TV Image Cover</label>
                              <div className="new-video-upload" id="">
                                <p className="p1">
                                  Select The Live Stream TV Image (1920 X 1080
                                  Or 16:9 Ratio) :
                                </p>
                                <img
                                  src={editUser?.Video_TV_Thumbnail}
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Tv_live_image"
                                  name="Tv_live_image"
                                  id="Tv_live_image"
                                  onChange={(e) =>
                                    setTv_live_image(e.target.files[0])
                                  }
                                />
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Enable ReStream</label>
                              {/*<div className="mt-1">
                                         <label className="switch">
                                            <input name="enable_restream" className="enable_restream" id="enable_restream" type="checkbox" />
                                            <span className="slider round"></span>
                                        </label> 
                                        <input
                                          type="checkbox" value={setEnable_restream ? 1 : ''}
                                          className="form-group"
                                          onChange={e => setEnable_restream(e.target.value)}
                                          placeholder="Enable_restream"
                                        />
                                      </div> */}
                              <div>
                                <select className="form-select url_type mt-3">
                                  <option
                                    name="enable_restream"
                                    value={editUser?.enable_restream}
                                    onChange={handleInput}
                                  >
                                    {editUser?.enable_restream}
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start"></div>
                          {/*
                                <div className="row mt-3 text-start">
                                  <div className="col-sm-6 ">
                                    <div className="mt-2" >
                                      <label className="mb-1"> YouTube Stream (RTMP URL) </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setYoutube_restream_url(e.target.value)}
                                        placeholder="youtube_restream_url"
                                      />
                                    </div>
                                  </div>
        
                                  <div className="col-sm-6">
                                    <div className="mt-2" id="">
                                      <label className="mb-1"> YouTube Stream Key </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setYoutube_streamkey(e.target.value)}
                                        placeholder="youtube_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div>
        
                                <div className="row mt-3 text-start">
                                  <div className="col-sm-6">
                                    <div className="mt-2">
                                      <label className="mb-1"> FaceBook Stream (RTMP URL) </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setFb_restream_url(e.target.value)}
                                        placeholder="fb_restream_url"
                                      />
                                    </div>
                                  </div>
        
                                  <div className="col-sm-6" >
                                    <div className="mt-2" id="">
                                      <label className="mb-1"> FaceBook Stream Key </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setFb_streamkey(e.target.value)}
                                        placeholder="fb_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div>
        
                                <div className="row mt-3 text-start">
                                  <div className="col-sm-6">
                                    <div className="mt-2" >
                                      <label className="mb-1"> Twitter Stream (RTMP URL) </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setTwitter_restream_url(e.target.value)}
                                        placeholder="twitter_restream_url"
                                      />
                                    </div>
                                  </div>
        
                                  <div className="col-sm-6">
                                    <div className="mt-2" >
                                      <label className="mb-1"> Twitter Stream Key </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => setTwitter_streamkey(e.target.value)}
                                        placeholder="twitter_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div> */}

                          <div className="row mt-3 text-start">
                            <div className="col-sm-12">
                              <label className="m-0">Short Description</label>
                              <p className="">
                                Add a short description of the Livestream below:
                              </p>
                              <div className="">
                                <textarea
                                  className="form-control"
                                  value={editUser?.description}
                                  name="description"
                                  onChange={handleInput}
                                  placeholder="Description"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Search Tags</label>
                              <div className="">
                                <input
                                  className="form-control me-2"
                                  type="search"
                                  placeholder="search_tags"
                                  aria-label="Search"
                                  value={editUser?.search_tags}
                                  onChange={handleInput}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Artists</label>
                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={artist}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-12">
                              <label className="m-0">
                                Live Stream Details, Links, and Info
                              </label>
                              {/* <div className="">
                                <textarea
                                  className="form-control"
                                  value={editUser.details}
                                  onChange={handleInput}
                                  name="details"
                                  placeholder="setDetails"
                                />
                              </div> */}

                              <div className="panel-body mt-3">
                                <JoditEditor
                                  value={editUser?.details}
                                  onChange={(e) => {
                                    handleChangeFirst(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Category</label>
                              <p className="p1">
                                Select A Live Stream Category Below:
                              </p>

                              <div className="">
                                {/* categoryOptions */}
                                <Select
                                  isMulti
                                  options={allOptions}
                                  value={selectedOptions}
                                  onChange={(selected) =>
                                    setSelectedOptions(selected)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Language</label>
                              <p className="p1">
                                Select A Live Stream Language Below:
                              </p>
                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Age Restriction</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={cate}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Block Country</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row text-start mt-4">
                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Ratings</label>
                              <p className="p1">
                                Live Stream Ratings 10 Out Of 10{" "}
                              </p>
                              <div className="form-control">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                >
                                  <option
                                    value={editUser?.rating}
                                    onChange={handleInput}
                                    name="rating"
                                  ></option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Year</label>
                              <p className="p1">Live Stream Released Year</p>
                              <div className="">
                                <input
                                  type="text"
                                  name="year"
                                  className="form-control"
                                  value={editUser?.year}
                                  onChange={handleInput}
                                  placeholder="Set Year"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Choose Ads Position</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={cate}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">
                                Choose Advertisement
                              </label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Duration</label>
                              <p className="p1">
                                Enter The Live Stream Duration In (HH : MM : SS)
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={editUser?.duration}
                                  onChange={handleInput}
                                  name="duration"
                                  placeholder="duration"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">User Access</label>
                              <p className="p1">
                                Who Is Allowed To View This Live Stream ?
                              </p>
                              <select className="form-control">
                                <option
                                  value={editUser?.access}
                                  onChange={handleInput}
                                  name="access"
                                ></option>
                              </select>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-4">
                              <label className="m-0">Publish Type</label>
                              <div className="p2">
                                <div>
                                  <input
                                    type="radio"
                                    name="type"
                                    className="form-group"
                                    value={editUser?.publish_type}
                                    onChange={handleInput}
                                    placeholder="publish_type"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div>
                                {/* <label className="m-0">Publish Time</label>
                                       <div className="">
                                            <input type="datetime-local" className="form-control" id="publish_time" name="publish_time" />
                                        </div> */}
                              </div>
                            </div>

                            <div className="col-sm-5" id="publishlater">
                              <label className="m-0">Status Settings</label>
                              <div>
                                <p className="p2">
                                  Is this Live stream Featured:
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      className="featured form-group"
                                      onChange={handleInput}
                                      id="featured"
                                      type="checkbox"
                                      value={editUser?.featured}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                              <div>
                                <p className="p2">
                                  Is this Live stream Active:
                                  <label className="switch">
                                    <input
                                      name="active"
                                      className="active form-group"
                                      onChange={handleInput}
                                      id="active"
                                      type="checkbox"
                                      value={editUser?.active}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>

                              <div>
                                <p className="p2">
                                  Is this Live stream display in Banner:
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      className="banner form-group"
                                      onChange={handleInput}
                                      id="banner"
                                      type="checkbox"
                                      value={editUser?.banner}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="text-end">
                            <button className="btn btn-primary">Update</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default EditAddNewLiveStream;
