import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

const EditAdvertiser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    company_name: "",
    license_number: "",
    ccode: "",
    email_id: "",
    mobile_number: "",
    address: "",
    password: "",
    status: "",
    stripe_id: "",
    card_brand: "",
    card_last_four: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [data, setData] = useState([]);
  // console.log("data", data)
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState("");

  // console.log(avatar)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData.json();
      var res = resData.Advertiser;
      setEditUser(res);
      // {
      // 	results.map((item) =>
      // 		setAvatar(item.avatar),
      // 	)
      // }
      // console.log(resData)
      // console.log(res)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response.data.role;
        setData(result);
        var results = response.data.country;
        setNumber(results);
        console.log("result", result);
        console.log("orders", result);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/list`, {
        headers: headers,
      })
      .then((response) => {
        var results = response.data.users_list;

        {
          results.map((item) => setUser(item.user_avatar));
        }
        // console.log(results)
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      company_name: editUser.company_name,
      license_number: editUser.license_number,
      ccode: editUser.ccode,
      email_id: editUser.email_id,
      mobile_number: editUser.mobile_number,
      address: editUser.address,
      password: editUser.password,
      status: editUser.status,
      stripe_id: editUser.stripe_id,
      card_brand: editUser.card_brand,
      card_last_four: editUser.card_last_four,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Advertisers/update/` + id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i> Update Advertiser
              </h4>
              <hr />
              <div className="p-3">
                <div className="col-md-12 mb-3">
                  <label className="m-0">Company Name:</label>

                  <input
                    type="text"
                    className="form-control"
                    name="company_name"
                    id="company_name"
                    value={editUser.company_name}
                    placeholder="Enter Company Name"
                    onChange={handleInput}
                  />
                </div>

                <div className="col-md-12 mb-3" data-collapsed="0">
                  <label className="m-0">License Number:</label>
                  <div className="panel-body">
                    <input
                      type="number"
                      className="form-control"
                      name="license_number"
                      id="license_number"
                      value={editUser.license_number}
                      onChange={handleInput}
                      placeholder="Enter License Number"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="m-0">Address:</label>

                  <input
                    type="address"
                    className="form-control"
                    name="address"
                    value={editUser.address}
                    onChange={handleInput}
                    id="address"
                    placeholder="Enter Address"
                  />
                </div>

                <div className="col-md-12 mb-3" data-collapsed="0">
                  <label className="m-0">Mobile Number:</label>
                  <div className="panel-body">
                    <input
                      type="number"
                      className="form-control"
                      name="mobile_number"
                      id="mobile_number"
                      onChange={handleInput}
                      value={editUser.mobile_number}
                      placeholder="Enter Number"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="m-0">Email Name:</label>

                  <input
                    type="email_id"
                    className="form-control"
                    name="email_id"
                    onChange={handleInput}
                    id="email_id"
                    value={editUser.email_id}
                    placeholder="Enter Email"
                  />
                </div>

                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Upload"
                      onClick={handleUpdate}
                      className="btn btn-primary "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditAdvertiser;
