import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ViewChannelPartner.css";

function ViewChannelPartner() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Channel Users Lists</h4>
                        </div>
                        <hr />
                      </div>
                      <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex align-items-baseline">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="view-search"
                            placeholder="Search Data"
                          />
                        </div>
                      </div>
                      <div className="iq-card-body table-responsive p-0">
                        <div className="table-view">
                          <table
                            className="table table-striped table-bordered table movie_table iq-card text-center"
                            id="channeluser"
                          >
                            <thead>
                              <tr className="r1">
                                <th>Id</th>
                                <th>Channel Name</th>
                                <th>Email ID</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                <th>Intro Video</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td className="bg-danger"> </td>

                                <td>
                                  <video
                                    width="100"
                                    height="100"
                                    id="videoPlayer"
                                    className=""
                                    controls
                                    data-setup='{"controls": true, "aspectRatio":"16:9", "fluid": true}'
                                    src="{{ $user->intro_video }}"
                                    type="video/mp4"
                                  ></video>
                                </td>
                                <td>
                                  <Link to="">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="clear"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewChannelPartner;
