import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Addalbums = () => {
  const [albumname, setAlbumname] = useState("");
  const [slug, setSlug] = useState("");
  const [uploaded_by, setUploaded_by] = useState("admin");
  const [album, setAlbum] = useState("");

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);
  const [parent_id, setParent_id] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  async function submitalbum() {
    console.log("append data");

    const formData = new FormData();

    formData.append("albumname", albumname);
    formData.append("slug", slug);
    formData.append("parent_id", parent_id);
    formData.append("uploaded_by", uploaded_by);
    formData.append("Channel_Partner_id", Channel_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          // alert("Added successfully  !")
          setInterval(10000);

          var album_id = response.data.AudioAlbum_id;
          var resssss = response.data;
          console.log("album_id", resssss);
          // navigate('/admin/all-artist')
          // setArtist_id(result)

          // console.log('data', image)
          const formDatas = new FormData();

          formDatas.append("album", album);
          formDatas.append("audio_album_id", album_id);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Ablum/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        // var result = response.data.role
        // setData(result)
        // var results = response.data.country
        // setNumber(results)
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.audio_albums;
        setUser(result);
        // {
        //   result.map((item) =>
        //     setUser(item.image_url),
        //   )
        // }
        console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div>
        <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Add Album</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="albumname"
                      name="albumname"
                      className="form-control"
                      onChange={(e) => setAlbumname(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group add-profile-pic mt-2">
                    <label>Cover Image:</label>
                    <input
                      id="f02"
                      type="file"
                      name="album"
                      className=" form-control"
                      placeholder="Add profile picture"
                      onChange={(e) => setAlbum(e.target.files[0])}
                    />
                    <p className="">
                      Must be JPEG, PNG, or GIF and cannot exceed 10MB.
                    </p>
                  </div>
                  <div className="form-group">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      onChange={(e) => setSlug(e.target.value)}
                      placeholder="Enter Slug"
                    />
                    <input
                      type="text"
                      hidden
                      id="uploaded_by"
                      name="uploaded_by"
                      className="form-control"
                      onChange={(e) => setUploaded_by(e.target.value)}
                    />
                  </div>
                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                      onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {user?.map((item) => (
                        <option value={item?.parent_id}>{item?.albumname}</option>
                      ))}
                    </select>
                  </div>
                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="submit-new-cat"
                      onClick={submitalbum}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  )
}

export default Addalbums