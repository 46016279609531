import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Myprofile.css";
import JoditEditor from "jodit-react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../components/Images/loading/loading.gif";
import deleteitem from "../../components/Images/deleteitem.svg";
import Dropzone from "react-dropzone";

const Myprofile = (props) => {
  const [loading, setLoading] = useState(true);
  const [activation_code, setActive] = useState("");

  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const Channel_Partner_id = localStorage.getItem("Channel_Partner_id");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [editUser, setEditUser] = useState({
    email: "",
    password: "",
    channel_name: "",
    channel_slug: "",
    ccode: "",
    mobile_number: "",
    channel_about: "",
    activation_code: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    IFSC_Code: "",
    cancelled_cheque: "",
    upi_id: "",
    upi_mobile_number: "",
    channel_image: "",
    channel_banner: "",
    status: "",
    channel_logo_url: "",
    channel_banner_url: "",
    channel_image_url: "",
    intro_video_url: "",
    cancelled_cheque_url: "",
  });

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/About`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.users_list;

        var resultssetEditUser = response?.data?.Channel;
        setEditUser(resultssetEditUser);
        var results = response?.data?.country_code;
        setNumber(results);
        // console.log(resultssetEditUser)
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const getFirstNElements = (arr, n) => {
    return arr.slice(0, n);
  };

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);

  // Get the first 3 elements from the data array
  const firstNElements = getFirstNElements(data, 3);

  const [autoFillValuechannel_name, setAutoFillValuechannel_name] =
    useState("");

  const handleInputKeyDownchannel_name = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.channel_name);
    }
  };
  const handleBlurchannel_name = () => {
    generateSlug(editUser?.channel_name);
  };

  const handleAutoFillChangechannel_name = (event) => {
    setAutoFillValuechannel_name(event.target.value);
  };

  const generateSlug = (channel_name) => {
    channel_name = channel_name.trim();
    const slug = channel_name.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuechannel_name(slug);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
  };

  const handleChangeFirstchannel_about = (e) => {
    setEditUser({ ...editUser, channel_about: e });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageemail("");
    setIsValidationHiddenemail(true);
    setValidationMessagechannel_name("");
    setIsValidationHiddenchannel_name(true);
    setValidationMessagepassword("");
    setIsValidationHiddenpassword(true);
    setValidationMessagemobile_number("");
    setIsValidationHiddenmobile_number(true);
    setValidationMessageccode("");
    setIsValidationHiddenccode(true);
    setValidationMessagerole("");
    setIsValidationHiddenrole(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [videosintro, setVideosintro] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropintro = (acceptedFiles) => {
    setVideosintro(acceptedFiles);
  };

  const [validationMessagechannel_name, setValidationMessagechannel_name] =
    useState("");
  const [isValidationHiddenchannel_name, setIsValidationHiddenchannel_name] =
    useState(true);

  const [validationMessageemail, setValidationMessageemail] = useState("");
  const [isValidationHiddenemail, setIsValidationHiddenemail] = useState(true);
  const [focusInputRef, setFocusInputRef] = useState(null); // Initialize focusInputRef to null

  const [validationMessagemobile_number, setValidationMessagemobile_number] =
    useState("");
  const [isValidationHiddenmobile_number, setIsValidationHiddenmobile_number] =
    useState(true);

  const [validationMessageccode, setValidationMessageccode] = useState("");
  const [isValidationHiddenccode, setIsValidationHiddenccode] = useState(true);

  const [validationMessagepassword, setValidationMessagepassword] =
    useState("");
  const [isValidationHiddenpassword, setIsValidationHiddenpassword] =
    useState(true);
  const [validationMessagerole, setValidationMessagerole] = useState("");
  const [isValidationHiddenrole, setIsValidationHiddenrole] = useState(true);

  const channel_nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const mobile_numberInputRef = useRef(null);
  const ccodeInputRef = useRef(null);
  const roleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function saveUser() {
    setValidationMessageemail("");
    setIsValidationHiddenemail(true);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    let focusInputRef = null;

    if (editUser?.channel_name === "") {
      setValidationMessagechannel_name("Name cannot be empty.");
      setIsValidationHiddenchannel_name(false);
      focusInputRef = channel_nameInputRef;
    }

    if (editUser?.ccode === "") {
      setValidationMessageccode("");
      setIsValidationHiddenccode(false);
      focusInputRef = ccodeInputRef;
    }
    if (editUser?.mobile_number === "") {
      setValidationMessagemobile_number(
        "Code and Mobile Number cannot be empty."
      );
      setIsValidationHiddenmobile_number(false);
      focusInputRef = mobile_numberInputRef;
    }
    if (editUser?.password === "") {
      setValidationMessagepassword("Passwords cannot be empty.");
      setIsValidationHiddenpassword(false);
      focusInputRef = passwordInputRef;
    }
    if (!editUser?.email || !emailPattern.test(editUser?.email)) {
      setValidationMessageemail("Invalid email format.");
      setIsValidationHiddenemail(false);
      emailInputRef.current.focus();
      return;
    }
    if (editUser?.role === "") {
      setValidationMessagerole("User Role cannot be empty.");
      setIsValidationHiddenrole(false);
      focusInputRef = roleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("Channel_Partner_id", Channel_Partner_id);
    formData.append("channel_name", editUser?.channel_name);
    formData.append("channel_slug", editUser?.channel_slug);
    formData.append("email", editUser?.email);
    formData.append("ccode", editUser?.ccode);
    formData.append("password", editUser?.password);
    formData.append("mobile_number", editUser?.mobile_number);
    formData.append("channel_about", editUser?.channel_about);
    formData.append("activation_code", editUser?.activation_code);
    formData.append("bank_name", editUser?.bank_name);
    formData.append("branch_name", editUser?.branch_name);
    formData.append("account_number", editUser?.account_number);
    formData.append("IFSC_Code", editUser?.IFSC_Code);
    formData.append("cancelled_cheque", editUser?.cancelled_cheque);
    formData.append("upi_id", editUser?.upi_id);
    formData.append("upi_mobile_number", editUser?.upi_mobile_number);
    formData.append("channel_image", editUser?.channel_image);
    formData.append("channel_banner", editUser?.channel_banner);
    formData.append("status", editUser?.status);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let forthApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/About-update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;

        const formData = new FormData();

        // formData.append("video_id", id);
        if (selectedFile1) {
          formData.append("channel_image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("channel_banner", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("channel_logo", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Images-update`,
          formData,
          { headers: headers }
        );

        const formData1 = new FormData();

        videosintro.map((video, index) => {
          formData1.append("intro_video", video);
        });

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Intro-video-update`,
          formData1,
          { headers: headers }
        );

        const formDatacheque = new FormData();
        if (selectedFile4) {
          formDatacheque.append("cancelled_cheque_image", selectedFile4);
        }
        const formDatachequeresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Cancelled-Cheque-Image-update`,
          formDatacheque,
          { headers: headers }
        );

        if (imageResponse.data.status === true) {
          secondApiStatus = true;
        }
        if (formData1response.data.status === true) {
          thirdApiStatus = true;
        }
        if (formDatachequeresponse.data.status === true) {
          forthApiStatus = true;
        }
      } else if (response.data.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (firstApiStatus && secondApiStatus && thirdApiStatus && forthApiStatus) {
      setValidationMessagechannel_name("");
      setValidationMessageemail("");
      setValidationMessagepassword("");
      setValidationMessagemobile_number("");
      setValidationMessageccode("");
      setValidationMessagerole("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenchannel_name(true);
      setIsValidationHiddenpassword(true);
      setIsValidationHiddenmobile_number(true);
      setIsValidationHiddenccode(true);
      setIsValidationHiddenrole(true);
    }
  }

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>My Profile</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={saveUser} className="btn btn-primary">
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    <img src={loadings} width={100} alt="Animated GIF" />
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Profile Details</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      User Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="channel_name"
                        name="channel_name"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.channel_name}
                        onKeyDown={handleInputKeyDownchannel_name}
                        onBlur={handleBlurchannel_name} // Add onBlur event handler
                        ref={channel_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenchannel_name && (
                        <p>{validationMessagechannel_name}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">User Slug</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="channel_slug"
                        name="channel_slug"
                        placeholder=""
                        value={editUser?.channel_slug}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      User Email Address<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        value={editUser?.email}
                        onChange={handleInput}
                        ref={emailInputRef}
                      />
                    </div>

                    <span className="errorred">
                      {!isValidationHiddenemail && (
                        <p>{validationMessageemail}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      User Mobile Number<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <div className="row">
                        <div className="col-sm-4">
                          <select
                            className="form-select"
                            name="ccode"
                            id="ccode"
                            value={editUser?.ccode}
                            onChange={handleInput}
                            ref={ccodeInputRef}
                          >
                            {number?.map((item) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.country_name} ({item?.phonecode})
                              </option>
                            ))}
                          </select>

                          <span className="errorred">
                            {!isValidationHiddenccode && (
                              <p>{validationMessageccode}</p>
                            )}
                          </span>
                        </div>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            style={{ height: "50px" }}
                            className="rs-input form-control-lg"
                            id="mobile_number"
                            name="mobile_number"
                            placeholder="Enter Mobile"
                            value={editUser?.mobile_number}
                            onChange={handleInput}
                            ref={mobile_numberInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddenmobile_number && (
                            <p>{validationMessagemobile_number}</p>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        User Password<span className="mandatory">*</span>
                      </label>

                      <div className="mt-2">
                        <span
                          className="eyepassworduser"
                          aria-hidden="true"
                          onClick={togglePassword}
                        >
                          {" "}
                          {passwordType === "password" ? (
                            <i className="fa fa-eye-slash"></i>
                          ) : (
                            <i className="fa fa-eye"></i>
                          )}
                        </span>

                        <input
                          type={passwordType}
                          autoComplete="off"
                          className="rs-input form-control-lg"
                          id="password"
                          name="password"
                          placeholder=""
                          // value={editUser?.password}
                          onChange={handleInput}
                          ref={passwordInputRef}
                        />
                        <span className="errorred">
                          {!isValidationHiddenpassword && (
                            <p>{validationMessagepassword}</p>
                          )}
                        </span>
                      </div>

                      {/* <div className="mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg"
                          id="password"  
                          name="password"
                          placeholder="Enter Email"
                          value={editUser?.password}
                          onChange={handleInput}
                          ref={passwordInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenpassword && (
                          <p>{validationMessagepassword}</p>
                        )}
                      </span> */}
                    </div>
                  </div>

                  <div className="text-start mt-2">
                    <label className="m-0">Channel About</label>

                    <div className="mt-2">
                      <div className="col-lg-12">
                        <div className="mt-2">
                          <JoditEditor
                            className="rs-input"
                            value={editUser?.channel_about}
                            onChange={(e) => {
                              handleChangeFirstchannel_about(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Profile Image</label>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.channel_image_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Banner Image</label>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.channel_banner_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Channel Logo</label>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.channel_logo_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={saveUser} className="btn btn-primary">
                    Update Profile
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Bank Details</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">Bank Name</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="bank_name"
                        name="bank_name"
                        placeholder=""
                        value={editUser?.bank_name}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Branch Name</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="branch_name"
                        name="branch_name"
                        placeholder=""
                        value={editUser?.branch_name}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Account Number</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="account_number"
                        name="account_number"
                        placeholder=""
                        value={editUser?.account_number}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">IFSC Code</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="IFSC_Code"
                        name="IFSC_Code"
                        placeholder=""
                        value={editUser?.IFSC_Code}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">UPI ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="upi_id"
                        name="upi_id"
                        placeholder=""
                        value={editUser?.upi_id}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">UPI Mobile Number</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="upi_mobile_number"
                        name="upi_mobile_number"
                        placeholder=""
                        value={editUser?.upi_mobile_number}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Cheque Details</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Cancelled Cheque</label>

                    <div>
                      {!loading && editUser?.cancelled_cheque_url !== null && (
                        <img
                          src={editUser?.cancelled_cheque_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile4)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5> Intro Video</h5>
                  <hr></hr>

                  <div className="">
                    {loading ? (
                      <img
                        src={loadings}
                        className="thumbnails-images"
                        alt="Loading"
                      />
                    ) : (
                      <div>
                        <div>
                          <div className="col-lg-12 row">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                              {/* Assuming this is a custom media player component */}
                              <media-player
                                title={editUser?.intro_video_url}
                                src={editUser?.intro_video_url}
                                poster=""
                                controls
                              >
                                <media-outlet></media-outlet>
                              </media-player>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-2 file-draganddrop">
                      <Dropzone
                        onDrop={handleDropintro}
                        accept="video/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p className="p-2">
                              Drag and drop Intro videos here
                            </p>
                            {videosintro?.length > 0 && (
                              <p>
                                {videosintro?.length}{" "}
                                {videosintro?.length === 1 ? "file" : "files"}{" "}
                                selected
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="col-sm-12 row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this User Status</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="status"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={editUser?.status == 1 ? true : false}
                          checked={editUser?.status == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="status"
                          onChange={handleInputenable}
                          value={editUser?.status == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this User Activation Code</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="activation_code"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.activation_code == 1 ? true : false
                          }
                          checked={
                            editUser?.activation_code == 1 ? true : false
                          }
                          className="rs-input"
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="activation_code"
                          onChange={handleInputenable}
                          value={editUser?.activation_code == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
