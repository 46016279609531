// import { Route, Routes } from "react-router-dom";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MainPage from "./components/MainPage/MainPage";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";
import Myprofile from "./layout/My_Profile/Myprofile";

import Allvideo from "./Pages/Video/Video_Management/Allvideo";
import Videoforapproval from "./Pages/Video/Video_Management/Videoforapproval";
import Videochannelpartner from "./Pages/Video/Video_Management/Videochannelpartner";
import Videocontentpartner from "./Pages/Video/Video_Management/Videocontentpartner";
import Mastervideolist from "./Pages/Video/Video_Management/Mastervideolist";
import Videoschedule from "./Pages/Video/Video_Management/Videoschedule";
import AddVideoschedule from "./Pages/Video/Video_Management/AddVideoschedule";
import EditVideoschedule from "./Pages/Video/Video_Management/EditVideoschedule";
import Manageschedule from "./Pages/Video/Video_Management/Manageschedule";
import Videopartner from "./Pages/Video/Video_Management/Videopartner";
import Managevideocategories from "./Pages/Video/Video_Management/Managevideocategories";
import AddManagevideocategories from "./Pages/Video/Video_Management/AddManagevideocategories";
import EditManagevideocategories from "./Pages/Video/Video_Management/EditManagevideocategories";
import Addnewvideo from "./Pages/Video/Video_Management/Addnewvideo";
import EditVideotag from "./Pages/Video/Video_Management/EditVideotag";

import AddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/AddNewLiveStream";
import AllLiveStream from "./Pages/Live Stream/Management_Live_Stream/AllLiveStream";
import LiveStreamApproval from "./Pages/Live Stream/Management_Live_Stream/LiveStreamApproval";
import Livecontentpartner from "./Pages/Live Stream/Management_Live_Stream/Livecontentpartner";
import Livechannelpartner from "./Pages/Live Stream/Management_Live_Stream/Livechannelpartner";
import ManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/ManageLiveCategories";
import AddManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/AddManageLiveCategories";
import EditManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/EditManageLiveCategories";
import LiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/LiveEventArtist";
import AddLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/AddLiveEventArtist";
import EditLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/EditLiveEventArtist";
import SeriesList from "./Pages/Video/Series&Episode/SeriesList";
import Addnewseries from "./Pages/Video/Series&Episode/Addnewseries";
import ManageSeries from "./Pages/Video/Series&Episode/ManageSeries";
import AddManageSeries from "./Pages/Video/Series&Episode/AddManageSeries";
import EditManageseriesgenre from "./Pages/Video/Series&Episode/EditManageseriesgenre";
import SeriesApproval from "./Pages/Video/Series&Episode/SeriesApproval";
import Editseason from "./Pages/Video/Series&Episode/Editseason";
import EditSeriesList from "./Pages/Video/Series&Episode/EditSeriesList";
import Editvideomanage from "./Pages/Video/Series&Episode/Editvideomanage";
import EditEpisodemanage from "./Pages/Video/Series&Episode/EditEpisodemanage";
import EditEpisodeVideo from "./Pages/Video/Series&Episode/EditEpisodeVideo";
import Addepisode from "./Pages/Video/Series&Episode/Addepisode";
import Manageepisode from "./Pages/Video/Series&Episode/Manageepisode";
import AddSeason from "./Pages/Video/Series&Episode/AddSeason";
import SeriesContentPartner from "./Pages/Video/Series&Episode/SeriesContentPartner";
import SeriesChannelPartner from "./Pages/Video/Series&Episode/SeriesChannelPartner";

import AudioList from "./Pages/Audio/Audio_Management/AudioList";
import Addnewaudio from "./Pages/Audio/Audio_Management/Addnewaudio";
import AudioApproval from "./Pages/Audio/Audio_Management/AudioApproval";
import Audiocontentpartner from "./Pages/Audio/Audio_Management/Audiocontentpartner";
import Audiochannelpartner from "./Pages/Audio/Audio_Management/Audiochannelpartner";
import Editnewaudio from "./Pages/Audio/Audio_Management/Editnewaudio";
import Manageaudiocategories from "./Pages/Audio/Audio_Management/Manageaudiocategories";
import Managealbum from "./Pages/Audio/Audio_Management/Managealbum";
import AllArtist from "./Pages/Audio/Artist_Management/AllArtist";
import NewArtist from "./Pages/Audio/Artist_Management/NewArtist";
import EditNewArtist from "./Pages/Audio/Artist_Management/EditNewArtist";
import Addcategory from "./Pages/Audio/Audio_Management/Addcategory";
import Editcategory from "./Pages/Audio/Audio_Management/Editcategory";
import Addalbum from "./Pages/Audio/Audio_Management/Addalbum";
import Editalbum from "./Pages/Audio/Audio_Management/Editalbum";

import AllUser from "./Pages/Account/User/AllUser";
import AddUser from "./Pages/Account/User/AddUser";
import EditUser from "./Pages/Account/User/EditUser";
import ImportUser from "./Pages/Account/User/ImportUser";
import MultiUserManagement from "./Pages/Account/User/MultiUserManagement";
import Menu from "./Pages/Account/Menu/Menu";
import AddMenu from "./Pages/Account/Menu/AddMenu";
import EditMenu from "./Pages/Account/Menu/EditMenu";
import FooterMenu from "./Pages/Account/Menu/FooterMenu";
import AddFooterMenu from "./Pages/Account/Menu/AddFooterMenu";
import EditFooterMenu from "./Pages/Account/Menu/EditFooterMenu";
import MobileMenu from "./Pages/Account/Menu/MobileMenu";
import AddMobileMenu from "./Pages/Account/Menu/AddMobileMenu";
import EditMobileMenu from "./Pages/Account/Menu/EditMobileMenu";
import SignupMenu from "./Pages/Account/SignupMenu/SignupMenu";
import FileManager from "./Pages/Account/FileManager/FileManager";
import Slider from "./Pages/Language/Slider/Slider";
import AddSlider from "./Pages/Language/Slider/AddSlider";
import EditSlider from "./Pages/Language/Slider/EditSlider";
import VideoLanguage from "./Pages/Language/Manage_Language/VideoLanguage";
import SubtitleLanguage from "./Pages/Language/Manage_Language/SubtitleLanguage";
import EditVideoLanguage from "./Pages/Language/Manage_Language/EditVideoLanguage";
import ManageTranslation from "./Pages/Language/Manage_Language/ManageTranslation";
import ManageTranslationLanguage from "./Pages/Language/Manage_Language/ManageTranslationLanguage";
import EditSubtitleLanguage from "./Pages/Language/Manage_Language/EditSubtitleLanguage";

import Player from "./Pages/Site/Player_UI/Player";

import AddContentPartner from "./Pages/Site/Content_Partner/AddContentPartner";
import ViewContentPartner from "./Pages/Site/Content_Partner/ViewContentPartner";
import EditContentPartner from "./Pages/Site/Content_Partner/EditContentPartner";
import ContentPartnerApproval from "./Pages/Site/Content_Partner/ContentPartnerApproval";
import ContentAddRole from "./Pages/Site/Content_Partner/ContentAddRole";
import ContentPartnerView from "./Pages/Site/Content_Partner/ContentPartnerView";
import EditContentPartnerView from "./Pages/Site/Content_Partner/EditContentPartnerView";
import ContentCommission from "./Pages/Site/Content_Partner/ContentCommission";
import ContentPayout from "./Pages/Site/Content_Partner/ContentPayout";

import ChannelPartnerApproval from "./Pages/Site/Channel_Partner/ChannelPartnerApproval";
import ChannelCommission from "./Pages/Site/Channel_Partner/ChannelCommission";
import ChannelPayout from "./Pages/Site/Channel_Partner/ChannelPayout";
import ChannelPackage from "./Pages/Site/Channel_Partner/ChannelPackage";
import AddChannelPackage from "./Pages/Site/Channel_Partner/AddChannelPackage";
import AllPages from "./Pages/Site/Site_Pages/AllPages";
import AddPages from "./Pages/Site/Site_Pages/AddPages";
import EditPages from "./Pages/Site/Site_Pages/EditPages";
import CreateLandingPages from "./Pages/Site/Site_Pages/CreateLandingPages";
import LandingPages from "./Pages/Site/Site_Pages/LandingPages";
import AddLandingPages from "./Pages/Site/Site_Pages/AddLandingPages";
import EditLandingPages from "./Pages/Site/Site_Pages/EditLandingPages";
import SubscriptionPlan from "./Pages/Site/Plans/SubscriptionPlan";
import AddSubscriptionPlan from "./Pages/Site/Plans/AddSubscriptionPlan";
import EditSubscriptionPlan from "./Pages/Site/Plans/EditSubscriptionPlan";
import PurchasePlan from "./Pages/Site/Plans/PurchasePlan";
import EditPurchasePlan from "./Pages/Site/Plans/EditPurchasePlan";
import LifeTimeSubcription from "./Pages/Site/Plans/LifeTimeSubcription";
import Devices from "./Pages/Site/Plans/Devices";
import EditDevices from "./Pages/Site/Plans/EditDevices";
import TotalRevenue from "./Pages/Site/Payment_Management/TotalRevenue";
import SubcriptionPayment from "./Pages/Site/Payment_Management/SubcriptionPayment";
import ViewSubcription from "./Pages/Site/Payment_Management/ViewSubcription";
import PayperviewPayments from "./Pages/Site/Payment_Management/PayperviewPayments";
import ViewPayperview from "./Pages/Site/Payment_Management/ViewPayperview";
import UsersRevenue from "./Pages/Site/Analytics/UsersRevenue";
import UsersAnalytics from "./Pages/Site/Analytics/UsersAnalytics";
import PurchaseVideoAnalytics from "./Pages/Site/Analytics/PurchaseVideoAnalytics";
import CPPAnalytics from "./Pages/Site/Analytics/CPPAnalytics";
import CPPVideoAnalytics from "./Pages/Site/Analytics/CPPVideoAnalytics";
import CPPRevenue from "./Pages/Site/Analytics/CPPRevenue";
import ViewRegion from "./Pages/Site/Analytics/ViewRegion";
import RevenueRegion from "./Pages/Site/Analytics/RevenueRegion";
import PlayerVideoAnalytics from "./Pages/Site/Analytics/PlayerVideoAnalytics";
import RegionVideoAnalytics from "./Pages/Site/Analytics/RegionVideoAnalytics";
import PlayerUserAnalytics from "./Pages/Site/Analytics/PlayerUserAnalytics";
import CPPLiveVideo from "./Pages/Site/Analytics/CPPLiveVideo";
import LiveStreamAnalytics from "./Pages/Site/Analytics/LiveStreamAnalytics";
import StorefrontSettings from "./Pages/Site/Settings/StorefrontSettings";
import HomePageSettings from "./Pages/Site/Settings/HomePageSettings";
import Editorderhome from "./Pages/Site/Settings/Editorderhome";
import LinkSettings from "./Pages/Site/Settings/LinkSettings";
import ManageAge from "./Pages/Site/Settings/ManageAge";
import AddManageAge from "./Pages/Site/Settings/AddManageAge";
import EditManageAge from "./Pages/Site/Settings/EditManageAge";
import ThemeSettings from "./Pages/Site/Settings/ThemeSettings";
import EmailSettings from "./Pages/Site/Settings/EmailSettings";
import PaymentSettings from "./Pages/Site/Settings/PaymentSettings";
import EmailSettingsTemplate from "./Pages/Site/Settings/EmailSettingsTemplate";
import StorageSettings from "./Pages/Site/Settings/StorageSettings";
import MobileSettings from "./Pages/Site/Settings/MobileSettings";
import EditSplashMobileSettings from "./Pages/Site/Settings/EditSplashMobileSettings";
import EditMobileSettings from "./Pages/Site/Settings/EditMobileSettings";
import SocialLoginSettings from "./Pages/Site/Settings/SocialLoginSettings";
import CurrencySettings from "./Pages/Site/Settings/CurrencySettings";
import RevenueSettings from "./Pages/Site/Settings/RevenueSettings";
import ThumbnailSettings from "./Pages/Site/Settings/ThumbnailSettings";
import ProfileScreen from "./Pages/Site/Settings/ProfileScreen";
import ViewChannelPartner from "./Pages/Site/Channel_Partner/ViewChannelPartner";
import Theme from "./Pages/Site/Settings/Theme";
import ImageSettings from "./Pages/Site/Settings/ImageSettings";
import PopupSettings from "./Pages/Site/Settings/PopupSettings";
import CommentSetting from "./Pages/Site/Settings/CommentSetting";
import Advertiser from "./Pages/Ads_Categories/Manage_Advertiser/Advertiser";
import EditAdvertiser from "./Pages/Ads_Categories/Manage_Advertiser/EditAdvertiser";
import EditAdsList from "./Pages/Ads_Categories/Ads_List/EditAdsList";
import AdsCategories from "./Pages/Ads_Categories/Ads_Categories/AdsCategories";
import EditAdsCategories from "./Pages/Ads_Categories/Ads_Categories/EditAdsCategories";
import AdsList from "./Pages/Ads_Categories/Ads_List/AdsList";
import AdsPlan from "./Pages/Ads_Categories/Ads_Plan/AdsPlan";
import AdsRevenue from "./Pages/Ads_Categories/Ads_Revenue/AdsRevenue";
import CalenderEvent from "./Pages/Ads_Categories/Calender_Event/CalenderEvent";
import AdsCampaigns from "./Pages/Ads_Categories/Ads_Campaigns/AdsCampaigns";
import AdsTimeSlot from "./Pages/Ads_Categories/Ads_Time_Slot/AdsTimeSlot";
import ManageGeoFencing from "./Pages/Geo_Fencing/Manage_Geo_Fencing/ManageGeoFencing";
import ManageCountry from "./Pages/Geo_Fencing/Manage_Country/ManageCountry";
import CacheManagement from "./Pages/Configuration/Cache_Management/CacheManagement";
import Debug from "./Pages/Configuration/Manage_Geo_Fencing/Debug";
import ContactRequest from "./Pages/ContactUs/Cache Management/ContactRequest";
import SeeLogActivity from "./Pages/LogActivity/Cache_Management/SeeLogActivity";
import Editvideo from "./Pages/Video/Video_Management/Editvideo";
import EditLiveStream from "./Pages/Live Stream/Management_Live_Stream/EditLiveStream";
import EditAddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/EditAddNewLiveStream";
import UpdateCurrencySettings from "./Pages/Site/Settings/UpdateCurrencySettings";
import ViewTemplate from "./Pages/Site/Settings/ViewTemplate";

import AllVideos from "./Channel_Partner/VideoManagement/AllVideos";
import AddNewVideo from "./Channel_Partner/VideoManagement/AddNewVideo";
import ManageVideoCategories from "./Channel_Partner/VideoManagement/ManageVideoCategories";
import Payouts from "./Channel_Partner/Analytics/Payouts";
import VideoAnalytics from "./Channel_Partner/Analytics/VideoAnalytics";
import VideoViewRegion from "./Channel_Partner/Analytics/VideoViewRegion";
import MyChannelSettings from "./Channel_Partner/SettingManage/MyChannelSettings";
import AllLiveVideo from "./Channel_Partner/ManageLiveVideo/AllLiveVideo";
import AddNewLiveVideo from "./Channel_Partner/ManageLiveVideo/AddNewLiveVideo";
import EditNewLiveVideo from "./Channel_Partner/ManageLiveVideo/EditNewLiveVideo";
import ManageLiveVideoCategories from "./Channel_Partner/ManageLiveVideo/ManageLiveVideoCategories";
import AddManageCategories from "./Channel_Partner/ManageLiveVideo/AddManageCategories";
import EditManageCategories from "./Channel_Partner/ManageLiveVideo/EditManageCategories";
import ManageLiveEvent from "./Channel_Partner/ManageLiveVideo/ManageLiveEvent";
import AddManageLiveEvent from "./Channel_Partner/ManageLiveVideo/AddManageLiveEvent";
import EditManageLiveEvent from "./Channel_Partner/ManageLiveVideo/EditManageLiveEvent";
import AudioLists from "./Channel_Partner/AudioManagement/AudioLists";
import ManageAudioCategories from "./Channel_Partner/AudioManagement/ManageAudioCategories";
import AddCategory from "./Channel_Partner/AudioManagement/AddCategory";
import ManageAlbums from "./Channel_Partner/AudioManagement/ManageAlbums";
import Addalbums from "./Channel_Partner/AudioManagement/Addalbums";
import SeriesLists from "./Channel_Partner/Series&Episode/SeriesLists";
import AddNewSeries from "./Channel_Partner/Series&Episode/AddNewSeries";
import EditNewSeries from "./Channel_Partner/Series&Episode/EditNewSeries";
import Edit_seriesseason from "./Channel_Partner/Series&Episode/Edit_seriesseason";
import Manage_Episode from "./Channel_Partner/Series&Episode/Manage_Episode";
import Edit_Episode_manage from "./Channel_Partner/Series&Episode/Edit_Episode_manage";
import Edit_Episode_Video from "./Channel_Partner/Series&Episode/Edit_Episode_Video";
import AddManageVideoCategory from "./Channel_Partner/VideoManagement/AddManageVideoCategory";
import EditManageVideoCategory from "./Channel_Partner/VideoManagement/EditManageVideoCategory";
import AddnewAudios from "./Channel_Partner/AudioManagement/AddnewAudios";
import EditNewVideo from "./Channel_Partner/VideoManagement/EditNewVideo";
import Edit_album from "./Channel_Partner/AudioManagement/Edit_album";
import Edit_category from "./Channel_Partner/AudioManagement/Edit_category";
import Edit_new_audio from "./Channel_Partner/AudioManagement/Edit_new_audio";
import AboutMyChannel from "./Channel_Partner/SettingManage/AboutMyChannel";
import Edit_SingleAudio from "./Channel_Partner/AudioManagement/Edit_SingleAudio";

import ChannelPage from "./Channel_Partner/ChannelPage";
import DashBoards from "./Channel_Partner/DashBoard/DashBoards";


import All_Videos from "./Content_Partner/VideoManagement/All_Videos";
import Add_NewVideos from "./Content_Partner/VideoManagement/Add_NewVideos";
import Manage_VideoCategories from "./Content_Partner/VideoManagement/Manage_VideoCategories";
import AddManage_VideoCategories from "./Content_Partner/VideoManagement/AddManage_VideoCategories";
import Audio_Lists from "./Content_Partner/AudioManagement/Audio_Lists";
import Add_NewAudios from "./Content_Partner/AudioManagement/Add_NewAudios";
import Manage_AudioCategories from "./Content_Partner/AudioManagement/Manage_AudioCategories";
import Manage_Albums from "./Content_Partner/AudioManagement/Manage_Albums";
import Add_category from "./Content_Partner/AudioManagement/Add_category";
import Add_Albums from "./Content_Partner/AudioManagement/Add_Albums";
import All_LiveVideo from "./Content_Partner/ManageLiveVideo/All_LiveVideo";
import Add_NewLiveVideo from "./Content_Partner/ManageLiveVideo/Add_NewLiveVideo";
import Manage_LiveCategory from "./Content_Partner/ManageLiveVideo/Manage_LiveCategory";
import Add_manageLiveCategory from "./Content_Partner/ManageLiveVideo/Add_manageLiveCategory";
import Manage_LiveEvent from "./Content_Partner/ManageLiveVideo/Manage_LiveEvent";
import Add_ManageLiveEvent from "./Content_Partner/ManageLiveVideo/Add_ManageLiveEvent";
import Video_Analytics from "./Content_Partner/Analytics/Video_Analytics";
import Video_ViewRegion from "./Content_Partner/Analytics/Video_ViewRegion";
import Video_Payout from "./Content_Partner/Analytics/Video_Payout";
import Live_StreamPayout from "./Content_Partner/Analytics/Live_StreamPayout";
import All_pages from "./Content_Partner/Pages/All_pages";
import Add_pages from "./Content_Partner/Pages/Add_pages";
import Dash_Board from "./Content_Partner/Dash_Board/Dash_Board";
import About_MyProfile from "./Content_Partner/Profile/About_MyProfile";

import Error404 from "./Error404/error404";
import Maintances from "./Error404/Maintances";

function AllRoutes(props) {
  const [loginData, setLoginData] = useState(null);

  const [apiresponsealert, setApiresponsealert] = useState();
  const [showalert, setShowalert] = useState();

  useEffect(() => {
    const userData = localStorage.getItem("access_token");
    setLoginData(userData);
  }, []);
  // console.log(loginData, "LoginData");

  return (
    <>
      {loginData === null ? (
        <Router>
          <Suspense
            fallback={
              <div className="text-white text-center mt-3">Loading...</div>
            }
            setApiresponsealert={setApiresponsealert}
            setShowalert={setShowalert}
          >
            <Routes>
              <Route
                path="/admin"
                element={
                  <MainPage
                    setApiresponsealert={setApiresponsealert}
                    setShowalert={setShowalert}
                  ></MainPage>
                }
              >
                {" "}
              </Route>
              <Route
                path="/"
                element={
                  <Signin
                    setApiresponsealert={setApiresponsealert}
                    setShowalert={setShowalert}
                  ></Signin>
                }
              >
                {" "}
              </Route>
              <Route
                path="/signup"
                element={
                  <Signup
                    setApiresponsealert={setApiresponsealert}
                    setShowalert={setShowalert}
                  ></Signup>
                }
              >
                {" "}
              </Route>
              <Route
                path="/maintances"
                element={
                  <Maintances
                    setApiresponsealert={setApiresponsealert}
                    setShowalert={setShowalert}
                  ></Maintances>
                }
              >
                {" "}
              </Route>
              {/* <Route path="*" element={<Error404 setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}></Error404>}> </Route> */}
            </Routes>
          </Suspense>
        </Router>
      ) : (
        <Router>
          <Layout
           apiresponsealert={apiresponsealert}
           showalert={showalert}
           setApiresponsealert={setApiresponsealert}
           setShowalert={setShowalert}
           >
            <Suspense
              fallback={
                <div className="text-white text-center mt-3">Loading...</div>
              }
              setApiresponsealert={setApiresponsealert}
              setShowalert={setShowalert}
            >
              <Routes>
                {/* ChannelPartner */}
                <Route
                  path="/channel/home"
                  element={
                    <DashBoards
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></DashBoards>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="/channel/my-profile"
                  element={
                    <Myprofile
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Myprofile>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/allvideos"
                  element={
                    <AllVideos
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllVideos>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-new-videos"
                  element={
                    <AddNewVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddNewVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/video-categories"
                  element={
                    <ManageVideoCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageVideoCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-video-category"
                  element={
                    <AddManageVideoCategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageVideoCategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-video/:id"
                  element={
                    <EditNewVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditNewVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-video-category/:id"
                  element={
                    <EditManageVideoCategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageVideoCategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/series-lists"
                  element={
                    <SeriesLists
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeriesLists>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-series/:id"
                  element={
                    <EditNewSeries
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditNewSeries>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-new-series"
                  element={
                    <AddNewSeries
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddNewSeries>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-season/:id"
                  element={
                    <Edit_seriesseason
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_seriesseason>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/manage-episode/:seriesid/:id"
                  element={
                    <Manage_Episode
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_Episode>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-episode/manage/:id"
                  element={
                    <Edit_Episode_manage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_Episode_manage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-episode/video-manage/:id"
                  element={
                    <Edit_Episode_Video
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_Episode_Video>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/audio-lists"
                  element={
                    <AudioLists
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AudioLists>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-new-audio"
                  element={
                    <AddnewAudios
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddnewAudios>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/manage-audio-category"
                  element={
                    <ManageAudioCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageAudioCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-category"
                  element={
                    <AddCategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddCategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/manage-albums"
                  element={
                    <ManageAlbums
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageAlbums>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-albums"
                  element={
                    <Addalbums
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addalbums>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-albums/:id"
                  element={
                    <Edit_album
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_album>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-category/:id"
                  element={
                    <Edit_category
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_category>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-audio/:id"
                  element={
                    <Edit_new_audio
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_new_audio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/audio/edit/:id"
                  element={
                    <Edit_SingleAudio
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Edit_SingleAudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/all-livevideos"
                  element={
                    <AllLiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllLiveVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-livestream"
                  element={
                    <AddNewLiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddNewLiveVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-livestream/:id"
                  element={
                    <EditNewLiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditNewLiveVideo>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="/channel/manage-live-category"
                  element={
                    <ManageLiveVideoCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageLiveVideoCategories>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="/channel/add-manage-live-category"
                  element={
                    <AddManageCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-live-category/:id"
                  element={
                    <EditManageCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageCategories>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="/channel/manage-live-event"
                  element={
                    <ManageLiveEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageLiveEvent>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/add-manage-live-event"
                  element={
                    <AddManageLiveEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageLiveEvent>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/edit-live-event/:id"
                  element={
                    <EditManageLiveEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageLiveEvent>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="/channel/mychannel-setting"
                  element={
                    <MyChannelSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></MyChannelSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/about-my-channel"
                  element={
                    <AboutMyChannel
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AboutMyChannel>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/video-analytics"
                  element={
                    <VideoAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></VideoAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/video-region"
                  element={
                    <VideoViewRegion
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></VideoViewRegion>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/channel/payouts"
                  element={
                    <Payouts
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Payouts>
                  }
                >
                  {" "}
                </Route>

                {/* ContentPartner */}
                <Route
                  path="/cpp/home"
                  element={
                    <Dash_Board
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Dash_Board>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/all-videos"
                  element={
                    <All_Videos
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></All_Videos>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-newvideos"
                  element={
                    <Add_NewVideos
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_NewVideos>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/manage-video-category"
                  element={
                    <Manage_VideoCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_VideoCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-manage-video-category"
                  element={
                    <AddManage_VideoCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManage_VideoCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/audio-lists"
                  element={
                    <Audio_Lists
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Audio_Lists>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-newaudio-lists"
                  element={
                    <Add_NewAudios
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_NewAudios>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/manage-audio-category"
                  element={
                    <Manage_AudioCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_AudioCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-category"
                  element={
                    <Add_category
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_category>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/manage-albums"
                  element={
                    <Manage_Albums
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_Albums>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-manage-albums"
                  element={
                    <Add_Albums
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_Albums>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/all-livevideo"
                  element={
                    <All_LiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></All_LiveVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-new-livevideo"
                  element={
                    <Add_NewLiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_NewLiveVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/manage-live-videocategory"
                  element={
                    <Manage_LiveCategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_LiveCategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-manage-live-category"
                  element={
                    <Add_manageLiveCategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_manageLiveCategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/manage-live-event"
                  element={
                    <Manage_LiveEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manage_LiveEvent>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-manage-live-event"
                  element={
                    <Add_ManageLiveEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_ManageLiveEvent>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/video-analytics"
                  element={
                    <Video_Analytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Video_Analytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/video-view-region"
                  element={
                    <Video_ViewRegion
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Video_ViewRegion>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/video-payout"
                  element={
                    <Video_Payout
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Video_Payout>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/live-stream-payout"
                  element={
                    <Live_StreamPayout
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Live_StreamPayout>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/all-pages"
                  element={
                    <All_pages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></All_pages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/add-pages"
                  element={
                    <Add_pages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Add_pages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/cpp/about-myprofile"
                  element={
                    <About_MyProfile
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></About_MyProfile>
                  }
                >
                  {" "}
                </Route>

                {/* video */}
                {/* <Route path="" element={<Dashboard setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}></Dashboard>}> </Route> */}
                <Route
                  path="allvideo"
                  element={
                    <Allvideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Allvideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addnewvideo"
                  element={
                    <Addnewvideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addnewvideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="videos/editvideo/:id"
                  element={
                    <EditVideotag
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditVideotag>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editvideo/:id"
                  element={
                    <Editvideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editvideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-for-approval"
                  element={
                    <Videoforapproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Videoforapproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-channel-partner"
                  element={
                    <Videochannelpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Videochannelpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-content-partner"
                  element={
                    <Videocontentpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Videocontentpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="master-list"
                  element={
                    <Mastervideolist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Mastervideolist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-schedule"
                  element={
                    <Videoschedule
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Videoschedule>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-schedule"
                  element={
                    <AddVideoschedule
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddVideoschedule>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-schedule/:id"
                  element={
                    <EditVideoschedule
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditVideoschedule>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="manage-schedule/:id"
                  element={
                    <Manageschedule
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manageschedule>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-partner"
                  element={
                    <Videopartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Videopartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video-categories"
                  element={
                    <Managevideocategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Managevideocategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-categories"
                  element={
                    <AddManagevideocategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManagevideocategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-categories/:id"
                  element={
                    <EditManagevideocategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManagevideocategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="series-list"
                  element={
                    <SeriesList
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeriesList>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-series"
                  element={
                    <Addnewseries
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addnewseries>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-series/:id"
                  element={
                    <EditSeriesList
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditSeriesList>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-video/manage"
                  element={
                    <Editvideomanage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editvideomanage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-episode/manage/:id"
                  element={
                    <EditEpisodemanage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditEpisodemanage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-episode/video-manage/:id"
                  element={
                    <EditEpisodeVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditEpisodeVideo>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="add-episode"
                  element={
                    <Addepisode
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addepisode>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="manage-episode/:seriesid/:id"
                  element={
                    <Manageepisode
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manageepisode>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-season/:id"
                  element={
                    <Editseason
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editseason>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="Series-Genre"
                  element={
                    <ManageSeries
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageSeries>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-Genre"
                  element={
                    <AddManageSeries
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageSeries>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-genre/:id"
                  element={
                    <EditManageseriesgenre
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageseriesgenre>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="Add-season"
                  element={
                    <AddSeason
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddSeason>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="CPPSeriesIndex"
                  element={
                    <SeriesApproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeriesApproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="series-content-partner"
                  element={
                    <SeriesContentPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeriesContentPartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="series-channel-partner"
                  element={
                    <SeriesChannelPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeriesChannelPartner>
                  }
                >
                  {" "}
                </Route>
                {/* Live stream */}
                <Route
                  path="livestream"
                  element={
                    <AllLiveStream
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllLiveStream>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-livestream"
                  element={
                    <AddNewLiveStream
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddNewLiveStream>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="CPPLiveVideosIndex"
                  element={
                    <LiveStreamApproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LiveStreamApproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="live-content-partner"
                  element={
                    <Livecontentpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Livecontentpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="live-channel-partner"
                  element={
                    <Livechannelpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Livechannelpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="livestream-categories"
                  element={
                    <ManageLiveCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageLiveCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="livestream-add-categories"
                  element={
                    <AddManageLiveCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageLiveCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="livestream-edit-categories/:id"
                  element={
                    <EditManageLiveCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageLiveCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="live-event-artist"
                  element={
                    <LiveEventArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LiveEventArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-live-event-artist"
                  element={
                    <AddLiveEventArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddLiveEventArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="live-event-artist-edit/:id"
                  element={
                    <EditLiveEventArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditLiveEventArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editlivestream/:id"
                  element={
                    <EditLiveStream
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditLiveStream>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit/add-livestream/:id"
                  element={
                    <EditAddNewLiveStream
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditAddNewLiveStream>
                  }
                >
                  {" "}
                </Route>
                {/* Audio */}
                <Route
                  path="audio-list"
                  element={
                    <AudioList
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AudioList>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-audio/:id"
                  element={
                    <Editnewaudio
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editnewaudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addnewaudio"
                  element={
                    <Addnewaudio
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addnewaudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="audio-approval"
                  element={
                    <AudioApproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AudioApproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="audio-content-partner"
                  element={
                    <Audiocontentpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Audiocontentpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="audio-channel-partner"
                  element={
                    <Audiochannelpartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Audiochannelpartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-audio-categories"
                  element={
                    <Addcategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addcategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-audio-categories/:id"
                  element={
                    <Editcategory
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editcategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="audio-categories"
                  element={
                    <Manageaudiocategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Manageaudiocategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="manage-albums"
                  element={
                    <Managealbum
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Managealbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-albums"
                  element={
                    <Addalbum
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Addalbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-albums/:id"
                  element={
                    <Editalbum
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editalbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="all-artist"
                  element={
                    <AllArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="new-artist"
                  element={
                    <NewArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></NewArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-artist/:id"
                  element={
                    <EditNewArtist
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditNewArtist>
                  }
                >
                  {" "}
                </Route>
                {/* Accounts */}
                <Route
                  path="users"
                  element={
                    <AllUser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllUser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-users"
                  element={
                    <AddUser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddUser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-users/:id"
                  element={
                    <EditUser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditUser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="import-users-view"
                  element={
                    <ImportUser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ImportUser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="MultiUser-limit"
                  element={
                    <MultiUserManagement
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></MultiUserManagement>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="menu"
                  element={
                    <Menu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Menu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addmenu"
                  element={
                    <AddMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editmenu/:id"
                  element={
                    <EditMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="footermenu"
                  element={
                    <FooterMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></FooterMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addfootermenu"
                  element={
                    <AddFooterMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddFooterMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editfootermenu/:id"
                  element={
                    <EditFooterMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditFooterMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="mobilemenu"
                  element={
                    <MobileMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></MobileMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addmobilemenu"
                  element={
                    <AddMobileMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddMobileMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editmobilemenu/:id"
                  element={
                    <EditMobileMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditMobileMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="signup"
                  element={
                    <SignupMenu
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SignupMenu>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="filemanager"
                  element={
                    <FileManager
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></FileManager>
                  }
                >
                  {" "}
                </Route>
                {/* Languages */}
                <Route
                  path="admin-languages"
                  element={
                    <VideoLanguage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></VideoLanguage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="admin-editlanguages/:id"
                  element={
                    <EditVideoLanguage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditVideoLanguage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="languages"
                  element={
                    <ManageTranslation
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageTranslation>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="subtitlelanguage"
                  element={
                    <SubtitleLanguage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SubtitleLanguage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-subtitlelanguage/:id"
                  element={
                    <EditSubtitleLanguage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditSubtitleLanguage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="admin-languages-transulates"
                  element={
                    <ManageTranslationLanguage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageTranslationLanguage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="sliders"
                  element={
                    <Slider
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Slider>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addslider"
                  element={
                    <AddSlider
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddSlider>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editslider/:id"
                  element={
                    <EditSlider
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditSlider>
                  }
                >
                  {" "}
                </Route>
                {/* Site */}
                <Route
                  path="players"
                  element={
                    <Player
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Player>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator"
                  element={
                    <AddContentPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddContentPartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="allmoderator"
                  element={
                    <ViewContentPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewContentPartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-allmoderator/:id"
                  element={
                    <EditContentPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditContentPartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="allmoderator-approval"
                  element={
                    <ContentPartnerApproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContentPartnerApproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator/role"
                  element={
                    <ContentAddRole
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContentAddRole>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator/view"
                  element={
                    <ContentPartnerView
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContentPartnerView>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator/view/edit/:id"
                  element={
                    <EditContentPartnerView
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditContentPartnerView>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator/commission"
                  element={
                    <ContentCommission
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContentCommission>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moderator/payouts"
                  element={
                    <ContentPayout
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContentPayout>
                  }
                >
                  {" "}
                </Route>
                {/* Ads Management */}
                <Route
                  path="view-channel-members"
                  element={
                    <ViewChannelPartner
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewChannelPartner>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="partnerapproval"
                  element={
                    <ChannelPartnerApproval
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ChannelPartnerApproval>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="commission"
                  element={
                    <ChannelCommission
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ChannelCommission>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payouts"
                  element={
                    <ChannelPayout
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ChannelPayout>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="channel-package-index"
                  element={
                    <ChannelPackage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ChannelPackage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-channel-package-index"
                  element={
                    <AddChannelPackage
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddChannelPackage>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="pages"
                  element={
                    <AllPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AllPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="addpages"
                  element={
                    <AddPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editpages/:id"
                  element={
                    <EditPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="landing-page/index"
                  element={
                    <LandingPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LandingPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-landing-page/index"
                  element={
                    <AddLandingPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddLandingPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-landing-page/:id"
                  element={
                    <EditLandingPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditLandingPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="landing-page/create"
                  element={
                    <CreateLandingPages
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CreateLandingPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="subscription-plans"
                  element={
                    <SubscriptionPlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SubscriptionPlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-subscription-plans"
                  element={
                    <AddSubscriptionPlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddSubscriptionPlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-subscription-plans/:id"
                  element={
                    <EditSubscriptionPlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditSubscriptionPlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="inapp-purchase"
                  element={
                    <PurchasePlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PurchasePlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-inapp-purchase/:id"
                  element={
                    <EditPurchasePlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditPurchasePlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="Life-time-subscription"
                  element={
                    <LifeTimeSubcription
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LifeTimeSubcription>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="devices"
                  element={
                    <Devices
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Devices>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-devices/:id"
                  element={
                    <EditDevices
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditDevices>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment/total_revenue"
                  element={
                    <TotalRevenue
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></TotalRevenue>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment/subscription"
                  element={
                    <SubcriptionPayment
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SubcriptionPayment>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment/subscription-view"
                  element={
                    <ViewSubcription
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewSubcription>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment/payPerView"
                  element={
                    <PayperviewPayments
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PayperviewPayments>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment/payPerView-view"
                  element={
                    <ViewPayperview
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewPayperview>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="analytics/revenue"
                  element={
                    <UsersAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></UsersAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="users/revenue"
                  element={
                    <UsersRevenue
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></UsersRevenue>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="video/purchased-analytics"
                  element={
                    <PurchaseVideoAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PurchaseVideoAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="cpp/analytics"
                  element={
                    <CPPAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CPPAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="cpp/video-analytics"
                  element={
                    <CPPVideoAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CPPVideoAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="cpp/revenue"
                  element={
                    <CPPRevenue
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CPPRevenue>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/ViewsRegion"
                  element={
                    <ViewRegion
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewRegion>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/RevenueRegion"
                  element={
                    <RevenueRegion
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></RevenueRegion>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/PlayerVideoAnalytics"
                  element={
                    <PlayerVideoAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PlayerVideoAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/RegionVideoAnalytics"
                  element={
                    <RegionVideoAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></RegionVideoAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/PlayerUserAnalytics"
                  element={
                    <PlayerUserAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PlayerUserAnalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="analytics/livestream-analytics"
                  element={
                    <CPPLiveVideo
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CPPLiveVideo>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="live/purchased-analytics"
                  element={
                    <LiveStreamAnalytics
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LiveStreamAnalytics>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="settings"
                  element={
                    <StorefrontSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></StorefrontSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="home-settings"
                  element={
                    <HomePageSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></HomePageSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-orderhome/:id"
                  element={
                    <Editorderhome
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Editorderhome>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="linking_settings"
                  element={
                    <LinkSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></LinkSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="age/index"
                  element={
                    <ManageAge
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageAge>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="add-age"
                  element={
                    <AddManageAge
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AddManageAge>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit/manage-age/:id"
                  element={
                    <EditManageAge
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditManageAge>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="theme_settings"
                  element={
                    <ThemeSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ThemeSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="email_settings"
                  element={
                    <EmailSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EmailSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="payment_settings"
                  element={
                    <PaymentSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PaymentSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit-template-email/:id"
                  element={
                    <EmailSettingsTemplate
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EmailSettingsTemplate>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="template-email/view/:id"
                  element={
                    <ViewTemplate
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ViewTemplate>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="storage_settings"
                  element={
                    <StorageSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></StorageSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="mobileapp"
                  element={
                    <MobileSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></MobileSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="mobileapp/splash/edit/:id"
                  element={
                    <EditSplashMobileSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditSplashMobileSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="mobileapp/edit/:id"
                  element={
                    <EditMobileSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditMobileSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="system_settings"
                  element={
                    <SocialLoginSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SocialLoginSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit_currency_settings"
                  element={
                    <CurrencySettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CurrencySettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="currency_settings"
                  element={
                    <CurrencySettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CurrencySettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="update_currency_settings/:id"
                  element={
                    <UpdateCurrencySettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></UpdateCurrencySettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="revenue_settings/index"
                  element={
                    <RevenueSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></RevenueSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ThumbnailSetting"
                  element={
                    <ThumbnailSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ThumbnailSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ChooseProfileScreen"
                  element={
                    <ProfileScreen
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ProfileScreen>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ThemeIntegration"
                  element={
                    <Theme
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Theme>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="compress-image-setting"
                  element={
                    <ImageSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ImageSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="pop-up-setting"
                  element={
                    <PopupSettings
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></PopupSettings>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="comment-section-setting"
                  element={
                    <CommentSetting
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CommentSetting>
                  }
                >
                  {" "}
                </Route>

                <Route
                  path="advertisers"
                  element={
                    <Advertiser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Advertiser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="editadvertiser/:id"
                  element={
                    <EditAdvertiser
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditAdvertiser>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit_ads_list/:id"
                  element={
                    <EditAdsList
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditAdsList>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads_categories"
                  element={
                    <AdsCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="edit_ads_categories/:id"
                  element={
                    <EditAdsCategories
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></EditAdsCategories>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads_list"
                  element={
                    <AdsList
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsList>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads_plans"
                  element={
                    <AdsPlan
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsPlan>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads_revenue"
                  element={
                    <AdsRevenue
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsRevenue>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="calender"
                  element={
                    <CalenderEvent
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CalenderEvent>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads_campaigns"
                  element={
                    <AdsCampaigns
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsCampaigns>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ads-time-slot"
                  element={
                    <AdsTimeSlot
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></AdsTimeSlot>
                  }
                >
                  {" "}
                </Route>

                {/* Geo_Fencing */}
                <Route
                  path="Geofencing"
                  element={
                    <ManageGeoFencing
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageGeoFencing>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="countries"
                  element={
                    <ManageCountry
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ManageCountry>
                  }
                >
                  {" "}
                </Route>
                {/* Configuration */}
                <Route
                  path="clear_cache"
                  element={
                    <CacheManagement
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></CacheManagement>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="debug"
                  element={
                    <Debug
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></Debug>
                  }
                >
                  {" "}
                </Route>
                {/* Contact Us */}
                <Route
                  path="contact-us"
                  element={
                    <ContactRequest
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></ContactRequest>
                  }
                >
                  {" "}
                </Route>
                {/* Log Activity */}
                <Route
                  path="logActivity"
                  element={
                    <SeeLogActivity
                      setApiresponsealert={setApiresponsealert}
                      setShowalert={setShowalert}
                    ></SeeLogActivity>
                  }
                >
                  {" "}
                </Route>
                {/* <Route path="*" element={<Error404 setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}></Error404>}> </Route> */}
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      )}
    </>
  );
}

export default AllRoutes;
