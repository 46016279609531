import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./AddPages.css";
import JoditEditor from "jodit-react";

import Creatable from "react-select/creatable";
import { color } from "@mui/system";

function AddPages() {
  const [user_id, setUser_id] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [body, setBody] = useState("");
  const [active, setActive] = useState("");
  const [image, setImage] = useState("");

  const [data, setData] = useState([]);

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const editor = useRef(null);
  const contentFieldChanaged = (data) => {
    setBody({ ...body, "/content/": data });
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
    }
  };

  async function AddPages() {
    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const bodyedit = body;
    var bodydata = JSON.stringify(bodyedit);
    var bodysubmit = bodydata;

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id", id);
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("body", bodysubmit);
    formData.append("active", aactivedata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/page/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
          var page_id = response.data.Page_id;
          var resssss = response.data;

          console.log("resssss", page_id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          formDatas.append("page_id", page_id);
          formDatas.append("image", image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-pages`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                // window.location.reload();
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const [options, setOptions] = useState([""]);
  // const [publishdata, setPublish_timedata] = useState([]);

  // const [ThumbnailTitle, setThumbnailTitle] = useState('');

  // console.log("publish", publishdata)
  // console.log("ThumbnailTitle", ThumbnailTitle)

  // const handlevalue = (e) => {
  // 	setPublish_timedata(e)
  // 		{
  // 			publishdata.map((item) =>
  // 		setThumbnailTitle(item.value + ""),
  // 	)
  // }
  //   }

  // useEffect(() => {
  // 	const getData = async () => {
  // 		const arr = [];
  // 		await axios.get("https://api.flicknexs.com/admin/videos-create", { headers: headers }).then((res) => {
  // 			let result = res.data.video_language;
  // 			// console.log(result)

  // 			result.map((user) => {
  // 				return arr.push({ value: user.code, label: user.name });
  // 			});
  // 			setOptions(arr)
  // 		});
  // 	};
  // 	getData();
  // }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i> Add New Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">
                      {/* <Creatable
												placeholder="Select an individual"
												options={options}
												isMulti
												onChange={(e) => handlevalue(e)}
												noOptionsMessage={() => "name not found"}
											></Creatable> */}

                      <div className="col-sm-6 col-sm-8 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add the page title in the textbox below:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3  col-sm-4f">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>SEO URL Slug</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">(example. /page/slug-name)</p>
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                              onChange={(e) => setSlug(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-3">
													<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
														<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
														<div className="panel-body" >
															<p className="p1">Select Date/Time Below</p>
															<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="@if(!empty($page->created_at)){{ $page->created_at }}@endif" />
														</div>
													</div>
												</div> */}
                    </div>

                    <div className=" mt-3 form-group">
                      <label>Banner:</label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="banner"
                        id="banner"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>

                    <div className="panel panel-primary" data-collapsed="0">
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Page Content</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body mt-3">
                        {/* <textarea className="form-control" name="body" id="summary-ckeditor"
													onChange={e => setBody(e.target.value)}></textareLink> */}
                        <JoditEditor
                          ref={editor}
                          value={body.content}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title">
                              <label> Status Settings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <div>
                              <label for="active">Is this page Active:</label>
                              {/* <input type="text"  name="active"  onChange={e => setActive(e.target.value)}/> */}
                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    active?.active === 1 ? true : false
                                  }
                                  checked={active?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={active?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Add New Page"
                          className="btn btn-primary pull-right"
                          onClick={AddPages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddPages;
