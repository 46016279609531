import React from "react";
import "./UsersAnalytics.css";

// import PieChart from 'react-pie-graph-chart';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function UsersAnalytics() {
  const datas = [
    {
      user: "Flicknexs",
      ACCType: "Subscribed User",
      Country: "India",
      RegisteredON: "2021-03-26 13:51:03",
      Source: "Web User",
      Status: "Active",
    },
    {
      user: "Flicknexs",
      ACCType: "Subscribed User",
      Country: "India",
      RegisteredON: "2021-03-26 13:51:03",
      Source: "Web User",
      Status: "InActive",
    },
  ];

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = ["March", "February", "January"];

  const data = {
    labels,
    datasets: [
      {
        label: "Register Users Month Wise",

        data: [4, 2, 7, 0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">Users Analytics</h4>
                  </div>
                </div>

                <div className="row p-3">
                  <hr />
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <label for="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_time"
                          name="start_time"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="start_time"> End Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_time"
                          name="end_time"
                        />
                      </div>
                      <div className="col-md-3 d-flex align-items-end">
                        <div class="dropdown">
                          <button
                            class="btn btn-outline-dark filter-btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Filter
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Day
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Week
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Month{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-3 text-right mt-4 pt-3">
                        <span id="export" className="btn btn-success  w-50">
                          Export
                        </span>
                      </div>
                    </div>
                    <div className="clear"></div>
                    {/* <div className="row align-items-center">
                      <div className="col-md-4">

                      </div>
                      <div className="col-md-8">
                        <figure className="highcharts-figure">

                          <div id="google-line-chart" ></div>
                        </figure>
                      </div>
                    </div> */}
                    <div>
                      <div className="row mt-5">
                        <div className="col-md-6 user-count">
                          <div>
                            <h5>Registered User : 132</h5>
                            <h5>Subscribed User : 24</h5>
                            <h5>Admin Users : 3</h5>
                            <h5>PPV Users : 53</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Line options={options} data={data} />
                          <p className="text-center">Register Users Count</p>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mb-5 ">
                      <div className="col-md-4">
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose Users</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <h5 className="mb-0">
                          {" "}
                          User Count : <span id="user_tables"> </span>
                        </h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>User</th>
                              <th>ACC Type</th>
                              <th>Country</th>
                              <th>Registered ON </th>
                              <th>Source</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {datas.map((item) => (
                            <thead>
                              <tr  key={item?.id} className="r1">
                                <th>{item.user}</th>
                                <th>{item.ACCType}</th>
                                <th>{item.Country}</th>
                                <th>{item.RegisteredON} </th>
                                <th>{item.Source}</th>
                                <th>
                                  <button className="btn btn-success">
                                    {item.Status}{" "}
                                  </button>
                                </th>
                                {/* <th><button className='btn btn-success'>
                                Active </button></th>
                                <th><button className='btn btn-seccess'>
                                InActive </button></th> */}
                              </tr>
                            </thead>
                          ))}
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        {/* <PieChart data={[
    {
      type: "Bus",
      value: 235,
      color: "#E97D30"
    },
    {
      type: "Bicycle",
      value: 165,
      color: "#62B170"
    },
    {
      type: "Train",
      value: 90,
      color: "#F1AF13"
    },
    {
      type: "Car",
      value: 345,
      color: "#4BA2DA"
    }
                       ]} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UsersAnalytics;
