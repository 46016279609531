import React from 'react'
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const All_LiveVideo = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-live-stream container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card">
                <div className="card-body">
                  <div className="row pt-3">
                    <div className="col-md-6">
                      <div className="moderator-section-title">
                        <h4>
                          <i className="entypo-globe"></i> Live Videos
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-6" align="right">
                      <Link to="/cpp/add-new-livevideo">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="fa fa-plus-circle"></i> Add New
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between p-3">
                    <div className="d-flex">
                      <p>Show 10</p>
                      <p>&nbsp; &nbsp; entries</p>
                    </div>
                    <div className="d-flex">
                      <p>Search:</p>
                      <input
                        type="search"
                        id="gsearch"
                        name="gsearch"
                        className="border-0 search-rev"
                      />
                    </div>
                  </div>

                  <Table>
                    <thead>
                      <tr className="title">
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>User Name</th>
                        <th>Video Type</th>
                        <th>Video Access</th>
                        <th>Status</th>
                        <th>Stream Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {/* {data.map((item, key) => (
                      <thead key={item.id}>
                        <tr className="text-center">
                    
                          <td key={key}> {key + 1} </td>
                          <td className="">
                            <img
                              src={item.Video_thumbnail}
                              className="img-fluid img-thumbnail img-manage"
                              alt="image"
                            />
                          </td>
                          <td>{item.title}</td>
                          <td>{item.id}</td>
                          <td>{item.publish_type}</td>
                          <td>{item.access}</td>
                          <td>{item.active}</td>
                         

                          <td>
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleUpdate}
                                value={
                                  editUser.status == 1 ||
                                  editUser.status == null
                                    ? "0"
                                    : editUser.status == 1
                                    ? "0"
                                    : "1"
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleUpdate}
                                onClick={() => handleUpdate(item.id)}
                                value={
                                  editUser.status == 1 ||
                                  editUser.status == null
                                    ? "0"
                                    : editUser.status == 1
                                    ? "0"
                                    : "1"
                                }
                              ></span>
                            </label>
                          
                          </td>
                          <td>
                            <Link to="" className="view">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                            <Link
                              to={"/admin/edit/add-livestream/" + item.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))} */}
                  </Table>

                  <div className="row d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        // onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{/* {page} */}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        // onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default All_LiveVideo