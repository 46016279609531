import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./Editnewaudio.css";

function Editnewaudio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [data, setData] = useState([]);
  const [order, setOrder] = useState({});
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [order3, setOrder3] = useState({});

  const [category, setCategory] = useState([]);
  const [cate, setCate] = useState([]);
  const [artist, setArtists] = useState([]);
  const [language, setLanguage] = useState([]);

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/audio/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audios[0];
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      slug: editUser.slug,
      ppv_status: editUser.ppv_status,
      ppv_price: editUser.ppv_price,
      type: editUser.type,
      access: editUser.access,
      album_id: editUser.album_id,
      artists: editUser.artists,
      rating: editUser.rating,
      details: editUser.details,
      description: editUser.description,
      active: editUser.active,
      status: editUser.status,
      draft: editUser.draft,
      featured: editUser.featured,
      banner: editUser.banner,
      duration: editUser.duration,
      views: editUser.views,
      year: editUser.year,
      age_restrict: editUser.age_restrict,
      mp3_url: editUser.mp3_url,
      image: editUser.image,
      player_image: editUser.player_image,
      tv_image: editUser.tv_image,
      search_tags: editUser.search_tags,
      ios_ppv_price: editUser.ios_ppv_price,
      uploaded_by: editUser.uploaded_by,
      image_url: editUser.image_url,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/audio/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);

    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')

      var audio_id = resjson.audio_id;
      var resssss = resjson;
      console.log("resssss", resssss);
      console.log("audio_id", audio_id);

      const formDatas = new FormData();

      formDatas.append("audio_id", audio_id);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("tv_image", tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audio`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const headers1 = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getartistname = [];
    const getlanguagename = [];
    const getcatename = [];
    const audioalbums = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response?.data?.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result?.length; i++) {
          getartistname?.push(result[i]?.artist_name);
        }
        setArtists(getartistname);

        var result = response?.data?.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getlanguagename?.push(result[i]?.name);
        }
        setLanguage(getlanguagename);

        var result = response.data.audio_categories;
        setOrder3(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          getcatename?.push(result[i]?.name);
        }
        setCate(getcatename);

        var result = response?.data?.audio_albums;
        setOrder3(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result?.length; i++) {
          audioalbums?.push(result[i]?.name);
        }
        setCate(audioalbums);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        //   console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));

    //   const getlanguagename = [];
    //   await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
    //   ).then(response => {
    // 	// console.log('api checks', response.data.livestream_language)
    // 	var result = response.data.languages
    // 	setOrder2(result)
    // 	// console.log('result', result)
    // 	// console.log('livestream_language', result);
    // 	for (let i = 0; i < result.length; i++) {
    // 	  getlanguagename.push(result[i].name);
    // 	}
    // 	setLanguage(getlanguagename);

    //   })
    // 	.catch(error =>
    // 	  console.log(error));

    //   const getcatename = [];
    //   await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
    //   ).then(response => {
    // 	// console.log('api checks', response.data.livestream_language)
    // 	var result = response.data.audio_categories
    // 	setOrder3(result)
    // 	// console.log('result', result)
    // 	// console.log('livestream_language', result);
    // 	for (let i = 0; i < result.length; i++) {
    // 	  getcatename.push(result[i].name);
    // 	}
    // 	setCate(getcatename);

    //   })
    // 	.catch(error =>
    // 	  console.log(error));

    //   const audioalbums = [];
    //   await axios.get("https://api.flicknexs.com/admin/audio/create", { headers: headers }
    //   ).then(response => {
    // 	// console.log('api checks', response.data.livestream_language)
    // 	var result = response.data.audio_albums
    // 	setOrder3(result)
    // 	// console.log('result', result)
    // 	// console.log('livestream_language', result);
    // 	for (let i = 0; i < result.length; i++) {
    // 		audioalbums.push(result[i].name);
    // 	}
    // 	setCate(audioalbums);

    //   })
    // 	.catch(error =>
    // 	  console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Audio</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body">
                      <form>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary "
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Title</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Add the audio title in the textbox below:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  placeholder="Audio Title"
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Slug</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Add the Audio slug:</p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="slug"
                                  id="slug"
                                  placeholder=""
                                  onChange={handleInput}
                                  value={editUser?.slug}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-6">
														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
															<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
															<div className="panel-body" >
																<p className="p1">Select Date/Time Below</p>
																<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
															</div>
														</div>
													</div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.image_url}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Player Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <img
                                  src={editUser?.Player_thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="panel panel-primary col-sm-6  mt-3"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Country</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <p className="p1">
                                Block the Audio for Selected Country:
                              </p>
                              {/* <select name="country[]" className="js-example-basic-multiple" multiple="multiple">
																<option value="" ></option>
															</select> */}
                            </div>
                          </div>

                          <div className="col-sm-6 mt-3">
                            <div className="">
                              <label className="m-0">Search Tags</label>

                              <div className="panel-body mt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tag-input1"
                                  onChange={handleInput}
                                  value={editUser?.search_tags}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Audio Details, Links, and Info</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <textarea
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.details}
                              name="details"
                              id="details"
                            ></textarea>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Short Description</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add a short description of the audio below:
                            </p>
                            <textarea
                              className="form-control"
                              name="description"
                              onChange={handleInput}
                              value={editUser?.description}
                              id="description"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Cast and Crew</label>{" "}
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p>Add artists for the audio below:</p>
                                {/* <select name="artists[]" className="js-example-basic-multiple" multiple="multiple">
																	<option value="" selected="true"></option>
																	<option value=""></option>
																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Album</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Album Below:
                                </p>
                                <select
                                  id="album_id"
                                  name="album_id"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.album_id}>
                                    {editUser?.album_id}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                <label className="p2" for="global_ppv">
                                  Age Restrict:
                                </label>
                              </div>
                              <div className="panel-body">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="age_restrict"
                                  id="age_restrict"
                                  onChange={editUser?.age_restrict}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Artists</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Artists Below:
                                </p>
                                <select
                                  id="artists"
                                  name="artists"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.artists}>
                                    {editUser?.artists}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Category</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Category Below:
                                </p>

                                {/* <select className="form-control js-example-basic-multiple" name="audio_category_id[]" id="audio_category_id" multiple="multiple" >

																	<option value="{{ $category->id }}" selected="true"></option>

																	<option value="{{ $category->id }}"></option>

																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Ratings</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1"> IMDB Ratings 10 out of 10</p>
                                <select
                                  value={editUser?.rating}
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={handleInput}
                                >
                                  {/* <option value={editUser.rating}>{editUser.rating}</option> */}
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                                {/* <input className="form-control" name="rating" id="rating" value="" onkeyup="NumAndTwoDecimals(event , this);" /> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Language</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Language Below:
                                </p>
                                {/* <select className="form-control js-example-basic-multiple" id="language" name="language[]" multiple="multiple" >

																	<option value="{{ $language->id }}" selected="true"></option>

																	<option value="{{ $language->id }}" ></option>

																</select> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Year</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Audio Released Year</p>
                                <input
                                  className="form-control"
                                  name="year"
                                  id="year"
                                  onChange={handleInput}
                                  value={editUser?.year}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Duration</label>
                                </div>
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Enter the audio duration in the following
                                  format (Hours : Minutes : Seconds)
                                </p>
                                <input
                                  className="form-control"
                                  name="duration"
                                  id="duration"
                                  maxlength="12"
                                  onChange={handleInput}
                                  value={editUser?.duration}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  {" "}
                                  <label>User Access</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Who is allowed to view this audio?
                                </p>
                                <select
                                  value={editUser?.user_access}
                                  id="access"
                                  name="access"
                                  className="form-control"
                                >
                                  {/* <option value={editUser.user_access}>{editUser.user_access}</option> */}

                                  {data?.map((item) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 row ">
                          <div className="col-lg-6 ">
                            <div className="col-lg-12 row">
                              <div className="col-lg-10 col-10">
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-lg-2 col-2">
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    value={
                                      editUser?.ppv_status === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div>
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      value={editUser?.banner === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      value={editUser?.active === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      value={editUser?.status === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="row mt-3 align-items-center ml-3">
                            <div className="col-sm-12">
                              <label className="switch" hidden>
                                <input
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.draft === 1 ? true : false
                                  }
                                  checked={editUser?.draft === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  value={editUser?.draft === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              <label className="switch" hidden>
                                <input
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.views === 1 ? true : false
                                  }
                                  checked={editUser?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  value={editUser?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2 p-2 d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Update"
                            className="btn btn-primary "
                            onClick={handleUpdate}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Editnewaudio;
