import React from 'react'

const ViewPayperview = () => {

    const datas = [
        {
            user: "Username",
            userdetail: "Flicknexs",
            title: "Video Title",
            titledetail: "",
            payment: "Payment ID",
            paymentdetail: "Cus_IVKRYwYv1Kj4IH",
            mode: "Payment Mode",
            modedetail: "",
            paid: "Padi Amount",
            paiddetail: "₹",
            admin: "Admin Amount",
            admindetail: "₹",
            amount: "Moderator Amount",
            amountdetail: "₹",
            status: "Status",
            statusdetail: "1"

        }
    ]

    return (
        <div>
            <div>
                <div className=' m-0 p-0'>
                    <div className=''>

                        <section className="section container-fluid">
                            <div className="iq-card p-2 mt-3" >

                                <h4 className='p-2'><i className="entypo-plus"></i>View Subscription</h4>
                                <hr />
                                <div className='p-3' >
                                    {datas.map((item) => (
                                        <div>
                                            <div key={item?.id} className='row'>
                                                <div className='col-md-6'>
                                                    <div>
                                                        <label>{item.user}</label>
                                                        <p>{item.userdetail}</p>

                                                    </div>
                                                    <div >
                                                        <label>{item.payment}</label>
                                                        <p>{item.paymentdetail}</p>
                                                    </div>
                                                    <div >
                                                        <label>{item.paid}</label>
                                                        <p>{item.paiddetail}</p>
                                                    </div>
                                                    <div >
                                                        <label>{item.amount}</label>
                                                        <p>{item.amountdetail}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div>

                                                        <label>{item.title}</label>
                                                        <p>{item.titledetail}</p>
                                                    </div>

                                                    <div>
                                                        <label>{item.mode}</label>
                                                        <p>{item.modedetail}</p>
                                                    </div>
                                                    <div >
                                                        <label>{item.admin}</label>
                                                        <p>{item.admindetail}</p>
                                                    </div>
                                                    <div>
                                                        <label>{item.status}</label>
                                                        <p>{item.statusdetail}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewPayperview