import React from "react";

function RegionVideoAnalytics() {
  const datas = [
    {
      name: "Tenet",
      count: "1",
      watch: "7.1533124078566",
      seek: "189.243",
      time: "No Buffer	",
      country: "United States",
      state: "New Jersey",
      city: "Piscataway",
    },
  ];

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">Video Analytics By Region</h4>
                  </div>
                </div>

                <div className="row p-3">
                  <hr />
                  <div className="col-md-12">
                    <div className="clear"></div>

                    <div className="row align-items-center mb-5 mt-3">
                      <div className="col-md-4">
                        <h5>Country :</h5>
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose Country</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <h5>State</h5>
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose State</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <h5>City</h5>
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose City</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex">
                        <p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className="d-flex">
                        <p>Search:</p>
                        <input
                          type="search"
                          id="gsearch"
                          name="gsearch"
                          className="border-0 search-rev"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>VIDEO NAME</th>
                              <th>VIEWED COUNT</th>
                              <th>WATCH PERCENTAGE (MINUTES)</th>
                              <th>SEEK TIME (SECONDS)</th>
                              <th>BUFFERED TIME (SECONDS)</th>
                              <th>COUNTRY NAME</th>
                              <th>STATE NAME</th>
                              <th>CITY NAME</th>
                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr key={item?.id}  className="r1">
                                <td key={key}> {key + 1} </td>

                                <td>{item.name}</td>
                                <td>{item.count}</td>
                                <td>{item.watch}</td>
                                <td>{item.seek}</td>
                                <td>{item.time}</td>
                                <td>{item.country}</td>
                                <td>{item.state}</td>
                                <td>{item.city}</td>
                              </tr>
                            </thead>
                          ))}
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default RegionVideoAnalytics;
