import React from 'react'
import { Link } from 'react-router-dom'


const Audio_Lists = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section ">
            <div className="container-fluid p-2">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                  <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <i className="entypo-archive"></i> Audio Lists
                          </h4>
                        </div>
                        <div className="col-md-6" align="right">
                          <Link to="/cpp/add-newaudio-lists">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="border-0 search-rev"
                          />
                        </div>
                      </div>
                    <div className=" p-0 mt-4">
                      <div className="table-view">
                        <table className="data-tables table audio_table iq-card text-center p-0 w-100">
                          <thead>
                            <tr className="r1">
                              {/* <th>Select All <input type="checkbox" id="select_all" /></th> */}
                              {/* <th>S.No</th> */}
                              <th>Title</th>                              
                              <th>Rating</th>                           
                              <th>Views</th>
                              <th>Status</th>                            
                              <th>Action</th>
                            </tr>
                          </thead>
                          {/* {audio.map((item, key) => (
                            <tbody>
                              <tr>
                                <td key={key}> {key + 1} </td>
                                <td>{item.title}</td>
                                <td>
                                  <img
                                    src={item.image_url}
                                    width={250}
                                    alt=""
                                  />
                                </td>
                                <td>{item.rating}</td>
                                <td>{item.rating}</td>
                                <td>{item.views}</td>
                                <td>
                                  <Link
                                    to={"/admin/edit-audio/" + item.id}
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))} */}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                            //   onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">
                                {/* {page} */}
                                </p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                            //   onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Audio_Lists