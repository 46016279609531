import React from 'react'
import { Link } from 'react-router-dom'


const Manage_Albums = () => {
  return (
    <div>
    <div className=" m-0 p-0">
    <div className="">
      <section className="section container-fluid">
        <div className=" pt-3">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-baseline mb-4">
                  <div className="iq-header-title">
                    <h4 className="card-title">Audio Album</h4>
                  </div>

                  <div className="iq-card-header-toolbar d-flex align-items-center">
                    <Link
                      to="/cpp/add-manage-albums"
                      className="btn btn-primary"
                    >
                      Add Audio Album
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-between p-3">
                    <div className="d-flex">
                      <p>Show 10</p>
                      <p>&nbsp; &nbsp; entries</p>
                    </div>
                    <div className="d-flex">
                      <p>Search:</p>
                      <input
                        type="search"
                        id="gsearch"
                        name="gsearch"
                        className="border-0 search-rev"
                      />
                    </div>
                  </div>
                <div className="iq-card-body p-0">
                  <div
                    id="nestable"
                    className="nested-list dd with-margins p-0"
                  >
                    <table
                      className="table-bordered table audio_table iq-card text-center p-0"
                      id="categorytbl"
                    >
                      <thead>
                        <tr className="r1">
                          <th>
                            <label>S.No</label>
                          </th>
                          <th>
                            <label>Name</label>
                          </th>
                          <th>
                            <label>Image</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>
                      </thead>

                      {/* {managealbum.map((item, key) => (
                        <tbody>
                          <tr>
                            <td key={key}> {key + 1} </td>
                            <td>{item.albumname}</td>
                            <td>
                              <img src={item.albums} width="150" />
                            </td>
                            <td>
                              <Link
                                to={"/admin/edit-albums/" + item.id}
                                className="edit ms-1"
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link to="" className="delete ms-1">
                                <span
                                  onClick={() => deleteOperation(item.id)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      ))} */}
                    </table>

                    <div className="row text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                        //   onClick={handlePreClick}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">
                            {/* {page} */}
                            </p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                        //   onClick={handleNxtClick}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
  )
}

export default Manage_Albums