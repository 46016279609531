import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditPurchasePlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    plan_price: "",
    user_id: "",
    id: "",
    product_id: "",
    enable: "",
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Inapp_Purchase;
      setEditUser(res);
      //   console.log(resData)
      //   console.log(res)
      //   console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      plan_price: editUser.plan_price,
      user_id: editUser.user_id,
      enable: editUser.enable,
      product_id: editUser.product_id,
    };
    console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/update/${id}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        // navigate('/admin/age/index');
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };
  return (
    <div>
      <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="section container-fluid pt-3">
              <div className="iq-card p-2 mt-3">
                <h4 className="p-2">
                  <i className="entypo-plus"></i> Manage In App Purchase Plans
                </h4>
                <hr />
                <div className="p-3">
                  <div className="col-md-12 mb-3">
                    <label className="m-0">Plan Price :</label>

                    <input
                      type="text"
                      id="plan_price"
                      name="plan_price"
                      className="form-control"
                      value={editUser?.plan_price}
                      onChange={handleInput}
                      placeholder="Enter Plan Price"
                    />
                  </div>

                  <div className="col-md-12 mb-3" data-collapsed="0">
                    <label className="m-0">Product ID :</label>
                    <div className="panel-body">
                      <input
                        type="text"
                        className="form-control"
                        name="product_id"
                        id="product_id"
                        value={editUser?.product_id}
                        onChange={handleInput}
                        placeholder="Enter Product ID"
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPurchasePlan;
