import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AllPages.css";

function AllPages() {
  const [allpages, setAllpages] = useState([]);

  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/page/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.pages?.data;
        setAllpages(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/page/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/page/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.pages.data);
        setPrevious(res?.data?.pages?.data);
        setAllpages(res?.data?.pages?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // function handlefirstpage() {
  //   // console.log('handlefirstpage');

  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/page/index?page=${page - 1}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log(res.data.pages.data);
  //       setPrevious(res.data.pages.data);
  //       setAllpages(res.data.pages.data);
  //       setPage(page - 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/page/index?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.pages.data);
        setNext(res?.data?.pages?.data);
        setAllpages(res?.data?.pages?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div className="admin-section-title">
                <div className="">
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-newspaper"></i> Page
                      </h4>
                    </div>
                    <div className="col-md-8" align="right">
                      <Link to="/admin/addpages" className="btn btn-primary">
                        <i className="fa fa-plus-circle"></i> Add New
                      </Link>
                    </div>
                  </div>
                  <div className="gallery-env">
                    <div className="row p-3 justify-content-center">
                      <table className="table table-bordered genres-table text-center allpage">
                        <thead>
                          <tr className="table-header r1">
                            <th>Page</th>
                            <th>URL</th>
                            <th>Active</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allpages?.map((item, key) => (
                            <tr key={item?.id}>
                              <td>
                                <Link className="title" to="" target="_blank">
                                  {item?.title}
                                </Link>
                              </td>
                              <td>
                                <p>{item?.slug}</p>
                              </td>
                              <td className="activeinactive">
                                {item?.active === 0 || item?.active === null ? (
                                  <td className="bg-warning video_active">
                                    Inactive
                                  </td>
                                ) : item?.active === 1 &&
                                  item?.active === 1 &&
                                  item?.active === 1 ? (
                                  <td className="bg-success video_active">
                                    Active
                                  </td>
                                ) : (
                                  <td className="bg-warning video_active">
                                    Draft
                                  </td>
                                )}
                              </td>

                              {/* <td ><p dangerouslySetInnerHTML={{ __html: item.body }}></p></td> */}
                              <td>
                                {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                <Link
                                  to={"/admin/editpages/" + item?.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-2">
                                  <span
                                    onClick={() => deleteOperation(item?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="row text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClick}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{page}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClick}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AllPages;
