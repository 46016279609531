
import React from 'react'
import "./SubcriptionPayment.css"
import { Link } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';

function SubcriptionPayment() {

  const datas = [
    {
      user: "Flicknexs",
      mail: "admin@admin.com",
      title: "",
      payment: "Cus_IVKRYwYv1Kj4IH",
      mode: "",
      amount: "₹ 12",
      date: "02 Jul 2021",
      status:"Active"


    }
  ]
  return (

    <>
      <div className=' m-0 p-0'>
        <div className=''>
          
          <section className="section container-fluid pt-3">
          <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title d-flex justify-content-between p-0">
                    <h4 className="card-title">Subscription Payment</h4>
                    <input type="search" id="gsearch" name="gsearch" className='pay-search' placeholder='Search Data'/>
                  </div>
                </div>
                <hr />
                <div className="row p-3 ps-0">

                  
                  <div className="col-md-12 ps-0">
                    
                    <div className="clear"></div>
                    {/* <div className='d-flex justify-content-between p-3'>
                     
                      <div className='d-flex'>
                        <p>Search:</p>
                        <input type="search" id="gsearch" name="gsearch" className='border-0 search-rev' />
                      </div>

                    </div> */}


                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss" >
                          <thead>
                            <tr className="r1">
                              <th>ID</th>
                              <th>USERNAME</th>
                              <th>Email</th>
                              <th>SUBSCRIPTION TITLE</th>
                              <th>PAYMENT ID </th>
                              <th>PAYMENT MODE</th>
                              <th>PAID AMOUNT</th>
                              <th>EXPIRY DATE</th>
                              <th>STATUS</th>
                              <th>ACTION</th>

                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr key={item?.id} className="r1">
                                <td key={key}> {key + 1} </td>
                                <td>{item.user}</td>
                                <td>{item.mail}</td>
                                <td>{item.title}</td>
                                <td>{item.payment} </td>
                                <td>{item.mode}</td>
                                <td>{item.amount}</td>
                                <td>{item.date}</td>
                                <td>{item.status}</td>
                                <td> <Link
                                    to={"/admin/payment/subscription-view"}
                                    className="edit ms-1"
                                  >
                                  <AiOutlineEye />
                                  </Link></td>

                              </tr>
                            </thead>
                          ))}
                          <tbody>


                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">

                      </div>

                    </div>

                  </div>
                </div>

              </div>
              <div>

              </div>

            </div>
          </section>
        </div></div>
    </>
  );
}

export default SubcriptionPayment;

