import React from "react";
import "./AddContentPartner.css";

function AddContentPartner() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="moderator-container">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> Users
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />

                    <form
                      method="POST"
                      action=""
                      accept-charset="UTF-8"
                      file="1"
                      enctype="multipart/form-data"
                      id="Moderator_form"
                    >
                      <div className="row justify-content-between p-3">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              User Name
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="username"
                              defaultValue="{{ old('username') }}"
                              autoComplete="username"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                              E-Mail Address
                            </label>
                            <input
                              id="email_id"
                              type="email"
                              className="form-control "
                              name="email_id"
                              defaultValue="{{ old('email_id') }}"
                              autoComplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              Mobile Number
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control "
                              name="mobile_number"
                              defaultValue="{{ old('mobile_number') }}"
                              autoComplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="password"
                              className=" col-form-label text-md-right"
                            >
                              Password
                            </label>
                            <input
                              id="password"
                              type="password"
                              className="form-control "
                              name="password"
                              autoComplete="new-password"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="password-confirm"
                              className=" col-form-label text-md-right"
                            >
                              Confirm Password
                            </label>
                            <input
                              id="confirm_password"
                              type="password"
                              className="form-control"
                              name="confirm_password"
                              autoComplete="new-password"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="user_role"
                              className=" col-form-label text-md-right"
                            >
                              User Roles
                            </label>
                            <select
                              className="form-control"
                              id="user_role"
                              name="user_role"
                            >
                              <option defaultValue="">Select Roles</option>

                              <option defaultValue="">role_name</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="description"
                              className=" col-form-label text-md-right"
                            >
                              Description
                            </label>
                            <input
                              id="description"
                              type="textarea"
                              className="form-control"
                              name="description"
                              autoComplete="description"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Picture
                            </label>
                            <input
                              id="picture"
                              type="file"
                              className="form-control"
                              name="picture"
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="form-group row mb-0">
                          <div className="col-md-12 ">
                            <button type="submit" className="btn btn-primary">
                              Register
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddContentPartner;
