import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function SeriesApproval() {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [channel, setChannel] = useState([]);
  const [video_id, setVideo_id] = useState([]);
  const [channel_partner_id, setChannel_partner_id] = useState([]);

  const [videos1, setVideos1] = useState([]);
  const [content, setContent] = useState([]);
  const [video_id1, setVideo_id1] = useState([]);
  const [content_partner_id, setContent_partner_id] = useState([]);
  console.log(content_partner_id);
  console.log(video_id1);

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUsers = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/move_series/index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.ContentPartner;
      var result = resData?.Series;
      setVideos1(result);
      setContent(res);
      // console.log(res);
      // console.log(result);
    };
    getUsers();

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/move_series/index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Channel;
      var result = resData?.Series;
      setVideos(result);
      setChannel(res);
      // console.log(res);
      // console.log(result);
      // console.log(res)
    };
    getUser();
  }, []);

  async function submitchannel() {
    console.log("append data");

    const formData = new FormData();

    formData.append("Series_id", video_id);
    formData.append("Channel_partner_id", channel_partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/move_series`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          alert("Added successfully  !")
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function submitcontent() {
    console.log("append data");

    const formData = new FormData();

    formData.append("Series_id", video_id1);
    formData.append("content_partner_id", content_partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/move_series`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          alert("Added successfully  !")
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid Videopartner">
            <div className="iq-card mt-4">
              <ul
                class="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    CPP Uploaded Series Videos
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Channel Uploaded Series Videos
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div>
                    <div class="col-md-12">
                      <div class="row">
                        <div className="mb-3">
                          <h4>Content Series Video Lists</h4>
                        </div>

                        <div class="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            class="form-control"
                            onChange={(e) =>
                              setContent_partner_id(e.target.value)
                            }
                            id="channel_users"
                          >
                            {content?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>{item?.username}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            class="form-control"
                            id="video_data"
                            onChange={(e) => setVideo_id1(e.target.value)}
                          >
                            {videos1?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>{item?.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12 text-end">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        value="submit"
                        onClick={submitcontent}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div>
                    <div class="col-md-12">
                      <div class="row">
                        <div className="mb-3">
                          <h4>Channel Series Video Lists</h4>
                        </div>

                        <div class="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            class="form-control"
                            onChange={(e) =>
                              setChannel_partner_id(e.target.value)
                            }
                            id="channel_users"
                          >
                            {channel?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.channel_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            class="form-control"
                            id="video_data"
                            onChange={(e) => setVideo_id(e.target.value)}
                          >
                            {videos?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>{item?.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12 text-end">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        value="submit"
                        onClick={submitchannel}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SeriesApproval;
