import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function MultiUserManagement() {
  const [editUser, setEditUser] = useState({ Multi_user_limit: "" });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Multi-User-limit/index`, {
        headers: headers,
      })
      .then((response) => {
        //   console.log('api checks', response.data.Multi_User_limit)
        var result = response?.data;
        setEditUser(result);
        // console.log("result", response.data.Multi_user_limit);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = { Multi_User_limit: editUser.Multi_user_limit };
    console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Multi-User-limit/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    // console.log(res)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      //   window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Multi User Limit Management</h4>
                </div>
              </div>
              <hr />
              <div className="panel panel-primary mt-3" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-options">
                    <Link to="#" data-rel="collapse">
                      <i className="entypo-down-open"></i>
                    </Link>
                  </div>
                </div>

                <form>
                  <div className="panel-body">
                    <div className="row align-items-center p-2">
                      <div className="col-sm-5">
                        <div className="form-group">
                          <label className="mb-2">
                            Number of simultaneous Sessions{" "}
                            <small>(Give in Number)</small>{" "}
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="Multi_user_limit"
                              id="Multi_user_limit"
                              value={editUser?.Multi_user_limit}
                              onChange={handleInput}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bt p-3 d-flex justify-content-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      onClick={handleUpdate}
                      value="Update"
                    />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default MultiUserManagement;
