import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ContentPartnerView() {
  const sub = [
    {
      name: "Admin",
    },
  ];

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>Roles</h4>
                      </div>
                      <div className="col-md-6" align="right">
                        <p>
                          <Link
                            className="btn btn-primary"
                            to="/admin/moderator/role"
                          >
                            <span className="glyphicon glyphicon-plus"></span>{" "}
                            Add Role
                          </Link>
                        </p>
                      </div>
                      <hr />
                    </div>

                    <table className="table table-bordered table-hover">
                      <thead className="r1">
                        <th>Role Name</th>
                        <th>Actions</th>
                      </thead>
                      <tbody>
                        {sub.map((item, key) => (
                          <tr className="r1">
                            <td className="table-content">
                              <p>{item.name}</p>
                            </td>
                            <td className="table-content">
                              <Link to={"/admin/moderator/view/edit/:id"}>
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentPartnerView;
