import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./MobileSettings.css";

function MobileSettings() {
  const [data, setData] = useState([]);
  const [splash, setSplash] = useState([]);
  const [splash_image_url, setSplash_image_url] = useState([]);

  const [profile_name, setProfile_name] = useState("");
  const [welcome_images, setWelcome_images] = useState("");
  const [welcome_images_url, setWelcome_images_url] = useState("");

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        setData(res?.data?.Welcome_Screen);
        setWelcome_images_url(res?.data?.Welcome_Screen[0]?.welcome_images_url);
        // console.log(res.data.Welcome_Screen)
        // setProfile_name(res.data.Welcome_Screen[0].name);
        // setWelcome_images(res.data.Welcome_Screen[0].welcome_images);
      });

    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        setSplash(res?.data?.splash_Screen);
        setSplash_image_url(res?.data?.splash_Screen[0]?.splash_image_url);
        // console.log(res.data.Welcome_Screen)
        // setProfile_name(res.data.Welcome_Screen[0].name);
        // setWelcome_images(res.data.Welcome_Screen[0].welcome_images);
      });
  }, []);

  function editProduct() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    // console.log(profile_name, welcome_images)
    const formData = new FormData();
    formData.append("welcome_images", welcome_images);
    formData.append("profile_name", profile_name);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/update`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response", response.data);
        alert("success");
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  }

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-delete/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid dashboard mt-3">
            <div className="iq-card p-2">
              <div className="">
                <div
                  className="nav  nav-pills me-3 justify-content-center"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Splash Screen{" "}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Moblie Screen
                  </button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  {splash?.map((datas) => (
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="container-fluid row" id="rtmp_url">
                        <div className="col-lg-7 ">
                          <div className="mt-3" key={datas?.id}>
                            <img src={splash_image_url} width={100} />
                          </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                          <Link
                            to={"/admin/mobileapp/splash/edit/" + datas?.id}
                            className="edit ms-1"
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          {/* <Link to="" className="delete ms-1"><span ><i className="fa fa-trash" aria-hidden="true"></i></span></Link> */}
                        </div>
                      </div>

                      <div className="container-fluid row" id="rtmp_url">
                        <div className="col-lg-7 ">
                          <div className="mt-3">
                            <img src={datas.welcome_images_url} width={100} />
                          </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                          <Link
                            to={"/admin/mobileapp/splash/edit/" + datas?.id}
                            className="edit ms-1"
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          {/* <Link to="" className="delete ms-1"><span ><i className="fa fa-trash" aria-hidden="true"></i></span></Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {splash?.map((datas) => (
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      key={datas?.id}
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="container-fluid row" id="rtmp_url">
                        <div className="col-lg-7 ">
                          <div className="mt-3">
                            <img src={datas.splash_image_url} width={100} />
                          </div>
                        </div>
                        <div className="col-lg-3 mt-5">
                          <Link
                            to={"/admin/mobileapp/splash/edit/" + datas?.id}
                            className="edit ms-1"
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          {/* <Link to="" className="delete ms-1"><span ><i className="fa fa-trash" aria-hidden="true"></i></span></Link> */}
                        </div>
                      </div>
                    </div>
                  ))}

                  {data?.map((item) => (
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      key={item?.id}
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <div className="container-fluid row" id="rtmp_url">
                        <div className="col-lg-7 ">
                          <div className="mt-3">
                            <img src={welcome_images_url} width={100} />
                          </div>
                        </div>

                        <div className="col-lg-3 mt-5">
                          <Link
                            to={"/admin/mobileapp/edit/" + item?.id}
                            className="edit ms-1"
                          >
                            <i
                              className="fa fa-pencil-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                          {/* <Link to="" className="delete ms-1"><span ><i className="fa fa-trash" aria-hidden="true"></i></span></Link> */}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <div className="container-fluid row" id="rtmp_url" >

                      <table id="table " className="table table-bordered text-center">
                        <thead>
                          <tr className="r1">
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {data.map((item) =>
                          <tbody id="tablecontents">
                            <tr className="row1" data-id="">
                              <td>
                                <div className='mt-3'>
                                  <img src={"https://api.flicknexs.com/public/uploads/settings/" + item.welcome_images} width={100} />
                                </div>
                                <div className='mt-3'>
                                  <input type="file" />
                                </div>
                              </td>
                              <td>
                                <div className=" mt-5">
                                  <Link to='' className="edit" ><i className="fa fa-pencil-square" aria-hidden="true"></i></Link>
                                  <Link to="" className="delete ms-5" ><span onClick={() => deleteOperation(item.id)}><i className="fa fa-trash" aria-hidden="true"></i></span></Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default MobileSettings;
