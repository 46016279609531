import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./Editcategory.css";

function Editcategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [audioGenre, setAudioGenre] = useState([]);
  const [editUser, setEditUser] = useState({
    name: "",
    parent_id: "",
    slug: "",
    image: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");
  const [imageurl, setImageurl] = useState([]);

  console.log(imageurl);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/audio-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Audio_Genre[0];
      var result = resData?.Audio_Genre;
      setEditUser(res);

      {
        result?.map(
          (item) => setImageurl(item?.image_url)
          // console.log(setImageurl)
        );
      }
      // console.log(result)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio-category/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.AudioGenre;
        setAudioGenre(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      name: editUser.name,
      parent_id: editUser.parent_id,
      image: editUser.image,
      slug: editUser.slug,
    };
    console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/audio-category/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      // setMessage(resjson.success);
      // alert('Success')

      var audio_genre_id = resjson.AudioGenreid;

      console.log(audio_genre_id);

      console.log("data", image);
      const formDatas = new FormData();
      formDatas.append("image", image);
      formDatas.append("audio_genre_id", audio_genre_id);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audiogenre`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
      navigate("/admin/audio-categories");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Edit Category</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Enter Name"
                      value={editUser?.name}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group ">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      placeholder="Enter Slug"
                      value={editUser?.slug}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <label>Image:</label>
                    <div>
                      <img src={imageurl} width="200px" />
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="image"
                        id="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      onChange={handleInput}
                      name="parent_id"
                      id="parent_id"
                    >
                      <option value={0}>Select</option>
                      {audioGenre?.map((item) => (
                        <option value={item?.parent_id}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id=""
                      onClick={handleUpdate}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Editcategory;
