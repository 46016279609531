import React from "react";

const EditContentPartner = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <div className="p-3">
                <div className="">
                  <h4>
                    <i className="entypo-archive"></i> Update Moderator Users
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="m-0"> User Name</label>

                    <input
                      type="text"
                      id="devices_name"
                      name="devices_name"
                      className="form-control"
                      placeholder="Enter name"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="m-0"> E-Mail Address</label>

                    <input
                      type="text"
                      id="devices_name"
                      name="devices_name"
                      className="form-control"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-6 mb-3">
                    <label className="m-0"> Mobile Number</label>

                    <input
                      type="number"
                      id="devices_name"
                      name="devices_name"
                      className="form-control"
                      placeholder="Enter Mobile number"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="m-0"> Description </label>

                    <input
                      type="number"
                      id="devices_name"
                      name="devices_name"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>Picture</label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="banner"
                        id="banner"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="m-0">User Roles</label>
                    <select
                      id="in_name"
                      name="in_name"
                      className="form-control"
                    >
                      <option value="0">Select</option>
                      <option value="1">webnexs</option>
                      <option value="0">test</option>
                    </select>
                  </div>
                </div>
                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Upload"
                      className="btn btn-primary "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditContentPartner;
