import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import "./AddSubscriptionPlan.css";
import { string } from "prop-types";

const AddSubscriptionPlan = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    placeholder: "Start typings...",
  };

  const [paymentsettings, setPaymentsettings] = useState([]);
  const [plan_id, setPlan_id] = useState("");
  const [user_id, setUser_id] = useState("");
  const [plans_name, setPlans_name] = useState("");
  const [plan_idstripe, setPlan_idstripe] = useState("");
  const [plan_idpaypal, setPlan_idpaypal] = useState("");
  const [plan_idrazropay, setPlan_idrazropay] = useState("");
  const [plan_idpaystack, setPlan_idpaystack] = useState("");
  const [billing_interval, setBilling_interval] = useState("");
  const [billing_type, setBilling_type] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [type, setType] = useState("");
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  const [andriod_paystack_url, setAndriod_paystack_url] = useState("");
  const [video_quality, setVideo_quality] = useState("");
  const [plan_content, setPlan_content] = useState("");
  const [resolution, setResolution] = useState("");
  const [devices, setDevices] = useState([]);
  const [subscription_plan_name, setSubscription_plan_name] = useState("");
  const [ios_plan_price, setIos_plan_price] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  const [ads_status, setAds_status] = useState("");

  // console.log("devices", devices);

  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  async function submitsub() {
    const editInputvalue = ads_status?.ads_status;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputdevices = devices?.devices;
    var datadevices = JSON.stringify(editInputdevices);
    var aactivedatadevices = datadevices;

    // console.log(aactivedatadevices);

    const formData = new FormData();

    formData.append("plans_name", plans_name);
    paymentsettings?.forEach((item, index) => {
      if (typeof item?.payment_type === "string") {
        var data = item?.payment_type;
        formData.append(`plan_id[${data}]`, plan_id);
      }
    });
    formData.append("billing_interval", billing_interval);
    formData.append("billing_type", billing_type);
    formData.append("payment_type", payment_type);
    formData.append("type", type);
    formData.append("days", days);
    formData.append("price", price);
    formData.append("andriod_paystack_url", andriod_paystack_url);
    formData.append("video_quality", video_quality);
    formData.append("plan_content", JSON.stringify(plan_content));
    formData.append("resolution", resolution);
    formData.append("devices", devices.join(","));
    formData.append("subscription_plan_name", subscription_plan_name);
    formData.append("ios_plan_price", ios_plan_price);
    formData.append("ios_product_id", ios_product_id);
    formData.append("ads_status", aactivedata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/store_plan`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data.id;
          // console.log('result', result);
          // navigate('/admin/all-artist')
          alert("Added successfully  !");
          setInterval(10000);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const contentFieldChanaged = (data) => {
    setPlan_content(data);
    console.log("datasssssss", data);
  };

  const handleInputs = (e) => {
    if (e.target.checked === true) {
      setAds_status({
        ...ads_status,
        [e.target.name]: 1,
      });
    } else {
      setAds_status({
        ...ads_status,
        [e.target.name]: 0,
      });
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/subscription/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.Devices;
        setData(result);
        var results = response?.data?.country;
        var paymentsettings = response?.data?.PaymentSetting;
        setNumber(results);
        setPaymentsettings(paymentsettings);
        // console.log(paymentsettings);
        // console.log("result", result);
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  // const handleInputChange = (event) => {
  //   setDevices(event.target.value.split(","));
  //   console.log(event.target.value.split(","), "handleInputChange")
  // };

  // const handleInputChange = (id) => {
  //   const index = devices.findIndex((val) => val ==== id);
  //   if (index !=== -1) {
  //     setDevices(devices.filter((val) => val !=== id));
  //     // console.log(devices.filter((val) => val !=== id), "handleInputChange")
  //   } else {
  //     setDevices([...devices, id]);
  //     // console.log([...devices, id], "handleInputChange")
  //   }
  // };

  const handleInputChange = (event) => {
    const deviceIds = event.target.value.split(",");
    setDevices(deviceIds);
  };
  const myArray = [];

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <h4>
                            <i className="entypo-archive"></i> Add Plan
                          </h4>
                        </div>
                      </div>

                      <hr></hr>

                      <div className="form-group ">
                        <label>Plan Name:</label>
                        <input
                          type="text"
                          id="plans_name"
                          name="plans_name"
                          onChange={(e) => setPlans_name(e.target.value)}
                          className="form-control"
                          placeholder="Plan Name"
                        />
                      </div>

                      <div className="form-group pt-0">
                        {paymentsettings?.map((item, index) => {
                          const value = item?.payment_type;
                          const value2 = item?.payment_type;

                          if (value && value2) {
                            return (
                              <div className="mt-2">
                                <label>{value} Plan ID:</label>
                                <input
                                  type="text"
                                  id={value2}
                                  name={value2}
                                  onChange={(e) => setPlan_id(e.target.value)}
                                  className="form-control mt-2"
                                  placeholder="Plan ID"
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}
                      </div>

                      {/* <div className="form-group ">
                        <label>Stripe Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idstripe(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Stripe</small>
                      </div> */}

                      {/* <div className="form-group ">
                        <label>Paystack Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          onChange={(e) => setPlan_idpaystack(e.target.value)}
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Paystack</small>
                      </div> */}

                      {/* <div className="form-group ">
                        <label>Paypal Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idpaypal(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Paypal</small>
                      </div> */}

                      {/* <div className="form-group ">
                        <label>Razropay Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idrazropay(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Razropay</small>
                      </div> */}

                      <div className="form-group ">
                        <label>Billing Interval:</label>
                        <input
                          type="text"
                          id="billing_interval"
                          name="billing_interval"
                          onChange={(e) => setBilling_interval(e.target.value)}
                          className="form-control"
                          placeholder="etc..Monthly, Yearly, 3 Month.."
                        />
                      </div>

                      <div className="form-group ">
                        <label>Billing Type:</label>
                        <input
                          type="text"
                          id="billing_type"
                          name="billing_type"
                          onChange={(e) => setBilling_type(e.target.value)}
                          className="form-control"
                          placeholder="Example... Non Refundable"
                        />
                      </div>

                      <div className="panel panel-primary" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Page Content</label>
                          </div>

                          <div className="panel-options">
                            <Link
                              to="#"
                              data-rel="collapse"
                              name="plan_content"
                            >
                              <i className="entypo-down-open"></i>
                            </Link>
                          </div>
                        </div>
                        {/* <div className="panel-body mt-3">
                          <JoditEditor
                            ref={editor}
                            config={config}
                            onBlur={(newContent) => setContent(newContent)}
                          />
                        </div> */}

                        <textarea
                          onChange={(e) => setContent(e.target.value)}
                        ></textarea>

                        <JoditEditor
                          ref={editor}
                          config={config}
                          onBlur={(newContent) => setContent(newContent)}
                        />

                        <JoditEditor
                          // ref={editor}
                          value={plan_content}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>

                      <div className="form-group ">
                        <label>Andriod Paystack Url</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) =>
                            setAndriod_paystack_url(e.target.value)
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="row">
                        <label>Payment Type:</label>

                        <div class="form-check col-md-4 mt-2 ps-5">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="one_time"
                            onChange={(e) => setPayment_type(e.target.value)}
                            checked
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            One Time Payment
                          </label>
                        </div>
                        <div class="form-check col-md-4 mt-2">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            value="recurring"
                            id="flexRadioDefault2"
                            onChange={(e) => setPayment_type(e.target.value)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>

                      <div className="form-group ">
                        <label>Price ( $ ):</label>
                        <input
                          type="text"
                          id="price"
                          name="price"
                          className="form-control"
                          placeholder="Price"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Days :</label>
                        <input
                          type="text"
                          id="days"
                          name="days"
                          className="form-control"
                          placeholder="Days"
                          onChange={(e) => setDays(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Video Quality:</label>
                        <input
                          type="text"
                          id="video_quality"
                          name="video_quality"
                          className="form-control"
                          placeholder="Quality"
                          onChange={(e) => setVideo_quality(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Resolution :</label>
                        <input
                          type="text"
                          id="resolution"
                          name="resolution"
                          className="form-control"
                          placeholder="Resolution"
                          onChange={(e) => setResolution(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>IOS Product ID :</label>
                        <input
                          type="text"
                          id="ios_product_id"
                          name="ios_product_id"
                          className="form-control"
                          onChange={(e) => setIos_product_id(e.target.value)}
                          placeholder="IOS Product ID"
                        />
                      </div>

                      <div className="form-group ">
                        <label>IOS Plan Price ( $ ):</label>
                        <input
                          type="text"
                          id="ios_plan_price"
                          name="ios_plan_price"
                          className="form-control"
                          onChange={(e) => setIos_plan_price(e.target.value)}
                          placeholder="IOS Plan Price"
                        />
                      </div>

                      <div className="row mt-3 form-group">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>Enable Ads</label>
                            </div>
                            <div className="col-lg-6">
                              <label className="switch">
                                <input
                                  name="ads_status"
                                  onChange={handleInputs}
                                  defaultChecked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  checked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="ads_status"
                                  onChange={handleInputs}
                                  value={ads_status?.ads_status === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 row form-group">
                        <h5>Devices :</h5>

                        {data?.map((item, index) => {
                          const value = item?.id;
                          const devices_name = item?.devices_name;
                          // const deviceIds = devices.split(",");
                          // const devices = deviceIds.filter((val) =>
                          //   val.includes(item?.id)
                          // );
                          // const array =
                          //   myArray.filter((item) => item !=== null).length ====
                          //   0;

                          return (
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="">
                                  <label>{devices_name}</label>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div>
                                  <label className="switch">
                                    <input
                                      name="active"
                                      value={value}
                                      onChange={handleInputChange}
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      value={value}
                                      onChange={handleInputChange}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={submitsub}
                      >
                        Add plan
                      </button>

                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddSubscriptionPlan;
