import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import './Devices.css'

function Devices() {
 
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true)
  const [isOff, setIsOff] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [devices_name, setDevices_name] = useState('');


  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + access_token,
    "Accept": 'application/json',
    'Access-Control-Allow-Origin': '*',
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/admin/devices/index`, { headers: headers }
    ).then(response => {
      // console.log('api checks', response)
      var result = response?.data?.devices
      setData(result)
      // console.log(result)
    })
      .catch(error =>
        console.log(error));
  }, [])


  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("devices_name", devices_name);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/devices/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          alert("Added Successfully");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios.delete(`${process.env.REACT_APP_Baseurl}/admin/devices/delete/` + id, { headers: headers })
      .then(response => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      })
  }

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${page - 1}`, { headers: headers }
      )
      .then(res => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch(err => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${page - 1}`, { headers: headers }
      )
      .then(res => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list?page=${page + 1}`, { headers: headers }
      )
      .then(res => {
        // console.log('next', res?.data?.LiveStream);
        setNext(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page + 1);
      })
      .catch(err => {
        console.log(err);
      });
  }


  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Devices</h4>
                        </div>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="btn btn-primary create_category"
                          >
                            <i className="fa fa-plus-circle"></i>Add New
                          </Link>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  New Devices
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <label>Devices Name: </label>

                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter Devices Name"
                                    className="form-control rtmp_urls"
                                    onChange={(e) => setDevices_name(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="modal-body"></div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-primary ml-3 "
                                  onClick={handleSubmit}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row p-3">
                        {data?.map((item, key) => (
                          <>
                            <div className="col-lg-12 row">
                              <div className="col-lg-1">
                                <div key={item?.id}> {key + 1} </div>
                                </div>
                              <div className="col-lg-3">
                                <div className="">
                                  <label>{item?.devices_name}</label>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="">
                                  <p>
                                    <Link to={"/admin/edit-devices/" + item?.id}>
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link onClick={() => deleteOperation(item?.id)}>
                                      <i
                                        className="fa fa-trash ms-3"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>

                          </>
                        ))}
                      </div>
                      {/* <div className="iq-card-body table-responsive mt-3">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table " >
                            <thead>
                              <tr className="r1">
                                <th>S.No</th>
                                <th>Plan Price</th>
                                <th>Product ID</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>

                            {sub.map((item, key) => (
                          <tr className="r1">
                            <td className="table-content" key={key}>
                              {key + 1}
                            </td>


                            <td className="table-content">
                              <p>{item.plan}</p>
                            </td>
                            <td className="table-content">
                              <p>{item.product}</p>
                            </td>
                            <td className="table-content">
                              <Link to={"/admin/edit-inapp-purchase/:id"}>
                                <i className="fa fa-pencil-square" aria-hidden="true"></i>
                              </Link>
                              <Link>
                                <i className="fa fa-trash" aria-hidden="true"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}

                            </tbody>
                          </table>


                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Devices;
