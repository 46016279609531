import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  // PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CPPAnalytics() {
  const sub = [
    {
      email: "demos@webnexs.in",
      name: "Sanjai Kumar",
      uploads: "1",
      views: "1",
      comments: "",
    },
  ];
  const [isTrue, setIsTrue] = useState(true);

  const handleChange = () => {
    setIsTrue(false);
  };
  const handleOpen = () => {
    setIsTrue(true);
  };

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const label = [];

  const data = {
    label,
    datasets: [
      {
        label: "Moderator Users Analytics",

        data: [0.0, 0.0, 0.0, -1.0, 1.0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const labels = ["Audio", "Video", "Live Stream"];

  const datas = {
    labels,
    datasets: [
      {
        label: "Content Partners Uploded Content",

        data: [0.0, 0.0, 0.0, -1.0, 1.0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="col-md-12">
                  <div className="iq-card-header  justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">
                        Content Partners Analytics :
                      </h4>
                    </div>
                  </div>

                  <div className="clear"></div>
                  <div className="row p-3">
                    <hr />
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <div className="col-md-3">
                          <label for="start_time"> Start Date: </label>
                          <input
                            type="date"
                            className="form-control"
                            id="start_time"
                            name="start_time"
                          />
                        </div>
                        <div className="col-md-3">
                          <label for="start_time"> End Date: </label>
                          <input
                            type="date"
                            className="form-control"
                            id="end_time"
                            name="end_time"
                          />
                        </div>

                        <div className="col-md-3 d-flex align-items-end">
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-dark filter-btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Filter
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Day
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Week
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  1 Month{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-3 d-flex align-items-end">
                          <span
                            id="export"
                            className="btn btn-primary download-btn btn-sm"
                          >
                            Download CSV
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="row mt-5">
                          <h5>Content Partners Content</h5>
                          <div className="col-md-6 mt-3">
                            <div>
                              <h5>Total No. Of Video Content : 0</h5>
                              <h5>Total No. Of Live Video Content : 0</h5>
                              <h5>Total No. Of Audio Content : 0</h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id="validationFormCheck2"
                                name="radio-stacked"
                                checked="checked"
                                required
                                onClick={handleOpen}
                              />
                              <p
                                class="form-check-label"
                                for="validationFormCheck2"
                              >
                                Total Content By Users
                              </p>
                            </div>
                            <div class="form-check mb-3">
                              <input
                                type="radio"
                                class="form-check-input"
                                id="validationFormCheck3"
                                name="radio-stacked"
                                required
                                onClick={handleChange}
                              />
                              <p
                                class="form-check-label"
                                for="validationFormCheck3"
                              >
                                {" "}
                                Filtered Content By Users
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isTrue ? (
                        <>
                          <div className="mt-5">
                            <h5>Content View Through Graph :</h5>
                          </div>
                          <div>
                            <div className="row mt-5">
                              <div className="col-md-2"></div>
                              <div className="col-md-7">
                                <Line options={options} data={data} />
                                <p className="text-center">
                                  Moderator Users Analytics
                                </p>
                              </div>
                              <div className="col-md-3"></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="row mt-5">
                            <div className="col-md-2"></div>
                            <div className="col-md-7">
                              <Line options={options} data={datas} />
                              <p className="text-center">Time of Day</p>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </div>
                      )}

                      <div id="PayPerView_content">
                        <div className="row ">
                          <div className="col-md-12">
                            <table className="table" id="cpp_revenue_table">
                              <thead>
                                <tr className="r1">
                                  <th>#</th>
                                  <th>Email </th>
                                  <th>Uploader Name</th>
                                  <th>No Of Uploads</th>
                                  <th>Total Views</th>
                                  <th>Total Comments</th>
                                </tr>
                              </thead>
                              {sub.map((item, key) => (
                                <thead>
                                  <tr key={item?.id} className="r1">
                                    <td key={key}> {key + 1} </td>
                                    <td>{item.email}</td>
                                    <td>{item.name}</td>
                                    <td>{item.uploads}</td>
                                    <td>{item.views} </td>
                                    <td>{item.comments} </td>
                                  </tr>
                                </thead>
                              ))}
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CPPAnalytics;
