// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./error404.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*======================\n    404 page\n=======================*/\n\n.page_404 {\n  padding: 50px 0;\n  background: #fff;\n  height: 100vh;\n}\n.d404{\n  color:red;\n}\n\n.page_404 img {\n  width: 100%;\n}\n\n.four_zero_four_bg {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 400px;\n  background-position: center;\n}\n\n.four_zero_four_bg h1 {\n  font-size: 80px;\n}\n\n.four_zero_four_bg h3 {\n  font-size: 80px;\n}\n\n.link_404 {\n  color: #fff !important;\n  padding: 10px 20px;\n  background: #39ac31;\n  margin: 20px 0;\n  display: inline-block;\n}\n.contant_box_404 {\n  margin-top: -50px;\n}\n", "",{"version":3,"sources":["webpack://./src/Error404/error404.css"],"names":[],"mappings":"AAAA;;wBAEwB;;AAExB;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yDAAuC;EACvC,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB","sourcesContent":["/*======================\n    404 page\n=======================*/\n\n.page_404 {\n  padding: 50px 0;\n  background: #fff;\n  height: 100vh;\n}\n.d404{\n  color:red;\n}\n\n.page_404 img {\n  width: 100%;\n}\n\n.four_zero_four_bg {\n  background-image: url('./error404.gif');\n  height: 400px;\n  background-position: center;\n}\n\n.four_zero_four_bg h1 {\n  font-size: 80px;\n}\n\n.four_zero_four_bg h3 {\n  font-size: 80px;\n}\n\n.link_404 {\n  color: #fff !important;\n  padding: 10px 20px;\n  background: #39ac31;\n  margin: 20px 0;\n  display: inline-block;\n}\n.contant_box_404 {\n  margin-top: -50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
