import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./AddFooterMenu.css";

function EditFooterMenu() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    user_id: "",
    id: "",
    link: "",
    name: "",
    order: "",
    column_position: "",
    parent_id: "",
  });
  const [column, setColumn] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.footer_menus;
      var result = resData?.columns;
      // console.log(res);
      // console.log(result);
      setEditUser(res);
      setColumn(result);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      name: editUser.name,
      link: editUser.link,
      order: editUser.order,
      column_position: editUser.column_position,
      parent_id: editUser.parent_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/footer_menu/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Added Successfully");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className=" mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Footer Link</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                  </div>
                  <div>
                    <div className="modal-body">
                    
                      <div className="form-group">
                        <label>Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={editUser?.name}
                          onChange={handleInput}
                          placeholder="Enter the Name"
                        />
                        <input
                          type="text"
                          id="parent_id"
                          name="parent_id"
                          className="form-control"
                          placeholder="Enter the parent_id"
                          value={editUser?.parent_id}
                          hidden
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group">
                        <label>Link:</label>
                        <input
                          type="text"
                          id="link"
                          name="link"
                          value={editUser?.link}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Enter the Link"
                        />
                      </div>

                      <div className="form-group">
                        <label>Column position :</label>

                        <select
                          value={editUser?.column_position}
                          name="column_position"
                          id="column_position"
                          className="form-control"
                          onChange={handleInput}
                        >
                          {/* <option value={editUser.column_position}  > Column {editUser.column_position}</option> */}
                          {column?.map((item, key) => (
                            <option key={item?.id} value={item?.name}> {item?.Column}</option>
                          ))}
                          {/* <option value="2" > Column 2</option>
				<option value="3" > Column 3</option>
				<option value="4" > Column 4</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        id="submit-new-footer"
                        onClick={handleUpdate}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditFooterMenu;
