import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./PopupSettings.css";

function PopupSettings() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [popup_enable, setPopup_enable] = useState("");
  const [popup_header, setPopup_header] = useState("");
  const [popup_footer, setPopup_footer] = useState("");
  const [popup_content, setPopup_content] = useState("");
  const [popup_image, setPopup_image] = useState("");
  const [after_login_link, setAfter_login_link] = useState("");
  const [before_login_link, setBefore_login_link] = useState("");
  const [popup_image_link, setPopup_image_link] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/home-popup", { headers: headers })
      .then((res) => {
        setPopup_image(res?.data?.Home_pop_up[0]?.popup_image);
        setPopup_image_link(res?.data?.Home_pop_up[0]?.popup_image_link);
        // console.log(res.data.Welcome_Screen)
        // setProfile_name(res.data.Welcome_Screen[0].name);
        // setWelcome_images(res.data.Welcome_Screen[0].welcome_images);
      });
  }, []);

  async function stream() {
    console.log(
      "append data",
      id,
      popup_enable,
      popup_header,
      popup_footer,
      popup_content,
      popup_image,
      after_login_link,
      before_login_link,
      popup_image_link
    );

    const formData = new FormData();

    formData.append("id", id);
    formData.append("popup_enable", popup_enable);
    formData.append("popup_header", popup_header);
    formData.append("popup_footer", popup_footer);
    formData.append("popup_content", popup_content);
    formData.append("popup_image", popup_image);
    formData.append("after_login_link", after_login_link);
    formData.append("before_login_link", before_login_link);
    formData.append("popup_image_link", popup_image_link);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/home-popup-update`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/age/index')
          window.location.reload();
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="p-2">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title p-1"> Pop-up </h4>
                      </div>
                    </div>
                    <hr />

                    <div className="iq-card-body ">
                      <div className="form-card">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-3">
                                      {" "}
                                      Enable Home Page Pop Up :{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="mt-1 d-flex align-items-center ">
                                  {/* <div className="">OFF</div> */}
                                  {/* <label className="switch" onChange={e => setPopup_enable(e.target.value)}>
                                      <input type="checkbox" name="popup_enable" id="popup_enable" value='1'  />
                                      <span className="slider round"></span>
                                    </label> */}
                                  {/* <input name="popup_enable" onChange={e => setPopup_enable(e.target.value)}/> */}
                                  {/* <div className="ml-2">ON</div> */}
                                  <select
                                    className="form-control "
                                    onChange={(e) =>
                                      setPopup_enable(e.target.value)
                                    }
                                    required
                                  >
                                    <option defaultValue="0">
                                      Choose Enable (Yes or No)
                                    </option>
                                    <option defaultValue="1">Yes</option>
                                    <option defaultValue="0">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0"> Header :</label>
                                <input
                                  type="text"
                                  className="form-control summary-ckeditor"
                                  name="popup_header"
                                  id="popup_header"
                                  placeholder="Pop-up Header"
                                  onChange={(e) =>
                                    setPopup_header(e.target.value)
                                  }
                                />
                              </div>

                              <div className="col-sm-6 form-group">
                                <label className="m-0">Footer :</label>
                                <input
                                  type="text"
                                  className="form-control summary-ckeditor"
                                  name="popup_footer"
                                  id="popup_footer"
                                  placeholder="Pop-up Footer"
                                  onChange={(e) =>
                                    setPopup_footer(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0">
                                  {" "}
                                  Before Login Link :
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="before_login_link"
                                  id="before_login_link"
                                  placeholder="Before login link"
                                  onChange={(e) =>
                                    setBefore_login_link(e.target.value)
                                  }
                                />
                              </div>

                              <div className="col-sm-6 form-group">
                                <label className="m-0">
                                  {" "}
                                  After Login Link :{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="after_login_link"
                                  id="after_login_link"
                                  placeholder="After login link"
                                  onChange={(e) =>
                                    setAfter_login_link(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0"> Pop-up Image : </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="popup_image"
                                  onChange={(e) =>
                                    setPopup_image(e.target.files[0])
                                  }
                                  id="popup_image"
                                />
                              </div>

                              <div className="col-sm-6 d-flex">
                                <img
                                  src={popup_image_link}
                                  width="100"
                                  alt="pop-up Image"
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-12 form-group">
                                <label className="m-0"> Content :</label>
                                {/* <Editor
                                    wrapperclassName="wrapper" name="popup_content" id="popup_content"
                                    editorclassName="editor"
                                    toolbarclassName="toolbar"
                                    onChange={e => setPopup_content(e.target.value)}
                                  /> */}
                                <textarea
                                  name="popup_content"
                                  className="form-control summary-ckeditor"
                                  id="popup_content"
                                  onChange={(e) =>
                                    setPopup_content(e.target.value)
                                  }
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-12 text-end">
                                <input
                                  type="submit"
                                  className="btn btn-primary"
                                  id=""
                                  defaultValue="update"
                                  onClick={stream}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PopupSettings;
