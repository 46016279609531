import React from "react";

const AddChannelPackage = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h4>
                          <i className="entypo-video"></i> Add New Channel
                          Package{" "}
                        </h4>{" "}
                      </div>

                      <div className="col-md-6"></div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <form>
                          <div className="form-group ">
                            <label>Package Name</label>
                            <p>
                              Add The Channel Package Name In The Textbox Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Package Name"
                            />
                          </div>
                        </form>
                        <form>
                          <div className="form-group ">
                            <label>Package Plan ID</label>
                            <p>
                              Add The Channel Package Plan Id In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Package Plan ID"
                            />
                          </div>
                        </form>
                        <form>
                          <div className="form-group ">
                            <label>Plan Interval</label>
                            <p>
                              Add The Channel Plan Interval In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Plan Interval"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-md-6">
                        <form>
                          <div className="form-group ">
                            <label>Add Channel</label>
                            <p>
                              Select The List Of Channel In The Textbox Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </form>
                        <form>
                          <div className="form-group">
                            <label>Package Price</label>
                            <p>
                              Add The Channel Package Price In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Package Price"
                            />
                          </div>
                        </form>
                        <form>
                          <div className="form-group">
                            <label>Channel Package Status</label>
                            <p>Enable The Status For Channel Package:</p>
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary text-right"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddChannelPackage;
