import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AddMenu.css";

function AddMenu() {
  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  const [user_id, setUser_id] = useState("");
  const [id, setId] = useState("");
  const [parent_id, setParent_id] = useState("0");
  const [order, setOrder] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [select_url, setSelect_url] = useState("");
  const [custom_url, setCustom_url] = useState("");
  const [image, setImage] = useState("");

  const [data, setData] = useState([]);

  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
    } else {
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
    }
  };

  async function Addmenudata() {
    const editInputvalue = in_menu.in_menu;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id", id);
    formData.append("parent_id", parent_id);
    formData.append("order", order);
    formData.append("in_menu", aactivedata);
    formData.append("name", name);
    formData.append("url", url);
    formData.append("type", type);
    formData.append("select_url", select_url);
    formData.append("custom_url", custom_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/menu/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
          var Menu_id = response.data.Menu_id;
          var resssss = response.data;

          // console.log('resssss', Menu_id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          formDatas.append("menu_id", Menu_id);
          formDatas.append("image", image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-menus`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/menu/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.categories;
        setCategories(result);
        // console.log(result)
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  function enablestreams() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "site_url":
        return (
          <div className="mt-3">
            <div id="div_Site">
              <label for="url">Menu Item URL (ex. /site/url)</label>
              <input
                name="url"
                id="url"
                placeholder="URL"
                className="form-control"
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
        );
      case "custom_url":
        return (
          <div className="mt-3">
            <div id="div_Custom">
              <label for="url">Custom URL (ex. full url)</label>
              <input
                name="custom_url"
                id="custom_url"
                placeholder="Custom URL"
                className="form-control"
                onChange={(e) => setCustom_url(e.target.value)}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className="mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row justify-content-start">
                    <div className="col-md-12 d-flex justify-content-between">
                      <h4>
                        <i className="entypo-list"></i> Add Menu Items
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="">
                    <div className="modal-body">
                      <div>
                        <label for="name">
                          Enter the new menu item name below
                        </label>
                        <input
                          name="name"
                          id="name"
                          placeholder="Menu Item Name"
                          className="form-control"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <input
                          name="name"
                          id="name"
                          placeholder="Menu Item Name"
                          className="form-control"
                          onChange={(e) => setParent_id(e.target.value)}
                          hidden
                        />
                        <br />
                        <div id="image">
                          <label for="name">Menu Item Image</label>
                          <br />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            id="image"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </div>

                        <br />
                        <label for="name">Show In-Home</label>
                        <div className="mt-2 d-flex align-items-center">
                          <div className="mr-2">OFF</div>
                          {/* <label className="switch">
														<input type="checkbox" name="in_home" />
														<span className="slider round"></span>
													</label> */}
                          <label className="switch">
                            <input
                              name="in_menu"
                              onChange={handleInput}
                              defaultChecked={
                                in_menu?.in_menu === 1 ? true : false
                              }
                              checked={in_menu?.in_menu === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="in_menu"
                              onChange={handleInput}
                              value={in_menu?.in_menu === 1 ? "1" : "0"}
                            ></span>
                          </label>
                          <div className="ml-2">ON</div>
                        </div>
                        <br />
                        <label for="name">Menu Item URL</label>
                        <div>
                          <select
                            className="form-control url_type mt-3"
                            onClick={(event) => {
                              setEnable_restream(event.target.value);
                            }}
                            onChange={(e) => setSelect_url(e.target.value)}
                          >
                            <option defaultValue="none">Select URL Type</option>
                            <option value="site_url">Site URL</option>
                            <option value="custom_url">Custom URL</option>
                          </select>
                        </div>
                        <div
                          onClick={(event) => {
                            setEnablestreamvisible(event.target.value);
                          }}
                        >
                          {enablestreams()}
                        </div>
                        <br />
                        <div>
                          <label for="dropdown">or Dropdown for:</label>
                          <select
                            name="select_url"
                            id="select_url"
                            className="form-control"
                            onChange={(e) => setType(e.target.value)}
                          >
                            {categories?.map((item, key) => (
                              <option  key={item?.id}
                                className="menu-dropdown-radio"
                                value={item?.name}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <br />
                      </div>
                    </div>

                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        id="submit-new-menu"
                        onClick={Addmenudata}
                      >
                        Save Menus
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddMenu;
