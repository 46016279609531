import React from "react";
import { Link } from "react-router-dom";

function AdsList() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Advertisement List</h4>
                      </div>
                    </div>
                    <div className="iq-card-body table-responsive">
                      <div className="table-view">
                        <table className="table table-striped table-bordered table movie_table ">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>Ads Name</th>
                              <th>Ads Category</th>
                              <th>Ads Play</th>
                              <th>Status</th>
                              <th>Created At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>name</td>
                              <td>categories name</td>
                              <td>ads_position</td>
                              <td>
                                <p className="btn btn-success status_change">
                                  Approved
                                </p>
                              </td>
                              <td></td>
                              {/* <td className="align-items-center list-inline">								
							            <Link to="{{ URL::to('admin/advertisement/edit') . '/' . $advertisement->id }}" className="iq-bg-success"></Link>
                                 <Link to="{{ URL::to('admin/advertisement/delete') . '/' . $advertisement->id }}" onclick="return confirm('Are you sure?')" className="iq-bg-danger"></Link>
                                 </td> */}

                              <td className="table-content">
                                <Link to={"/admin/edit_ads_list/:id"}>
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link>
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsList;
