import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function ImageSettings() {
  const [data, setData] = useState([]);
  const [editUser, setEditUser] = useState({
    compress_resolution_size: "",
    compress_resolution_format: "",
    enable_compress_image: "",
    audios: "",
    episode: "",
    season: "",
    series: "",
    live: "",
    videos: "",
  });
  const [message, setMessage] = useState("");
  const [play_button, setPlay_button] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.Compress_image;
        setEditUser(result);
        // setData(result2)
        // console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.compress_resolution_format;
        setData(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      compress_resolution_size: editUser.compress_resolution_size,
      compress_resolution_format: editUser.compress_resolution_format,
      enable_compress_image: editUser.enable_compress_image,
      audios: editUser.audios,
      episode: editUser.episode,
      season: editUser.season,
      series: editUser.series,
      live: editUser.live,
      videos: editUser.videos,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/compress_image_store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid ">
            <div id="content-page" className="p-2">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between mb-3">
                  <div className="iq-header-title">
                    <h4 className="card-title">Compress Image</h4>
                  </div>
                </div>

                <div className="">
                  <form>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <label for="">
                            {" "}
                            Compress Resolution Size <span> ( kb )</span>{" "}
                          </label>
                          <input
                            type="number"
                            name="compress_resolution_size"
                            id="compress_resolution_size"
                            placeholder="Compress Resolution Size"
                            className="form-control"
                            required
                            onChange={handleInput}
                            value={editUser?.compress_resolution_size}
                          />
                          <br />
                        </div>

                        <div className="col-md-6">
                          <label for=""> Compress Resolution Format </label>

                          <select
                            className="form-control"
                            name="compress_resolution_format"
                            id="compress_resolution_format"
                            onChange={handleInput}
                          >
                            <option value="webp" onChange={handleInput}>
                              {" "}
                              Webp Format{" "}
                            </option>
                            <option value="jpg" onChange={handleInput}>
                              {" "}
                              JPG Format{" "}
                            </option>
                            <option value="jpeg" onChange={handleInput}>
                              {" "}
                              JPEG Format{" "}
                            </option>
                          </select>
                        </div>

                        <div className="col-md-9 row py-3">
                          <label className="col-md-5" for="">
                            Enable Compress for Images{" "}
                          </label>
                          <div className="mt-1 col-md-4">
                            {/* <label className="switch">
                              <input name="enable_compress_image" id="enable_compress_image" onChange={handleInput} value={(editUser.enable_compress_image === 1 || editUser.enable_compress_image === null) ? '0' : (editUser.enable_compress_image === 1) ? '0' : '1'} className="enable_compress_image" type="checkbox" />
                              <span className="slider round" value={(editUser.enable_compress_image === 1 || editUser.enable_compress_image === null) ? '0' : (editUser.enable_compress_image === 0) ? '0' : '1'} onChange={handleInput}></span>
                            </label> */}
                            <label className="switch">
                              <input
                                name="enable_compress_image"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.enable_compress_image === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.enable_compress_image === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="enable_compress_image"
                                onChange={handleInput}
                                value={
                                  editUser?.enable_compress_image === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="iq-card-header d-flex justify-content-between mb-3">
                      <div className="iq-header-title">
                        <h4 className="card-title">
                          Dimension Image validation
                        </h4>
                      </div>
                    </div>

                    <div className="iq-card-body table-responsive p-0 text-center">
                      <div className="table-view">
                        <table
                          className="table table-striped table-bordered table movie_table iq-card"
                          id="Thumbnail"
                        >
                          <thead>
                            <tr className="r1">
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <td>1</td>
                            <td> Videos </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="videos" onChange={handleInput} value={(editUser.videos === 1 || editUser.videos === null) ? '0' : (editUser.videos === 1) ? '0' : '1'} className="videos" type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.videos === 1 || editUser.videos === null) ? '0' : (editUser.videos === 1) ? '0' : '1'} type="checkbox" ></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="videos"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.videos === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.videos === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="videos"
                                    onChange={handleInput}
                                    value={editUser?.videos === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 2</td>
                            <td> Live Videos </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="live" className="live" id="live" onChange={handleInput} value={(editUser.live === 1 || editUser.live === null) ? '0' : (editUser.live === 1) ? '0' : '1'} type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.live === 1 || editUser.live === null) ? '0' : (editUser.live === 1) ? '0' : '1'} type="checkbox"></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="live"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.live === 1 ? true : false
                                    }
                                    checked={editUser?.live === 1 ? true : false}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="live"
                                    onChange={handleInput}
                                    value={editUser?.live === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 3 </td>
                            <td> Series</td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="series" className="series" id="series" onChange={handleInput} value={(editUser.series === 1 || editUser.series === null) ? '0' : (editUser.series === 1) ? '0' : '1'} type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.series === 1 || editUser.series === null) ? '0' : (editUser.series === 1) ? '0' : '1'} type="checkbox"></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="series"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.series === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.series === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="series"
                                    onChange={handleInput}
                                    value={editUser?.series === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 4 </td>
                            <td> Season</td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="season" className="season" id="season" onChange={handleInput} value={(editUser.season === 1 || editUser.season === null) ? '0' : (editUser.season === 1) ? '0' : '1'} type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.season === 1 || editUser.season === null) ? '0' : (editUser.season === 1) ? '0' : '1'} type="checkbox"></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="season"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.season === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.season === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="season"
                                    onChange={handleInput}
                                    value={editUser?.season === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td>5 </td>
                            <td> Episode </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="episode" className="episode" id="episode" onChange={handleInput} value={(editUser.episode === 1 || editUser.episode === null) ? '0' : (editUser.episode === 1) ? '0' : '1'} type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.episode === 1 || editUser.episode === null) ? '0' : (editUser.episode === 1) ? '0' : '1'} type="checkbox"></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="episode"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.episode === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.episode === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="episode"
                                    onChange={handleInput}
                                    value={editUser?.episode === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 6</td>
                            <td> Audio </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                  <input name="audios" className="audios" id="audios" onChange={handleInput} value={(editUser.audios === 1 || editUser.audios === null) ? '0' : (editUser.audios === 1) ? '0' : '1'} type="checkbox" />
                                  <span className="slider round" onChange={handleInput} value={(editUser.audios === 1 || editUser.audios === null) ? '0' : (editUser.audios === 1) ? '0' : '1'} type="checkbox" ></span>
                                </label> */}
                                <label className="switch">
                                  <input
                                    name="audios"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.audios === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.audios === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="audios"
                                    onChange={handleInput}
                                    value={editUser?.audios === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 form-group" align="right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ImageSettings;
