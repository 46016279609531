import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

function EditManageAge() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    age: "",
    slug: "",
    active: "",
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Age_Category;
      setEditUser(res);
      // console.log(resData);
      // console.log(res);
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      age: editUser.age,
      slug: editUser.slug,
      active: editUser.active,
    };
    console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/age-category/update/${id}`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        navigate("/admin/age/index");
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="container-fluid p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i>Manage Age Category
                      </h4>
                    </div>
                    <hr />
                  </div>

                  <div className=" p-3 ">
                    <h5 className="">Age: </h5>
                    <div>
                      <input
                        type="text"
                        name="age"
                        value={editUser?.age}
                        onChange={handleInput}
                        className="form-control"
                        placeholder="age"
                      />
                    </div>
                  </div>
                  <div className=" p-3 ">
                    <h5 className="">Slug: </h5>
                    <div>
                      <input
                        type="text"
                        name="slug"
                        value={editUser?.slug}
                        onChange={handleInput}
                        className="form-control"
                        placeholder="slug"
                      />
                    </div>
                  </div>
                  <div className=" p-3 ">
                    <h5 className="">Status: </h5>

                    <div name="active">
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="1"
                        onChange={handleInput}
                      />{" "}
                      Active &nbsp;&nbsp;
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="0"
                        onChange={handleInput}
                      />{" "}
                      In Active
                    </div>
                  </div>

                  <div className="text-end p-3">
                    <button className="btn btn-primary " onClick={handleUpdate}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditManageAge;
