import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function ThumbnailSettings() {
  const [data, setData] = useState([]);
  const [editUser, setEditUser] = useState({
    title: "",
    reels_videos: "",
    trailer: "",
    age: "",
    rating: "",
    published_year: "",
    published_on: "",
    duration: "",
    category: "",
    featured: "",
    play_button: "",
    free_or_cost_label: "",
    play_button_path: "",
  });
  const [message, setMessage] = useState("");

  const [setting, setSetting] = useState({
    title: "",
    reels_videos: "",
    trailer: "",
    age: "",
    rating: "",
    published_year: "",
    published_on: "",
    duration: "",
    category: "",
    featured: "",
    play_button: "",
    free_or_cost_label: "",
    play_button_path: "",
  });

  const [play_button, setPlay_button] = useState("");
  const [play_button_path, setPlay_button_path] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/Thumbnail-Setting/index", {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var script = response?.data?.Thumbnail_Setting[0];
        var play_button = response?.data?.Thumbnail_Setting[0]?.play_button;
        var play_button_path =
          response?.data?.Thumbnail_Setting[0]?.play_button_path;
        setPlay_button(play_button);
        setPlay_button_path(play_button_path);
        setSetting(script);
        // console.log(script)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setSetting({ ...setting, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // console.log('append data',  logo,  favicon, );

    const formData = new FormData();

    formData.append("play_button", play_button);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Thumbnail-Setting/update`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/age/index')
          window.location.reload();
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });

    const editInputvalue = {
      title: setting.title,
      reels_videos: setting.reels_videos,
      trailer: setting.trailer,
      age: setting.age,
      rating: setting.rating,
      published_year: setting.published_year,
      published_on: setting.published_on,
      duration: setting.duration,
      category: setting.category,
      featured: setting.featured,
      play_button: setting.play_button,
      play_button_path: setting.play_button_path,
      free_or_cost_label: setting.free_or_cost_label,
    };
    console.log(editInputvalue);
    let respon = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Thumbnail-Setting/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    // console.log(resjson)
    if (respon.status === 200) {
      setMessage(respon.success);
      // alert('Success')
      window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="p-2">
              <div className="row">
                <div className="col-sm-12 card">
                  <div className="">
                    <div className="iq-card-header text-center">
                      <div className="iq-header-title">
                        <h4 className="card-title p-3">Thumbnail Setting </h4>
                      </div>
                    </div>

                    <div className="iq-card-body table-responsive p-0">
                      <div className="table-view">
                        <table
                          className="table table-striped table-bordered table movie_table iq-card text-center"
                          id="Thumbnail"
                        >
                          <thead>
                            <tr className="r1">
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <td> 1 </td>
                            <td> Title </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch"  >
                                                                    <input name='title' onChange={handleInput} value={(editUser.title === 1 || editUser.title === null) ? '0' : (editUser.title === 1) ? '0' : '1'} className="title" id="title" type="checkbox" />
                                                                    <span className="slider round" value={(editUser.title === 1 || editUser.title === null) ? '0' : (editUser.title === 0) ? '0' : '1'} onChange={handleInput}></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="title"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.title === 1 ? true : false
                                    }
                                    checked={setting?.title === 1 ? true : false}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="title"
                                    onChange={handleInput}
                                    value={setting?.title === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 2 </td>
                            <td> Age </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="age" onChange={handleInput} value={(editUser.age === 1 || editUser.age === null) ? '0' : (editUser.age === 1) ? '0' : '1'} id="age" className="age" type="checkbox" />
                                                                    <span className="slider round" name="age" onChange={handleInput} value={(editUser.age === 1 || editUser.age === null) ? '0' : (editUser.age === 1) ? '0' : '1'}></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="age"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.age === 1 ? true : false
                                    }
                                    checked={setting?.age === 1 ? true : false}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="age"
                                    onChange={handleInput}
                                    value={setting?.age === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='age' value={editUser.age}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 3 </td>
                            <td> Rating </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="rating" onChange={handleInput} value={(editUser.rating === 1 || editUser.rating === null) ? '0' : (editUser.rating === 1) ? '0' : '1'} className="rating" id="rating" type="checkbox" />
                                                                    <span className="slider round" name="rating" onChange={handleInput} value={(editUser.rating === 1 || editUser.rating === null) ? '0' : (editUser.rating === 1) ? '0' : '1'}></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="rating"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.rating === 1 ? true : false
                                    }
                                    checked={
                                      setting?.rating === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="rating"
                                    onChange={handleInput}
                                    value={setting?.rating === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='rating' value={editUser.rating}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 4 </td>
                            <td> Published Year </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="published_year" onChange={handleInput} value={(editUser.published_year === 1 || editUser.published_year === null) ? '0' : (editUser.published_year === 1) ? '0' : '1'} className="published_year" id="published_year" type="checkbox" />
                                                                    <span className="slider round" name="published_year" onChange={handleInput} value={(editUser.published_year === 1 || editUser.published_year === null) ? '0' : (editUser.published_year === 1) ? '0' : '1'}  ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="published_year"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.published_year === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      setting?.published_year === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="published_year"
                                    onChange={handleInput}
                                    value={
                                      setting?.published_year === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='published_year' value={editUser.published_year}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 5 </td>
                            <td> Published ON </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="published_on" onChange={handleInput} value={(editUser.published_on === 1 || editUser.published_on === null) ? '0' : (editUser.published_on === 1) ? '0' : '1'} className="published_on" id="published_on" type="checkbox" />
                                                                    <span className="slider round" name="published_on" onChange={handleInput} value={(editUser.published_on === 1 || editUser.published_on === null) ? '0' : (editUser.published_on === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="published_on"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.published_on === 1 ? true : false
                                    }
                                    checked={
                                      setting?.published_on === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="published_on"
                                    onChange={handleInput}
                                    value={
                                      setting?.published_on === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='published_on' value={editUser.published_on}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 6 </td>
                            <td> Duration </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="duration" onChange={handleInput} value={(editUser.duration === 1 || editUser.duration === null) ? '0' : (editUser.duration === 1) ? '0' : '1'} className="duration" id="duration" type="checkbox" />
                                                                    <span className="slider round" name="duration" onChange={handleInput} value={(editUser.duration === 1 || editUser.duration === null) ? '0' : (editUser.duration === 1) ? '0' : '1'}  ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="duration"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.duration === 1 ? true : false
                                    }
                                    checked={
                                      setting?.duration === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="duration"
                                    onChange={handleInput}
                                    value={setting?.duration === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='duration' value={editUser.duration}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 7 </td>
                            <td> Featured </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="featured" onChange={handleInput} value={(editUser.featured === 1 || editUser.featured === null) ? '0' : (editUser.featured === 1) ? '0' : '1'} className="featured" id="featured" type="checkbox" />
                                                                    <span className="slider round" name="featured" onChange={handleInput} value={(editUser.featured === 1 || editUser.featured === null) ? '0' : (editUser.featured === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="featured"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.featured === 1 ? true : false
                                    }
                                    checked={
                                      setting?.featured === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="featured"
                                    onChange={handleInput}
                                    value={setting?.featured === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='featured' value={editUser.featured}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 8 </td>
                            <td> Free or Cost Label </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="free_or_cost_label" onChange={handleInput} value={(editUser.free_or_cost_label === 1 || editUser.free_or_cost_label === null) ? '0' : (editUser.free_or_cost_label === 1) ? '0' : '1'} className="free_or_cost_label" id="free_or_cost_label" type="checkbox" />
                                                                    <span className="slider round" name="free_or_cost_label" onChange={handleInput} value={(editUser.free_or_cost_label === 1 || editUser.free_or_cost_label === null) ? '0' : (editUser.free_or_cost_label === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="free_or_cost_label"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.free_or_cost_label === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      setting?.free_or_cost_label === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="free_or_cost_label"
                                    onChange={handleInput}
                                    value={
                                      setting?.free_or_cost_label === 1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='free_or_cost_label' value={editUser.free_or_cost_label}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 9 </td>
                            <td> Play button </td>
                            <td>
                              <div className="mt-1 text-center">
                                <div>
                                  <img
                                    src={play_button_path}
                                    htmlfor="play_button"
                                    name="play_button_path"
                                  />
                                </div>
                                <div className="col-md-12 mt-2 tex-center">
                                  <input
                                    name="play_button"
                                    onChange={(e) =>
                                      setPlay_button(e.target.files[0])
                                    }
                                    type="file"
                                    id="play_button"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              {/* <input type="file"  name='play_button' value={editUser.play_button}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 10 </td>
                            <td> Category </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="category" onChange={handleInput} value={(editUser.category === 1 || editUser.category === null) ? '0' : (editUser.category === 1) ? '0' : '1'} className="category" id="category" type="checkbox" />
                                                                    <span className="slider round" name="category" onChange={handleInput} value={(editUser.category === 1 || editUser.category === null) ? '0' : (editUser.category === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="category"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.category === 1 ? true : false
                                    }
                                    checked={
                                      setting?.category === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="category"
                                    onChange={handleInput}
                                    value={setting?.category === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='category' value={editUser.category}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 11 </td>
                            <td> Trailer </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="trailer" onChange={handleInput} value={(editUser.trailer === 1 || editUser.trailer === null) ? '0' : (editUser.trailer === 1) ? '0' : '1'} id="trailer" className="trailer" type="checkbox" />
                                                                    <span className="slider round" name="trailer" onChange={handleInput} value={(editUser.trailer === 1 || editUser.trailer === null) ? '0' : (editUser.trailer === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="trailer"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.trailer === 1 ? true : false
                                    }
                                    checked={
                                      setting?.trailer === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="trailer"
                                    onChange={handleInput}
                                    value={setting?.trailer === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='trailer' value={editUser.trailer}  onChange={handleInput} /> */}
                            </td>
                          </tbody>

                          <tbody>
                            <td> 12 </td>
                            <td> Reels Videos </td>
                            <td>
                              <div className="mt-1">
                                {/* <label className="switch">
                                                                    <input name="reels_videos" onChange={handleInput} value={(editUser.reels_videos === 1 || editUser.reels_videos === null) ? '0' : (editUser.reels_videos === 1) ? '0' : '1'} id="reels_videos" className="reels_videos" type="checkbox" />
                                                                    <span className="slider round" name="reels_videos" onChange={handleInput} value={(editUser.reels_videos === 1 || editUser.reels_videos === null) ? '0' : (editUser.reels_videos === 1) ? '0' : '1'} ></span>
                                                                </label> */}
                                <label className="switch">
                                  <input
                                    name="reels_videos"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.reels_videos === 1 ? true : false
                                    }
                                    checked={
                                      setting?.reels_videos === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="reels_videos"
                                    onChange={handleInput}
                                    value={
                                      setting?.reels_videos === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                              {/* <input name='reels_videos' value={editUser.reels_videos}  onChange={handleInput} /> */}
                            </td>
                          </tbody>
                        </table>

                        <div className="col-md-12 form-group p-3" align="right">
                          <input
                            type="submit"
                            value="Update Settings"
                            onClick={handleUpdate}
                            className="btn btn-primary "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ThumbnailSettings;
