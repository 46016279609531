import { Link, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import "./Addnewvideo.css";
import Dropzone from "react-dropzone";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
// import loadings from "../../../components/Images/loading/loading.gif";

export default function Addnewvideo(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [file, setFile] = useState("");
  const [video_id, setVideo_id] = useState("");

  const [video_type, setVideo_type] = useState("");
  const [m3u8_url, setM3u8_url] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [rating, setRating] = useState("");
  const [duration, setDuration] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [mobile_image, setMobile_image] = useState("");
  const [tablet_image, setTablet_image] = useState("");
  const [enable_video_title_image, setEnable_video_title_image] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");
  const [android_tv, setAndroid_tv] = useState("");
  const [video_gif, setVideo_gif] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [access, setAccess] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [enable, setEnable] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [disk, setDisk] = useState("");
  const [original_name, setOriginal_name] = useState("");
  const [path, setPath] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [Recommendation, setRecommendation] = useState("");
  const [country, setCountry] = useState("");
  const [publish_status, setPublish_status] = useState("");
  const [publish_time, setPublish_time] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [url, setUrl] = useState("");
  const [url_link, setUrl_link] = useState("");
  const [url_linktym, setUrl_linktym] = useState("");
  const [url_linksec, setUrl_linksec] = useState("");
  const [urlEnd_linksec, setUrlEnd_linksec] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailer_filename, setTrailer_filename] = useState("");
  const [trailer_description, setTrailer_description] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [free_duration_status, setFree_duration_status] = useState("");
  const [default_ads, setDefault_ads] = useState("");
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [pre_ads, setPre_ads] = useState("");
  const [mid_ads, setMid_ads] = useState("");
  const [post_ads, setPost_ads] = useState("");
  const [time_zone, setTime_zone] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [user_id, setUser_id] = useState("");
  const [video_category_id, setVideo_category_id] = useState("");
  const [artist, setArtist] = useState("");
  const [ads_tag_url_id, setAds_tag_url_id] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);

  const [message, setMessage] = useState("");

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  // const [formDatastore, setFormData] = useState(new FormData());

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [videosreels, setVideosreels] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [publish_type, setPublish_type] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    slug: "",
  });
  // console.log(editUser)

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const [inputValueyear, setInputValueyear] = useState("");

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValueyear(value);
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 1,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 0,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const [userTime, setUserTime] = useState({
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    url_linktym: "",
  });

  // const handleInputintrotime = (e) => {
  //   const inputName = e.target.name;
  //   const inputValue = e.target.value;

  //   const formattedInput = inputValue.replace(/\D/g, "").slice(0, 6);
  //   setUserTime({
  //     ...userTime,
  //     [inputName]: formattedInput,
  //   });

  //   // Call the store function immediately when input changes
  //   // storeUserData(inputName, formattedInput);
  // };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime === "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime === "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime === "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime === "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  // const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadCompleteddata, setUploadCompleteddata] = useState(false);

  async function urlsupload() {
    const formData = new FormData();

    formData.append("video_type", selectedOption);
    formData.append("m3u8_url", m3u8_url);
    formData.append("mp4_url", mp4_url);
    formData.append("embed_code", embed_code);
    // console.log('append data', video_type,)

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/videos-url`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var resultapi = response.data;
          var result = response.data.video_id;
          // console.log('result', result);
          // console.log('result', resultapi);
          setVideo_id(result);
          setUploadCompleteddata(true);
          props.setApiresponsealert(resultapi);
          props.setShowalert(true);
        } else {
          if (response.data.status === false) {
            var resultError = response.data;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
            // console.log(resultError)
          }
        }
      });
  }

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };
  const contentFieldChanagedtraildes = (data) => {
    setTrailer_description(data);
    // console.log("datasssssss", data);
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-category-depends`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          // console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4,
    setSelectedFile5
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
    setSelectedFile5(null);
    if (fileInputRef5.current) {
      fileInputRef5.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);
  const [countryblock_country, setCountryblock_country] = useState([]);
  const [
    selectedOptionsCountryblock_country,
    setSelectedOptionsCountryblock_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
        { headers: headers }
      );
      setCountryblock_country(response.data.Block_Country);
      setAvailable_country(response.data.Available_country);
      setRelated_videos(response.data.Related_videos);
      setLanguage(response.data.video_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response.data.video_artist);
      setCategory(response.data.video_category);
      setInputValueAPI(response.data.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      // setAlbum(response?.data?.audio_albums);
      const subtitlesmap = response?.data?.video_subtitle;
      setSubtitlesmap(subtitlesmap);
      var age_restrictdata = response.data.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      //  console.log(response.data);
      // console.log(response.data.video_artist);
      // console.log(age_restrictdata);
      // console.log(response.data.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };
  const handleSelectChangeCountryblock_country = (selectedValues) => {
    setSelectedOptionsCountryblock_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) === index);
    setSearchTags(cleanedTags);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  // async function videoupload() {
  //   let focusInputRef = null;

  //   if (editUser?.title === "") {
  //     setValidationMessagetitle("Title cannot be empty.");
  //     setIsValidationHiddentitle(false);
  //     focusInputRef = titleInputRef;
  //   } else if (selectedOptionscategory.length === 0) {
  //     setValidationMessagecategory("Please select at least one Category.");
  //     setIsValidationHiddencategory(false);
  //     focusInputRef = categoryInputRef;
  //   } else if (selectedOptionslanguage.length === 0) {
  //     setValidationMessagelanguage("Please select at least one Language.");
  //     setIsValidationHiddenlanguage(false);
  //     focusInputRef = languageInputRef;
  //   }

  //   if (focusInputRef) {
  //     focusInputRef.current.focus();
  //     return;
  //   }

  //   const editInputvalue = active.active;
  //   var data = JSON.stringify(editInputvalue);
  //   var aactivedata = data;

  //   const editInputglobal_ppv = global_ppv.global_ppv;
  //   var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
  //   var global_ppvdata = dataglobal_ppv;

  //   const editInputfeatured = featured.featured;
  //   var datafeatured = JSON.stringify(editInputfeatured);
  //   var featureddata = datafeatured;

  //   const editInputbanner = banner.banner;
  //   var databanner = JSON.stringify(editInputbanner);
  //   var bannerdata = databanner;

  //   const editInputenable_video_title_image =
  //     enable_video_title_image.enable_video_title_image;
  //   var dataenable_video_title_image = JSON.stringify(
  //     editInputenable_video_title_image
  //   );
  //   var enable_video_title_imagedata = dataenable_video_title_image;

  //   // const formData = new FormData();

  //   formDatastore.append("video_id", video_id);
  //   formDatastore.append("title", editUser?.title);
  //   formDatastore.append("slug", autoFillValuetitle);
  //   formDatastore.append("type", type);
  //   formDatastore.append("active", aactivedata);
  //   formDatastore.append("status", status);
  //   formDatastore.append("draft", draft);
  //   formDatastore.append("rating", rating);
  //   formDatastore.append("duration", editUser?.duration);
  //   formDatastore.append("details", details);
  //   formDatastore.append("description", description);
  //   formDatastore.append("android_tv", android_tv);
  //   formDatastore.append("video_gif", video_gif);
  //   formDatastore.append("skip_intro", insertColons(userTime.skip_intro));
  //   formDatastore.append("search_tags", search_tags);
  //   formDatastore.append("access", access);
  //   formDatastore.append("global_ppv", global_ppvdata);
  //   formDatastore.append("ppv_price", inputValue);
  //   formDatastore.append("ios_ppv_price", ios_ppv_price);
  //   formDatastore.append("featured", featureddata);
  //   formDatastore.append("banner", bannerdata);
  //   formDatastore.append("enable", enable);
  //   formDatastore.append("webm_url", webm_url);
  //   formDatastore.append("ogg_url", ogg_url);
  //   formDatastore.append("disk", disk);
  //   formDatastore.append("original_name", original_name);
  //   formDatastore.append("path", path);
  //   formDatastore.append("stream_path", stream_path);
  //   formDatastore.append("processed_low", processed_low);
  //   formDatastore.append(
  //     "converted_for_streaming_at",
  //     converted_for_streaming_at
  //   );
  //   formDatastore.append("views", views);
  //   formDatastore.append("year", inputValueyear);

  //   formDatastore.append(
  //     "artist_id",
  //     JSON.stringify(selectedOptionsartists?.map((option) => option.value))
  //   );
  //   formDatastore.append(
  //     "block_country_id",
  //     JSON.stringify(
  //       selectedOptionsCountryblock_country?.map((option) => option.value)
  //     )
  //   );
  //   formDatastore.append(
  //     "available_country_id",
  //     JSON.stringify(
  //       selectedOptionsAvailable_country?.map((option) => option.value)
  //     )
  //   );
  //   formDatastore.append(
  //     "category_id",
  //     JSON.stringify(selectedOptionscategory?.map((option) => option.value))
  //   );
  //   formDatastore.append(
  //     "languages_id",
  //     JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
  //   );
  //   formDatastore.append("age_restrict", age_restrict);
  //   formDatastore.append(
  //     "related_videos",
  //     JSON.stringify(
  //       selectedOptionsRelated_videos?.map((option) => option.value)
  //     )
  //   );

  //   formDatastore.append("Recommendation", Recommendation);
  //   formDatastore.append("country", country);
  //   formDatastore.append("publish_status", publish_status);
  //   formDatastore.set("publish_type", publishType);
  //   formDatastore.append("skip_recap", insertColons(userTime.skip_recap));
  //   formDatastore.append(
  //     "recap_end_time",
  //     insertColons(userTime.recap_end_time)
  //   );
  //   formDatastore.append(
  //     "recap_start_time",
  //     insertColons(userTime.recap_start_time)
  //   );
  //   formDatastore.append(
  //     "intro_start_time",
  //     insertColons(userTime.intro_start_time)
  //   );
  //   formDatastore.set("publish_time", publishTime);
  //   formDatastore.set("publish_type", publishType);
  //   formDatastore.append(
  //     "intro_end_time",
  //     insertColons(userTime.intro_end_time)
  //   );
  //   formDatastore.append("pdf_files", pdf_files);
  //   formDatastore.append("reels_thumbnail", reels_thumbnail);
  //   formDatastore.append(
  //     "enable_video_title_image",
  //     enable_video_title_imagedata
  //   );
  //   formDatastore.append("reelvideo", reelvideo);
  //   formDatastore.append("url", url);
  //   formDatastore.append("url_link", url_link);
  //   formDatastore.append("url_linktym", insertColons(userTime.url_linktym));
  //   formDatastore.append("url_linksec", url_linksec);
  //   formDatastore.append("urlEnd_linksec", urlEnd_linksec);
  //   formDatastore.append("trailer_type", trailer_type);
  //   formDatastore.append("trailer", selectedFiles);
  //   formDatastore.append("trailer_description", trailer_description);
  //   formDatastore.append("ads_status", ads_status);
  //   formDatastore.append("default_ads", default_ads);
  //   formDatastore.append("pre_ads_category", pre_ads_category);
  //   formDatastore.append("mid_ads_category", mid_ads_category);
  //   formDatastore.append("post_ads_category", post_ads_category);
  //   formDatastore.append("pre_ads", pre_ads);
  //   formDatastore.append("mid_ads", mid_ads);
  //   formDatastore.append("post_ads", post_ads);
  //   formDatastore.append("time_zone", time_zone);
  //   formDatastore.append("home_genre", home_genre);
  //   formDatastore.append("in_menu", in_menu);
  //   formDatastore.append("footer", footer);
  //   formDatastore.append("uploaded_by", uploaded_by);
  //   formDatastore.append("user_id", user_id);
  //   formDatastore.append("ads_tag_url_id", ads_tag_url_id);

  //   formDatastore.append("ads_position", selectedAdsPosition?.value);
  //   formDatastore.append("live_ads", secondSelectOptions?.value);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/video-store`,
  //       formDatastore,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       // console.log('api' , response);
  //       if (response.data.status == true) {
  //         var result = response.data.video_id;
  //         // console.log(result);
  //         var resultapi = response.data;

  //         const formData = new FormData();
  //         formData.append("video_id", video_id);
  //         if (selectedFile1) {
  //           formData.append("image", selectedFile1);
  //         }
  //         if (selectedFile2) {
  //           formData.append("player_image", selectedFile2);
  //         }
  //         if (selectedFile3) {
  //           formData.append("video_tv_image", selectedFile3);
  //         }
  //         if (selectedFile4) {
  //           formData.append("video_title_image", selectedFile4);
  //         }

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
  //             formData,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log("File(s) uploaded successfully:", response.message);
  //             // Perform any actions with the response data as needed.
  //             //       // navigate('/admin/all-artist')
  //             // navigate("/admin/allvideo");
  //             // alert(response.data.message + "!");
  //           })
  //           .catch((error) => {
  //             // console.error("Error uploading file(s):", error);
  //             // Handle any errors that occurred during the upload process.
  //           });

  //         const formData1 = new FormData();

  //         videosreels.forEach((video, index) => {
  //           formData1.append(`reels_videos[${index}]`, video);
  //         });

  //         formData1.append("video_id", video_id);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/video-reels`,
  //             formData1,
  //             { headers: headers }
  //           )
  //           .then((response) => {})
  //           .catch((error) => {});

  //         const formDatasubtitles = new FormData();

  //         Object.keys(videosubtitles).forEach((language, index) => {
  //           formDatasubtitles.append(
  //             `short_code[${index}]`,
  //             Subtitlesmap[index].short_code
  //           );
  //           formDatasubtitles.append(
  //             `language[${index}]`,
  //             Subtitlesmap[index].language
  //           );
  //           videosubtitles[language].forEach((file) => {
  //             formDatasubtitles.append(`subtitles[${index}]`, file);
  //           });
  //         });
  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles`,
  //             formDatasubtitles,
  //             {
  //               headers: headers,
  //             }
  //           )
  //           .then((response) => {})
  //           .catch((error) => {
  //             console.error("Error uploading file(s):", error);
  //             // Handle any errors that occurred during the upload process.
  //           });

  //         const formDatareel = new FormData();

  //         formDatareel.append("video_id", video_id);
  //         // formData1.append("reels_videos", reelvideo);
  //         if (selectedFile5) {
  //           formDatareel.append("reels_thumbnail", selectedFile5);
  //         }

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/video-reels-thumbnail`,
  //             formDatareel,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log("File(s) uploaded successfully:", response.message);
  //             // Perform any actions with the response data as needed.
  //             //       // navigate('/admin/all-artist')
  //             // navigate("admin/allvideo");
  //             // alert(response.data.message + "!");
  //           })
  //           .catch((error) => {
  //             // console.error("Error uploading file(s):", error);
  //             // Handle any errors that occurred during the upload process.
  //           });

  //         const formDataseo = new FormData();
  //         formDataseo.append("video_id", video_id);
  //         formDataseo.append("website_page_title", websitetitle);
  //         formDataseo.append("website_URL", websiteurl);
  //         formDataseo.append("Meta_description", metadescription);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
  //             formDataseo,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log("File(s) uploaded successfully:", response.data);
  //           });

  //         const formDatass = new FormData();

  //         formDatass.append("video_id", video_id);
  //         formDatass.append("trailer", trailer);
  //         formDatass.append("trailer_type", trailer_type);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
  //             formDatass,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log('api' , response);
  //             if (response.data.status == true) {
  //               var result = response.data;
  //               // console.log('result', response);
  //               // navigate('/admin/all-artist')
  //               // alert("Added successfully  !")
  //             }
  //           });

  //         const formD = new FormData();

  //         formD.append("video_id", video_id);
  //         formD.append("trailer", trailer);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
  //             formD,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log('api' , response);
  //             if (response.data.status == true) {
  //               var result = response.data;
  //               // console.log('result', response);
  //               // navigate('/admin/all-artist')
  //             }
  //           });

  //         const formDa = new FormData();

  //         formDa.append("video_id", video_id);
  //         formDa.append("pdf_file", pdf_files);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/Epaper-video`,
  //             formDa,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log('api' , response);
  //             if (response.data.status == true) {
  //               var result = response.data;
  //               // console.log('result', response);
  //               // navigate('admin/allvideo')
  //             }
  //           });
  //         // navigate("/admin/allvideo");
  //         // alert("Added successfully  !");
  //         setValidationMessagetitle("");
  //         setValidationMessageslug("");
  //         setValidationMessagecategory("");
  //         setValidationMessagelanguage("");

  //         props.setApiresponsealert(resultapi);
  //         props.setShowalert(true);
  //         setTimeout(() => {
  //           // window.location.reload();
  //           navigate("/admin/allvideo");
  //         }, 3000);
  //       } else {
  //         // console.log("Error");
  //         // alert("Enter Correct Details");
  //         if (response.data.status === false) {
  //           // setMessage("Some error Occured");
  //           // console.log("Error");
  //           // alert("Enter Correct Details");
  //           var resultError = response.data;
  //           props.setApiresponsealert(resultError);
  //           props.setShowalert(true);

  //           setIsValidationHiddentitle(false);
  //           setValidationMessageslug(false);
  //           setIsValidationHiddencategory(false);
  //           setIsValidationHiddenlanguage(false);
  //         }
  //       }
  //     });
  // }

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function videoupload() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (selectedOptionslanguage?.length === 0) {
      setValidationMessagelanguage("Please select at least one Language.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputfree_duration_statusdata =
      free_duration_status.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const editInputglobal_ppv = global_ppv.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputenable_video_title_image =
      enable_video_title_image.enable_video_title_image;
    var dataenable_video_title_image = JSON.stringify(
      editInputenable_video_title_image
    );
    var enable_video_title_imagedata = dataenable_video_title_image;

    const formDatastore = new FormData();

    formDatastore.append("video_id", video_id);
    formDatastore.append("title", editUser?.title);
    formDatastore.append("slug", autoFillValuetitle);
    formDatastore.append("type", type);
    formDatastore.append("active", aactivedata);
    formDatastore.append("status", status);
    formDatastore.append("draft", draft);
    formDatastore.append("rating", rating);
    formDatastore.append("duration", editUser?.duration);
    formDatastore.append("details", details);
    formDatastore.append("description", description);
    formDatastore.append("android_tv", android_tv);
    formDatastore.append("video_gif", video_gif);
    formDatastore.append("search_tags", searchTags);
    formDatastore.append("access", access);
    formDatastore.append("global_ppv", global_ppvdata);
    formDatastore.append("ppv_price", inputValue);
    formDatastore.append("ios_ppv_price", ios_ppv_price);
    formDatastore.append("featured", featureddata);
    formDatastore.append("banner", bannerdata);
    formDatastore.append("enable", enable);
    formDatastore.append("webm_url", webm_url);
    formDatastore.append("ogg_url", ogg_url);
    formDatastore.append("disk", disk);
    formDatastore.append("original_name", original_name);
    formDatastore.append("path", path);
    formDatastore.append("stream_path", stream_path);
    formDatastore.append("processed_low", processed_low);
    formDatastore.append(
      "converted_for_streaming_at",
      converted_for_streaming_at
    );
    formDatastore.append("views", views);
    formDatastore.append("year", inputValueyear);

    formDatastore.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formDatastore.append(
      "block_country_id",
      JSON.stringify(
        selectedOptionsCountryblock_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formDatastore.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formDatastore.append("age_restrict", age_restrict);
    formDatastore.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );

    formDatastore.append("Recommendation", Recommendation);
    formDatastore.append("country", country);
    formDatastore.append("publish_status", publish_status);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("skip_start_time", userTime?.skip_start_time);
    formDatastore.append("skip_end_time", userTime?.skip_end_time);
    formDatastore.append("skip_start_session", userTime?.skip_start_session);
    formDatastore.append("recap_start_time", userTime?.recap_start_time);
    formDatastore.append("recap_end_time", userTime?.recap_end_time);
    formDatastore.append("recap_start_session", userTime?.recap_start_session);

    formDatastore.append("url_linktym", insertColons(userTime?.url_linktym));
    formDatastore.set("publish_time", publishTime);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("pdf_files", pdf_files);
    formDatastore.append("reels_thumbnail", reels_thumbnail);
    formDatastore.append(
      "enable_video_title_image",
      enable_video_title_imagedata
    );
    formDatastore.append("reelvideo", reelvideo);
    formDatastore.append("url", url);
    formDatastore.append("url_link", url_link);
    formDatastore.append("url_linksec", url_linksec);
    formDatastore.append("urlEnd_linksec", urlEnd_linksec);
    formDatastore.append("trailer_type", trailer_type);
    formDatastore.append("trailer", selectedFiles);
    formDatastore.append("trailer_description", trailer_description);
    formDatastore.append("ads_status", ads_status);
    formDatastore.append("free_duration_status", free_duration_statusdata);
    formDatastore.append("free_duration_time", editUser?.free_duration_time);
    formDatastore.append("default_ads", default_ads);
    formDatastore.append("pre_ads_category", pre_ads_category);
    formDatastore.append("mid_ads_category", mid_ads_category);
    formDatastore.append("post_ads_category", post_ads_category);
    formDatastore.append("pre_ads", pre_ads);
    formDatastore.append("mid_ads", mid_ads);
    formDatastore.append("post_ads", post_ads);
    formDatastore.append("time_zone", time_zone);
    formDatastore.append("home_genre", home_genre);
    formDatastore.append("in_menu", in_menu);
    formDatastore.append("footer", footer);
    formDatastore.append("uploaded_by", uploaded_by);
    formDatastore.append("user_id", user_id);
    formDatastore.append("ads_tag_url_id", ads_tag_url_id);
    formDatastore.append("draft", 1);

    formDatastore.append("ads_position", selectedAdsPosition?.value);
    formDatastore.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let fourthApiStatus = false;
    let fifthApiStatus = false;
    let sixthApiStatus = false;
    let seventhApiStatus = false;
    let eighthApiStatus = false;
    let ninethApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video-store`,
        formDatastore,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
        var result = response.data.video_id;

        const imageFormData = new FormData();
        imageFormData.append("video_id", video_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("video_tv_image", selectedFile3);
        }
        if (selectedFile4) {
          imageFormData.append("video_title_image", selectedFile4);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
          imageFormData,
          { headers: headers }
        );

        const formData1 = new FormData();

        videosreels.forEach((video, index) => {
          formData1.append(`reels_videos[${index}]`, video);
        });

        formData1.append("video_id", video_id);

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels`,
          formData1,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("video_id", video_id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos/subtitles`,
          formDatasubtitles,
          { headers: headers }
        );

        const formDatareel = new FormData();

        formDatareel.append("video_id", video_id);
        // formData1.append("reels_videos", reelvideo);
        if (selectedFile5) {
          formDatareel.append("reels_thumbnail", selectedFile5);
        }

        const formDatareelresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-reels-thumbnail`,
          formDatareel,
          { headers: headers }
        );

        const formDaEpaper = new FormData();

        formDaEpaper.append("video_id", video_id);
        formDaEpaper.append("pdf_file", pdf_files);

        const formDaEpaperresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Epaper-video`,
          formDaEpaper,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("video_id", video_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatatrailerurl = new FormData();

        formDatatrailerurl.append("video_id", video_id);
        formDatatrailerurl.append("trailer", trailer);
        formDatatrailerurl.append("trailer_type", trailer_type);

        const formDatatrailerurlresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
          formDatatrailerurl,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("video_id", video_id);
        formDtrailervideo.append("trailer", trailer);
        formDtrailervideo.append("trailer_filename", trailer_filename);

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse.data.status === true) {
          secondApiStatus = true;
        }
        if (formData1response.data.status === true) {
          thirdApiStatus = true;
        }
        if (formDatasubtitlesresponse.data.status === true) {
          fourthApiStatus = true;
        }
        if (formDatareelresponse.data.status === true) {
          fifthApiStatus = true;
        }
        if (formDaEpaperresponse.data.status === true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse.data.status === true) {
          seventhApiStatus = true;
        }
        if (formDatatrailerurlresponse.data.status === true) {
          eighthApiStatus = true;
        }
        if (formDtrailervideoresponse.data.status === true) {
          ninethApiStatus = true;
        }
      } else if (response.data.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) {}

    if (
      firstApiStatus &&
      secondApiStatus &&
      thirdApiStatus &&
      fourthApiStatus &&
      fifthApiStatus &&
      sixthApiStatus &&
      seventhApiStatus &&
      eighthApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/admin/allvideo");
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data.ads_position.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
        headers: headers,
      })
      .then((response) => {
        var ads_category = response.data.ads_category;
        // setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);

        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(ads_category)
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-5 mb-5 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    onChange={(e) => setM3u8_url(e.target.value)}
                    placeholder="m3u8 Trailer"
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                    onChange={(e) => setMp4_url(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                    onChange={(e) => setEmbed_code(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                  handleNext();
                }}
                className="btn btn-primary"
              >
                Proceed to Next
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  // const [enable_restreampublic, setEnable_restreampublic] = useState("");
  // const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
  //   useState("");

  // function proceednextpublic() {
  //   switch (enable_restreampublic) {
  //     case "publish_now":
  //       return <div></div>;
  //     case "publish_later":
  //       return (
  //         <div className="mb-3">
  //           <label className="m-0">Publish Time</label>
  //           <input
  //             type="datetime-local"
  //             className="form-control"
  //             id="publish_time"
  //             name="publish_time"
  //             onChange={(e) => setPublish_time(e.target.value)}
  //           />
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNext} className="btn btn-primary">
                Proceed to Next{" "}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-12">
            <label className="mb-1 mt-2"> Upload Trailer : </label>

            <div className="file-draganddrop rs-input">
              <div
                {...getRootProps()}
                className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the video file here...</p>
                ) : (
                  <p>Drag and drop your video file here</p>
                )}
              </div>
              {selectedFiles && (
                <div>
                  <p>{selectedFiles.name}</p>
                </div>
              )}
            </div>
            {/* <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-lg rs-input"
                      onChange={(e) => setTrailer(e.target.files[0])}
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
            </div> */}
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp3_url"
                    onChange={(e) => setTrailer(e.target.value)}
                    placeholder="m3u8 URL"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp4_trailer"
                    placeholder="mp4 Trailer"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div class="row mt-3 text-start ">
            <div class="col-sm-12">
              <label class="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p class="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div class="panel-body">
                <input
                  type="number"
                  class="rs-input"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  // onChange={(e) => setPpv_price(e.target.value)}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="col-sm-12 mt-2">
              <label class="m-0"> IOS PPV Price</label>
              <p class="p1">Apply IOS PPV Price from Global Settings?</p>
              <div class="panel-body ppv_price_ios  mt-2">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select"
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // const handleUpload = async (files) => {
  //   const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   const uploaders = files.map((file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     return axios.post(uploadUrl, formData, {
  //       headers: headers,
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         setUploadProgress(percentCompleted);
  //       },
  //     });
  //   });

  //   try {
  //     await Promise.all(uploaders);

  //     const responses = await Promise.all(uploaders);
  //     // console.log("Responses after upload:", responses);
  //     if (responses.data.status === true) {

  //     responses.forEach((response, index) => {
  //       console.log(`Response for video ${index + 1}:`, response.data.Videos);
  //       setEditUser(response.data.Videos);
  //       setVideo_id(response.data.video_id);
  //       // Process the response data as needed for each video
  //     });
  //     const uploadedDetails = files.map((file, index) => ({
  //       name: file.name,
  //       size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
  //     }));

  //     var videosapi = responses?.data
  //     setUploadedFiles(uploadedDetails);
  //     setUploadCompleted(true);
  //     setUploadProgress(0);
  //     // alert("All videos uploaded successfully!");
  //     props.setApiresponsealert(videosapi);
  //       props.setShowalert(true);

  //     }
  //     else {
  //       if (responses.data.status === false) {
  //       var resultError = responses.data.message;
  //             props.setApiresponsealert(resultError);
  //             props.setShowalert(true);
  //             setUploadProgress(0);
  //             // console.log(resultError)
  //       }
  //     }
  //   } catch (error) {
  //     // if (error.data.status === false) {
  //     //   var resultError = error.data.message;
  //     //         props.setApiresponsealert(resultError);
  //     //         props.setShowalert(true);
  //     //         // console.log(resultError)
  //     //         setUploadProgress(0);
  //     //   }
  //   }
  // };

  const handleUpload = async (files) => {
    // API endpoint for video upload
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`;

    // Headers for the request
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token, // You need to define access_token
      "Access-Control-Allow-Origin": "*",
    };

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      const responses = await Promise.all(uploaders);

      if (responses[0].data.status === true) {
        responses.forEach((response, index) => {
          // Process the response data for each video
          // console.log(`Response for video ${index + 1}:`, response.data.Videos);
          setEditUser(response.data.Videos);
          setVideo_id(response.data.video_id);
        });

        // Create details about uploaded files
        const uploadedDetails = files.map((file, index) => ({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2),
        }));

        // Update state and show success alert
        setUploadedFiles(uploadedDetails);
        setUploadCompleted(true);
        setUploadProgress(0);
        props.setApiresponsealert(responses[0].data);
        props.setShowalert(true);
      } else {
        // Handle error response
        var resultError = responses[0].data;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setUploadProgress(0);
      }
    } catch (error) {
      // Handle general error case
      // console.error("Error uploading videos:", error);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const [timeskip_intro, setTimeskip_intro] = useState("");
  const [formattedTimeskip_intro, setFormattedTimeskip_intro] = useState("");

  const formatTimeskip_intro = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangeskip_intro = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimeskip_intro(input);

    setTimeskip_intro(formattedInput);
    setFormattedTimeskip_intro(formattedInput);
  };

  const [timesintrostarttime, setTimesintrostarttime] = useState("");
  const [formattedTimesintrostarttime, setFormattedTimesintrostarttime] =
    useState("");

  const formatTimesintrostarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintrostarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintrostarttime(input);

    setTimesintrostarttime(formattedInput);
    setFormattedTimesintrostarttime(formattedInput);
  };

  const [timesintroendtime, setTimesintroendtime] = useState("");
  const [formattedTimesintroendtime, setFormattedTimesintroendtime] =
    useState("");

  const formatTimesintroendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintroendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintroendtime(input);

    setTimesintroendtime(formattedInput);
    setFormattedTimesintroendtime(formattedInput);
  };

  const [timesskiprecaptime, setTimesskiprecaptime] = useState("");
  const [formattedTimesskiprecaptime, setFormattedTimesskiprecaptime] =
    useState("");

  const formatTimesskiprecaptime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskiprecaptime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskiprecaptime(input);

    setTimesskiprecaptime(formattedInput);
    setFormattedTimesskiprecaptime(formattedInput);
  };

  const [timesskipstarttime, setTimesskipstarttime] = useState("");
  const [formattedTimesskipstarttime, setFormattedTimesskipstarttime] =
    useState("");

  const formatTimesskipstarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskipstarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipstarttime(input);

    setTimesskipstarttime(formattedInput);
    setFormattedTimesskipstarttime(formattedInput);
  };

  const [timesskipendtime, setTimesskipendtime] = useState("");
  const [formattedTimesskipendtime, setFormattedTimesskipendtime] =
    useState("");

  const formatTimesskipendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskipendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipendtime(input);

    setTimesskipendtime(formattedInput);
    setFormattedTimesskipendtime(formattedInput);
  };

  const datadata = [
    {
      value: "add_video",
      label: "Video Upload",
      content: (
        <div>
          <Dropzone
            accept="video/*"
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="draganddrop">
                  Drag and drop video files here, or click to select files
                </p>
              </div>
            )}
          </Dropzone>
          <div className="text-center">
            {uploadProgress > 0 && (
              <div>
                <p>Uploading... {uploadProgress}%</p>
                <progress value={uploadProgress} max="100" />
              </div>
            )}

            {uploadCompleted && (
              <div>
                {uploadedFiles.length > 0 && (
                  <div className="text-center">
                    <p>Uploaded Files:</p>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>
                          {file.name} - {file.size} MB
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            )}

            {/* {uploadCompleted && (
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            )} */}
          </div>
        </div>
      ),
    },
    {
      value: "m3u8_url",
      label: "m3u8 Url",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> m3u8 URL: </label>
                <input
                  type="text"
                  className="form-control"
                  name="m3u8_trailer"
                  onChange={(e) => setM3u8_url(e.target.value)}
                  placeholder="m3u8 Trailer"
                />
              </div>
            </div>

            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn btn-primary ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "mp4_url",
      label: "Video mp4",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> Mp4 File URL: </label>
                <input
                  type="text"
                  className="form-control"
                  name="live_mp3_url"
                  placeholder="Mp4 File URL"
                  onChange={(e) => setMp4_url(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn btn-primary ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "embed",
      label: "Embed Code",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1"> Embed URL: </label>
                <div>
                  <p>
                    Example URL Format : (
                    Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                  </p>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="embed_trailer"
                  placeholder="Embed Trailer"
                  onChange={(e) => setEmbed_code(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <div className="">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                }}
                className="btn btn-primary"
              >
                Submit
              </Button>
              {uploadCompleteddata && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn btn-primary ms-3"
                >
                  Proceed to Next
                </Button>
              )}{" "}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(datadata[0].value);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [inputValuedata, setInputValuedata] = useState("");

  const handleInputChangedata = (event) => {
    const newValue = event.target.value;

    // Validate input using a regular expression
    const validInput =
      /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)([eE][-+]?\d+)?[a-zA-Z]*$/.test(newValue);

    if (validInput) {
      setInputValue(newValue);
    }
  };

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <div className="card mt-4 p-3">
            <div className="mt-3">
              <h4>Upload Video</h4>
            </div>

            <div className="radio-options text-center row d-flex py-3">
              {datadata.map((option) => (
                <div
                  key={option.value}
                  className={` col-lg-3 radio-option ${
                    selectedOption === option.value ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                  />
                  <label className="ms-1">{option.label}</label>
                </div>
              ))}
            </div>

            <div className="active-div-content">
              {
                datadata?.find((option) => option.value === selectedOption)
                  ?.content
              }
            </div>
          </div>

          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <h3>Add Video</h3>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="text-end">
                  <button onClick={videoupload} className="btn btn-primary">
                    Save Video
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    {/* <img src={loadings} width={100} alt="Animated GIF" /> */}
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Video</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      Add the Video title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        onBlur={handleBlurtitle}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Slug</label>
                    <p className="t-1">Add the Video slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Short Description</label>
                    <p className="t-1">
                      Add a short description of the Video below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        Video Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Duration</label>
                      <p className="t-1">
                        Enter the Video duration in the following format (Hours
                        : Minutes : Seconds)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                          placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Free Duration Time</label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="free_duration_time"
                          className="rs-input form-control-lg"
                          maxLength="8"
                          placeholder="HH:MM:SS"
                          value={editUser?.free_duration_time}
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Publish Year</label>
                      <p className="t-1">Video Released Year</p>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="number"
                          value={inputValueyear}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>Organize</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">Video Ratings</label>
                      <p className="t-1">IMDb Ratings 10 out of 10</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">Age Restrict</label>
                      <p className="t-1">Select a Video Age Below</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          <option selected value="">
                            Choose an Age
                          </option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.id}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="mt-2 text-start">
                      <label className="m-0">Album</label>
                      <p className="t-1">Select A Audio Album Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          onChange={(e) => setAlbum_id(e.target.value)}
                        >
                          <option value="">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item.id}>{item.albumname}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">Cast and Crew</label>
                      <p className="t-1">Add artists for the Video below</p>

                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">Select a Video Category Below</p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">Select a Video Language Below</p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Block Country</label>
                      <p className="t-1">
                        Block the Video for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={countryblock_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsCountryblock_country}
                          onChange={handleSelectChangeCountryblock_country}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Available Country</label>
                      <p className="t-1">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>

                    {/* <div className=" text-start mt-2">
                      <label className="m-0">Available Country</label>
                      <p className="t-1">
                        Available the Video for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div> */}
                    <div className=" text-start mt-2">
                      <label className="m-0">Related Videos</label>
                      <p className="t-1">Select a Related Video Below</p>
                      <div className="text-dark">
                        <Select
                          options={related_videos?.map((option) => ({
                            value: option?.id,
                            label: option?.title,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsRelated_videos}
                          onChange={handleSelectChangeRelated_videos}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>
                      Intro Time <small>(Optional)</small>
                    </h5>
                    <hr></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Skip Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_start_time"
                              id="skip_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Recap Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.recap_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0">
                            Skip End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg "
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Recap End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Skip Start Session{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.skip_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Recap Start Session{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>
                    Resources <small>(Optional)</small>
                  </h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">E-Paper</label>
                    <p className="t-1">(Upload your PDF file)</p>
                    <div className="mt-2">
                      <div className="">
                        <div className="">
                          <input
                            type="file"
                            className="form-control-lg rs-input"
                            name="pdf_file"
                            accept="application/pdf"
                            onChange={(e) => setPdf_files(e.target.files[0])}
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">URL Link</label>
                    <p className="t-1">Select the URL Link</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_link"
                        onChange={(e) => setUrl_link(e.target.value)}
                        id="url_link"
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">URL Start Time Format </label>
                    <p className="t-1">(HH:MM:SS)</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_linktym"
                        placeholder="HH:MM:SS"
                        value={insertColons(userTime?.url_linktym)}
                        onChange={handleInputintrotime}
                        id="url_linktym"
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>SEO</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">Website Page Title</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Website URL</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Meta Description</label>

                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setMetadescription(e.target.value)}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Search Tags</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <p className="p2">You don't have any search keywords.</p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={videoupload} className="btn btn-primary">
                    Save Video
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Video Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 9:16 Ratio or 1080X1920px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Video TV Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Video Title Thumbnail</label>
                    <p className="t-1">
                      Select the Video Title image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                              <CameraRetroIcon />
                            </button>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile4)
                                      }
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Trailers Upload</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Video Trailer Type</label>
                    <p className="t-1">Upload trailer video in type</p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-12 col-sm-12 col-lg-12">
                          <select
                            className="form-select"
                            name="trailer_type"
                            id="trailer_type"
                            onChange={(e) => setTrailer_type(e.target.value)}
                            onClick={(event) => {
                              setEnable_restreamtrailer(event.target.value);
                            }}
                          >
                            <option value="null">
                              {" "}
                              Select the Video Trailer Type{" "}
                            </option>
                            <option value="1">Video Upload </option>
                            <option value="m3u8_url">M3u8 Url </option>
                            <option value="mp4_url">Mp4 Url</option>
                            <option value="embed_url">Embed Code</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start">
                    <div
                      className=""
                      onClick={(event) => {
                        setEnablestreamvisibletrailer(event.target.value);
                      }}
                    >
                      {proceednexttrailer()}
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Trailer Description</label>

                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={trailer_description}
                        onChange={(newContent) =>
                          contentFieldChanagedtraildes(newContent)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Visibility</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="mb-2">Publish Type</label>
                    <div>
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        value="publish_now"
                        checked={publishType === "publish_now"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_now" className="mt-2 ms-1">
                        Publish Now
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="publish_later"
                        name="publish_type"
                        value="publish_later"
                        checked={publishType === "publish_later"}
                        onChange={handlePublishTypeChange}
                      />
                      <label htmlFor="publish_later" className="mt-2 ms-1">
                        Publish Later
                      </label>
                      {publishType === "publish_later" && (
                        <div className="mb-3">
                          <label className="m-0">Publish Time</label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="publish_time"
                            name="publish_time"
                            value={publishTime}
                            onChange={handlePublishTimeChange}
                          />
                        </div>
                      )}
                    </div>
                    {/* <button onClick={handleFormSubmit}>Submit</button> */}
                  </div>

                  {/* <div className="col-sm-12">
                    <label className="mb-2">Publish Type</label>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                        value="publish_now"
                        checked="checked"
                      />{" "}
                      <span
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Now
                      </span>
                    </div>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_later"
                        value="publish_later"
                        name="publish_type"
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      />{" "}
                      <span
                        value="publish_later"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Later
                      </span>{" "}
                    </div>
                  </div>

                  <div className="col-sm-12" id="publishlater">
                    <div
                      className="mt-2"
                      onClick={(event) => {
                        setEnablestreamvisiblepublic(event.target.value);
                      }}
                    >
                      {proceednextpublic()}
                    </div>
                  </div> */}
                </div>
                <div className="card p-3">
                  <h5>Free Duration</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable Free Duration Status</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInput}
                            defaultChecked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            checked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            onChange={handleInput}
                            value={
                              free_duration_status?.free_duration_status == 1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">Free Duration Time</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="free_duration_time"
                            className="rs-input form-control-lg"
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            value={editUser?.free_duration_time}
                            onChange={handleInputdata}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>Access</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">User Access</label>
                      <p className="p1">Who Is Allowed To View This Video ?</p>
                      <select
                        onChange={(e) => setAccess(e.target.value)}
                        className="form-select"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>Status Settings</h5>
                    <hr></hr>
                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this video as Featured</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured == 1 ? true : false
                            }
                            checked={featured?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this Video</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active == 1 ? true : false}
                            checked={active?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this Video as Slider</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner == 1 ? true : false}
                            checked={banner?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable Video Title Thumbnail</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_video_title_image"
                            onChange={handleInput}
                            defaultChecked={
                              enable_video_title_image?.enable_video_title_image ==
                              1
                                ? true
                                : false
                            }
                            checked={
                              enable_video_title_image?.enable_video_title_image ==
                              1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_video_title_image"
                            onChange={handleInput}
                            value={
                              enable_video_title_image?.enable_video_title_image ==
                              1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable Free Duration Status</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInput}
                            defaultChecked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            checked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            onChange={handleInput}
                            value={
                              free_duration_status?.free_duration_status == 1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="card p-3">
                    <h5>
                      Reels <small>(Optional)</small>
                    </h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">Reels Video</label>
                      <p className="t-1">Select the Reels Video</p>
                      <div className="mt-2 file-draganddrop">
                        {/* <input
                          type="file"
                          className="form-control-lg rs-input"
                          name="reels_videos"
                          accept="video/mp4,video/x-m4v,video/*"
                          multiple 
                          onChange={handleDropreels}
                          // multiple
                        /> */}

                        <Dropzone
                          onDrop={handleDropreels}
                          accept="video/*"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone">
                              <input {...getInputProps()} />
                              <p className="p-2">
                                Drag and drop reels videos here
                              </p>
                              {videosreels.length > 0 && (
                                <p>
                                  {videosreels.length}{" "}
                                  {videosreels.length === 1 ? "file" : "files"}{" "}
                                  selected
                                </p>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Reels Video Thumbnail</label>
                      <p className="t-1">
                        Select the Reels Video image ( 16:9 Ratio or 1280X720px
                        )
                      </p>
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef5.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef5}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef5,
                                    setSelectedFile5
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <button>
                                <CameraRetroIcon />
                              </button>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-lg-6">
                            <div>
                              {selectedFile5 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL.createObjectURL(selectedFile5)}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile5)
                                        }
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>Subtitles (WebVTT (.vtt))</h5>
                    <hr></hr>
                    <div className="mt-0">
                      {Subtitlesmap?.map((item, index) => (
                        <div className="col-sm-12 mt-2" key={index}>
                          <label>{item.language}</label>
                          <div className="file-draganddrop mt-2">
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                handleDropsubtitles(
                                  item.short_code,
                                  acceptedFiles
                                )
                              }
                              accept="video/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="dropzone">
                                  <input {...getInputProps()} />
                                  <p className="p-3">
                                    Drag and drop subtitles videos here
                                  </p>
                                  {videosubtitles[item.short_code]?.length >
                                    0 && (
                                    <p>
                                      {videosubtitles[item?.short_code].length}{" "}
                                      {videosubtitles[item?.short_code]
                                        .length === 1
                                        ? "file"
                                        : "files"}{" "}
                                      selected
                                    </p>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>
                      Choose Ads Settings <small>(Optional)</small>
                    </h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div className="mt-3">
            <h4>Video Information:</h4>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Title :</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video Title"
                    name="title"
                    id="title"
                    value={editUser?.title}
                    onChange={handleInput}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label className="m-0">Video Slug :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    id="slug"
                    placeholder="Video Slug"
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Age Restrict :</label>
                  <select
                    className="form-control"
                    id="age_restrict"
                    name="age_restrict"
                    onChange={(e) => setAge_restrict(e.target.value)}
                  >
                    {age_restrictdata.map((item) => (
                      <option selected value={item.id}>
                        {item.slug}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 form-group ">
                  <label className="m-0">Rating:</label>
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => setRating(e.target.value)}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 form-group">
                  <label className="m-0">Video Description:</label>
                  <JoditEditor
                    value={descript}
                    onChange={(newContent) =>
                      contentFieldChanageddescript(newContent)
                    }
                  />
                </div>
                <div className="col-12 form-group">
                  <label className="m-0">Links &amp; Details:</label>
                  <JoditEditor
                    value={detal}
                    onChange={(newContent) => contentFieldChanaged(newContent)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Skip Intro Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="skip_intro"
                    name="skip_intro"
                    onChange={(e) => setSkip_intro(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Intro Start Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="intro_start_time"
                    name="intro_start_time"
                    onChange={(e) => setIntro_start_time(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Intro End Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="intro_end_time"
                    name="intro_end_time"
                    onChange={(e) => setIntro_end_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Skip Recap <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="skip_recap"
                    name="skip_recap"
                    onChange={(e) => setSkip_recap(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Recap Start Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recap_start_time"
                    name="recap_start_time"
                    onChange={(e) => setRecap_start_time(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 form-group">
                  <label className="m-0">
                    Recap End Time <small>(Please Give In Seconds)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recap_end_time"
                    name="recap_end_time"
                    onChange={(e) => setRecap_end_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="m-0">Video Duration:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Video Duration"
                    name="duration"
                    id="duration"
                    value={editUser?.duration}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label className="m-0">Year:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Release Year"
                    name="year"
                    id="year"
                    onChange={(e) => setYear(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="mb-2">Publish Type</label>
                  <div>
                    <input
                      type="radio"
                      id="publish_now"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                      value="publish_now"
                      checked="checked"
                    />{" "}
                    <span>Publish Now</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="publish_later"
                      value="publish_later"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                    />{" "}
                    <span
                      value="publish_later"
                      name="publish_type"
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                      onChange={(e) => setPublish_type(e.target.value)}
                    >
                      Publish Later
                    </span>{" "}
                  </div>
                </div>

                <div className="col-sm-6 form-group" id="publishlater">
                  <div
                    onClick={(event) => {
                      setEnablestreamvisiblepublic(event.target.value);
                    }}
                  >
                    {proceednextpublic()}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-3">
              <Button onClick={handleBack} className="btn btn-dark ms-2">
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                className="btn btn-primary ms-2"
              >
                Next
              </Button>
            </div>
          </div> */}
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <>
          {/* <div className="container-fluid">
          <div className="form-card mt-3">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Select Video Category :</label>
                <div>
                  <Select
                    options={category?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionscategory}
                    onChange={handleSelectChangecategory}
                  />
                </div>

                <span className="image_error_msg">
                  * Choose The Video Category
                </span>
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                    <div className="panel-options">
                      <Select
                        options={artists?.map((option) => ({
                          value: option?.id,
                          label: option?.artist_name,
                        }))}
                        className="form-control"
                        isMulti
                        value={selectedOptionsartists}
                        onChange={handleSelectChangeartists}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Choose Language:</label>
                <div>
                  <Select
                    options={language?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionslanguage}
                    onChange={handleSelectChangelanguage}
                  />
                </div>

                <span className="image_error_msg">* Choose The Language</span>
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_videos[]"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  onChange={(e) => setUrl(e.target.value)}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  onChange={(e) => setUrl_linktym(e.target.value)}
                  id="url_linktym"
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleNext();
              }}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="fs-title">Geo-location for Videos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="m-0">Block Country </label>
              <p className="p1">
                ( Choose the countries for block the videos )
              </p>
              <div>
                <Select
                  options={block_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptions}
                  onChange={handleSelectChange}
                />
              </div>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0"> Country </label>
              <p className="p1">( Choose the countries videos )</p>
              <div>
                <Select
                  options={available_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptionsAvailable_country}
                  onChange={handleSelectChangeAvailable_country}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6 form-group">
              <label for="">Search Tags </label>
              <input
                type="text"
                id="exist-values"
                className="tagged form-control"
                data-removeBtn="true"
                name="searchtags"
                onChange={(e) => setSearch_tags(e.target.value)}
              />
            </div>

            <div className="col-sm-6 form-group ">
              <label className="m-0">Related Videos:</label>
              <div>
                <Select
                  options={related_videos?.map((option) => ({
                    value: option?.id,
                    label: option?.title,
                  }))}
                  className="rs-input"
                  isMulti
                  value={selectedOptionsRelated_videos}
                  onChange={handleSelectChangeRelated_videos}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0">User Access</label>
              <p className="p1">Who Is Allowed To View This Video ?</p>
              <select
                className="form-control"
                onChange={(e) => setUserAccess(e.target.value)}
                onClick={(event) => {
                  setLivesource(event.target.value);
                }}
              >
                {user_access?.map((item, key) => (
                  <option value={item.role}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div>{live_stream_source()}</div>

          <div className="row">
            <div className="col-sm-6 form-group mt-3" id="ppv_price">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  <label for="global_ppv">Is this video Is Global PPV:</label>
                </div>
                <div className="col-lg-2 col-3">
                  <label className="switch">
                    <input
                      name="global_ppv"
                      onChange={handleInput}
                      defaultChecked={global_ppv.global_ppv == 1 ? true : false}
                      checked={global_ppv.global_ppv == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="global_ppv"
                      onChange={handleInput}
                      value={global_ppv.global_ppv == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>
                      <h3 className="fs-title">Status Settings</h3>
                    </label>
                  </div>
                </div>

                <div className="panel-body">
                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="featured">
                        Enable this video as Featured:
                      </label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          defaultChecked={featured.featured == 1 ? true : false}
                          checked={featured.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={featured.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="active">Enable this Video:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active.active == 1 ? true : false}
                          checked={active.active == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="banner">Enable this Video as Slider:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          defaultChecked={banner.banner == 1 ? true : false}
                          checked={banner.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row">
            <div className="col-7">
              <h3 className="fs-title">Image Upload:</h3>
            </div>
            <div className="col-5"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1080 x 1920 pixels dimension or
                  ratio 9:16{" "}
                </p>
              </span>
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="player_image"
                id="player_image"
                onChange={(e) => setPlayer_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1280 x 720 pixels dimension or
                  ratio 16:9{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video TV Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_tv_image"
                id="video_tv_image"
                onChange={(e) => setVideo_tv_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1920 x 1080 pixels dimension or
                  16:9 ratio{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video Title Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_title_image"
                id="video_title_image"
                onChange={(e) => setVideo_Title_Thumbnail(e.target.files[0])}
              />
            </div>

            <div className="col-sm-6 form-group">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  {" "}
                  <label className="mb-1">Enable Video Title Thumbnail</label>
                </div>
                <div className="col-lg-2 col-2">
                  <label className="switch">
                    <input
                      name="enable_video_title_image"
                      onChange={handleInput}
                      defaultChecked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      checked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_video_title_image"
                      onChange={handleInput}
                      value={
                        enable_video_title_image.enable_video_title_image == 1
                          ? "1"
                          : "0"
                      }
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-7">
              <h5 className="fs-title">Trailer Upload:</h5>
            </div>

            <div className="col-sm-6">
              <label className="m-0">Video Trailer Type:</label>
              <select
                className="trailer_type form-control"
                name="trailer_type"
                id="trailer_type"
                onChange={(e) => setTrailer_type(e.target.value)}
                onClick={(event) => {
                  setEnable_restreamtrailer(event.target.value);
                }}
              >
                <option value="null"> Select the Video Trailer Type </option>
                <option value="1"> Video Upload </option>
                <option value="m3u8_url">m3u8 Url </option>
                <option value="mp4_url">mp4 Url</option>
                <option value="embed_url">Embed Code</option>
              </select>
            </div>
          </div>

          <div>
            <div
              onClick={(event) => {
                setEnablestreamvisibletrailer(event.target.value);
              }}
            >
              {proceednexttrailer()}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8  form-group">
              <label className="m-0">Trailer Description:</label>
              <JoditEditor
                value={trailerdec}
                onChange={(newContent) =>
                  contentFieldChanagedtrailerdec(newContent)
                }
              />
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <>
          {/* <div className="form-card mt-3">
          <div className="row p-0 m-0">
            <div className="col-7">
              <h4 className="fs-title">ADS Management:</h4>
            </div>

            <div className="row text-start mt-4">
              <div className="col-sm-6">
                <label className="m-0">Choose Ads Position</label>

                <div className="">
                  <Select
                    options={adsPositions}
                    onChange={(e) => handleAdsPositionChange(e)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="m-0">Choose Advertisement</label>

                <div className="">
                  <Select
                    options={secondSelectOptions}
                    onChange={(e) => setSecondSelectOptions(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Submit
            </Button>
          </div>
        </div> */}
        </>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="addvideo">
                  <Box>
                    {/* <Stepper activeStep={activeStep} nonLinear>
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 5 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper> */}

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            {/* <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-primary">
                                  {index === steps.length - 1 ? 'Upload Video' : 'Next'}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-dark">
                                  Back
                                </Button>
                              </div>
                            </Box> */}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
