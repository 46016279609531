import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CPPVideoAnalytics() {
  const datas = [
    {
      name: "Flicknexs",
      uploader: "Subscribed User",
      commission: "20",
      admin: "30",
      moderator: "50",
      views: "10",
      date: "12-02-20023",
    },
  ];

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = ["March", "February", "January"];

  const data = {
    labels,
    datasets: [
      {
        label: "Content Partners Video Analytics",

        data: [4, 2, 7, 0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">
                      Content Partners Video Analytics :
                    </h4>
                  </div>
                </div>

                <div className="row p-3">
                  <hr />
                  <div className="col-md-12">
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <label for="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_time"
                          name="start_time"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="start_time"> End Date: </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_time"
                          name="end_time"
                        />
                      </div>
                      <div className="col-md-3 d-flex align-items-end">
                        <div class="dropdown">
                          <button
                            class="btn btn-outline-dark filter-btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Filter
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Day
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Week
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                1 Month{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-3 d-flex align-items-end">
                        <span
                          id="export"
                          className="btn btn-primary download-btn btn-sm"
                        >
                          Download CSV
                        </span>
                      </div>
                    </div>
                    <div className="clear"></div>

                    <div className="mt-5">
                      <h4>CPP Video View Through Graph :</h4>
                    </div>
                    <div>
                      <div className="row mt-5">
                        <div className="col-md-2"></div>
                        <div className="col-md-7">
                          <Line options={options} data={data} />
                          <p className="text-center">
                            Content Partners Video Analytics{" "}
                          </p>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </div>

                    <div className="row align-items-center mb-5 ">
                      <h5>Filter Moderator</h5>
                      <div className="col-md-6">
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose Moderator</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>
                      <div className="col-md-6"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>Video Name</th>
                              <th>Uploader Name</th>
                              <th>Total Commission</th>
                              <th>Admin Commission </th>
                              <th>Moderator Commission</th>
                              <th>Total Views</th>
                              <th>Purchased Date</th>
                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr  key={item?.id}  className="r1">
                                <td key={key}> {key + 1} </td>

                                <td>{item.name}</td>
                                <td>{item.uploader}</td>
                                <td>{item.commission}</td>
                                <td>{item.admin} </td>
                                <td>{item.moderator}</td>
                                <td>{item.views}</td>
                                <td>{item.date}</td>
                              </tr>
                            </thead>
                          ))}
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CPPVideoAnalytics;
