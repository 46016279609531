import "./Header.css";
import React, { useState, useEffect } from "react";
// import logo from '../Images/flicklogo.png'
import logo from "../../components/Images/flicklogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine } from "react-icons/ri";
import loadings from "../../components/Images/loading/loading.gif";

function Header(props) {
  const [terms, setTerms] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const access_token = localStorage.getItem("access_token");
  const channel_name = localStorage.getItem("channel_name");
  const expires_in = localStorage.getItem("expires_in");

  // let channel_name = localStorage.getItem("channel_name");
  // console.log(userName);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token") || ""
  );

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // Track the last interaction time and the automatic logout time (in milliseconds)
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const automaticLogoutTime = expires_in; // Adjust this time as needed (e.g., 15 minutes)

  // Function to reset the last interaction time
  const resetInteractionTime = () => {
    setLastInteractionTime(Date.now());
  };

  // Check for automatic logout when the component mounts
  useEffect(() => {
    const checkForAutomaticLogout = () => {
      const currentTime = Date.now();
      if (currentTime - lastInteractionTime >= automaticLogoutTime) {
        // Automatic logout due to inactivity
        handleLogout();
      }
    };
    const intervalId = setInterval(checkForAutomaticLogout, 10000); // Check every 10 seconds
    return () => {
      clearInterval(intervalId);
    };
  }, [lastInteractionTime]);

  async function handleLogout() {
    setProcessing(true);
    setShowOverlay(true);

    try {
      const access_token = localStorage.getItem("access_token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/logout`,
        {},
        { headers: headers }
      );

      if (response.data.status === true) {
        localStorage.removeItem("access_token");
        localStorage.clear();

        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 10);

        props.props.setApiresponsealert(response.data);
        props.props.setShowalert(true);
      } else {
        props.props.setApiresponsealert(response.data);
        props.props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (error) {
      console.error("Error during logout:", error);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  const search = (id) => {
    axios
      .get("", { headers: headers })
      .then((response) => {
        var result = response.data.data.data;
        console.warn("result", result);
        setData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function logout() {
    localStorage.clear();
    navigate("/");
    window.location.reload();
    setTimeout(() => {}, 500); // delay execution by 1 second (1000 milliseconds)
  }

  // const [theme, setTheme] = useState('light');
  // const toggleTheme = () => {
  //   if (theme === 'light') {
  //     setTheme('dark');
  //   } else {
  //     setTheme('light');
  //   }
  // };
  // useEffect(() => {
  //   document.body.className = theme;
  // }, [theme]);

  // <div className={`App ${theme}`}>
  //   <button onClick={toggleTheme}>Toggle Theme</button>
  // </div>

  const channel = () => {
    window.open("https://channel-flick.flicknexs.com/", "_blank");
  };
  const content = () => {
    window.open("https://cpp-flick.flicknexs.com/", "_blank");
  };
  const website = () => {
    window.open("https://demo-flick.flicknexs.com/", "_blank");
  };

  const MyAlert = ({ message, setShowalert, apiresponsealert }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        props.props.setShowalert(false);
        setVisible(false);
      }, 3000);
    }, [setShowalert]);

    let color = "error";
    if (props.props.apiresponsealert.status === true) {
      color = "success";
    }

    const onDismiss = () => {
      setVisible(false);
      props.props.setShowalert(false);
    };

    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        {visible && (
          <Alert
            severity={color}
            onClose={onDismiss}
            variant="filled"
            className="liveAlert"
          >
            <strong>
              {props?.props?.apiresponsealert.status === true
                ? props?.props?.apiresponsealert?.message
                : props?.props?.apiresponsealert?.message}
              {apiresponsealert?.message}
            </strong>
          </Alert>
        )}
      </Stack>
    );
  };

  return (
    <section className="header">
      <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              {" "}
              <img src={loadings} width={100} alt="Animated GIF" />
              Processing ...
            </span>
          </div>
        )}
      </div>

      <header>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            {/* <Link className="logo d-flex align-items-center" to="/admin">
      <img src={logo} alt="flogo" />
      <div className="logo">
        {isLoading ? (
          <p>Loading...</p>
        ) : data1 ? (
          <div>
            <p>
              <img src={data1} className="w-100" />
            </p>
          </div>
        ) : data2 ? (
          <p>
            <img src={data2} className="w-100" />
          </p>
        ) : data3 ? (
          <p>
            <img src={data3} className="w-100" />
          </p>
        ) : (
          <p></p>
        )}
      </div>
    </Link> */}
            <Link className="logo d-flex align-items-center" to=""></Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse nav-portal"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 text-start">
                <li className="nav-item">
                  <Link className="nav-link nav-icon" onClick={content}>
                    <button className="btn btn-primary">CPP Portal</button>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link nav-icon" onClick={website}>
                    <button className="btn btn-primary">
                      Website{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </Link>
                </li>
              </ul>
              <form className="d-flex">
                <li className="nav-item dropdown pe-3">
                  <Link
                    className="nav-link username nav-profile d-flex align-items-center pe-0 "
                    data-bs-toggle="dropdown"
                  >
                    <img src="" className="fa fa-user-circle" />
                    <span className=" d-md-block dropdown-toggle ps-2">
                      {channel_name}
                    </span>
                  </Link>

                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow ">
                    {/* <li>
                <hr className="dropdown-divider" />
              </li> */}

                    <li>
                      {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to=""
                >
                  <i className="bi bi-person"></i> */}
                      {/* <li className=""> */}
                      <Link
                        className="dropdown-item d-flex align-items-center "
                        to="/channel/my-profile"
                      >
                        <CgProfile className="exportIcon me-2" />
                        My Profile
                      </Link>
                      {/* </li> */}
                      {/* </Link> */}
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    {/* <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to=""
                >
                  <i className="bi bi-gear"></i>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Account Settings
                    </Link>
                  </li>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to=""
                >
                  <i className="bi bi-question-circle"></i>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Need Help?
                    </Link>
                  </li>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li> */}

                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="/"
                        onClick={handleLogout}
                      >
                        {/* <i className="bi bi-box-arrow-right"></i> */}
                        <RiLogoutCircleRLine className="exportIcon me-2" />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </form>

              {props?.props ? (
                <div className="row spacing_bottom">
                  <div className="col-12 d-flex justify-content-center">
                    <div id="liveAlert">
                      {props?.props && props?.props?.showalert === true && (
                        <MyAlert
                          message={props?.props.apiresponsealert.message}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                {}
              )}
            </div>
          </div>
        </nav>
      </header>
    </section>
  );
}

export default Header;
