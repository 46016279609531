import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./PurchasePlan.css";

function PurchasePlan() {
  const [data, setData] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [plan_price, setPlan_price] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(purchase.plan_price)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.Inapp_Purchase?.data;
        setData(result);
        setPurchase(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("plan_price", plan_price);
    formData.append("product_id", product_id);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          alert("Added Successfully");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.Inapp_Purchase?.data);
        setPurchase(res?.data?.Inapp_Purchase?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.Inapp_Purchase?.data);
        setPurchase(res?.data?.Inapp_Purchase?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res.data.Inapp_Purchase);
        setNext(res?.data?.Inapp_Purchase?.data);
        setPurchase(res?.data?.Inapp_Purchase?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">
                            In App Enable Setting :
                          </h4>
                        </div>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="btn btn-primary create_category"
                          >
                            <i className="fa fa-plus-circle"></i>Add New{" "}
                          </Link>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  New In App Purchase Plans
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <label>Plan Price ( ₹ ) : </label>

                                  <input
                                    type="text"
                                    name="plan_price"
                                    placeholder="Enter Plan Price"
                                    className="form-control rtmp_urls"
                                    onChange={(e) =>
                                      setPlan_price(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <label>Product ID : </label>

                                  <input
                                    type="text"
                                    name="product_id"
                                    placeholder="Enter Product ID "
                                    className="form-control rtmp_urls"
                                    onChange={(e) =>
                                      setProduct_id(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-primary ml-3 "
                                  onClick={handleSubmit}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="iq-card-body table-responsive mt-3">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table ">
                            <thead>
                              <tr className="r1">
                                <th>S.No</th>
                                <th>Plan Price</th>
                                <th>Product ID</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {purchase?.map((item, key) => (
                                <tr className="r1">
                                  <td className="table-content" key={item?.id}>
                                    {key + 1}
                                  </td>

                                  <td className="table-content">
                                    <p>{item?.plan_price}</p>
                                  </td>
                                  <td className="table-content">
                                    <p>{item?.product_id}</p>
                                  </td>
                                  <td className="table-content">
                                    <Link
                                      to={
                                        "/admin/edit-inapp-purchase/" + item?.id
                                      }
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash ms-2"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row d-flex text-center align-items-center">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PurchasePlan;
