import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./HomePageSettings.css";

function HomePageSettings() {
  const [order, setOrder] = useState({});
  const [seriesid, setSeriesid] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [editUser, seteditUser] = useState({
    user_id: "",
    id: "",
    featured_videos: "",
    latest_videos: "",
    category_videos: "",
    live_videos: "",
    series: "",
    audios: "",
    albums: "",
    Recommendation: "",
    AutoIntro_skip: "",
    continue_watching: "",
    theme_choosen: "",
    prevent_inspect: "",
    artist: "",
    pop_up: "",
    live_category: "",
    videoCategories: "",
    liveCategories: "",
    video_schedule: "",
    single_series: "",
    list_of_series: "",
    single_series_id: "",
    latest_series: "",
    scheduled_publish_video: "",
    scheduled_publish_LiveStream: "",
    content_partner: "",
    channel_partner: "",
    Individual_ContentPartner: "",
    Individual_channelPartner: "",
  });
  const [editUserdata, seteditUserdata] = useState({
    user_id: "",
    id: "",
    featured_videos: "",
    latest_videos: "",
    category_videos: "",
    live_videos: "",
    series: "",
    audios: "",
    albums: "",
    Recommendation: "",
    AutoIntro_skip: "",
    continue_watching: "",
    theme_choosen: "",
    prevent_inspect: "",
    artist: "",
    pop_up: "",
    live_category: "",
    videoCategories: "",
    liveCategories: "",
    video_schedule: "",
    single_series: "",
    list_of_series: "",
    single_series_id: "",
    latest_series: "",
    scheduled_publish_video: "",
    scheduled_publish_LiveStream: "",
    content_partner: "",
    channel_partner: "",
    Individual_ContentPartner: "",
    Individual_channelPartner: "",
  });
  // const [editUser, seteditUser] = useState({
  //   user_id: "",
  //   id: "",
  //   featured_videos: "",
  //   latest_videos: "",
  //   category_videos: "",
  //   live_videos: "",
  //   series: "",
  //   audios: "",
  //   albums: "",
  //   Recommendation: "",
  //   AutoIntro_skip: "",
  //   continue_watching: "",
  //   theme_choosen: "",
  //   prevent_inspect: "",
  //   artist: "",
  //   pop_up: "",
  //   live_category: "",
  //   videoCategories: "",
  //   liveCategories: "",
  //   video_schedule: "",
  //   single_series: "",
  //   list_of_series: "",
  //   single_series_id: "",
  //   latest_series: "",
  //   scheduled_publish_video: "",
  //   scheduled_publish_LiveStream: "",
  //   content_partner: "",
  //   channel_partner: "",
  //   Individual_ContentPartner: "",
  //   Individual_channelPartner: "",
  // });
  // const [editUserdata, seteditUserdata] = useState({
  //   user_id: "",
  //   id: "",
  //   featured_videos: "",
  //   latest_videos: "",
  //   category_videos: "",
  //   live_videos: "",
  //   series: "",
  //   audios: "",
  //   albums: "",
  //   Recommendation: "",
  //   AutoIntro_skip: "",
  //   continue_watching: "",
  //   theme_choosen: "",
  //   prevent_inspect: "",
  //   artist: "",
  //   pop_up: "",
  //   live_category: "",
  //   videoCategories: "",
  //   liveCategories: "",
  //   video_schedule: "",
  //   single_series: "",
  //   list_of_series: "",
  //   single_series_id: "",
  //   latest_series: "",
  //   scheduled_publish_video: "",
  //   scheduled_publish_LiveStream: "",
  //   content_partner: "",
  //   channel_partner: "",
  //   Individual_ContentPartner: "",
  //   Individual_channelPartner: "",
  // });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Mobile-HomePage/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.home_setting)
        var result = response?.data?.Mobile_HomePage;
        // setOrder(result)
        seteditUserdata(result);
        // console.log('result', result)
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.home_setting)
        var result = response?.data?.Web_HomePage;
        var resultid = response?.data?.list_of_series;
        // setOrder(result)
        seteditUser(result);
        setSeriesid(resultid);
        // console.log('result', result)
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    // console.log(e.target.checked, e.target.value);
    if (e.target.checked === true) {
      seteditUser({ ...editUser, [e.target.name]: 1 });
      seteditUserdata({ ...editUserdata, [e.target.name]: 1 });
    } else {
      seteditUser({ ...editUser, [e.target.name]: 0 });
      seteditUserdata({ ...editUserdata, [e.target.name]: 0 });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      featured_videos: editUser.featured_videos,
      latest_videos: editUser.latest_videos,
      category_videos: editUser.category_videos,
      live_videos: editUser.live_videos,
      series: editUser.series,
      audios: editUser.audios,
      albums: editUser.albums,
      Recommendation: editUser.Recommendation,
      AutoIntro_skip: editUser.AutoIntro_skip,
      continue_watching: editUser.continue_watching,
      theme_choosen: editUser.theme_choosen,
      prevent_inspect: editUser.prevent_inspect,
      artist: editUser.artist,
      pop_up: editUser.pop_up,
      live_category: editUser.live_category,
      videoCategories: editUser.videoCategories,
      liveCategories: editUser.liveCategories,
      video_schedule: editUser.video_schedule,
      single_series: editUser.single_series,
      list_of_series: editUser.list_of_series,
      single_series_id: editUser.single_series_id,
      latest_series: editUser.latest_series,
      scheduled_publish_video: editUser.scheduled_publish_video,
      scheduled_publish_LiveStream: editUser.scheduled_publish_LiveStream,
      content_partner: editUser.content_partner,
      channel_partner: editUser.channel_partner,
      Individual_ContentPartner: editUser.Individual_ContentPartner,
      Individual_channelPartner: editUser.Individual_channelPartner,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const handleUpdatedata = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUserdata.id,
      user_id: editUserdata.user_id,
      featured_videos: editUserdata.featured_videos,
      latest_videos: editUserdata.latest_videos,
      category_videos: editUserdata.category_videos,
      live_videos: editUserdata.live_videos,
      series: editUserdata.series,
      audios: editUserdata.audios,
      albums: editUserdata.albums,
      Recommendation: editUserdata.Recommendation,
      AutoIntro_skip: editUserdata.AutoIntro_skip,
      continue_watching: editUserdata.continue_watching,
      theme_choosen: editUserdata.theme_choosen,
      prevent_inspect: editUserdata.prevent_inspect,
      artist: editUserdata.artist,
      pop_up: editUserdata.pop_up,
      live_category: editUserdata.live_category,
      videoCategories: editUserdata.videoCategories,
      liveCategories: editUserdata.liveCategories,
      video_schedule: editUserdata.video_schedule,
      single_series: editUserdata.single_series,
      list_of_series: editUserdata.list_of_series,
      single_series_id: editUserdata.single_series_id,
      latest_series: editUserdata.latest_series,
      scheduled_publish_video: editUserdata.scheduled_publish_video,
      scheduled_publish_LiveStream: editUserdata.scheduled_publish_LiveStream,
      content_partner: editUserdata.content_partner,
      channel_partner: editUserdata.channel_partner,
      Individual_ContentPartner: editUserdata.Individual_ContentPartner,
      Individual_channelPartner: editUserdata.Individual_channelPartner,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Mobile-HomePage/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/index`,
        { headers: headers }
      );
      setMenuItems(response.data.Web_HomePage_Order);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(menuItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setMenuItems(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    try {
      if (!updatedItems || updatedItems.length === 0) {
        console.error("Menu items are empty");
        return;
      }

      const ids = updatedItems.map((menuItem) => menuItem.id);
      const positions = updatedItems.map((_, index) => index + 1);

      const requestData = {
        id: ids,
        position: positions,
      };

      await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`,
        requestData,
        { headers: headers }
      );
      console.log("Shuffle order saved successfully");
    } catch (error) {
      console.error(error);
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     id: editUser.id,
  //     user_id: editUser.user_id,
  //     featured_videos: editUser.featured_videos,
  //     latest_videos: editUser.latest_videos,
  //     category_videos: editUser.category_videos,
  //     live_videos: editUser.live_videos,
  //     series: editUser.series,
  //     audios: editUser.audios,
  //     albums: editUser.albums,
  //     Recommendation: editUser.Recommendation,
  //     AutoIntro_skip: editUser.AutoIntro_skip,
  //     continue_watching: editUser.continue_watching,
  //     theme_choosen: editUser.theme_choosen,
  //     prevent_inspect: editUser.prevent_inspect,
  //     artist: editUser.artist,
  //     pop_up: editUser.pop_up,
  //     live_category: editUser.live_category,
  //     videoCategories: editUser.videoCategories,
  //     liveCategories: editUser.liveCategories,
  //     video_schedule: editUser.video_schedule,
  //     single_series: editUser.single_series,
  //     list_of_series: editUser.list_of_series,
  //     single_series_id: editUser.single_series_id,
  //     latest_series: editUser.latest_series,
  //     scheduled_publish_video: editUser.scheduled_publish_video,
  //     scheduled_publish_LiveStream: editUser.scheduled_publish_LiveStream,
  //     content_partner: editUser.content_partner,
  //     channel_partner: editUser.channel_partner,
  //     Individual_ContentPartner: editUser.Individual_ContentPartner,
  //     Individual_channelPartner: editUser.Individual_channelPartner,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/update`,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status ==== 200) {
  //     setMessage(resjson.success);
  //     alert('WEb HomePage Success')
  //     // window.location.reload();
  //     setTimeout(() => {}, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  // const handleUpdatedata = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     id: editUserdata.id,
  //     user_id: editUserdata.user_id,
  //     featured_videos: editUserdata.featured_videos,
  //     latest_videos: editUserdata.latest_videos,
  //     category_videos: editUserdata.category_videos,
  //     live_videos: editUserdata.live_videos,
  //     series: editUserdata.series,
  //     audios: editUserdata.audios,
  //     albums: editUserdata.albums,
  //     Recommendation: editUserdata.Recommendation,
  //     AutoIntro_skip: editUserdata.AutoIntro_skip,
  //     continue_watching: editUserdata.continue_watching,
  //     theme_choosen: editUserdata.theme_choosen,
  //     prevent_inspect: editUserdata.prevent_inspect,
  //     artist: editUserdata.artist,
  //     pop_up: editUserdata.pop_up,
  //     live_category: editUserdata.live_category,
  //     videoCategories: editUserdata.videoCategories,
  //     liveCategories: editUserdata.liveCategories,
  //     video_schedule: editUserdata.video_schedule,
  //     single_series: editUserdata.single_series,
  //     list_of_series: editUserdata.list_of_series,
  //     single_series_id: editUserdata.single_series_id,
  //     latest_series: editUserdata.latest_series,
  //     scheduled_publish_video: editUserdata.scheduled_publish_video,
  //     scheduled_publish_LiveStream: editUserdata.scheduled_publish_LiveStream,
  //     content_partner: editUserdata.content_partner,
  //     channel_partner: editUserdata.channel_partner,
  //     Individual_ContentPartner: editUserdata.Individual_ContentPartner,
  //     Individual_channelPartner: editUserdata.Individual_channelPartner,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/Mobile-HomePage/update`,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status ==== 200) {
  //     setMessage(resjson.success);
  //     alert('Mobile HomePage Success')
  //     // window.location.reload();
  //     setTimeout(() => {}, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-0">
              <div id="webhomesetting">
                <div className="iq-card">
                  <div className="tab"></div>
                  <div className="admin-section-title mt-3">
                    <h4>
                      <i className="entypo-monitor"></i> Home Page Menu Settings{" "}
                    </h4>
                    <hr />
                  </div>
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Modal title
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <select className="form-control">
                            {seriesid?.map((item, key) => (
                              <option key={item?.id} value={item.id}>{item.title}</option>
                            ))}
                          </select>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={handleUpdate}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home_mobile">
                    <ul class="nav nav-tabs " id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Home
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Profile
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="panel-body">
                          <div className="row align-items-center p-3 pt-1">
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Featured Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="featured_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.featured_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.featured_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="featured_videos"
                                        onChange={handleInput}
                                        value={
                                          editUser?.featured_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Latest Videos{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="latest_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.latest_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.latest_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="latest_videos"
                                        onChange={handleInput}
                                        value={
                                          editUser?.latest_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Category Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="category_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.category_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.category_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="category_videos"
                                        onChange={handleInput}
                                        value={
                                          editUser?.category_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Live Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="live_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.live_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.live_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="live_videos"
                                        onChange={handleInput}
                                        value={
                                          editUser?.live_videos === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Series
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.series === 1 ? true : false
                                        }
                                        checked={
                                          editUser?.series === 1 ? true : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="series"
                                        onChange={handleInput}
                                        value={
                                          editUser?.series === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Audios
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="audios"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.audios === 1 ? true : false
                                        }
                                        checked={
                                          editUser?.audios === 1 ? true : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="audios"
                                        onChange={handleInput}
                                        value={
                                          editUser?.audios === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Albums
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="albums"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.albums === 1 ? true : false
                                        }
                                        checked={
                                          editUser?.albums === 1 ? true : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="albums"
                                        onChange={handleInput}
                                        value={
                                          editUser?.albums === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Recommendation
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Recommendation"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.Recommendation === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.Recommendation === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Recommendation"
                                        onChange={handleInput}
                                        value={
                                          editUser?.Recommendation === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Auto Intro Skip{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="AutoIntro_skip"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.AutoIntro_skip === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.AutoIntro_skip === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="AutoIntro_skip"
                                        onChange={handleInput}
                                        value={
                                          editUser?.AutoIntro_skip === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Continue Watching
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="continue_watching"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.continue_watching === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.continue_watching === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="continue_watching"
                                        onChange={handleInput}
                                        value={
                                          editUser?.continue_watching === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Prevent Inspect
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="prevent_inspect"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.prevent_inspect === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.prevent_inspect === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="prevent_inspect"
                                        onChange={handleInput}
                                        value={
                                          editUser?.prevent_inspect === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Artist{" "}
                                    </label>
                                  </div>
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="artist"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.artist === 1 ? true : false
                                        }
                                        checked={
                                          editUser?.artist === 1 ? true : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="artist"
                                        onChange={handleInput}
                                        value={
                                          editUser?.artist === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      PopUp{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="pop_up"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.pop_up === 1 ? true : false
                                        }
                                        checked={
                                          editUser?.pop_up === 1 ? true : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="pop_up"
                                        onChange={handleInput}
                                        value={
                                          editUser?.pop_up === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Live Category
                                    </label>
                                  </div>
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="live_category"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.live_category === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.live_category === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="live_category"
                                        onChange={handleInput}
                                        value={
                                          editUser?.live_category === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Video Categories{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="videoCategories"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.videoCategories === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.videoCategories === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="videoCategories"
                                        onChange={handleInput}
                                        value={
                                          editUser?.videoCategories === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Live Categories{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="liveCategories"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.liveCategories === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.liveCategories === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="liveCategories"
                                        onChange={handleInput}
                                        value={
                                          editUser?.liveCategories === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Video Schedule
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="video_schedule"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.video_schedule === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.video_schedule === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="video_schedule"
                                        onChange={handleInput}
                                        value={
                                          editUser?.video_schedule === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      List Of Series{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="list_of_series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.list_of_series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.list_of_series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="list_of_series"
                                        onChange={handleInput}
                                        value={
                                          editUser?.list_of_series === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Single Series Id{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="single_series_id"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="single_series_id"
                                      onChange={handleInput}
                                      value={
                                        editUser?.single_series_id === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Latest Series{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="latest_series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.latest_series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.latest_series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="latest_series"
                                        onChange={handleInput}
                                        value={
                                          editUser?.latest_series === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Scheduled Publish Video{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="scheduled_publish_video"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.scheduled_publish_video === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.scheduled_publish_video === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="scheduled_publish_video"
                                        onChange={handleInput}
                                        value={
                                          editUser?.scheduled_publish_video === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Scheduled Publish LiveStream{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="scheduled_publish_LiveStream"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.scheduled_publish_LiveStream ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.scheduled_publish_LiveStream ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="scheduled_publish_LiveStream"
                                        onChange={handleInput}
                                        value={
                                          editUser?.scheduled_publish_LiveStream ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Content Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="content_partner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.content_partner === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.content_partner === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="content_partner"
                                        onChange={handleInput}
                                        value={
                                          editUser?.content_partner === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Channel Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="channel_partner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.channel_partner === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.channel_partner === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="channel_partner"
                                        onChange={handleInput}
                                        value={
                                          editUser?.channel_partner === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Individual Content Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Individual_ContentPartner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.Individual_ContentPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.Individual_ContentPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Individual_ContentPartner"
                                        onChange={handleInput}
                                        value={
                                          editUser?.Individual_ContentPartner ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Individual Channel Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Individual_channelPartner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.Individual_channelPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.Individual_channelPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Individual_channelPartner"
                                        onChange={handleInput}
                                        value={
                                          editUser?.Individual_channelPartner ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop headingpop">
                                      Single Series
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label
                                      className="switch"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      <input
                                        name="single_series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUser?.single_series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUser?.single_series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="single_series"
                                        onChange={handleInput}
                                        value={
                                          editUser?.single_series === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                                <span className="image_error_msg">
                                  *Choose The Single Series
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                onClick={handleUpdate}
                                name="submit"
                              >
                                {" "}
                                Save Settings
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="panel-body">
                          <div className="row align-items-center p-3 pt-1">
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Featured Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="featured_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.featured_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.featured_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="featured_videos"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.featured_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Latest Videos{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="latest_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.latest_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.latest_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="latest_videos"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.latest_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Category Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="category_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.category_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.category_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="category_videos"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.category_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Live Videos
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="live_videos"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.live_videos === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.live_videos === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="live_videos"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.live_videos === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Series
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="series"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.series === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Audios
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="audios"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.audios === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.audios === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="audios"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.audios === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Albums
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="albums"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.albums === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.albums === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="albums"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.albums === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Recommendation
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Recommendation"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.Recommendation === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.Recommendation === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Recommendation"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.Recommendation === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Auto Intro Skip{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="AutoIntro_skip"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.AutoIntro_skip === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.AutoIntro_skip === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="AutoIntro_skip"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.AutoIntro_skip === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Continue Watching
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="continue_watching"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.continue_watching === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.continue_watching === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="continue_watching"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.continue_watching === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Prevent Inspect
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="prevent_inspect"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.prevent_inspect === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.prevent_inspect === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="prevent_inspect"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.prevent_inspect === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Artist{" "}
                                    </label>
                                  </div>
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="artist"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.artist === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.artist === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="artist"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.artist === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      PopUp{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="pop_up"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.pop_up === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.pop_up === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="pop_up"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.pop_up === 1 ? "1" : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {" "}
                                      Live Category
                                    </label>
                                  </div>
                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="live_category"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.live_category === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.live_category === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="live_category"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.live_category === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Video Categories{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="videoCategories"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.videoCategories === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.videoCategories === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="videoCategories"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.videoCategories === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Live Categories{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="liveCategories"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.liveCategories === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.liveCategories === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="liveCategories"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.liveCategories === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Video Schedule
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="video_schedule"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.video_schedule === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.video_schedule === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="video_schedule"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.video_schedule === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    List Of Series{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="list_of_series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.list_of_series === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.list_of_series === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="list_of_series"
                                      onChange={handleInput}
                                      value={
                                        editUser?.list_of_series === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                            {/* <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Single Series Id{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="single_series_id"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="single_series_id"
                                      onChange={handleInput}
                                      value={
                                        editUser?.single_series_id === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop headingpop">
                                      Single Series
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="single_series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.single_series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.single_series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="single_series"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.single_series === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Latest Series{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="latest_series"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.latest_series === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.latest_series === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="latest_series"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.latest_series === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Scheduled Publish Video{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="scheduled_publish_video"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.scheduled_publish_video ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.scheduled_publish_video ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="scheduled_publish_video"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.scheduled_publish_video ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Scheduled Publish LiveStream{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="scheduled_publish_LiveStream"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.scheduled_publish_LiveStream ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.scheduled_publish_LiveStream ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="scheduled_publish_LiveStream"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.scheduled_publish_LiveStream ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Content Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="content_partner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.content_partner === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.content_partner === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="content_partner"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.content_partner === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Channel Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="channel_partner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.channel_partner === 1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.channel_partner === 1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="channel_partner"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.channel_partner === 1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Individual Content Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Individual_ContentPartner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.Individual_ContentPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.Individual_ContentPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Individual_ContentPartner"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.Individual_ContentPartner ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 p-1">
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      Individual Channel Partner{" "}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name="Individual_channelPartner"
                                        onChange={handleInput}
                                        defaultChecked={
                                          editUserdata?.Individual_channelPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        checked={
                                          editUserdata?.Individual_channelPartner ===
                                          1
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="Individual_channelPartner"
                                        onChange={handleInput}
                                        value={
                                          editUserdata?.Individual_channelPartner ===
                                          1
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                onClick={handleUpdatedata}
                                name="submit"
                              >
                                {" "}
                                Save Settings
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form>
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {/* <div className="panel-body">
                        <div className="row align-items-center p-3 pt-1">
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Featured Videos
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="featured_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.featured_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured_videos === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">Latest Videos </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="latest_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.latest_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.latest_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="latest_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.latest_videos === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Category Videos
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="category_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.category_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.category_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="category_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.category_videos === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">Live Category</label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="live_category"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.live_category === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.live_category === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="live_category"
                                      onChange={handleInput}
                                      value={
                                        editUser?.live_category === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    Categories Video
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="videoCategories"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.videoCategories === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.videoCategories === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="videoCategories"
                                      onChange={handleInput}
                                      value={
                                        editUser?.videoCategories === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    {" "}
                                    Categories Live
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="liveCategories"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.liveCategories === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.liveCategories === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="liveCategories"
                                      onChange={handleInput}
                                      value={
                                        editUser?.liveCategories === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">Live Videos</label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="live_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.live_videos === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.live_videos === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="live_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.live_videos === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1"> Audios</label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="audios"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.audios === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.audios === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="audios"
                                      onChange={handleInput}
                                      value={editUser?.audios === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1"> Albums </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="albums"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.albums === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.albums === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="albums"
                                      onChange={handleInput}
                                      value={editUser?.albums === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1"> Series</label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.series === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.series === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="series"
                                      onChange={handleInput}
                                      value={editUser?.series === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">Recommendation</label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="Recommendation"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.Recommendation === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.Recommendation === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="Recommendation"
                                      onChange={handleInput}
                                      value={
                                        editUser?.Recommendation === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    {" "}
                                    Video Schedule
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="video_schedule"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.video_schedule === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.video_schedule === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="video_schedule"
                                      onChange={handleInput}
                                      value={
                                        editUser?.video_schedule === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">Artist </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="artist"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.artist === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.artist === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="artist"
                                      onChange={handleInput}
                                      value={editUser?.artist === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    {" "}
                                    Auto Intro Skip{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="AutoIntro_skip"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.AutoIntro_skip === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.AutoIntro_skip === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="AutoIntro_skip"
                                      onChange={handleInput}
                                      value={
                                        editUser?.AutoIntro_skip === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1">
                                    {" "}
                                    Continue Watching
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="continue_watching"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.continue_watching === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.continue_watching === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="continue_watching"
                                      onChange={handleInput}
                                      value={
                                        editUser?.continue_watching === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1"> Pop up </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="pop_up"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.pop_up === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.pop_up === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="pop_up"
                                      onChange={handleInput}
                                      value={editUser?.pop_up === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              onClick={handleUpdate}
                              name="submit"
                            >
                              {" "}
                              Save Settings
                            </button>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="panel-body">
                        <div className="row align-items-center p-3 pt-1">
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Featured Videos
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="featured_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.featured_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured_videos === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Latest Videos{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="latest_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.latest_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.latest_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="latest_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.latest_videos === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Category Videos
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="category_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.category_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.category_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="category_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.category_videos === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Live Videos
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="live_videos"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.live_videos === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.live_videos === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="live_videos"
                                      onChange={handleInput}
                                      value={
                                        editUser?.live_videos === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Series
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.series === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.series === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="series"
                                      onChange={handleInput}
                                      value={editUser?.series === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Audios
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="audios"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.audios === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.audios === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="audios"
                                      onChange={handleInput}
                                      value={editUser?.audios === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Albums
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="albums"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.albums === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.albums === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="albums"
                                      onChange={handleInput}
                                      value={editUser?.albums === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Recommendation
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="Recommendation"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.Recommendation === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.Recommendation === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="Recommendation"
                                      onChange={handleInput}
                                      value={
                                        editUser?.Recommendation === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Auto Intro Skip{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="AutoIntro_skip"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.AutoIntro_skip === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.AutoIntro_skip === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="AutoIntro_skip"
                                      onChange={handleInput}
                                      value={
                                        editUser?.AutoIntro_skip === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Continue Watching
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="continue_watching"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.continue_watching === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.continue_watching === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="continue_watching"
                                      onChange={handleInput}
                                      value={
                                        editUser?.continue_watching === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Prevent Inspect
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="prevent_inspect"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.prevent_inspect === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.prevent_inspect === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="prevent_inspect"
                                      onChange={handleInput}
                                      value={
                                        editUser?.prevent_inspect === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Artist{" "}
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="artist"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.artist === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.artist === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="artist"
                                      onChange={handleInput}
                                      value={editUser?.artist === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    PopUp{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="pop_up"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.pop_up === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.pop_up === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="pop_up"
                                      onChange={handleInput}
                                      value={editUser?.pop_up === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    {" "}
                                    Live Category
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="live_category"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.live_category === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.live_category === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="live_category"
                                      onChange={handleInput}
                                      value={
                                        editUser?.live_category === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Video Categories{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="videoCategories"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.videoCategories === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.videoCategories === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="videoCategories"
                                      onChange={handleInput}
                                      value={
                                        editUser?.videoCategories === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Live Categories{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="liveCategories"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.liveCategories === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.liveCategories === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="liveCategories"
                                      onChange={handleInput}
                                      value={
                                        editUser?.liveCategories === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Video Schedule
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="video_schedule"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.video_schedule === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.video_schedule === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="video_schedule"
                                      onChange={handleInput}
                                      value={
                                        editUser?.video_schedule === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    List Of Series{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="list_of_series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.list_of_series === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.list_of_series === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="list_of_series"
                                      onChange={handleInput}
                                      value={
                                        editUser?.list_of_series === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Single Series Id{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="single_series_id"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.single_series_id === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="single_series_id"
                                      onChange={handleInput}
                                      value={
                                        editUser?.single_series_id === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      {/* <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Latest Series{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="latest_series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.latest_series === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.latest_series === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="latest_series"
                                      onChange={handleInput}
                                      value={
                                        editUser?.latest_series === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Scheduled Publish Video{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="scheduled_publish_video"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.scheduled_publish_video === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.scheduled_publish_video === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="scheduled_publish_video"
                                      onChange={handleInput}
                                      value={
                                        editUser?.scheduled_publish_video === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Scheduled Publish LiveStream{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="scheduled_publish_LiveStream"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.scheduled_publish_LiveStream ===
                                        1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.scheduled_publish_LiveStream ===
                                        1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="scheduled_publish_LiveStream"
                                      onChange={handleInput}
                                      value={
                                        editUser?.scheduled_publish_LiveStream ===
                                        1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Content Partner{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="content_partner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.content_partner === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.content_partner === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="content_partner"
                                      onChange={handleInput}
                                      value={
                                        editUser?.content_partner === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Channel Partner{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="channel_partner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.channel_partner === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.channel_partner === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="channel_partner"
                                      onChange={handleInput}
                                      value={
                                        editUser?.channel_partner === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Individual Content Partner{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="Individual_ContentPartner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.Individual_ContentPartner === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.Individual_ContentPartner === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="Individual_ContentPartner"
                                      onChange={handleInput}
                                      value={
                                        editUser?.Individual_ContentPartner === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop">
                                    Individual Channel Partner{" "}
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label className="switch">
                                    <input
                                      name="Individual_channelPartner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.Individual_channelPartner === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.Individual_channelPartner === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="Individual_channelPartner"
                                      onChange={handleInput}
                                      value={
                                        editUser?.Individual_channelPartner === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 p-1">
                            <div className="home">
                              <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                <div>
                                  <label className="mt-1 headingpop headingpop">
                                    Single Series
                                  </label>
                                </div>

                                <div className="mt-1 d-flex align-items-center justify-content-around">
                                  <div className="mr-2">OFF</div>
                                  <label
                                    className="switch"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                  >
                                    <input
                                      name="single_series"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.single_series === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        editUser?.single_series === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="single_series"
                                      onChange={handleInput}
                                      value={
                                        editUser?.single_series === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                  <div className="ml-2">ON</div>
                                </div>
                              </div>
                              <span className="image_error_msg">
                                *Choose The Single Series
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              onClick={handleUpdate}
                              name="submit"
                            >
                              {" "}
                              Save Settings
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="iq-card">
              <div className="col-md-8 d-flex justify-content-between ">
                <h4>
                  <i className="entypo-list"></i> Order Home Page
                </h4>
              </div>
              <div className="col-md-8 p-0">
                <div className="order_home text-center">
                  <div className="row">
                    <div className="col-lg-4 col-4 data">#</div>
                    <div className="col-lg-4 col-4 data">Name</div>
                    <div className="col-lg-4 col-4 data">Action</div>
                  </div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="menu-items">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {menuItems.map((menuItem, index) => (
                            <Draggable
                              key={menuItem?.id.toString()}
                              draggableId={menuItem?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="text-center">
                                    <div className="row">
                                      <div className="col-lg-4 col-4 data_th">
                                        {menuItem.id}
                                      </div>
                                      <div className="col-lg-4 col-4 data_th">
                                        {menuItem.header_name}
                                      </div>
                                      <div className="col-lg-4 col-4 data_th">
                                        <Link
                                          to={
                                            "/admin/edit-orderhome/" +
                                            menuItem?.id
                                          }
                                          className="edit ms-1"
                                        >
                                          <i
                                            className="fa fa-pencil-square"
                                            aria-hidden="true"
                                          ></i>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default HomePageSettings;
