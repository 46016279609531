
import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineEye } from 'react-icons/ai';

function PayperviewPayments() {

  const datas = [
    {
      user: "Admin",
      title: "Zootopia",
      payment: "Cus_IVKRYwYv1Kj4IH",
      mode: "",
      paid: "₹",
      admin: "₹",
      amount: "₹",
      status: "1"


    }
  ]


  return (

    <>
      <div className='m-0 p-0'>
        <div className=''>

          <section className="section container-fluid py-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">PayPerView Payment</h4>

                  </div>
                </div>
                <hr />
                <div className="row p-3">

                 
                  <div className="col-md-12">

                    <div className="clear"></div>
                    <div className='d-flex justify-content-between p-3'>
                      <div className='d-flex' ><p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className='d-flex'>
                        <p>Search:</p>
                        <input type="search" id="gsearch" name="gsearch" className='border-0 search-rev' />
                      </div>

                    </div>


                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss" >
                          <thead>
                            <tr className="r1">
                              <th>ID</th>
                              <th>USERNAME</th>
                              <th>VIDEO TITLE</th>
                              <th>PAYMENT ID </th>
                              <th>PAYMENT MODE</th>
                              <th>PAID AMOUNT</th>
                              <th>ADMIN AMOUNT</th>
                              <th>MODERATOR AMOUNT</th>
                              <th>STATUS</th>
                              <th>ACTION</th>

                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr key={item?.id} className="r1">
                                <td key={key}> {key + 1} </td>
                                <td>{item.user}</td>
                                <td>{item.title}</td>
                                <td>{item.payment} </td>
                                <td>{item.mode}</td>
                                <td>{item.paid}</td>
                                <td>{item.admin}</td>
                                <td>{item.amount}</td>
                                <td>{item.status}</td>
                                <td> <Link
                                  to={"/admin/payment/payPerView-view"}
                                  className="edit ms-1"
                                >
                                  <AiOutlineEye />
                                </Link></td>

                              </tr>
                            </thead>
                          ))}
                          <tbody>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">

                      </div>

                    </div>

                  </div>
                </div>

              </div>
              <div>

              </div>

            </div>
          </section>
        </div></div>
    </>
  );
}

export default PayperviewPayments;

