import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function AllArtist() {
  const [artist, setArtist] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/artist/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.artists?.data;
        setArtist(result);
        // console.log(response)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/artist/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        // alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        // window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/artist/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.artists.data);
        setPrevious(res.data.artists.data);
        setArtist(res.data.artists.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/artist/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.artists.data);
        setPrevious(res.data.artists.data);
        setArtist(res.data.artists.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/artist/index?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.artists.data);
        setNext(res.data.artists.data);
        setArtist(res.data.artists.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className=" container-fluid p-0">
                <div className="iq-card">
                  <div className="row mt-3 align-items-center">
                    <div className="col-md-6 mb-3">
                      <h4>
                        <i className="entypo-newspaper"></i> Manage Artist
                      </h4>
                    </div>

                    {/* <div className="col-md-3">
                      <form method="get" role="form" className="search-form-full"> <div className="form-group mb-3"> <input type="text" className="form-control" name="s" id="search-input" value="" placeholder="Search..." /> <i className="entypo-search"></i> </div> </form>
                    </div>
                    <div className="col-md-3 text-right mb-3">
                      <Link to="" className="btn btn-primary"><i className="fa fa-plus-circle"></i> Create Artist</Link>
                    </div> */}
                  </div>

                  <div className="clear"></div>

                  <table className="table  artists-table  text-center p-0">
                    <thead>
                      <tr className="r1">
                        <th>
                          <label> S.No </label>
                        </th>
                        <th>
                          <label> Image </label>
                        </th>
                        <th>
                          <label> Artist Name </label>
                        </th>
                        <th>
                          <label> Artist Type </label>
                        </th>
                        <th>
                          <label> Action </label>
                        </th>
                      </tr>
                    </thead>
                    {artist.map((item, key) => (
                      <thead>
                        <tr>
                          <td key={item?.id}> {key + 1} </td>
                          <td>
                            <img
                              src={item.Thumbnail}
                              alt="Thumbnail"
                              width="100"
                            />
                          </td>
                          <td>{item.artist_name}</td>
                          <td>{item.artist_type}</td>
                          <td>
                            <Link
                              to={"/admin/edit-artist/" + item.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </table>

                  <div className="row text-center d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{page}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AllArtist;
