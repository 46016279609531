import React from 'react'

const ViewSubcription = () => {

    const datas = [
        {
          user: "Username",
          userdetail: "Flicknexs",
          title: "Subscription Title",
          titledetail: "",
          payment: "Payment ID",
          paymentdetail: "Cus_IVKRYwYv1Kj4IH",
          mode: "Payment Mode",
          modedetail: "",
          amount: "Paid Amount",
          amountdetail: "₹ 12",
          date: "Expiry Date",
          datedetail: "02 Jul 2021",
          status:"Status",
          statusdetail:"Active"
    
        }
      ]

    return (
        <div>
            <div className=' m-0 p-0'>
                <div className=''>

                    <section className="section container-fluid">
                        <div className="iq-card p-2 mt-3" >

                            <h4 className='p-2'><i className="entypo-plus"></i>View Subscription</h4>
                            <hr />
                            <div className='p-3' >
                            {datas.map((item) => (
                                <div>
                                <div className='row' key={item?.id}>
                                    <div className='col-md-8'>
                                    <div className='row'>
                                    <div className='col-md-3'>
                                    <label>{item.payment}</label>
                                        <p>{item.paymentdetail}</p>
                                    </div>
                                    <div className='col-md-3'>
                                    <label>{item.mode}</label>
                                        <p>{item.modedetail}</p>
                                    </div>
                                    <div className='col-md-3'>
                                    <label>{item.amount}</label>
                                        <p>{item.amountdetail}</p>
                                    </div>
                                    <div className='col-md-3'>
                                    <label>{item.date}</label>
                                        <p>{item.datedetail}</p>
                                    </div>
                                </div>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                                <div className='row'>
                                <div className='col-md-6'>
                                        <label>{item.status}</label>
                                        <p>{item.statusdetail}</p>

                                    </div>
                                    <div className='col-md-6'> 
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>{item.user}</label>
                                                <p>{item.userdetail}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>{item.title}</label>
                                                <p>{item.titledetail}</p>

                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ViewSubcription