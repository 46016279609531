import React, { useState, useEffect } from "react";
import "./signin.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Navbar from "./Header";

const Signin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    emailerr: "",
    passworderr: "",
  });
  
  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      navigate("/channel/home");
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  async function login() {
    // console.log(inputField);
    setLoading(true);
    setErrorMessage("");
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/channel-auth/sign-in`,
        { email: inputField.email, password: inputField.password },
        { cors: "no-cors" },
        { credentials: "include" },
        {withCredential: false },
        { credentials: "same-origin" },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res)
        let access_token = res.data.access_token;
        let expires_in = res.data.expires_in;
        let channel_name = res.data.Channel_user.channel_name;
        let user_id = res.data.Channel_user.id;
        // let role = res.data.Channel_user.role;
        let channel_image = res.data.Channel_user.channel_image;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("Channel_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("channel_name", channel_name);
        localStorage.setItem("channel_image", channel_image);
        localStorage.setItem("expires_in", expires_in);
        // localStorage.setItem("user", inputField)
        navigate("/channel/home");
        setLoading(false);
        window.location.reload();
        setTimeout(() => {
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage("Login failed. Please check your credentials*");
        alert("Please Enter Correct Details");
        console.log(err);
      });
  }

  const validForm = (e) => {
    e.preventDefault();

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    let formIsValid = true;
    setErrField({
      emailerr: "",
      passworderr: "",
    });
    if (inputField.email === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Email*",
      }));
    } else if (!validateEmail(inputField.email)) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Valid Email ID",
      }));
    }

    if (inputField.password === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Please Enter Password*",
      }));
    }

    if (inputField.email !== "" && inputField.password !== "") {
      login();
    }
    return formIsValid;
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <Navbar />

      <form onSubmit={validForm}>
        <section className="signin mt-3">
          <div className="container">
            <div className="d-flex justify-content-center text-center">
              <div className="box-signin mt-4">
                <h1 className="text-center mt-4">Sign In</h1>
                <div>
                  <input
                    id="email"
                    name="email"
                    className="mt-4 w-75 p-2"
                    value={inputField.email}
                    onChange={handleChange}
                    placeholder="Enter your Email"
                    type="email"
                    autoComplete="off"
                  />
                  {errField.emailerr !== "" && (
                    <span className="siginerror">{errField.emailerr}</span>
                  )}
                </div>
                <div>
                <span className="eyepasswordsignin" aria-hidden="true" onClick={togglePassword}> {passwordType === "password" ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</span>

                  <input
                    id="password"
                    name="password"
                    value={inputField.password}
                    type={passwordType}
                    onChange={handleChange}
                    className="mt-4 w-75 p-2"
                    placeholder="Enter Your Password"
                    autoComplete="off"
                  />
                  {errField.passworderr !== "" && (
                    <span className="siginerror">{errField.passworderr}</span>
                  )}
                </div>

                <div className="Remember mt-4 text-start">
                  <input type="checkbox" id="scales" name="check" />
                  <label
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Remember me
                  </label>
                </div>
{/* 
                <button
                 type="submit"
                  className="btn btn mt-4 w-75 button"
                  onClick={validForm}
                >
                  Sign In
                </button> */}
                <button
                  type="submit"
                  className="btn btn mt-4 w-75 button"
                  onClick={validForm}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login"}
                </button>
                {errorMessage && <p className="siginerrorlogin">{errorMessage}</p>}

                <Link to="">
                  <h6 className="forgot mt-1">Forgot Password?</h6>
                </Link>
                <Link to="/signup" className="text-decoration-none">
                  <h6 className="accountsign mb-5 mt-4">
                    Don't have an account? <span>Sign Up?</span>
                  </h6>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default Signin;
