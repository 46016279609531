import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function PaymentSettings() {
  const [stripe, setStripe] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_secret_key: "",
    test_publishable_key: "",
    live_secret_key: "",
    live_publishable_key: "",
    plan_name: "",
    stripe_lable: "",
    status: "",
  });
  const [stripes, setStripes] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_secret_key: "",
    test_publishable_key: "",
    live_secret_key: "",
    live_publishable_key: "",
    plan_name: "",
    stripe_lable: "",
    status: "",
    subscription_trail_status: "",
    subscription_trail_days: "",
  });
  const [razorpay_payment, setRazorpay_payment] = useState({
    Razorpay_Name: "",
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    Razorpay_Testkeyid: "",
    Razorpay_TestkeySecret: "",
    Razorpay_LivekeySecret: "",
    Razorpay_Livekeyid: "",
    Razorpay_lable: "",
    status: "",
  });
  const [razorpay_payments, setRazorpay_payments] = useState({
    Razorpay_Name: "",
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    Razorpay_Testkeyid: "",
    Razorpay_TestkeySecret: "",
    Razorpay_LivekeySecret: "",
    Razorpay_Livekeyid: "",
    Razorpay_lable: "",
    status: "",
  });
  const [paypal_payment, setPaypal_payment] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_paypal_username: "",
    test_paypal_password: "",
    test_paypal_signature: "",
    live_paypal_username: "",
    live_paypal_password: "",
    live_paypal_signature: "",
    paypal_plan_name: "",
    paypal_lable: "",
    status: "",
  });
  const [paypal_payments, setPaypal_payments] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_paypal_username: "",
    test_paypal_password: "",
    test_paypal_signature: "",
    live_paypal_username: "",
    live_paypal_password: "",
    live_paypal_signature: "",
    paypal_plan_name: "",
    paypal_lable: "",
    status: "",
  });
  const [paystack_payment, setPaystack_payment] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    paystack_name: "",
    paystack_test_secret_key: "",
    paystack_test_publishable_key: "",
    paystack_live_secret_key: "",
    paystack_live_publishable_key: "",
    paystack_callback_url: "",
    paystack_lable: "",
    status: "",
  });
  const [paystack_payments, setPaystack_payments] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    paystack_name: "",
    paystack_test_secret_key: "",
    paystack_test_publishable_key: "",
    paystack_live_secret_key: "",
    paystack_live_publishable_key: "",
    paystack_callback_url: "",
    paystack_lable: "",
    status: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get("http://api.flicknexs.com/admin/stripe_payment/index", {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.stripe_payment;
        var results = response?.data?.stripe_payment;
        setStripe(result);
        setStripes(results);
        //   console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get("http://api.flicknexs.com/admin/razorpay_payment/index", {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.razorpay_payment;
        var results = response?.data?.razorpay_payment;
        setRazorpay_payment(result);
        setRazorpay_payments(results);
        // console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get("http://api.flicknexs.com/admin/paypal_payment/index", {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.paypal_payment;
        var results = response?.data?.paypal_payment;
        setPaypal_payment(result);
        setPaypal_payments(results);
        //   console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get("http://api.flicknexs.com/admin/paystack_payment/index", {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.paystack_payment;
        var results = response?.data?.paystack_payment;
        setPaystack_payment(result);
        setPaystack_payments(results);
        //   console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setStripe({ ...stripe, [e.target.name]: e.target.value });
    setPaypal_payment({ ...paypal_payment, [e.target.name]: e.target.value });
    setPaystack_payment({
      ...paystack_payment,
      [e.target.name]: e.target.value,
    });
    setRazorpay_payment({
      ...razorpay_payments,
      [e.target.name]: e.target.value,
    });

    if (e.target.checked === true) {
      setStripe({ ...stripe, [e.target.name]: 1 });
      setPaypal_payment({ ...paypal_payment, [e.target.name]: 1 });
      setPaystack_payment({ ...paystack_payment, [e.target.name]: 1 });
      setRazorpay_payment({ ...razorpay_payments, [e.target.name]: 1 });
    } else {
      setStripe({ ...stripe, [e.target.name]: e.target.value });
      setStripes({ ...stripes, [e.target.name]: 0 });
      setPaypal_payment({ ...paypal_payment, [e.target.name]: e.target.value });
      setPaypal_payments({ ...paypal_payments, [e.target.name]: 0 });
      setPaystack_payment({
        ...paystack_payment,
        [e.target.name]: e.target.value,
      });
      setPaystack_payments({ ...paystack_payments, [e.target.name]: 0 });
      setRazorpay_payment({
        ...razorpay_payment,
        [e.target.name]: e.target.value,
      });
      setRazorpay_payments({ ...razorpay_payments, [e.target.name]: 0 });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: stripe.id,
      user_id: stripe.user_id,
      payment_type: stripe.payment_type,
      payment_mode: stripes.payment_mode,
      test_secret_key: stripe.test_secret_key,
      test_publishable_key: stripe.test_publishable_key,
      live_secret_key: stripe.live_secret_key,
      live_publishable_key: stripe.live_publishable_key,
      plan_name: stripe.plan_name,
      stripe_lable: stripe.stripe_lable,
      status: stripes.status,
      subscription_trail_days: stripes.subscription_trail_days,
      subscription_trail_status: stripes.subscription_trail_status,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/stripe_payment/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    const editpaystack = {
      id: paypal_payment.id,
      user_id: paypal_payment.user_id,
      payment_type: paypal_payment.payment_type,
      payment_mode: paypal_payments.payment_mode,
      test_paypal_username: paypal_payment.test_paypal_username,
      test_paypal_password: paypal_payment.test_paypal_password,
      test_paypal_signature: paypal_payment.test_paypal_signature,
      live_paypal_username: paypal_payment.live_paypal_username,
      live_paypal_password: paypal_payment.live_paypal_password,
      live_paypal_signature: paypal_payment.live_paypal_signature,
      status: paypal_payments.status,
      paypal_plan_name: paypal_payment.paypal_plan_name,
      paypal_lable: paypal_payment.paypal_lable,
    };
    // console.log(editInputvalue);
    let editpaystacks = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/paypal_payment/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editpaystack),
      }
    );

    // console.log(resjson)
    if (editpaystacks.status === 200) {
      setMessage(editpaystacks.success);
      // alert('Success')
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    const editInputpaypal = {
      id: paystack_payment.id,
      user_id: paystack_payment.user_id,
      payment_type: paystack_payment.payment_type,
      payment_mode: paystack_payments.payment_mode,
      paystack_name: paystack_payment.paystack_name,
      paystack_test_secret_key: paystack_payment.paystack_test_secret_key,
      paystack_test_publishable_key:
        paystack_payment.paystack_test_publishable_key,
      paystack_live_secret_key: paystack_payment.paystack_live_secret_key,
      paystack_live_publishable_key:
        paystack_payment.paystack_live_publishable_key,
      paystack_callback_url: paystack_payment.paystack_callback_url,
      status: paystack_payments.status,
      paystack_lable: paystack_payment.paystack_lable,
    };
    // console.log(editInputvalue);
    let respay = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/paystack_payment/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputpaypal),
      }
    );

    // console.log(resjson)
    if (respay.status === 200) {
      setMessage(respay.success);
      // alert('Success')
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }

    const editInputrazor = {
      id: razorpay_payment.id,
      user_id: razorpay_payment.user_id,
      payment_type: razorpay_payment.payment_type,
      payment_mode: razorpay_payments.payment_mode,
      razorpay_name: razorpay_payment.razorpay_name,
      Razorpay_Testkeyid: razorpay_payment.Razorpay_Testkeyid,
      Razorpay_TestkeySecret: razorpay_payment.Razorpay_TestkeySecret,
      Razorpay_LivekeySecret: razorpay_payment.Razorpay_LivekeySecret,
      Razorpay_Livekeyid: razorpay_payment.Razorpay_Livekeyid,
      Razorpay_lable: razorpay_payment.Razorpay_lable,
      status: razorpay_payments.status,
    };
    // console.log(editInputvalue);
    let resrazor = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/razorpay_payment/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputrazor),
      }
    );

    // console.log(resjson)
    if (resrazor.status === 200) {
      setMessage(resrazor.success);
      // alert('Success')
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Payment Settings
                    </h4>
                    <hr />
                  </div>
                  <div>
                    <p>
                      <h3>Stripe Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>

                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.status === 1 ? true : false
                                }
                                checked={stripe?.status === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={stripe?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>

                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for="">Stripe Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>

                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.payment_mode === 1 ? true : false
                                }
                                checked={
                                  stripe?.payment_mode === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={stripe?.payment_mode === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>

                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          Stripe Plan (
                          <Link
                            to="https://stripe.com/docs/tutorials/dashboard"
                            target="_blank"
                          >
                            https://stripe.com/docs/tutorials/dashboard
                          </Link>
                          )
                        </div>
                        <div className="panel-body">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plan_name"
                            placeholder="Test Secret Key"
                            value={stripe?.plan_name}
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Stripe Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="stripe_lable"
                          placeholder="Stripe Lable"
                          value={stripe?.stripe_lable}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_publishable_key"
                          placeholder="Test Publishable Key"
                          value={stripe?.test_publishable_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_secret_key"
                          value={stripe?.test_secret_key}
                          placeholder="Test Secret Key"
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_publishable_key"
                          value={stripe?.live_publishable_key}
                          placeholder="Live Publishable Key"
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_secret_key"
                          placeholder="Live Secret Key"
                          value={stripe?.live_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label for="">
                          {" "}
                          Stripe Subscription Trail Status:{" "}
                        </label>
                        <div className="d-flex  align-items-center">
                          <div> Disable </div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="subscription_trail_status"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.subscription_trail_status === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  stripe?.subscription_trail_status === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="subscription_trail_status"
                                onChange={handleInput}
                                value={
                                  stripe?.subscription_trail_status === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div> Enable </div>
                          <div
                            className="make-switch"
                            data-on="success"
                            data-off="warning"
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Stripe Subscription Trail Days: </label>
                        <input
                          type="number"
                          min="1"
                          className="form-control"
                          name="subscription_trail_days"
                          id="subscription_trail_days"
                          placeholder=" 1 - 100 "
                          value={stripe?.subscription_trail_days}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <br />
                    <p>
                      <h3>PayPal Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label for="">Payment Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  paypal_payment?.status === 1 ? true : false
                                }
                                checked={
                                  paypal_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={paypal_payment?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label for="">PayPal Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  paypal_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  paypal_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  paypal_payment?.payment_mode === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>
                                Paypal Payment API Keys (
                                <Link
                                  to="https://www.paypal.com/us/home"
                                  target="_blank"
                                >
                                  https://www.paypal.com/us/home
                                </Link>
                                )
                              </label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <label>Test PayPal Username:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="test_paypal_username"
                              placeholder="Test PayPal Username"
                              value={paypal_payment?.test_paypal_username}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test PayPal Password:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_paypal_password"
                          placeholder="Test PayPal Password"
                          value={paypal_payment?.test_paypal_password}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test PayPal Signature:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_paypal_signature"
                          placeholder="Test PayPal Signature"
                          value={paypal_payment?.test_paypal_signature}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Username:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_username"
                          placeholder="Live PayPal Username"
                          value={paypal_payment?.live_paypal_username}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Password:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_password"
                          placeholder="Live PayPal Password"
                          value={paypal_payment?.live_paypal_password}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Signature:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_signature"
                          placeholder="Live PayPal Signature"
                          value={paypal_payment?.live_paypal_signature}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>PayPal Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="paypal_lable"
                          placeholder="PayPal Label"
                          value={paypal_payment?.paypal_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <p>
                      <h3>Paystack Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  paystack_payment?.status === 1 ? true : false
                                }
                                checked={
                                  paystack_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={
                                  paystack_payment?.status === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for=""> Paystack Mode </label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  paystack_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  paystack_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  paystack_payment?.payment_mode === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>

                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          {" "}
                          Paystack Plan (
                          <Link to="https://paystack.com/" target="_blank">
                            {" "}
                            https://paystack.com/{" "}
                          </Link>
                          )
                        </div>
                        <label>Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_name"
                          placeholder="Paystack Name"
                          value={paystack_payment?.paystack_name}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Callback URL: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_callback_url"
                          placeholder="Paystack Callback URL"
                          value={paystack_payment?.paystack_callback_url}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Test Publishable Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_test_publishable_key"
                          placeholder="Paystack Test Publishable Key"
                          value={
                            paystack_payment?.paystack_test_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Test Secret Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_test_secret_key"
                          placeholder="Paystack Test Secret Key"
                          value={paystack_payment?.paystack_test_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Live Publishable Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_live_publishable_key"
                          placeholder="Paystack Live Publishable Key"
                          value={
                            paystack_payment?.paystack_live_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Live Secret Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_live_secret_key"
                          placeholder="Paystack Live Secret Key"
                          value={paystack_payment?.paystack_live_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Label: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_lable"
                          placeholder="paystack Label"
                          value={paystack_payment?.paystack_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>{" "}
                    <br />
                    <br />
                    <p>
                      <h3>Razorpay Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  razorpay_payment?.status === 1 ? true : false
                                }
                                checked={
                                  razorpay_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={
                                  razorpay_payment?.status === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for="">Razorpay Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  razorpay_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  razorpay_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  razorpay_payment?.payment_mode === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          Razorpay Plan (
                          <Link to="https://razorpay.com/" target="_blank">
                            {" "}
                            https://razorpay.com/{" "}
                          </Link>
                          )
                        </div>
                        <div className="panel-body">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="razorpay_name"
                            value={razorpay_payment?.razorpay_name}
                            onChange={handleInput}
                            placeholder="Razorpay Test Secret Key"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Test Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_Testkeyid"
                          placeholder="Razorpay Test Secret Key"
                          value={razorpay_payment?.Razorpay_Testkeyid}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Test Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_test_publishable_key"
                          placeholder="Razorpay Test Publishable Key"
                          value={
                            razorpay_payment?.Razorpay_test_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Live Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_LivekeySecret"
                          placeholder="Razorpay Live Secret Key"
                          value={razorpay_payment?.Razorpay_LivekeySecret}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Live Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_live_publishable_key"
                          placeholder="Razorpay Live Publishable Key"
                          value={
                            razorpay_payment?.Razorpay_live_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_label"
                          placeholder="Razorpay Label"
                          value={razorpay_payment?.Razorpay_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>{" "}
                    <br />
                    <br />
                    <div className="panel-body mt-3 d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Update Payment Settings"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PaymentSettings;
