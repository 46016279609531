import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ImportUser.css";

function ImportUser() {
  const [user, setUser] = useState("");

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const downloadFile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/users/import-sample-file`,
        { headers: headers },
        {
          method: "GET",
        }
      );
      const fileData = await response?.blob();
      const downloadUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "example.xlsx"; // Set the file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  async function importuser() {
    console.log("append data");

    const formData = new FormData();

    formData.append("excel_file", user);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/users/bluk-export`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          alert("Added successfully  !");
          setInterval(10000);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card Bulk_Data">
              <div id="moderator-container">
                <div className="moderator-section-title">
                  <h4>
                    <i className="entypo-globe"></i> Import user Bulk Data{" "}
                  </h4>
                </div>

                <div>
                  <div className="container mt-4">
                    <div className="row justify-content-between">
                      <div className="col-md-6">
                        <label for=""> Import Excel file </label>
                        <div className="form-group row">
                          <div className="col-md-12 form-group" align="left">
                            <input
                              type="file"
                              name="file"
                              className="form-control"
                              onChange={(e) => setUser(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label for=""> Sample Import Excel file </label>
                        <div className="form-group row">
                          <div className="col-md-12 form-group" align="left">
                            <div className="text-white">
                              <Link
                                onClick={downloadFile}
                                className="fa fa-file-excel-o video_pdf"
                                download
                              ></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 form-group" align="right">
                    <button className="btn btn-success" onClick={importuser}>
                      {" "}
                      Import{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ImportUser;
