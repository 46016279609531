import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Menu.css";

function Menu() {
  const [menus, setMenu] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/menu/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.menus?.data;
        setMenu(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/menu/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/menu/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data?.menus?.data);
        setPrevious(res?.data?.menus?.data);
        setMenu(res?.data?.menus?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/menu/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res?.data.menus?.data);
        setPrevious(res?.data?.menus?.data);
        setMenu(res?.data?.menus?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/menu/index?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.menus?.data);
        setNext(res?.data?.menus?.data);
        setMenu(res?.data?.menus?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className=" mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row justify-content-start">
                    <div className="col-md-12 d-flex justify-content-between">
                      <h4>
                        <i className="entypo-list"></i> Menu Items
                      </h4>
                      <div>
                        <Link to="/admin/addmenu" className="btn btn-primary">
                          <i className="fa fa-plus-circle"></i> Add New
                        </Link>
                        <Link
                          to="/admin/footermenu"
                          className="btn btn-primary"
                        >
                          <i className="fa fa-plus-circle"></i> Change Footer
                          Menu
                        </Link>
                        <Link
                          to="/admin/mobilemenu"
                          className="btn btn-primary"
                        >
                          <i className="fa fa-plus-circle"></i> Mobile Side Menu
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-8" align="right"></div>
                  </div>

                  <div className="col-md-8 p-0">
                    <div
                      className="panel panel-primary menu-panel"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title">
                          <p className="p1">
                            Organize the Menu Items below: (max of 3 levels)
                          </p>
                        </div>

                        <div className="panel-options">
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>
                        </div>
                      </div>

                      <div className="">
                        <table
                          id="table "
                          className="table table-bordered iq-card text-center"
                        >
                          <thead>
                            <tr className="r1 ">
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody id="tablecontents">
                            {menus?.map((item, key) => (
                              <tr  key={item?.id} className="row1">
                                <td key={key}> {key + 1} </td>
                                <td>{item?.name}</td>
                                <td>
                                  {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                  <Link
                                    to={"/admin/editmenu/" + item?.id}
                                    className="edit ms-2"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-2">
                                    <span
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClick}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{page}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClick}
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Menu;
