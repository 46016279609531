import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

const EditAdsCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    company_name: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [data, setData] = useState([]);
  // console.log("data", data)
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState("");

  // console.log(avatar)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-categories-edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData.json();
      var res = resData.Advertisement_categories;
      setEditUser(res);
      // {
      // 	results.map((item) =>
      // 		setAvatar(item.avatar),
      // 	)
      // }
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      name: editUser.name,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Advertisement-categories-update/` +
        id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div className="iq-card iq-card-header ">
            <div className="iq-header-title">
              <h4 className="card-title">Ads Category List</h4>
            </div>
            <div className="mt-3">
              <div>
                <label>Category Name </label>

                <input
                  type="text"
                  name="name"
                  value={editUser.name}
                  onChange={handleInput}
                  placeholder="Ads Category Name"
                  className="form-control rtmp_urls"
                />
              </div>
            </div>
            <div className="text-end py-3">
              <button
                type="submit"
                className="btn btn-primary ml-3 "
                onClick={handleUpdate}
              >
                Update Category
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditAdsCategories;
