import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./LinkSettings.css";

function LinkSettings() {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    ios_app_store_id: "",
    ios_url: "",
    ipad_app_store_id: "",
    ipad_url: "",
    android_url: "",
    windows_phone_app_store_id: "",
    android_app_store_id: "",
    windows_phone_url: "",
    user_id: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/deep_linking_setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Deep_Linking_Setting;
      setEditUser(res);
      //    console.log(resData)
      //    console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      ios_app_store_id: editUser.ios_app_store_id,
      ios_url: editUser.ios_url,
      ipad_app_store_id: editUser.ipad_app_store_id,
      ipad_url: editUser.ipad_url,
      android_app_store_id: editUser.android_app_store_id,
      android_url: editUser.android_url,
      windows_phone_app_store_id: editUser.windows_phone_app_store_id,
      windows_phone_url: editUser.windows_phone_url,
      user_id: editUser.user_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/deep_linking_setting/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid ">
            <div className=" p-2">
              <div className="iq-card">
                <div className="">
                  <form
                    id="update-menu-form"
                    accept-charset="UTF-8"
                    action=""
                    method="post"
                  >
                    <div>
                      <h4>
                        <i className="entypo-globe"></i> Link Settings
                      </h4>
                      <hr />
                    </div>
                    <div className="">
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IOS App Store ID</label>
                            <input
                              name="ios_app_store_id"
                              id="ios_app_store_id"
                              placeholder="IOS App Store ID"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ios_app_store_id}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IOS URL</label>
                            <input
                              name="ios_url"
                              id="ios_url"
                              placeholder="IOS URL"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ios_url}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IPAD App Store ID</label>
                            <input
                              name="ipad_app_store_id"
                              onChange={handleInput}
                              value={editUser?.ipad_app_store_id}
                              id="ipad_app_store_id"
                              placeholder="IPAD App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IPAD URL</label>
                            <input
                              name="ipad_url"
                              id="ipad_url"
                              placeholder="IPAD URL"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ipad_url}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Android App Store ID</label>
                            <input
                              name="android_app_store_id"
                              id="android_app_store_id"
                              onChange={handleInput}
                              value={editUser?.android_app_store_id}
                              placeholder="Android App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Android URL</label>
                            <input
                              name="android_url"
                              id="android_url"
                              onChange={handleInput}
                              value={editUser?.android_url}
                              placeholder="Android URL"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Windows Phone App Store ID</label>
                            <input
                              name="windows_phone_app_store_id"
                              onChange={handleInput}
                              value={editUser?.windows_phone_app_store_id}
                              id="windows_phone_app_store_id"
                              placeholder="Windows Phone App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Windows Phone URL</label>
                            <input
                              name="windows_phone_url"
                              id="windows_phone_url"
                              onChange={handleInput}
                              value={editUser?.windows_phone_url}
                              placeholder="Windows Phone URL"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 text-right home d-flex justify-content-end">
                      <Link
                        type="submit"
                        className="btn btn-primary"
                        id="submit-update-menu"
                        onClick={handleUpdate}
                      >
                        Update
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LinkSettings;
