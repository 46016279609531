import React from "react";
import { Link } from "react-router-dom";

function Videoschedule() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" class="content-page">
              <div class="mt-4">
                <div class="admin-section-title">
                  <div class="iq-card">
                    <div class="row justify-content-start">
                      <div class="col-md-8 d-flex justify-content-between">
                        <h4>
                          <i class="entypo-list"></i> Stream Scheduled Videos{" "}
                        </h4>
                        <div>
                          <Link
                            to="/admin/add-schedule"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-plus-circle"></i> Create a New
                            Schedule
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div class="modal fade" id="update-menu">
                      <div class="modal-dialog">
                        <div class="modal-content"></div>
                      </div>
                    </div>

                    <div class="clear"></div>

                    <div class="col-md-8 p-0">
                      <div
                        class="panel panel-primary menu-panel"
                        data-collapsed="0"
                      >
                        <div class="panel-heading">
                          <div class="panel-title">
                            <p class="p1">Organize the video below :</p>
                          </div>

                          <div class="panel-options">
                            <a href="#" data-rel="collapse">
                              <i class="entypo-down-open"></i>
                            </a>
                          </div>
                        </div>

                        <div class="">
                          <table
                            id="table"
                            class="table table-bordered iq-card text-center schedules"
                          >
                            <thead>
                              <tr class="r1 ">
                                <th width="30px">#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Video Schedule</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody id="tablecontents">
                              <tr class="text-center">
                                <td> </td>
                                <td> </td>
                                <td> </td>

                                <td>
                                  <a
                                    href=""
                                    class="iq-bg-success"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Schedule"
                                  >
                                    <i class="fa fa-calendar">
                                      {" "}
                                      Manage Schedule
                                    </i>
                                  </a>
                                </td>
                                {/* <td class="align-items-center list-user-action">
                                  <a
                                    href=""
                                    class="iq-bg-success"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Edit"
                                  >
                                    <i class="ri-pencil-line"></i>
                                  </a>
                                  <a
                                    href=""
                                    onclick="return confirm('Are you sure?')"
                                    class="iq-bg-danger"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Delete"
                                  >
                                    <i class="ri-delete-bin-line"></i>
                                  </a>
                                </td> */}
                                <td>
                                  {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                  <Link
                                    to={"/admin/edit-schedule/:id"}
                                    className="edit ms-2"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-2">
                                    <span>
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Videoschedule;
