import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Advertiser() {
  const [advertiser, setAdvertiser] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Advertisers/list`, {
        headers: headers,
      })
      .then((response) => {
        var result = response.data.Advertiser;
        setAdvertiser(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data.users_list.data);
        setPrevious(res.data.users_list.data);
        setAdvertiser(res.data.users_list.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data.users_list.data);
        setPrevious(res.data.users_list.data);
        setAdvertiser(res.data.users_list.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/users/list?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        console.log("next", res.data.users_list.data);
        setNext(res.data.users_list.data);
        setAdvertiser(res.data.users_list.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Advertisers Lists</h4>
                        </div>
                      </div>
                      <div className="iq-card-body table-responsive">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table ">
                            <thead>
                              <tr className="r1">
                                <th>#</th>
                                <th>Company Name</th>
                                <th>Lisence Number</th>
                                <th>Address</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {advertiser.map((item, key) => (
                                <tr className="r1" key={item?.id}>
                                  <td key={key}> {key + 1} </td>
                                  <td>{item.company_name} </td>
                                  <td>{item.license_number} </td>
                                  <td>{item.address} </td>
                                  <td>{item.mobile_number} </td>
                                  <td>{item.email_id}</td>
                                  <td>
                                    <button className="font-weight-bold btn btn-success">
                                      Approved
                                    </button>

                                    <button className="font-weight-bold btn btn-danger mt-2 ">
                                      Disapproved
                                    </button>
                                  </td>

                                  <td>
                                    <Link
                                      to={"/admin/editadvertiser/" + item.id}
                                      className="edit ms-1"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-3">
                                      <span
                                        onClick={() => deleteOperation(item.id)}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Advertiser;
