import React from "react";

function ManageTranslationLanguage() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <h4>
                            <i className="entypo-archive"></i> Languages
                          </h4>
                        </div>
                        <div className="col-md-9" align="right">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="fa fa-plus-circle"></i> Add New
                          </button>
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                New Language
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>

                            <div className="modal-body">
                              <form>
                                <div className="form-group ">
                                  <label>Name:</label>
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                  />
                                </div>
                              </form>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>

                              <button type="button" className="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal fade" id="update-category">
                        <div className="modal-dialog">
                          <div className="modal-content"></div>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <div
                        className="panel panel-primary category-panel"
                        data-collapsed="0"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">
                            <p className="p1">Organize the Languages below: </p>
                          </div>

                          <div className="panel-options">
                            <a href="#" data-rel="collapse">
                              <i className="entypo-down-open"></i>
                            </a>
                          </div>
                        </div>

                        <div className="panel-body">
                          <div
                            id="nestable"
                            className="nested-list dd with-margins"
                          >
                            <table className="table table-bordered iq-card text-center">
                              <tr className="table-header r1">
                                <th className="">
                                  <label>Language</label>
                                </th>
                                <th className="">
                                  <label>Action</label>
                                </th>
                              </tr>
                              <tr>
                                <td>php echo $languagename</td>
                                <td className="">
                                  <div className=" align-items-center list-user-action">
                                    <a href="" className="iq-bg-success">
                                      <i className="fa fa-pencil-square"></i>
                                    </a>{" "}
                                    <a href="" className="iq-bg-danger">
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageTranslationLanguage;
