import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditSlider.css";

function EditSlider() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [slider, setSlider] = useState([]);
  const [editUser, setEditUser] = useState({
    user_id: "",
    title: "",
    link: "",
    trailer_link: "",
    active: "",
    order_position: "",
    slider_image_url: "",
    slider_player_image_url: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [slider_image, setSlider_image] = useState("");
  const [player_image, setPlayer_image] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/slider/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.slider[0];
      // console.log(res);
      setEditUser(res);
      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/slider/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.slider;
        setSlider(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      user_id: editUser.user_id,
      link: editUser.link,
      trailer_link: editUser.trailer_link,
      active: editUser.active,
      order_position: editUser.order_position,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/slider/update/` + id,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')

      var slider_id = resjson.slider_id;

      console.log(slider_id);

      // console.log('slider_id', slider_id)
      const formDatas = new FormData();
      formDatas.append("slider_id", slider_id);
      formDatas.append("slider_image", slider_image);
      formDatas.append("player_image", player_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/slider/slider_image`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            navigate("/admin/sliders");
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div className="admin-section-title">
            <div className="iq-card">
              <div className="row align-items-center ">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Slider</h4>
                </div>

                <div className="modal-body">
                  <form>
                    <div className="form-group ">
                      <label>
                        Select the Slider Image{" "}
                        <span>(1280x720 px or 16:9 ratio)</span>
                      </label>
                      <div>
                        <img src={editUser?.slider_image_url} width="150" />
                      </div>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="slider"
                        id="slider"
                        onChange={(e) => setSlider_image(e.target.files[0])}
                      />
                    </div>

                    <div className="form-group ">
                      <label className="mb-1">
                        Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
                      </label>
                      <br />
                      <div>
                        <img
                          src={editUser?.slider_player_image_url}
                          width="150"
                        />
                      </div>
                      <input
                        type="file"
                        name="player_image"
                        className="form-control"
                        id="player_image"
                        onChange={(e) => setPlayer_image(e.target.files[0])}
                      />
                    </div>

                    <div className="form-group ">
                      <label>Target Link:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="link"
                        id="link"
                        value={editUser?.link}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group ">
                      <label>Trailer Link:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="trailer_link"
                        id="trailer_link"
                        value={editUser?.trailer_link}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-group ">
                      <label>Title:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        value={editUser?.title}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>Status:</label>
                            </div>
                            <div className="col-lg-6">
                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.active === 1 ? true : false
                                  }
                                  checked={editUser?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={editUser?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                        id="submit-new-cat"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditSlider;
