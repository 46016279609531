import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Slider.css";

function Slider() {
  const [slider, setSlider] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/slider/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.slider?.data;
        setSlider(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/slider/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        // alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        // window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/slider/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.slider?.data);
        setSlider(res?.data?.slider?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/slider/index?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.slider?.data);
        setSlider(res?.data?.slider?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/slider/index?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.slider.data);
        setNext(res?.data?.slider?.data);
        setSlider(res?.data?.slider?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div className="">
            <div className="admin-section-title">
              <div className="iq-card">
                <div className="row align-items-center mt-2 mb-2">
                  <div className="col-md-4">
                    <h4>
                      <i className="entypo-archive"></i> Sliders{" "}
                    </h4>
                  </div>
                  <div className="col-md-8" align="right">
                    <Link
                      to="/admin/addslider"
                      className="btn btn-primary mb-4"
                    >
                      <i className="fa fa-plus-circle"></i> Add New
                    </Link>
                  </div>
                  <hr />
                </div>

                <div
                  className="panel panel-primary category-panel"
                  data-collapsed="0"
                >
                  <div className="panel-heading">
                    <div className="panel-title">
                      <p className="p1">Organize the sliders below: </p>
                    </div>
                  </div>
                  <div className="panel-body ">
                    <div id="nestable" className="nested-list with-margins">
                      <table
                        className="table table-bordered iq-card"
                        id="slidertbl"
                      >
                        <thead>
                          <tr className="table-header r1">
                            <th className="text-center">Slider Title</th>
                            <th className="text-center">Slider Image</th>
                            <th className="text-center">Player Image</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Operation</th>
                          </tr>
                        </thead>
                        {slider?.map((item, key) => (
                          <thead>
                            <tr key={item?.id} className="dd ">
                              <td valign="bottom" className="text-center">
                                {item?.title}
                              </td>
                              <td valign="bottom" className="text-center">
                                {" "}
                                <img
                                  src={item?.slider_image_url}
                                  width="100"
                                  height="100"
                                />
                              </td>
                              <td valign="bottom" className="text-center">
                                {" "}
                                <img
                                  src={item?.slider_player_image_url}
                                  width="100"
                                  height="100"
                                />
                              </td>

                              <td className="text-center sliderstatus">
                                {item?.active === 0 || item?.active === null ? (
                                  <span className="bg-warning video_active">
                                    Draft
                                  </span>
                                ) : item?.active === 1 &&
                                  item?.active === 1 &&
                                  item?.active === 1 ? (
                                  <span className="bg-success video_active">
                                    Approved
                                  </span>
                                ) : (
                                  <span className="bg-warning video_active">
                                    Draft
                                  </span>
                                )}
                              </td>
                              <td className="text-center">
                                {/* <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                                <Link
                                  to={"/admin/editslider/" + item?.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-2">
                                  <span
                                    onClick={() => deleteOperation(item?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          </thead>
                        ))}
                      </table>

                      <div className="row text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClick}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{page}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClick}
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Slider;
