import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "./Edit_Episode_manage.css";

function Edit_Episode_manage() {
  const [seriesgen, setSeriesgen] = useState([]);

  const { id } = useParams();

  const { series_id } = useParams();
  const { season_id } = useParams();

  // console.log(series_id)
  // console.log(season_id)

  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    season_id: "",
    title: "",
    slug: "",
    episode_description: "",
    type: "",
    access: "",
    ppv_status: "",
    ppv_price: "",
    mp4_url: "",
    url: "",
    path: "",
    disk: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    episode_order: "",
    active: "",
    skip_recap: "",
    skip_intro: "",
    recap_start_time: "",
    recap_end_time: "",
    intro_start_time: "",
    intro_end_time: "",
    featured: "",
    banner: "",
    footer: "",
    duration: "",
    age_restrict: "",
    views: "",
    rating: "",
    status: "",
    free_content_duration: "",
    search_tags: "",
    ads_position: "",
    episode_ads: "",
    uploaded_by: "",
  });
  const [message, setMessage] = useState("");

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [data, setData] = useState([]);
  const [banner, setBanner] = useState("");

  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, content: e });
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.episode[0];
      // var result = resData.user_access
      setEditUser(res);
      // setUseraccess(result)
      // console.log(resData)
      console.log(res);
      console.log(res);
    };
    getUser();

    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          // console.log(data.user_access, "user");
          const userAcces = data?.user_access?.map((userAcc) => ({
            value: userAcc?.role,
            label: userAcc?.name,
          }));

          const ppv_price = data?.InappPurchase?.map((userAcc) => ({
            value: userAcc?.id,
            label: userAcc?.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series-category/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.SeriesGenre?.data;
        // var resultss = response.data.SeriesGenre[0].name
        setData(result);
        // console.log('result', result)
        // console.log('SeriesGenre', result);
      })
      .catch((error) => console.log(error));

    // const getUser = async () => {
    //     const reqData = await fetch(`https://api.flicknexs.com/admin/series_season/edit/${id}`, { headers: headers });
    //     const resData = await reqData.json();
    //     console.log(resData)
    //     var res = resData.episode[0]
    //     // var result = resData.user_access
    //     setEditUser(res);
    //     // setUseraccess(result)
    //     console.log(res)

    //     // console.log(res)
    // }
    // getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.name]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.name]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      episode_id: id,
      user_id: editUser.user_id,
      series_id: editUser.series_id,
      season_id: editUser.season_id,
      title: editUser.title,
      slug: editUser.slug,
      episode_description: editUser.episode_description,
      type: editUser.type,
      access: editUser.access,
      ppv_status: editUser.ppv_status,
      uploaded_by: editUser.uploaded_by,
      ppv_price: editUser.ppv_price,
      mp4_url: editUser.mp4_url,
      url: editUser.url,
      path: editUser.path,
      disk: editUser.disk,
      stream_path: editUser.stream_path,
      processed_low: editUser.processed_low,
      converted_for_streaming_at: editUser.converted_for_streaming_at,
      episode_order: editUser.episode_order,
      active: editUser.active,
      skip_recap: editUser.skip_recap,
      skip_intro: editUser.skip_intro,
      recap_start_time: editUser.recap_start_time,
      recap_end_time: editUser.recap_end_time,
      intro_start_time: editUser.intro_start_time,
      intro_end_time: editUser.intro_end_time,
      featured: editUser.featured,
      banner: editUser.banner,
      footer: editUser.footer,
      duration: editUser.duration,
      age_restrict: editUser.age_restrict,
      views: editUser.views,
      rating: editUser.rating,
      status: editUser.status,
      free_content_duration: editUser.free_content_duration,
      search_tags: editUser.search_tags,
      live_ads: editUser.live_ads,
      ads_position: editUser.ads_position,
      episode_ads: editUser.episode_ads,
      Channel_Partner_id: Channel_Partner_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/update/` +
        id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      var episode_id = resjson.Episode_id;

      const formDat = new FormData();

      formDat.append("episode_id", episode_id);
      formDat.append("image", image);
      formDat.append("player_image", player_image);
      formDat.append("tv_image", tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Image-upload`,
          formDat,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            //   console.log('result', result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const facebook = () => {
    window.open("https://facebook.com", "_blank");
  };
  const twitter = () => {
    window.open("https://twitter.com/", "_blank");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="">
              <div className="iq-card">
                <h5>Edit Manage Episode</h5>
                <hr />

                <form>
                  <div className="row ">
                    <div className="col-md-6 mb-3">
                      <label className="m-0">
                        <h4 className="fs-title m-0">Embed Link:</h4>
                      </label>
                      <p>
                        Click{" "}
                        <Link
                          to="#"
                          onclick="EmbedCopy();"
                          className="share-ico"
                        >
                          <i className="ri-links-fill"></i> here
                        </Link>{" "}
                        to get the Embedded URL
                      </p>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="m-0">
                        <h4 className="fs-title m-0">Social Share:</h4>
                      </label>
                      <div className="share-box">
                        <Link onClick={facebook} className="share-ico">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </Link>
                        &nbsp;
                        <Link onClick={twitter} className="share-ico">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="season_id"
                    id="series_id"
                    placeholder="series_id"
                    value={editUser?.series_id}
                    onChange={handleInput}
                    hidden
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="season_id"
                    id="season_id"
                    placeholder="season_id"
                    value={editUser?.season_id}
                    onChange={handleInput}
                    hidden
                  />

                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label className="m-0">Title</label>
                      <p className="p1">
                        Add the episodes title in the textbox below:
                      </p>

                      <div className="panel-body">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          id="title"
                          placeholder="Episode Title"
                          value={editUser?.title}
                          onChange={handleInput}
                        />
                      </div>
                    </div>

                    {/* <div className="col-sm-6 mb-3">
                                            <label className="m-0">Created Date</label>
                                            <p className="p1">Select Date/Time Below</p>

                                            <div className="panel-body">
                                                <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
                                            </div>
                                        </div> */}

                    <div className="col-sm-6 mb-3">
                      <label className="m-0">Slug</label>
                      <p className="p1">
                        Add the episodes Slug in the textbox below:
                      </p>

                      <div className="panel-body">
                        <input
                          type="text"
                          className="form-control"
                          name="slug"
                          id="slug"
                          placeholder="Episode Slug"
                          value={editUser?.slug}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="m-1">Episode Image Cover</label>
                      <p className="p1">
                        Select the episodes image (9:16 ratio or 1080 X 1920px )
                      </p>

                      <div className="panel-body">
                        <img
                          src={editUser?.Thumbnail}
                          className="episodes-img"
                          width="150"
                        />
                      </div>
                      <div className="panel-body">
                        <input
                          type="file"
                          multiple="true"
                          className="form-control mt-2"
                          name="image"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="m-1">Episode Player Image</label>
                      <p className="p1">
                        Select the player image ( 16:9 Ratio or 1280X720px)
                      </p>

                      <div className="panel-body">
                        <img
                          src={editUser?.Player_thumbnail}
                          className="episodes-img"
                          width="150"
                        />
                      </div>
                      <div className="panel-body">
                        <input
                          type="file"
                          multiple="true"
                          className="form-control mt-2"
                          name="player_image"
                          id="player_image"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="m-0">Episode TV Image</label>
                      <p className="p1">
                        Select the player image ( 16:9 Ratio or 1920 X 1080 px)
                      </p>

                      <div className="panel-body">
                        <img
                          src={editUser?.TV_Thumbnail}
                          className="episodes-img"
                          width="150"
                        />
                      </div>
                      <div className="panel-body">
                        <input
                          type="file"
                          multiple="true"
                          className="form-group mt-2"
                          name="tv_image"
                          id="tv_image"
                          onChange={(e) => setTv_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <label className="m-0"> Episode Description </label>
                      <p className="p1">
                        {" "}
                        Add a description of the Episode below:{" "}
                      </p>
                      <div className="panel-body">
                        <JoditEditor
                          // ref={editor}
                          value={editUser?.episode_description}
                          //   onChange={(newContent) => setContent(newContent)}
                          // onChange={handleInput}

                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="m-0">Episode Ratings</label>
                      <p className="p1">IMDb Ratings 10 out of 10</p>
                      <select
                        value={editUser.rating}
                        className="form-control"
                        aria-label="Default select example"
                        onChange={handleInput}
                      >
                        {/* <option value={editUser.rating}>{editUser.rating}</option> */}
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>

                    <div className="col-sm-6">
                      <label className="m-0">Search Tags</label>
                      <div className="panel-body">
                        <input
                          type="text"
                          name="search_tags"
                          className="form-control"
                          value={editUser?.search_tags}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-6">
                      {/* <label className="m-0">Episode Source</label>
                      <p className="p1">Episode Format</p>

                      <div className="panel-body">
                        <select
                          id="type"
                          name="type"
                          className="form-control"
                          onChange={handleInput}
                        >
                          <option value="embed">Episode File</option>
                          <option value="file">Episode File</option>
                          <option value="upload">Upload Episode</option>
                          <option value="aws_m3u8">AWS Upload Episode</option>
                        </select>
                        <hr />
                      </div> */}

                      <div className="panel-body">
                        <div className="new-episodes-file">
                          <label for="mp4_url">Mp4 File URL:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="mp4_url"
                            id="mp4_url"
                            value={editUser?.mp4_url}
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <label>
                        <h6>Age Restrict :</h6>
                      </label>
                      <select
                        className="form-control"
                        id="age_restrict"
                        name="age_restrict"
                      >
                        <option selected disabled="">
                          Choose Age
                        </option>
                      </select>
                    </div>
                    <div className="col-sm-6 episode_video">
                      {/* <video width="200" height="200" controls>
                        <source
                          src=""
                          type="video/mp4"
                          value={editUser.mp4_url}
                        />
                      </video> */}

                      <media-player
                        title={editUser?.title}
                        src={editUser?.mp4_url}
                        poster={editUser?.channel_image_url}
                        controls
                        width="300"
                        height="300"
                      >
                        <media-outlet className="episode_video"></media-outlet>
                      </media-player>
                    </div>
                  </div>

                  {/* <div className="panel-body col-sm-6 p-0">
                    <label>
                      <h6>Age Restrict :</h6>
                    </label>
                    <select
                      className="form-control"
                      id="age_restrict"
                      name="age_restrict"
                    >
                      <option selected disabled="">
                        Choose Age
                      </option>
                    </select>
                  </div> */}

                  <div className="row mt-3">
                    <div className="col-sm-4">
                      <label className="m-0">
                        Skip Intro Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_intro"
                          id="skip_intro"
                          value={editUser?.skip_intro}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="m-0">
                        Intro Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_start_time"
                          id="intro_start_time"
                          value={editUser?.intro_start_time}
                          onChange={handleInput}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <label className="m-0">
                        Intro End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="intro_end_time"
                          id="intro_end_time"
                          value={editUser?.intro_end_time}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-4">
                      <label className="m-0">
                        Skip Recap Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="skip_recap"
                          id="skip_recap"
                          value={editUser?.skip_recap}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="m-0">
                        Recap Start Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_start_time"
                          id="recap_start_time"
                          value={editUser?.recap_start_time}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="m-0">
                        Recap End Time <small>(Please Give In Seconds)</small>
                      </label>
                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="recap_end_time"
                          id="recap_end_time"
                          value={editUser?.recap_end_time}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-6">
                      <label className="m-0">Duration</label>
                      <p className="p1">
                        Enter the episode duration in (HH : MM : SS)
                      </p>

                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="duration"
                          id="duration"
                          value={editUser?.duration}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
                      <label className="m-0">Free content Duration</label>
                      <p className="p1">
                        Enter the episode duration in (HH : MM : SS){" "}
                      </p>

                      <div className="panel-body">
                        <input
                          className="form-control"
                          name="free_content_duration"
                          id="free_content_duration"
                          value={editUser?.free_content_duration}
                          onChange={handleInput}
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-6">
                      <label className="m-0">User Access</label>
                      <p className="p1">Who is allowed to view this episode?</p>

                      <div className="panel-body">
                        <Select
                          options={userAccesses}
                          id="access"
                          name="access"
                          value={editUser?.access}
                          onChange={(e) => handleAccessChange(e)}
                          className="abcd form-control"
                        />
                        {/* <select
                          value={editUser?.access}
                          id="access"
                          name="access"
                          className="form-control"
                          onChange={handleInput}
                        >
                          <option value={editUser.access}>{editUser.access}</option>
                          <option value="guest">Guest (everyone)</option>
                          <option value="registered">
                            Registered Users (free registration must be enabled)
                          </option>
                          <option value="subscriber">
                            Subscriber (only paid subscription users)
                          </option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start mt-4">
                    <div className="col-sm-6">
                      <label className="m-0">Choose Ads Position</label>

                      <div className="">
                        {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                        <Select
                          options={adsPositions}
                          className="form-control"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="m-0">Choose Advertisement</label>

                      <div className="">
                        <Select
                          options={secondSelectOptions}
                          className="form-control"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-6">
                      <label className="m-0">Status Settings</label>
                      <div className="panel-body">
                        <div className="col-lg-12 row">
                          <div className="col-lg-10 col-6">
                            {" "}
                            <label for="featured">
                              This is Episode Featured:
                            </label>
                          </div>
                          <div className="col-lg-2 col-2">
                            <label className="switch">
                              <input
                                name="featured"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.featured === 1 ? true : false
                                }
                                checked={
                                  editUser?.featured === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="featured"
                                onChange={handleInput}
                                value={editUser?.featured === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 row">
                          <div className="col-lg-10 col-6">
                            {" "}
                            <label className="m-0">
                              Is this episode Views:
                            </label>
                          </div>
                          <div className="col-lg-2 col-2">
                            <label className="switch">
                              <input
                                name="views"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.views === 1 ? true : false
                                }
                                checked={editUser?.views === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="views"
                                onChange={handleInput}
                                value={editUser?.views === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 row">
                          <div className="col-lg-10 col-6">
                            {" "}
                            <label className="m-0">
                              Is this episode Active:
                            </label>
                          </div>
                          <div className="col-lg-2 col-2">
                            <label className="switch">
                              <input
                                name="active"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.active === 1 ? true : false
                                }
                                checked={editUser?.active === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="active"
                                onChange={handleInput}
                                value={editUser?.active === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 row">
                          <div className="col-lg-10 col-6">
                            {" "}
                            <label className="m-0">
                              Is this episode display in Banner:
                            </label>
                          </div>
                          <div className="col-lg-2 col-2">
                            <label className="switch">
                              <input
                                name="banner"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.banner === 1 ? true : false
                                }
                                checked={editUser?.banner === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="banner"
                                onChange={handleInput}
                                value={editUser?.banner === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12 row">
                          <div className="col-lg-10 col-6">
                            {" "}
                            <label className="m-0">
                              Is this episode display in Footer:
                            </label>
                          </div>
                          <div className="col-lg-2 col-2">
                            <label className="switch">
                              <input
                                name="footer"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.footer === 1 ? true : false
                                }
                                checked={editUser?.footer === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="footer"
                                onChange={handleInput}
                                value={editUser?.footer === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-4 mt-3">
                      <div className="panel panel-primary" data-collapsed="0">
                        <div className="clear"></div>
                      </div>
                    </div>
                    <div className="col-sm-8 p-3">
                      <input
                        type="submit"
                        value="Update"
                        className="btn btn-primary pull-right"
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit_Episode_manage;
