// import React from 'react'
// import Header from '../../../components/Header/Header';
// import Sidebar from '../../../components/Sidebar/Sidebar';

// function Addnewvideo() {

//   return (

//     <>
//       <div className='row m-0 p-0'>
//         <div className='col-lg-2'>  </div>
//         <div className='col-lg-10'>
//
//           <section className="section container-fluid">
//           Addnewvideo
//           </section>
//         </div></div>
//     </>
//   );
// }

// export default Addnewvideo;

import { Link, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Creatable from "react-select/creatable";
import "./Addnewvideo.css";

export default function Addnewvideo() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [file, setFile] = useState("");
  const [video_id, setVideo_id] = useState("");

  const [video_duration, setVideo_duration] = useState("");
  const [video_title, setVideo_title] = useState("");

  // console.log(video_id)
  // console.log(video_duration)
  // console.log(video_title)

  const [video_type, setVideo_type] = useState("");
  const [m3u8_url, setM3u8_url] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [rating, setRating] = useState("");
  const [duration, setDuration] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [mobile_image, setMobile_image] = useState("");
  const [tablet_image, setTablet_image] = useState("");
  const [enable_video_title_image, setEnable_video_title_image] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");
  const [android_tv, setAndroid_tv] = useState("");
  const [video_gif, setVideo_gif] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [access, setAccess] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [enable, setEnable] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [disk, setDisk] = useState("");
  const [original_name, setOriginal_name] = useState("");
  const [path, setPath] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [age_restrict, setAge_restrict] = useState("");
  const [Recommendation, setRecommendation] = useState("");
  const [country, setCountry] = useState("");
  const [publish_status, setPublish_status] = useState("");
  const [publish_type, setPublish_type] = useState("");
  const [publish_time, setPublish_time] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [url, setUrl] = useState("");
  const [url_link, setUrl_link] = useState("");
  const [url_linktym, setUrl_linktym] = useState("");
  const [url_linksec, setUrl_linksec] = useState("");
  const [urlEnd_linksec, setUrlEnd_linksec] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailer_description, setTrailer_description] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [default_ads, setDefault_ads] = useState("");
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [pre_ads, setPre_ads] = useState("");
  const [mid_ads, setMid_ads] = useState("");
  const [post_ads, setPost_ads] = useState("");
  const [time_zone, setTime_zone] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [user_id, setUser_id] = useState("");
  const [language, setLanguage] = useState("");
  const [video_category_id, setVideo_category_id] = useState("");
  const [artist, setArtist] = useState("");
  const [ads_tag_url_id, setAds_tag_url_id] = useState("");

  const [Thumbnail, setThumbnail] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");
  const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");

  const [Block_Country, setBlock_Country] = useState([]);
  const [Available_country, setAvailable_country] = useState([]);
  const [user_access, setUser_access] = useState([]);

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [age_restrictdata, setAge_restrictdata] = useState([]);

  const [category, setCategory] = useState([]);

  const [message, setMessage] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [editUser, setEditUser] = useState({ video_title: "", duration: "" });
  // console.log(editUser)

  const handleInput = (e) => {
    // setGlobal_ppv({ ...global_ppv, [e.target.name]: e.target.value });
    // setBanner({ ...banner, [e.target.name]: e.target.value });
    // setFeatured({ ...featured, [e.target.name]: e.target.value });
    // setTitle({ ...title, [e.target.id]: e.target.value });

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 1,
      });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 0,
      });
    }
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  // const uploadFile = ({ target: { files } }) => {
  //   let data = new FormData();
  //   data.append("file", files[0]);

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;

  //       let percent = Math.floor((loaded * 100) / total);

  //       if (percent < 100) {
  //         setUploadPercentage(percent);
  //       }
  //     },
  //     cancelToken: new CancelToken(
  //       (cancel) => (cancelFileUpload.current = cancel)
  //     ),
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_Baseurl}/admin/video-upload-file`, data, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status == true) {
  //         var data = res.data;
  //         // var datas = res.data.data;
  //         var video_id = res.data.video_id;
  //         var video_title = res.data.video_title;
  //         var duration = res.data.duration;
  //         // console.log("data", datas)
  //         console.log("data", data);
  //         // console.log(Audio_id)
  //         setEditUser(data);
  //         setVideo_id(video_id);
  //         setVideo_duration(duration);
  //         setVideo_title(video_title);
  //         setUploadPercentage(100);
  //         alert("Added successfully  !");

  //         setTimeout(() => {
  //           setUploadPercentage(0);
  //         }, 1000);
  //       } else {
  //         setMessage("Some error Occured");
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);

  //       if (isCancel(err)) {
  //         alert(err.message);
  //       }
  //       setUploadPercentage(0);
  //     });
  // };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const uploadFilefile = ({ target: { mp3_url } }) => {
    let data = new FormData();
    data.append("mp3_url", mp3_url);

    // console.log("mp3_url", mp3_url);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentagefile(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/video-upload-file`, data, {
        headers: headers,
      })
      .then((res) => {
        // console.log(res);
        if (res.data.status == true) {
          var data = res.data;
          var video_id = res.data.video_id;
          // console.log(data)
          // console.log(Audio_id)
          setVideo_id(video_id);
          setUploadPercentagefile(100);

          setTimeout(() => {
            setUploadPercentagefile(0);
          }, 1000);
        } else {
          setMessage("Some error Occured");
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);

        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentagefile(0);
      });
  };

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function urlsupload() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("video_type", video_type);
    formData.append("m3u8_url", m3u8_url);
    formData.append("mp4_url", mp4_url);
    formData.append("embed_code", embed_code);
    // console.log('append data', video_type,)

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/videos-url`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          var result = response.data.video_id;
          // console.log('result', result);
          // console.log('result', result);
          setVideo_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const editorvideodes = useRef(null);
  const contentFieldChanagedvideodes = (data) => {
    setDescription({ ...description, content: data });
  };

  const editorlinkanddes = useRef(null);
  const contentFieldChanagedlinkanddes = (data) => {
    setDetails({ ...details, content: data });
  };

  const editortrailerdes = useRef(null);
  const contentFieldChanagedtrailerdes = (data) => {
    setTrailer_description({ ...trailer_description, content: data });
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChange = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-category-depends`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          // console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
    // const response = await fetch("https://api.flicknexs.com/admin/advertisement-category-depends",
    // { headers: headers },
    // {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ selectedValue: value })
    // });
    // const data = await response.json();
    // setApiResponse(data);

    //    console.log("adsdata", data)
  };

  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   // other form fields
  // });

  // const [formErrors, setFormErrors] = useState({});

  // const handleInputChangess = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Validate the form data
  //   const errors = {};
  //   if (formData.name.trim() === '') {
  //     errors.name = 'Name is required';
  //   }
  //   if (formData.email.trim() === '') {
  //     errors.email = 'Email is required';
  //   } else if (!isValidEmail(formData.email)) {
  //     errors.email = 'Invalid email address';
  //   }

  //   // Update the form errors state
  //   setFormErrors(errors);

  //   // If there are no errors, proceed with form submission logic
  //   if (Object.keys(errors).length === 0) {
  //     // Perform form submission or processing logic
  //     console.log('Form data:', formData);
  //     // ...
  //   }
  // };

  // const isValidEmail = (email) => {
  //   // Implement your email validation logic
  //   // Return true if the email is valid, false otherwise
  //   return /\S+@\S+\.\S+/.test(email);
  // };

  async function videoupload() {
    const editorvideodes = description;
    var editorvideodesdata = JSON.stringify(editorvideodes);
    var editorvideodessubmit = editorvideodesdata;

    const editorlinkanddes = details;
    var editorlinkanddesdata = JSON.stringify(editorlinkanddes);
    var editorlinkanddessubmit = editorlinkanddesdata;

    const editortrailerdes = details;
    var editortrailerdesdata = JSON.stringify(editortrailerdes);
    var editortrailerdessubmit = editortrailerdesdata;

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputglobal_ppv = global_ppv.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputenable_video_title_image =
      enable_video_title_image.enable_video_title_image;
    var dataenable_video_title_image = JSON.stringify(
      editInputenable_video_title_image
    );
    var enable_video_title_imagedata = dataenable_video_title_image;

    const formData = new FormData();

    formData.append("video_id", video_id);
    formData.append("title", editUser.video_title);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("active", aactivedata);
    formData.append("status", status);
    formData.append("draft", draft);
    formData.append("rating", rating);
    formData.append("duration", editUser.duration);
    formData.append("details", editorlinkanddessubmit);
    formData.append("description", editorvideodessubmit);
    formData.append("android_tv", android_tv);
    formData.append("video_gif", video_gif);
    formData.append("skip_intro", skip_intro);
    formData.append("related_videos", ThumbnailTitlerelated_videos);
    formData.append("block_country_id", ThumbnailTitleblock_country);
    formData.append("artist_id", ThumbnailTitleartist_name);
    formData.append("search_tags", search_tags);
    formData.append("access", access);
    formData.append("global_ppv", global_ppvdata);
    formData.append("ppv_price", ppv_price);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("enable", enable);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    formData.append("disk", disk);
    formData.append("original_name", original_name);
    formData.append("path", path);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    formData.append("views", views);
    formData.append("year", year);
    formData.append("age_restrict", age_restrict);
    formData.append("Recommendation", Recommendation);
    formData.append("country", country);
    formData.append("publish_status", publish_status);
    formData.append("publish_type", publish_type);
    formData.append("publish_time", publish_time);
    formData.append("skip_recap", skip_recap);
    formData.append("recap_start_time", recap_start_time);
    formData.append("recap_end_time", recap_end_time);
    formData.append("intro_start_time", intro_start_time);
    formData.append("intro_end_time", intro_end_time);
    formData.append("pdf_files", pdf_files);
    formData.append("reels_thumbnail", reels_thumbnail);
    formData.append("enable_video_title_image", enable_video_title_imagedata);
    formData.append("reelvideo", reelvideo);
    formData.append("url", url);
    formData.append("url_link", url_link);
    formData.append("url_linktym", url_linktym);
    formData.append("url_linksec", url_linksec);
    formData.append("urlEnd_linksec", urlEnd_linksec);
    formData.append("trailer_type", trailer_type);
    formData.append("trailer", trailer);
    formData.append("trailer_description", editortrailerdessubmit);
    formData.append("ads_status", ads_status);
    formData.append("default_ads", default_ads);
    formData.append("pre_ads_category", pre_ads_category);
    formData.append("mid_ads_category", mid_ads_category);
    formData.append("post_ads_category", post_ads_category);
    formData.append("pre_ads", pre_ads);
    formData.append("mid_ads", mid_ads);
    formData.append("post_ads", post_ads);
    formData.append("time_zone", time_zone);
    formData.append("home_genre", home_genre);
    formData.append("in_menu", in_menu);
    formData.append("footer", footer);
    formData.append("uploaded_by", uploaded_by);
    formData.append("user_id", user_id);
    formData.append("languages_id", ThumbnailTitle);
    formData.append("category_id", ThumbnailTitlecate);
    formData.append("ads_tag_url_id", ads_tag_url_id);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/video-store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;

          const formDatas = new FormData();

          formDatas.append("video_id", video_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("video_tv_image", video_tv_image);
          formDatas.append("video_title_image", video_title_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });

          const formDatass = new FormData();

          formDatass.append("video_id", video_id);
          formDatass.append("trailer", trailer);
          formDatass.append("trailer_type", trailer_type);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
              formDatass,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                // alert("Added successfully  !")
              }
            });

          const formD = new FormData();

          formD.append("video_id", video_id);
          formD.append("trailer", trailer);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
              formD,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });

          const formDa = new FormData();

          formDa.append("video_id", video_id);
          formDa.append("pdf_file", pdf_files);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Epaper-video`,
              formDa,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [options, setOptions] = useState([""]);
  const [publishdata, setPublish_timedata] = useState([]);
  const [ThumbnailTitle, setThumbnailTitle] = useState([]);

  const [optionscate, setOptionscate] = useState([""]);
  const [publishdatacate, setPublish_timedatacate] = useState([]);
  const [ThumbnailTitlecate, setThumbnailTitlecate] = useState([]);

  const [optionsartist_name, setOptionsartist_name] = useState([""]);
  const [publishdataartist_name, setPublish_timedataartist_name] = useState([]);
  const [ThumbnailTitleartist_name, setThumbnailTitleartist_name] = useState(
    []
  );

  const [optionsrelated_videos, setOptionsrelated_videos] = useState([""]);
  const [publishdatarelated_videos, setPublish_timedatarelated_videos] =
    useState([]);
  const [ThumbnailTitlerelated_videos, setThumbnailTitlerelated_videos] =
    useState([]);

  const [optionsblock_country, setOptionsblock_country] = useState([""]);
  const [publishdatablock_country, setPublish_timedatablock_country] = useState(
    []
  );
  const [ThumbnailTitleblock_country, setThumbnailTitleblock_country] =
    useState([]);
  // console.log("publish", publishdata)
  // console.log("ThumbnailTitle", ThumbnailTitle)

  const handlevalue = (e) => {
    setPublish_timedata(e);
    {
      publishdata.map((item) =>
        // setThumbnailTitle(item.value + ""),
        setThumbnailTitle((arr) => [...arr, item.value])
      );
    }
  };

  const handlevaluecate = (e) => {
    setPublish_timedatacate(e);
    {
      publishdatacate.map((item) =>
        // setThumbnailTitle(item.value + ""),
        setThumbnailTitlecate((arr) => [...arr, item.value])
      );
    }
  };

  const handlevalueartist_name = (e) => {
    setPublish_timedataartist_name(e);
    {
      publishdataartist_name.map((item) =>
        // setThumbnailTitle(item.value + ""),
        setThumbnailTitleartist_name((arr) => [...arr, item.value])
      );
    }
  };

  const handlevaluerelated_videos = (e) => {
    setPublish_timedatarelated_videos(e);
    {
      publishdatarelated_videos.map((item) =>
        // setThumbnailTitle(item.value + ""),
        setThumbnailTitlerelated_videos((arr) => [...arr, item.value])
      );
    }
  };

  const handlevalueblock_country = (e) => {
    setPublish_timedatablock_country(e);
    {
      publishdatablock_country.map((item) =>
        // setThumbnailTitle(item.value + ""),
        setThumbnailTitleblock_country((arr) => [...arr, item.value])
      );
    }
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data.ads_position.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    const getData = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_language;
          // console.log(result)
          result.map((user) => {
            return arr.push({ value: user.id, label: user.name });
          });
          setOptions(arr);
        });
    };
    getData();

    const getDatacate = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_category;
          // console.log(result)
          // console.log(result)
          // console.log(result)
          result.map((user) => {
            return arr.push({ value: user.id, label: user.name });
          });
          setOptionscate(arr);
        });
    };
    getDatacate();

    const getDatartist_name = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.video_artist;
          // console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.artist_name });
          });
          setOptionsartist_name(arr);
        });
    };

    getDatartist_name();

    const getDatarelated_videos = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.Related_videos;
          // console.log(result);
          // console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.title });
          });
          setOptionsrelated_videos(arr);
          // console.log(arr);
        });
    };
    getDatarelated_videos();

    const getDatablock_country = async () => {
      const arr = [];
      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.Block_Country;
          // console.log(result);
          result.map((user) => {
            return arr.push({ value: user.id, label: user.country_name });
          });
          setOptionsblock_country(arr);
        });
    };
    getDatablock_country();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var age_restrictdata = response.data.Age_Restrict;
        var ads_category = response.data.ads_category;
        var Block_Country = response.data.Block_Country;
        var Available_country = response.data.Available_country;
        var user_access = response.data.user_access;
        setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(ads_category)
        setUser_access(user_access);
        setBlock_Country(Block_Country);
        setAvailable_country(Available_country);
      })
      .catch((error) => console.log(error));

    //  axios.get('http://api.flicknexs.com/admin/videos-create', { headers: headers }).then(response => {
    //   var ads_category = response.data.ads_category
    //   // setAllvideos(result)
    //   console.log(ads_category)
    //   console.log(ads_category)
    // }).catch(error =>
    //   console.log(error));

    // const getlanguagedata = async () => {

    //   const getlanguagename = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_language
    //   // console.log(data);
    //   // console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getlanguagename.push(data[i].name);
    //   }
    //   setLanguage(getlanguagename);
    // }
    // getlanguagedata();

    // const getartistdata = async () => {

    //   const getartistname = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_artist
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getartistname.push(data[i].artist_name);
    //   }
    //   setArtist(getartistname);
    //   // console.log(getartistname);
    // }
    // getartistdata();

    // const getvideo_categorydata = async () => {

    //   const getvideo_categoryname = [];
    //   const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

    //   const resData = await reqData.json();
    //   var data = resData.video_category
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     getvideo_categoryname.push(data[i].artist_name);
    //   }
    //   setArtist(getvideo_categoryname);
    //   // console.log(getvideo_categoryname);
    // }
    // getvideo_categorydata();

    const globel = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const globelset = data?.ppv_gobal_price;
          setInputValueAPI(globelset);

          // console.log(data.user_access, "user");
          const userAcces = data?.user_access.map((userAcc) => ({
            value: userAcc.role,
            label: userAcc.name,
          }));

          const ppv_price = data?.InappPurchase.map((userAcc) => ({
            value: userAcc.id,
            label: userAcc.plan_price,
          }));
          setIos_ppv_price(ppv_price);
          setUserAccesses(userAcces);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching user accesses:", error);
      }
    };
    globel();
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  // useEffect(async () => {

  //   const getlanguagename = [];

  //   await axios.get("https://api.flicknexs.com/admin/videos-create", { headers: headers }
  //   ).then(response => {
  //     // console.log('api checks', response.data.livestream_language)
  //     var result = response.data.video_language
  //     var Block_Country = response.data.Block_Country
  //     var Available_country = response.data.Available_country
  //     var user_access = response.data.user_access
  //     setOrder2(result)
  //     setUser_access(user_access)
  //     setBlock_Country(Block_Country)
  //     setAvailable_country(Available_country)

  //   })
  //     .catch(error =>
  //       console.log(error));

  //   const getlanguagedata = async () => {

  //     const getlanguagename = [];
  //     const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

  //     const resData = await reqData.json();
  //     var data = resData.video_language
  //     // console.log(data);
  //     // console.log(data);
  //     for (let i = 0; i < data.length; i++) {
  //       getlanguagename.push(data[i].name);
  //     }
  //     setLanguage(getlanguagename);
  //   }
  //   getlanguagedata();

  //   const getartistdata = async () => {

  //     const getartistname = [];
  //     const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

  //     const resData = await reqData.json();
  //     var data = resData.video_artist
  //     console.log(data);
  //     for (let i = 0; i < data.length; i++) {
  //       getartistname.push(data[i].artist_name);
  //     }
  //     setArtist(getartistname);
  //     console.log(getartistname);
  //   }
  //   getartistdata();

  //   const getvideo_categorydata = async () => {

  //     const getvideo_categoryname = [];
  //     const reqData = await fetch("https://api.flicknexs.com/admin/videos-create", { headers: headers });

  //     const resData = await reqData.json();
  //     var data = resData.video_category
  //     console.log(data);
  //     for (let i = 0; i < data.length; i++) {
  //       getvideo_categoryname.push(data[i].artist_name);
  //     }
  //     setArtist(getvideo_categoryname);
  //     console.log(getvideo_categoryname);
  //   }
  //   getvideo_categorydata();

  // }, [])

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-5 mb-5 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />
                  {/* <div>File Size: {getFileSizeInMB()} MB</div>
                  <div>Upload Progress: {uploadProgress}%</div> */}

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>

                {/* <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control"
                      onChange={uploadFile}
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                      name="addvideo"
                      onClick={(event) => {
                        setEnable_restreamaddvideo(event.target.name);
                      }}
                    />
                  </p>
                </div> */}
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>

              {/* <input className="form-check-input ms-5" type="radio" name="addvideo" id="addvideo"
                value='1' onClick={(event) => { setEnable_restreamaddvideo(event.target.name); }} />
              <label className="form-check-label ms-1" for="flexRadioDefault1">
                Video Upload
              </label> */}

              {/* <div className='mb-5'>
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}

            </div> */}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    onChange={(e) => setM3u8_url(e.target.value)}
                    placeholder="m3u8 Trailer"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                  Submit </button>
              </div> */}
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                    onChange={(e) => setMp4_url(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                  Submit </button>
              </div> */}
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                    onChange={(e) => setEmbed_code(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                  handleNext();
                }}
                className="btn btn-primary"
              >
                Proceed to Next
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNext} className="btn btn-primary">
                Proceed to Next{" "}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-6">
            <label className="mb-1"> Upload Trailer : </label>
            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => setTrailer(e.target.files[0])}
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setTrailer(e.target.value)}
                    placeholder="m3u8 URL"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp4_trailer"
                    placeholder="mp4 Trailer"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
      <input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
      <input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

            <div className="row d-flex mt-3">
              <div className="col-md-8 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="1"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault1"
                >
                  Video Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="m3u8_url"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault2"
                >
                  m3u8 Url
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="mp4_url"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Video mp4
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="embed"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Embed Code
                </label>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="mt-3">
          <h4>Video Information:</h4>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-sm-6 form-group">
                <label className="m-0">Title :</label>
                {/* <input type="text" className="form-control" name="video_title" id="video_title" placeholder="Title"
                // onChange={e => setTitle(e.target.value)} 
                value={editUser.video_title} onChange={handleInput}/> */}

                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Title"
                  name="video_title"
                  id="video_title"
                  value={editUser.video_title}
                  onChange={handleInput}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Video Slug
                  <Link
                    className=""
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Please enter the URL Slug"
                    data-original-title="this is the tooltip"
                    to="#"
                  >
                    <i className="las la-exclamation-circle"></i>
                  </Link>
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  id="slug"
                  placeholder="Video Slug"
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Age Restrict :</label>
                <select
                  className="form-control"
                  id="age_restrict"
                  name="age_restrict"
                >
                  {age_restrictdata.map((item) => (
                    <option selected value={item.id}>
                      {item.slug}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 form-group ">
                <label className="m-0">Rating:</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <label className="m-0">Video Description:</label>
                {/* <textarea rows="5" className="form-control mt-2" name="description" id="summary-ckeditor"
                placeholder="Description" onChange={e => setDescription(e.target.value)}></textareLink> */}
                <JoditEditor
                  ref={editorvideodes}
                  value={description.content}
                  onChange={(newContent) =>
                    contentFieldChanagedvideodes(newContent)
                  }
                />
              </div>
              <div className="col-12 form-group">
                <label className="m-0">Links &amp; Details:</label>
                {/* <textarea rows="5" className="form-control mt-2" name="details" id="links-ckeditor"
                placeholder="Link , and details" onChange={e => setDetails(e.target.value)} ></textareLink> */}
                <JoditEditor
                  ref={editorlinkanddes}
                  value={details.content}
                  onChange={(newContent) =>
                    contentFieldChanagedlinkanddes(newContent)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Intro Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_intro"
                  name="skip_intro"
                  onChange={(e) => setSkip_intro(e.target.value)}
                />
                {/* <span><p id="error_skip_intro_time">* Fill Skip Intro Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_start_time"
                  name="intro_start_time"
                  onChange={(e) => setIntro_start_time(e.target.value)}
                />
                {/* <span><p id="error_intro_start_time" >* Fill Intro Start Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_end_time"
                  name="intro_end_time"
                  onChange={(e) => setIntro_end_time(e.target.value)}
                />
                {/* <span><p id="error_intro_end_time" >* Fill Intro End Time </p></span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Recap <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_recap"
                  name="skip_recap"
                  onChange={(e) => setSkip_recap(e.target.value)}
                />
                {/* <span><p id="error_skip_recap_time" >* Fill Skip Recap Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_start_time"
                  name="recap_start_time"
                  onChange={(e) => setRecap_start_time(e.target.value)}
                />
                {/* <span><p id="error_recap_start_time"  >* Fill Recap Start Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={(e) => setRecap_end_time(e.target.value)}
                />
                {/* <span><p id="error_recap_end_time"  >* Fill Recap End Time </p></span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Video Duration:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Duration"
                  name="duration"
                  id="duration"
                  value={editUser.duration}
                  onChange={handleInput}
                />
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">Year:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Release Year"
                  name="year"
                  id="year"
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="mb-2">Publish Type</label>
                <div>
                  <input
                    type="radio"
                    id="publish_now"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                    value="publish_now"
                    checked="checked"
                  />{" "}
                  <span>Publish Now</span>
                </div>
                <div>
                  <input
                    type="radio"
                    id="publish_later"
                    value="publish_later"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                  />{" "}
                  <span
                    value="publish_later"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                  >
                    Publish Later
                  </span>{" "}
                </div>
              </div>

              <div className="col-sm-6 form-group" id="publishlater">
                <div
                  onClick={(event) => {
                    setEnablestreamvisiblepublic(event.target.value);
                  }}
                >
                  {proceednextpublic()}
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <div className="container-fluid">
          <div className="form-card mt-3">

          {/* <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChangess}
        />
        {formErrors.name && <span className="error">{formErrors.name}</span>}
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChangess}
        />
        {formErrors.email && <span className="error">{formErrors.email}</span>}
      </div>
      <button type="submit"  onClick={(e) => {  handleSubmit(e); handleNext(e);}}>Submit</button>
    </form> */}


            <div className="row">
              <div className="col-sm-6 form-group formcreatable">
                <label className="m-0">Select Video Category :</label>
                {/* <select className="form-control js-example-basic-multiple" id="video_category_id" name="video_category_id[]" multiple="multiple">

                <option value=""></option>

              </select> */}

                <Creatable
                  placeholder="Select an individual"
                  options={optionscate}
                  isMulti
                  className=""
                  onChange={(e) => handlevaluecate(e)}
                  noOptionsMessage={() => "name not found"}
                ></Creatable>

                <span className="image_error_msg">
                  * Choose The Video Category
                </span>

                {/* <div className="text-dark">
                    <Multiselect
                      isObject={false}
                      onRemove={(event) => { console.log(event) }}
                      onSelect={(event) => { console.log(event) }}
                      options={video_category_id}
                      value={(event) => { console.log(event) }}
                      onChange={e => setVideo_category_id(e.target.value)}
                      showCheckbox
                    />
                  </div> */}

                {/* <span><p id="error_video_Category"  >* Choose the Video Category </p></span> */}
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                    <div className="panel-options">
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="panel-body formcreatable">
                    {/* <select name="artists[]" className="js-example-basic-multiple form-control" multiple="multiple">

                    <option value="" selected="true"></option>

                  </select> */}

                    <Creatable
                      placeholder="Select an individual"
                      options={optionsartist_name}
                      isMulti
                      onChange={(e) => handlevalueartist_name(e)}
                      noOptionsMessage={() => "name not found"}
                    ></Creatable>

                    {/* <div className="text-dark">
                    <Multiselect
                      isObject={false}
                      onRemove={(event) => { console.log(event) }}
                      onSelect={(event) => { console.log(event) }}
                      options={artist}
                      value={(event) => { console.log(event) }}
                      onChange={e => setArtist(e.target.value)}
                      showCheckbox
                    />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group formcreatable">
                <label className="m-0">Choose Language:</label>
                {/* <select className="form-control js-example-basic-multiple" id="language" name="language[]" multiple="multiple">

                <option value="" ></option>

              </select> */}

                {/* <Multiselect
                  isObject={false} 
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={language} name="video_category_id"
                  onChange={e => setLanguage(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}

                {/* onChange={(e) => console.log(`e`, e)} */}

                <Creatable
                  placeholder="Select an individual"
                  options={options}
                  isMulti
                  onChange={(e) => handlevalue(e)}
                  noOptionsMessage={() => "name not found"}
                ></Creatable>

                <span className="image_error_msg">* Choose The Language</span>

                {/* <div className="text-dark">
                <Multiselect
                  isObject={false}
                  onRemove={(event) => { console.log(event) }}
                  onSelect={(event) => { console.log(event) }}
                  value={(event) => { console.log(event) }}
                  options={language}
                  onChange={e => setLanguage(e.target.value)}
                  showCheckbox
                />
              </div> */}

                {/* <span><p id="error_language"  >* Choose the Language </p></span> */}
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_videos[]"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  onChange={(e) => setUrl(e.target.value)}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  onChange={(e) => setUrl_linktym(e.target.value)}
                  id="url_linktym"
                />
              </div>
            </div>
            {/* <div className="row mt-5">
            <div className="panel panel-primary" data-collapsed="0">
              <div className="panel-heading col-sm-12">
                <div className="panel-title" > <label className="m-0">Subtitles (srt or txt) :</label>
                </div>
                <div className="panel-options">
                  <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link>
                </div>
              </div>
              <div className="panel-body row" >

                <div className="col-sm-6 form-group" >
                  <div className="align-items-center" >
                    <label for="embed_code"  >Upload Subtitle English </label>
                    <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                    <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                  </div>
                </div>
                <div className="col-sm-6 form-group" >
                  <div className="align-items-center" >
                    <label for="embed_code"  >Upload Subtitle German </label>
                    <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                    <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                  </div>
                </div>
                <div className="col-sm-6 form-group" >
                  <div className="align-items-center" >
                    <label for="embed_code"  >Upload Subtitle Spanish </label>
                    <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                    <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                  </div>
                </div>
                <div className="col-sm-6 form-group" >
                  <div className="align-items-center" >
                    <label for="embed_code"  >Upload Subtitle Hindi </label>
                    <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                    <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                  </div>
                </div>

              </div>
            </div>
          </div> */}
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {  handleNext();}}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <div className="form-card mt-3">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="fs-title">Geo-location for Videos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group formcreatable">
              <label className="m-0">Block Country </label>
              <p className="p1">
                ( Choose the countries for block the videos )
              </p>
              {/* <select name="country[]" className="js-example-basic-multiple form-control" multiple="multiple">

            </select> */}

              <Creatable
                placeholder="Select an individual"
                options={optionsblock_country}
                isMulti
                className=""
                onChange={(e) => handlevalueblock_country(e)}
                noOptionsMessage={() => "name not found"}
              ></Creatable>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0"> Country </label>
              <p className="p1">( Choose the countries videos )</p>
              <select
                name="video_country"
                className="form-control"
                id="country"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="All">Select Country </option>
                {Available_country.map((item, key) => (
                  <option value={item.country_name}>{item.country_name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="row" id="ppv_price" >
          <div className="col-sm-6 form-group" >
            <label className="m-0">PPV Price:</label>
            <input type="text" className="form-control" placeholder="PPV Price" name="ppv_price" id="price"
              onChange={e => setPpv_price(e.target.value)} />
          </div>

          <div className="col-sm-6 form-group" >
            <label className="m-0">IOS PPV Price:</label>
            <select name="ios_ppv_price" className="form-control" id="ios_ppv_price" onChange={e => setIos_ppv_price(e.target.value)} >
              <option value="" >Select IOS PPV Price: </option>

            </select>
          </div>
        </div> */}

          <div className="row align-items-center">
            <div className="col-sm-6 form-group">
              <label for="">Search Tags </label>
              <input
                type="text"
                id="exist-values"
                className="tagged form-control"
                data-removeBtn="true"
                name="searchtags"
                onChange={(e) => setSearch_tags(e.target.value)}
              />
            </div>

            <div className="col-sm-6 form-group formcreatable">
              <label className="m-0">Related Videos:</label>
              {/* <select name="related_videos[]" className="form-control js-example-basic-multiple" multiple="multiple" >
              <option value=""  > </option>

            </select> */}

              <Creatable
                placeholder="Select an individual"
                options={optionsrelated_videos}
                isMulti
                className=""
                onChange={(e) => handlevaluerelated_videos(e)}
                noOptionsMessage={() => "name not found"}
              ></Creatable>
            </div>

            <div className="col-sm-6">
              <label className="m-0">User Access</label>
              <p className="p1">Who Is Allowed To View This Live Stream ?</p>
              <Select
                options={userAccesses}
                onChange={(e) => handleAccessChange(e)}
                className="abcd"
              />
            </div>
          </div>
          {userAccessesValue?.value === "ppv" ? (
            <>
              <div class="row mt-3 text-start ">
                <div class="col-sm-4">
                  <label class="m-0">PPV Price</label>
                  <p class="p1">
                    Apply PPV Price from Global Settings?
                    <input
                      type="checkbox"
                      name="ppv_gobal_price"
                      id="ppv_gobal_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    />
                  </p>

                  <div class="panel-body">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    <div class="clear"></div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="m-0"> IOS PPV Price</label>
                  <p class="p1">Apply IOS PPV Price from Global Settings?</p>
                  <div class="panel-body ppv_price_ios">
                    <Select
                      name="ios_ppv_price"
                      className="form-control"
                      options={ios_ppv_price}
                      onChange={(e) => setIos_ppv_price(e.target.value)}
                    />

                    {/* <select
                                    name="ios_ppv_price"
                                    class="form-control"
                                    id="ios_ppv_price"
                                  >
                                    <option value="">
                                      Select IOS PPV Price:{" "}
                                    </option>
                                    <option value="ppv2"> 349</option>
                                    <option value="multiverse"> 179</option>
                                  </select> */}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="row">
            <div className="col-sm-6 form-group mt-3" id="ppv_price">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  <label for="global_ppv">Is this video Is Global PPV:</label>
                </div>
                <div className="col-lg-2 col-3">
                  <label className="switch">
                    <input
                      name="global_ppv"
                      onChange={handleInput}
                      defaultChecked={global_ppv.global_ppv == 1 ? true : false}
                      checked={global_ppv.global_ppv == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="global_ppv"
                      onChange={handleInput}
                      value={global_ppv.global_ppv == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>
                      <h3 className="fs-title">Status Settings</h3>
                    </label>
                  </div>
                  <div className="panel-options">
                    <Link to="#" data-rel="collapse">
                      <i className="entypo-down-open"></i>
                    </Link>
                  </div>
                </div>

                <div className="panel-body">
                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="featured">
                        Enable this video as Featured:
                      </label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          defaultChecked={featured.featured == 1 ? true : false}
                          checked={featured.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={featured.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="active">Enable this Video:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active.active == 1 ? true : false}
                          checked={active.active == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="banner">Enable this Video as Slider:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          defaultChecked={banner.banner == 1 ? true : false}
                          checked={banner.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <div className="form-card mt-3">
          <div className="row">
            <div className="col-7">
              <h3 className="fs-title">Image Upload:</h3>
            </div>
            <div className="col-5"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1080 x 1920 pixels dimension or
                  ratio 9:16{" "}
                </p>
              </span>
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="player_image"
                id="player_image"
                onChange={(e) => setPlayer_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1280 x 720 pixels dimension or
                  ratio 16:9{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video TV Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_tv_image"
                id="video_tv_image"
                onChange={(e) => setVideo_tv_image(e.target.files[0])}
              />
              <span>
                <p className="image_error_msg">
                  * Please upload an image with 1920 x 1080 pixels dimension or
                  16:9 ratio{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video Title Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_title_image"
                id="video_title_image"
                onChange={(e) => setVideo_Title_Thumbnail(e.target.files[0])}
              />
            </div>

            <div className="col-sm-6 form-group">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  {" "}
                  <label className="mb-1">Enable Video Title Thumbnail</label>
                </div>
                <div className="col-lg-2 col-2">
                  <label className="switch">
                    <input
                      name="enable_video_title_image"
                      onChange={handleInput}
                      defaultChecked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      checked={
                        enable_video_title_image.enable_video_title_image == 1
                          ? true
                          : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_video_title_image"
                      onChange={handleInput}
                      value={
                        enable_video_title_image.enable_video_title_image == 1
                          ? "1"
                          : "0"
                      }
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-7">
              <h5 className="fs-title">Trailer Upload:</h5>
            </div>

            <div className="col-sm-6">
              <label className="m-0">Video Trailer Type:</label>
              <select
                className="trailer_type form-control"
                name="trailer_type"
                id="trailer_type"
                onChange={(e) => setTrailer_type(e.target.value)}
                onClick={(event) => {
                  setEnable_restreamtrailer(event.target.value);
                }}
              >
                <option value="null"> Select the Video Trailer Type </option>
                <option value="1"> Video Upload </option>
                <option value="m3u8_url">m3u8 Url </option>
                <option value="mp4_url">mp4 Url</option>
                <option value="embed_url">Embed Code</option>
              </select>
            </div>
          </div>

          <div>
            <div
              onClick={(event) => {
                setEnablestreamvisibletrailer(event.target.value);
              }}
            >
              {proceednexttrailer()}
            </div>
          </div>

          {/* <div className="row trailer_m3u8_url">
          <div className="col-sm-6 form-group" >
            <label className="m-0"> Trailer m3u8 Url :</label>
            <input type="text" className="form-control" name="m3u8_trailer" id="" value="" />
          </div>
        </div>

        <div className="row trailer_mp4_url">
          <div className="col-sm-6 form-group" >
            <label className="m-0"> Trailer mp4 Url :</label>
            <input type="text" className="form-control" name="mp4_trailer" id="" value="" />
          </div>
        </div>

        <div className="row trailer_embed_url">
          <div className="col-sm-6 form-group" >
            <label className="m-0">Trailer Embed Code :</label>
            <input type="text" className="form-control" name="embed_trailer" id="" value="" />
          </div>
        </div> */}

          {/* <div className="row trailer_video_upload">
          <div className="col-sm-8 form-group">
            <label className="m-0">Upload Trailer :</label><br />
            <div className="new-video-file form_video-upload" >
              <input type="file" accept="video/mp4,video/x-m4v,video/*" name="trailer" id="trailer" />
              <p >Drop and drag the video file</p>
            </div>
            <span id="remove" className="danger">Remove</span>
          </div>

          <div className="col-sm-4 form-group">

            <video width="200" height="200" controls>

            </video>

          </div>
        </div> */}

          <div className="row">
            <div className="col-sm-8  form-group">
              <label className="m-0">Trailer Description:</label>
              {/* <textarea rows="5" className="form-control mt-2" name="trailer_description" id="trailer-ckeditor"
              onChange={e => setTrailer_description(e.target.value)} placeholder="Trailer Description">
            </textareLink> */}

              <JoditEditor
                ref={editortrailerdes}
                value={trailer.content}
                onChange={(newContent) =>
                  contentFieldChanagedtrailerdes(newContent)
                }
              />
            </div>
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <div className="form-card mt-3">
          <div className="row p-0 m-0">
            <div className="col-7">
              <h4 className="fs-title">ADS Management:</h4>
            </div>

                       <div className="row text-start mt-4">
              <div className="col-sm-6">
                <label className="m-0">Choose Ads Position</label>

                <div className="">
                  {/* <AddLiveStreamPosition
                                options={adsPositions}
                                onChange={handleAdsPositionChange}
                              /> */}

                  <Select
                    options={adsPositions}
                    onChange={(e) => handleAdsPositionChange(e)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="m-0">Choose Advertisement</label>

                <div className="">
                  <Select
                    options={secondSelectOptions}
                    onChange={(e) => setSecondSelectOptions(e)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row col-lg-12">
              <div className="col-lg-6 col-sm-3 form-group mt-1">
                <label className="">Choose Ads Position</label>
                <select
                  className="form-control"
                  name="mid_ads_category"
                  id="mid_ads_category"
                >
                  <option value="item.id">tem.name</option>
                </select>
              </div>
              <div className="col-lg-6 col-sm-3 form-group mt-3">
                <label className="">Choose Advertisement</label>
                <select
                  className="form-control"
                  name="mid_ads_category"
                  id="mid_ads_category"
                >
                  <option value="item.id">tem.name</option>
                </select>
              </div>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
              <label className="">
                Choose Pre-Ad <br /> Category
              </label>
              <select
                className="form-control"
                name="pre_ads_category"
                id="pre_ads_category"
                value={selectedValue}
                onChange={handleSelectChange}
              > */}
            {/* {pre_ads_category.map((item, key) =>
              <option value=" ">Select Pre-Ad Category</option>
              )} */}
            {/* {pre_ads_category.map((item, key) =>
                <option value={item.id}>{item.name}</option>
              )} */}
            {/* </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
              <label className="">
                Choose Mid-Ad <br /> Category
              </label>
              <select
                className="form-control"
                name="mid_ads_category"
                id="mid_ads_category"
              > */}
            {/* {mid_ads_category.map((item, key) =>
                <option value={item.id}>{item.name}</option>
              )} */}
            {/* </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
              <label className="">
                Choose Post-Ad <br /> Category
              </label>
              <select
                className="form-control"
                name="post_ads_category"
                id="post_ads_category"
              > */}
            {/* {post_ads_category.map((item, key) =>
                <option value={item.id}>{item.name}</option>
              )} */}
            {/* </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
              <label className="">Choose Tag-url Ads Position</label>
              <select
                className="form-control"
                name="tag_url_ads_position"
                id="tag_url_ads_position"
              > */}
            {/* {post_ads_category.map((item, key) =>
                <option value={item.id}>{item.name}</option>
              )} */}
            {/* </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="pre_ads_div">
              <label className="">Choose Pre-Ad </label>
              <select className="form-control" name="pre_ads" id="pre_ads">
                <option value=" ">Please Choose Above Cataegory </option>
              </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="mid_ads_div">
              <label className="">Choose Mid-Ad </label>
              <select className="form-control" name="mid_ads" id="mid_ads">
                <option value=" ">Please Choose Above Cataegory </option>
              </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="post_ads_div">
              <label className="">Choose Post-Ad </label>
              <select className="form-control" name="post_ads" id="post_ads">
                <option value=" ">Please Choose Above Cataegory</option>
              </select>
            </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="ads_tag_url_id_div">
              <label className="">Choose Tag-url Ads </label>
              <select
                className="form-control"
                name="ads_tag_url_id"
                id="ads_tag_url_id"
              >
                <option value=" ">Please Choose Above Cataegory </option>
              </select>
            </div>

            <div className="col-sm-4 form-group mt-3" id="post_ads_div">
              <label className="">Choose Post-Ad </label>
              <input onChange={(e) => setAds_tag_url_id(e.target.value)} />
            </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
            <label className="">Choose Ad Name</label>
            <select className="form-control" name="ads_id">
              <option value="0">Select Ads</option>

            </select>
          </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
            <label className="">Default Ads</label>
            <label className="switch">
              <input name="default_ads" type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
            <label className="">Choose Ad Roll</label>
            <select className="form-control" name="ad_roll">
              <option value="0">Select Ad Roll</option>
              <option value="1">Pre</option>
              <option value="2">Mid</option>
              <option value="3">Post</option>
            </select>
          </div> */}
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Submit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addvideo">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add Video</h4>
                    </div>
                  </div>
                  <hr />

                  <Box>
                    <Stepper activeStep={activeStep} nonLinear>
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 5 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            {/* <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-primary">
                                  {index === steps.length - 1 ? 'Upload Video' : 'Next'}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-dark">
                                  Back
                                </Button>
                              </div>
                            </Box> */}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
