import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

function UpdateCurrencySettings() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    country: "",
    symbol: "",
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.CurrencySetting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)

      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
          headers: headers,
        })
        .then((response) => {
          var result = response?.data?.allCurrency;
          setData(result);
          // console.log(result)
        })
        .catch((error) => console.log(error));
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      country: editUser.country,
      symbol: editUser.symbol,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/currency_setting/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        navigate("/admin/currency_settings");
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  // const handleUpdate = () => {
  // 	console.log(editUser);

  // 	const headers = {
  // 		"Content-Type": "application/json",
  // 		Authorization: "Bearer " + access_token,
  // 	  };

  // 	axios
  // 	  .post('https://api.flicknexs.com/admin/currency_setting/store',{country: editUser.country},{ headers: headers },)
  // 	  .then(res => {
  // 		console.log(res)
  // 		navigate('/admin/currency_settings');
  // 	  })
  // 	  .catch(err => {
  // 		console.log('Error');
  // 	  });
  //   };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i>Edit Currency Setting{" "}
                      </h4>
                    </div>
                    <hr />
                  </div>

                  <div
                    className="panel p-3 panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-heading">
                      <div className="panel-title">
                        <p className="p1">Country: </p>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div id="nestable" className="nested-list with-margins">
                        <select
                          className="form-select"
                          name="country"
                          value="country"
                          aria-label="Default select example"
                          onChange={handleInput}
                        >
                          <option
                            selected
                            value={editUser?.country - editUser?.symbol}
                          >
                            {editUser?.symbol} - {editUser?.country}
                          </option>
                          {data?.map((item) => (
                            <option name={item?.country} value={item?.country}>
                              {" "}
                              {item?.symbol} - {item?.country}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* .then(res => {}); */}
                      {/* <div>
												<input name="country" value={editUser.country} onChange={handleInput}>{editUser.country}</input>
											</div> */}
                    </div>
                  </div>
                  <div className="text-end p-3">
                    <button className="btn btn-primary " onClick={handleUpdate}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UpdateCurrencySettings;
