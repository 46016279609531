import React, { useState, useEffect } from "react";
import axios from "axios";

function ProfileScreen() {
  const [data, setData] = useState([]);
  const [profile_name, setProfile_name] = useState("");
  const [image, setImage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/index`,
        { headers: headers }
      )
      .then((res) => {
        setData(res?.data?.ChooseProfileScene_Setting);
        setProfile_name(res?.data?.ChooseProfileScene_Setting[0]?.profile_name);
        setImage(res?.data?.ChooseProfileScene_Setting[0]?.image);
      });
  }, []);

  function editProduct() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    console.log(profile_name, image);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("profile_name", profile_name);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/update`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response", response.data);
        window.location.reload();
        alert("success");
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="iq-card">
                <h4>Profile image</h4>
                <hr />
                {data?.map((item) => (
                  <div key={item?.id} className="row mt-4">
                    <div className="col-md-6">
                      <label>Profile Name</label>
                      <div className="col-lg-12 ">
                        <input
                          type="text"
                          className="form-control"
                          name="profile_name"
                          defaultValue={item?.profile_name}
                          onChange={(e) => setProfile_name(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Choose Profile Image</label>
                      <div className="mt-3">
                        <img
                          src={item?.choose_profile_screen_image}
                          width={100}
                        />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>

                    <div className="text-end mt-4">
                      <input
                        type="submit"
                        value=" Upload!"
                        onClick={editProduct}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ProfileScreen;
