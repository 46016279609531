import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const SeriesLists = () => {

  const [series, setSeries] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");
  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setSeries({ ...series, [e.target.name]: 1 });
    } else {
      setSeries({ ...series, [e.target.name]: 0 });
    }
  };

  useEffect(async () => {
    await axios
      .post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/list`,
      { Channel_Partner_id: Channel_Partner_id }, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.series?.data;
        setSeries(result);

        // console.log(result)  
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.series.data);
        setPrevious(res?.data?.series?.data);
        setSeries(res?.data?.series?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/list?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.series.data);
        setPrevious(res?.data?.series?.data);
        setSeries(res?.data?.series?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/list?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.series.data);
        setNext(res?.data?.series?.data);
        setSeries(res?.data?.series?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2 mt-3">
              <div className="row align-items-center p-2">
                <div className="col-md-5">
                  <h4>
                    <i className="entypo-movie"></i> All Series List
                  </h4>
                </div>
              </div>

              <div className="gallery-env">
                <div className="row mt-3 p-2">
                  <table className="table text-center iq-card">
                    <thead>
                      <tr className="table-header r1">
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Image</label>
                        </th>
                        <th>
                          <label>Series Title</label>
                        </th>
                        <th>
                          <label>Genre</label>
                        </th>
                        <th>
                          <label>Slider</label>
                        </th>
                        <th>
                          <label>Operation</label>
                        </th>
                      </tr>
                    </thead>
                    {series.map((item, key) => (
                      <thead>
                        <tr>
                          <td key={key}> {key + 1} </td>
                          <td>
                            <img
                              src={item.Thumbnail}
                              alt="Thumbnail"
                              width={200}
                            />
                          </td>
                          <td>{item.title}</td>
                          <td>{item.genre_id}</td>
                          <td>
                            <label className="switch">
                              <input
                                name="ppv_status"
                                defaultChecked={
                                  item.ppv_status == 1 ? true : false
                                }
                                checked={item.ppv_status == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="ppv_status"
                                value={item.ppv_status == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </td>
                          <td>
                            <Link
                              to={"/channel/edit-series/" + item.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </table>

                  <div className="row text-center d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">
                        {page}
                        </p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default SeriesLists