import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function SubtitleLanguage() {
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [subtitle, setSubtitle] = useState([]);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/subtitles/index`, {
        headers: headers,
      })
      .then((getData) => {
        setSubtitle(getData?.data?.Subtitle?.data);
        // console.log(getData.data.Language);
      });
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("language", name);
    formData.append("short_code", slug);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          alert("Item Added Successfully !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/subtitles/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        alert("Item Deleted Successfully !");
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.Subtitle?.data);
        setSubtitle(res?.data?.Subtitle?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/index?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.slider.data);
        setPrevious(res?.data?.Subtitle?.data);
        setSubtitle(res?.data?.Subtitle?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/index?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.slider.data);
        setNext(res?.data?.Subtitle?.data);
        setSubtitle(res?.data?.Subtitle?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="iq-card mt-4">
              <div className="row mt-2 ">
                <div className="col-md-6">
                  <h4>
                    <i className="entypo-archive"></i> Subtitles
                  </h4>
                  <small></small>
                </div>

                <div className="col-md-6" align="right">
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="fa fa-plus-circle"></i> Add New
                  </button>
                </div>

                <hr className="mt-2"></hr>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          New Language
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <label>Name: </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter Language Name"
                            className="form-control rtmp_urls"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="modal-body">
                        <div>
                          <label>Slug: </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter Short Code"
                            className="form-control rtmp_urls"
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary ml-3 "
                          onClick={handleSubmit}
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                className="table table-bordered iq-card text-center"
                id="categorytbl"
              >
                <tr className="table-header r1">
                  <th>
                    <label>S.No</label>
                  </th>
                  <th>
                    <label>Language</label>
                  </th>
                  <th>
                    <label>Short Code</label>
                  </th>
                  <th>
                    <label>Action</label>
                  </th>
                </tr>

                {subtitle?.map((item, key) => (
                  <tr>
                    <td className="table-content" key={item?.id}>
                      {key + 1}
                    </td>
                    <td className="table-content">
                      <p>{item?.language}</p>
                    </td>
                    <td className="table-content">
                      <p>{item?.short_code}</p>
                    </td>
                    <td className="table-content">
                      <Link to={"/admin/edit-subtitlelanguage/" + item?.id}>
                        <i
                          className="fa fa-pencil-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <Link onClick={() => deleteOperation(item?.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>

              <div className="row text-center d-flex">
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handlePreClick}
                  >
                    {"<< Previous"}
                  </button>
                </div>
                <div className="col-lg-4">
                  <p className="">{page}</p>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleNxtClick}
                  >
                    {"Next >>"}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default SubtitleLanguage;
