import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditManageseriesgenre.css";

function EditManageseriesgenre() {
  const [seriesgen, setSeriesgen] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    order: "",
    parent_id: "",
    name: "",
    slug: "",
    in_home: "",
    footer: "",
    in_menu: "",
    uploaded_by: "",
  });
  const [message, setMessage] = useState("");

  const [image, setImage] = useState("");
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState("");

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series-category/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.SeriesGenre?.data;
        // var resultss = response.data.SeriesGenre[0].name
        setData(result);
        // console.log('result', result)
        // console.log('SeriesGenre', result);
      })
      .catch((error) => console.log(error));

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesGenre[0];
      // var result = resData.user_access
      setEditUser(res);
      // setUseraccess(result)
      // console.log(resData)
      // console.log(result)
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      order: editUser.order,
      parent_id: editUser.parent_id,
      name: editUser.name,
      image: editUser.image,
      slug: editUser.slug,
      in_home: editUser.in_home,
      footer: editUser.footer,
      banner: editUser.banner,
      uploaded_by: editUser.uploaded_by,
      in_menu: editUser.in_menu,
      banner_image: editUser.banner_image,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/series-category/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson);
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      var SeriesGenre_id = resjson.SeriesGenre_id;

      const formDat = new FormData();

      formDat.append("image", image);
      formDat.append("banner_image", banner);
      formDat.append("series_genre_id", SeriesGenre_id);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series-category`,
          formDat,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            console.log("result", result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="EditManageSeries container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Series Genre
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label>Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    onChange={handleInput}
                    value={editUser?.name}
                    placeholder="Enter name"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Slug:</label>
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    onChange={handleInput}
                    value={editUser?.slug}
                    className="form-control"
                    placeholder="Enter Slug"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Display In Menu :</label>
                  <select
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label>Display In Home :</label>
                  <select
                    id="in_home"
                    name="in_home"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div className="mt-3">
                  <label>Display In Footer :</label>
                  <select
                    id="footer"
                    name="footer"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label>Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    id="image"
                  />
                </div>

                <div className=" mt-3">
                  <label>Category:</label>
                  <select
                    value={editUser?.parent_id}
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={handleInput}
                  >
                    {/* <option value={editUser.parent_id}>{editUser.name}</option> */}
                    {data?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditManageseriesgenre;
