import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import "./EditUser.css";

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    username: "",
    email: "",
    ccode: "",
    mobile_number: "",
    active: "",
    role: "",
    password: "",
  });
  // console.log(editUser)
  const [avatar, setAvatar] = useState({});
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [data, setData] = useState([]);
  // console.log("data", data)
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState("");

  // console.log(avatar)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/users/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.users[0];
      var results = resData?.users;
      setEditUser(res);
      // {
      // 	results.map((item) =>
      // 		setAvatar(item.avatar),
      // 	)
      // }
      // console.log(resData)
      // console.log(res)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.role;
        setData(result);
        var results = response?.data?.country;
        setNumber(results);
        // console.log("result", result);
        // console.log("orders", result);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/users/list`, {
        headers: headers,
      })
      .then((response) => {
        var results = response?.data?.users_list;

        {
          results?.map((item) => setUser(item?.user_avatar));
        }
        // console.log(results)
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      username: editUser.username,
      email: editUser.email,
      ccode: editUser.ccode,
      mobile_number: editUser.mobile_number,
      role: editUser.role,
      active: editUser.active,
      password: editUser.password,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/users/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");

      var user_id = resjson.user_id;
      var ressss = resjson;

      // console.log(user_id)
      // console.log(ressss)

      console.log("data", avatar, user_id);
      const formDatas = new FormData();
      formDatas.append("avatar", avatar);
      formDatas.append("user_id", user_id);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/users/avatar-update`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div>
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <div id="user-badge">
                      <img height="100" width="100" src={user} />
                      <label for="avatar">Profile Image</label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control mt-2 mb-3"
                        name="avatar"
                        id="avatar"
                        onChange={(e) => setAvatar(e.target.files[0])}
                      />
                    </div>
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1"> Username</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          name="username"
                          id="username"
                          value={editUser?.username}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1">User's mobile_number</label>
                        <div className="row">
                          <div className="col-sm-4">
                            <select
                              value={editUser?.ccode}
                              className="form-control"
                              name="ccode"
                              onChange={handleInput}
                            >
                              {/* <option value={editUser.ccode}>({editUser.ccode})</option> */}
                              {number?.map((item) => (
                                <option key={item?.id}
                                  value={item?.country_name + item?.phonecode}
                                >
                                  {item?.country_name} (+{item?.phonecode})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control mb-3"
                              name="mobile_number"
                              onChange={handleInput}
                              value={editUser?.mobile_number}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-body">
                        <label className="mb-1">User Email Address</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          name="email"
                          id="email"
                          value={editUser?.email}
                          onChange={handleInput}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="mb-1">Password</label>
                        </div>
                      </div>
                      <div className="panel-body">
                        <p className="p1">
                          (leave empty to keep your original password)
                        </p>

                        <p className="p1">Enter users password:</p>

                        <input
                          type="password"
                          className="form-control mb-3"
                          name="password"
                          id="password"
                          value={editUser?.password}
                          onChange={handleInput}
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-2"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label className="mb-1">User Role</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body">
                        <p className="p1">Select the user's role below</p>
                        <select
                          value={editUser.role}
                          className="form-control"
                          name="role"
                          onChange={handleInput}
                        >
                          {/* <option value={editUser.role}>{editUser.role}</option> */}
                          {data?.map((item) => (
                            <option key={item?.id} value={item?.role_name}>
                              {item?.role_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-2">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">User Active Status </label>
                      {/* <label className="switch" >
												<input name="status" value={(active.active === 0 || active.active === null) ? '1' : (active.active === 0) ? '1' : '0'} type="checkbox" />
												<span className="slider round" name="status" value={(active.active === 0 || active.active === null) ? '1' : (active.active === 0) ? '1' : '0'} ></span>
											</label> */}
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={editUser?.active === 1 ? true : false}
                          checked={editUser?.active === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={editUser?.active === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-2 p-2 d-flex justify-content-end">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Update"
                    onClick={handleUpdate}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default EditUser;
