import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const ManageAlbums = () => {
  const [managealbum, setManagealbum] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/List`,{Channel_Partner_id:Channel_Partner_id},
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.audio_albums?.data;
        setManagealbum(result);
        console.log(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        // alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload(); 
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/Channel/Backend/Audio-Album/List?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/Channel/Backend/Audio-Album/List?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.audio_albums.data);
        setPrevious(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/Channel/Backend/Audio-Album/List?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.audio_albums);
        setNext(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className=" pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between align-items-baseline mb-4">
                      <div className="iq-header-title">
                        <h4 className="card-title">Audio Album</h4>
                      </div>

                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link
                          to="/channel/add-albums"
                          className="btn btn-primary"
                        >
                          Add Audio Album
                        </Link>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="border-0 search-rev"
                          />
                        </div>
                      </div> */}
                    <div className="iq-card-body p-0">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins p-0"
                      >
                        <table
                          className="table-bordered table audio_table iq-card text-center p-0"
                          id="categorytbl"
                        >
                          <thead>
                            <tr className="r1">
                              <th>
                                <label>S.No</label>
                              </th>
                              <th>
                                <label>Name</label>
                              </th>
                              <th>
                                <label>Image</label>
                              </th>
                              <th>
                                <label>Action</label>
                              </th>
                            </tr>
                          </thead>

                          {managealbum.map((item, key) => (
                            <tbody>
                              <tr>
                                <td key={key}> {key + 1} </td>
                                <td>{item.albumname}</td>
                                <td>
                                  <img src={item.image_url} width="150" />
                                </td>
                                <td>
                                  <Link
                                    to={"/channel/edit-albums/" + item.id}
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageAlbums;
