import React, { useState, useEffect } from "react";

import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function ManageLiveCategories() {
  
  const [liveCategory, setLiveCategory] = useState([]);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios

      .get(`${process.env.REACT_APP_Baseurl}/admin/live-stream-category/index`, {
        headers: headers,
      })

      .then((getData) => {
        console.log()
        setLiveCategory(getData?.data?.LiveCategory?.data);
      });
  };

  const handleDelete = async (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    await axios.delete(
      `https://api.flicknexs.com/admin/live-stream-category/delete/${id}`,
      { headers: headers }
    );
    getData();

    // window.location.reload(true);
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-video-stream container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <i className="entypo-archive"></i> Live Stream
                            Categories
                          </h4>
                          <p>Organize The Categories Below:</p>
                        </div>
                        <div className="col-md-6" align="right">
                          <Link to="/admin/livestream-add-categories">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <table
                        className="table table-bordered iq-card text-center"
                        id="categorytbl"
                      >
                        <thead>
                        <tr className="table-header r1">
                          <th>
                            <label>Video Category Name</label>
                          </th>
                          <th>
                            <label>Action</label>
                          </th>
                        </tr>
                        </thead>

                        <thead>
                        {liveCategory?.map((item, key) => (
                          <tr>
                            <td key={item?.id} className="table-content">
                              <p>{item?.name}</p>
                            </td>
                            <td className="table-content">
                              <Link
                                to={"/admin/livestream-edit-categories/" + item?.id}
                              >
                                <i
                                  className="fa fa-pencil-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                              <Link >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                  onClick={() => handleDelete(item?.id)}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}</thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12 p-2">
          <div className="card text-center">
            <div className="card-body p-4">
              <Table >
                <thead>
                  <tr className="title">
                     <th >S.No</th>
                    <th>Video Category Name</th>
                    <th>Operation</th>

                  </tr>
                </thead>
                {managelivecategories.map((item, key) =>
                  <thead>
                    <tr >
                    <td key={key}> {key+1} </td>
                      <td>
                      <div>{item.name}</div>
                      </td>
                     
                      <td>
                      <div>
                      <Link to={"/admin/editlivestream/"+item.id}  className="edit ms-2" ><i className="fa fa-pencil-square" aria-hidden="true"></i></Link>
                            <Link to="" className="delete ms-5"><span><i className="fa fa-trash" aria-hidden="true"></i></span></Link>
                      </div>
                      </td>
                    </tr>
                  </thead>)}
              </Table>
            </div>
          </div>
        </div> */}
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageLiveCategories;
