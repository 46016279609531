import React, { useState, useEffect } from "react";
import axios from "axios";

const AddManageLiveCategories = () => {
  const [name, setName] = useState("");
  // console.log(name, "nn");
  const [slug, setSlug] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);

  const [in_menu, setIn_menu] = useState(false);

  // console.log(in_menu, "in");
  const [parent_id, setParent_id] = useState([]);
  const [category, setCategory] = useState([]);
  const [image, setImage] = useState("");

  const access_token = localStorage.getItem("access_token");

  // function handleOptionChange(event) {
  //   setSelectedOption(event.target.value);

  // }

  const checkHandler = () => {
    setIn_menu(!in_menu);
  };

  const handleSave = async () => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("slug", slug);
    formData.append("parent_id", parent_id);
    formData.append("in_menu", in_menu === true ? 1 : 0);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    // await axios
    //   .post(
    //     `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/store`,
    //     formData,
    //     { headers: headers }
    //   )
    //   .then((response) => {
    //     if (response.data.status === true) {
    //       var result = response.data;
    //       console.log("result", response);
    //     } else {
    //       console.log("Error");
    //       alert("Enter Correct Details");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error");
    //     alert("Enter Correct Details");
    //   });

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          var result = response.data;
          var id = response.data.LiveCategory.id;
          var resssss = response.data;

          console.log("resssss", id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          formDatas.append("id", id);
          formDatas.append("image", image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/image`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              if (response.data.status === true) {
                var result = response.data;

                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    getData();
    getData1();
  }, []);

  const getData = async () => {
    await axios

      .get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/index`,
        {
          headers: headers,
        }
      )

      .then((getData) => {
        setCategory(getData?.data?.LiveCategory);
      });
  };

  const getData1 = async () => {
    await axios.get(
      `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/create`,
      {
        headers: headers,
      }
    );
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <div className="p-3">
                <div className="col-md-12 mb-3">
                  <label className="m-0"> Name:</label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label className="m-0"> Slug:</label>

                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    className="form-control"
                    placeholder="Enter Slug"
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </div>
                {/* <div className="d-flex align-items-center">
                  <label>Display In Home page:</label>

                  <div class="form-check  mt-2 ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="yes"
                      checked={selectedOption ==== "yes"}
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Yes
                    </label>
                  </div>
                  <div class="form-check  mt-2 ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="no"
                      checked={selectedOption ==== "no"}
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      No
                    </label>
                  </div>
                </div> */}

                <div className="d-flex align-items-center">
                  <label>Display In Home page:</label>
                  <div className="ms-3">
                    <label className="text-danger">No </label>
                    <label className="switch">
                      <input
                        name="active"
                        // value={item.id}
                        // id="checkbox"
                        type="checkbox"
                        checked={in_menu}
                        onChange={checkHandler}
                      />
                      <span
                        className="slider round"
                        name="active"
                        // value={item.id}
                      ></span>
                    </label>
                    <label className="text-success">Yes</label>
                  </div>
                </div>
                <div className=" mt-3 form-group">
                  <label>Image:</label>
                  <input
                    type="file"
                    multiple="true"
                    className="form-control"
                    name="image"
                    id="image"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label>Category:</label>
                  <select
                    className="form-control"
                    name="parent_id"
                    id="parent_id"
                    onChange={(e) => setParent_id(e.target.value)}
                  >
                    <option value="0">Select</option>
                    {category?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Save"
                      className="btn btn-primary "
                      onClick={handleSave}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddManageLiveCategories;
