import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function ManageAge() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/age-category/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.Age_Categories;
        setData(result);
        // console.log(results)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-live-stream container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card ">
                <div className="row p-3">
                  <h4>
                    <i className=""></i>Age Category
                  </h4>
                  <hr />
                </div>

                <div className=" p-3">
                  <div className="row">
                    <div className="col-lg-6">
                      {" "}
                      <p>Organize The Categories Below:</p>
                    </div>
                    <div className="col-lg-6 p-2 text-end">
                      {" "}
                      <Link to="/admin/add-age">
                        <p className="btn btn-primary ">
                          {" "}
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                          New
                        </p>
                      </Link>
                    </div>
                  </div>
                  <Table className="text-center">
                    <thead>
                      <tr className="">
                        <th>ID</th>
                        <th>Age</th>
                        <th>Age Slug Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {data?.map((item) => (
                      <thead key={item?.id}>
                        <tr className="text-center">
                          <td>{item?.id}</td>
                          <td>{item?.age}</td>
                          <td>{item?.slug}</td>
                          <td>
                            <Link
                              to={"/admin/edit/manage-age/" + item?.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item?.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageAge;
